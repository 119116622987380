/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PDFObject from 'pdfobject';
import classNames from 'classnames';
import ExifJS from 'exif-js';
import { throwAuthorizationError, Select, Image } from '@lemonade-hq/bluis';
import { snakeToCamelCaseKeys } from '@lemonade-hq/ts-helpers';
import LightBox from '../../../../Bluis/Lightbox';
import ClaimItemsTable from './ClaimItemsTable';
import { assignmentTypes } from '../../../../../Commons/AttachmentsUtils';
import './style.scss';

function normalizeSelectedAttachment(selectedAttachment) {
    if (selectedAttachment == null) return selectedAttachment;
    return {
        name: selectedAttachment.name ?? selectedAttachment.originalFilename,
        url: selectedAttachment.url ?? selectedAttachment.downloadUrl,
        contentType: selectedAttachment.content_type ?? selectedAttachment.contentType,
        attachmentPublicId: selectedAttachment.publicId,
        claimItems: selectedAttachment.claimItems,
    };
}

const AssignAttachmentDialog = ({ claimItems, currency, id, onClose, selectedAttachment, updateAttachmentData }) => {
    selectedAttachment = normalizeSelectedAttachment(selectedAttachment);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(undefined);
    const [type, setType] = useState('');
    const [imageOrientation, setImageOrientation] = useState(0);
    const [claimItemIds, setClaimItemIds] = useState(
        selectedAttachment?.claimItems?.map(item => snakeToCamelCaseKeys(item).publicId || item.id) ?? []
    );
    const [pdfPreviewLoaded, setPdfPreviewLoaded] = useState(false);
    const isEditAssignment = selectedAttachment?.claimItems?.length > 0;

    const getImageOrientation = url => {
        const img = new Image();

        img.src = url;
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            ExifJS.getData(img, () => {
                setImageOrientation(ExifJS.getTag(img, 'Orientation'));
            });
        };
    };

    useEffect(() => {
        const { name, url } = selectedAttachment;
        const isPDFFile = name != null && !name.toLowerCase().endsWith('.pdf');

        if (isPDFFile) {
            getImageOrientation(url);
        }
    }, [selectedAttachment]);

    const getAttachment = () => {
        const { url, contentType, name } = selectedAttachment;
        const previewPdf = thumbnail => {
            PDFObject.embed(`${escapeHTML(thumbnail)}#page=1`, '#pdf-container-review', {
                width: '364px',
                height: '560px',
            });
        };
        let previewElement;

        if (contentType === 'application/pdf' || (name != null && name.toLowerCase().endsWith('.pdf'))) {
            previewElement = <div id="pdf-container-review" />;
            if (!pdfPreviewLoaded) {
                setPdfPreviewLoaded(true);
                setTimeout(() => previewPdf(url), 10);
            }
        } else {
            const imageClass = classNames('lightbox-attachment-image', {
                rotate: imageOrientation === 6,
            });

            previewElement = <Image img={{ lightSrc: url }} alt="" className={imageClass} />;
        }

        return (
            <div className="lightbox-attachment-image-container">
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {previewElement}
                </a>
            </div>
        );
    };

    const lightboxOptions = {
        className: () => classNames('lightbox-assign-attachments'),
        isSubmitting,
        error,
        title: 'Assign Attachment',
        centered: false,
        desktopStyle: true,
        btnClose: true,
        overlayClose: true,
        closeByKeyboard: true,
        onClose,
        id,
        leftPanel: getAttachment(),
        actions: [
            {
                text: 'close',
                type: 'close',
            },
            {
                text: isEditAssignment ? 'save' : 'assign',
                type: 'submit',
                isDisabled: () => !isEditAssignment && !(type === 'police_report' || claimItemIds.length > 0),
                onClick: async () => {
                    setIsSubmitting(true);
                    try {
                        await updateAttachmentData(id, {
                            type,
                            claimItemIds,
                            attachmentPublicId: selectedAttachment.attachmentPublicId,
                        });
                        onClose();
                    } catch (e) {
                        setIsSubmitting(false);
                        throwAuthorizationError(e);
                        setError('An error occurred - please try again.');
                    }
                },
            },
        ],
    };

    const onSelect = ({ value: type }) => {
        if (type === 'police_report') {
            setClaimItemIds([]);
        }

        setType(type);
    };

    return (
        <LightBox options={lightboxOptions}>
            <div className="assign-attachments-dropdown-container">
                <div className="assign-attachments-dropdown-title">What&apos;s this document?</div>
                <Select
                    value={type}
                    placeholder="Select doc type"
                    onOptionSelected={option => onSelect(option)}
                    options={assignmentTypes}
                />
            </div>
            <ClaimItemsTable
                {...{
                    claimItems,
                    currency,
                    claimItemIds,
                    setClaimItemIds,
                    disabled: !type || type === 'police_report',
                }}
            />
        </LightBox>
    );
};

export default AssignAttachmentDialog;
