import { MainButton, PencilIcon, SecondaryButton, Spinner, throwAuthorizationError } from '@lemonade-hq/bluis';
import { font, themedColor } from '@lemonade-hq/boutique';
import { Flex, useClickOutside } from '@lemonade-hq/cdk';
import isEmpty from 'lodash/isEmpty';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Form from 'bluis/Form';
import Input from 'bluis/Input';
import { GENERAL_ERROR_MSG } from 'commons/Constants';

const HOVER_FILL_COLOR = themedColor('inputBorderHover');

const HoverStylePencil = styled(PencilIcon)<{ readonly active: boolean }>`
    ${({ active }) => `visibility: ${active ? 'visible' : 'hidden'};`}
    &:hover #Pencil {
        fill: ${HOVER_FILL_COLOR};
    }
`;

interface EditIndicatorProps extends React.SVGAttributes<SVGElement> {
    readonly active?: boolean;
}

export const EditIndicator: React.FC<React.PropsWithChildren<EditIndicatorProps>> = ({
    width = 12,
    height = 12,
    active = true,
    ...restProps
}) => <HoverStylePencil {...restProps} active={active} height={height} width={width} />;

const EditorContainer = styled.div<{ readonly error?: boolean }>`
    display: flex;
    position: absolute;
    right: -171px;
    bottom: 33px;
    padding: 10px;
    background-color: ${themedColor('componentBackground')};
    border-radius: 5px;
    border: 1px solid ${themedColor('separator')};
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.07);
    flex-direction: column;

    ${Input} {
        border-color: ${themedColor('secondaryButtonBorderHover')} !important;
        width: 238px;
        height: 38px;

        &:focus,
        &:active {
            border-color: ${themedColor('secondaryText')} !important;
        }

        ${({ error }) =>
            error &&
            css`
                border-color: ${themedColor('error')} !important;
            `};
    }
`;

const EditorContent = styled(Flex)``;

export const TriangleWithShadow = styled.div`
    width: 25px;
    height: 15px;
    overflow: hidden;
    position: absolute;
    bottom: -15px;
    left: 155px;

    &::after {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        background: ${themedColor('componentBackground')};
        transform: rotate(45deg);
        top: -17px;
        left: 0;
        border: 1px solid ${themedColor('separator')};
        border-radius: 5px;
    }
`;

const decisionButton = css`
    min-width: 0;
    width: 38px;
    height: 38px;
    padding: 0;
    position: relative;
    margin-left: 8px;
`;

const DeclineButton = styled(SecondaryButton)<{ readonly submitting: boolean }>`
    ${decisionButton}
    &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        left: 13px;
        top: 13px;
        background: url('${__assets_url}blender_assets/backoffice/decline_night.png') no-repeat;
        background-size: 100%;
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
    }

    ${({ submitting }) =>
        submitting &&
        css`
            cursor: default;
            opacity: 0.5;
            background-color: ${themedColor('elementBackground')};
        `}
`;

const SubmittingSpinner = styled(Spinner)`
    visibility: hidden;
    border-top-color: rgba(255, 255, 255, 0.8);
`;

const ApproveButton = styled(MainButton)<{ readonly submitting: boolean; readonly disabled?: boolean }>`
    ${decisionButton}
    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 12px;
        top: 12px;
        background: url('${__assets_url}blender_assets/backoffice/approve_white.png') no-repeat;
        background-size: 100%;
    }

    &:disabled,
    &.disabled {
        background-color: ${themedColor('mainButtonBackground')};
        cursor: default;
        opacity: 0.3;
        pointer-events: none;
        border: 1px solid ${themedColor('mainButtonBackground')};
    }

    ${({ submitting }) =>
        submitting &&
        css`
            ${SubmittingSpinner} {
                visibility: visible;
            }

            &::after {
                background: none;
            }
        `}
`;

const StyledErrorMessage = styled(Flex)`
    color: ${themedColor('error')};
    ${font('main', { fontSize: '12px', fontWeight: 400, lineHeight: 'normal' })}
    text-align: left;
    margin-top: 8px;
    white-space: pre-line;
`;

interface TextValidation {
    readonly isValid: (text: string) => boolean;
    readonly errorMessage?: string;
}

interface InlineInputEditorProps {
    readonly text: string;
    readonly updateText: (text: string) => Promise<void> | void;
    readonly textValidation?: TextValidation;
    readonly editorVisible: boolean;
    readonly closeEditor: () => void;
    readonly disableSubmit?: (text: string) => boolean;
    readonly showErrorMessage?: boolean;
    readonly className?: string;
    readonly defaultValue?: string;
}

/**
 * @deprecated
 * use InlineInputEditor from @lemonade-hq/bluis
 */
const InlineInputEditor: React.FC<React.PropsWithChildren<InlineInputEditorProps>> = ({
    text,
    updateText,
    textValidation,
    editorVisible,
    closeEditor,
    disableSubmit,
    showErrorMessage,
    className,
    defaultValue,
}) => {
    const [inputText, setInputText] = useState(text);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const editorRef = useRef(null);

    const invalidText = disableSubmit ? disableSubmit(inputText) : false;
    const submitDisabled = inputText === text || isSubmitting || isEmpty(inputText) || invalidText;

    async function onSubmitTextForm(e: React.FormEvent) {
        e.preventDefault();

        await onSubmitText();
    }

    async function onSubmitTextClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        e.stopPropagation();

        await onSubmitText();
    }

    async function onSubmitText() {
        if (submitDisabled) {
            return;
        }

        if (textValidation && !textValidation.isValid(inputText.trim())) {
            setIsSubmitting(false);
            setError(textValidation.errorMessage ?? GENERAL_ERROR_MSG);
            return;
        }

        setIsSubmitting(true);

        try {
            await updateText(inputText.trim());
        } catch (e) {
            setIsSubmitting(false);
            throwAuthorizationError(e);
            setError(showErrorMessage ? e?.message ?? GENERAL_ERROR_MSG : GENERAL_ERROR_MSG);
        }
    }

    function onDescriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
        setInputText(e.target.value);
    }

    function handleClickOutside(e: KeyboardEvent | MouseEvent | React.MouseEvent | TouchEvent) {
        e.stopPropagation();
        if (!isSubmitting) {
            closeEditor();
        }
    }

    useClickOutside(editorRef, editorVisible, handleClickOutside);

    return (
        <EditorContainer className={className} error={error.length > 0} ref={editorRef}>
            <EditorContent>
                <Form onSubmit={onSubmitTextForm}>
                    <Input autoFocus defaultValue={defaultValue} onChange={onDescriptionChange} placeholder={text} />
                </Form>
                <DeclineButton onClick={handleClickOutside} submitting={isSubmitting} />
                <ApproveButton disabled={submitDisabled} onClick={onSubmitTextClick} submitting={isSubmitting}>
                    <SubmittingSpinner size={15} />
                </ApproveButton>
            </EditorContent>
            {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
            <TriangleWithShadow />
        </EditorContainer>
    );
};

export default InlineInputEditor;
