import type { TableColumn, TableItem } from '@lemonade-hq/blender-ui';
import type { AttachmentCustomData } from 'components/Attachments/types';
import { MISSING_VALUE } from 'components/Attachments/utils';

export function getCustomColumns({ data }: { readonly data: AttachmentCustomData[] }): TableColumn[] {
    if (!data.length) return [];

    return data.map(item => ({ key: item.columnId, name: item.columnLabel }));
}

export function getCustomRows({
    data,
}: {
    readonly data: AttachmentCustomData[];
}): Record<string, TableItem> | undefined {
    if (!data.length) return undefined;

    return data.reduce<Record<string, { value: TableItem['value'] }>>((acc, item) => {
        const rows = item.data.map(it => {
            switch (it.label.type) {
                case 'string':
                    return { [it.value]: { value: it.label.text } };
                default:
                    return {};
            }
        });

        acc[item.columnId] = {
            value: rows.length === 0 ? MISSING_VALUE : rows.map(row => Object.values(row)[0]?.value).join(', '),
        };

        return acc;
    }, {});
}
