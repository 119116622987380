import { EntityTypes, withSectionErrorBoundary } from '@lemonade-hq/bluiza';
import moment from 'moment-timezone';
import React, { useContext } from 'react';
import PetClaimContext from '../PetClaimContext';
import { createAttachments, getAttachmentTypes } from 'apis/Pet/PetAttachmentsAPI';
import ClaimAttachments from 'bluis/ClaimAttachments';
import { filterPresentableAttachments } from 'commons/AttachmentsUtils';
import { AttachmentHub } from 'components/Attachments/AttachmentHub';
import { TrackContentLoaded } from 'components/CustomPageLoadMetric';
import useAttachmentUpload from 'hooks/useAttachmentUpload';
import { AttachmentEntityType, AttachmentShared, AttachmentType } from 'models/Attachment';
import { usePetClaim, usePetClaimUpdateAttachmentMutation, useRefreshClaim } from 'queries/Pet/PetClaimQueries';

const PetClaimAttachments: React.FC<React.PropsWithChildren> = () => {
    const { claimId } = useContext(PetClaimContext);
    const { data } = usePetClaim(claimId);
    const { upload, isUploading } = useAttachmentUpload(
        AttachmentEntityType.Claim,
        AttachmentShared.Claim,
        createAttachments,
        reloadClaimAttachments
    );

    const refreshClaim = useRefreshClaim(claimId);
    const { mutateAsync: updateAttachmentMutation } = usePetClaimUpdateAttachmentMutation(claimId);

    if (!data) return null;

    const { claim, timezone = 'UTC' } = data;

    const policyAttachments = [];
    const { attachments: claimAttachments = [], effective_policy_pdf_url: effectivePolicyPdfUrl } = claim;

    async function reloadClaimAttachments() {
        refreshClaim();
    }

    async function updateAttachment(attachmentId: string, description?: string) {
        await updateAttachmentMutation({ attachments: [{ file_public_id: attachmentId, description }] });
    }

    const filteredAttachments = claimAttachments
        .filter(filterPresentableAttachments)
        .sort((a, b) => moment(b.created_at).diff(a.created_at, 'minutes'));

    if (effectivePolicyPdfUrl) {
        policyAttachments.push({
            type: AttachmentType.PolicyPDF,
            download_url: effectivePolicyPdfUrl,
            description: 'Policy PDF',
            content_type: 'pdf',
            original_filename: 'Policy PDF.pdf',
            file_public_id: 'policy_pdf',
            fraud_detection_identifier: 'policy_pdf',
        });
    }

    return (
        <>
            <TrackContentLoaded />

            <ClaimAttachments
                entityType={AttachmentEntityType.Claim}
                {...{ timezone, getAttachmentTypes }}
                addAttachments={upload}
                addAttachmentsLoading={isUploading}
                attachments={filteredAttachments}
                entityId={claimId}
                policyAttachments={policyAttachments}
                updateAttachmentData={updateAttachment}
            />
        </>
    );
};

const Hub: React.FC<{ readonly forceRenderLegacy?: boolean }> = ({ forceRenderLegacy }) => {
    const { claimId } = useContext(PetClaimContext);

    if (forceRenderLegacy) {
        return <PetClaimAttachments />;
    }

    return (
        <>
            <TrackContentLoaded />
            <AttachmentHub entityId={claimId} entityType={EntityTypes.PetClaim} />
        </>
    );
};

export default withSectionErrorBoundary(Hub);
