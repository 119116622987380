import { produce } from 'immer';
import noop from 'lodash/noop';
import React, { createContext, useContext, useState } from 'react';

type ICarouselContext = {
    readonly selectedIndex: number;
    readonly setSelectedIndex: (index: number) => void;
    readonly updateLoading: (index: number, loading: boolean) => void;
    readonly updateError: (index: number, error: boolean) => void;
    readonly getIsError: (index: number) => boolean;
    readonly getIsLoading: (index: number) => boolean;
};

type CarouselItem = {
    readonly loading: boolean;
    readonly error: boolean;
};

type CarouselProviderProps = {
    readonly children: JSX.Element[];
    readonly index: number;
    readonly length: number;
};

const CarouselContext = createContext<ICarouselContext>({
    selectedIndex: -1,
    setSelectedIndex: noop,
    updateLoading: noop,
    updateError: noop,
    getIsError: () => false,
    getIsLoading: () => false,
});

const CarouselProvider: React.FC<React.PropsWithChildren<CarouselProviderProps>> = ({ children, index, length }) => {
    const [selectedIndex, setSelectedIndex] = useState(index);
    const [itemsStatus, setItemsStatus] = useState<CarouselItem[]>(Array(length).fill({ loading: true, error: false }));

    const updateLoading = (index: number, loading: boolean) => {
        setItemsStatus(state =>
            produce(state, draft => {
                draft[index].loading = loading;
            })
        );
    };

    const updateError = (index: number, error: boolean) => {
        setItemsStatus(state =>
            produce(state, draft => {
                draft[index].error = error;
                draft[index].loading = false;
            })
        );
    };

    const getIsLoading = (index: number) => {
        return itemsStatus[index]?.loading;
    };

    const getIsError = (index: number) => {
        return itemsStatus[index]?.error;
    };

    const value = {
        selectedIndex,
        setSelectedIndex,
        updateLoading,
        updateError,
        getIsError,
        getIsLoading,
    };

    return <CarouselContext.Provider value={value}>{children}</CarouselContext.Provider>;
};

function useCarousel(): ICarouselContext {
    const context = useContext(CarouselContext);

    if (context.selectedIndex < 0) {
        throw new Error('useCarousel must be used within a CarouselProvider');
    }

    return context;
}

export { CarouselProvider, useCarousel };
