/* eslint-disable default-case */
import type { AudioPlayerPresentedEvent, AudioPlayerPresentedSegment } from '@lemonade-hq/blender-ui';
import { AudioPlayerPresentedSegmentType, colors, opacify } from '@lemonade-hq/blender-ui';
import type { ConversationTimelineEvent } from '@lemonade-hq/voice-conversations';
import groupBy from 'lodash/groupBy';
import type {
    VoiceConversation,
    VoicePlaygroundCalledToolEvent,
    VoicePlaygroundSegmentItem,
    VoicePlaygroundTimelineEvent,
    VoicePlaygroundToolResultEvent,
} from '../Voice.types';

function getTitle(segment: VoicePlaygroundSegmentItem): string {
    switch (segment.side) {
        case 'tool':
            return 'CXLLM Inquiry';
        case 'user':
            return 'User';
        case 'assistant':
            return 'AI Maya';
    }
}

export function voiceSegmentPresenter(segment: VoicePlaygroundSegmentItem): AudioPlayerPresentedSegment {
    return {
        title: getTitle(segment),
        content: segment.content,
        image: segment.side === 'assistant' ? 'https://s3.amazonaws.com/lmnd-images/maya_avatar.png' : undefined,
        icon: segment.side === 'tool' ? 'spark-solid' : undefined,
        group: segment.side,
        type: segment.side === 'tool' ? AudioPlayerPresentedSegmentType.Process : AudioPlayerPresentedSegmentType.Audio,
        metadata: segment.metadata,
        color: segment.side === 'user' ? colors.neutral7 : colors.ai2,
        backgroundColor: segment.side === 'user' ? 'transparent' : opacify(colors.ai4, 0.75),
    };
}

export function voiceEventPresenter(event: VoicePlaygroundTimelineEvent): AudioPlayerPresentedEvent {
    const result = { timestamp: event.timestamp, title: event.type } as AudioPlayerPresentedEvent;

    switch (event.type) {
        // general
        case 'assistantBecameReady':
        case 'conversationStarted':
            return { ...result, hidden: true };
        case 'callTerminatedByAssistant': {
            const command = (event as Extract<ConversationTimelineEvent, { type: 'callTerminatedByAssistant' }>)
                .command;
            return {
                ...result,
                description: `command: ${command}`,
                icon:
                    command === 'ok' ? 'shield-check-solid' : command === 'error' ? 'alert-circle-solid' : 'flag-solid',
                iconColor: command === 'error' ? 'negative1' : 'shadow',
            };
        }

        case 'callTerminatedByUser':
            return { ...result, icon: 'phone-solid', iconColor: 'shadow' };

        // tool calling
        case 'calledTool':
            return { ...result, icon: 'spark-solid', iconColor: 'brand1' };
        case 'toolFailed':
            return { ...result, icon: 'spark-solid', iconColor: 'negative1' };
        case 'toolSucceeded':
            return { ...result, icon: 'spark-solid', iconColor: 'ai2' };

        // assistant
        case 'assistantBehavioralResponseTriggered':
            return {
                ...result,
                description: `behavior: ${
                    (event as Extract<ConversationTimelineEvent, { type: 'assistantBehavioralResponseTriggered' }>)
                        .behavior
                }`,
                icon: 'clock-solid',
                iconColor: 'ai2',
            };
        case 'assistantInterruptedUser':
            return { ...result, icon: 'media-pause', iconColor: 'attention1' };
        case 'assistantStartedSpeaking':
            return { ...result, icon: 'message', iconColor: 'ai2' };
        case 'assistantEndedSpeaking':
            return { ...result, icon: 'bullet', iconColor: 'ai2' };
        case 'assistantDeliveredToolResponse':
            return { timestamp: event.timestamp, hidden: true };
        case 'assistantTerminationRequested':
            return {
                ...result,
                description: `reason: ${
                    (event as Extract<ConversationTimelineEvent, { type: 'assistantTerminationRequested' }>).reason
                }`,
                icon: 'x-circle-solid',
                iconColor: 'shadow',
            };
        case 'assistantTerminationAdded':
            return { ...result, hidden: true };

        // user
        case 'userStartedSpeaking':
            return { ...result, icon: 'user', iconColor: 'brand1' };
        case 'userEndedSpeaking':
            return { ...result, icon: 'bullet', iconColor: 'brand1' };
        case 'userInterruptedAssistant':
            return { ...result, icon: 'media-pause', iconColor: 'brand1' };

        // errors
        case 'generalErrorReceived':
            return {
                ...result,
                description: (event as Extract<ConversationTimelineEvent, { type: 'generalErrorReceived' }>).error,
                icon: 'alert-circle-solid',
                iconColor: 'negative2',
            };
        case 'invalidTransitionReceived':
            return {
                ...result,
                description: (event as Extract<ConversationTimelineEvent, { type: 'invalidTransitionReceived' }>).event,
                icon: 'alert-circle-solid',
                iconColor: 'negative2',
            };
    }
}

export function addToolCallingToConversationSegments(
    conversation: VoiceConversation | null | undefined
): VoicePlaygroundSegmentItem[] {
    if (conversation == null) return [];

    const transcript = [...conversation.transcript] as VoicePlaygroundSegmentItem[];
    const toolEvents = conversation.timeline.filter(e =>
        ['calledTool', 'toolSucceeded', 'toolFailed'].includes(e.type)
    );
    const groupedToolEvents = groupBy(toolEvents, 'toolCallId');
    Object.values(groupedToolEvents).forEach(events => {
        const toolCall = events.find(e => e.type === 'calledTool') as VoicePlaygroundCalledToolEvent;
        const toolResult = events.find(e => e.type === 'toolSucceeded' || e.type === 'toolFailed') as
            | VoicePlaygroundToolResultEvent
            | undefined;
        if (toolResult == null) return;

        transcript.push({
            side: 'tool',
            id: toolCall.toolCallId,
            startedAt: toolCall.timestamp,
            endedAt: toolResult.timestamp,
            metadata: {
                ...toolCall.toolParams,
                action:
                    toolResult.type === 'toolSucceeded' ? (toolResult.toolResult as { type: string }).type : '<N/A>',
                output:
                    toolResult.type === 'toolSucceeded'
                        ? (toolResult.toolResult as { output: string }).output
                        : 'error' in toolResult
                          ? toolResult.error
                          : null,
            },
            content: null,
        });
    });
    return transcript;
}
