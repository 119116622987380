import type { DateValue } from '@internationalized/date';
import { createCalendar } from '@internationalized/date';
import { clsx } from 'clsx';
import type { FC } from 'react';
import { useRef } from 'react';
import type { AriaButtonOptions, AriaDatePickerProps } from 'react-aria';
import { useButton, useDateField, useLocale } from 'react-aria';
import { useDateFieldState } from 'react-stately';
import type { DateSegment as AriaDateSegment } from 'react-stately';
import { Flex } from '../../base/Flex/Flex';
import { Layout } from '../../base/Layout/Layout';
import { Text } from '../../base/Text/Text';
import { input } from '../../theme/input.css';
import { spacing } from '../../theme/spacing.css';
import { IconButton } from '../IconButton/IconButton';
import { DateSegment } from './DateSegment';
import { dropdownArrow, inputActionsContainer, inputLikeDisabled } from './DateTimePicker.css';

const getTimeSegments = (segments: AriaDateSegment[]): AriaDateSegment[] => {
  const hour = segments.find(({ type }) => type === 'hour');
  const minute = segments.find(({ type }) => type === 'minute');
  const dayPeriod = segments.find(({ type }) => type === 'dayPeriod');

  if (!hour || !minute || !dayPeriod) {
    throw new Error('Invalid time segments');
  }

  return [
    { type: 'literal', text: ', ', isPlaceholder: false, placeholder: ' ', isEditable: false },
    hour,
    { type: 'literal', text: ':', isPlaceholder: false, placeholder: '', isEditable: false },
    minute,
    { type: 'literal', text: ' ', isPlaceholder: false, placeholder: '', isEditable: false },
    dayPeriod,
  ];
};

const formatDateSegments = (segments: AriaDateSegment[], format?: string): AriaDateSegment[] => {
  if (format == null) return segments.slice(0, 5);

  const separator = format[format.search(/\W/)];

  const separatorSegment: AriaDateSegment = {
    type: 'literal',
    text: separator,
    isEditable: false,
    isPlaceholder: false,
    placeholder: '',
  };

  const daySegment = segments.find(({ type }) => type === 'day');
  const monthSegment = segments.find(({ type }) => type === 'month');
  const yearSegment = segments.find(({ type }) => type === 'year');

  return format.split(separator).flatMap((segmentType, i, arr) => {
    let currSegment: AriaDateSegment | undefined;

    const segmentLetter = segmentType.toLowerCase()[0];

    switch (segmentLetter) {
      case 'd':
        currSegment = daySegment;
        break;
      case 'm':
        currSegment = monthSegment;
        break;
      case 'y':
        currSegment = yearSegment;
        break;
      default:
        currSegment = undefined;
    }

    if (currSegment == null) {
      throw new Error(`Invalid format: ${format}`);
    }

    if (i === arr.length - 1) {
      return [currSegment];
    }

    return [currSegment, separatorSegment];
  });
};

interface DateTimeFieldProps {
  readonly format?: string;
  readonly buttonProps: AriaButtonOptions<'button'>;
  readonly fieldProps: AriaDatePickerProps<DateValue>;
  readonly disabled: boolean;
  readonly withTime?: boolean;
  readonly locale?: string;
  readonly isOpen: boolean;
  readonly clear: () => void;
  readonly cancelable: boolean;
  readonly error?: string;
  readonly triggerTestId: string;
  readonly className?: string;
}

export const DateTimeField: FC<DateTimeFieldProps> = ({
  format,
  buttonProps,
  withTime = false,
  fieldProps,
  locale,
  isOpen,
  disabled,
  clear,
  cancelable,
  error,
  triggerTestId,
  className,
}) => {
  const { locale: ariaLocale } = useLocale();
  const state = useDateFieldState({
    ...fieldProps,
    locale: locale ?? ariaLocale,
    createCalendar,
    granularity: withTime ? 'minute' : 'day',
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { fieldProps: ariaFieldProps } = useDateField(fieldProps, state, inputRef);

  const { buttonProps: dateButtonProps } = useButton(buttonProps, buttonRef);

  const { color, ...sanitizedDateButtonProps } = dateButtonProps;

  return (
    <Layout position="relative">
      <Flex
        {...ariaFieldProps}
        alignItems="center"
        aria-label="Date"
        className={clsx(input(), disabled && inputLikeDisabled, className)}
        minWidth="13.6rem"
        ref={inputRef}
        role="textbox"
      >
        {[...formatDateSegments(state.segments, format), ...(withTime ? getTimeSegments(state.segments) : [])].map(
          (segment, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <DateSegment disabled={disabled} key={i} segment={segment} state={state} />
          ),
        )}
        <Flex className={inputActionsContainer} gap={spacing.s06}>
          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
          {state.value != null && cancelable && (
            <IconButton color="neutral7" icon="x" iconSize="sm" onClick={clear} size="sm" variant="inline" />
          )}
          <IconButton
            className={dropdownArrow({ isOpen })}
            data-testid={triggerTestId}
            disabled={disabled}
            icon="arrow-drop-down-solid"
            iconSize="xs"
            ref={buttonRef}
            size="sm"
            variant="inline"
            {...(disabled ? {} : sanitizedDateButtonProps)}
          />
        </Flex>
      </Flex>
      {Boolean(error) && (
        <Flex justifyContent="space-between" width="100%">
          <Text as="span" color="error" type="text-sm">
            {error}
          </Text>
        </Flex>
      )}
    </Layout>
  );
};
