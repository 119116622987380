import type { FC } from 'react';
import { IconButton } from '../IconButton/IconButton';
import { useAudioPlayer } from './AudioPlayer.Provider';

export const AudioPlayerMuteControls: FC = () => {
  const { isMuted, toggleMuted } = useAudioPlayer();

  return (
    <IconButton
      aria-label={isMuted ? 'muted' : 'unmuted'}
      icon={isMuted ? 'media-unmute' : 'media-mute'}
      iconSize="xl"
      onClick={toggleMuted}
      variant="inline"
    />
  );
};
