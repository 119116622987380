import { font } from '@lemonade-hq/cdk';
import domPurify from 'dompurify';
import type { FC } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { removeStyleAttributesFromHtml } from '../../Supervisor/supervisor.utils';

interface HtmlBodyProps {
    readonly html: string;
}

const Root = styled.div`
    word-break: break-word;
    ${font('main', { fontSize: '16px', lineHeight: '1.45' })};
    img {
        max-width: 100%;
        height: auto;
    }
`;

export const HtmlBody: FC<React.PropsWithChildren<HtmlBodyProps>> = ({ html }) => {
    const htmlClean = useMemo(() => removeStyleAttributesFromHtml(html), [html]);

    return <Root dangerouslySetInnerHTML={{ __html: domPurify.sanitize(htmlClean) }} />;
};
