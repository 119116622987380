import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';
import type { HomeOwnersReviewEntity, HomeOwnersReviewStatus } from 'models/HomeOwnersReview';

const homeBlenderUrlResolver = getUrlResolver('home-blender');

export interface ReviewRelatedEntity {
    readonly entityType: HomeOwnersReviewEntity;
    readonly entityPublicId: string;
}

export interface UpdateReviewAttributes {
    readonly reviewId: number;
    readonly properties: UpdateReviewProperties;
}
export interface UpdateReviewProperties {
    readonly reviewer_id?: string | null;
    readonly status?: HomeOwnersReviewStatus;
    readonly notes?: string;
}

export async function getReviewRelatedEntity(reviewId: string): Promise<ReviewRelatedEntity> {
    const url = homeBlenderUrlResolver(`/api/v1/reviews/${reviewId}/related_entity`);

    return (await axios.get<{ data: ReviewRelatedEntity }>(url)).data.data;
}

export async function updateReview({ reviewId, properties }: UpdateReviewAttributes) {
    const url = '/backoffice/underwriting_reviews/update';
    const data = { review_id: reviewId, ...properties };

    return await axios.put(url, data).then(response => response.data);
}
