import { MedicalConditionCategoryCatalogueItem } from './PetMedicalCondition';
import { MedicalProcedureType } from './PetMedicalConditionTimeline';

export enum PetFeatureType {
    AccidentIllness = 'accident_illness',
    Preventative = 'preventative',
    EndOfLife = 'end_of_life',
    DentalIllness = 'dental_illness',
    BehavioralConditions = 'behavioral_conditions',
}

export enum PetItemType {
    Procedure = 'procedure',
    VetExamFee = 'vet_exam_fee',
    Other = 'other',
}

export type PetClaimProceduresItem = {
    code: string;
    title: string;
    type: PetItemType;
    categories?: string[];
};

export type PetClaimProcedures = {
    medication?: PetClaimProceduresItem[];
    procedure?: PetClaimProceduresItem[];
    test?: PetClaimProceduresItem[];
    treatment?: PetClaimProceduresItem[];
    vet_fees?: PetClaimProceduresItem[];
    miscellaneous?: PetClaimProceduresItem[];
    PhysicalTherapy?: PetClaimProceduresItem[];
};

export type PetProcedureItem = {
    type: PetItemType.Procedure;
    procedure_type: string;
    procedure_code: string;
    procedure_name?: string;
};

type PetCustomItem = {
    type: PetItemType.Other;
    custom_procedure_name: string;
};

type PetVetExamFeeItem = {
    type: PetItemType.VetExamFee;
    custom_procedure_name: string;
};

export type PetClaimItem = {
    public_id: string;
    feature_type: PetFeatureType;
    cost_after_coinsurance: number;
    status: PetClaimItemStatus;
    cost: number;
    procedure_code: string;
    procedure_type: string;
    title?: string;
    applied_deductible_amount?: string;
    paid_amount?: number;
    limit?: number;
    due_payment?: number;
    is_paid_by_instant_claim_error?: boolean;
    claim_public_id?: string;
    rejection_reason: { category: string; code: string; text: string } | null;
    custom_rejection_reason?: string;
} & (PetProcedureItem | PetCustomItem | PetVetExamFeeItem);

export enum PetClaimItemStatus {
    Active = 'active',
    Paid = 'paid',
    Rejected = 'rejected',
    Archived = 'archived',
    PaymentFailed = 'payment_failed',
    PendingPayment = 'pending_payment',
    PaymentInProgress = 'payment_in_progress',
    PendingApproval = 'pending_approval',
    Approved = 'approved',
}

export const PetClaimDisabledStatuses = [
    PetClaimItemStatus.Archived,
    PetClaimItemStatus.PaymentFailed,
    PetClaimItemStatus.PendingPayment,
    PetClaimItemStatus.PaymentInProgress,
    PetClaimItemStatus.Paid,
    PetClaimItemStatus.Approved,
    PetClaimItemStatus.PendingApproval,
];

export type FeaturesAndItems = { features: Features; item_list_incomplete: boolean };

export type Features = { [key in PetFeatureType]: PetClaimItem[] };

export interface MedicalProcedure {
    id: number;
    code: string;
    text: string;
    type: MedicalProcedureType;
}

export type MedicalProcedureWithCategories = MedicalProcedure & {
    readonly categories: MedicalConditionCategoryCatalogueItem[];
};

export type MedicalProceduresByFeatures = Partial<Record<PetFeatureType, MedicalProcedureWithCategories[]>>;
