import { Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import type { DialogAction, SelectOption } from '@lemonade-hq/bluis';
import {
    AlertMode,
    Dialog,
    ErrorSection,
    FormInputWrapper,
    LoadingSection,
    Select,
    SuccessIcon,
} from '@lemonade-hq/bluis';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { ViolationsErrorMessage } from 'components/LoCo/common/components/ViolationsErrorMessage';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { EditionValidationsResponse } from 'models/LoCo/Insurance/CoveragesEdition';
import { checkCoverageEditionCompatibility } from 'queries/LoCo/Insurance/CoveragesEditionQueries';
import { useGetLatestsPublished } from 'queries/LoCo/Insurance/EditionTrackerQueries';

const NOTICE_MSG =
    'This action will check if there are any breaking changes in the current version against a selected base version';

const NO_VIOLATIONS_MSG = 'This version has no breaking changes compared to selected base version';

interface CheckCompatibilityDialogProps {
    readonly editionCode: string;
    readonly productCode: string;
    readonly productName: string;
    readonly referenceEditionContentCode?: string;
    readonly onClose: () => void;
}

export const CheckBackwardsCompatibilityDialog: React.FC<CheckCompatibilityDialogProps> = ({
    productCode,
    editionCode,
    productName,
    referenceEditionContentCode,
    onClose,
}) => {
    const [compatibilityStatus, setCompatibilityStatus] = useState<{
        status: EditionValidationsResponse | null;
        isError: boolean;
        isLoading: boolean;
    } | null>(null);

    const { errors, values, setValue, valid } = useForm({
        fields: {
            baseEditionCode: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
        },
    });

    const {
        data: latestPublished,
        isLoading: isLoadingLatestPublished,
        isError: isErrorLatestPublished,
    } = useGetLatestsPublished(productCode);

    const coverageEditionVersionOptions = useMemo<SelectOption[]>(
        () =>
            latestPublished?.map(latest => ({
                id: latest.editionContentCode,
                value: latest.editionContentCode,
                label: `${latest.friendlyName} (${latest.editionContentCode})`,
            })) ?? [],
        [latestPublished]
    );

    useEffect(() => {
        const baseEditionInLatestPublished = latestPublished?.find(
            latest => latest.editionContentCode === referenceEditionContentCode
        );

        if (baseEditionInLatestPublished != null) {
            setValue('baseEditionCode', baseEditionInLatestPublished.editionContentCode);
        }
    }, [latestPublished, referenceEditionContentCode, setValue]);

    const checkBreakingChanges = useCallback(async () => {
        setCompatibilityStatus({
            isError: false,
            isLoading: true,
            status: null,
        });

        try {
            const response = await checkCoverageEditionCompatibility(editionCode, values.baseEditionCode);

            setCompatibilityStatus({
                isError: false,
                isLoading: false,
                status: response,
            });
        } catch (error) {
            setCompatibilityStatus({
                isError: true,
                isLoading: false,
                status: null,
            });
        }
    }, [editionCode, values.baseEditionCode]);

    function selectBaseEditionCode(selectedOption: SelectOption): void {
        setValue('baseEditionCode', selectedOption.value);
    }

    const isDisabled = compatibilityStatus?.isLoading ?? (Boolean(isLoadingLatestPublished) || isErrorLatestPublished);

    const breakingChanges = useMemo<string[] | null>(() => {
        return compatibilityStatus?.status?.hasBreakingChanges === true ? compatibilityStatus.status.messages : null;
    }, [compatibilityStatus?.status?.hasBreakingChanges, compatibilityStatus?.status?.messages]);

    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Check',
                type: 'submit',
                onClick: checkBreakingChanges,
                disabled: Boolean(isDisabled) || !valid,
            },
        ],
        [onClose, checkBreakingChanges, isDisabled, valid]
    );

    const showNotice = useMemo(() => {
        return compatibilityStatus == null || compatibilityStatus.isLoading;
    }, [compatibilityStatus]);

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={
                compatibilityStatus?.isError ? (
                    GENERAL_ERROR_MSG
                ) : breakingChanges ? (
                    <ViolationsErrorMessage
                        editionType={EditionType.Coverages}
                        message={`This version's breaking changes`}
                        violations={breakingChanges}
                    />
                ) : undefined
            }
            loading={Boolean(compatibilityStatus?.isLoading)}
            notice={showNotice ? [{ title: NOTICE_MSG, mode: AlertMode.Info }] : undefined}
            onClose={onClose}
            size="large"
            title="Check Backwards Compatibility"
        >
            {Boolean(isLoadingLatestPublished) && <LoadingSection noBorders noShadow />}
            {Boolean(isErrorLatestPublished) && <ErrorSection noBorders title="Failed fetching base editions  :(" />}
            {latestPublished != null && (
                <>
                    <Flex justifyContent="center" mb={spacing.s40}>
                        <Text fontWeight="bold" type="text-lg">
                            {productName} Coverage Editions
                        </Text>
                    </Flex>
                    <FormInputWrapper label="Base Edition" showErrors={!isEmpty(errors.baseEditionCode)}>
                        <Select
                            disabled={isDisabled}
                            onOptionSelected={selectBaseEditionCode}
                            options={coverageEditionVersionOptions}
                            placeholder="Select"
                            value={values.baseEditionCode}
                        />
                    </FormInputWrapper>
                </>
            )}

            {Boolean(compatibilityStatus?.status) && (
                <Flex gap={spacing.s10} mt={spacing.s32}>
                    {breakingChanges == null && (
                        <>
                            <SuccessIcon />
                            {NO_VIOLATIONS_MSG}
                        </>
                    )}
                </Flex>
            )}
        </Dialog>
    );
};
