import { Flex, spacing } from '@lemonade-hq/blender-ui';
import type { RowItem, TableHeaderCellProps } from '@lemonade-hq/bluis';
import { ContentSection, EmptySection, Table, TableHeader, TableRow, TableTitle } from '@lemonade-hq/bluis';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { LabelWithTooltip } from '../coverage-editions/Settings/Dialogs/FormItems/Attributes/SharedLabels';
import type { FieldEntry, FieldMetadata, SchemaResponse } from 'models/LoCo/Insurance/Schema';

const AttributeRow: React.FC<{
    readonly field: Pick<FieldEntry, 'doc' | 'name'>;
    readonly fieldMetadata: FieldMetadata;
}> = ({ field, fieldMetadata }) => {
    const rowItems: RowItem[] = [
        { key: 'name', value: field.name },
        {
            key: 'type',
            value: isDefined(fieldMetadata.error) ? (
                <LabelWithTooltip label={fieldMetadata.displayType} tooltipContent={fieldMetadata.error} type="error" />
            ) : (
                fieldMetadata.displayType
            ),
        },
    ];

    return <TableRow key={field.name} row={rowItems} />;
};

const headerFields: TableHeaderCellProps[] = [
    { key: 'name', value: 'Name' },
    { key: 'type', value: 'Type' },
];

const AttributesTable: React.FC<{ readonly schemaResponse: SchemaResponse }> = ({ schemaResponse }) => {
    if (schemaResponse.schema.fields.length === 0) {
        return <EmptySection>No schema found for this Rating edition</EmptySection>;
    }

    return (
        <Flex margin={`${spacing.s24} 0 0 0`}>
            <Table>
                <TableHeader headers={headerFields} />
                {schemaResponse.schema.fields.map(field => (
                    <AttributeRow
                        field={field}
                        fieldMetadata={schemaResponse.fieldsMetadata[field.name]}
                        key={field.name}
                    />
                ))}
            </Table>
        </Flex>
    );
};

export const RatingSchema: React.FC<{ readonly schemaResponse: SchemaResponse }> = ({ schemaResponse }) => {
    return (
        <ContentSection>
            <TableTitle title="Schema Attributes" />
            <AttributesTable schemaResponse={schemaResponse} />
        </ContentSection>
    );
};
