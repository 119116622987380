import { Flex, FormProvider, spacing, useForm } from '@lemonade-hq/blender-ui';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DefineEditionSetStep } from './DefineEditionSetStep';
import type { EffectiveDatesStepType } from './EffectiveDates';
import { testInEnvSchema, TestInStagingForm } from './TestDialog';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import { StyledDialog } from 'components/LoCo/common/components/Dialog/Dialog';
import { useMiniFlowFormDialog } from 'components/LoCo/common/components/Dialog/useMiniFlowFormDialog';
import { StyledMiniFlow } from 'components/LoCo/common/components/MiniFlow';
import { getEditionSetUrl } from 'components/LoCo/common/urlBuilders';
import { EditionType, VersionType } from 'models/LoCo/Insurance/BaseEdition';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';
import {
    useActivateEditionSetInEnv,
    useArchiveEditionSet,
    useCreateEditionSetTest,
    useGetLatestMinorEditionSets,
} from 'queries/LoCo/Insurance/EditionSetQueries';

const TEXTS = [
    'Select the environment in which to activate this Edition Set.',
    'The Edition Set can be removed from the environment, or added to others, at any time via Blender.',
];

enum TestInEnvStep {
    DefineEditionSet = 'define_edition_set',
    SelectEnvironment = 'select_environment',
}

export interface EditionSelectionData {
    readonly selectedEditions: {
        readonly coveragesEditionCode: string;
        readonly digitalAgentEditionCode: string;
        readonly underwritingFiltersEditionCode: string;
        readonly ratingEditionCode: string;
    };
    readonly selectedPlatformSchema: string | null;
    readonly selectedProductSchema: string | null;
    readonly isValid: boolean;
}

export interface TestInEnvironmentDialogData {
    readonly editions: EditionSelectionData;
    readonly effectiveDates: EffectiveDatesStepType;
    readonly versionType: VersionType;
    readonly version: {
        readonly major: number;
        readonly minor: number;
        readonly isValid: boolean;
    };
}

const stepsIds = [TestInEnvStep.DefineEditionSet, TestInEnvStep.SelectEnvironment];

interface TestInEnvironmentDialogProps {
    readonly editionCode: string;
    readonly editionType: EditionType;
    readonly productCode: string;
    readonly onClose: () => void;
}

const now = new Date();

export const TestInEnvironmentDialog: React.FC<TestInEnvironmentDialogProps> = ({
    productCode,
    editionCode,
    editionType,
    onClose,
}) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { mutateAsync: createEditionSetTest } = useCreateEditionSetTest();
    const { mutateAsync: activateEditionSetInEnv } = useActivateEditionSetInEnv();
    const { mutateAsync: archiveEditionSet } = useArchiveEditionSet();

    const navigate = useNavigate();

    const {
        validationResults: { valid },
        values,
    } = useForm();

    const { currentStep, actions, dispatch, state } = useMiniFlowFormDialog<
        TestInEnvStep,
        DeepNullable<TestInEnvironmentDialogData>
    >({
        steps: stepsIds,
        onClose,
        onSubmit: async _data => {
            let response: { readonly code: string } | undefined;
            try {
                setIsLoading(true);
                response = await createEditionSetTest({
                    coveragesEditionCode: state.editions.selectedEditions.coveragesEditionCode ?? '',
                    digitalAgentEditionCode: state.editions.selectedEditions.digitalAgentEditionCode ?? '',
                    ratingEditionCode: state.editions.selectedEditions.ratingEditionCode ?? '',
                    underwritingFiltersEditionCode:
                        state.editions.selectedEditions.underwritingFiltersEditionCode ?? '',
                    newBusinessEffectiveAt: state.effectiveDates.newBusiness?.toISOString() ?? '',
                    renewalEffectiveAt: state.effectiveDates.renewals?.toISOString() ?? '',
                    platformSchemaRevision: Number(state.editions.selectedPlatformSchema),
                    productSchemaRevision: Number(state.editions.selectedProductSchema),
                    versionType:
                        state.version.minor != null && state.version.minor > 0 ? VersionType.Minor : VersionType.Major,
                    versionMajor: state.version.major ?? 1,
                    versionMinor: state.version.minor ?? 0,
                });

                await activateEditionSetInEnv({
                    editionSetCodes: [response.code],
                    envStage: values?.envType as 'development' | 'staging',
                    envName: values?.envName as string,
                    daysUntilExpiration: values?.expireInDays as number,
                });
                setIsLoading(false);
                navigate(getEditionSetUrl(productCode, response.code));
            } catch (error) {
                setIsLoading(false);
                const err =
                    'response' in error ? (error.response?.data?.message as string) : 'Oops! Something went wrong.';
                setErrorMessage(err);
                if (response?.code != null) void archiveEditionSet(response.code);

                throw new Error(err);
            }
        },
        clear: () => {},
        isNextDisabled: (values, step) => {
            switch (step) {
                case TestInEnvStep.DefineEditionSet:
                    return (
                        !values.editions.isValid ||
                        !values.effectiveDates.isValid ||
                        !values.version.isValid ||
                        values.versionType == null
                    );
                case TestInEnvStep.SelectEnvironment:
                    return !valid;
                default:
                    return true;
            }
        },
        initialData: {
            editions: {
                selectedEditions: {
                    coveragesEditionCode: editionType === EditionType.Coverages ? editionCode : null,
                    digitalAgentEditionCode: editionType === EditionType.DigitalAgent ? editionCode : null,
                    underwritingFiltersEditionCode:
                        editionType === EditionType.UnderwritingFilters ? editionCode : null,
                    ratingEditionCode: editionType === EditionType.Rating ? editionCode : null,
                },
                selectedPlatformSchema: null,
                selectedProductSchema: null,
                isValid: false,
            },
            effectiveDates: {
                newBusiness: now,
                renewals: now,
                isValid: true,
            },
            versionType: null,
            version: {
                major: null,
                minor: null,
                isValid: false,
            },
        },
        initialStep: TestInEnvStep.DefineEditionSet,
    });

    const { isLoading: isLatestMinorsLoading } = useGetLatestMinorEditionSets(productCode, latestMinors => {
        const latestMinor =
            latestMinors.length > 0
                ? latestMinors.reduce<EditionSet>((acc, curr) => {
                      if (
                          curr.versionMajor != null &&
                          acc.versionMajor != null &&
                          curr.versionMajor > acc.versionMajor
                      ) {
                          return curr;
                      }

                      return acc;
                  }, latestMinors[0])
                : null;

        function getSelectedEdition(type: EditionType): string | null {
            if (type === editionType) {
                return editionCode;
            }

            switch (type) {
                case EditionType.Coverages:
                    return latestMinor?.coveragesEdition.code ?? null;
                case EditionType.DigitalAgent:
                    return latestMinor?.digitalAgentEdition.code ?? null;
                case EditionType.UnderwritingFilters:
                    return latestMinor?.underwritingFiltersEdition.code ?? null;
                case EditionType.Rating:
                    return latestMinor?.ratingEdition.code ?? null;
                default:
                    return null;
            }
        }
        if (latestMinor != null) {
            dispatch({
                type: 'editions',
                value: {
                    selectedEditions: {
                        coveragesEditionCode: getSelectedEdition(EditionType.Coverages),
                        digitalAgentEditionCode: getSelectedEdition(EditionType.DigitalAgent),
                        underwritingFiltersEditionCode: getSelectedEdition(EditionType.UnderwritingFilters),
                        ratingEditionCode: getSelectedEdition(EditionType.Rating),
                    },
                    selectedPlatformSchema: latestMinor.platformSchemaRevision.toString(),
                    selectedProductSchema: latestMinor.productSchemaRevision.toString(),
                    isValid: true,
                },
            });
            dispatch({
                type: 'effectiveDates',
                value: {
                    newBusiness: new Date(latestMinor.newBusinessEffectiveAt),
                    renewals: new Date(latestMinor.renewalEffectiveAt),
                    isValid: true,
                },
            });
            dispatch({
                type: 'version',
                value: {
                    major: latestMinor.versionMajor != null ? latestMinor.versionMajor + 1 : 1,
                    minor: 0,
                    isValid: latestMinor.versionMajor != null,
                },
            });
        }
    });
    const steps = useMemo(
        () => [
            {
                title: 'Define Edition Set',
                body: (
                    <DefineEditionSetStep
                        isLatestMinorsLoading={isLatestMinorsLoading}
                        onChange={payload => dispatch(payload)}
                        productCode={productCode}
                        state={state}
                    />
                ),
                id: TestInEnvStep.DefineEditionSet,
            },
            {
                title: 'Select Environment',
                body: (
                    <Flex px={spacing.s64} py={spacing.s40}>
                        <TestInStagingForm texts={TEXTS} />
                    </Flex>
                ),
                id: TestInEnvStep.SelectEnvironment,
            },
        ],
        [dispatch, isLatestMinorsLoading, productCode, state]
    );

    return (
        <StyledDialog
            actions={actions}
            error={errorMessage}
            loading={isLoading}
            minWidth="600px"
            title="Test Edition In Environment"
        >
            <StyledMiniFlow activeStepIndex={steps.findIndex(step => step.id === currentStep)} steps={steps} />
        </StyledDialog>
    );
};

export const TestInEnvironmentProvider: React.FC<TestInEnvironmentDialogProps> = ({
    editionCode,
    editionType,
    productCode,
    onClose,
}) => {
    return (
        <FormProvider
            initialConfig={{ showErrorsOnBlur: true }}
            initialValues={{ expireInDays: 5 }}
            schema={testInEnvSchema}
        >
            <TestInEnvironmentDialog
                editionCode={editionCode}
                editionType={editionType}
                onClose={onClose}
                productCode={productCode}
            />
        </FormProvider>
    );
};
