import { Maybe } from '@lemonade-hq/ts-helpers';
import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';

const carBlenderTasksUrlResolver = getUrlResolver('car-blender', '/api/v1');
const blenderGeneralUrlResolver = getUrlResolver('blender-general', '/api/v1');

export interface AddWorkflowTaskParams {
    claimPublicId: string;
    taskType: string;
    referenceId?: string;
}

export interface AddWorkflowCustomTaskParams {
    referenceType: string;
    groupPublicId: string;
    title: string;
    operatorPublicId: Maybe<string>;
    actionDate: string;
    description: string;
    forcedSquad?: string;
}

export interface AddWorkflowLitigationTaskParams {
    entityPublicId: string;
    entityType: string;
    title: string;
    referenceType: string;
}

export async function addCarWorkflowsTask({ data }: { data: AddWorkflowTaskParams }): Promise<void> {
    const url = carBlenderTasksUrlResolver('/tasks');

    return axios.post(url, data);
}

export async function addCarWorkflowsCustomTask({ data }: { data: AddWorkflowCustomTaskParams }): Promise<void> {
    const url = blenderGeneralUrlResolver('/custom_tasks');

    return axios.post(url, data);
}

export async function addCarWorkflowsLitigationTask({
    data,
}: {
    data: AddWorkflowLitigationTaskParams;
}): Promise<void> {
    const url = blenderGeneralUrlResolver('/task_groups');

    return axios.post(url, data);
}
