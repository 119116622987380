/* eslint-disable jsx-a11y/media-has-caption, @typescript-eslint/no-non-null-assertion,  react/function-component-definition */
import type { PropsWithChildren, ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useAudioPlayer } from './AudioPlayer.Provider';
import type { AudioPlayerPresentedSegment, AudioPlayerSegment } from './AudioPlayer.SegmentsProvider.types';
import { AudioPlayerPresentedSegmentType } from './AudioPlayer.SegmentsProvider.types';

export type AudioPlayerSegmentPresenter<TSegment extends AudioPlayerSegment> = (
  segment: TSegment,
  index: number,
) => AudioPlayerPresentedSegment;

export interface AudioPlayerSegmentsProviderProps<TSegment extends AudioPlayerSegment = AudioPlayerSegment> {
  readonly segments: readonly TSegment[];
  readonly segmentPresenter?: AudioPlayerSegmentPresenter<NoInfer<TSegment>>;

  // a background segment is a segment that should never be considered as a current segment, but can still be navigated too
  readonly isBackgroundSegment?: (segment: TSegment) => boolean;
}

export interface AudioPlayerSegmentsContextProps<TSegment extends AudioPlayerSegment = AudioPlayerSegment> {
  readonly segments: readonly TSegment[];
  readonly segmentPresenter?: AudioPlayerSegmentPresenter<TSegment>;
  readonly currentSegmentIndex: number;
  readonly currentSegment: TSegment | undefined;
  readonly canGoNext: boolean;
  readonly canGoBack: boolean;
  readonly goToSegmentIndex: (index: number) => void;
  readonly goToNextSegment: () => void;
  readonly goToPrevSegment: () => void;
}

export const DEFAULT_AUDIO_PLAYER_SEGMENTS_CONTEXT = {
  segments: [],
  segmentPresenter: () => ({ type: AudioPlayerPresentedSegmentType.Audio }),
  currentSegmentIndex: -1,
  currentSegment: undefined,
  canGoNext: false,
  canGoBack: false,
  goToSegmentIndex: () => {},
  goToNextSegment: () => {},
  goToPrevSegment: () => {},
};

export const AudioPlayerSegmentsContext = createContext<AudioPlayerSegmentsContextProps | null>(
  DEFAULT_AUDIO_PLAYER_SEGMENTS_CONTEXT,
);

export function AudioPlayerSegmentsProvider<TSegment extends AudioPlayerSegment = AudioPlayerSegment>({
  children,
  segments,
  segmentPresenter,
  isBackgroundSegment,
}: PropsWithChildren<AudioPlayerSegmentsProviderProps<TSegment>>): ReactNode {
  const { currentTime, seek: seekAudio } = useAudioPlayer();

  const currentSegmentIndex = useMemo(
    () => segments.findLastIndex(segment => currentTime >= segment.startedAt && !isBackgroundSegment?.(segment)),
    [currentTime, segments, isBackgroundSegment],
  );
  const currentSegment = useMemo(() => segments[currentSegmentIndex], [segments, currentSegmentIndex]);
  const canGoNext = currentSegmentIndex < segments.length - 1;
  const canGoBack = currentSegmentIndex > -1; // allow -1 since the first segment might not be at timestamp 0.

  const goToSegmentIndex = useCallback(
    (index: number) => {
      if (index === -1) {
        seekAudio(0);
      } else if (index >= 0 && index < segments.length) {
        seekAudio(segments[index].startedAt);
      }
    },
    [seekAudio, segments],
  );

  const goToNextSegment = useCallback(() => {
    goToSegmentIndex(currentSegmentIndex + 1);
  }, [goToSegmentIndex, currentSegmentIndex]);

  const goToPrevSegment = useCallback(() => {
    goToSegmentIndex(currentSegmentIndex - 1);
  }, [goToSegmentIndex, currentSegmentIndex]);

  const value = useMemo(
    () => ({
      segments,
      segmentPresenter,
      currentSegmentIndex,
      currentSegment,
      canGoBack,
      canGoNext,
      goToNextSegment,
      goToPrevSegment,
      goToSegmentIndex,
    }),
    [
      segments,
      segmentPresenter,
      currentSegmentIndex,
      currentSegment,
      canGoBack,
      canGoNext,
      goToNextSegment,
      goToPrevSegment,
      goToSegmentIndex,
    ],
  );

  return <AudioPlayerSegmentsContext.Provider value={value}>{children}</AudioPlayerSegmentsContext.Provider>;
}

export function useAudioPlayerSegments<
  TSegment extends AudioPlayerSegment = AudioPlayerSegment,
>(): AudioPlayerSegmentsContextProps<TSegment> {
  const context = useContext(AudioPlayerSegmentsContext) as AudioPlayerSegmentsContextProps<TSegment> | null;

  if (context == null) {
    throw new Error('useAudioPlayerSegments must be used within a AudioPlayerSegmentsProvider');
  }

  return context;
}
