import { Flex, Select, spacing, Text } from '@lemonade-hq/blender-ui';
import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useMemo } from 'react';
import type { FC } from 'react';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { UpdateDigitalAgentEditionViewPreferenceParams } from 'queries/LoCo/Insurance/DigitalAgentEditionQueries';
import { useGetDigitalAgentViewPreference } from 'queries/LoCo/Insurance/DigitalAgentEditionQueries';
import { useGetProductEditionsSummary } from 'queries/LoCo/Insurance/ProductQueries';

interface CoveragesEditionPreviewSelectorProps {
    readonly digitalAgentEditionCode: string;
    readonly productCode: string;

    readonly updateDigitalAgentViewPreference: UseMutateAsyncFunction<
        void,
        unknown,
        UpdateDigitalAgentEditionViewPreferenceParams,
        null
    >;

    readonly isPending: boolean;
}

export const CoveragesEditionPreviewSelector: FC<CoveragesEditionPreviewSelectorProps> = ({
    digitalAgentEditionCode,
    productCode,
    isPending,
    updateDigitalAgentViewPreference,
}) => {
    const { data: digitalAgentViewPreference, isLoading: isDigitalAgentViewPreferenceLoading } =
        useGetDigitalAgentViewPreference(digitalAgentEditionCode);

    const { data: coveragesEditionsSummary, isLoading: isLoadingCoveragesEditionsSummary } =
        useGetProductEditionsSummary(productCode, { editionContentType: EditionType.Coverages });

    const sortedEditions = useMemo(() => {
        return coveragesEditionsSummary?.coveragesEditions.sort(
            (a, b) => new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime()
        );
    }, [coveragesEditionsSummary]);

    return (
        <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Text type="h5">Configure Coverages Business Rules</Text>
            <Flex alignItems="center" gap={spacing.s24}>
                <Text type="h6">Validate against</Text>
                <Select
                    disabled={isPending || isDigitalAgentViewPreferenceLoading || isLoadingCoveragesEditionsSummary}
                    onChange={async coveragesEditionCode => {
                        if (coveragesEditionCode !== null)
                            await updateDigitalAgentViewPreference({ digitalAgentEditionCode, coveragesEditionCode });
                    }}
                    options={
                        sortedEditions?.map(({ code, status, friendlyName }) => ({
                            value: code,
                            label: `${friendlyName} (${status})`,
                        })) ?? []
                    }
                    placeholder="Select Coverages Edition"
                    selectedKey={digitalAgentViewPreference?.coveragesEditionCode}
                    style={{ width: '240px' }}
                />
            </Flex>
        </Flex>
    );
};
