export interface EventEmitterEvent {
  readonly type: string;
}

export class EventEmitter<TEvent extends EventEmitterEvent> {
  private readonly subscriptions: Map<TEvent['type'], Set<(event: TEvent) => void>> = new Map();

  subscribe<TType extends TEvent['type']>(
    event: TType,
    callback: (event: Extract<TEvent, { type: TType }>) => void,
  ): void {
    this.subscriptions.set(event, this.subscriptions.get(event)?.add(callback) ?? new Set([callback]));
  }

  unsubscribe<TType extends TEvent['type']>(
    event: TType,
    callback: (event: Extract<TEvent, { type: TType }>) => void,
  ): void {
    this.subscriptions.get(event)?.delete(callback);
  }

  emit(event: TEvent): void {
    this.subscriptions.get(event.type)?.forEach(callback => callback(event));
  }
}
