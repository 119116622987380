import { Icon } from '@lemonade-hq/blender-ui';
import {
    Alert,
    AlertMode,
    AttachmentType,
    CloseIcon,
    Dialog,
    ExportArrowIcon,
    getTypeFromContentType,
    IconButton,
} from '@lemonade-hq/bluis';
import { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent, useEntityPageData } from '@lemonade-hq/boutique';
import { CommonLayer, ellipsis, Flex, Text, themedColor } from '@lemonade-hq/cdk';
import { useFlag } from '@lemonade-hq/flags';
import { COLORS } from '@lemonade-hq/tokens';
import { capitalize, snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { CarouselMenuProps } from '../Carousel/CarouselMenu';
import CarouselMenu from '../Carousel/CarouselMenu';
import { AttachmentPreviewItem, ThumbnailItem } from './AttachmentPreviewItem';
import { ComparePreview } from './ComparePreview';
import { getContextByURL, getInlineUrl } from './utils';
import type { ScanFraudError, ScanStatusResult } from 'apis/FraudAPI';
import { anyAttachmentToAttachment, getDisplayType, getRaiDetectionResult, isFraud } from 'commons/AttachmentsUtils';
import Carousel from 'components/Bluis/Carousel';
import { CarouselProvider, useCarousel } from 'components/Bluis/Carousel/CarouselContext';
import CarouselScroller from 'components/Bluis/Carousel/CarouselScroller';
import type { Attachment } from 'models/Attachment';
import { useScanFraudMutation, useScanStatusQuery } from 'queries/FraudQueries';

export const StyleAttachmentsPreview = styled(Flex)`
    flex: 1;
    overflow: hidden;
`;

const StyledTooltipContent = styled.span`
    color: ${themedColor('tooltipText')};
    font-size: 12px;
`;

const StyledTooltipNumber = styled.span`
    padding-right: 10px;
    opacity: 0.5;
`;

const HeaderContent = styled(Flex)`
    padding: 22px 10px;
    color: ${themedColor('mainButtonText')};
    gap: 12px;
    justify-content: space-between;
    align-items: center;
`;

const StyledHeaderTypeLabel = styled(Text).attrs({ variant: 'label-sm' })`
    border-right: 1px solid ${rgba(COLORS.white, 0)};
    ${ellipsis}
`;

const StyledHeaderNameLabel = styled(Text).attrs({ variant: 'label-md' })`
    border-right: 1px solid ${rgba(COLORS.white, 0)};
    ${ellipsis}
`;

export const StyledPreviewHeader = styled.div`
    && {
        position: relative;
        color: ${themedColor('mainButtonText')};
        background-image: linear-gradient(180deg, rgba('#191919', 0), rgba((25, 25, 25), 0) 100%);
        flex: 0;
    }
`;

const StyledActionsContainer = styled(Flex)`
    align-items: center;
    gap: 12px;

    & > *:not(:first-child) {
        border-left: 1px solid white;
        padding-left: 12px;
    }
`;

const StyledIconButton = styled(IconButton)`
    display: flex;
    align-items: center;
    min-width: 0;
    height: 24px;
    width: auto;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
    color: ${themedColor('mainButtonText')};
    transition: all 0.2s ease;

    & path {
        stroke: ${themedColor('mainButtonText')};
    }

    &:not(:first-child) {
        border-left: 1px solid ${rgba(COLORS.white, 0.1)};
        padding-left: 12px;
    }
`;

const ScanText = styled(Text)`
    display: flex;
    text-transform: none;
`;

export const StyledCloseButton = styled(StyledIconButton)`
    & rect {
        stroke: none;
        fill: ${themedColor('mainButtonText')};
    }
`;

const StyledAlert = styled(Alert)<{ readonly background: string }>`
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 350px;
    padding: 6px 5px 6px 10px;
    background: ${({ background }) => background};

    & > span {
        width: 100%;
    }
`;

const ToolTipContent = styled.span`
    ${ellipsis}
`;

const RaiLink = styled.a`
    color: ${COLORS.white};
    text-decoration: underline;
    cursor: pointer;
`;

const ErrorDialog = styled(Dialog)`
    position: relative;
    z-index: ${CommonLayer.DialogForeground + 2};
`;

function getFraudAlertText(attachment: Attachment, isScanning: boolean) {
    const raiDetectionResult = getRaiDetectionResult(attachment);
    const contextParams = getContextByURL();

    function trackClick() {
        trackEvent('resistant_ai_fraud_score_info.clicked', {
            attachment_id: attachment.file_public_id,
            attachment_type: attachment.type,
            score: snakeCaseToReadable(raiDetectionResult?.score ?? ''),
            ...contextParams,
        });
    }
    if (isScanning) {
        return (
            <Flex justifyContent="space-between" width="100%">
                <b>Scanning documents.</b>
                <span>This can take up to 30 sec</span>
            </Flex>
        );
    }

    if (raiDetectionResult?.score != null) {
        return (
            <Flex justifyContent="space-between" width="100%">
                <span>
                    Integrity score: <b>{snakeCaseToReadable(raiDetectionResult.score.toLowerCase())}</b>
                </span>
                <RaiLink href={raiDetectionResult.externalViewUrl} onClick={trackClick} target="_blank">
                    See more details
                </RaiLink>
            </Flex>
        );
    }

    return 'It looks like this document may have been modified by the user.';
}

function getFraudAlertBG(attachment: Attachment, isScanning: boolean): string {
    if (isScanning) {
        return COLORS.doveGray;
    }

    const raiDetectionResult = getRaiDetectionResult(attachment);

    if (raiDetectionResult != null) {
        switch (raiDetectionResult.score) {
            case 'NORMAL':
                return COLORS.doveGray;
            case 'TRUSTED':
                return COLORS.cuttySark;
            case 'HIGH_RISK':
                return COLORS.falcon;
            case 'WARNING':
                return COLORS.falcon;
            default:
                return COLORS.falcon;
        }
    }

    return COLORS.falcon;
}

function getAlertMode(attachment: Attachment, isScanning: boolean): AlertMode {
    if (isScanning) {
        return AlertMode.Info;
    }

    const raiDetectionResult = getRaiDetectionResult(attachment);

    if (raiDetectionResult != null) {
        switch (raiDetectionResult.score) {
            case 'NORMAL':
                return AlertMode.Info;
            case 'TRUSTED':
                return AlertMode.Success;
            case 'HIGH_RISK':
                return AlertMode.Attention;
            case 'WARNING':
                return AlertMode.Attention;
            default:
                return AlertMode.Attention;
        }
    }

    return AlertMode.Attention;
}

enum ErrorType {
    ResistantAILimitExceededException = 'ResistantAILimitExceededException',
    Default = 'Default',
}

const errorsMap = {
    [ErrorType.ResistantAILimitExceededException]: {
        title: 'Quota limit reached',
        message:
            'Document cannot be scanned right now due to quota limits. Please discuss next steps with your team lead',
    },

    [ErrorType.Default]: {
        title: 'Error',
        message: 'Oops, something went wrong',
    },
};

const ALLOWED_ENTITIES = [EntityTypes.HomeClaim];

type PreviewHeaderProps = {
    readonly attachments: Attachment[];
    readonly onClose: () => void;
    readonly entityPublicId: string;
    readonly onScanStart: (attachmentId: string) => void;
    readonly onError: (errorType: ErrorType) => void;
    readonly scanningIds: string[];
};

const PreviewHeader: React.FC<PreviewHeaderProps> = ({ attachments, onClose, onScanStart, onError, scanningIds }) => {
    const isManualScanEnabled = useFlag('ENABLE_MANUAL_FRAUD_SCAN');
    const { publicId: entityPublicId, entityType, productType, countryCode } = useEntityPageData() ?? {};
    const { mutateAsync: scanForFraudMutation } = useScanFraudMutation();
    const { selectedIndex } = useCarousel();

    const attachment = attachments[selectedIndex];

    const contentType = useMemo(() => getTypeFromContentType(attachment.content_type), [attachment.content_type]);

    const isScanAllowed = useMemo(
        () =>
            isManualScanEnabled &&
            entityType != null &&
            ALLOWED_ENTITIES.includes(entityType as EntityTypes) &&
            attachment.id != null &&
            attachment.id !== 'policy_pdf' &&
            contentType != null &&
            [AttachmentType.Doc, AttachmentType.Image, AttachmentType.Pdf].includes(contentType),
        [attachment.id, contentType, entityType, isManualScanEnabled]
    );

    const attachmentHasFraud = useMemo(() => isFraud(attachment), [attachment]);
    const raiDetectionResult = getRaiDetectionResult(attachment);

    const trackOnClick = (actionName: string) => {
        const pathArray = window.location.pathname.split('/');

        trackEvent('attachment_gallery_action.clicked', {
            action: actionName,
            attachment_id: String(attachment.id),
            attachment_type: attachment.type,
            fraud_score: raiDetectionResult?.score ?? '',
            entity_id: pathArray.at(4) ?? '',
            context: pathArray.at(5) ?? '',
        });
    };

    const handleScanClick = async () => {
        trackOnClick('scan suspicious document');

        if (attachment.id != null && entityPublicId != null && entityType != null) {
            try {
                await scanForFraudMutation({
                    attachmentPublicId: attachment.originEntityPublicId ?? String(attachment.id),
                    entityPublicId,
                    productLine: productType === 'home' ? `home_${countryCode === 'US' ? 'us' : 'eu'}` : undefined,
                    ...(attachment.fraud_detection_identifier != null
                        ? { filePublicId: attachment.fraud_detection_identifier }
                        : {
                              fileUrl: attachment.download_url,
                          }),
                });

                onScanStart(String(attachment.id));
            } catch (error) {
                onError((error?.response?.data as ScanFraudError).data?.type as ErrorType);
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (attachment == null) return null;

    const isScanning = scanningIds.find(id => id === String(attachment.id)) != null;

    return (
        <StyledPreviewHeader>
            <HeaderContent>
                <Flex flexDirection="column" gap="5px">
                    <StyledHeaderTypeLabel>
                        <span>{capitalize(getDisplayType(attachment.type))}</span>
                    </StyledHeaderTypeLabel>
                    {attachment.original_filename && (
                        <StyledHeaderNameLabel>{attachment.original_filename}</StyledHeaderNameLabel>
                    )}
                </Flex>
                <StyledActionsContainer>
                    {isScanAllowed && raiDetectionResult == null && !isScanning && (
                        <Flex>
                            <StyledIconButton onClick={handleScanClick}>
                                <ScanText variant="label-md">Scan suspicious document</ScanText>
                            </StyledIconButton>
                        </Flex>
                    )}
                    <StyledIconButton
                        as="a"
                        download={attachment.original_filename}
                        href={attachment.download_url.replace(/\?inline=true$/, '')}
                        onClick={() => trackOnClick('download')}
                    >
                        <Icon name="download" />
                    </StyledIconButton>
                    <StyledIconButton
                        as="a"
                        href={getInlineUrl(attachment.download_url)}
                        onClick={() => trackOnClick('open in new tab')}
                        target="_blank"
                    >
                        <ExportArrowIcon />
                    </StyledIconButton>
                    <StyledCloseButton onClick={onClose}>
                        <CloseIcon />
                    </StyledCloseButton>
                </StyledActionsContainer>
            </HeaderContent>
            {(isScanning || attachmentHasFraud || raiDetectionResult?.score != null) && (
                <StyledAlert
                    background={getFraudAlertBG(attachment, isScanning)}
                    mode={getAlertMode(attachment, isScanning)}
                    noBackground
                    title={getFraudAlertText(attachment, isScanning)}
                />
            )}
        </StyledPreviewHeader>
    );
};

export interface AttachmentsPreviewInfoPanel {
    readonly active: boolean;
    readonly timezone: string;
}

export interface AttachmentsPreviewProps {
    readonly attachments: Attachment[];
    readonly selectedIndex: number;
    readonly onClose: () => void;
    readonly onNavigation?: (index: number) => void;
    readonly attachmentTooltipProperty?: string;
    readonly infoPanel?: AttachmentsPreviewInfoPanel;
}

function raiDetectionResult(attachment: Attachment) {
    return getRaiDetectionResult(attachment);
}

export type GetCarouselMenuProps = Omit<CarouselMenuProps, 'attachment'>;
export const CarouselPreview: React.FC<React.PropsWithChildren<AttachmentsPreviewProps>> = ({
    attachments,
    onClose,
    selectedIndex = 0,
    onNavigation,
    attachmentTooltipProperty,
    infoPanel,
}) => {
    const isManualScanEnabled = useFlag('ENABLE_MANUAL_FRAUD_SCAN');
    const [scanningIds, setScanningIds] = useState<string[]>(
        isManualScanEnabled
            ? (attachments
                  .filter(attachment =>
                      attachment.fraudDetections?.some(detection => detection.detectionStatus === 'processing')
                  )
                  .map(({ id }) => id) as string[])
            : []
    );
    const [errorType, setErrorType] = useState<ErrorType | null>(null);

    const { data: scanStatuses, error } = useScanStatusQuery(
        scanningIds,
        data => {
            if (data != null) {
                console.log(error);
                const statusesMap = data
                    .filter(status => status.detectionModel === 'resistant_ai')
                    .reduce<Record<string, ScanStatusResult | undefined>>(
                        (acc, curr) => ({
                            ...acc,
                            [curr.attachmentPublicId]: curr,
                        }),
                        {}
                    );

                setScanningIds(currIds => currIds.filter(id => statusesMap[id]?.detectionStatus !== 'completed'));
            }
        },
        () => {
            setScanningIds([]);
            setErrorType(ErrorType.Default);
        }
    );

    useEffect(() => {
        if (scanStatuses) {
            const statusesMap = scanStatuses
                .filter(status => status.detectionModel === 'resistant_ai')
                .reduce<Record<string, ScanStatusResult | undefined>>(
                    (acc, curr) => ({
                        ...acc,
                        [curr.attachmentPublicId]: curr,
                    }),
                    {}
                );

            setScanningIds(currIds => currIds.filter(id => statusesMap[id]?.detectionStatus !== 'completed'));
        }
    }, [scanStatuses]);

    const updateAttachments = useMemo(
        () =>
            attachments.map(attachment => {
                const currentStatus = scanStatuses?.find(status => status.attachmentPublicId === attachment.id);

                return {
                    ...attachment,
                    fraudDetections: currentStatus != null ? [currentStatus] : attachment.fraudDetections,
                } as Attachment;
            }),
        [attachments, scanStatuses]
    );

    const handleScanStart = useCallback((attachmentId: string) => setScanningIds(curr => [...curr, attachmentId]), []);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        },
        [onClose]
    );

    const getCarouselMenu = useCallback(
        (props: GetCarouselMenuProps): JSX.Element => {
            const type = getTypeFromContentType(updateAttachments[props.selectedIndex]?.content_type);

            const fraudArray = updateAttachments.map(attachment => isFraud(attachment));

            return (
                <CarouselMenu
                    {...props}
                    attachment={anyAttachmentToAttachment(
                        updateAttachments[props.selectedIndex] as unknown as Record<string, string>
                    )}
                    fraudArray={fraudArray}
                    infoPanel={infoPanel}
                    zoomable={type === 'image'}
                />
            );
        },
        [updateAttachments, infoPanel]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const handleError = (errorType: ErrorType | undefined) => {
        setErrorType(errorType ?? ErrorType.Default);
    };

    const handleErrorDialogClose = () => setErrorType(null);

    return (
        <>
            <CarouselProvider index={selectedIndex} length={updateAttachments.length}>
                <StyleAttachmentsPreview flexDirection="column">
                    <PreviewHeader
                        attachments={updateAttachments}
                        entityPublicId=""
                        onClose={onClose}
                        onError={handleError}
                        onScanStart={handleScanStart}
                        scanningIds={scanningIds}
                    />
                    <Carousel getCarouselMenu={getCarouselMenu} onNavigation={onNavigation}>
                        {updateAttachments.map(attachment =>
                            isFraud(attachment) || raiDetectionResult(attachment) != null ? (
                                <ComparePreview attachment={attachment} key={attachment.file_public_id} />
                            ) : (
                                <AttachmentPreviewItem attachment={attachment} key={attachment.file_public_id} />
                            )
                        )}
                    </Carousel>
                </StyleAttachmentsPreview>
                <CarouselScroller>
                    {updateAttachments.map((attachment, index) => (
                        <ThumbnailItem
                            attachment={attachment}
                            key={`${attachment.file_public_id}-thumb`}
                            tooltipContent={
                                <StyledTooltipContent>
                                    <StyledTooltipNumber>{index + 1}</StyledTooltipNumber>
                                    <ToolTipContent>
                                        <span>
                                            {capitalize(
                                                snakeCaseToReadable(
                                                    getDisplayType(attachment[attachmentTooltipProperty ?? 'type'])
                                                )
                                            )}
                                        </span>
                                        {attachment.original_filename && ` - ${attachment.original_filename}`}
                                    </ToolTipContent>
                                </StyledTooltipContent>
                            }
                        />
                    ))}
                </CarouselScroller>
            </CarouselProvider>
            {errorType != null && (
                <ErrorDialog
                    actions={[{ text: 'Close', type: 'close', onClick: handleErrorDialogClose }]}
                    onClose={handleErrorDialogClose}
                    title={errorsMap[errorType].title}
                >
                    {errorsMap[errorType].message}
                </ErrorDialog>
            )}
        </>
    );
};
