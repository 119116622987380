// TODO remove once Home uses files-service and pet and car updated their responses
export const getInlineUrl = (url?: string | null): string | undefined => {
    if (
        url?.startsWith('https://files-') ||
        (url?.startsWith('https://icebox.lemonade') && !url.endsWith('?inline=true'))
    ) {
        if (url.endsWith('?inline=false')) {
            return url.replace('inline=false', 'inline=true');
        }

        return `${url}?inline=true`;
    }

    return url ?? undefined;
};

export const getContextByURL = () => {
    const pathArray = window.location.pathname.split('/');

    return {
        context: pathArray[5],
        entity_id: pathArray[4],
        product: pathArray[2],
    };
};
