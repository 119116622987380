import type { HTMLAttributes, PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import { Flex } from '../../base/Flex/Flex';
import type { LayoutProps } from '../../base/Layout/Layout';
import { Layout } from '../../base/Layout/Layout';
import { spacing } from '../../theme/spacing.css';
import * as styles from './Form.css';

export type FormLayoutProps = HTMLAttributes<HTMLDivElement> &
  Pick<LayoutProps, 'as' | 'mb' | 'mt' | 'pb' | 'pt'> &
  PropsWithChildren;
export type FormInputGroupProps = PropsWithChildren;
export type FormInputColumnsProps = PropsWithChildren & { readonly gridTemplateColumns?: string };

export const FormLayout = forwardRef<HTMLDivElement, FormLayoutProps>(
  ({ children, ...props }: FormLayoutProps, ref) => {
    return (
      <Flex flexDirection="column" gap={spacing.s20} ref={ref} {...props}>
        {children}
      </Flex>
    );
  },
);

export const FormInputGroup = forwardRef<HTMLDivElement, FormInputGroupProps>(
  ({ children }: FormInputGroupProps, ref) => {
    return (
      <Layout className={styles.formInputGroup} ref={ref}>
        {children}
      </Layout>
    );
  },
);

export const FormInputColumns = forwardRef<HTMLDivElement, FormInputColumnsProps>(
  ({ children, gridTemplateColumns = 'repeat(1fr)' }: FormInputColumnsProps, ref) => {
    return (
      <Layout className={styles.formInputGroupColumns} ref={ref} style={{ gridTemplateColumns }}>
        {children}
      </Layout>
    );
  },
);
