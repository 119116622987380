export enum Gender {
    Female = 'female',
    Male = 'male',
    Unknown = 'unknown',
}

export const GenderString: Record<Gender, string> = {
    [Gender.Female]: 'Female (F)',
    [Gender.Male]: 'Male (M)',
    [Gender.Unknown]: 'Undefined (X)',
};

export enum MaritalStatus {
    Married = 'married',
    Single = 'single',
    Widowed = 'widowed',
}

export enum Role {
    Primary = 'primary',
    Parent = 'parent',
    Spouse = 'spouse',
    Child = 'child',
    Sibling = 'sibling',
    Relative = 'relative',
    Other = 'other',
    Unknown = 'unknown',
}

export enum Type {
    PrimaryNamedInsured = 'primary_named_insured',
    SecondaryNamedInsured = 'secondary_named_insured',
    AdditionalDriver = 'additional_driver',
}

export const InsuredTypes = {
    ...Type,
};

export enum Status {
    Included = 'included',
    Excluded = 'excluded',
}

export type TypeWithExcluded = Status.Excluded | Type;

export enum LicenseStatus {
    Valid = 'valid',
    LearnersPermit = 'learners_permit',
    Foreign = 'foreign',
    NotValid = 'not_valid',
}

export enum DriverTelematicsStatus {
    NotEnrolled = 'not_enrolled',
    Active = 'active',
    Pending = 'pending',
    Inactive = 'inactive',
}

export enum DriverReportStatus {
    NotApplicable = 'NotApplicable',
    Reported = 'Reported',
    Discontinued = 'Discontinued',
}

export interface Driver {
    readonly publicId: string;
    readonly userPublicId?: string;
    readonly licenseId?: string;
    readonly licenseIssueDate: string;
    readonly licenseState?: string;
    readonly licenseStatus?: LicenseStatus;
    readonly firstName: string;
    readonly lastName: string;
    readonly email?: string;
    readonly phoneNumber?: string;
    readonly dateOfBirth: string;
    readonly language: string;
    readonly gender: Gender;
    readonly role: Role;
    readonly type: Type;
    readonly status: Status;
    readonly maritalStatus: MaritalStatus;
    readonly matureDriverCourse?: boolean;
    readonly matureDriverCourseYearsAgo?: number;
    readonly addedAt: string;
    readonly sr22ReportStatus: DriverReportStatus;
}

export interface AdditionalPolicyDetails {
    readonly policyPublicId: string;
    readonly quotePublicId: string;
}

export interface DriverEditData {
    readonly driverPublicId: string;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly birthdate?: Date;
    readonly licenseIssueDate?: Date;
    readonly licenseState?: string | null;
    readonly licenseId?: string | null;
    readonly maritalStatus?: MaritalStatus;
    readonly licenseStatus?: LicenseStatus;
    readonly gender?: Gender;
    readonly role?: Role;
    readonly type?: Type;
    readonly additionalPolicies?: AdditionalPolicyDetails[];
}

export const DriverEditSaveDataKeys: (keyof DriverEditData)[] = [
    'driverPublicId',
    'firstName',
    'lastName',
    'birthdate',
    'licenseIssueDate',
    'licenseState',
    'licenseId',
    'additionalPolicies',
];
