import type { Milliseconds } from '@lemonade-hq/ts-helpers';
import { AudioSource } from './AudioSource';

interface MicrophoneSourceProps {
  readonly chunkSize?: Milliseconds;
  readonly fftSize?: number;
  readonly minDecibels?: number;
  readonly maxDecibels?: number;
  readonly smoothingTimeConstant?: number;
  readonly noiseFloor?: number;
  readonly normalizationFactor?: number;
}

export class MicrophoneAudioSource extends AudioSource {
  private stream?: MediaStream;
  private audioContext?: AudioContext;
  private analyzer?: AnalyserNode;
  private recorder?: MediaRecorder;
  private readonly analyzerProps?: MicrophoneSourceProps;

  constructor(
    private readonly props?: MicrophoneSourceProps,
    private readonly onError?: (e: unknown) => void,
  ) {
    super();
  }

  async start(): Promise<void> {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.audioContext = new AudioContext();

      const source = this.audioContext.createMediaStreamSource(this.stream);

      this.recorder = new MediaRecorder(this.stream);
      this.recorder.ondataavailable = event => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.emit({ type: 'media', data: reader.result as string });
        };

        reader.readAsDataURL(event.data);
      };

      this.recorder.start(this.props?.chunkSize ?? 500);
      this.startVolumeAnalysis(source);
      this.emit({ type: 'start' });
      this.isPlaying = true;
    } catch (error) {
      this.onError?.(error);
    }
  }

  async stop(): Promise<void> {
    this.isPlaying = false;
    this.emit({ type: 'volume', volume: 0 });
    this.emit({ type: 'stop' });
    this.stream?.getTracks().forEach(track => track.stop());
    this.recorder?.stop();
    await this.audioContext?.close();
    this.stream = undefined;
    this.audioContext = undefined;
    this.analyzer = undefined;
    this.recorder = undefined;
  }

  private startVolumeAnalysis(source: MediaStreamAudioSourceNode): void {
    if (this.audioContext == null) return;

    this.analyzer = this.audioContext.createAnalyser();
    this.analyzer.fftSize = this.props?.fftSize ?? 1024;
    this.analyzer.minDecibels = this.props?.minDecibels ?? -90;
    this.analyzer.maxDecibels = this.props?.maxDecibels ?? -10;
    this.analyzer.smoothingTimeConstant = this.props?.smoothingTimeConstant ?? 0.8;

    source.connect(this.analyzer);

    const analyze = (): void => {
      if (this.analyzer == null) return;

      const dataArray = new Uint8Array(this.analyzer.frequencyBinCount);
      this.analyzer.getByteFrequencyData(dataArray);

      const peak = Math.max(...Array.from(dataArray));

      const noiseFloor = this.props?.noiseFloor ?? 50;
      const normalizedPeak = Math.max(0, peak - noiseFloor);
      const volume = Math.min(1, normalizedPeak / (this.props?.normalizationFactor ?? 150));

      this.emit({ type: 'volume', volume });
      requestAnimationFrame(analyze);
    };

    analyze();
  }
}
