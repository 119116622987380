import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useState } from 'react';
import type { AiSuggestionFeedback } from 'components/Attachments/AttachmentsQueries';
import { useSuggestionFeedback } from 'components/Attachments/AttachmentsQueries';
import { useAttachmentsData } from 'components/Attachments/context';
import type { AiSuggestion, AttachmentDTO, AttachmentType } from 'components/Attachments/types';

export interface UseAttachmentSuggestionsReturnType {
    readonly typeSuggestion: AiSuggestion | null | undefined;
    readonly descriptionSuggestion: AiSuggestion | null | undefined;
    readonly feedback:
        | { readonly publicId: string; readonly accepted: boolean | null; readonly field: 'description' | 'type' }[]
        | null;
    readonly handleFeedback: (value: boolean, form: 'description' | 'type') => void;
    readonly submitSuggestionFeedback: UseMutateAsyncFunction<
        null,
        unknown,
        {
            readonly attachmentPublicId: string;
            readonly feedback: AiSuggestionFeedback[];
        },
        null
    >;
    readonly getTypeValue: (accepted: boolean | null) => AttachmentType | null;
}

export const useAttachmentSuggestions = (attachment: AttachmentDTO): UseAttachmentSuggestionsReturnType => {
    const { entityType, entityPublicId } = useAttachmentsData();
    const hasAiSuggestion = attachment.aiSuggestions != null && attachment.aiSuggestions.length > 0;
    const typeSuggestion = hasAiSuggestion ? attachment.aiSuggestions.find(it => it.field === 'type') : null;
    const descriptionSuggestion = hasAiSuggestion
        ? attachment.aiSuggestions.find(it => it.field === 'description')
        : null;
    const [feedback, setFeedback] = useState<
        { publicId: string; accepted: boolean | null; field: 'description' | 'type' }[] | null
    >(
        hasAiSuggestion
            ? attachment.aiSuggestions.map(it => ({
                  publicId: it.publicId,
                  accepted: it.accepted ?? null,
                  field: it.field,
              }))
            : null
    );

    const handleFeedback = (value: boolean, form: 'description' | 'type') => {
        if (!hasAiSuggestion) return;

        const id = form === 'description' ? descriptionSuggestion?.publicId : typeSuggestion?.publicId;

        setFeedback(prev => {
            if (prev == null) return prev;
            // if type suggestion is rejected, reject description suggestion as well
            if (form === 'type' && !value) {
                return prev.map(it => ({ ...it, accepted: value }));
            }

            return prev.map(it => (it.publicId === id ? { ...it, accepted: value } : it));
        });
    };

    // if type suggestion is accepted, use it as the value
    const getTypeValue = (accepted: boolean | null): AttachmentType | null => {
        return accepted ? { value: typeSuggestion?.value ?? '', label: typeSuggestion?.label ?? '' } : null;
    };

    const { mutateAsync: submitSuggestionFeedback } = useSuggestionFeedback({ entityType, entityPublicId });

    return {
        typeSuggestion,
        descriptionSuggestion,
        feedback,
        handleFeedback,
        submitSuggestionFeedback,
        getTypeValue,
    };
};
