import type { TableHeaderCellProps } from '@lemonade-hq/bluis';
import { EmptySection, ErrorSection, LoadingSection, Table, TableHeader, TableRow } from '@lemonade-hq/bluis';
import { useMemo } from 'react';
import { EditionLink, getFlatEditions } from './EditionsTableShared';
import { getEditionName } from 'components/LoCo/common/editions/editionHelpers';
import { getFormattedDate } from 'components/LoCo/common/helpers/dateHelpers';
import { EditionStatus } from 'models/LoCo/Insurance/BaseEdition';
import type { Edition, EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { useGetProductEditionsSummary } from 'queries/LoCo/Insurance/ProductQueries';

interface DraftEditionsTableProps {
    readonly productCode: string;
}

const headerFields: TableHeaderCellProps[] = [
    { key: 'type', value: 'Type', width: 150 },
    { key: 'edition', value: 'Edition', width: 200 },
    { key: 'published on', value: 'Published on', width: 200 },
    { key: 'new business effective date', value: 'New business effective date', width: 200 },
    { key: 'renewal effective date', value: 'Renewal effective date', width: 200 },
];

interface EditionToRowProps {
    readonly type: EditionType;
    readonly edition: Edition;
    readonly productCode: string;
}

const EditionToRow: React.FC<EditionToRowProps> = ({ type, edition, productCode }) => {
    const row = useMemo(
        () => [
            { key: 'type', value: getEditionName(type) },
            { key: 'edition', value: <EditionLink edition={edition} productCode={productCode} type={type} /> },
            {
                key: 'published on',
                value: edition.publishedAt != null ? getFormattedDate(edition.publishedAt) : '-',
            },
            {
                key: 'new business effective date',
                value:
                    edition.earliestRenewalEffectiveAt != null
                        ? getFormattedDate(edition.earliestRenewalEffectiveAt)
                        : '-',
            },
            {
                key: 'renewal effective date',
                value:
                    edition.earliestNewBusinessEffectiveAt != null
                        ? getFormattedDate(edition.earliestNewBusinessEffectiveAt)
                        : '-',
            },
        ],
        [edition, productCode, type]
    );
    return <TableRow key={edition.code} row={row} />;
};

export const PublishedEditionsTable: React.FC<React.PropsWithChildren<DraftEditionsTableProps>> = ({ productCode }) => {
    const {
        data: editionsSummary,
        isError,
        isLoading,
    } = useGetProductEditionsSummary(productCode, { status: EditionStatus.Published });

    if (isLoading) {
        return <LoadingSection />;
    }

    if (!editionsSummary || isError) {
        return <ErrorSection title="Failed fetching published editions :(" />;
    }

    const joinedEditions = getFlatEditions(editionsSummary);

    return joinedEditions.length === 0 ? (
        <EmptySection>No published editions for this product</EmptySection>
    ) : (
        <Table>
            <TableHeader headers={headerFields} />
            {joinedEditions.map(edition => (
                <EditionToRow edition={edition} key={edition.code} productCode={productCode} type={edition.type} />
            ))}
        </Table>
    );
};
