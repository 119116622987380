import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationFunction, MutationKey, QueryKey, UseMutationResult } from '@tanstack/react-query';

export interface UseOptimisticMutation<TVariables, TSnapshot> {
  readonly mutationFn: MutationFunction<null, TVariables>;
  readonly mutate: (props: { readonly data: TSnapshot; readonly variables: TVariables }) => TSnapshot;
  readonly mutateKey: QueryKey;
  readonly invalidateKeys: QueryKey[];
  readonly mutationKey?: MutationKey;
  readonly onSuccess?: (data: null, variables: TVariables) => void;
}

export function useOptimisticMutation<TVariables, TSnapshot>(
  args: UseOptimisticMutation<TVariables, TSnapshot>,
): UseMutationResult<null, unknown, TVariables, TSnapshot> {
  const { mutationFn, mutate, mutateKey, invalidateKeys, mutationKey, onSuccess } = args;
  const queryClient = useQueryClient();

  return useMutation<null, unknown, TVariables, TSnapshot>({
    mutationFn,
    mutationKey: mutationKey ?? mutateKey,
    onMutate: async variables => {
      await queryClient.cancelQueries({ queryKey: mutateKey });

      const previousValue = queryClient.getQueryData<TSnapshot>(mutateKey) as TSnapshot;

      queryClient.setQueryData<TSnapshot>(mutateKey, data => {
        if (data === undefined) return previousValue;

        return mutate({ data, variables });
      });

      return previousValue;
    },
    onSettled: () => {
      invalidateKeys.forEach(key => {
        void queryClient.invalidateQueries({ queryKey: key });
      });
    },
    onSuccess,
  });
}
