import axios from 'axios';
import { Demand } from 'models/Demand';
import { getUrlResolver } from 'commons/UrlResolver';

const carBlenderMitchellBiDemandsResolver = getUrlResolver('car-blender', '/api/v1/mitchell_bi/demands');
const carBlenderMitchellBiBillsResolver = getUrlResolver('car-blender', '/api/v1/mitchell_bi/bills');

export async function createDemand({ demand }: { demand: Omit<Demand, 'publicId' | 'status'> }): Promise<null> {
    const url = carBlenderMitchellBiDemandsResolver();

    return axios.post(url, demand);
}

export async function updateEvaluation({
    demandId,
    evaluation,
}: {
    demandId: string;
    evaluation: string;
}): Promise<null> {
    const url = carBlenderMitchellBiDemandsResolver(`/${demandId}`);

    return axios.patch(url, { evaluation });
}

export async function sendBill({
    attachmentPublicIds,
    claimPublicId,
    involvedPersonPublicId,
}: {
    attachmentPublicIds: string[];
    claimPublicId: string;
    involvedPersonPublicId: string;
}): Promise<null> {
    const url = carBlenderMitchellBiBillsResolver();

    return axios.post(url, { attachmentPublicIds, claimPublicId, involvedPersonPublicId });
}

export async function approveBill({ billPublicId }: { billPublicId: string }): Promise<null> {
    const url = carBlenderMitchellBiBillsResolver(`/${billPublicId}/approve`);

    return axios.post(url);
}

export async function declineBill({ billPublicId }: { billPublicId: string }): Promise<null> {
    const url = carBlenderMitchellBiBillsResolver(`/${billPublicId}/reject`);

    return axios.post(url);
}
