import type { LabelValueItem, TableItem } from '@lemonade-hq/blender-ui';
import {
    Card,
    CopyButton,
    Flex,
    LabelValueTable,
    spacing,
    SummarySection,
    Table,
    Text,
    Tooltip,
} from '@lemonade-hq/blender-ui';
import { LinkComp } from '@lemonade-hq/bluiza';
import { titlize } from '@lemonade-hq/ts-helpers';
import { useMemo } from 'react';
import { getEditionName } from '../common/editions/editionHelpers';
import { getFormattedDate } from '../common/helpers/dateHelpers';
import { getEditionUrl, getReleaseUrl } from '../common/urlBuilders';
import { EditionSetValidations } from './EditionSetValidations';
import { camelCaseToReadable } from 'commons/StringUtils';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';

function getRow(type: EditionType, edition: Edition, productCode: string): Record<string, TableItem> {
    return {
        type: { value: getEditionName(type) },
        name: {
            value: (
                <LinkComp url={getEditionUrl(productCode, edition.code, type)}>
                    {camelCaseToReadable(edition.friendlyName)}
                </LinkComp>
            ),
        },
        publishedAt: { value: edition.publishedAt != null ? getFormattedDate(edition.publishedAt) : '-' },
        earliestNewBusinessEffectiveDate: {
            value:
                edition.earliestRenewalEffectiveAt != null ? getFormattedDate(edition.earliestRenewalEffectiveAt) : '-',
        },
        earliestRenewalEffectiveDate: {
            value:
                edition.earliestNewBusinessEffectiveAt != null
                    ? getFormattedDate(edition.earliestNewBusinessEffectiveAt)
                    : '-',
        },
    };
}

export const EditionSetDetails: React.FC<{ readonly productCode: string; readonly editionSet: EditionSet }> = ({
    productCode,
    editionSet,
}) => {
    const editionSetDetails = useMemo(() => {
        const details: LabelValueItem[] = [
            { label: 'Rollout Strategy', value: titlize(editionSet.versionType) },
            { label: 'Effective Date: New Business', value: getFormattedDate(editionSet.newBusinessEffectiveAt) },
            { label: 'Effective Date: Renewals', value: getFormattedDate(editionSet.renewalEffectiveAt) },
            {
                label: 'Edition Set Code',
                value: (
                    <Flex alignItems="center" gap={spacing.s04}>
                        <Text>{editionSet.code}</Text>
                        <CopyButton size="sm" successMessage="Copied to clipboard" textToCopy={editionSet.code} />
                    </Flex>
                ),
            },
        ];

        if (editionSet.publishedAt != null) {
            details.push({
                label: 'Published On',
                value: (
                    <Tooltip content={`By ${editionSet.publishedByName}`} disabled={editionSet.publishedByName == null}>
                        {getFormattedDate(editionSet.publishedAt)}
                    </Tooltip>
                ),
            });
        }

        if (editionSet.releasePublicId != null && editionSet.releaseFriendlyName != null) {
            details.push({
                label: 'Release',
                value: (
                    <LinkComp url={getReleaseUrl(productCode, editionSet.releasePublicId)}>
                        {editionSet.releaseFriendlyName}
                    </LinkComp>
                ),
            });
        }

        if (editionSet.archivedAt != null) {
            details.push({
                label: 'Canceled On',
                value: (
                    <Tooltip content={`By ${editionSet.archivedByName}`} disabled={editionSet.archivedByName == null}>
                        {getFormattedDate(editionSet.archivedAt)}
                    </Tooltip>
                ),
            });
        }

        return details;
    }, [editionSet, productCode]);

    const cols = useMemo(
        () => [
            { name: 'Type', key: 'type' },
            { name: 'Name', key: 'friendlyName' },
            { name: 'First Published', key: 'publishedAt' },
            { name: 'New Business', key: 'earliestNewBusinessEffectiveDate' },
            { name: 'Renewals', key: 'earliestRenewalEffectiveDate' },
        ],
        []
    );

    const rows = useMemo(() => {
        return [
            getRow(EditionType.Coverages, editionSet.coveragesEdition, productCode),
            getRow(EditionType.DigitalAgent, editionSet.digitalAgentEdition, productCode),
            getRow(EditionType.UnderwritingFilters, editionSet.underwritingFiltersEdition, productCode),
            getRow(EditionType.Rating, editionSet.ratingEdition, productCode),
        ];
    }, [editionSet, productCode]);

    return (
        <Flex flexDirection="column" gap={spacing.s12} py={spacing.s20}>
            <SummarySection title="Edition Set Details">
                <LabelValueTable columnCount={1} data={editionSetDetails} />
            </SummarySection>
            <Card p={spacing.s32}>
                <Flex flexDirection="column" gap={spacing.s12}>
                    <Text type="h4">Editions</Text>
                    <Table columns={cols} data={rows} />
                </Flex>
            </Card>
            <Card p={spacing.s32}>
                <EditionSetValidations editionSet={editionSet} productCode={productCode} />
            </Card>
        </Flex>
    );
};
