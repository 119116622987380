import type { Edition, VersionType } from './BaseEdition';

export enum ReleaseStatus {
    Blocked = 'blocked',
    Draft = 'draft',
    Cancelled = 'cancelled',
    Published = 'published',
}

export interface Release {
    readonly publicId: string;
    readonly productCode: string;
    readonly friendlyName: string;
    readonly rolloutStrategy: VersionType;
    readonly renewalEffectiveAt?: string;
    readonly newBusinessEffectiveAt?: string;
    readonly editionSetVersions: Record<
        string,
        {
            readonly code: string;
            readonly version: string;
            readonly versionMajor: number;
            readonly versionMinor: number;
        }
    >;
    readonly note?: string;

    readonly startedAt: string;
    readonly startedBy: string;
    readonly startedByName: string;

    readonly cancelledAt?: string;
    readonly cancelledBy?: string;
    readonly cancelledByName?: string;

    readonly publishedAt: string | null;
    readonly publishedBy?: string;
    readonly publishedByName?: string;

    readonly status: ReleaseStatus;

    readonly readyToPublish: boolean;
    readonly editionSetsSynced: boolean;

    readonly editions: {
        readonly rating?: Edition;
        readonly coverages?: Edition;
        readonly digitalAgent?: Edition;
        readonly underwritingFilters?: Edition;
    };

    readonly platformSchemaRevision: number | null;
    readonly productSchemaRevision: number | null;
    readonly initialRelease: boolean;
}
