/* eslint-disable @typescript-eslint/naming-convention */
import type { DateValue } from '@internationalized/date';
import {
  CalendarDate,
  createCalendar,
  getLocalTimeZone,
  getWeeksInMonth,
  isSameDay,
  toCalendarDate,
  today,
} from '@internationalized/date';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { FC, KeyboardEvent, ReactNode } from 'react';
import type {
  AriaButtonProps,
  CalendarProps as AriaCalendarProps,
  AriaDatePickerProps,
  CalendarAria,
  DateRange,
} from 'react-aria';
import { useButton, useCalendarCell, useCalendarGrid, useDateField, useLocale } from 'react-aria';
import type { DateSegment as AriaDateSegment, CalendarState, RangeCalendarState } from 'react-stately';
import { useDateFieldState } from 'react-stately';
import { Flex } from '../../base/Flex/Flex';
import { Grid } from '../../base/Grid/Grid';
import { Layout } from '../../base/Layout/Layout';
import type { TextProps } from '../../base/Text/Text';
import { Text } from '../../base/Text/Text';
import { input } from '../../theme/input.css';
import { spacing } from '../../theme/spacing.css';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { IconButton } from '../IconButton/IconButton';
import { DateSegment } from './DateSegment';
import {
  actionButton,
  cell,
  listItem,
  listWrapper,
  rangeSelection,
  rotated,
  tableCell,
  tableStyle,
} from './DateTimePicker.css';
import type { RangeValue } from './types';

const isRangeCalendarState = (state: CalendarState | RangeCalendarState): state is RangeCalendarState =>
  'highlightedRange' in state;

const CalendarCell: FC<{ readonly date: CalendarDate; readonly state: CalendarState | RangeCalendarState }> = ({
  state,
  date,
}) => {
  const ref = useRef(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    isFocused,
    formattedDate,
  } = useCalendarCell({ date }, state, ref);

  const isRange = isRangeCalendarState(state);

  const isSelectionStart = useMemo(
    () => (isRange && state.highlightedRange != null ? isSameDay(date, state.highlightedRange.start) : isSelected),
    [date, isRange, isSelected, state],
  );

  const isSelectionEnd = useMemo(
    () => (isRange && state.highlightedRange != null ? isSameDay(date, state.highlightedRange.end) : isSelected),
    [date, isRange, isSelected, state],
  );

  const isInRange = isRange ? isSelected && !isSelectionEnd && !isSelectionStart : false;

  const textColor = useMemo<TextProps<'span'>['color']>(() => {
    if (isSelected && !isInRange && (!isRange || !isSelectionEnd)) return 'inverted';
    if (isDisabled) return 'disabled';
    if (isFocused) return 'brand';

    return undefined;
  }, [isDisabled, isFocused, isInRange, isRange, isSelected, isSelectionEnd]);

  return (
    <td
      {...cellProps}
      className={tableCell}
      data-testid={
        isRange
          ? isSelectionStart
            ? 'daterangepicker-selected-day-start'
            : isSelectionEnd
              ? 'daterangepicker-selected-day-end'
              : undefined
          : isSelected
            ? 'datetimepicker-selected-day'
            : undefined
      }
    >
      {isRange && isSelected && <Layout className={rangeSelection({ isSelectionStart, isSelectionEnd })} />}
      <Flex
        {...buttonProps}
        alignItems="center"
        className={cell({
          isSelected: isRange ? isSelectionStart || isSelectionEnd : isSelected,
          disabled: isOutsideVisibleRange || isDisabled || isUnavailable,
          isInRange,
          isSelectionEnd: isRange && isSelectionEnd,
        })}
        justifyContent="center"
        ref={ref}
      >
        {!isOutsideVisibleRange && (
          <Text as="span" color={textColor} textAlign="center" type="text-md">
            {formattedDate}
          </Text>
        )}
      </Flex>
    </td>
  );
};

const customWeekdayTitles = {
  Monday: 'Mo',
  Tuesday: 'Tu',
  Wednesday: 'We',
  Thursday: 'Th',
  Friday: 'Fr',
  Saturday: 'Sa',
  Sunday: 'Su',
};

const CalendarGrid: FC<{ readonly state: CalendarState | RangeCalendarState; readonly locale?: string }> = ({
  state,
  locale,
}) => {
  const { locale: ariaLocale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid({ weekdayStyle: 'long' }, state);

  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale ?? ariaLocale);

  return (
    <table {...gridProps} className={tableStyle}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <th key={i} style={{ width: '3.2rem' }}>
              <Text as="span" fontWeight="semi-bold" textAlign="center" type="text-md">
                {customWeekdayTitles[day]}
              </Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map(weekIndex => (
          <tr key={weekIndex}>
            {state.getDatesInWeek(weekIndex).map((date, i) =>
              // eslint-disable-next-line react/no-array-index-key
              date != null ? <CalendarCell date={date} key={date.toString()} state={state} /> : <td key={i} />,
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

interface ArrowButtonProps extends AriaButtonProps<'button'> {
  readonly direction: 'left' | 'right';
}

const ArrowButton: FC<ArrowButtonProps> = ({ direction, ...props }) => {
  const buttonRef = useRef(null);
  const { buttonProps } = useButton(props, buttonRef);

  const { color, ...sanitizedButtonProps } = buttonProps;

  return (
    <IconButton
      className={rotated}
      icon={direction === 'left' ? 'chevron-down' : 'chevron-up'}
      ref={buttonRef}
      variant="inline"
      {...sanitizedButtonProps}
    />
  );
};

interface DateInputProps extends AriaDatePickerProps<DateValue> {
  readonly locale?: string;
  readonly disabled: boolean;
  readonly inputLabel?: string;
  readonly ariaLabel?: string;
}

const DateInput: FC<DateInputProps> = ({
  inputLabel = 'Date',
  ariaLabel = 'Date',
  locale,
  disabled,
  ...fieldProps
}) => {
  const inputRef = useRef(null);
  const { locale: ariaLocale } = useLocale();
  const state = useDateFieldState({
    ...fieldProps,
    locale: locale ?? ariaLocale,
    createCalendar,
    granularity: 'day',
  });

  const { fieldProps: ariaFieldProps } = useDateField(fieldProps, state, inputRef);

  return (
    <Flex flex="1" flexDirection="column" gap={spacing.s04}>
      <Text as="span" type="text-md">
        {inputLabel}
      </Text>
      <Flex
        {...ariaFieldProps}
        alignItems="center"
        aria-label={ariaLabel}
        className={input()}
        minWidth="13.6rem"
        ref={inputRef}
      >
        {state.segments.map((segment, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <DateSegment disabled={disabled} key={i} segment={segment} state={state} />
        ))}
      </Flex>
    </Flex>
  );
};

const getTimeSegments = (segments: AriaDateSegment[]): AriaDateSegment[] => {
  const hour = segments.find(({ type }) => type === 'hour');
  const minute = segments.find(({ type }) => type === 'minute');
  const dayPeriod = segments.find(({ type }) => type === 'dayPeriod');

  if (!hour || !minute || !dayPeriod) {
    throw new Error('Invalid time segments');
  }

  return [
    hour,
    { type: 'literal', text: ':', isPlaceholder: false, placeholder: '', isEditable: false },
    minute,
    { type: 'literal', text: ' ', isPlaceholder: false, placeholder: '', isEditable: false },
    dayPeriod,
  ];
};

const TimeInput: FC<DateInputProps> = ({ locale, disabled, ...fieldProps }) => {
  const inputRef = useRef(null);
  const { locale: ariaLocale } = useLocale();
  const state = useDateFieldState({
    ...fieldProps,
    locale: locale ?? ariaLocale,
    createCalendar,
    granularity: 'minute',
  });

  const { fieldProps: ariaFieldProps } = useDateField(fieldProps, state, inputRef);

  return (
    <Flex flex="1" flexDirection="column" gap={spacing.s04}>
      <Text as="span" type="text-md">
        Time
      </Text>
      {/* We need tabIndex={1} here to avoid focus trap created by react-aria */}
      {/* eslint-disable-next-line jsx-a11y/tabindex-no-positive */}
      <Flex {...ariaFieldProps} alignItems="center" aria-label="Date" className={input()} ref={inputRef} tabIndex={1}>
        {getTimeSegments(state.segments).map((segment, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <DateSegment disabled={disabled} key={i} segment={segment} state={state} />
        ))}
      </Flex>
    </Flex>
  );
};

const SelectMonth: FC<{
  readonly currentMonth: number;
  readonly currentYear: number;
  readonly onSelect: (month: number) => void;
  readonly onBack: () => void;
  readonly state: CalendarState | RangeCalendarState;
  readonly locale?: string;
}> = ({ currentMonth, currentYear, onSelect, onBack, state, locale }) => {
  const currentMonthRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (currentMonthRef.current == null) return;

    currentMonthRef.current.scrollIntoView({ block: 'center' });
  }, []);

  const { locale: ariaLocale } = useLocale();

  const months = useMemo(() => {
    return [...Array(12).keys()].map(i =>
      new Date(1970, i).toLocaleDateString(locale ?? ariaLocale, {
        month: 'long',
      }),
    );
  }, [locale, ariaLocale]);

  const handleKeyDown = useCallback(
    (month: number) => (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.code === 'Enter') {
        onSelect(month);
      }
    },
    [onSelect],
  );

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" gap={spacing.s06}>
        <ArrowButton direction="left" onPress={onBack} />
        <Text as="span" fontWeight="bold" type="text-md">
          {currentYear}
        </Text>
      </Flex>
      <Flex className={listWrapper} flexDirection="column" padding={spacing.s02}>
        {months.map((month, i) => {
          const currMonthIndex = i + 1;

          const isSelected = currentMonth === currMonthIndex;
          const isDisabled =
            (state.minValue != null && state.minValue.year === currentYear && state.minValue.month > currMonthIndex) ||
            (state.maxValue != null && state.maxValue.year === currentYear && state.maxValue.month < currMonthIndex);

          return (
            <Flex
              alignItems="center"
              className={listItem({ isSelected })}
              key={month}
              onClick={() => onSelect(currMonthIndex)}
              onKeyDown={handleKeyDown(currMonthIndex)}
              ref={isSelected ? currentMonthRef : undefined}
              tabIndex={0}
            >
              <Text as="span" color={isSelected ? 'inverted' : isDisabled ? 'disabled' : undefined} type="text-md">
                {month}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

type DateSelectionState = 'day' | 'month' | 'year';

const YEARS_RANGE = 100;

const getYears = (currentYear: number): number[] =>
  [...new Array(YEARS_RANGE * 2 + 1).keys()].map(i => currentYear - YEARS_RANGE + i);

const SelectYear: FC<{
  readonly currentYear: number;
  readonly onSelect: (year: number) => void;
  readonly onBack: () => void;
  readonly state: CalendarState | RangeCalendarState;
}> = ({ currentYear, onSelect, onBack, state }) => {
  const currentYearRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (currentYearRef.current == null) return;

    currentYearRef.current.scrollIntoView({ block: 'center' });
  }, []);

  const handleKeyDown = useCallback(
    (year: number) => (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.code === 'Enter') {
        onSelect(year);
      }
    },
    [onSelect],
  );

  return (
    <Flex flexDirection="column">
      <Flex>
        <ArrowButton direction="left" onPress={onBack} />
      </Flex>
      <Grid className={listWrapper} gridTemplateColumns="repeat(3, 1fr)" padding={spacing.s02}>
        {getYears(currentYear).map(year => {
          const isSelected = year === currentYear;
          const isDisabled =
            (state.minValue != null && state.minValue.year > year) ||
            (state.maxValue != null && state.maxValue.year < year);

          return (
            <Flex
              alignItems="center"
              className={listItem({ isSelected })}
              justifyContent="center"
              key={year}
              onClick={() => onSelect(year)}
              onKeyDown={handleKeyDown(year)}
              ref={isSelected ? currentYearRef : undefined}
              tabIndex={0}
            >
              <Text as="span" color={isSelected ? 'inverted' : isDisabled ? 'disabled' : undefined} type="text-md">
                {year}
              </Text>
            </Flex>
          );
        })}
      </Grid>
    </Flex>
  );
};

interface CalendarPropsBase extends Omit<AriaCalendarProps<DateValue>, 'defaultValue' | 'onChange' | 'value'> {
  readonly onDone: () => void;
  readonly onCancel: (value: DateValue | RangeValue<DateValue> | null | undefined) => void;
  readonly withTime?: boolean;
  readonly withDateInput?: boolean;
  readonly locale?: string;
  readonly disabled: boolean;
  readonly inputPosition?: 'bottom' | 'top';
  readonly customHeader?: ReactNode;

  readonly calendarProps: CalendarAria['calendarProps'];
  readonly nextButtonProps: CalendarAria['nextButtonProps'];
  readonly prevButtonProps: CalendarAria['prevButtonProps'];
  readonly title: CalendarAria['title'];
}

export type CalendarProps = CalendarPropsBase &
  (
    | {
        readonly isRange: false;
        readonly state: CalendarState;
        readonly value?: DateValue | null;
        readonly fieldProps: AriaDatePickerProps<DateValue>;
        readonly startFieldProps?: never;
        readonly endFieldProps?: never;
      }
    | {
        readonly isRange: true;
        readonly state: RangeCalendarState;
        readonly value?: DateRange | null;
        readonly fieldProps?: never;
        readonly startFieldProps: AriaDatePickerProps<DateValue>;
        readonly endFieldProps: AriaDatePickerProps<DateValue>;
      }
  );

export const Calendar: FC<CalendarProps> = ({
  onDone,
  onCancel,
  withTime = false,
  withDateInput = false,
  locale,
  disabled,
  inputPosition = 'bottom',
  customHeader,

  calendarProps,
  nextButtonProps,
  prevButtonProps,
  title,

  isRange,
  fieldProps,
  startFieldProps,
  endFieldProps,

  state,
}) => {
  const { year: todayYear, month: todayMonth, day: todayDay } = today(getLocalTimeZone());
  const [dateSelectionState, setDateSelectionState] = useState<DateSelectionState>('day');

  const startValueRef = useRef(state.value);

  const yearToFocusRef = useRef<number | undefined>();

  const handleYearSelect = useCallback((year: number) => {
    yearToFocusRef.current = year;
    setDateSelectionState('month');
  }, []);

  const handleCancelYearSelect = useCallback(() => {
    yearToFocusRef.current = undefined;
    setDateSelectionState('day');
  }, []);

  const handleMonthSelect = useCallback(
    (month: number) => {
      if (yearToFocusRef.current == null) return;

      setDateSelectionState('day');

      state.setFocusedDate(
        new CalendarDate(yearToFocusRef.current, month, isRange ? todayDay : state.value?.day ?? todayDay),
      );
      yearToFocusRef.current = undefined;
    },
    [state, isRange, todayDay],
  );

  const handleCancelMonthSelect = useCallback(() => {
    setDateSelectionState('year');
  }, []);

  const inputComponent = useMemo(
    () => (
      <Flex gap={spacing.s12}>
        {withDateInput &&
          (isRange ? (
            <Flex gap={spacing.s16}>
              <DateInput
                {...startFieldProps}
                ariaLabel="From Date"
                disabled={disabled}
                inputLabel="From"
                locale={locale}
                onBlur={() => state.value != null && state.setFocusedDate(toCalendarDate(state.value.start))}
              />
              <DateInput
                {...endFieldProps}
                ariaLabel="To Date"
                disabled={disabled}
                inputLabel="To"
                locale={locale}
                onBlur={() => state.value != null && state.setFocusedDate(toCalendarDate(state.value.end))}
              />
            </Flex>
          ) : (
            <DateInput
              {...fieldProps}
              disabled={disabled}
              locale={locale}
              onBlur={() => state.value != null && state.setFocusedDate(toCalendarDate(state.value))}
            />
          ))}
        {withTime && <TimeInput {...fieldProps} disabled={disabled} locale={locale} />}
      </Flex>
    ),
    [disabled, endFieldProps, fieldProps, isRange, locale, startFieldProps, state, withDateInput, withTime],
  );

  return (
    <Card borderRadius="sm" maxHeight="41.2rem" padding={spacing.s12} shadow="ds6" width="33.5rem">
      <Flex {...calendarProps} flexDirection="column" gap={spacing.s12} position="relative">
        {dateSelectionState === 'day' && (
          <>
            {customHeader}
            {inputPosition === 'top' && inputComponent}
            <Flex gap={spacing.s12} justifyContent="space-between" padding={`0 ${spacing.s04}`}>
              <Button
                endIcon="chevron-down"
                label={title}
                onClick={() => setDateSelectionState('year')}
                variant="inline"
              />
              <Flex alignItems="center" gap={spacing.s04}>
                <ArrowButton direction="left" {...prevButtonProps} />
                <ArrowButton direction="right" {...nextButtonProps} />
              </Flex>
            </Flex>
            <CalendarGrid state={state} />
            {inputPosition === 'bottom' && inputComponent}
            <Flex gap={spacing.s04}>
              <Button
                className={actionButton}
                label="Cancel"
                onClick={() => onCancel(startValueRef.current)}
                variant="secondary"
              />
              <Button className={actionButton} label="Done" onClick={onDone} variant="primary" />
            </Flex>
          </>
        )}
        {dateSelectionState === 'year' && (
          <SelectYear
            currentYear={(isRange ? state.value?.start.year : state.value?.year) ?? todayYear}
            onBack={handleCancelYearSelect}
            onSelect={handleYearSelect}
            state={state}
          />
        )}
        {dateSelectionState === 'month' && (
          <SelectMonth
            currentMonth={(isRange ? state.value?.start.month : state.value?.month) ?? todayMonth}
            currentYear={yearToFocusRef.current ?? todayYear}
            onBack={handleCancelMonthSelect}
            onSelect={handleMonthSelect}
            state={state}
          />
        )}
      </Flex>
    </Card>
  );
};
