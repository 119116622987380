import type { UseQueryResult } from '@tanstack/react-query';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import type { ScanStatusResult } from 'apis/FraudAPI';
import { getScanStatus, scanForFraud } from 'apis/FraudAPI';
import { usePessimisticMutation } from 'queries/MutationHooks';

export enum FraudQueryKey {
    GET_SCAN_STATUS = 'GET_SCAN_STATUS',
}

export const useScanFraudMutation = () => {
    return usePessimisticMutation({
        mutationFn: scanForFraud,
        invalidateKeys: [],
    });
};

export const useScanStatusQuery = (
    attachmentPublicIds: string[],
    onSuccess: (data: ScanStatusResult[]) => void,
    onError: () => void
): UseQueryResult<ScanStatusResult[] | null> => {
    return useQuery({
        queryKey: [FraudQueryKey.GET_SCAN_STATUS, attachmentPublicIds],
        queryFn: async () => {
            const response = await getScanStatus(attachmentPublicIds).catch(() => {
                onError();
                throw new Error('Failed to fetch scan status');
            });

            onSuccess(response);
        },
        refetchInterval: 5000,
        enabled: attachmentPublicIds.length > 0,
        placeholderData: keepPreviousData,
    });
};
