import { Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import { AlertMode, Dialog } from '@lemonade-hq/bluis';
import { LinkComp } from '@lemonade-hq/bluiza';
import { useCallback } from 'react';
import { editionsList } from './PublishDialog.css';
import { titlize, toReadable } from 'commons/StringUtils';
import { getEditionUrl } from 'components/LoCo/common/urlBuilders';
import { VersionType } from 'models/LoCo/Insurance/BaseEdition';
import type { Release } from 'models/LoCo/Insurance/Release';
import {
    usePublishRelease,
    useSuspenseGetReleasePotentiallyArchivedEditions,
} from 'queries/LoCo/Insurance/ReleasesQueries';

interface PublishDialogProps {
    readonly release: Release;
    readonly onClose: () => void;
}

export const PublishDialog: React.FC<PublishDialogProps> = ({ release, onClose }) => {
    const { mutateAsync, isPending, error } = usePublishRelease(release.publicId);

    const { data: potentiallyArchivedEditions } = useSuspenseGetReleasePotentiallyArchivedEditions(release.publicId);

    const publish = useCallback(async () => {
        await mutateAsync(release.publicId);
        onClose();
    }, [mutateAsync, onClose, release.publicId]);

    const rolloutStrategy = release.rolloutStrategy;

    const hasPotentiallyArchivedEditions = potentiallyArchivedEditions.length > 0;

    return (
        <Dialog
            actions={[
                { type: 'close', text: 'Cancel', onClick: onClose },
                { type: 'submit', text: 'Publish release', onClick: publish },
            ]}
            error={error === null ? undefined : (error as Error).message}
            loading={isPending}
            notice={
                hasPotentiallyArchivedEditions
                    ? [
                          {
                              mode: AlertMode.Info,
                              title: (
                                  <>
                                      <Text>
                                          Once the edition set is published the following editions will be automatically
                                          archived:
                                      </Text>
                                      <ul className={editionsList}>
                                          {potentiallyArchivedEditions.map(edition => (
                                              <li key={edition.editionContentCode}>
                                                  {titlize(toReadable(edition.editionContentType))} Edition: &nbsp;
                                                  <LinkComp
                                                      target="_blank"
                                                      url={getEditionUrl(
                                                          release.productCode,
                                                          edition.editionContentCode,
                                                          edition.editionContentType
                                                      )}
                                                  >
                                                      {edition.friendlyName}
                                                  </LinkComp>
                                              </li>
                                          ))}
                                      </ul>
                                  </>
                              ),
                          },
                      ]
                    : []
            }
            size="large"
            title="Publish release to production"
        >
            <Flex alignItems="center" flexDirection="column" gap={spacing.s08} style={{ textAlign: 'center' }}>
                <Text fontWeight="bold">Are you sure you are ready to publish?</Text>
                {rolloutStrategy === VersionType.Major && (
                    <>
                        <Text textAlign="center">
                            Once published, the changes will be applied for new quotes and renewal policies with the
                            relevant effective dates.
                        </Text>
                        <Text textAlign="center">
                            This will not impact existing quotes (unless the effective date is updated) or policies
                            until their next renewal.
                        </Text>
                        <Text textAlign="center">
                            Upcoming Renewal Policies which have already been created will not be impacted.
                        </Text>
                    </>
                )}
                {rolloutStrategy === VersionType.Minor && (
                    <>
                        <Text textAlign="center">
                            Once published, it will be available for existing quotes/policies (if they make any edits)
                            starting on the relevant effective dates.
                        </Text>
                        <Text textAlign="center">No changes will be made automatically to any quotes or policies.</Text>
                    </>
                )}
            </Flex>
        </Dialog>
    );
};
