import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';
import { ExpressionSimpleEditor } from '../../ExpressionSimpleEditor/ExpressionSimpleEditor';
import type { ExpressionState } from '../../ExpressionSimpleEditor/ExpressionSimpleEditorContext';
import { recordsToString } from '../../ExpressionSimpleEditor/expressionTransformers';
import type { InputFunction } from '../../ExpressionSimpleEditor/operators';
import type { RuleBuilderRefMethods } from '../ManageRuleDialogContext';
import { schemaToFieldsRecord } from 'components/LoCo/common/helpers/schemaHelpers';
import type { SchemaResponse } from 'models/LoCo/Insurance/Schema';

interface SegmentSimpleEditorProps {
    readonly productSchema: SchemaResponse;
    readonly renderExpressionRef: MutableRefObject<RuleBuilderRefMethods | null>;
    readonly expression?: string;
    readonly onValidation?: (isValid: boolean) => void;
    readonly additionalFunctions?: Record<string, InputFunction>;
    readonly allowEmpty?: boolean;
}

export const SegmentRuleSimpleEditor: React.FC<SegmentSimpleEditorProps> = ({
    productSchema,
    renderExpressionRef,
    expression,
    onValidation,
    additionalFunctions,
    allowEmpty,
}) => {
    const ruleBuilderRef = useRef<ExpressionState>(null);

    useEffect(() => {
        renderExpressionRef.current = {
            get: () => {
                if (ruleBuilderRef.current === null) {
                    throw new Error('Expression editor is not set');
                }

                return recordsToString(ruleBuilderRef.current.expressionTree, ruleBuilderRef.current.root);
            },
        };
    }, [renderExpressionRef]);

    return (
        <ExpressionSimpleEditor
            additionalFunctions={additionalFunctions}
            allowEmpty={allowEmpty}
            expression={expression ?? ''}
            onValidation={onValidation}
            productSchema={schemaToFieldsRecord(productSchema.schema.fields)}
            ruleBuilderRef={ruleBuilderRef}
        />
    );
};
