import type { Dispatch } from '@lemonade-hq/blender-ui';
import { ActionsMenu, Button, Flex, Grid, Input, spacing, Text, useForm } from '@lemonade-hq/blender-ui';
import type { Infer } from '@lemonade-hq/maschema-schema';
import { va } from '@lemonade-hq/maschema-schema';
import type { ChangeEventHandler, FC } from 'react';
import type { PartialDeep } from 'type-fest';
import { FormGroup, HorizontalInputGroup } from './ManageSettingDialogForm';
import type { manageSettingDialogSchema } from './manageSettingDialogSchema';
import { coupledSettingAddButton, nonCoupledInput, nonCoupledInputWrapper } from './styles.css';
import { ValueType } from 'models/LoCo/Insurance/CoveragesEdition';
import type { ListOfValues, SettingInstance, Values } from 'models/LoCo/Insurance/CoveragesEdition';

interface WithEditionSettingInstances {
    readonly editionSettingInstances: SettingInstance[];
}

const SelectCoupledSettingTemplateCode: FC<WithEditionSettingInstances> = ({ editionSettingInstances }) => {
    const { dispatch } = useForm<typeof manageSettingDialogSchema>();

    return (
        <HorizontalInputGroup
            inputComponent="Select"
            label="Coupled setting"
            labels={Object.fromEntries(
                editionSettingInstances
                    .filter(s => s.values.type === ValueType.List)
                    .map(({ templateCode, name }) => [templateCode, name])
            )}
            onChangeOverride={({ value, defaultOnChange }) => {
                defaultOnChange(value);

                const setting = editionSettingInstances.find(({ templateCode }) => templateCode === value);

                if (setting && setting.values.type === ValueType.List) {
                    dispatch({
                        type: 'setValue',
                        key: 'coupledSetting.values',
                        value: setting.values.values.map(v => ({
                            currentSettingValue: undefined,
                            coupledSettingValue: v,
                        })),
                    });
                    dispatch({
                        key: 'values.values',
                        type: 'setValue',
                        value: [],
                    });
                }
            }}
            placeholder="Select a coupled setting"
            requiredOverride
            schemaKey="coupledSetting.coupledSettingTemplateCode"
            tooltip="Select another setting from the edition in order to define their values as pairs"
        />
    );
};

const createOnCoupledSettingValueChanged =
    ({
        dispatch,
        values,
        coupledSettingIndex,
    }: {
        readonly dispatch: Dispatch<typeof manageSettingDialogSchema>;
        readonly values: PartialDeep<Infer<typeof manageSettingDialogSchema, { readonly mergeUnions: true }>>;
        readonly coupledSettingIndex: number;
    }): ChangeEventHandler<HTMLInputElement> =>
    e => {
        const parsedValue = parseInt(e.target.value);
        const newValue = isNaN(parsedValue) ? undefined : parsedValue;

        const currentSettingValue = values.coupledSetting?.values?.[coupledSettingIndex]?.currentSettingValue;

        const currentValueIndex =
            currentSettingValue !== undefined
                ? values.values?.values?.indexOf(currentSettingValue) ?? undefined
                : undefined;

        dispatch({
            type: 'setValue',
            key: `coupledSetting.values[${coupledSettingIndex}].currentSettingValue`,
            value: newValue,
        });

        if (currentSettingValue === undefined && newValue !== undefined) {
            // add
            dispatch({
                type: 'setValue',
                key: 'values.values',
                value: [...(values.values?.values ?? []), newValue],
            });
        } else if (currentSettingValue !== undefined && newValue !== undefined) {
            // edit
            dispatch({
                type: 'setValue',
                key: `values.values[${currentValueIndex}]`,
                value: newValue,
            });
        } else if (currentSettingValue !== undefined && newValue === undefined && currentValueIndex !== undefined) {
            // remove
            const newValues = values.values?.values ? structuredClone(values.values.values) : [];
            newValues.splice(currentValueIndex, 1);

            dispatch({
                type: 'setValue',
                key: 'values.values',
                value: newValues,
            });
        }
    };

interface CoupledSettingInputProps {
    readonly coupledSettingValue: number;
    readonly coupledSettingIndex: number;
}

const CoupledSettingInput: FC<CoupledSettingInputProps> = ({ coupledSettingValue, coupledSettingIndex }) => {
    const { values, dispatch } = useForm<typeof manageSettingDialogSchema>();

    return (
        <>
            <Text>{coupledSettingValue}</Text>
            <Input
                min={0}
                onChange={createOnCoupledSettingValueChanged({ dispatch, values, coupledSettingIndex })}
                type="number"
                value={
                    values.coupledSetting?.values?.find(v => v.coupledSettingValue === coupledSettingValue)
                        ?.currentSettingValue
                }
            />
        </>
    );
};

interface NonCoupledSettingInputProps {
    readonly nonCoupledSettingValue: number;
    readonly nonCoupledSettingIndex: number;
}

const NonCoupledSettingInput: FC<NonCoupledSettingInputProps> = ({
    nonCoupledSettingValue,
    nonCoupledSettingIndex,
}) => {
    const { values, dispatch } = useForm<typeof manageSettingDialogSchema>();

    return (
        <>
            <Text>Missing</Text>
            <Flex alignItems="center" className={nonCoupledInputWrapper}>
                <Input
                    className={nonCoupledInput}
                    min={0}
                    onChange={e => {
                        const parsedValue = parseInt(e.target.value);
                        const newValue = isNaN(parsedValue) ? undefined : parsedValue;

                        const newValues = values.values?.values ? structuredClone(values.values.values) : [];

                        const newCoupledSettingValues = structuredClone(values.coupledSetting?.values ?? []);
                        const index = newValues.indexOf(nonCoupledSettingValue);

                        if (index !== -1) {
                            // @ts-expect-error -- we intentionally add an empty string as an empty value
                            newValues[index] = newValue ?? '';
                            dispatch({
                                type: 'setValue',
                                key: 'values.values',
                                value: newValues,
                            });
                        }

                        newCoupledSettingValues.splice(nonCoupledSettingIndex, 1, {
                            // @ts-expect-error -- we intentionally add an empty string as an empty value
                            currentSettingValue: newValue ?? '',
                        });

                        dispatch({
                            type: 'setValue',
                            key: 'coupledSetting.values',
                            value: newCoupledSettingValues,
                        });
                    }}
                    type="number"
                    value={nonCoupledSettingValue}
                />
                <ActionsMenu
                    items={[
                        {
                            label: 'Remove',
                            onSelect: () => {
                                const newValues = values.values?.values ? structuredClone(values.values.values) : [];

                                const index = newValues.indexOf(nonCoupledSettingValue);
                                if (index !== -1) {
                                    newValues.splice(index, 1);
                                    dispatch({
                                        type: 'setValue',
                                        key: 'values.values',
                                        value: newValues,
                                    });
                                }

                                const newCoupledSettingValues = structuredClone(values.coupledSetting?.values ?? []);

                                const coupledSettingIndex = newCoupledSettingValues.findIndex(
                                    ({ currentSettingValue, coupledSettingValue }) =>
                                        coupledSettingValue === undefined &&
                                        currentSettingValue === nonCoupledSettingValue
                                );

                                if (coupledSettingIndex !== -1) {
                                    newCoupledSettingValues.splice(coupledSettingIndex, 1);
                                    dispatch({
                                        type: 'setValue',
                                        key: 'coupledSetting.values',
                                        value: newCoupledSettingValues,
                                    });
                                }
                            },
                        },
                    ]}
                />
            </Flex>
        </>
    );
};

const CoupledSettingValues: FC<WithEditionSettingInstances> = ({ editionSettingInstances }) => {
    const { values, dispatch } = useForm<typeof manageSettingDialogSchema>();

    const coupledSettingTemplateCode = values.coupledSetting?.coupledSettingTemplateCode;

    const coupledSetting = editionSettingInstances.find(
        ({ templateCode }) => templateCode === coupledSettingTemplateCode
    );

    if (coupledSetting === undefined) {
        return null;
    }

    assertLimitSettingInstance(coupledSetting.values);

    return (
        <Grid alignItems="center" gap={spacing.s10} gridTemplateColumns="1fr 300px" width="100%">
            {values.coupledSetting?.values?.map(({ coupledSettingValue, currentSettingValue }, index) => {
                if (coupledSettingValue !== undefined) {
                    return (
                        <CoupledSettingInput
                            coupledSettingIndex={index}
                            coupledSettingValue={coupledSettingValue}
                            // eslint-disable-next-line react/no-array-index-key -- nothing unique to use
                            key={index}
                        />
                    );
                } else if (currentSettingValue !== undefined) {
                    return (
                        <NonCoupledSettingInput
                            // eslint-disable-next-line react/no-array-index-key -- nothing unique to use
                            key={index}
                            nonCoupledSettingIndex={index}
                            nonCoupledSettingValue={currentSettingValue}
                        />
                    );
                }

                return null;
            })}
            <Button
                className={coupledSettingAddButton}
                label="Add new"
                onClick={() => {
                    const newValues = values.values?.values ? structuredClone(values.values.values) : [];
                    // @ts-expect-error -- we intentionally add an empty string as an empty value
                    newValues.push('');

                    const newCoupledSettingValues = structuredClone(values.coupledSetting?.values ?? []);
                    // @ts-expect-error -- we intentionally add an empty string as an empty value
                    newCoupledSettingValues.push({ coupledSettingValue: undefined, currentSettingValue: '' });
                    dispatch({ type: 'setValue', key: 'coupledSetting.values', value: newCoupledSettingValues });
                    dispatch({
                        type: 'setValue',
                        key: 'values.values',
                        value: newValues,
                    });
                }}
                startIcon="plus-solid"
                variant="inline"
            />
        </Grid>
    );
};

export const CoupledSetting: FC<{ readonly editionSettingInstances: SettingInstance[] }> = ({
    editionSettingInstances,
}) => {
    return (
        <FormGroup conditions={{ 'coupledSetting.values': [va.required()] }}>
            <SelectCoupledSettingTemplateCode editionSettingInstances={editionSettingInstances} />
            <CoupledSettingValues editionSettingInstances={editionSettingInstances} />
        </FormGroup>
    );
};

export function assertLimitSettingInstance(values: Values): asserts values is ListOfValues {
    if (values.type !== ValueType.List) {
        throw new Error('Expected a ValueType.List');
    }
}
