import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';
import { MedicalRecordLabelStatus, MedicalReview } from 'models/MedicalReview';
import { VetNote } from 'models/PetVetNotes';

const petUrlResolver = getUrlResolver('pet-blender');

export async function fetchMedicalExamReviews(petPublicId: string): Promise<MedicalReview[]> {
    const url = petUrlResolver(`/api/v2/${petPublicId}/medical_exam/review/files`);

    return axios.get(url).then(response => response.data.data);
}

export async function updateMedicalExamReview({
    petId,
    filePublicId,
    userPublicId,
    reviewed,
    labelStatus,
}: {
    petId: string;
    filePublicId: string;
    userPublicId: string;
    reviewed: boolean;
    labelStatus?: MedicalRecordLabelStatus;
}): Promise<unknown> {
    const url = petUrlResolver(`/api/v2/${petId}/medical_exam/review/files`);

    const body = {
        filePublicId,
        userPublicId,
        reviewed,
        labelStatus,
    };

    return axios.put(url, body).then(response => response.data.data);
}

export async function getVetNotes(petPublicId: string): Promise<VetNote[]> {
    const url = petUrlResolver(`/api/v1/pets/${petPublicId}/vet_notes`);

    return axios.get<{ data: VetNote[] }>(url).then(response => response.data.data);
}
