import { Flex, FormProvider, generateTypedFormComponents, spacing, Text, useForm } from '@lemonade-hq/blender-ui';
import { AlertMode, Dialog, ErrorSection, LoadingSection } from '@lemonade-hq/bluis';
import { ma, va } from '@lemonade-hq/maschema-schema';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useCallback } from 'react';
import { referenceEditionSelect } from './UpdateRatingReferenceDialog.css';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';
import { EditionStatus } from 'models/LoCo/Insurance/BaseEdition';
import type { Release } from 'models/LoCo/Insurance/Release';
import { useGetProductEditionsSummary } from 'queries/LoCo/Insurance/ProductQueries';
import { useUpdateRatingReferenceEdition } from 'queries/LoCo/Insurance/ReleasesQueries';

const updateRatingReferenceEditionSchema = ma.record({
    ratingReferenceEditionCode: ma.string([va.required()]),
});

interface UpdateRatingReferenceDialogProps {
    readonly release: Release;
    readonly onClose: () => void;
}

const { Select } = generateTypedFormComponents<typeof updateRatingReferenceEditionSchema>();

export const UpdateRatingReferenceEditionForm: React.FC<{
    readonly isLoading: boolean;
    readonly onSubmit: (ratingReferenceEditionCode: string) => Promise<void>;
    readonly ratingLabels: Record<string, string>;
    readonly onClose: () => void;
    readonly releaseRatingEdition: Edition;
}> = ({ isLoading, onSubmit, onClose, ratingLabels, releaseRatingEdition }) => {
    const {
        values,
        validationResults: { valid },
    } = useForm<typeof updateRatingReferenceEditionSchema>();

    const onFormSubmit = useCallback(async () => {
        if (valid && isDefined(values.ratingReferenceEditionCode)) {
            await onSubmit(values.ratingReferenceEditionCode);
        }
    }, [values, valid, onSubmit]);

    return (
        <Dialog
            actions={[
                { type: 'close', text: 'Cancel', onClick: onClose },
                { type: 'submit', text: 'Publish release', onClick: onFormSubmit, disabled: !valid },
            ]}
            loading={isLoading}
            notice={[
                {
                    mode: AlertMode.Attention,
                    title: (
                        <Text>
                            Once you update the Reference Edition, all edition sets containing{' '}
                            {releaseRatingEdition.friendlyName} will be removed from testing environments. The Edition
                            Sets in all other releases containing {releaseRatingEdition.friendlyName} will be
                            recalculated
                        </Text>
                    ),
                },
            ]}
            size="medium"
            title="Update Rating Reference Edition"
        >
            <Flex alignItems="flex-start" flexDirection="column" gap={spacing.s16} justifyContent="center">
                <Text>Select the published rating edition being replaced by {releaseRatingEdition.friendlyName}</Text>
                <Select
                    className={referenceEditionSelect}
                    disabled={Object.values(ratingLabels).length === 1}
                    labels={ratingLabels}
                    placeholder="Select rating reference edition"
                    schemaKey="ratingReferenceEditionCode"
                />
            </Flex>
        </Dialog>
    );
};

export const UpdateRatingReferenceDialog: React.FC<UpdateRatingReferenceDialogProps> = ({ release, onClose }) => {
    const { mutateAsync, isPending } = useUpdateRatingReferenceEdition(release.publicId);

    const { data: productEditions, isLoading } = useGetProductEditionsSummary(release.productCode, {
        status: [EditionStatus.Published],
    });

    const onSubmit = useCallback(
        async (ratingReferenceEditionCode: string) => {
            await mutateAsync(ratingReferenceEditionCode);
            onClose();
        },
        [mutateAsync, onClose]
    );

    if (isLoading) {
        return <LoadingSection />;
    }

    const publishedRatingEditions = productEditions?.ratingEditions;

    const releaseRatingEdition = release.editions.rating;
    const referenceEditionCode = releaseRatingEdition?.referenceEditionContentCode;

    if (!isDefined(publishedRatingEditions) || !isDefined(releaseRatingEdition) || !isDefined(referenceEditionCode)) {
        return <ErrorSection />;
    }

    const options = publishedRatingEditions.map(edition => edition.code);
    const labels = publishedRatingEditions.reduce<Record<string, string>>((acc, edition) => {
        acc[edition.code] = edition.friendlyName;
        return acc;
    }, {});

    return (
        <FormProvider
            initialConfig={{
                additionalValidations: {
                    ratingReferenceEditionCode: [va.oneOf(options), va.noneOf([referenceEditionCode])],
                },
            }}
            initialValues={{
                ratingReferenceEditionCode: referenceEditionCode,
            }}
            schema={updateRatingReferenceEditionSchema}
        >
            <UpdateRatingReferenceEditionForm
                isLoading={isPending}
                onClose={onClose}
                onSubmit={onSubmit}
                ratingLabels={labels}
                releaseRatingEdition={releaseRatingEdition}
            />
        </FormProvider>
    );
};
