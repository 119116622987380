/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    ActionsMenu,
    AudioPlayer,
    CardGrid,
    CardGridArea,
    Flex,
    overflowYContainer,
    pageWrapper,
    shimmering,
    spacing,
    Text,
} from '@lemonade-hq/blender-ui';
import { clsx } from 'clsx';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useGetConversation } from '../Voice.queries';
import type { VoicePlaygroundTranscriptItem } from '../Voice.types';
import { VIEWED_CONVERSATIONS_LIST_KEY } from './VoiceConversation.consts';
import {
    addToolCallingToConversationSegments,
    voiceEventPresenter,
    voiceSegmentPresenter,
} from './VoiceConversations.helpers';

function getRecordingAudioSrc(recordingFilePublicId: string): string {
    return `${import.meta.env.VITE_FILES_SERVICE_URL}/files/${recordingFilePublicId}`;
}

export interface VoicePlaygroundConversationProps {
    readonly conversationPublicId?: string;
    readonly sideBarComponent: JSX.Element;
}

export const VoiceConversation: FC<VoicePlaygroundConversationProps> = ({ conversationPublicId, sideBarComponent }) => {
    const [, setViewedConversationsPublicIds] = useLocalStorage<string[]>(VIEWED_CONVERSATIONS_LIST_KEY, []);
    const { data: currentConversation, isPlaceholderData: isLoadingConversation } =
        useGetConversation(conversationPublicId);

    const knownTotalTime =
        new Date(currentConversation?.endedAt ?? 0).getTime() - new Date(currentConversation?.startedAt ?? 0).getTime();

    const segments = useMemo(() => addToolCallingToConversationSegments(currentConversation), [currentConversation]);
    const events = useMemo(() => currentConversation?.timeline ?? [], [currentConversation]);

    const audioSrc =
        isLoadingConversation || currentConversation == null
            ? undefined
            : getRecordingAudioSrc(currentConversation.recordingFilePublicId);

    useEffect(() => {
        setViewedConversationsPublicIds(curr => {
            if (conversationPublicId == null) return curr;
            if (curr.includes(conversationPublicId)) return curr;

            return [...curr, conversationPublicId];
        });
    }, [conversationPublicId, setViewedConversationsPublicIds]);

    return (
        <AudioPlayer.Provider knownTotalTime={knownTotalTime} mimeType="audio/wav" src={audioSrc}>
            <AudioPlayer.WaveformProvider frameDuration={10} normalize reverseChannels>
                <AudioPlayer.SegmentsProvider
                    isBackgroundSegment={segment => segment.side === 'tool'}
                    segmentPresenter={voiceSegmentPresenter}
                    segments={segments}
                >
                    <AudioPlayer.EventsProvider eventPresenter={voiceEventPresenter} events={events}>
                        <AudioPlayer.ZoomProvider initialZoom={1} maxZoom={15}>
                            <Flex
                                className={clsx(pageWrapper, { [shimmering]: isLoadingConversation })}
                                flexDirection="column"
                                gap={spacing.s16}
                                height="100%"
                                padding={spacing.s16}
                            >
                                <Flex flexGrow={0} flexShrink={1} gap={spacing.s16} height="100%" minHeight={0}>
                                    <CardGrid
                                        areas={[
                                            ['sidebar', 'header'],
                                            ['sidebar', 'content'],
                                        ]}
                                        gridTemplateColumns="2fr 5fr"
                                        gridTemplateRows="min-content 1fr"
                                        showSeparators
                                    >
                                        <CardGridArea
                                            areaName="sidebar"
                                            display="flex"
                                            flexDirection="column"
                                            gap={spacing.s02}
                                        >
                                            {sideBarComponent}
                                        </CardGridArea>
                                        <CardGridArea areaName="header" display="flex" justifyContent="space-between">
                                            <Flex flexDirection="column" gap={spacing.s02}>
                                                <Text type="h4">Audio Transcript</Text>
                                                <Text color="secondary">{conversationPublicId}</Text>
                                            </Flex>
                                            <ActionsMenu
                                                items={[
                                                    {
                                                        label: 'Download Recording',
                                                        disabled: audioSrc == null,
                                                        onSelect: () => window.open(audioSrc),
                                                    },
                                                ]}
                                            />
                                        </CardGridArea>
                                        <CardGridArea
                                            areaName="content"
                                            className={overflowYContainer({ paddingBlock: 's16' })}
                                        >
                                            <AudioPlayer.SegmentedList<VoicePlaygroundTranscriptItem>
                                                filterBy={segment => !isEmpty(segment.content)}
                                                segmentComponent={AudioPlayer.StructuredTranscriptItem}
                                            />
                                        </CardGridArea>
                                    </CardGrid>
                                </Flex>
                                <CardGrid
                                    areas={[['header'], ['content']]}
                                    flexGrow={1}
                                    flexShrink={0}
                                    gridTemplateRows="min-content"
                                    showSeparators
                                >
                                    <CardGridArea
                                        alignItems="center"
                                        areaName="header"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Text type="h4">Timeline</Text>
                                        <Flex alignItems="center" gap={spacing.s16} justifyContent="space-between">
                                            <AudioPlayer.SegmentedControls />
                                            <AudioPlayer.Durations />
                                        </Flex>
                                        <Flex alignItems="center" gap={spacing.s16} justifyContent="space-between">
                                            <AudioPlayer.MuteControls />
                                            <AudioPlayer.PlaybackRateControls />
                                            <Text color="tertiary">|</Text>
                                            <AudioPlayer.ZoomControls step={0.1} />
                                        </Flex>
                                    </CardGridArea>
                                    <CardGridArea areaName="content" padding={spacing.s16}>
                                        <AudioPlayer.SegmentedTimeline hideAudioSegmentsDetails showEventsDistances />
                                    </CardGridArea>
                                </CardGrid>
                            </Flex>
                        </AudioPlayer.ZoomProvider>
                    </AudioPlayer.EventsProvider>
                </AudioPlayer.SegmentsProvider>
            </AudioPlayer.WaveformProvider>
        </AudioPlayer.Provider>
    );
};
