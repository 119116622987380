import axios from 'axios';
import { ProductTypes } from 'models/Products';
import { PayeeType, PayableParty } from 'models/Payee';

const baseUrl = '/backoffice/claim_payees';

interface PayeeToAddBase {
    token: string;
    payable_party?: PayableParty;
    carrier?: string;
}

interface InsuredToAdd extends PayeeToAddBase {
    type: PayeeType.Insured;
    user_public_id: string;
    ssn?: string;
}

interface CoInsuredToAdd extends PayeeToAddBase {
    type: PayeeType.CoInsured;
    user_public_id: string;
    ssn?: string;
}

interface VendorsToAdd extends PayeeToAddBase {
    type: PayeeType.Vendor;
    entity_public_ids: string[];
}

interface ThirdPartyIndividualToAdd extends PayeeToAddBase {
    type: PayeeType.ThirdPartyIndividual;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    ssn?: string;
    description?: string;
}

interface ThirdPartyCompanyToAdd extends PayeeToAddBase {
    type: PayeeType.ThirdPartyCompany;
    name: string;
    tax_id: string;
    website_url: string;
    description?: string;
}

export type PayeeToAdd =
    | InsuredToAdd
    | ThirdPartyIndividualToAdd
    | ThirdPartyCompanyToAdd
    | CoInsuredToAdd
    | VendorsToAdd;

export type PayeeToAddWithoutToken =
    | Omit<InsuredToAdd, 'token'>
    | Omit<ThirdPartyIndividualToAdd, 'token'>
    | Omit<ThirdPartyCompanyToAdd, 'token'>
    | Omit<CoInsuredToAdd, 'token'>
    | Omit<VendorsToAdd, 'token'>;

export type PayeeToEditWithoutToken =
    | Omit<InsuredToAdd, 'token' | 'user_public_id'>
    | Omit<ThirdPartyIndividualToAdd, 'token'>
    | Omit<ThirdPartyCompanyToAdd, 'token'>
    | Omit<CoInsuredToAdd, 'token'>
    | Omit<VendorsToAdd, 'token'>;

export interface PayeeAccountDetails {
    public_id: string;
    ach: string;
    routing: string;
    carrier?: string;
}

export type PayeeToEdit = PayeeToEditWithoutToken & { public_id: string };

export async function addPayee({
    payee,
    claimPublicId,
}: {
    payee: PayeeToAdd | PayeeToAddWithoutToken;
    claimPublicId: string;
}): Promise<null> {
    return axios.post(baseUrl, { ...payee, claim_public_id: claimPublicId });
}

export async function editPayee({ payee, product }: { payee: PayeeToEdit; product: ProductTypes }): Promise<null> {
    const { type, ...restPayee } = payee;

    return axios.patch(`${baseUrl}/${restPayee.public_id}`, { ...restPayee, product });
}

export async function deletePayee({ payeeId }: { payeeId: string }): Promise<null> {
    return axios.delete(`${baseUrl}/${payeeId}`);
}

export async function updatePaymentToken({
    payee,
    product,
}: {
    payee: PayeeAccountDetails & { token: string };
    product: ProductTypes;
}): Promise<null> {
    return axios.patch(`${baseUrl}/${payee.public_id}`, { ...payee, product });
}
