/* eslint-disable @lemonade-hq/base/favor-singular-enums */
/* eslint-disable @typescript-eslint/naming-convention */
import { useOptimisticMutation } from '@lemonade-hq/blender-ui';
import type { UseBaseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { produce } from 'immer';
import noop from 'lodash/noop';
import { fetchHomeQuoteData } from 'apis/Home/QuotesAPI';
import type { ReviewRelatedEntity, UpdateReviewAttributes } from 'apis/Home/UnderwritingReviewsAPI';
import { getReviewRelatedEntity, updateReview } from 'apis/Home/UnderwritingReviewsAPI';
import { fetchHomePolicyData } from 'apis/HomePoliciesAPI';
import type {
    HomeownersReviewCoverages,
    HomeownersReviewDetails,
    HomeownersReviewOwnership,
    HomeownersReviewProperty,
    HomeownersReviewPropertyAddress,
    HomeownersReviewPropertyPhoto,
    HomeownersReviewPropertyStaticMap,
    HomeownersReviewUser,
    HomeownersReviewVeriskMortgage,
    HomeownersReviewZillow,
} from 'models/HomeOwnersReview';
import { HomeOwnersReviewEntity } from 'models/HomeOwnersReview';
import type { HomePolicySummary } from 'models/HomePolicy';

export enum HomeQuoteQueryKeys {
    GetHomeOwnersReview = 'get_homeowners_review',
    GetHomeOwnersReviewDetails = 'get_homeowners_review_details',
    GetHomeOwnersReviewRelatedEntity = 'get_homeowners_review_related_entity',
}

export interface HomeownersReviewDataResponse {
    readonly homeowners_review_ownership: HomeownersReviewOwnership;
    readonly homeowners_review_property: HomeownersReviewProperty;
    readonly homeowners_review_coverages: HomeownersReviewCoverages;
    readonly homeowners_review_verisk_mortgages: HomeownersReviewVeriskMortgage[];
    readonly homeowners_review_property_address: HomeownersReviewPropertyAddress | null;
    readonly homeowners_review_property_static_map?: HomeownersReviewPropertyStaticMap;
    readonly homeowners_review_property_photos: HomeownersReviewPropertyPhoto[];
    readonly homeowners_review_zillow: HomeownersReviewZillow;
    readonly homeowners_review_details: HomeownersReviewDetails | null;
    readonly homeowners_review_user: HomeownersReviewUser;
    readonly policy_summary: HomePolicySummary;
}

export type HomeownersReviewLocalMutation = {
    readonly data: HomeownersReviewDataResponse;
    readonly variables: UpdateReviewAttributes;
};

export type HomeownersReviewMutationResult = UseBaseMutationResult<null, unknown, UpdateReviewAttributes>;

type HomeownersReview = Pick<
    HomeownersReviewDataResponse,
    | 'homeowners_review_coverages'
    | 'homeowners_review_details'
    | 'homeowners_review_ownership'
    | 'homeowners_review_property_address'
    | 'homeowners_review_property_photos'
    | 'homeowners_review_property_static_map'
    | 'homeowners_review_property'
    | 'homeowners_review_user'
    | 'homeowners_review_verisk_mortgages'
    | 'homeowners_review_zillow'
>;

type HomeownersRenewalReviewDetails = Partial<
    Pick<
        HomeownersReviewDataResponse,
        | 'homeowners_review_coverages'
        | 'homeowners_review_details'
        | 'homeowners_review_ownership'
        | 'homeowners_review_property_photos'
        | 'homeowners_review_property'
        | 'homeowners_review_user'
        | 'homeowners_review_verisk_mortgages'
        | 'policy_summary'
    >
>;

async function getHomeownersQuoteReview(quoteId: string): Promise<HomeownersReview> {
    return await fetchHomeQuoteData(quoteId, [
        'homeowners_review_ownership',
        'homeowners_review_property',
        'homeowners_review_coverages',
        'homeowners_review_verisk_mortgages',
        'homeowners_review_property_address',
        'homeowners_review_property_static_map',
        'homeowners_review_property_photos',
        'homeowners_review_zillow',
        'homeowners_review_details',
        'homeowners_review_user',
    ]);
}

async function getHomeownersPolicyReview(policyId: string): Promise<HomeownersReview> {
    return await fetchHomePolicyData(policyId, [
        'homeowners_review_ownership',
        'homeowners_review_property',
        'homeowners_review_coverages',
        'homeowners_review_verisk_mortgages',
        'homeowners_review_property_address',
        'homeowners_review_property_static_map',
        'homeowners_review_property_photos',
        'homeowners_review_zillow',
        'homeowners_review_details',
        'homeowners_review_user',
    ]);
}

async function getHomeownersPolicyRenewalReview(policyId: string): Promise<HomeownersRenewalReviewDetails> {
    return await fetchHomePolicyData(policyId, [
        'homeowners_review_ownership',
        'homeowners_review_property',
        'homeowners_review_coverages',
        'homeowners_review_verisk_mortgages',
        'homeowners_review_user',
        'homeowners_review_details',
        'homeowners_review_property_photos',
        'policy_summary',
        'user_summary',
    ]);
}

export function useHomeownersReview(
    entityId: string | undefined,
    entityType: HomeOwnersReviewEntity
): UseQueryResult<HomeownersReview> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetHomeOwnersReview, entityId],

        queryFn: async () => {
            if (entityId === undefined) {
                return {};
            }

            switch (entityType) {
                case HomeOwnersReviewEntity.Quote:
                    return await getHomeownersQuoteReview(entityId);
                case HomeOwnersReviewEntity.Policy:
                    return await getHomeownersPolicyReview(entityId);
                default:
                    throw new Error('Invalid entity type');
            }
        },
    });
}

export function useRenewalHomeownersReview(
    entityId: string | undefined
): UseQueryResult<HomeownersRenewalReviewDetails> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetHomeOwnersReview, entityId],

        queryFn: async () => {
            if (entityId === undefined) {
                return {};
            }

            return await getHomeownersPolicyRenewalReview(entityId);
        },
    });
}

export function useUpdateHomeownersReview(quoteId: string): HomeownersReviewMutationResult {
    const queryKey = [HomeQuoteQueryKeys.GetHomeOwnersReview, quoteId];

    function mutateHomeOwnersReviewLocally({ data, variables }: HomeownersReviewLocalMutation) {
        const { properties } = variables;

        return produce(data, draft => {
            if (draft.homeowners_review_details != null) {
                draft.homeowners_review_details.operatorId = Number(properties.reviewer_id ?? null);
            }
        });
    }

    const args = {
        mutationFn: updateReview,
        mutate: mutateHomeOwnersReviewLocally,
        mutateKey: [queryKey],
        invalidateKeys: [queryKey],
    };

    return useOptimisticMutation(args);
}

export function usePolicyHomeownersReviewDetails(
    policyId: string
): UseQueryResult<Pick<HomeownersReview, 'homeowners_review_details'>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetHomeOwnersReviewDetails, policyId],

        queryFn: async () => await fetchHomePolicyData(policyId, ['homeowners_review_details']),
    });
}

export function useQuoteHomeownersReviewDetails(
    quoteId?: string
): UseQueryResult<Pick<HomeownersReview, 'homeowners_review_details'>> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetHomeOwnersReviewDetails, quoteId],
        queryFn: quoteId ? async () => await fetchHomeQuoteData(quoteId, ['homeowners_review_details']) : noop,
    });
}

export function useHomeownerReviewRelatedEntity(reviewId: string): UseQueryResult<ReviewRelatedEntity> {
    return useQuery({
        queryKey: [HomeQuoteQueryKeys.GetHomeOwnersReviewRelatedEntity, reviewId],
        queryFn: async () => await getReviewRelatedEntity(reviewId),
    });
}
