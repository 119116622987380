import { NoticeFilledIcon } from '@lemonade-hq/bluis';
import { font } from '@lemonade-hq/boutique';
import { Flex } from '@lemonade-hq/cdk';
import { COLORS } from '@lemonade-hq/tokens';
import { capitalize } from '@lemonade-hq/ts-helpers';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { AttachmentPreviewItem } from './AttachmentPreviewItem';
import { getInlineUrl } from './utils';
import { getLmndDetectionResult, getRaiDetectionResult } from 'commons/AttachmentsUtils';
import type { Attachment, Indicator } from 'models/Attachment';

const PdfContainer = styled.iframe`
    height: 86vh;
    border: 0px none;
    border-radius: 10px;
    width: 100%;
    margin-top: 20px;
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
`;

const Section = styled(Flex)`
    width: 45%;
    height: 100%;
    color: ${COLORS.white};
`;

const TextSection = styled(Flex)`
    color: ${COLORS.white};
`;

const Header = styled.div`
    ${font('main', { fontWeight: 700 })}
    font-size: 16px;
    color: ${COLORS.white};
    text-transform: uppercase;
    margin-top: 10px;
`;

const TextContentContainer = styled.div`
    width: 400px;
    height: fit-content;
    max-height: 60vh;
    background: ${COLORS.black};
    padding: 15px;
    border-radius: 5px;
    overflow: auto;
    font-size: 14px;
    line-height: 23px;

    li {
        margin-bottom: 5px;
    }
`;

const SubHeader = styled(Flex)`
    gap: 0 8px;
`;

const CompareModeWrapper = styled(Flex)`
    width: 100%;
    gap: 0 2%;
    height: 100%;
`;

const IndicatorTitle = styled.div`
    font-weight: bold;
`;

function groupIndicatorsByType(indicators?: Indicator[]) {
    if (indicators == null) return [];

    return indicators.reduce((acc, indicator) => {
        const { type } = indicator;

        if (acc[type] == null) {
            acc[type] = [];
        }

        acc[type].push(indicator);
        return acc;
    }, {});
}

interface ComparePreviewProps {
    readonly attachment: Attachment;
    readonly zoom?: number;
    readonly isSelected?: boolean;
}

export const ComparePreview: React.FC<React.PropsWithChildren<ComparePreviewProps>> = ({
    attachment,
    isSelected = false,
    zoom = 100,
}) => {
    const { fraudDoc } = attachment;
    const raiDetectionResult = getRaiDetectionResult(attachment);
    const lmndDetectionResult = getLmndDetectionResult(attachment);

    // show comparison when we have a fraudDoc or when we have a legacy fraud detection
    const isCompareMode = useMemo((): boolean => {
        return Boolean(fraudDoc?.annotatedSource != null && fraudDoc.annotatedReconstructed != null);
    }, [fraudDoc?.annotatedReconstructed, fraudDoc?.annotatedSource]);

    const originalSrc = useMemo(() => {
        return getInlineUrl(attachment.fraudDoc?.annotatedSource);
    }, [attachment.fraudDoc?.annotatedSource]);

    const modifiedSrc = useMemo(() => {
        return getInlineUrl(attachment.fraudDoc?.annotatedReconstructed);
    }, [attachment.fraudDoc?.annotatedReconstructed]);

    const textChanges = useMemo(() => {
        return lmndDetectionResult?.predictionDetails?.textChanges ?? fraudDoc?.textChanges ?? {};
    }, [fraudDoc?.textChanges, lmndDetectionResult?.predictionDetails?.textChanges]);

    const hasTextChanges = useMemo(() => {
        return Object.keys(textChanges).length > 0;
    }, [textChanges]);

    const hasIndicators = useMemo(() => {
        return raiDetectionResult?.indicators != null && raiDetectionResult.indicators.length > 0;
    }, [raiDetectionResult]);

    const renderModification = useMemo(() => {
        if (hasTextChanges) {
            return (
                <ul>
                    {Object.keys(textChanges).map(key => (
                        <li key={key}>
                            {textChanges[key].map((str, index) => (
                                <div key={index}>{`Page ${key}, Text: "${str}"`}</div>
                            ))}
                        </li>
                    ))}
                </ul>
            );
        } else if (hasIndicators) {
            const groupedIndicators = groupIndicatorsByType(raiDetectionResult?.indicators);

            return Object.keys(groupedIndicators).map(key => (
                <>
                    <SubHeader alignItems="center" mb={12}>
                        <NoticeFilledIcon color={COLORS.darkGray} />
                        <b>{capitalize(key.toLocaleLowerCase())} Indicators</b>
                    </SubHeader>
                    <ul>
                        {groupedIndicators[key].map((indicator: Indicator) => (
                            <li key={indicator.indicator_id}>
                                <IndicatorTitle>{indicator.title}</IndicatorTitle>
                                {indicator.description}
                            </li>
                        ))}
                    </ul>
                </>
            ));
        }

        return null;
    }, [hasIndicators, hasTextChanges, raiDetectionResult?.indicators, textChanges]);

    return (
        <Flex alignItems="flex-start" flexDirection="column" height="100%" width="100%">
            <Content>
                {isCompareMode ? (
                    <Flex flexDirection="column">
                        <CompareModeWrapper alignItems="center" justifyContent="center">
                            <Section alignItems="center" flexDirection="column">
                                <Header>original</Header>
                                <PdfContainer src={originalSrc} />
                            </Section>
                            <Section alignItems="center" flexDirection="column">
                                <Header>
                                    modified <NoticeFilledIcon color={COLORS.pink} />
                                </Header>
                                <PdfContainer src={modifiedSrc} />
                            </Section>
                        </CompareModeWrapper>
                    </Flex>
                ) : (
                    <CompareModeWrapper alignItems="center" justifyContent="center">
                        <Section alignItems="center" flexDirection="column">
                            <AttachmentPreviewItem attachment={attachment} isSelected={isSelected} zoom={zoom} />
                        </Section>
                        {hasTextChanges || hasIndicators ? (
                            <TextSection alignItems="flex-start" flexDirection="column">
                                <TextContentContainer>
                                    {hasTextChanges && (
                                        <SubHeader alignItems="center" mb={12}>
                                            <NoticeFilledIcon color={COLORS.darkGray} />
                                            The following text on this document might have been modified:
                                        </SubHeader>
                                    )}
                                    {renderModification}
                                </TextContentContainer>
                            </TextSection>
                        ) : null}
                    </CompareModeWrapper>
                )}
            </Content>
        </Flex>
    );
};
