import { Flex, generateTypedFormComponents, Grid, Select, useForm } from '@lemonade-hq/blender-ui';

import { va } from '@lemonade-hq/maschema-schema';
import { getOptionsFromSchema } from 'libs/blender-ui/src/components/Form/utils';
import type { FC } from 'react';
import { useMemo } from 'react';
import { CoupledSetting } from './CoupledSetting';
import type { ManageSettingDialogFormProps } from './ManageSettingDialog';
import { manageSettingDialogSchema } from './manageSettingDialogSchema';
import { dynamicListWrapper, separator } from './styles.css';
import { VisibilityWrapper } from './VisibilityWrapper';
import {
    coInsuranceVariantDisplayName,
    getDurationTypeDisplayName,
    getDurationUnitDisplayName,
    getInsuranceScopeTypeDisplayName,
    getUnitDisplayName,
    getValueSelectionMethodDisplayName,
    getValueType,
    SETTING_TO_DISPLAY_NAME,
} from 'components/LoCo/common/display-texts/setting-instance';
import {
    CoinsuranceVariant,
    DurationType,
    DurationUnit,
    EntityScopeValueSelectionMethod,
    InsuranceScopeType,
    SettingUnit,
    ValueType,
} from 'models/LoCo/Insurance/CoveragesEdition';
import type { SettingTemplate } from 'models/LoCo/Insurance/Registry';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

export const { InputGroup, DynamicList } = generateTypedFormComponents<typeof manageSettingDialogSchema>();

// @ts-expect-error -- not sure why ts is complaining about this
export const HorizontalInputGroup: typeof InputGroup = props => <InputGroup {...props} direction="horizontal" />;

export const FormGroup = VisibilityWrapper<typeof manageSettingDialogSchema>;

const SettingTypeSelect: FC<{ readonly settingsRegistry: SettingTemplate[] }> = ({ settingsRegistry }) => {
    const { values } = useForm<typeof manageSettingDialogSchema>();

    return (
        <HorizontalInputGroup
            disabled
            inputComponent="Select"
            label="Type"
            labels={SETTING_TO_DISPLAY_NAME}
            placeholder="Select"
            rules={[
                {
                    conditions: {
                        templateCode: [va.required()],
                    },
                    actions: [
                        {
                            type: 'setValue',
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- I have no idea why ESLint thinks this is necessary
                            value: settingsRegistry.find(({ code }) => code === values.templateCode)?.type,
                        },
                    ],
                },
            ]}
            schemaKey="type"
        />
    );
};

const COUPLED_SETTING_VALUE_TYPE = 'coupled';

const ListValues: FC = () => {
    const { values } = useForm<typeof manageSettingDialogSchema>();
    if (values.values?.type === ValueType.List && values.coupledSetting?.coupledSettingTemplateCode === undefined) {
        return (
            <DynamicList
                canAddAbove={false}
                className={dynamicListWrapper}
                newItemTemplate={0}
                schemaKey="values.values"
            />
        );
    }
};

const ValuesTypesSelect: FC = () => {
    const { dispatch, values } = useForm<typeof manageSettingDialogSchema>();

    const optionsFromSchema = useMemo(() => {
        return getOptionsFromSchema<string>(manageSettingDialogSchema, 'values.type', values, 'single');
    }, [values]);

    const selectedKey =
        values.coupledSetting?.coupledSettingTemplateCode !== undefined
            ? COUPLED_SETTING_VALUE_TYPE
            : values.values?.type;
    return (
        <HorizontalInputGroup label="Values type" schemaKey="values.type">
            <Select
                onChange={selectedValue => {
                    if (selectedValue === COUPLED_SETTING_VALUE_TYPE) {
                        dispatch({ key: 'values.type', type: 'setValue', value: ValueType.List });
                        dispatch({
                            key: 'coupledSetting',
                            type: 'setValue',
                            value: { coupledSettingTemplateCode: '', values: [] },
                        });
                    } else {
                        dispatch({ key: 'values.type', type: 'setValue', value: selectedValue as ValueType });
                        if (values.coupledSetting?.coupledSettingTemplateCode !== undefined) {
                            dispatch({
                                key: 'coupledSetting',
                                type: 'setValue',
                                value: undefined,
                            });
                        }
                    }
                }}
                options={optionsFromSchema
                    .map(option => ({ label: getValueType(option as ValueType), value: option }))
                    .concat([{ label: 'Coupled with Other Setting', value: COUPLED_SETTING_VALUE_TYPE }])}
                placeholder="Select"
                selectedKey={selectedKey}
            />
        </HorizontalInputGroup>
    );
};

export const ManageSettingDialogForm: FC<ManageSettingDialogFormProps> = ({
    settingsRegistry,
    insurableEntities,
    editionCoverageInstances,
    editionSettingInstances,
}) => {
    return (
        <Flex flexDirection="column" gap="1rem">
            <HorizontalInputGroup
                inputComponent="ComboBox"
                label="Setting"
                labels={Object.fromEntries(settingsRegistry.map(({ code, name }) => [code, name]))}
                placeholder="Select"
                schemaKey="templateCode"
            />
            <SettingTypeSelect settingsRegistry={settingsRegistry} />
            <HorizontalInputGroup
                inputComponent="Select"
                label="Coverages"
                labels={Object.fromEntries(
                    editionCoverageInstances.map(({ templateCode, name }) => [templateCode, name])
                )}
                mode="multiple"
                placeholder="Select"
                rules={[
                    { conditions: { relatedCoverages: [va.nullish()] }, actions: [{ type: 'setValue', value: [] }] },
                ]}
                schemaKey="relatedCoverages"
            />
            <hr className={separator} />
            <ValuesTypesSelect />
            <CoupledSetting editionSettingInstances={editionSettingInstances} />
            <Grid gridTemplateColumns="1fr 300px">
                <ListValues />
            </Grid>
            <HorizontalInputGroup
                inputComponent="Checkbox"
                label="Unlimited"
                rules={[
                    {
                        conditions: {
                            type: [va.is(SettingType.Limit)],
                            'coupledSetting.values': [va.nullish()],
                            'values.type': [va.is(ValueType.List)],
                        },
                        actions: [{ type: 'setVisible', value: true }],
                    },
                    {
                        conditions: { type: [va.noneOf([SettingType.Limit])] },
                        actions: [
                            { type: 'setVisible', value: false },
                            { type: 'setValue', value: false },
                        ],
                    },
                    {
                        conditions: { 'values.type': [va.noneOf([ValueType.List])] },
                        actions: [
                            { type: 'setVisible', value: false },
                            { type: 'setValue', value: false },
                        ],
                    },
                    {
                        conditions: { 'coupledSetting.values': [va.required()] },
                        actions: [
                            { type: 'setVisible', value: false },
                            { type: 'setValue', value: false },
                        ],
                    },
                ]}
                schemaKey="values.includeUnlimited"
            />
            <FormGroup
                conditions={{
                    'values.type': [va.is(ValueType.Range)],
                }}
            >
                <HorizontalInputGroup
                    inputComponent="Input"
                    label="Minimum"
                    min={0}
                    schemaKey="values.min"
                    type="number"
                />
                <HorizontalInputGroup
                    inputComponent="Input"
                    label="Maximum"
                    min={0}
                    schemaKey="values.max"
                    type="number"
                />
                <HorizontalInputGroup
                    inputComponent="Input"
                    label="Interval Size"
                    min={0}
                    schemaKey="values.step"
                    type="number"
                />
            </FormGroup>
            <hr className={separator} />
            <FormGroup conditions={{ type: [va.oneOf([SettingType.Limit, SettingType.Deductible])] }}>
                <HorizontalInputGroup
                    inputComponent="Select"
                    label="Scope"
                    labels={Object.fromEntries(
                        Object.values(InsuranceScopeType).map(v => [v, getInsuranceScopeTypeDisplayName(v)])
                    )}
                    placeholder="Select"
                    schemaKey="scope.type"
                    tooltip="Should this Setting be applied as a total for the entire policy, or separately to different entities covered by the policy? \n(for example: a Per-Driver or Per-Vehicle limit)"
                />
                <FormGroup conditions={{ 'scope.type': [va.is(InsuranceScopeType.ExternalEntity)] }}>
                    <HorizontalInputGroup
                        inputComponent="Input"
                        label="Entity Name"
                        schemaKey="scope.name"
                        tooltip='Name the entity which is considered the scope of this limit (for example: "Tree" for a Per-Tree
                    limit)'
                    />
                </FormGroup>
                <FormGroup conditions={{ 'scope.type': [va.is(InsuranceScopeType.InsuredEntity)] }}>
                    <HorizontalInputGroup
                        inputComponent="Select"
                        label="Insured Entity"
                        labels={Object.fromEntries(insurableEntities.map(({ code, name }) => [code, name]))}
                        placeholder="Select"
                        schemaKey="scope.insuredEntityCode"
                        tooltip="Which entity should this setting apply to?"
                    />
                    <HorizontalInputGroup
                        inputComponent="Select"
                        label="Value Selection"
                        labels={Object.fromEntries(
                            Object.values(EntityScopeValueSelectionMethod).map(v => [
                                v,
                                getValueSelectionMethodDisplayName(v),
                            ])
                        )}
                        placeholder="Select"
                        schemaKey="scope.valueSelectionMethod"
                        tooltip="Can customers select different values for each insured entity on their policy,\nor should the same value be used for each entity in a policy?"
                    />
                </FormGroup>
                <HorizontalInputGroup
                    inputComponent="Select"
                    label="Duration"
                    labels={Object.fromEntries(
                        Object.values(DurationType).map(v => [v, getDurationTypeDisplayName(v)])
                    )}
                    placeholder="Select"
                    schemaKey="duration.type"
                    tooltip="Should this setting be applied across the entire policy term, applied separately per event,\nor reset on a fixed timeframe? (for example: a daily or weekly limit)"
                />
                <FormGroup conditions={{ 'duration.type': [va.oneOf([DurationType.Timespan])] }}>
                    <HorizontalInputGroup
                        inputComponent="Select"
                        label="Time Span"
                        placeholder="Select"
                        schemaKey="duration.unit"
                    />
                    <HorizontalInputGroup
                        inputComponent="Input"
                        label="Amount"
                        min={0}
                        schemaKey="duration.amount"
                        type="number"
                    />
                </FormGroup>
                <FormGroup conditions={{ type: [va.noneOf([SettingType.Deductible])] }}>
                    <HorizontalInputGroup
                        inputComponent="Select"
                        label="Unit"
                        labels={Object.fromEntries(Object.values(SettingUnit).map(v => [v, getUnitDisplayName(v)]))}
                        placeholder="Select"
                        schemaKey="unit"
                    />
                </FormGroup>
                <FormGroup
                    conditions={{
                        unit: [
                            va.if({
                                conditions: { type: [va.noneOf([SettingType.Deductible])] },
                                validations: [va.is(SettingUnit.Currency)],
                            }),
                        ],
                    }}
                >
                    <HorizontalInputGroup
                        inputComponent="Select"
                        label="Currency Unit"
                        placeholder="Select"
                        schemaKey="currencyUnit"
                    />
                </FormGroup>
            </FormGroup>
            <FormGroup conditions={{ type: [va.is(SettingType.Limit)] }}>
                <HorizontalInputGroup
                    cancelable
                    inputComponent="Select"
                    label="Sublimit of"
                    labels={Object.fromEntries(
                        editionSettingInstances.map(({ templateCode, name }) => [templateCode, name])
                    )}
                    placeholder="Not a sublimit"
                    schemaKey="parentLimitTemplateCode"
                    tooltip="Is this a sublimit of another limit?"
                />
            </FormGroup>
            <FormGroup conditions={{ type: [va.is(SettingType.Coinsurance)] }}>
                <HorizontalInputGroup
                    cancelable
                    inputComponent="Select"
                    label="Variant"
                    labels={Object.fromEntries(
                        Object.values(CoinsuranceVariant).map(v => [v, coInsuranceVariantDisplayName(v)])
                    )}
                    placeholder="Select"
                    schemaKey="variant"
                />
            </FormGroup>
            <FormGroup conditions={{ type: [va.is(SettingType.WaitingPeriod)] }}>
                <HorizontalInputGroup
                    inputComponent="Select"
                    label="Duration Unit"
                    labels={Object.fromEntries(
                        Object.values(DurationUnit).map(v => [v, getDurationUnitDisplayName(v)])
                    )}
                    placeholder="Select"
                    schemaKey="durationUnit"
                />
            </FormGroup>
        </Flex>
    );
};
