import type { Proportion } from '@lemonade-hq/ts-helpers';
import type { TaggedUnion } from 'type-fest';
import { EventEmitter } from '../utils/event_emitter';

type AudioSourceEvent = TaggedUnion<
  'type',
  {
    readonly start: NonNullable<unknown>;
    readonly stop: NonNullable<unknown>;
    readonly media: { readonly data: string };
    readonly volume: { readonly volume: Proportion };
  }
>;

export abstract class AudioSource extends EventEmitter<AudioSourceEvent> {
  public isPlaying: boolean = false;
  abstract start(): Promise<void>;
  abstract stop(): Promise<void>;
}
