/* eslint-disable @typescript-eslint/naming-convention */
import type { FeatureType } from './Features';
import type { PayeeType } from './Payee';
import type { Vehicle } from './Vehicle';

export enum HandlingType {
    Managed = 'managed',
    SelfServe = 'self_serve',
}

export interface PaymentInfo {
    readonly is_final: boolean;
    readonly deductible_previously_applied?: boolean;
    readonly force_waive_deductible?: boolean;
    readonly invoice: Invoice;
    readonly scheduled_payment_at?: Date;
    readonly over_limit?: string;
}

export type PaymentUser = {
    readonly public_id: string;
    readonly provider_type: string;
    readonly provider_id: string;
    readonly provider_customer_url: string;
    readonly user_external_id: string;
};

type ItemBase = {
    readonly entity_id: string;
    readonly title: string;
    readonly cost: number;
    readonly feature_type?: string;
};

type CarItem = ItemBase & {
    readonly involved_party: string;
};

export type Item = CarItem | ItemBase;

export const isCarItem = (item: Item): item is CarItem => 'involved_party' in item;

export enum InvoiceStatus {
    Created = 'created',
    Transaction_Linked = 'transaction_linked',
    InTransit = 'in_transit',
    Paid = 'paid',
    Error = 'error',
    Canceled = 'canceled',
    PartiallyPaid = 'partially_paid',
}

interface InvoiceTxMetadata {
    readonly carrier: string;
    readonly claimId: string;
    readonly env_id: string;
    readonly initiated_by_principal: string;
    readonly on_session: boolean;
    readonly policy_id: string;
    readonly product: string;
    readonly invoice_ids?: string; // comma separated list of invoice ids
}

interface InvoiceTx {
    readonly metadata: InvoiceTxMetadata;
}

export interface Invoice {
    readonly payee_type?: PayeeType;
    readonly id: string;
    readonly payee_name: string;
    readonly payment_method_type: PaymentMethodType;
    readonly currency: string;
    readonly total: number;
    readonly deductible?: number;
    readonly waived_deductible?: boolean;
    readonly items: Item[];
    readonly feature_type: string;
    readonly item_type?: string;
    readonly status: InvoiceStatus;
    readonly tx?: InvoiceTx[];
    readonly issued_by_employee?: string;
}

export interface SubscriptionPayments {
    readonly processed: PolicyProcessedTransaction[];
    readonly scheduled: PolicyScheduledTransaction[];
    readonly disputed?: PolicyDisputedTransactionBase[];
}

export interface PpmSubscriptionPayments extends SubscriptionPayments {
    readonly processed: PolicyProcessedTransactionPPM[];
}

export interface PayoutOpts {
    readonly statementDescriptor?: string;
}

export enum PaymentMethodType {
    Card = 'card',
    BankAccount = 'bank_account',
    Manual = 'manual',
    PaperCheck = 'paper_check',
    InternalPayment = 'internal_payment',
    VirtualCard = 'virtual_card',
    Escrow = 'escrow',
}

export enum ApprovalDecisionReason {
    AmountTooHigh = 'amount_too_high',
    SuspiciousClaims = 'suspicious_claims',
}

export type PaperCheckAddress = PaperCheckAddressCamelCased & PaperCheckAddressSnakeCased;

export interface PaperCheckAddressCamelCased {
    readonly name: string;
    readonly company: string;
    readonly addressLine1: string;
    readonly addressLine2: string;
    readonly zip: string;
    readonly city: string;
    readonly state: string;
    readonly country: string;
    readonly description: string;
}

export interface PaperCheckAddressSnakeCased {
    readonly name: string;
    readonly company: string;
    readonly address_line1: string;
    readonly address_line2: string;
    readonly zip: string;
    readonly city: string;
    readonly state: string;
    readonly country: string;
    readonly description: string;
}

export type Transaction = {
    readonly public_id: string;
    readonly amount: number;
    readonly currency: string;
    readonly done_processing_at?: string;
    readonly due_at: string;
    readonly error_additional_info?: string;
    readonly error_code?: string;
    readonly error_message?: string;
    readonly is_self_serve_error?: boolean;
    readonly processing_started_at?: string;
    readonly provider: string;
    readonly provider_processor?: string;
    readonly provider_id: string;
    readonly provider_url: string;
    readonly status: TransactionStatus;
    readonly type: TransactionType;
    readonly origin: TransactionOrigin;
    readonly invoice: Invoice;
    readonly payment_method: {
        readonly type: PaymentMethodType;
        readonly user_type?: string;
        readonly address?: PaperCheckAddress;
    };
    readonly payment_user?: {
        readonly id: string;
        readonly type: string;
        readonly url: string;
    };
    readonly reversal_requests: ReversalRequest[];
    readonly debug_actions?: DebugActions;
    readonly user_notified?: boolean;
    readonly attachments: { readonly url: string; readonly file_name: string }[];
    readonly cancellation_token?: string;
    readonly cancellation_reason?: string;
    readonly message?: string;
    readonly payor?: string;
    readonly last_failed_payout?: { readonly handling_type?: HandlingType; readonly public_id: string };
    readonly expected_delivery_date?: string;
    readonly reissue_flow: ReissueFlowType;
};

export const isBulkPayment = (transaction: Transaction): boolean => {
    return (transaction.invoice.tx?.[0].metadata.invoice_ids ?? '').split(',').length > 1;
};

export enum ReissueFlowType {
    PaperCheckReissueAlternative = 'paper_check_reissue_alternative',
    ManualReissueAlternative = 'manual_reissue_alternative',
    Disabled = 'disabled',
}

export enum DebugActionTypes {
    MARK_AS_SUCCESSFUL = 'mark_successful',
    MARK_AS_FAILED = 'mark_failed',
}

export interface DebugActions {
    readonly [DebugActionTypes.MARK_AS_SUCCESSFUL]?: boolean;
    readonly [DebugActionTypes.MARK_AS_FAILED]?: boolean;
}

export interface Breakdown {
    readonly amount: string;
}

interface FeesBreakdown extends Breakdown {
    readonly code: string;
}

interface PremiumBreakdown extends Breakdown {
    readonly premium_code: string;
}

interface TaxesBreakdown extends Breakdown {
    readonly code: string;
}

// ---------------------- POLICY PROCESSED TRANSACTION ----------------------

export type PolicyProcessedTransactionBase = {
    readonly public_id: string;
    readonly amount: number;
    readonly actions?: string[];
    readonly currency: string;
    readonly done_processing_at?: string;
    readonly due_at: string;
    readonly installment: number;
    readonly origin: TransactionOrigin;
    readonly error_additional_info?: string;
    readonly error_code?: string;
    readonly error_message?: string;
    readonly processing_started_at?: string;
    readonly payment_method?:
        | PaymentMethodType
        | {
              readonly payment_method_type: PaymentMethodType;
          };
    readonly provider: string;
    readonly provider_processor?: string;
    readonly provider_id: string;
    readonly provider_url: string;
    readonly status: TransactionStatus;
    readonly type: TransactionType;
    readonly payment_user: PaymentUser;
    readonly reference_transaction_id?: string;
    readonly allow_retry_failed?: boolean;
    readonly invoice_id: string;
    readonly invoice?: { readonly public_id: string };
    readonly fees?: FeesBreakdown[];
    readonly downpayments?: number;
    readonly premiums_breakdown?: PremiumBreakdown[];
};

export interface PolicyProcessedTransactionFixed extends PolicyProcessedTransactionBase {
    readonly billing_type: 'fixed';
}

export interface PolicyProcessedTransactionPPM extends PolicyProcessedTransactionBase {
    readonly billing_type: 'ppm';
    readonly billed_vehicles: BilledVehicle[];
}

export type PolicyProcessedTransaction = PolicyProcessedTransactionFixed | PolicyProcessedTransactionPPM;

// ---------------------- POLICY SCHEDULED TRANSACTION ----------------------

export type PolicyScheduledTransactionBase = {
    readonly due_at: string;
    readonly origin: TransactionOrigin;
    readonly installment?: number;
    readonly amount: number | string;
    readonly downpayments?: number;
    readonly currency?: string;
};

export interface PolicyScheduledTransactionFixed extends PolicyScheduledTransactionBase {
    readonly billing_type: 'fixed';
}

export interface PolicyScheduledTransactionPPM extends PolicyScheduledTransactionBase {
    readonly billing_type: 'ppm';
    readonly accumulated_miles?: number;
    readonly billed_vehicles?: BilledVehicle[];
}

export type PolicyScheduledTransaction = PolicyScheduledTransactionFixed | PolicyScheduledTransactionPPM;

export interface BillingEvent {
    readonly event_date: Date;
    readonly reported_miles: number;
    readonly billed_miles: number;
    readonly gap_miles?: number;
    readonly odometer_mileage?: number;
    readonly mile_rate: number;
    readonly charge_type: string;
    readonly billed_amount: number;
}

export enum BillingStrategy {
    OBDOdometer = 'obd-odometer',
    GpsTrips = 'gps-trips',
}

export interface BillingSummary {
    readonly base_rate: number;
    readonly adjustments: number;
    readonly milage: number;
    readonly billed_miles: number;
    readonly mile_rate: number;
    readonly total: number;
    readonly billing_strategy: BillingStrategy;
}

export interface BasePremiumChange {
    readonly previous_value: number;
    readonly current_value: number;
    readonly effective_from: string;
}

export interface BilledVehicle {
    readonly vehicle: Vehicle;
    readonly billing_events: BillingEvent[];
    readonly billing_summary: BillingSummary;
    readonly premium_changes?: BasePremiumChange[];
}

export interface ReversalRequest {
    readonly public_id: string;
    readonly status: ReversalRequestStatus;
}

export type PolicyDisputedTransactionBase = {
    readonly actions: string[];
    readonly public_id: string;
    readonly due_at: string;
    readonly origin: TransactionOrigin;
    readonly amount: number | string;
    readonly status: string;
    readonly invoice_id: string;
    readonly currency?: string;
    readonly installment?: number;
    readonly allow_retry_failed?: boolean;
};

export enum ReversalRequestStatus {
    Pending = 'pending',
    Completed = 'completed',
    Rejected = 'rejected',
    Canceled = 'canceled',
}
export enum TransactionStatus {
    Pending = 'pending',
    Processing = 'processing',
    Refused = 'refused',
    Success = 'success',
    Expired = 'expired',
    Error = 'error',
    Canceled = 'canceled',
    Reverted = 'reverted',
    InformationRequested = 'info_requested',
    InformationSupplied = 'info_supplied',
    ChargedBack = 'chargeback',
    InTransit = 'in_transit',
    Scheduled = 'scheduled',
}

export enum TransactionOrigin {
    Subscription = 'subscription',
    ContractUpdate = 'contract_update',
    ContractCancellation = 'contract_cancellation',
    Dunning = 'dunning',
    RetryTransaction = 'retry',
    Payout = 'payout',
    DebtCharge = 'debt_charge',
    DebtCollection = 'debt_collection',
    ManualRefund = 'payout_refund',
    DisputeCharge = 'dispute_charge',
    Reinstate = 'reinstate',
    PaymentMethodUpdate = 'payment_method_update',
    Overcharge = 'overcharge_settlement',
    OverchargeRefund = 'overcharge_refund',
}

export enum TransactionType {
    Payout = 'payout',
    Charge = 'charge',
    Refund = 'refund',
}

export enum PaymentProviderType {
    Stripe = 'stripe',
    Hyperwallet = 'hyperwallet',
    Manual = 'manual',
    Lob = 'lob',
    EscrowPaperCheck = 'escrow_paper_check',
}

export interface DisabledReasonData {
    readonly title: string;
    readonly todo?: string[];
}

export enum ItemNotPayableReasonCode {
    BelowDeductible = 'below_deductible',
    AbovePerPersonLimit = 'above_per_person_limit',
    AboveFeatureLimit = 'above_feature_limit',
    AutomaticScheduledPayment = 'automatic_scheduled_payment',
    FeatureNotCovered = 'feature_not_covered',
    IntegrityTasksNotCompleted = 'integrity_tasks_not_completed',
    IncludesRejectedItems = 'includes_rejected_items',
}

interface ItemNotPayableBelowDeductible {
    readonly reason: ItemNotPayableReasonCode.BelowDeductible;
}

interface ItemNotPayableFeatureNotCovered {
    readonly reason: ItemNotPayableReasonCode.FeatureNotCovered;
}

interface ItemNotPayableAbovePerPersonLimit {
    readonly reason: ItemNotPayableReasonCode.AbovePerPersonLimit;
    readonly limit: number;
}

interface ItemNotPayableAboveFeatureLimit {
    readonly reason: ItemNotPayableReasonCode.AboveFeatureLimit;
    readonly limit: number;
}

interface ItemNotPayableAutomaticScheduledPayment {
    readonly reason: ItemNotPayableReasonCode.AutomaticScheduledPayment;
    readonly nonPayableItemIds: string[];
}

interface ItemNotPayableIntegrityTasksNotCompleted {
    readonly reason: ItemNotPayableReasonCode.IntegrityTasksNotCompleted;
    readonly disableReasonData: DisabledReasonData;
}

interface ItemNotPayableIncludesRejectedItems {
    readonly reason: ItemNotPayableReasonCode.IncludesRejectedItems;
}

export enum ItemPayableReasonCode {
    AboveAcvUnderFeatureLimit = 'above_acv_under_feature_limit',
}

interface ItemPayableAboveAcvUnderFeatureLimit {
    readonly reason: ItemPayableReasonCode.AboveAcvUnderFeatureLimit;
}

export type ItemNotPayable =
    | ItemNotPayableAboveFeatureLimit
    | ItemNotPayableAbovePerPersonLimit
    | ItemNotPayableAutomaticScheduledPayment
    | ItemNotPayableBelowDeductible
    | ItemNotPayableFeatureNotCovered
    | ItemNotPayableIncludesRejectedItems
    | ItemNotPayableIntegrityTasksNotCompleted;

export type ItemPayable = ItemPayableAboveAcvUnderFeatureLimit;

export enum PaymentPlan {
    Annual = 'annual',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    SemiAnnual = 'semiannual',
}

export enum TransactionKey {
    Processed = 'processed',
    Scheduled = 'scheduled',
}

export interface NewTransactionsData {
    readonly payment_plan: PaymentPlan;
    readonly update_payment_method_url: string;
    readonly actions: string[];
    readonly uses_billing_service: boolean;
    readonly transactions: TransactionsByType;
}

export type TransactionsByType = {
    readonly [key in keyof typeof TransactionKey]?: HomePolicyTransaction[] | PolicyScheduledTransactionBase[];
};

type TransactionOmitted = Omit<Transaction, 'payment_method' | 'payment_user'>;

export interface HomePolicyTransaction extends TransactionOmitted {
    readonly installment: number;
    readonly actions: string[];
    readonly allow_retry_failed?: boolean;
    readonly invoice_id: string;
    readonly payment_user: {
        readonly provider_customer_url: string;
        readonly provider_id: string;
        readonly provider_type: string;
        readonly public_id: string;
        readonly user_external_id: string;
    };
    readonly premiums_breakdown: PremiumBreakdown[];
    readonly fees?: FeesBreakdown[];
    readonly taxes?: TaxesBreakdown[];
    readonly payment_method?: Transaction['payment_method'];
}

export enum PaymentAction {
    UpdatePaymentMethod = 'update_payment_method',
    SimulateDispute = 'simulate_dispute',
    KeepPolicyActive = 'keep_policy_active',
    generateTransactionReceipt = 'generate_transaction_receipt',
}

export enum PaymentRequestStatus {
    Pending = 'pending',
    Rejected = 'rejected',
    Approved = 'approved',
}

export interface RiskIndicator {
    readonly type: string;
    readonly entityType: string;
    readonly entityPublicId: string;
    readonly data: Record<string, unknown> | null;
}

export interface ClaimRiskIndicators {
    readonly claimPublicId: string;
    readonly riskIndicators: RiskIndicator[];
}

export interface PaymentRequest {
    readonly payeeFullName: string;
    readonly amountInCents: number;
    readonly status: PaymentRequestStatus;
    readonly completedAt: Date | null;
    readonly submittedAt: Date;
    readonly requestedBy: string;
    readonly reviewedBy: string;
    readonly requesterNotes: string;
    readonly reviewerNotes: string;
    readonly publicId: string;
    readonly address?: PaperCheckAddress;
    readonly statementDescriptor?: string;
    readonly reason?: string;
    readonly paymentMethod: PaymentMethodType;
    readonly riskIndicators: ClaimRiskIndicators[];
}

export interface PaymentControlPermissions {
    readonly authorizedToReject: boolean;
    readonly authorizedToApprove: boolean;
    readonly autorisedToPayAfterApproved: boolean;
}

export interface PetPaymentControlPermissions extends Omit<PaymentControlPermissions, 'autorisedToPayAfterApproved'> {
    readonly authorizedToPayAfterApproved: boolean;
}
export interface PaymentRequestDetails {
    readonly featureType: FeatureType;
    readonly items: PaymentContentItem[];
    readonly deductibleInCents: number;
    readonly totalPaidInCents: number;
    readonly totalToPayInCents: number;
}

export type PetPaymentRequestDetails = Omit<PaymentRequestDetails, 'deductibleInCents' | 'totalPaidInCents'>;

export interface PaymentRequestData {
    readonly paymentRequest: PaymentRequest;
}

export interface PaymentBreakdown {
    readonly amountRequested: number;
    readonly deductible: number;
    readonly priorExpensePaid: number;
    readonly priorLossPaid: number;
    readonly totalClaimAuthority: number;
    readonly overFeatureLimit?: number;
}

export interface PaymentContentItem {
    readonly id: string;
    readonly publicId?: string;
    readonly title: string;
    readonly amountInCents: number;
}
export interface PaymentApprovalState {
    readonly requiresApproval: boolean;
    readonly paymentBreakdown: PaymentBreakdown;
    readonly decisionReason: string | null;
    readonly confirmationTexts?: string[];
}

export interface PaymentBreakdownResponse {
    readonly isLastPayment: boolean;
    readonly items: PaymentContentItem[];
    readonly paymentBreakdown: PaymentBreakdown;
}
export interface PaymentRequestResponse {
    readonly paymentRequest: PaymentRequest;
}

export interface PaymentRequestDetailsResponse {
    readonly payment_request_details: PaymentRequestDetails;
    readonly payment_control_permissions: PaymentControlPermissions;
    readonly payment_breakdown: PaymentBreakdownResponse;
    readonly payment_request: PaymentRequestResponse;
}

export interface PetPaymentRequestDetailsResponse
    extends Omit<PaymentRequestDetailsResponse, 'payment_control_permissions' | 'payment_request_details'> {
    readonly payment_control_permissions: PetPaymentControlPermissions;
    readonly payment_request_details: PetPaymentRequestDetails;
}
