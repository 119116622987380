import { LinkComp } from '@lemonade-hq/bluiza';
import { getEditionUrl } from 'components/LoCo/common/urlBuilders';
import type { Edition, EditionWithType } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { ProductEditions } from 'models/LoCo/Insurance/Product';

export const EditionLink: React.FC<{
    readonly type: EditionType;
    readonly edition: Edition;
    readonly productCode: string;
}> = ({ type, edition, productCode }) => {
    return <LinkComp url={getEditionUrl(productCode, edition.code, type)}>{edition.friendlyName}</LinkComp>;
};

export function getFlatEditions(editionsSummary: ProductEditions): EditionWithType[] {
    return [
        ...editionsSummary.coveragesEditions.map(edition => ({ ...edition, type: EditionType.Coverages })),
        ...editionsSummary.digitalAgentEditions.map(edition => ({ ...edition, type: EditionType.DigitalAgent })),
        ...editionsSummary.ratingEditions.map(edition => ({ ...edition, type: EditionType.Rating })),
        ...editionsSummary.underwritingFiltersEditions.map(edition => ({
            ...edition,
            type: EditionType.UnderwritingFilters,
        })),
    ].sort((a, b) => a.type.localeCompare(b.type));
}
