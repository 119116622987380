import type { RowItem, RowItemValue, TableHeaderCellProps } from '@lemonade-hq/bluis';
import { OrderByDirection } from '../EntitiesTable/types';
import type { ListFilters } from '../List/types';
import type { AttachmentSource } from 'models/Attachment';
import type { CarAttachmentType } from 'models/CarShared';

enum ColumnKeyOptions {
    Attachment = 'attachment',
    Type = 'type',
    Description = 'description',
    InvolvedParty = 'involvedParty',
    Source = 'source',
    CreatedAt = 'createdAt',
    TakenAt = 'takenAt',
    Location = 'location',
    Assign = 'assign',
}

export type AttachmentTableColumnKeys =
    | 'attachment'
    | 'createdAt'
    | 'description'
    | 'involvedParty'
    | 'location'
    | 'source'
    | 'takenAt'
    | 'type';

export type AttachmentTableValues = [
    { readonly key: 'attachment'; readonly value: RowItemValue; readonly sortable?: boolean },
    { readonly key: 'type'; readonly value: RowItemValue; readonly sortable?: boolean },
    { readonly key: 'description'; readonly value: RowItemValue; readonly sortable?: boolean },
    { readonly key: 'involvedParty'; readonly value: RowItemValue; readonly sortable?: boolean },
    { readonly key: 'source'; readonly value: RowItemValue; readonly sortable?: boolean },
    { readonly key: 'createdAt'; readonly value: RowItemValue; readonly sortable?: boolean },
    { readonly key: 'takenAt'; readonly value: RowItemValue; readonly sortable?: boolean },
    { readonly key: 'location'; readonly value: RowItemValue; readonly sortable?: boolean },
];

export type AttachmentColumn = RowItem;

export const headers: TableHeaderCellProps[] = [
    { key: 'attachment', value: '' },
    { key: 'type', value: 'Type', sortable: true },
    { key: 'description', value: 'Description', sortable: true },
    { key: 'involvedParty', value: 'Involved Party' },
    { key: 'source', value: 'Source', sortable: true },
    { key: 'createdAt', value: 'Uploaded at', sortable: true },
    { key: 'takenAt', value: 'Taken at' },
    { key: 'location', value: 'Location' },
    { key: 'assign', value: 'Assign' },
];

interface FilterOption {
    readonly title: string;
    readonly value: string;
}

export interface CarClaimsAttachmentsFiltersOptions {
    readonly type: FilterOption[];
    readonly involvedParty: FilterOption[];
    readonly source: FilterOption[];
}

export const INIT_SEARCH_PROPS: CarClaimsAttachmentsFilterFields = {
    description: '',
    offset: 0,
    orderBy: 'createdAt',
    orderByDir: OrderByDirection.Desc,
    pageSize: 10,
};

export type CarClaimsAttachmentsFilterFields = ListFilters & {
    readonly types?: CarAttachmentType[];
    readonly description?: string;
    readonly involvedPartyIds?: string[];
    readonly sources?: AttachmentSource[];
    readonly orderBy: string;
    readonly orderByDir: OrderByDirection;
    readonly pageSize: number;
};

export const defaultHeaders = headers.filter(
    header =>
        header.key !== ColumnKeyOptions.Source &&
        header.key !== ColumnKeyOptions.InvolvedParty &&
        header.key !== ColumnKeyOptions.Assign
);
export const defaultHeaderColumns = defaultHeaders.map(({ key }) => key);

export const headersWithInvolvedParty = headers.filter(
    header => header.key !== ColumnKeyOptions.Source && header.key !== ColumnKeyOptions.Assign
);
