export enum EditionType {
    Rating = 'rating',
    Coverages = 'coverages',
    DigitalAgent = 'digital_agent',
    UnderwritingFilters = 'underwriting_filters',
}

export enum EditionStatus {
    Draft = 'draft',
    Archived = 'archived',
    Approved = 'approved',
    Published = 'published',
}

export interface Edition {
    readonly code: string;
    readonly status: EditionStatus;
    readonly productCode: string;
    readonly description: string;
    readonly friendlyName: string;
    readonly baseEditionCode?: string;

    readonly addedAt: string;
    readonly addedBy: string;
    readonly addedByName: string;

    readonly approvedAt?: string;
    readonly approvedBy?: string;
    readonly approvedByName?: string;

    readonly archivedAt?: string;
    readonly archivedBy?: string;
    readonly archivedByName?: string;

    readonly publishedAt?: string;
    readonly publishedBy?: string;
    readonly publishedByName?: string;

    readonly earliestRenewalEffectiveAt?: string;
    readonly earliestNewBusinessEffectiveAt?: string;
    readonly trackerPublicId: string;
    readonly referenceEditionContentCode?: string;
}

export type LeanEdition = Pick<
    Edition,
    'code' | 'description' | 'friendlyName' | 'referenceEditionContentCode' | 'trackerPublicId'
> & {
    readonly type: EditionType;
    readonly eligibleForMajorUpdate?: boolean;
};

export type EditionWithType = Edition & { readonly type: EditionType };

export interface ApprovedEdition extends Edition {
    readonly status: EditionStatus.Approved;
    readonly approvedBy: string;
    readonly approvedAt: string;
    readonly approvedByName: string;
}

export interface PublishedEdition extends ApprovedEdition {
    readonly publishedAt: string;
    readonly publishedBy: string;
    readonly publishedByName: string;

    readonly earliestRenewalEffectiveAt: string;
    readonly earliestNewBusinessEffectiveAt: string;
}

export interface BaseEditionFilter {
    readonly maxDaysSinceArchived?: number;
    readonly status?: EditionStatus | EditionStatus[];
    readonly editionContentType?: EditionType;
}

export interface CreateEditionParams {
    readonly productCode: string;
    readonly description: string;
    readonly baseEditionCode?: string;
}

export interface ArchiveEditionParams {
    readonly editionCode: string;
}

export enum ReleaseType {
    ProductChange = 'productChange',
    BugFix = 'bugFix',
}

export enum VersionType {
    Major = 'major',
    Minor = 'minor',
    BugFix = 'bugfix',
}

export enum QuoteLifecycleContext {
    NewBusiness = 'new_business',
    Renewal = 'renewal',
    PolicyEdit = 'policy_edit',
    Moving = 'moving',
}

export interface EditionViolations {
    readonly hasViolation: boolean;
    readonly messages: string[];
}
