import type { ComboBoxItem } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import type { QueryKey } from '@tanstack/react-query';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { AttachmentDetailsData } from '../AttachmentsQueries';
import { useGetAttachmentTypes, useSubmitAttachmentDetails } from '../AttachmentsQueries';
import type { AttachmentDTO, AttachmentType } from '../types';

// A hook to manage the update attachment details - in dialog, preview or gallery

export interface UseUpdateDetailsReturnType {
    readonly types: ComboBoxItem[];
    readonly subtypes: ComboBoxItem[] | null;
    readonly type: ComboBoxItem | null;
    readonly subtype: ComboBoxItem | null;
    readonly description: string;
    readonly isTypesError: boolean;
    readonly isTypesLoading: boolean;
    readonly isSubmitPending: boolean;
    readonly isSubmitError: boolean;
    readonly handleSelectionChange: (item: ComboBoxItem | null) => void;
    readonly handleSubtypeSelectionChange: (item: ComboBoxItem | null) => void;
    readonly setDescription: Dispatch<SetStateAction<string>>;
    readonly handleSubmit: ({
        attachmentsData,
    }: {
        readonly attachmentsData: AttachmentDetailsData[];
    }) => Promise<void>;
    readonly isInTypeList?: boolean;
}

export const useUpdateDetails = ({
    entityPublicId,
    entityType,
    attachments,
    onClose,
    savedType,
    savedSubtype,
    savedDescription,
    invalidateKeys,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly attachments: AttachmentDTO[];
    readonly onClose?: () => void;
    readonly savedType?: AttachmentType | null;
    readonly savedSubtype?: AttachmentType | null;
    readonly savedDescription?: string | null;
    readonly invalidateKeys?: QueryKey[];
}): UseUpdateDetailsReturnType => {
    const [type, setType] = useState(savedType ?? null);
    const [subtype, setSubtype] = useState(savedSubtype ?? null);
    const [description, setDescription] = useState(savedDescription ?? '');
    const {
        data: attachmentTypes,
        isError: isTypesError,
        isLoading: isTypesLoading,
    } = useGetAttachmentTypes({ entityType });
    const {
        mutateAsync: updateAttachmentDetails,
        isPending: isSubmitPending,
        isError: isSubmitError,
    } = useSubmitAttachmentDetails({
        entityPublicId,
        entityType,
        invalidateKeys,
    });

    const handleSelectionChange = useCallback((item: ComboBoxItem | null) => {
        setType(item ?? null);
        // reset subtype if type is changed
        setSubtype(null);
    }, []);

    const handleSubtypeSelectionChange = useCallback((item: ComboBoxItem | null) => {
        setSubtype(item ?? null);
    }, []);

    // check if type is in the list of types - or is it a legacy type
    const isInTypeList = useMemo(() => attachmentTypes?.some(it => it.value === type?.value), [attachmentTypes, type]);

    const subtypes = useMemo(
        () => attachmentTypes?.find(it => it.value === type?.value)?.subtypes as ComboBoxItem[],
        [attachmentTypes, type]
    );

    const handleSubmit = useCallback(async () => {
        const attachmentsData = attachments.map(it => ({
            attachmentPublicId: it.publicId,
            ...(type != null && isInTypeList && { type: type.value }),
            ...(subtype != null && { subtype: subtype.value }),
            ...(description !== '' && { description }),
        }));

        await updateAttachmentDetails({ attachmentsData });

        onClose?.();
    }, [attachments, description, isInTypeList, onClose, subtype, type, updateAttachmentDetails]);

    return {
        types: (attachmentTypes ?? []) as ComboBoxItem[],
        subtypes: subtypes ?? null,
        type,
        subtype,
        description,
        isTypesError,
        isTypesLoading,
        isSubmitPending,
        isSubmitError,
        handleSelectionChange,
        handleSubtypeSelectionChange,
        setDescription,
        handleSubmit,
        isInTypeList,
    };
};
