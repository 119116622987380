/* eslint-disable jsx-a11y/media-has-caption, @typescript-eslint/no-non-null-assertion, react/jsx-no-constructed-context-values */
import type { Milliseconds } from '@lemonade-hq/ts-helpers';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

export interface AudioPlayerProviderProps {
  readonly src?: string;
  readonly mimeType: 'audio/mpeg' | 'audio/ogg' | 'audio/wav';
  readonly knownTotalTime?: Milliseconds;
}

export interface AudioPlayerContextProps {
  readonly isLoading: boolean;
  readonly isPlaying: boolean;
  readonly isMuted: boolean;
  readonly currentTime: Milliseconds;
  readonly totalTime: Milliseconds;
  readonly playbackRate: number;
  readonly audioBuffer: AudioBuffer | null;
  readonly play: () => void;
  readonly pause: () => void;
  readonly togglePlayState: () => void;
  readonly mute: () => void;
  readonly unmute: () => void;
  readonly toggleMuted: () => void;
  readonly seek: (time: number) => void;
  readonly setPlaybackRate: (rate: number) => void;
}

export const DEFAULT_AUDIO_PLAYER_CONTEXT = {
  currentTime: 0,
  totalTime: 0,
  isLoading: true,
  isPlaying: false,
  isMuted: false,
  playbackRate: 1,
  audioBuffer: null,
  play: () => {},
  pause: () => {},
  togglePlayState: () => {},
  mute: () => {},
  unmute: () => {},
  toggleMuted: () => {},
  seek: () => {},
  setPlaybackRate: () => {},
};

export const AudioPlayerContext = createContext<AudioPlayerContextProps | null>(DEFAULT_AUDIO_PLAYER_CONTEXT);

export const AudioPlayerProvider: FC<PropsWithChildren<AudioPlayerProviderProps>> = ({
  src,
  mimeType,
  knownTotalTime = NaN,
  children,
}) => {
  const raf = useRef<number | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null);
  const [isBufferLoading, setIsBufferLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(knownTotalTime);
  const [playbackRate, setPlaybackRate] = useState(1);

  const isLoading = isBufferLoading || isNaN(totalTime) || totalTime === 0;

  const loadAudioBuffer = useCallback(async () => {
    if (src == null) return;

    setIsBufferLoading(true);

    const response = await fetch(src);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await new AudioContext().decodeAudioData(arrayBuffer);
    setAudioBuffer(buffer);
    setIsBufferLoading(false);
  }, [src]);

  useEffect(() => {
    void loadAudioBuffer();
  }, [loadAudioBuffer, src]);

  const scheduleUpdateCurrentTime = useCallback(() => {
    setCurrentTime(audioRef.current!.currentTime * 1000);
    raf.current = window.requestAnimationFrame(scheduleUpdateCurrentTime);
  }, []);

  const handledAudioLoading = useCallback(() => {
    setIsPlaying(false);
    setCurrentTime(0);
    setTotalTime(knownTotalTime);
  }, [knownTotalTime]);

  const handleAudioLoaded = useCallback(() => {
    setTotalTime(Math.floor(audioRef.current!.duration * 1000));
    audioRef.current!.playbackRate = playbackRate;
  }, [playbackRate]);

  const handleAudioStartedPlaying = useCallback(() => {
    setIsPlaying(true);
    scheduleUpdateCurrentTime();
  }, [scheduleUpdateCurrentTime]);

  const handleAudioStoppedPlaying = useCallback(() => {
    setIsPlaying(false);
    if (raf.current != null) {
      window.cancelAnimationFrame(raf.current);
      raf.current = null;
    }
  }, []);

  const handleAudioTimeUpdate = useCallback(() => {
    setCurrentTime(audioRef.current!.currentTime * 1000);
  }, []);

  const handlePlay = useCallback(() => {
    void audioRef.current!.play();
  }, []);

  const handlePause = useCallback(() => {
    audioRef.current!.pause();
  }, []);

  const handleTogglePlayState = useCallback(() => {
    if (isPlaying) {
      audioRef.current!.pause();
    } else {
      void audioRef.current!.play();
    }
  }, [isPlaying]);

  const handleSeek = useCallback((time: number) => {
    audioRef.current!.currentTime = time / 1000;
  }, []);

  const handleMute = useCallback(() => {
    setIsMuted(true);
  }, []);

  const handleUnmute = useCallback(() => {
    setIsMuted(false);
  }, []);

  const handleToggleMuted = useCallback(() => {
    setIsMuted(val => !val);
  }, []);

  const handleSetPlaybackRate = useCallback((rate: number) => {
    audioRef.current!.playbackRate = rate;
    setPlaybackRate(rate);
  }, []);

  return (
    <>
      <audio
        key={src}
        muted={isMuted}
        onCanPlayThrough={handleAudioLoaded}
        onEnded={handleAudioStoppedPlaying}
        onLoadStart={handledAudioLoading}
        onPause={handleAudioStoppedPlaying}
        onPlay={handleAudioStartedPlaying}
        onTimeUpdate={handleAudioTimeUpdate}
        preload="auto"
        ref={audioRef}
      >
        <source src={src} type={mimeType} />
      </audio>
      <AudioPlayerContext.Provider
        value={{
          currentTime,
          totalTime,
          playbackRate,
          isLoading,
          isPlaying,
          isMuted,
          audioBuffer,
          play: handlePlay,
          pause: handlePause,
          togglePlayState: handleTogglePlayState,
          mute: handleMute,
          unmute: handleUnmute,
          toggleMuted: handleToggleMuted,
          seek: handleSeek,
          setPlaybackRate: handleSetPlaybackRate,
        }}
      >
        {children}
      </AudioPlayerContext.Provider>
    </>
  );
};

export function useAudioPlayer(): AudioPlayerContextProps {
  const context = useContext(AudioPlayerContext);

  if (context == null) {
    throw new Error('useAudioPlayer must be used within a AudioPlayerProvider');
  }

  return context;
}
