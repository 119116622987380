import type { Dispatch } from 'react';
import { createContext, useContext } from 'react';
import type { Operators } from './operators.types';
import type { Query } from './query.types';
import type { QueryBuilderReducerAction } from './queryBuilderReducer';
import type { Schema } from './schema.types';

interface QueryBuilderContextProps {
  readonly query: Query;
  readonly dispatch: Dispatch<QueryBuilderReducerAction>;
  readonly schema: Schema;
  readonly operators: Operators;
}

export const QueryBuilderContext = createContext<QueryBuilderContextProps | null>(null);

export function useQueryBuilder(): QueryBuilderContextProps {
  const context = useContext(QueryBuilderContext);

  if (context == null) {
    throw new Error('useQueryBuilder must be used within a QueryBuilderProvider');
  }

  return context;
}
