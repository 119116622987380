import type { Maybe } from '@lemonade-hq/ts-helpers';
import type { UseMutationResult, UseSuspenseQueryResult } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import qs from 'qs';
import { insuranceBlender } from '../../../apiClients';
import type { Pagination } from './QueriesShared';
import { useSuspenseQueryWithErrorHandling } from 'components/LoCo/common/hooks/useSuspenseQueryWithErrorHandling';
import type { PaginationResult } from 'models/CarShared';
import type { VersionType } from 'models/LoCo/Insurance/BaseEdition';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';
import type { EditionTrackerReference } from 'models/LoCo/Insurance/EditionTracker';
import type { Release } from 'models/LoCo/Insurance/Release';
import { ReleaseStatus } from 'models/LoCo/Insurance/Release';
import type { ReleaseRolloutInsightsReport } from 'models/LoCo/Insurance/ReleaseRolloutInsightsReport';
import { usePessimisticMutation } from 'queries/MutationHooks';

const RELEASES_BASE_PATH = '/api/v1/releases';

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE = 1;
const DEFAULT_MAX_DAYS_SINCE_CANCELLED = 7;

const DEFAULT_PAGINATION: Pagination = {
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
};

enum ReleaseQueryKey {
    GetReleases = 'GET_RELEASES',
    GetRelease = 'GET_RELEASE',
    CreateRelease = 'CREATE_RELEASE',
    UpdateRelease = 'UPDATE_RELEASE',
    GetReleaseEditionSets = 'GET_RELEASE_EDITION_SETS',
    PublishRelease = 'PUBLISH_RELEASE',
    UpdateRatingReferenceEdition = 'UPDATE_RATING_REFERENCE_EDITION',
    TestInStaging = 'TEST_RELEASE_IN_STAGING',
    UpdateEffectiveDates = 'UPDATE_EFFECTIVE_DATES',
    UpdateEditions = 'UPDATE_EDITIONS',
    Cancel = 'CANCEL_RELEASE',
    GetReleaseRolloutInsightsReport = 'GET_RELEASE_ROLLOUT_INSIGHTS_REPORT',
    GetReleasePotentiallyArchivedEditions = 'GET_RELEASE_POTENTIALLY_ARCHIVED_EDITIONS',
    RefreshRolloutInsights = 'REFRESH_ROLLOUT_INSIGHTS',
}

export interface ReleaseCreateRequest {
    readonly productCode: string;
    readonly rolloutStrategy: Maybe<VersionType>;
    readonly coveragesEditionCode: Maybe<string>;
    readonly digitalAgentEditionCode: Maybe<string>;
    readonly ratingEditionCode: Maybe<string>;
    readonly underwritingFiltersEditionCode: Maybe<string>;
    readonly newBusinessEffectiveAt: Maybe<Date>;
    readonly renewalEffectiveAt: Maybe<Date>;
    readonly productSchemaRevision?: number;
    readonly platformSchemaRevision?: number;
    readonly ratingReferenceEditionCode: Maybe<string>;
}

async function getReleases(
    productCode: string,
    releaseStatus: ReleaseStatus,
    page: number,
    size: number
): Promise<PaginationResult<Release>> {
    const query = qs.stringify({
        productCode,
        page,
        size,
        status: releaseStatus,
        maxDaysSinceCancelled: releaseStatus === ReleaseStatus.Cancelled ? DEFAULT_MAX_DAYS_SINCE_CANCELLED : undefined,
    });
    return await insuranceBlender
        .get<PaginationResult<Release>>(`${RELEASES_BASE_PATH}?${query}`)
        .then(response => response.data);
}

export function useSuspenseGetReleases(
    productCode: string,
    releaseStatus: ReleaseStatus,
    { page = DEFAULT_PAGE, size = DEFAULT_PAGE_SIZE }: Pagination = DEFAULT_PAGINATION
): UseSuspenseQueryResult<PaginationResult<Release>> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [ReleaseQueryKey.GetReleases, productCode, releaseStatus, page, size],
        queryFn: async () => await getReleases(productCode, releaseStatus, page, size),
    });
}

async function getRelease(releasePublicId: string): Promise<Release> {
    return await insuranceBlender
        .get<{ data: Release }>(`${RELEASES_BASE_PATH}/${releasePublicId}`)
        .then(response => response.data.data);
}

export function useSuspenseGetRelease(releasePublicId: string): UseSuspenseQueryResult<Release> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [ReleaseQueryKey.GetRelease, releasePublicId],
        queryFn: async () => await getRelease(releasePublicId),
    });
}

async function createRelease(body: ReleaseCreateRequest): Promise<Release> {
    const response = await insuranceBlender.post<{ data: Release }>(RELEASES_BASE_PATH, body);

    return response.data.data;
}

export function useCreateRelease(): UseMutationResult<Release, unknown, ReleaseCreateRequest, null> {
    return usePessimisticMutation({
        mutationFn: createRelease,
        invalidateKeys: [[ReleaseQueryKey.GetReleases]],
        mutationKey: [ReleaseQueryKey.CreateRelease],
    });
}

export interface UpdateReleaseRequest {
    readonly releasePublicId: string;
    readonly note: string;
}

async function updateRelease(request: UpdateReleaseRequest): Promise<void> {
    await insuranceBlender.patch(`${RELEASES_BASE_PATH}/${request.releasePublicId}`, { note: request.note });
}

export function useUpdateRelease(releasePublicId: string): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: async (note: string) => await updateRelease({ releasePublicId, note }),
        invalidateKeys: [[ReleaseQueryKey.GetReleases], [ReleaseQueryKey.GetRelease, releasePublicId]],
        mutationKey: [ReleaseQueryKey.UpdateRelease],
    });
}

async function getReleaseEditionSets(releasePublicId: string): Promise<EditionSet[]> {
    return await insuranceBlender
        .get<{ data: EditionSet[] }>(`${RELEASES_BASE_PATH}/${releasePublicId}/edition-sets`)
        .then(response => response.data.data);
}

export function useSuspenseGetReleaseEditionSets(
    releasePublicId: string,
    shouldRefetch: boolean = true
): UseSuspenseQueryResult<EditionSet[]> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [ReleaseQueryKey.GetReleaseEditionSets, releasePublicId],
        queryFn: async () => await getReleaseEditionSets(releasePublicId),
        refetchInterval: shouldRefetch ? 1000 : undefined,
    });
}

export function useGetReleaseEditionSetsSuspense(releasePublicId: string): UseSuspenseQueryResult<EditionSet[]> {
    return useSuspenseQuery({
        queryKey: [ReleaseQueryKey.GetReleaseEditionSets, releasePublicId],
        queryFn: async () => await getReleaseEditionSets(releasePublicId),
    });
}

async function getReleaseRolloutInsightsReport(releasePublicId: string): Promise<ReleaseRolloutInsightsReport> {
    return await insuranceBlender
        .get<{ data: ReleaseRolloutInsightsReport }>(`${RELEASES_BASE_PATH}/${releasePublicId}/report`)
        .then(response => response.data.data);
}

export function useSuspenseGetReleaseRolloutInsightsReport(
    releasePublicId: string
): UseSuspenseQueryResult<ReleaseRolloutInsightsReport> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [ReleaseQueryKey.GetReleaseRolloutInsightsReport, releasePublicId],
        queryFn: async () => await getReleaseRolloutInsightsReport(releasePublicId),
    });
}

export function useRefreshRolloutInsights(releasePublicId: string): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: async () =>
            await insuranceBlender.post(`${RELEASES_BASE_PATH}/${releasePublicId}/refresh-rollout-insights`),
        invalidateKeys: [[ReleaseQueryKey.GetReleaseRolloutInsightsReport, releasePublicId]],
        mutationKey: [ReleaseQueryKey.RefreshRolloutInsights],
    });
}

export function usePublishRelease(releasePublicId: string): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: async () => await insuranceBlender.post(`${RELEASES_BASE_PATH}/${releasePublicId}/publish`),
        invalidateKeys: [[ReleaseQueryKey.GetRelease, releasePublicId], [ReleaseQueryKey.GetReleases]],
        mutationKey: [ReleaseQueryKey.PublishRelease],
    });
}

export function useUpdateRatingReferenceEdition(
    releasePublicId: string
): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: async (ratingReferenceEditionCode: string) =>
            await insuranceBlender.post(`${RELEASES_BASE_PATH}/${releasePublicId}/update-rating-reference-edition`, {
                ratingReferenceEditionCode,
            }),
        invalidateKeys: [[ReleaseQueryKey.GetRelease, releasePublicId], [ReleaseQueryKey.GetReleases]],
        mutationKey: [ReleaseQueryKey.UpdateRatingReferenceEdition],
    });
}

// TODO: delete once replaced with new env override
export function useTestInStagingEnv(
    releasePublicId: string,
    _envId: string
): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: async () => {},
        invalidateKeys: [
            [ReleaseQueryKey.GetRelease, releasePublicId],
            [ReleaseQueryKey.GetReleaseEditionSets, releasePublicId],
        ],
        mutationKey: [ReleaseQueryKey.TestInStaging],
    });
}

export function useUpdateEffectiveDates(
    releasePublicId: string,
    newBusinessEffectiveAt: Date,
    renewalEffectiveAt: Date
): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: async () =>
            await insuranceBlender.post(`${RELEASES_BASE_PATH}/${releasePublicId}/update-effective-dates`, {
                newBusinessEffectiveAt,
                renewalEffectiveAt,
            }),
        invalidateKeys: [
            [ReleaseQueryKey.GetRelease, releasePublicId],
            [ReleaseQueryKey.GetReleases],
            [ReleaseQueryKey.GetReleaseEditionSets, releasePublicId],
        ],
        mutationKey: [ReleaseQueryKey.UpdateEffectiveDates],
    });
}

export function useUpdateEditions({
    codes,
    releasePublicId,
    schema,
}: {
    readonly releasePublicId: string;
    readonly codes: {
        readonly coveragesEditionCode?: string;
        readonly digitalAgentEditionCode?: string;
        readonly ratingEditionCode?: string;
        readonly underwritingFiltersEditionCode?: string;
    };
    readonly schema: {
        readonly platformSchemaRevision?: number;
        readonly productSchemaRevision?: number;
    };
}): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: async () =>
            await insuranceBlender.post(`${RELEASES_BASE_PATH}/${releasePublicId}/update-editions`, {
                ...codes,
                ...schema,
            }),
        invalidateKeys: [
            [ReleaseQueryKey.GetRelease, releasePublicId],
            [ReleaseQueryKey.GetReleaseEditionSets, releasePublicId],
            [ReleaseQueryKey.GetReleases],
        ],
        mutationKey: [ReleaseQueryKey.UpdateEditions],
    });
}

export function useCancelRelease(releasePublicId: string): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: async () => await insuranceBlender.post(`${RELEASES_BASE_PATH}/${releasePublicId}/cancel`),
        invalidateKeys: [[ReleaseQueryKey.GetRelease, releasePublicId], [ReleaseQueryKey.GetReleases]],
        mutationKey: [ReleaseQueryKey.Cancel],
    });
}

async function getReleasePotentiallyArchivedEditions(releasePublicId: string): Promise<EditionTrackerReference[]> {
    return await insuranceBlender
        .get<{
            data: EditionTrackerReference[];
        }>(`${RELEASES_BASE_PATH}/${releasePublicId}/potentially-archived-editions`)
        .then(response => response.data.data);
}

export function useSuspenseGetReleasePotentiallyArchivedEditions(
    releasePublicId: string
): UseSuspenseQueryResult<EditionTrackerReference[]> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [ReleaseQueryKey.GetReleasePotentiallyArchivedEditions, releasePublicId],
        queryFn: async () => await getReleasePotentiallyArchivedEditions(releasePublicId),
    });
}
