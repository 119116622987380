import {
    borderRadius,
    Button,
    darkThemeClass,
    ellipsis,
    Flex,
    IconButton,
    Layout,
    spacing,
    Text,
} from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent } from '@lemonade-hq/boutique';
import * as HoverCard from '@radix-ui/react-hover-card';
import * as Popover from '@radix-ui/react-popover';
import type { QueryKey } from '@tanstack/react-query';
import { clsx } from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';
import { DetectionBanner } from '../AttachmentGallery/Carousel/Detection';
import { imageActionBg } from '../AttachmentGallery/MediaComponents/Media.css';
import {
    attachmentPreviewWrapper,
    popoverActionContent,
    previewBottomButtons,
    previewContent,
    previewDetectionBanner,
    previewMedia,
} from './Preview.css';
import { PreviewActionContent } from './PreviewActionsContent';
import { OpenInNewTab } from 'components/Attachments/AttachmentGallery/Carousel/OpenInNewTab';
import { MediaPreview } from 'components/Attachments/AttachmentGallery/MediaComponents/GalleryMedia';
import type { AttachmentActionType, AttachmentDTO } from 'components/Attachments/types';
import {
    AttachmentFormat,
    attachmentHasDetections,
    getActionLabel,
    getAttachmentAnalyticsParam,
    getAttachmentFormat,
    isInvalidContentType,
} from 'components/Attachments/utils';

interface PreviewProps {
    readonly attachment: AttachmentDTO;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly openGallery?: (attachment: AttachmentDTO) => void;
    readonly invalidateKeys?: QueryKey[];
}

const PreviewActions: FC<PreviewProps> = ({ attachment, entityPublicId, entityType, invalidateKeys }) => {
    const { actions } = attachment;
    const [currentAction, setCurrentAction] = useState<AttachmentActionType | undefined>();

    if (actions == null || actions.length === 0) return null;

    const filteredActions = actions.filter(
        action => action !== 'check_for_modifications' && action !== 'unarchive' && action !== 'additional_details'
    );

    return (
        <Flex gap={spacing.s08} position="relative" zIndex={3}>
            {filteredActions.map(action => (
                <Popover.Root key={action}>
                    <Popover.Trigger asChild>
                        <Button
                            label={getActionLabel(action)}
                            onClick={() => setCurrentAction(action)}
                            variant="secondary"
                        />
                    </Popover.Trigger>
                    <Popover.Content align="end" className={popoverActionContent} sideOffset={8}>
                        <PreviewActionContent
                            action={currentAction}
                            attachment={attachment}
                            entityPublicId={entityPublicId}
                            entityType={entityType}
                            invalidateKeys={invalidateKeys}
                        />
                    </Popover.Content>
                </Popover.Root>
            ))}
        </Flex>
    );
};

const PreviewData: FC<PreviewProps> = ({ attachment, entityPublicId, entityType, invalidateKeys }) => {
    return (
        <Flex gap={spacing.s08} justifyContent="space-between">
            <Flex alignItems="center" gap={spacing.s08}>
                <Text className={ellipsis} maxWidth="200px" pl={spacing.s04} type="text-md">
                    {attachment.fileName}
                </Text>
            </Flex>
            <PreviewActions
                attachment={attachment}
                entityPublicId={entityPublicId}
                entityType={entityType}
                invalidateKeys={invalidateKeys}
            />
        </Flex>
    );
};

const HoverCardPortal: FC<PreviewProps> = ({ attachment, entityPublicId, entityType, openGallery, invalidateKeys }) => {
    const { contentType, fileName } = attachment;
    const type = getAttachmentFormat(!isInvalidContentType(contentType) ? contentType : fileName);
    const showBanner = attachmentHasDetections(attachment);

    const handleOpenGallery = (): void => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment,
                entityType,
                entityId: entityPublicId,
            }),
            action: 'click',
            action_type: 'open_gallery_view',
            source: 'preview_expand',
            platform: 'hub',
        });
        openGallery?.(attachment);
    };

    return (
        <HoverCard.Portal>
            <HoverCard.Content align="start" className={previewContent} side="right" sideOffset={8}>
                <Flex
                    className={clsx(darkThemeClass, attachmentPreviewWrapper, borderRadius({ borderRadius: 'sm' }))}
                    flexDirection="column"
                    gap={spacing.s08}
                >
                    <PreviewData
                        attachment={attachment}
                        entityPublicId={entityPublicId}
                        entityType={entityType}
                        invalidateKeys={invalidateKeys}
                    />
                    <Layout
                        height={type === AttachmentFormat.Video ? 'calc(100% - 40px)' : '100%'}
                        position="relative"
                        width="100%"
                    >
                        <MediaPreview
                            attachment={attachment}
                            className={previewMedia}
                            entityPublicId={entityPublicId}
                            entityType={entityType}
                        />
                        <Flex className={previewBottomButtons} gap={spacing.s08} justifyContent="flex-end">
                            <Layout className={imageActionBg}>
                                <OpenInNewTab url={attachment.url} />
                            </Layout>
                            {openGallery && (
                                <Layout className={imageActionBg}>
                                    <IconButton
                                        color="neutral7"
                                        icon="full-screen"
                                        iconSize="xl"
                                        onClick={handleOpenGallery}
                                        size="lg"
                                        title="Open gallery"
                                        variant="inline"
                                    />
                                </Layout>
                            )}
                        </Flex>
                    </Layout>
                    {showBanner && (
                        <DetectionBanner
                            attachment={attachment}
                            className={previewDetectionBanner}
                            hideContent
                            isScanning={false}
                            withBorder={false}
                        />
                    )}
                </Flex>
            </HoverCard.Content>
        </HoverCard.Portal>
    );
};

export const AttachmentPreview: FC<PropsWithChildren<PreviewProps>> = ({
    children,
    attachment,
    entityPublicId,
    entityType,
    openGallery,
    invalidateKeys,
}) => {
    const onOpen = (open: boolean): void => {
        trackEvent('docs.hover', {
            ...getAttachmentAnalyticsParam({
                attachment,
                entityType,
                entityId: entityPublicId,
            }),
            platform: 'hub',
            action: 'hover',
            action_type: open ? 'open' : 'close',
        });
    };

    return (
        <HoverCard.Root onOpenChange={onOpen} openDelay={500}>
            <HoverCard.Trigger asChild>{children}</HoverCard.Trigger>
            <HoverCardPortal
                attachment={attachment}
                entityPublicId={entityPublicId}
                entityType={entityType}
                invalidateKeys={invalidateKeys}
                openGallery={openGallery}
            />
        </HoverCard.Root>
    );
};
