import axios from 'axios';
import { NegotiateSettlement } from 'models/NegotiateSettlement';
import { getUrlResolver } from 'commons/UrlResolver';

const carBlenderUrlResolver = getUrlResolver('car-blender', '/api/v1/negotiate_settlements');

export async function createNegotiateSettlement({
    settlement,
}: {
    settlement: Omit<NegotiateSettlement, 'publicId'>;
}): Promise<null> {
    const url = carBlenderUrlResolver();

    return axios.post(url, settlement).then(response => response.data.data);
}
