/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useTheme } from '@lemonade-hq/boutique';
import { useClickOutside } from '@lemonade-hq/cdk';
import { NOOP } from '@lemonade-hq/ts-helpers';
import React, { useEffect, useRef, useState } from 'react';
import type { BSelectProps, MultiSelect, SingleSelect } from './helpers';
import {
    getOptionsWithFilter,
    getOptionsWithSelected,
    getSelectTitle,
    getSortedOptions,
    isMultiSelect,
    isSingleSelect,
    splitTextArray,
} from './helpers';
import { Checkbox, Icon, NoResults, Option, OptionsContainer, OptionTitle } from './options-styles';
import {
    ActionButton,
    ActionsContainer,
    BSelectContainer,
    BSelectTitle,
    FilterInput,
    Select,
    SelectContainer,
    SelectTitle,
} from './select-styles';

/**
 * @deprecated
 * use Select from '@lemonade-hq/bluis' instead
 */
const BSelect: React.FC<React.PropsWithChildren<BSelectProps>> = props => {
    const {
        options = [],
        defaultValue = '',
        multiple,
        withFilter,
        withIcons,
        isDisabled,
        title,
        className,
        wrapperClassName,
        selectClassName,
        overrideSelect,
        children,
        onOpen,
        onClose,
        placeholder = '',
        maxOptionsListHeight = 255,
        clippingElement,
        onFilterChange,
        keepFilterValue,
        actions = null,
    } = props;

    const selectedList = isMultiSelect(props) ? props.selectedList : null;
    const selectRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [above, setAbove] = useState(false);
    const selectTitle =
        getSelectTitle(options, isMultiSelect(props) ? props.selectedList : [props.selected]) || defaultValue;
    const [updatedOptions, setUpdatedOptions] = useState([...options]);
    const theme = useTheme();

    function handleClickOutside() {
        setIsOpen(false);
    }

    useClickOutside(selectRef, isOpen, handleClickOutside);

    const handleSelectClick = () => {
        if (!isOpen) {
            const optionsWithSelected = getOptionsWithSelected(props);

            if (multiple) {
                setUpdatedOptions(getSortedOptions({ options: optionsWithSelected }));
            } else {
                setUpdatedOptions(optionsWithSelected);
            }

            if (clippingElement && optionsRef.current) {
                const { bottom: clippingBottom } = clippingElement.getBoundingClientRect();
                const { top: optionsTop } = optionsRef.current.getBoundingClientRect();

                if (clippingBottom < optionsTop + maxOptionsListHeight) {
                    setAbove(true);
                }
            }
        } else {
            setAbove(false);
        }

        if (keepFilterValue === true) {
            setIsOpen(true);
        } else {
            setIsOpen(!isOpen);
        }
    };

    const handleOptionClick = (value: string) => () => {
        if (isMultiSelect(props)) {
            const newList = [...props.selectedList];
            const existingIndex = newList.indexOf(value);

            if (existingIndex > -1) {
                newList.splice(existingIndex, 1);
            } else {
                newList.push(value);
            }

            props.onSelect(newList, props.id);
        } else {
            setIsOpen(false);
            props.onSelect(value, props.id);
        }
    };

    useEffect(() => {
        if (isMultiSelect(props)) {
            setUpdatedOptions(
                getOptionsWithSelected({ options: updatedOptions, selectedList: props.selectedList, multiple })
            );
        }
    }, [selectedList]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen && withFilter) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [isOpen, withFilter]);

    useEffect(() => {
        if (isOpen) {
            if (keepFilterValue !== true) {
                setFilterText('');
            }

            if (onOpen) {
                onOpen();
            }
        } else if (onClose) {
            onClose();
        }
    }, [isOpen, onClose, onOpen, keepFilterValue]);

    const selectedIcon = (isSingleSelect(props) && options.find(option => option.value === props.selected)?.icon) || '';
    const shouldDisplayIcon = Boolean(withIcons) && Boolean(selectedIcon) && !multiple;
    const shouldDisplayFilter = (withFilter && isOpen) || keepFilterValue;
    const filteredOptions = getOptionsWithFilter({ options: updatedOptions, filterText });

    return (
        <BSelectContainer {...{ isOpen }} className={wrapperClassName}>
            {title && <BSelectTitle>{title}</BSelectTitle>}
            <SelectContainer ref={selectRef} {...{ isDisabled, className }}>
                <div onClick={handleSelectClick}>
                    {overrideSelect && children !== undefined ? (
                        children
                    ) : (
                        <Select {...{ isOpen, above, className: selectClassName, theme }}>
                            {shouldDisplayFilter ? (
                                <FilterInput
                                    onChange={e => {
                                        setFilterText(e.target.value);
                                        onFilterChange?.(e.target.value);
                                    }}
                                    onClick={e => keepFilterValue !== true && e.stopPropagation()}
                                    ref={inputRef}
                                    value={keepFilterValue && isSingleSelect(props) ? props.selected : filterText}
                                />
                            ) : (
                                <>
                                    {shouldDisplayIcon && <Icon src={selectedIcon} />}
                                    <SelectTitle
                                        data-testid="bselect-selected-title"
                                        {...{ withIcons, multiple }}
                                        showPlaceholder={!selectTitle}
                                    >
                                        {selectTitle || (isSingleSelect(props) && props.selected) || placeholder}
                                    </SelectTitle>
                                </>
                            )}
                        </Select>
                    )}
                </div>

                <OptionsContainer
                    data-testid="bselect-options"
                    {...{ isOpen, overrideSelect, above }}
                    maxHeight={maxOptionsListHeight}
                    ref={optionsRef}
                >
                    {filteredOptions.map(
                        ({ value, title, isSelected, icon, className = '', component, disabled = false, key }) => (
                            <Option
                                key={key ?? value}
                                {...{ className, isSelected, overrideSelect, disabled }}
                                onClick={!disabled ? handleOptionClick(value) : NOOP}
                            >
                                {multiple && <Checkbox {...{ isSelected }} />}
                                {withIcons && icon && <Icon src={icon} />}
                                {component ?? (
                                    <OptionTitle {...{ multiple, withIcons, disabled }}>
                                        {filterText
                                            ? splitTextArray(title, filterText).map((str, i) => {
                                                  return (
                                                      <span key={i} style={{ fontWeight: i % 2 === 0 ? 400 : 600 }}>
                                                          {str}
                                                      </span>
                                                  );
                                              })
                                            : title}
                                    </OptionTitle>
                                )}
                            </Option>
                        )
                    )}
                    {actions && actions.length > 0 && (
                        <ActionsContainer>
                            {actions.map(action => (
                                <ActionButton
                                    key={action.name}
                                    onClick={() => {
                                        action.onClick();
                                        setIsOpen(false);
                                    }}
                                >
                                    {action.name}
                                </ActionButton>
                            ))}
                        </ActionsContainer>
                    )}
                    {!filteredOptions.length && <NoResults>No results found</NoResults>}
                </OptionsContainer>
            </SelectContainer>
        </BSelectContainer>
    );
};

export default BSelect;

export const BSelectSingle: React.FC<React.PropsWithChildren<SingleSelect>> = props => <BSelect {...props} />;
export const BSelectMulti: React.FC<React.PropsWithChildren<MultiSelect>> = props => <BSelect {...props} multiple />;
