import React, { useState } from 'react';
import classNames from 'classnames';
import { Input } from '@lemonade-hq/bluis';
import { snakeToCamelCaseKeys } from '@lemonade-hq/ts-helpers';
import ClaimItem from './ClaimItem';
import { NoResults } from '../../../../../Bluis/BSelect/options-styles';
import './style.scss';

export default function ClaimItemsTable({
    claimItems = [],
    claimItemIds,
    setClaimItemIds,
    disabled,
    currency,
    withFilter = false,
}) {
    const empty = claimItems.length === 0;
    const claimItemsTableClass = classNames('claim-items-table', {
        disabled,
        empty,
    });

    const [filterText, setFilterText] = useState('');

    const getOptionsWithFilter = ({ options, filterText }) => {
        if (withFilter && filterText) {
            return options.filter(({ title }) => title.toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
        }
        return options;
    };

    const sorter = (firstItem, secondItem) => {
        if (firstItem.title.toUpperCase() > secondItem.title.toUpperCase()) {
            return 1;
        }
        if (secondItem.title.toUpperCase() > firstItem.title.toUpperCase()) {
            return -1;
        }

        return 0;
    };

    const addItem = id => {
        const list = [...claimItemIds];

        list.push(id);
        setClaimItemIds(list);
    };

    const removeItem = id => {
        const list = [...claimItemIds];

        list.splice(list.indexOf(id), 1);
        setClaimItemIds(list);
    };

    const getContent = () => {
        if (empty) {
            return (
                <div>
                    <div className="empty-title">NO ITEMS</div>
                    <div className="empty-subtitle">No items have been added to this claim</div>
                </div>
            );
        }
        const filteredClaimItems = getOptionsWithFilter({ options: claimItems, filterText });

        if (!filteredClaimItems.length) return <NoResults>No results found</NoResults>;

        return filteredClaimItems.sort(sorter).map(item => {
            const { id, publicId } = snakeToCamelCaseKeys(item);

            const checked = claimItemIds.includes(publicId || id);

            return <ClaimItem {...{ item, addItem, removeItem, currency, checked }} key={publicId} />;
        });
    };

    return (
        <div className={claimItemsTableClass}>
            <div className="claim-items-table-title">Which item(s) is this related to?</div>
            <div className="attachment-claim-items">
                {withFilter && (
                    <div className="claim-items-table-header">
                        <div className="search-bar">
                            <button className="search-icon" />
                            <Input
                                placeholder="Search"
                                className="search-input"
                                onChange={({ target }) => setFilterText(target.value)}
                            />
                        </div>
                    </div>
                )}
                <div>{getContent()}</div>
            </div>
        </div>
    );
}
