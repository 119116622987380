import type * as avro from 'avsc';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import keyBy from 'lodash/keyBy';
import type {
    AvroField,
    AvroFieldWithType,
    FieldEntry,
    SchemaResponse,
    UnionField,
} from 'models/LoCo/Insurance/Schema';

export function isPrimitiveType(type: unknown): type is avro.schema.PrimitiveType {
    return isString(type);
}

// example: { name: '...', type: 'string' }
export function isPrimitiveField(field: unknown): field is AvroFieldWithType<avro.schema.PrimitiveType> {
    return isPrimitiveType((field as AvroField).type);
}

// example: { type: 'int', logicalType: 'date' }
export function isDateType(type: unknown): type is avro.schema.LogicalType {
    return isObject(type) && (type as avro.schema.LogicalType).logicalType === 'date';
}

// example: { name: '...', type: { type: 'int', logicalType: 'date' }}
export function isDateField(field: unknown): field is AvroFieldWithType<avro.schema.LogicalType> {
    const entryType = (field as AvroField).type;
    return isDateType(entryType);
}

// note: root schema contains a record of fields in the following structure:
// { name: '...', type: 'record', fields: [...] }
// this differs from an inner record field, which its structure is:
// example: { name: '...', type: { type: 'record', fields: [...] }}
export function isRootRecordField(field: avro.Schema): field is avro.schema.RecordType {
    return (field as avro.schema.ComplexType).type === 'record';
}

// example: { name: '...', type: { type: 'record', fields: [...] }}
export function isRecordField(field: unknown): field is AvroFieldWithType<avro.schema.RecordType> {
    return ((field as avro.schema.ComplexType).type as unknown as avro.schema.RecordType).type === 'record';
}

// example: { name: '...', type: { type: 'array', items: 'string' }}
export function isArrayField(
    field: unknown
): field is AvroFieldWithType<avro.schema.ArrayType & { readonly name: string }> {
    return isComplexFieldType(field, 'array');
}

// example: { name: '...', type: ['null', 'string']}
export function isUnionField(field: unknown): field is AvroFieldWithType<UnionField> {
    return isObject(field) && isArray((field as AvroField).type);
}

// example: { name: '...', type: { type: 'enum', symbols: ['a', 'b'] }}
export function isEnumType(type: unknown): type is avro.schema.EnumType {
    return isObject(type) && (type as AvroField).type === 'enum';
}

// example: { name: '...', type: { type: 'enum', symbols: ['a', 'b'] }}
export function isEnumField(field: unknown): field is AvroFieldWithType<avro.schema.EnumType> {
    return isComplexFieldType(field, 'enum');
}

// example: { name: '...', type: { type: 'map', values: 'string' }}
export function isMapField(field: unknown): field is AvroFieldWithType<avro.schema.MapType> {
    return isComplexFieldType(field, 'map');
}

// check if in the structure of: { name: '...', type: { type: EXPECTED_TYPE }}
function isComplexFieldType(field: unknown, type: string): field is AvroField {
    return isObject((field as AvroField).type) && ((field as AvroField).type as AvroField).type === type;
}

export function schemaToFieldsRecord(schema: SchemaResponse['schema']['fields']): Record<string, FieldEntry> {
    return keyBy(schema, 'name') as unknown as Record<string, FieldEntry>;
}
