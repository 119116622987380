import { TaskStatus } from '@lemonade-hq/bluiza';
import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';
import { TaskType } from 'components/pet/PetClaimWorkflows/TaskType';

const petBlenderUrlResolver = getUrlResolver('pet-blender', '/api/v1');

export type CreateTask = {
    claimPublicId: string;
    taskType: TaskType;
    status?: TaskStatus;
    actionDate?: string;
    operatorPublicId?: string;
};

export async function createTask({
    claimPublicId,
    taskType,
    status,
    actionDate,
    operatorPublicId,
}: CreateTask): Promise<void> {
    const url = petBlenderUrlResolver('/claim_tasks/create');

    await axios.post<void>(url, { claimPublicId, taskType, status, actionDate, operatorPublicId });
}

export async function markAsDone({
    claimPublicId,
    taskPublicId,
    taskType,
}: {
    claimPublicId: string;
    taskPublicId: string;
    taskType?: TaskType;
}) {
    const url = petBlenderUrlResolver('/claim_tasks/submit');

    return axios.post(url, { claimPublicId, taskPublicId, taskType }).then(response => {
        return response.data.data;
    });
}

export async function dismiss({
    claimPublicId,
    taskId,
    dismissReason,
}: {
    claimPublicId: string;
    taskId: string;
    dismissReason: string;
}) {
    const url = petBlenderUrlResolver('/claim_tasks/dismiss');

    return axios.post(url, { claimPublicId, taskPublicId: taskId, dismissReason }).then(response => {
        return response.data.data;
    });
}

export async function reassignUpdateOpenMissingInfoTasks({
    claimPublicId,
    taskPublicId,
}: {
    readonly claimPublicId: string;
    readonly taskPublicId: string;
}) {
    const url = petBlenderUrlResolver('/claim_tasks/missing-information-tasks/reassign-update-open');

    return await axios.post(url, { claimPublicId, taskPublicId }).then(response => {
        return response.data.data;
    });
}