import type { EntityTypes } from '@lemonade-hq/bluiza';
import { snakeToCamelCaseKeys } from '@lemonade-hq/ts-helpers';
import axios from 'axios';
import { blenderGeneral } from '../apiClients';
import type { AttachmentDTO } from 'components/Attachments/types';
import { isNewAttachmentData } from 'components/Attachments/utils';
import type { Attachment } from 'models/Attachment';
import type { Email } from 'models/Email';
import type { Recipient } from 'models/Recipient';

export interface SendEmailArgs {
    readonly recipient: Recipient;

    readonly entityId?: string;
    readonly entityType?: EntityTypes;
    readonly subject: string;
    readonly content: string;
    readonly attachments: Attachment[] | AttachmentDTO[];
    readonly isNewEmail: boolean;
    readonly ccRecipients: Recipient[];
    readonly shouldUpdateThreadOwner?: boolean;
}

export type SendEmailArgsWithEntityId = SendEmailArgs & { readonly entityId: string };

interface SendEmailPayload {
    readonly id?: string;
    readonly recipient: Recipient;
    readonly subject: string;
    readonly content: string;
    readonly attachments_ids: string[];
    readonly create_new_ticket: boolean;
    readonly cc_recipients: Recipient[];
    readonly entity_type?: EntityTypes;
    readonly update_thread_owner?: boolean;
    readonly media_attachments_public_ids?: string[];
}

export async function sendEmail(
    args: SendEmailArgsWithEntityId,
    baseUrl: string,
    camelCaseKeys = false
): Promise<void> {
    const { entityId, attachments, isNewEmail, ccRecipients, entityType, shouldUpdateThreadOwner, ...restArgs } = args;
    const url = `${baseUrl}/${entityId}/send_email`;
    const attachments_ids = attachments.map(a => (isNewAttachmentData(a) ? a.filePublicId : a.file_public_id));
    const clean_attachments_ids = attachments_ids.filter(a => a != null);
    const mediaAttachmentsPublicIds = attachments.map(a =>
        isNewAttachmentData(a) ? a.publicId : a.mediaAttachmentPublicId ?? ''
    );

    const payload: SendEmailPayload = {
        id: entityId,
        attachments_ids: clean_attachments_ids,
        media_attachments_public_ids: mediaAttachmentsPublicIds,
        create_new_ticket: isNewEmail,
        cc_recipients: ccRecipients,
        entity_type: entityType,
        update_thread_owner: shouldUpdateThreadOwner,
        ...restArgs,
    };

    if (camelCaseKeys) {
        const camelCasePayload = snakeToCamelCaseKeys({ ...payload });

        await axios.post(url, camelCasePayload);
        return;
    }

    await axios.post(url, payload);
}

export async function mockWebHook(emailId: string, notificationsEmailId: string, event: string): Promise<void> {
    const url = `/backoffice/emails/${emailId}`;
    const body = {
        event,
        notifications_email_id: notificationsEmailId,
    };

    await axios.patch(url, body).then(() => {
        window.location.reload();
    });
}

export async function getEmails(options?: { readonly ticketPublicIds?: string[] | string }): Promise<Email[]> {
    const response = await blenderGeneral.get<{ data: Email[] }>('/api/v1/emails', { params: options });

    return response.data.data;
}
