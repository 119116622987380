import { Checkbox, Radio } from '@lemonade-hq/bluis';

import { Step, StyledMultilineInputWrapper, StyledRadioWrapper } from '../ManageRuleCommon';
import { useManageRuleDialogContext } from '../ManageRuleDialogContext';
import { formatLifeCycleContext } from 'components/LoCo/common/display-texts/common';
import { QuoteLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import { CoverageRuleType } from 'models/LoCo/Insurance/CoverageRule';

export const ContextSelection: React.FC = () => {
    const {
        values: { lifecycleContexts, ruleType },
        dispatch,
    } = useManageRuleDialogContext();
    const options = [QuoteLifecycleContext.NewBusiness, QuoteLifecycleContext.Renewal, QuoteLifecycleContext.Moving];
    if (ruleType === CoverageRuleType.Restriction) options.push(QuoteLifecycleContext.PolicyEdit);

    const isMultiSelect = ruleType === CoverageRuleType.Restriction;
    const SelectionComponent = isMultiSelect ? Checkbox : Radio;

    const handleChange = (value: QuoteLifecycleContext): void => {
        let newValues;
        if (isMultiSelect) {
            newValues = lifecycleContexts?.includes(value)
                ? lifecycleContexts.filter(v => v !== value)
                : [...(lifecycleContexts ?? []), value];
        } else {
            newValues = [value];
        }

        dispatch({
            type: 'lifecycleContexts',
            value: newValues,
        });
    };

    return (
        <Step gap="20px" width="530px">
            <StyledMultilineInputWrapper label="Lifecycle context">
                <StyledRadioWrapper>
                    {options.map(lifecycleContext => (
                        <SelectionComponent
                            checked={lifecycleContexts?.includes(lifecycleContext)}
                            id={lifecycleContext}
                            key={lifecycleContext}
                            name={lifecycleContext}
                            onChange={() => handleChange(lifecycleContext)}
                        >
                            {formatLifeCycleContext(lifecycleContext)}
                        </SelectionComponent>
                    ))}
                </StyledRadioWrapper>
            </StyledMultilineInputWrapper>
        </Step>
    );
};
