import { Flex } from '@lemonade-hq/cdk';
import type { ReactElement } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { useCarousel } from './CarouselContext';
import type { ZoomTypes } from './CarouselMenu';
import type { GetCarouselMenuProps } from 'components/Bluis/AttachmentsPreview/CarouselPreview';

export const StyleCarouselWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    padding: 0 7%;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    flex: 1;
`;

const StyledCarouselButton = styled.button`
    z-index: 2;
    background: black;
    border: 0 none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    outline: none;
    &:hover {
        background-color: #2b2b2b;
    }
`;

const StyledNavButton = styled(StyledCarouselButton)<{ readonly isNext?: boolean; readonly isDisabled: boolean }>`
    ${({ isNext }) => (isNext ? 'right:10px' : 'left:10px')};
    border-radius: 50%;
    height: 38px;
    width: 38px;
    margin: 0 20px;
    transform: ${({ isNext }) => (isNext ? 'rotate(180deg)' : '')};
    background-image: url('${__assets_url}blender_assets/backoffice/previous.svg');
    background-repeat: no-repeat;
    background-position: center;
    ${({ isDisabled }) =>
        isDisabled &&
        `
    opacity: 0;
    pointer-events: none;
    `}
    &:active {
        transform: scale(0.9) ${({ isNext }) => (isNext ? 'rotate(180deg)' : '')};
        background-color: black;
    }
`;

const CarouselWrapper = styled(Flex)`
    flex-flow: column;
    align-items: center;
    flex: 1;
    overflow: hidden;
    position: relative;
`;

const StyledCarousel = styled.div`
    position: relative;
    width: 80%;
    overflow: hidden;
    flex: 1;
    max-height: 100%;
    align-self: stretch;
`;

export const StyledSlider = styled.ul<{ readonly selectedIndex: number }>`
    display: flex;
    transition: all 0.3s ease-in-out;
    padding: 0;
    margin: 0;
    transform: translate3d(${({ selectedIndex }) => selectedIndex * -100}%, 0px, 0px);
    height: 100%;
`;

export const StyledSlide = styled.li`
    min-width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    z-index: -1;
`;

const Slide: React.FC<{ readonly index: number; readonly child: JSX.Element; readonly zoom: number }> = ({
    index,
    child,
    zoom,
}) => {
    const { selectedIndex, updateLoading, updateError, getIsError, getIsLoading } = useCarousel();
    const { ref, inView } = useInView({
        triggerOnce: true,
    });

    const getStatusProps = (index: number) => {
        return {
            updateLoading: (loading: boolean) => updateLoading(index, loading),
            updateError: (error: boolean) => updateError(index, error),
            getIsLoading: () => getIsLoading(index),
            getIsError: () => getIsError(index),
        };
    };

    return (
        <StyledSlide ref={ref}>
            {inView
                ? React.cloneElement(child, {
                      zoom,
                      isSelected: index === selectedIndex,
                      ...getStatusProps(index),
                  })
                : null}
        </StyledSlide>
    );
};

type CarouselProps = {
    readonly children: ReactElement[];
    readonly getCarouselMenu: (props: GetCarouselMenuProps) => JSX.Element;
    readonly onNavigation?: (index: number) => void;
    readonly onSelectedIndexToCompare?: (index: number) => void;
};

const Carousel: React.FC<React.PropsWithChildren<CarouselProps>> = ({
    children,
    getCarouselMenu,
    onNavigation,
    onSelectedIndexToCompare,
}) => {
    const { selectedIndex, setSelectedIndex } = useCarousel();
    const selectedIndexRef = useRef(selectedIndex);

    const changeSelectedIndex = (index: number) => {
        selectedIndexRef.current = index;
        setSelectedIndex(index);
        onNavigation?.(index);
    };

    const [zoom, setZoom] = useState(100);
    const zoomRef = useRef(zoom);
    const onZoom = (type: ZoomTypes) => {
        let newZoom = zoomRef.current;

        switch (type) {
            case 'minus':
                newZoom = Math.max(newZoom - 10, 10);
                break;
            case 'plus':
                newZoom += 10;
                break;
            default:
                newZoom = 100;
                break;
        }

        setZoom(newZoom);
        zoomRef.current = newZoom;
    };

    useEffect(() => {
        setZoom(100);
        zoomRef.current = 100;
        changeSelectedIndex(selectedIndex);
    }, [selectedIndex]);

    const handleKeyDown = (e: KeyboardEvent) => {
        e.preventDefault();
        if (e.key === 'ArrowRight') {
            changeSelectedIndex(Math.min(selectedIndexRef.current + 1, children.length - 1));
        } else if (e.key === 'ArrowLeft') {
            changeSelectedIndex(Math.max(selectedIndexRef.current - 1, 0));
        } else if (e.key === '+') {
            onZoom('plus');
        } else if (e.code === 'Minus') {
            onZoom('minus');
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CarouselWrapper>
            <StyleCarouselWrapper>
                <StyledNavButton
                    isDisabled={selectedIndex === 0}
                    onClick={() => changeSelectedIndex(selectedIndex - 1)}
                />
                <StyledCarousel>
                    <StyledSlider selectedIndex={selectedIndex}>
                        {children.map((child, index) => (
                            <Slide child={child} index={index} key={`${child.key}-wrapper`} zoom={zoom} />
                        ))}
                    </StyledSlider>
                </StyledCarousel>
                <StyledNavButton
                    isDisabled={selectedIndex >= children.length - 1}
                    isNext
                    onClick={() => changeSelectedIndex(selectedIndex + 1)}
                />
            </StyleCarouselWrapper>
            {getCarouselMenu({
                selectedIndex,
                onZoom,
                total: children.length,
                onSelectedIndexToCompare,
            })}
        </CarouselWrapper>
    );
};

export default Carousel;
