import { ErrorSection, LoadingSection } from '@lemonade-hq/bluis';
import type { Maybe } from '@lemonade-hq/ts-helpers';
import { ErrorBoundary } from '@sentry/react';
import { Suspense, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ManageReleaseStep } from '../ReleasesShared';
import type { EditionSelectionData } from './EditionSelectInputs';
import { EditionsSelection } from './EditionsSelection';
import { EffectiveDates } from './EffectiveDates';
import type { EffectiveDatesStepType } from './EffectiveDates';
import { ReleaseTypeSelection } from './ReleaseTypeSelection';
import { RolloutStrategyStep } from './RolloutStrategyStep';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import { StyledDialog } from 'components/LoCo/common/components/Dialog/Dialog';
import { useMiniFlowFormDialog } from 'components/LoCo/common/components/Dialog/useMiniFlowFormDialog';
import { StyledMiniFlow } from 'components/LoCo/common/components/MiniFlow';
import { parseLocalDateToUtcDate } from 'components/LoCo/common/helpers/dateHelpers';
import { getReleaseUrl } from 'components/LoCo/common/urlBuilders';
import type { LeanEdition } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType, ReleaseType, VersionType } from 'models/LoCo/Insurance/BaseEdition';
import { useCreateRelease } from 'queries/LoCo/Insurance/ReleasesQueries';

export enum RolloutStrategy {
    NewBusinessAndRenewals = 'NewBusinessAndRenewals',
    ActivePoliciesAndPendingRenewals = 'ActivePoliciesAndPendingRenewals',
}

export interface RolloutStrategies {
    readonly [RolloutStrategy.NewBusinessAndRenewals]: boolean;
    readonly [RolloutStrategy.ActivePoliciesAndPendingRenewals]: boolean;
}

function getEditionCode(
    editions: readonly DeepNullable<LeanEdition>[],
    editionType: EditionType
): Maybe<string> {
    return editions.find(edition => edition.type === editionType)?.code ?? undefined;
}

function getVersionType(
    releaseType: ReleaseType | null,
    rolloutStrategies: DeepNullable<RolloutStrategies>
): VersionType {
    return releaseType === ReleaseType.ProductChange
        ? rolloutStrategies[RolloutStrategy.ActivePoliciesAndPendingRenewals]
            ? VersionType.Minor
            : VersionType.Major
        : VersionType.BugFix;
}

const stepIdsWithoutFirst = [
    ManageReleaseStep.Editions,
    ManageReleaseStep.RolloutStrategy,
    ManageReleaseStep.EffectiveDates,
];

const stepIds = [ManageReleaseStep.ReleaseType, ...stepIdsWithoutFirst];

type ManageReleaseDialogData = {
    readonly releaseType: ReleaseType;
    readonly editions: EditionSelectionData;
    readonly rolloutStrategies: {
        readonly [RolloutStrategy.NewBusinessAndRenewals]: boolean;
        readonly [RolloutStrategy.ActivePoliciesAndPendingRenewals]: boolean;
    };
    readonly effectiveDates: EffectiveDatesStepType;
    readonly ratingReferenceEditionCode: string;
};

export const ManageReleaseDialog: React.FC<{
    readonly skipFirstStep: boolean;
    readonly productCode: string;
    readonly onClose: () => void;
}> = ({ productCode, skipFirstStep, onClose }) => {
    const { mutateAsync: createRelease, isError, isPending } = useCreateRelease();
    const navigate = useNavigate();

    const { actions, currentStep, dispatch, state } = useMiniFlowFormDialog<
        ManageReleaseStep,
        DeepNullable<ManageReleaseDialogData>
    >({
        steps: skipFirstStep ? stepIdsWithoutFirst : stepIds,
        onClose,
        onSubmit: async _data => {
            const versionType = getVersionType(state.releaseType, state.rolloutStrategies);
            const release = await createRelease({
                productCode,
                rolloutStrategy: versionType,
                underwritingFiltersEditionCode: getEditionCode(
                    state.editions.selectedEditions,
                    EditionType.UnderwritingFilters
                ),
                coveragesEditionCode: getEditionCode(state.editions.selectedEditions, EditionType.Coverages),
                digitalAgentEditionCode: getEditionCode(
                    state.editions.selectedEditions,
                    EditionType.DigitalAgent
                ),
                ratingEditionCode: getEditionCode(state.editions.selectedEditions, EditionType.Rating),
                newBusinessEffectiveAt: parseLocalDateToUtcDate(state.effectiveDates.newBusiness),
                renewalEffectiveAt:
                    state.releaseType === ReleaseType.ProductChange && versionType === VersionType.Minor
                        ? parseLocalDateToUtcDate(state.effectiveDates.newBusiness)
                        : parseLocalDateToUtcDate(state.effectiveDates.renewals),
                platformSchemaRevision:
                    state.editions.selectedPlatformSchema === null
                        ? undefined
                        : Number(state.editions.selectedPlatformSchema),
                productSchemaRevision:
                    state.editions.selectedProductSchema === null
                        ? undefined
                        : Number(state.editions.selectedProductSchema),
                ratingReferenceEditionCode: state.ratingReferenceEditionCode,
            });

            navigate(getReleaseUrl(productCode, release.publicId));
        },
        onNext: step => {
            if (step === ManageReleaseStep.ReleaseType && state.releaseType === ReleaseType.BugFix) {
                dispatch({
                    type: 'effectiveDates',
                    value: {
                        newBusiness: null,
                        renewals: null,
                        isValid: true,
                    },
                });
            }
        },
        isNextDisabled: (data, step) => {
            let ratingSelected: boolean;
            switch (step) {
                case ManageReleaseStep.ReleaseType:
                    return data.releaseType === null;
                case ManageReleaseStep.Editions:
                    return !data.editions.isValid;
                case ManageReleaseStep.RolloutStrategy:
                    ratingSelected = data.editions.selectedEditions.some(e => e.type === EditionType.Rating);

                    if (data.releaseType === ReleaseType.ProductChange) {
                        return (
                            (!data.rolloutStrategies[RolloutStrategy.NewBusinessAndRenewals] &&
                                !data.rolloutStrategies[RolloutStrategy.ActivePoliciesAndPendingRenewals]) ||
                            (Boolean(data.rolloutStrategies[RolloutStrategy.ActivePoliciesAndPendingRenewals]) &&
                                ratingSelected &&
                                data.ratingReferenceEditionCode == null)
                        );
                    }

                    return ratingSelected && data.ratingReferenceEditionCode == null;
                case ManageReleaseStep.EffectiveDates:
                    return !data.effectiveDates.isValid;
                default:
                    return false;
            }
        },
        clear: step => {
            switch (step) {
                case ManageReleaseStep.Editions:
                    dispatch({
                        type: 'editions',
                        value: {
                            selectedEditions: [],
                            selectedPlatformSchema: null,
                            selectedProductSchema: null,
                            isValid: false,
                        },
                    });
                    break;
                case ManageReleaseStep.RolloutStrategy:
                    dispatch({
                        type: 'rolloutStrategies',
                        value: {
                            [RolloutStrategy.NewBusinessAndRenewals]: false,
                            [RolloutStrategy.ActivePoliciesAndPendingRenewals]: false,
                        },
                    });
                    dispatch({
                        type: 'ratingReferenceEditionCode',
                        value: null,
                    });
                    break;
                case ManageReleaseStep.EffectiveDates:
                    dispatch({
                        type: 'effectiveDates',
                        value: {
                            newBusiness: null,
                            renewals: null,
                            isValid: state.releaseType === ReleaseType.BugFix,
                        },
                    });
                    break;
                default:
                    break;
            }
        },
        initialData: {
            releaseType: skipFirstStep ? ReleaseType.ProductChange : null,
            editions: {
                selectedEditions: [],
                selectedPlatformSchema: null,
                selectedProductSchema: null,
                isValid: false,
            },
            rolloutStrategies: {
                [RolloutStrategy.NewBusinessAndRenewals]: false,
                [RolloutStrategy.ActivePoliciesAndPendingRenewals]: false,
            },
            effectiveDates: {
                newBusiness: null,
                renewals: null,
                isValid: false,
            },
            ratingReferenceEditionCode: null,
        },
    });

    const steps = useMemo(
        () => [
            {
                title: 'release type',
                body: (
                    <ReleaseTypeSelection
                        onChange={value => dispatch({ type: 'releaseType', value })}
                        value={state.releaseType}
                    />
                ),
                id: ManageReleaseStep.ReleaseType,
            },
            {
                title: 'Select editions',
                body: (
                    <EditionsSelection
                        editionSelectionData={state.editions}
                        onChange={newEditionStepData =>
                            dispatch({
                                type: 'editions',
                                value: newEditionStepData,
                            })
                        }
                        productCode={productCode}
                        releaseType={state.releaseType}
                    />
                ),
                id: ManageReleaseStep.Editions,
            },
            {
                title: 'Rollout strategy',
                body: (
                    <RolloutStrategyStep
                        onChange={value => {
                            if (value.ratingReferenceEditionCode !== undefined) {
                                dispatch({
                                    type: 'ratingReferenceEditionCode',
                                    value: value.ratingReferenceEditionCode,
                                });
                            } else {
                                dispatch({
                                    type: 'rolloutStrategies',
                                    value: value.rolloutStrategies ?? null,
                                });
                            }
                        }}
                        productCode={productCode}
                        releaseType={state.releaseType}
                        selectedEditions={state.editions.selectedEditions as LeanEdition[]}
                        selectedRatingEdition={state.ratingReferenceEditionCode}
                        selectedStrategies={state.rolloutStrategies}
                    />
                ),
                id: ManageReleaseStep.RolloutStrategy,
            },
            {
                title: 'Effective dates',
                body: (
                    <EffectiveDates
                        effectiveDates={state.effectiveDates}
                        onChange={value => dispatch({ type: 'effectiveDates', value })}
                        releaseType={state.releaseType}
                        versionType={getVersionType(state.releaseType, state.rolloutStrategies)}
                    />
                ),
                id: ManageReleaseStep.EffectiveDates,
            },
        ],
        [
            dispatch,
            productCode,
            state.editions,
            state.effectiveDates,
            state.ratingReferenceEditionCode,
            state.releaseType,
            state.rolloutStrategies,
        ]
    );

    return (
        <StyledDialog
            actions={actions}
            closeOnOutsideClick={false}
            error={isError ? 'Failed to create release' : undefined}
            loading={isPending}
            minWidth="600px"
            onClose={onClose}
            size="large"
            title={'Start a Release'}
        >
            <ErrorBoundary fallback={<ErrorSection noBorders />}>
                <Suspense fallback={<LoadingSection noBorders />}>
                    <StyledMiniFlow activeStepIndex={steps.findIndex(step => step.id === currentStep)} steps={steps} />
                </Suspense>
            </ErrorBoundary>
        </StyledDialog>
    );
};
