import { ComboBox, Flex, Layout, shimmering, shimmeringMockBlock, spacing, Text } from '@lemonade-hq/blender-ui';
import { trackEvent } from '@lemonade-hq/boutique';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useGetAttachmentsFilters } from '../AttachmentsQueries';
import type { AttachmentFilter } from '../AttachmentsQueries';
import { useAttachmentsData } from '../context';

export function getInitialFilters(hasUnreviewedAttachments: boolean, customFilters?: AttachmentFilter[]): HubFilters {
    return {
        searchTerm: '',
        page: 1,
        status: 'active',
        // if we are not on unreviewed tab, we don't want to filter by reviewed
        reviewed: hasUnreviewedAttachments ? ('false' as Reviewed) : undefined,
        sortDirection: 'DESC',
        ...customFilters?.reduce((acc, curr) => ({ ...acc, [curr.name]: [] }), {}),
    };
}

export function resetComboBoxFilters(filters: HubFilters, customFilters?: AttachmentFilter[]): HubFilters {
    return {
        ...filters,
        ...customFilters?.reduce((acc, curr) => ({ ...acc, [curr.name]: [] }), {}),
    };
}

function getDefaultValue(value?: string[]): string[] | undefined {
    return value != null && value.length > 0 ? value : undefined;
}

export type Reviewed = 'false' | 'true';

export interface HubFilters extends Record<string, unknown> {
    // Search
    readonly searchTerm: string;
    // Pagination
    readonly page: number;
    // tabs
    readonly status: string;
    readonly reviewed?: Reviewed;
    // Sort
    readonly sortDirection: string;
}

interface FiltersProps {
    readonly filters: HubFilters;
    readonly updateFilters: Dispatch<SetStateAction<HubFilters>>;
}

export const Filters: FC<FiltersProps> = ({ filters, updateFilters }) => {
    const { entityType, entityPublicId } = useAttachmentsData();
    // get filters, with current filters
    const { data, isError, isLoading } = useGetAttachmentsFilters({ entityType, entityPublicId, filters });

    if (isLoading) {
        return (
            <Layout
                className={shimmering}
                display="grid"
                gap={spacing.s08}
                gridTemplateColumns="repeat(4, 1fr)"
                width="70%"
            >
                <Layout className={shimmeringMockBlock} height="3rem" />
                <Layout className={shimmeringMockBlock} height="3rem" />
                <Layout className={shimmeringMockBlock} height="3rem" />
                <Layout className={shimmeringMockBlock} height="3rem" />
            </Layout>
        );
    }

    if (isError) return <Text color="error">Error loading filters</Text>;
    if (data == null) return null;

    const handleChange = (filter: AttachmentFilter, items: { value: string; label: string }[] | null): void => {
        trackEvent('docs.clicked', {
            entity_id: entityPublicId,
            entity_type: entityType,
            action: 'filter',
            action_type: 'filter',
            filter_type: filter.name,
            filter_value: items?.map(i => i.value).join(',') ?? '',
        });
        updateFilters(prev => ({ ...prev, [filter.name]: items?.map(i => i.value) ?? [] }));
    };

    return (
        <Flex gap={spacing.s08}>
            {data.map(filter => {
                return (
                    <ComboBox
                        cancelable
                        defaultValue={getDefaultValue(filters[filter.name] as string[])}
                        disabled={filter.options.length === 0}
                        items={filter.options.map(option => ({
                            value: option.value,
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            label: option.label ?? option.value,
                        }))}
                        key={filter.name}
                        maxAmountOfChips={2}
                        mode="multiple"
                        onSelectionChange={items => handleChange(filter, items)}
                        placeholder={filter.displayValue ?? filter.label}
                        variant="brand"
                    />
                );
            })}
        </Flex>
    );
};
