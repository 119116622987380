import { assignmentTypes, mapAttachmentType } from 'commons/AttachmentsUtils';
import { toReadable } from 'commons/StringUtils';
import type { Attachment, AttachmentGroup, AttachmentType } from 'models/Attachment';

export function typeToDisplayName(type: AttachmentType): string {
    const option = assignmentTypes.find(option => option.value === type);

    return option ? option.value : toReadable(mapAttachmentType(type));
}

export function buildGroupedAttachments<TAttachment extends Attachment = Attachment>(
    attachments: TAttachment[]
): AttachmentGroup<TAttachment>[] {
    return attachments.reduce((acc, attachment, currentIndex) => {
        const group = acc.find(({ type }) => type === attachment.type);

        if (group != null) {
            group.items.push({ ...attachment, index: currentIndex });
        } else {
            acc.push({
                displayName: typeToDisplayName(attachment.type),
                items: [{ ...attachment, index: currentIndex }],
                type: attachment.type,
            });
        }

        return acc;
    }, [] as AttachmentGroup<TAttachment>[]);
}
