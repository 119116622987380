import React from 'react';
import InlineInputEditor from 'bluis/InlineInputEditor';
import type { Attachment } from 'models/Attachment';

interface AttachmentDescriptionEditorProps {
    readonly description: string;
    readonly id: string;
    readonly updateAttachmentData: (
        attachmentId: string,
        description?: string
    ) => Promise<Attachment | null | undefined | void>;
    readonly editorVisible: boolean;
    readonly closeEditor: () => void;
}

const AttachmentDescriptionEditor: React.FC<React.PropsWithChildren<AttachmentDescriptionEditorProps>> = ({
    description,
    id,
    updateAttachmentData,
    editorVisible,
    closeEditor,
}) => {
    async function onSubmitDescription(descriptionText: string) {
        await updateAttachmentData(id, descriptionText);
        closeEditor();
    }

    return (
        <InlineInputEditor
            closeEditor={closeEditor}
            defaultValue={description}
            editorVisible={editorVisible}
            text={description}
            updateText={onSubmitDescription}
        />
    );
};

export default AttachmentDescriptionEditor;
