import { useQuery, UseQueryResult, UseBaseMutationResult, QueryKey } from '@tanstack/react-query';
import { sendUpdateLink } from 'apis/ClaimsAPITyped';
import { CommunicationMethods } from 'models/Interaction';
import { Transaction } from 'models/Payment';
import { payInvoice, simulatePayoutSuccess, simulatePayoutFailure, PayInvoice, cancelPayment } from 'apis/PayoutsAPI';
import { usePessimisticMutationLegacy } from './MutationHooks';

export enum ClaimPaymentQueryKeys {
    GetClaimPayments = 'GET_CLAIM_PAYMENTS',
}

export function useClaimPayments(
    claimId?: string,
    getClaimPaymentsFn?: (claimId?: string) => Promise<{ transactions: Transaction[][] }>
): UseQueryResult<{ transactions: Transaction[][] }> {
    return useQuery({
        queryKey: [ClaimPaymentQueryKeys.GetClaimPayments, claimId],
        queryFn: async () => getClaimPaymentsFn?.(claimId),
        enabled: claimId != null,
    });
}

export function useSimulatePayoutSuccessMutation(
    claimId: string,
    additionalQueryKeys: QueryKey[] = []
): UseBaseMutationResult<
    null,
    unknown,
    {
        transactionId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(simulatePayoutSuccess, [
        [ClaimPaymentQueryKeys.GetClaimPayments, claimId],
        ...additionalQueryKeys,
    ]);
}

export function useSimulatePayoutFailureMutation(
    claimId: string,
    additionalQueryKeys: QueryKey[] = []
): UseBaseMutationResult<
    null,
    unknown,
    {
        transactionId: string;
        paymentMethodType: string;
        errorCode: string;
    },
    null
> {
    return usePessimisticMutationLegacy(simulatePayoutFailure, [
        [ClaimPaymentQueryKeys.GetClaimPayments, claimId],
        ...additionalQueryKeys,
    ]);
}

export function usePayInvoiceMutation(
    claimId: string,
    options?: {
        invalidateQueryKeys?: QueryKey[];
    }
): UseBaseMutationResult<null, unknown, PayInvoice, null> {
    return usePessimisticMutationLegacy(payInvoice, [
        [ClaimPaymentQueryKeys.GetClaimPayments, claimId],
        ...(options?.invalidateQueryKeys ?? []),
    ]);
}

export function useSendUpdateLinkMutation(
    claimId: string
): UseBaseMutationResult<
    null,
    unknown,
    {
        failedPayoutId: string;
        methods: CommunicationMethods;
    },
    null
> {
    return usePessimisticMutationLegacy(sendUpdateLink, [[ClaimPaymentQueryKeys.GetClaimPayments, claimId]]);
}

export function useCancelPaymentMutation(claimId: string): UseBaseMutationResult<null, unknown, string, null> {
    return usePessimisticMutationLegacy(cancelPayment, [[ClaimPaymentQueryKeys.GetClaimPayments, claimId]]);
}
