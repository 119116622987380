import { Input } from '@lemonade-hq/bluis';
import { themedColor } from '@lemonade-hq/boutique';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import type { CarClaimsAttachmentsFilterFields, CarClaimsAttachmentsFiltersOptions } from './types';
import { INIT_SEARCH_PROPS } from './types';
import {
    ButtonsContainer,
    ClearButton,
    FiltersContainer,
    MultiSelect,
    TextBoxContainer,
    TextBoxTitle,
    TextBoxWrapper,
} from 'bluis/EntitiesTable/filters-styles';
import type { AttachmentSource } from 'models/Attachment';
import type { CarAttachmentType } from 'models/CarShared';

const StyledFiltersContainer = styled(FiltersContainer)`
    width: auto;
    padding: 33px 65px 33px 55px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${themedColor('generalBackground')};
`;

interface FiltersProps {
    readonly selectedFilters: CarClaimsAttachmentsFilterFields;
    readonly options: CarClaimsAttachmentsFiltersOptions;
    readonly updateFilters: (filters: Partial<CarClaimsAttachmentsFilterFields>) => void;
}

const DescriptionFilter: React.FC<
    React.PropsWithChildren<{
        readonly description?: string;
        readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
        readonly onClear: () => void;
    }>
> = props => {
    return (
        <TextBoxWrapper>
            <TextBoxTitle>Description</TextBoxTitle>
            <TextBoxContainer>
                <Input
                    defaultValue=""
                    id="description"
                    onChange={props.onChange}
                    onClear={props.onClear}
                    placeholder=""
                    value={props.description}
                />
            </TextBoxContainer>
        </TextBoxWrapper>
    );
};

export const SearchFilters: React.FC<React.PropsWithChildren<FiltersProps>> = ({
    selectedFilters,
    options,
    updateFilters,
}) => {
    const onTypeSelect = useCallback(
        (value: string[]) => {
            updateFilters({ types: value as CarAttachmentType[] });
        },
        [updateFilters]
    );

    const onInvolvedPartySelect = useCallback(
        (value: string[]) => {
            updateFilters({ involvedPartyIds: value });
        },
        [updateFilters]
    );

    const onSourceSelect = useCallback(
        (value: string[]) => {
            updateFilters({ sources: value as AttachmentSource[] });
        },
        [updateFilters]
    );

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;

            updateFilters({ description: value });
        },
        [updateFilters]
    );

    const onClear = useCallback(() => {
        updateFilters({ description: '' });
    }, [updateFilters]);

    const clearFilters = useCallback(() => {
        const { types, description, involvedPartyIds, sources, offset, orderBy, orderByDir } = INIT_SEARCH_PROPS;

        updateFilters({ types, description, involvedPartyIds, sources, offset, orderBy, orderByDir });
    }, [updateFilters]);

    return (
        <StyledFiltersContainer>
            <MultiSelect
                defaultValue="All"
                id="types"
                multiple
                onSelect={onTypeSelect}
                options={options.type}
                selectedList={(selectedFilters.types ?? []) as string[]}
                title="Type"
                withFilter
            />
            <DescriptionFilter description={selectedFilters.description} onChange={onChange} onClear={onClear} />
            <MultiSelect
                defaultValue="All"
                id="involvedParties"
                multiple
                onSelect={onInvolvedPartySelect}
                options={options.involvedParty}
                selectedList={selectedFilters.involvedPartyIds ?? []}
                title="Involved Party"
                withFilter
            />
            <MultiSelect
                defaultValue="All"
                id="sources"
                multiple
                onSelect={onSourceSelect}
                options={options.source}
                selectedList={(selectedFilters.sources ?? []) as string[]}
                title="Source"
                withFilter
            />

            <ButtonsContainer>
                <ClearButton onClick={clearFilters}>Clear</ClearButton>
            </ButtonsContainer>
        </StyledFiltersContainer>
    );
};
