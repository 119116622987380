/* eslint-disable @typescript-eslint/naming-convention */
import { Flex, LabelValueTable, Layout, spacing, Text } from '@lemonade-hq/blender-ui';
import type { DialogProps } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import { DATE_FNS_FORMATS } from '@lemonade-hq/lemonation';
import { format } from 'date-fns-tz';
import { useMemo } from 'react';
import type { FC } from 'react';
import type { AttachmentDTO } from 'components/Attachments/types';
import { MISSING_VALUE } from 'components/Attachments/utils';

export const AdditionalInfoDialog: FC<{
    readonly attachment: AttachmentDTO;
    readonly onClose: () => void;
}> = ({ attachment, onClose }) => {
    const { fileName } = attachment;

    const dialogProps: DialogProps = {
        title: 'Additional Info',
        actions: [
            {
                type: 'close',
                text: 'Close',
                onClick: onClose,
            },
        ],
    };

    const info = useMemo(
        () => ({
            'General Info': {
                'Upload method': attachment.sources?.map(source => source.description).join(', ') ?? null,
                Sender:
                    attachment.customAdditionalData?.find(data => data.name === 'sender')?.data[0].label.text ?? null,
                Direction:
                    attachment.customAdditionalData?.find(data => data.name === 'direction')?.data[0].label.text ??
                    null,
            },
            Metadata: {
                'File type': attachment.type != null ? attachment.type.label : null,
                'Geo location':
                    attachment.visualMedia?.exifData?.latitude != null ? (
                        <a
                            href={`https://www.google.com/maps/@${attachment.visualMedia.exifData.latitude},${attachment.visualMedia.exifData.longitude},15z`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            View on Google Maps
                        </a>
                    ) : null,
                Make: attachment.visualMedia?.exifData?.Make ?? null,
                Model: attachment.visualMedia?.exifData?.Model ?? null,
                'Date created':
                    attachment.visualMedia?.exifData?.createdAt != null
                        ? format(
                              new Date(attachment.visualMedia.exifData.createdAt),
                              DATE_FNS_FORMATS.iso8601UtcHumanizedNoTime
                          )
                        : attachment.uploadedAt != null
                          ? format(new Date(attachment.uploadedAt), DATE_FNS_FORMATS.iso8601UtcHumanizedNoTime)
                          : null,
                'Date modified':
                    attachment.visualMedia?.exifData?.updatedAt != null
                        ? format(
                              new Date(attachment.visualMedia.exifData.updatedAt),
                              DATE_FNS_FORMATS.iso8601UtcHumanizedNoTime
                          )
                        : null,
            },
        }),
        [attachment]
    );

    return (
        <Dialog {...dialogProps}>
            <Flex alignItems="flex-start" flexDirection="column" gap={spacing.s12}>
                <Text>Filename: {fileName}</Text>
                {Object.entries(info).map(([key, value]) => (
                    <Flex alignItems="flex-start" flexDirection="column" gap={spacing.s12} key={key} mt={spacing.s12}>
                        <Text as="p" color="secondary" fontWeight="bold" type="text-md">
                            {key}
                        </Text>
                        <Layout mb={spacing.s12}>
                            <LabelValueTable
                                columnCount={1}
                                data={Object.entries(value).map(([innerKey, innerValue]) => ({
                                    label: innerKey,
                                    value: innerValue ?? MISSING_VALUE,
                                }))}
                                labelWidth="12rem"
                            />
                        </Layout>
                    </Flex>
                ))}
            </Flex>
        </Dialog>
    );
};
