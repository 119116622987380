import type { ChildSeatStatus } from './CarClaim';
import type { MedicareMissingDetailsCard } from 'models/CarMedicareMissingDetailsCard';
import type { Location } from 'models/CarShared';

export enum PrimaryInsuredRelation {
    Primary = 'primary',
    Parent = 'parent',
    Spouse = 'spouse',
    SignificantOther = 'significant_other',
    Child = 'child',
    Sibling = 'sibling',
    Relative = 'relative',
    NoFamilyRelation = 'no_family_relation',
}

export enum InvolvedPersonRole {
    FirstPartyDriver = 'first_party_driver',
    ThirdPartyDriver = 'third_party_driver',
    FirstPartyPassenger = 'first_party_passenger',
    ThirdPartyPassenger = 'third_party_passenger',
    ContactPerson = 'contact_person',
    Cyclist = 'cyclist',
    Pedestrian = 'pedestrian',
    Attorney = 'attorney',
    Witness = 'witness',
    Other = 'other',
}

export enum InvolvedPartyType {
    Person = 'person',
    Vehicle = 'vehicle',
}

export enum InvolvedPersonConnection {
    Attorney = 'attorney',
    LegalGuardian = 'legal_guardian',
}

export interface ConnectedInvolvedPerson {
    readonly publicId: string;
    readonly connection: InvolvedPersonConnection;
}

export enum ShopType {
    LemonadeShop = 'lemonade',
    OtherShop = 'other',
    NoSelection = 'no_selection',
}

export const LemonadeInvolvedPersonId = 'lemonade';

export interface InvolvedPerson {
    readonly type: InvolvedPartyType.Person;

    readonly publicId: string;

    readonly userPublicId?: string | null;

    readonly driverPublicId?: string | null;

    readonly involvedVehiclePublicId?: string;

    readonly name: string;

    readonly role: InvolvedPersonRole;

    readonly dateOfBirth?: string;

    readonly phoneNumber?: string | null;

    readonly email?: string | null;

    readonly address?: Location | null;

    readonly additionalInfo?: string | null;

    readonly relationToPrimaryInsured?: PrimaryInsuredRelation | null;

    readonly connectedInvolvedPeople?: ConnectedInvolvedPerson[];

    readonly minor?: boolean;

    readonly injured: boolean;

    readonly emergencyEvacuation?: boolean;

    readonly injuryDescription?: string;

    readonly firstParty: boolean;

    readonly incidentStatement?: string;

    readonly incidentStatementDate?: string;

    readonly incidentStatementAdjusterName?: string;

    readonly unreachableByPhone?: boolean;

    readonly collectDetailsCallSummary?: string;

    readonly collectDetailsCallSummaryDate?: string;

    readonly collectDetailsCallSummaryAdjusterName?: string;

    readonly injuriesUnreachableByPhone?: boolean;

    readonly failedRulesOfTheRoad?: boolean;

    readonly breachOfDuties?: boolean;

    readonly breachCausedDamages?: boolean;

    readonly liabilityPercentage?: number;

    readonly coveredByMedicareOrMedicaid?: boolean;

    readonly firmName?: string;

    readonly taxId?: string;

    readonly gender?: string;

    readonly ssnLastFour?: string;

    readonly ssnLastFourOfLastFive?: string;

    readonly billsSummary?: string;

    readonly representsLemonade?: boolean;

    readonly injuryInsuranceDetails?: InjuryInsuranceDetails;

    readonly medicareMissingDetailsCard?: MedicareMissingDetailsCard;

    readonly medicareId?: string;
}

export interface InjuryInsuranceDetails {
    readonly healthInsuranceInfo?: string | null;
    readonly healthInsuranceInEffect?: boolean | null;
    readonly healthInsuranceDateChanged?: Date | null;
    readonly healthInsuranceAdditionalInfo?: string | null;
    readonly consideredCat?: boolean | null;
    readonly catAdditionalInfo?: string | null;
    readonly confirmedPenaltyAdded?: boolean | null;
    readonly claimantHasPipPolicy?: boolean | null;
    readonly claimantFamilyHasPipPolicy?: boolean | null;
    readonly fullOrLimitedTortCoverage?: string | null;
    readonly additionalLossesIncurred?: boolean | null;
    readonly lossesAdditionalInfo?: string | null;
}

export enum InvolvedVehicleRole {
    FirstPartyVehicle = 'first_party_vehicle',
    ThirdPartyVehicle = 'third_party_vehicle',
}

export enum OwnedBy {
    Lease = 'lease',
    Finance = 'finance',
    Own = 'own',
    Rental = 'rental',
    Other = 'other',
}
export interface InvolvedVehicle {
    readonly type: InvolvedPartyType.Vehicle;

    readonly publicId: string;

    readonly role: InvolvedVehicleRole;

    readonly make: string;

    readonly model?: string;

    readonly year?: number;

    readonly vin?: string;

    readonly licensePlate?: string;

    readonly carPublicId?: string;

    readonly insuranceCarrier?: string;

    readonly insurancePolicyId?: string;

    readonly additionalInfo?: string;

    readonly ownedBy?: OwnedBy;

    readonly damageDescription?: string;

    readonly mileage?: number;

    readonly notDrivable?: boolean;

    readonly airbagsDeployed?: boolean;

    readonly drpShopPublicId?: string;

    readonly engineFluidLeaking?: boolean;

    readonly doorsNotOpening?: boolean;

    readonly someLightsNotWorking?: boolean;

    readonly damagedToGetOccupantsOut?: boolean;

    readonly waterReachedDashboard?: boolean;

    readonly engineCompartmentDamaged?: boolean;

    readonly damagedRightFrontCorner?: boolean;

    readonly damagedRightFrontSide?: boolean;

    readonly damagedRightRearCorner?: boolean;

    readonly damagedRightRearSide?: boolean;

    readonly damagedLeftFrontCorner?: boolean;

    readonly damagedLeftFrontSide?: boolean;

    readonly damagedLeftRearCorner?: boolean;

    readonly damagedLeftRearSide?: boolean;

    readonly damagedFrontCenter?: boolean;

    readonly damagedRearCenter?: boolean;

    readonly damagedFrontWindshield?: boolean;

    readonly damagedRearWindshield?: boolean;

    readonly damagedHood?: boolean;

    readonly damagedTrunkLid?: boolean;

    readonly damagedRoof?: boolean;

    // eslint-disable-next-line functional/prefer-readonly-type
    shopType?: ShopType;

    // eslint-disable-next-line functional/prefer-readonly-type
    damaged: boolean;

    readonly repairable?: boolean;

    readonly repairCost?: number;

    readonly involvedWantsRepair?: boolean;

    readonly totalLossValuationCompleted?: boolean;

    readonly involvedKeepsVehicle?: boolean;

    readonly payoutCalculationAcv?: number;

    readonly payoutCalculationValueVariations?: number;

    readonly payoutCalculationSalvageValue?: number;

    readonly payoutCalculationOwnerPayout?: number;

    readonly payoutCalculationCarrierPayout?: number;

    readonly towNeeded?: boolean;

    readonly repairShopPublicId?: string;

    readonly color?: string;

    readonly salvageAssignmentOpened?: boolean | null;

    readonly obtainedTitleForVehicle?: boolean | null;

    readonly expectedSalvageAmount?: number;

    readonly salvageLotNumber?: string;

    readonly finalSalvageAmount?: number;

    readonly ownerInvolvedPersonPublicId?: string;

    readonly childSeatStatus?: ChildSeatStatus;
}

export type InvolvedParty = InvolvedPerson | InvolvedVehicle;

export type InvolvedParties = { readonly vehicles: InvolvedVehicle[]; readonly people: InvolvedPerson[] };

export type GroupedInvolvedParties = {
    readonly vehicles: GroupedInvolvedVehicle[];
    readonly additionalInvolved: InvolvedPerson[];
};

export interface GroupedInvolvedVehicle extends InvolvedVehicle {
    readonly involvedPeople: InvolvedPerson[];
}

export const LemonadeInsuranceCarrier = 'lemonade';

export const InvolvedDriverRoles = [InvolvedPersonRole.FirstPartyDriver, InvolvedPersonRole.ThirdPartyDriver];

export const InvolvedPassengerRoles = [InvolvedPersonRole.FirstPartyPassenger, InvolvedPersonRole.ThirdPartyPassenger];
