import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';
import { IncompletionReason, MedicalExamReview } from 'components/PetPolicy/PreExistingConditions/types';

const petBlenderUrlResolver = getUrlResolver('pet-blender');

export async function getPetMedicalExamReview(petPublicId: string): Promise<MedicalExamReview> {
    const url = petBlenderUrlResolver(`/api/v2/${petPublicId}/medical_exam/review`);
    const medicalExamReview = await axios.get(url).then(response => response.data.data);

    return medicalExamReview;
}

export async function getPetIncompletionReasons(petPublicId: string): Promise<IncompletionReason[]> {
    const url = petBlenderUrlResolver(`/api/v2/${petPublicId}/medical_exam/review/incompletion_reasons`);
    const incompletionReasons = await axios.get(url).then(response => response.data);

    return incompletionReasons.filter((reason: IncompletionReason) => reason.code !== 'none');
}

export async function updateMedicalExamReview({
    petPublicId,
    medicalExamReview,
}: {
    petPublicId: string;
    medicalExamReview: MedicalExamReview;
}): Promise<MedicalExamReview> {
    const url = petBlenderUrlResolver(`/api/v2/${petPublicId}/medical_exam/review`);

    return axios.post(url, medicalExamReview).then(response => response.data.data);
}
