import { EyeIcon, Image, InfoOutlinedIcon } from '@lemonade-hq/bluis';
import { COLORS } from '@lemonade-hq/tokens';
import { rgba } from 'polished';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CarouselInfoPanel, shouldShowInfoPanelCarouselMenuButton } from './CarouselInfoPanel';
import type { AttachmentsPreviewInfoPanel } from 'components/Bluis/AttachmentsPreview/CarouselPreview';
import type { Attachment } from 'models/Attachment';

export type ZoomTypes = 'minus' | 'plus' | 'reset';
export type CarouselMenuProps = {
    readonly selectedIndex: number;
    readonly total?: number;
    readonly zoomable?: boolean;
    readonly onZoom: (zoomType: ZoomTypes) => void;
    readonly fraudArray?: boolean[];
    readonly onSelectedIndexToCompare?: (index: number) => void;
    readonly attachment: Attachment;
    readonly infoPanel?: AttachmentsPreviewInfoPanel;
};

const StyledCarouselMenu = styled.div`
    color: ${COLORS.white};
    border-radius: 6px;
    padding: 0 0 0 6px;
    background: ${rgba(COLORS.black, 0.6)};
    display: flex;
    position: absolute;
    bottom: 30px;
    align-items: center;
    user-select: none;
    position: relative;
    & > * {
        padding: 10px;
    }
`;

const StyledCarouselMenuButton = styled.button<{ readonly active?: boolean }>`
    z-index: 2;
    background: ${({ active }) => (active === true ? rgba(COLORS.black, 0.7) : 'transparent')};
    border: 0 none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    outline: none;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    &:hover {
        background: ${rgba(COLORS.black, 0.7)};
    }
`;

const ExamineSpan = styled.span`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0 10px;
    border-left: 1px solid ${rgba(COLORS.white, 0.1)};
    margin-left: 10px;

    &:hover {
        background-color: ${COLORS.mineShaft};
        border-radius: 0 5px 5px 0;
    }
`;

const CarouselMenu: React.FC<React.PropsWithChildren<CarouselMenuProps>> = ({
    selectedIndex,
    total,
    onZoom,
    zoomable,
    onSelectedIndexToCompare,
    fraudArray,
    attachment,
    infoPanel,
}) => {
    const [showInfoPanel, setShowInfoPanel] = useState(false);
    const showInfoPanelToggle = useMemo(
        () => infoPanel?.active && shouldShowInfoPanelCarouselMenuButton(attachment),
        [infoPanel?.active, attachment]
    );

    useEffect(() => {
        // hide panel when moving to an attachment that doesn't have a panel
        if (showInfoPanelToggle === false) setShowInfoPanel(false);
    }, [showInfoPanelToggle]);

    function toggleInfoPanel() {
        if (!infoPanel?.active) return;

        setShowInfoPanel(!showInfoPanel);
    }

    return (
        <StyledCarouselMenu>
            <span data-testid="counter">
                {selectedIndex + 1} / {total}
            </span>
            {zoomable ? (
                <>
                    <StyledCarouselMenuButton data-testid="minus" onClick={() => onZoom('minus')}>
                        <Image alt="chevron" img={{ lightSrc: '/blender_assets/backoffice/minus.svg' }} />
                    </StyledCarouselMenuButton>
                    <StyledCarouselMenuButton data-testid="reset" onClick={() => onZoom('reset')}>
                        <Image alt="chevron" img={{ lightSrc: '/blender_assets/backoffice/search.svg' }} />
                    </StyledCarouselMenuButton>
                    <StyledCarouselMenuButton data-testid="plus" onClick={() => onZoom('plus')}>
                        <Image alt="chevron" img={{ lightSrc: '/blender_assets/backoffice/plus-icon.svg' }} />
                    </StyledCarouselMenuButton>
                </>
            ) : null}
            {showInfoPanelToggle && (
                <StyledCarouselMenuButton active={showInfoPanel} data-testid="info" onClick={toggleInfoPanel}>
                    <InfoOutlinedIcon color="white" height={16} width={16} />
                </StyledCarouselMenuButton>
            )}
            {Array.isArray(fraudArray) && fraudArray[selectedIndex] && onSelectedIndexToCompare !== undefined && (
                <ExamineSpan onClick={() => {}}>
                    <EyeIcon color={COLORS.white} />
                    <span>Examine Modifications</span>
                </ExamineSpan>
            )}
            {showInfoPanel && <CarouselInfoPanel attachment={attachment} timezone={infoPanel?.timezone} />}
        </StyledCarouselMenu>
    );
};

export default CarouselMenu;
