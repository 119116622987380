import { LinkTab, PageWrapper, TabList, Tabs } from '@lemonade-hq/bluis';
import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { EditionSetDetails } from './EditionSetDetails';
import { EditionSetHeader } from './EditionSetHeader';
import { EditionSetSchema } from './EditionSetSchema';
import { EditionSetTesting } from './EditionSetTesting';
import { HeaderPortal } from 'components/Header';
import { EditionSetStatus, getEditionSetStatus } from 'models/LoCo/Insurance/EditionSets';
import { useSuspenseGetEditionSet } from 'queries/LoCo/Insurance/EditionSetQueries';
import { useSuspenseGetProduct } from 'queries/LoCo/Insurance/ProductQueries';

interface RouteItem {
    readonly path: string;
    readonly label: string;
    readonly component: JSX.Element;
}

export const EditionSet: React.FC = () => {
    const { productCode = '', editionSetCode = '' } = useParams<{
        productCode: string;
        editionSetCode: string;
    }>();
    const { data: product } = useSuspenseGetProduct(productCode);
    const { data: editionSet } = useSuspenseGetEditionSet(editionSetCode);

    const ROUTES: Record<string, RouteItem> = {
        general: {
            path: 'details',
            label: 'General',
            component: <EditionSetDetails editionSet={editionSet} productCode={product.code} />,
        },
        schema: {
            path: 'schema',
            label: 'Schema',
            component: <EditionSetSchema editionSet={editionSet} />,
        },
    };

    if (getEditionSetStatus(editionSet) !== EditionSetStatus.Published) {
        ROUTES.qaTesting = {
            path: 'qa-testing',
            label: 'QA/Testing',
            component: <EditionSetTesting editionSetCode={editionSet.code} />,
        };
    }

    return (
        <>
            <EditionSetHeader editionSet={editionSet} product={product} />
            <PageWrapper>
                <Tabs>
                    <HeaderPortal>
                        <TabList pageTabs>
                            {Object.values(ROUTES).map(({ label, path }) => (
                                <LinkTab key={label} to={path}>
                                    {label}
                                </LinkTab>
                            ))}
                        </TabList>
                    </HeaderPortal>
                    <Routes>
                        <Route element={<Navigate replace to="details" />} path="/" />

                        {Object.values(ROUTES).map(({ path, component }) => (
                            <Route element={component} key={path} path={path} />
                        ))}
                    </Routes>
                </Tabs>
            </PageWrapper>
        </>
    );
};
