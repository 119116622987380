/* eslint-disable import/no-duplicates */
import { addCSSPaintWorklet as addCSSWaveformPaintWorklet } from '@lemonade-hq/houdini-kit/waveform';
import waveformWorkletURL from '@lemonade-hq/houdini-kit/waveform?worker&url';
import { StripeProvider, useAnalytics, ViewModeProvider } from '@lemonade-hq/boutique';
import {
    RootErrorBoundary,
    shouldAuthenticate,
    shouldFallbackToMaster,
    getProductTypeFromUrl,
} from '@lemonade-hq/bluiza';
import { getCookie } from '@lemonade-hq/cdk';
import React, { useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserProfilingIntegration } from '@sentry/browser';
import {
    createBrowserRouter,
    createRoutesFromChildren,
    createRoutesFromElements,
    matchRoutes,
    Outlet,
    Route,
    RouterProvider,
    Routes,
    useLocation,
    useNavigate,
    useNavigationType,
} from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { EnsureRealtime } from 'components/EnsureRealtime';
import { Toaster } from '@lemonade-hq/bluis';
import { withFaroRouterInstrumentation, FaroErrorBoundary } from '@grafana/faro-react';
import { LoCoRoutes } from './components/LoCo/LoCoRoutes';
import { HeaderContainer as NewHeaderContainer, HeaderProvider } from './components/Header';
import { ImpersonationBanner, ImpersonationProvider } from './components/Impersonation';
import { queryClient } from './queryClient';
import { carriers } from './Commons/StripeCarriers';
import configureStore from './store/configureStore';
import HeaderInfoBarProvider from './context/HeaderInfoBar/HeaderInfoBarProvider';
import HeaderWithHeaderInfoBar from './components/Header/HeaderWithHeaderInfoBar';
import PageTitle from './components/Bluis/GeneralPageTitle';
import SentryErrorBoundary from './components/Sentry/SentryErrorBoundary';
import Life from './components/life/Life';
import Pet from './components/pet/Pet';
import Car from './components/car/Car';
import HomeRoutes from './components/Home/HomeRoutes';
import UserRoutes from './components/User/UserRoutes';
import CarrierRoutes from './components/Carrier/CarrierRoutes';
import LendersRoutes from './components/Lenders/LendersRoutes';
import PartnersRoutes from './components/Partner/PartnersRoutes';
import { EnsureFlags } from './components/EnsureFlags';
import { EnsureAnalytics } from './components/EnsureAnalytics';
import EnsureIntercom from './components/EnsureIntercom';
import EnsurePrincipal from './components/EnsurePrincipal';
import { EnsureTheme } from './components/EnsureTheme';
import GeneralRoutes from './GeneralRoutes';
import { fallback } from './Commons/UrlResolver';
import { LayoutRoot } from './LayoutRoot';
import { initializeFaro } from './root/GrafanaFaro';
import '../../../../libs/blender-ui/src/theme/global.css';
import { IconSprite } from '@lemonade-hq/blender-ui';
import { addCSSPaintWorklet as addCSSNoisePaintWorklet } from '@lemonade-hq/houdini-kit/noise';
import noiseWorkletURL from '@lemonade-hq/houdini-kit/noise?worker&url';

const store = configureStore();
const csrfToken = getCookie('_lmnd_blender_csrf_auth');
const resThreshold = 10000;

async function setSlowApiSpan({ method, url, duration, params }) {
    return await Sentry.startSpan(
        {
            op: 'http.client.slow_api',
            name: `${getUrlTags(url).apiGroup}`,
            attributes: {
                tags: {
                    message: 'slow API response',
                    resUrl: url,
                    method,
                    duration,
                    ...getUrlTags(url),
                    ...(params && { params }),
                },
            },
            forceTransaction: true,
        },
        async span => {
            span.setAttribute('http.request.method', method);
            span.setAttribute('server.address', url.hostname);
            span.setAttribute('server.port', url.port || undefined);

            const response = await fetch(url, {
                method,
            });

            span.setAttribute('http.response.status_code', response.status);
            span.setAttribute('http.response_content_length', Number(response.headers.get('content-length')));

            return response;
        }
    );
}

const getUrlTags = url => {
    const splittedUrl = url.includes('v1') ? url.split('v1') : url.split('backoffice');
    const apiGroup = splittedUrl[1]?.replace(/\d+|L[\w-]+/, '[id]');
    const serviceName = url.includes('backoffice')
        ? 'backoffice'
        : splittedUrl[0]?.replace('https://', '').split('.')[0];

    return { serviceName, apiGroup };
};

const resThresholdExceeded = ({ startTime, url, method, params }) => {
    // alert if request took too long (10s)
    const endTime = new Date();
    const duration = endTime - startTime;

    if (duration > resThreshold) {
        setSlowApiSpan({ method, url, duration, params });
        Sentry.captureException(new Error('slow API response'), {
            tags: {
                message: 'slow API response',
                duration,
                resUrl: url,
                product: getProductTypeFromUrl(window.location.pathname),
                method,
                ...getUrlTags(url),
                ...(params && { params }),
            },
        });
    }
};

axios.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0]?.getAttribute('content');
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common.timezone = moment.tz.guess();
axios.defaults.withCredentials = true;
axios.defaults.timeout = import.meta.env.VITE_ENV_NAME === 'development' ? 600000 : 30000;

if (csrfToken != null) {
    axios.defaults.headers.common['x-lmnd-blender-csrf-auth'] = csrfToken;
}

axios.interceptors.request.use(config => {
    config.metadata = { startTime: new Date() };
    config.headers['X-LEMONADE-PAGE'] = window.location.pathname;
    return config;
});

axios.interceptors.response.use(
    res => {
        resThresholdExceeded({
            startTime: res.config.metadata.startTime,
            url: res.config.url,
            method: res.config.method,
            params: res.config.data,
        });

        return res;
    },
    err => {
        const currentUrl = encodeURI(window.location.pathname);
        const signInUrl = `/operators/sign_in?skip_page=true&return_to_path=${currentUrl}`;

        if (shouldAuthenticate(err)) {
            window.location.href = signInUrl;
        }

        if (shouldFallbackToMaster(err)) {
            return fallback(err);
        }

        return Promise.reject(err);
    }
);

I18n.locale = 'en-US';

initializeFaro({
    stage: import.meta.env.VITE_ENV_NAME ?? 'development',
    ignoreStages: ['development', 'sandbox'],
    projectKey: import.meta.env.VITE_GRAFANA_FARO_PROJECT_KEY ?? '',
    projectName: import.meta.env.VITE_GRAFANA_FARO_PROJECT_NAME ?? '',
    projectVersion: import.meta.env.VITE_GRAFANA_FARO_PROJECT_VERSION ?? '1.0.0',
});

const sentryInit = () => {
    if (import.meta.env.VITE_ENV_NAME !== 'development') {
        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_CLIENT_DSN,
            environment: import.meta.env.VITE_ENV_NAME,
            integrations: [
                new BrowserProfilingIntegration(),
                new Sentry.BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                        React.useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes
                    ),
                }),
            ],
            tracesSampler: ({ attributes }) => {
                if (attributes['sentry.op'] === 'http.client.slow_api') {
                    return 1;
                }
                return 0.1;
            },
            profilesSampleRate: 0.5,
            ignoreErrors: [/ResizeObserver loop limit exceeded/i],
        });
    }
};
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const redirectToHomeRoutes = (pathname, navigate) => {
    const pathArray = pathname.split('/');
    const backofficeIdx = pathArray.indexOf('backoffice');

    pathArray.splice(backofficeIdx + 1, 0, 'home');

    return navigate(pathArray.join('/'), { replace: true });
};

const BackofficeRoute = () => {
    sentryInit();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { activateTracking } = useAnalytics();

    activateTracking();

    sessionStorage.setItem('performanceNow', performance.now());

    useEffect(() => {
        if (pathname.includes('/backoffice/claims/')) {
            redirectToHomeRoutes(pathname, navigate);
        }
    }, [navigate, pathname]);

    return (
        <RootErrorBoundary>
            <SentryRoutes>
                {/* USERS */}
                <Route element={<UserRoutes />} path="users/*" />

                {/* HOME */}
                <Route element={<HomeRoutes />} path="home/*" />

                {/* PET */}
                <Route element={<Pet />} path="pet/*" />

                {/* CAR */}
                <Route element={<Car />} path="car/*" />

                {/* LIFE */}
                <Route element={<Life />} path="life/*" />

                {/* CARRIERS */}
                <Route element={<CarrierRoutes />} path="carriers/*" />

                {/* LENDERS */}
                <Route element={<LendersRoutes />} path="lenders/*" />

                {/* PARTNERS */}
                <Route element={<PartnersRoutes />} path="partners/*" />

                {/* LOCO */}
                <Route element={<LoCoRoutes />} path="loco/*" />

                {/* GENERAL */}
                <Route element={<GeneralRoutes />} path="/*" />
            </SentryRoutes>
        </RootErrorBoundary>
    );
};

const composeComponents = (...components) => {
    return ({ children }) => components.reduceRight((acc, Comp) => <Comp>{acc}</Comp>, children);
};

const Layout = composeComponents(
    FaroErrorBoundary,
    SentryErrorBoundary,
    ({ children }) => <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>,
    ({ children }) => (
        <>
            <EnsurePrincipal>{children}</EnsurePrincipal>
            <ReactQueryDevtools initialIsOpen={false} />
        </>
    ),
    ImpersonationProvider,
    EnsureRealtime,
    EnsureFlags,
    EnsureAnalytics,
    ({ children }) => (
        <>
            <EnsureIntercom />
            <PageTitle>{children}</PageTitle>
        </>
    ),
    ViewModeProvider,
    EnsureTheme,
    HeaderProvider,
    LayoutRoot,
    ({ children }) => (
        <StripeProvider carriers={carriers} defaultApiKey={import.meta.env.VITE_STRIPE_KEY}>
            {children}
        </StripeProvider>
    ),
    HeaderInfoBarProvider,
    () => (
        <Provider store={store}>
            <IconSprite />
            <NewHeaderContainer>
                <HeaderWithHeaderInfoBar />
            </NewHeaderContainer>
            <ImpersonationBanner />
            <Outlet />
            <Toaster />
        </Provider>
    )
);

const router = createBrowserRouter(
    createRoutesFromElements([
        <Route element={<Layout />}>
            <Route element={<BackofficeRoute />} path="/backoffice/*" />
        </Route>,
    ])
);

const browserRouter = withFaroRouterInstrumentation(router);

const App = () => <RouterProvider router={browserRouter} />;

export default Sentry.withProfiler(App);

const reactAppContainer = document.getElementById('root');

if (reactAppContainer) {
    const root = createRoot(reactAppContainer);

    root.render(<App />);
}

addCSSWaveformPaintWorklet(waveformWorkletURL);