/* eslint-disable @typescript-eslint/naming-convention */
import { AudioPlayerDurations } from './AudioPlayer.Durations';
import { AudioPlayerEventsProvider, useAudioPlayerEvents } from './AudioPlayer.EventsProvider';
import { AudioPlayerMuteControls } from './AudioPlayer.MuteControls';
import { AudioPlayerPlaybackRateControls } from './AudioPlayer.PlaybackRateControls';
import { AudioPlayerProvider, useAudioPlayer } from './AudioPlayer.Provider';
import { AudioPlayerSegmentedControls } from './AudioPlayer.SegmentedControls';
import { AudioPlayerSegmentedList } from './AudioPlayer.SegmentedList';
import { AudioPlayerSegmentedTimeline } from './AudioPlayer.SegmentedTimeline';
import { AudioPlayerSegmentsProvider, useAudioPlayerSegments } from './AudioPlayer.SegmentsProvider';
import { AudioPlayerStructuredTranscriptItem } from './AudioPlayer.StructuredTranscriptItem';
import { AudioPlayerWaveformProvider, useAudioPlayerWaveform } from './AudioPlayer.WaveformProvider';
import { AudioPlayerZoomControls } from './AudioPlayer.ZoomControls';
import { AudioPlayerZoomProvider, useAudioPlayerZoom } from './AudioPlayer.ZoomProvider';

export const AudioPlayer = {
  useContext: useAudioPlayer,
  useSegments: useAudioPlayerSegments,
  useEvents: useAudioPlayerEvents,
  useWaveform: useAudioPlayerWaveform,
  useZoom: useAudioPlayerZoom,
  Provider: AudioPlayerProvider,
  EventsProvider: AudioPlayerEventsProvider,
  SegmentsProvider: AudioPlayerSegmentsProvider,
  SegmentedControls: AudioPlayerSegmentedControls,
  SegmentedList: AudioPlayerSegmentedList,
  SegmentedTimeline: AudioPlayerSegmentedTimeline,
  StructuredTranscriptItem: AudioPlayerStructuredTranscriptItem,
  MuteControls: AudioPlayerMuteControls,
  PlaybackRateControls: AudioPlayerPlaybackRateControls,
  Durations: AudioPlayerDurations,
  WaveformProvider: AudioPlayerWaveformProvider,
  ZoomProvider: AudioPlayerZoomProvider,
  ZoomControls: AudioPlayerZoomControls,
};
