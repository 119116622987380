import React, { useContext } from 'react';
import type { Release } from 'models/LoCo/Insurance/Release';
import { ReleaseStatus } from 'models/LoCo/Insurance/Release';

export enum ManageReleaseStep {
    ReleaseType = 'releaseType',
    Editions = 'editions',
    RolloutStrategy = 'rolloutStrategy',
    EffectiveDates = 'effectiveDates',
}

export function getReleaseDisplayStatus(status: ReleaseStatus): string {
    switch (status) {
        case ReleaseStatus.Draft:
            return 'In Preparation';
        case ReleaseStatus.Cancelled:
            return 'Cancelled';
        case ReleaseStatus.Published:
            return 'Published';
        default:
            return '';
    }
}

export enum ReleaseDialogType {
    Publish = 'publish',
    Test = 'test',
    UpdateDates = 'updateEffectiveDates',
    UpdateRatingReferenceEdition = 'updateRatingReferenceEdition',
    Replace = 'replaceEditions',
    Cancel = 'cancel',
}

export interface ReleaseContextData {
    readonly onActionRequested: (action: ReleaseDialogType) => void;
    readonly release: Release;
}

export const releaseContext = React.createContext<ReleaseContextData | null>(null);

export const useReleaseContext = (): ReleaseContextData => {
    const context = useContext(releaseContext);
    if (!context) {
        throw new Error('useReleaseContext must be used within ReleaseContext');
    }

    return context;
};
