import {
    AudioVisualizer,
    Button,
    colors,
    Flex,
    flexGrow,
    Icon,
    Layout,
    MicrophoneAudioSource,
    Range,
    spacing,
    Text,
} from '@lemonade-hq/blender-ui';
import { useCallback, useRef } from 'react';
import type { FC } from 'react';
import * as styles from './VoiceSimulation.css';

export const VoiceSimulation: FC = () => {
    const websocketSource = useRef(new MicrophoneAudioSource()); // TODO: change this to a websocket source once implemented
    const microphoneSource = useRef(new MicrophoneAudioSource());

    // TODO: implement volume controls
    const status = 'Waiting'; // TODO: implement

    const handleStart = useCallback(() => {
        void websocketSource.current.start();
        void microphoneSource.current.start();
    }, []);

    return (
        <Flex alignItems="center" flexDirection="column" height="100%">
            <Flex
                alignItems="center"
                borderRadius="$br400"
                className={styles.phoneContainer}
                flexDirection="column"
                gap={spacing.s16}
                height="100%"
                justifyContent="space-between"
                padding={spacing.s16}
                width="100%"
            >
                <Flex alignItems="center" flexDirection="column" gap={spacing.s16} width="100%">
                    <Layout backgroundColor="$neutral0" borderRadius="$br400" height="0.75rem" width="20%" />
                    <Text color="inverted" type="h5">
                        {status}
                    </Text>
                </Flex>
                <Flex height="20rem" position="relative" width="100%">
                    <AudioVisualizer
                        className={styles.audioVisualizer}
                        color={colors.ai2}
                        gapWidth="4px"
                        height="100%"
                        minPresentedVolume={0.1}
                        position="absolute"
                        source={websocketSource.current}
                        strokeWidth="4px"
                        width="100%"
                    />
                    <AudioVisualizer
                        className={styles.audioVisualizer}
                        color={colors.brand1}
                        gapWidth="4px"
                        height="100%"
                        initialOffset={Math.PI / 2}
                        minPresentedVolume={0.1}
                        position="absolute"
                        source={microphoneSource.current}
                        strokeWidth="4px"
                        width="100%"
                    />
                </Flex>
                <Flex flexDirection="column" gap={spacing.s16} justifySelf="flex-end" width="100%">
                    <Flex alignItems="center" gap={spacing.s16} width="100%">
                        <Icon color="neutral1" name="media-mute" size="xl" />
                        <Range className={flexGrow} max={1} min={0} step={0.1} value={1} />
                    </Flex>
                    <Button
                        className={styles.callButton}
                        label="Start Call"
                        onClick={handleStart}
                        size="lg"
                        startIcon="phone-solid"
                        variant="primary"
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};
