import { Button, ComboBox, Flex, spacing, Spinner, Text } from '@lemonade-hq/blender-ui';
import { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent } from '@lemonade-hq/boutique';
import { useMemo, useState } from 'react';
import type { FC } from 'react';
import { AttachmentDialogBase } from 'components/Attachments/AttachmentHub/Dialogs/AttachmentDialogBase';
import type { AttachmentDTO } from 'components/Attachments/types';
import { getAttachmentAnalyticsParam } from 'components/Attachments/utils';
import { ClaimItemType } from 'models/HomeClaim';
import { useGetClaimItems, useUpdateAttachmentV2 } from 'queries/HomeClaimQueries';

// since the old update attachment endpoint is used, we need to send to it the old monolith attachment id
// The old update endpoint supports only single attachment and not bulk update
export const AssignAttachmentDialog: FC<{
    readonly onClose: () => void;
    readonly attachments: AttachmentDTO[];
    readonly source: 'button' | 'single_row_menu';
    readonly entityPublicId: string;
}> = ({ onClose, attachments, source, entityPublicId: claimPublicId }) => {
    const { data, isPending, isError: isClaimItemsError } = useGetClaimItems(claimPublicId);
    const {
        mutateAsync: updateAttachment,
        isPending: isSubmitPending,
        isError: isSubmitError,
    } = useUpdateAttachmentV2(claimPublicId);
    const claimItemsIds = useMemo(() => {
        return attachments[0]?.customData?.find(item => item.columnId === 'claim_item')?.data.map(it => it.value);
    }, [attachments]);
    const [selectedClaimItemIds, setSelectedClaimItemIds] = useState<string[]>(claimItemsIds ?? []);

    // TODO: the update endpoint supports only single attachment and not bulk update
    const attachment = attachments[0];

    const lossItems = useMemo(
        () => data?.table.filter(feat => feat.type === ClaimItemType.Loss).flatMap(n => n.items),
        [data?.table]
    );

    const claimItemsOptions = useMemo(
        () =>
            lossItems?.map(item => ({
                label: item.title,
                value: item.publicId,
            })),
        [lossItems]
    );

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment: attachments,
                entityType: EntityTypes.HomeClaim,
                entityId: claimPublicId,
                source,
            }),
            action: 'submit',
            action_type: 'assign',
            platform: 'hub',
            is_bulk: 'false',
        });

        const attachmentData = {
            claimItemIds: selectedClaimItemIds,
        };

        await updateAttachment({ attachmentId: attachment.publicId ?? '', details: attachmentData });
        onClose();
    };

    const onChange = (items: { value: string; label: string }[] | null): void => {
        if (items == null) return;

        setSelectedClaimItemIds(items.map(item => item.value));
    };

    const handleOnClose = (): void => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment: attachments,
                entityType: EntityTypes.HomeClaim,
                entityId: claimPublicId,
                source,
            }),
            platform: 'hub',
            action: 'cancel',
            action_type: 'assign',
            is_bulk: 'false',
        });
        onClose();
    };

    return (
        <AttachmentDialogBase
            attachments={[attachment]}
            error={
                isClaimItemsError
                    ? 'Error loading claim items'
                    : isSubmitError
                      ? 'Error assigning attachment'
                      : undefined
            }
            isLoading={isPending || isSubmitPending}
            isSubmitDisabled={false}
            onClose={handleOnClose}
            onSubmit={onSubmit}
            submitLabel="Assign"
            title="Assign attachments"
        >
            <Flex flexDirection="column" gap={spacing.s06}>
                <Text color="primary" type="label-sm">
                    Assign to
                    <Text as="span" color="error">
                        &nbsp;*
                    </Text>
                </Text>
                {claimItemsOptions != null && claimItemsOptions.length > 0 ? (
                    <ComboBox
                        defaultValue={selectedClaimItemIds}
                        items={claimItemsOptions}
                        mode="multiple"
                        onSelectionChange={onChange}
                    />
                ) : isPending ? (
                    <Flex alignItems="center" height="200px" justifyContent="center">
                        <Spinner size="sm" />
                    </Flex>
                ) : (
                    <Text>No loss items to assign</Text>
                )}
            </Flex>
        </AttachmentDialogBase>
    );
};

// A form to show on Attachment Preview component
export const PreviewAssignAttachment: FC<{
    readonly attachments: AttachmentDTO[];
    readonly entityPublicId: string;
}> = ({ attachments, entityPublicId: claimPublicId }) => {
    const { data, isPending, isError: isClaimItemsError } = useGetClaimItems(claimPublicId);
    const {
        mutateAsync: updateAttachment,
        isPending: isSubmitPending,
        isError: isSubmitError,
    } = useUpdateAttachmentV2(claimPublicId);
    const claimIds = useMemo(() => {
        return attachments[0]?.customData?.find(item => item.columnId === 'claim_items')?.data.map(it => it.value);
    }, [attachments]);
    const [selectedClaimItemIds, setSelectedClaimItemIds] = useState<string[]>(claimIds ?? []);

    // TODO: the update endpoint supports only single attachment and not bulk update
    const attachment = attachments[0];

    const lossFeature = useMemo(() => data?.table.find(feature => feature.type === ClaimItemType.Loss), [data?.table]);

    const claimItemsOptions = useMemo(
        () =>
            lossFeature?.items.map(item => ({
                label: item.title,
                value: item.publicId,
            })),
        [lossFeature?.items]
    );

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment: attachments,
                entityType: EntityTypes.HomeClaim,
                entityId: claimPublicId,
            }),
            action: 'submit',
            action_type: 'assign',
            source: 'hover',
            is_bulk: 'false',
            platform: 'hub',
        });

        const attachmentData = {
            claimItemIds: selectedClaimItemIds,
        };
        await updateAttachment({ attachmentId: attachment.publicId, details: attachmentData });
    };

    const onChange = (item: { value: string; label: string } | null): void => {
        if (item == null) return;

        setSelectedClaimItemIds(prev => [...prev, item.value]);
    };

    const error = isClaimItemsError
        ? 'Error loading claim items'
        : isSubmitError
          ? 'Error assigning attachment'
          : undefined;

    return (
        <Flex flexDirection="column" gap={spacing.s06}>
            <Text color="primary" type="label-sm">
                Assign to
                <Text as="span" color="error">
                    &nbsp;*
                </Text>
            </Text>
            {claimItemsOptions != null && claimItemsOptions.length > 0 ? (
                <ComboBox
                    disabled={isPending || isSubmitPending}
                    items={claimItemsOptions}
                    onSelectionChange={onChange}
                />
            ) : isPending ? (
                <Flex>
                    <Spinner size="sm" />
                </Flex>
            ) : (
                <Text>No loss items to assign</Text>
            )}
            {error && (
                <Text color="error" type="label-sm">
                    {error}
                </Text>
            )}
            <Button
                disabled={selectedClaimItemIds.length === 0}
                label="Assign"
                loading={isSubmitPending}
                onClick={onSubmit}
                size="sm"
                variant="primary"
            />
        </Flex>
    );
};
