import type { Edition, VersionType } from './BaseEdition';

export enum EditionSetStatus {
    Published = 'published',
    PreRelease = 'pre_release',
    TestOnly = 'test_only',
    Archived = 'archived',
}

export interface EditionSet {
    readonly code: string;
    readonly productCode: string;
    readonly ratingEdition: Edition;
    readonly coveragesEdition: Edition;
    readonly underwritingFiltersEdition: Edition;
    readonly digitalAgentEdition: Edition;
    readonly newBusinessEffectiveAt: string;
    readonly renewalEffectiveAt: string;
    readonly compatible: boolean;
    readonly publishedAt?: string;
    readonly publishedBy?: string;
    readonly publishedByName?: string;
    readonly version: string;
    readonly versionMajor: number;
    readonly versionMinor: number;
    readonly versionType: VersionType;
    readonly platformSchemaRevision: number;
    readonly productSchemaRevision: number;
    readonly baseEditionSetCode: string | null;
    readonly baseEditionSetVersion: string | null;
    readonly archivedAt?: string;
    readonly archivedByName?: string;
    readonly addedAt: string;
    readonly addedByName: string;
    readonly releasePublicId?: string;
    readonly releaseFriendlyName?: string;
    readonly friendlyName: string;
    readonly isTest: boolean;
}

export enum AppStage {
    Staging = 'staging',
    Development = 'development',
}

export interface EditionSetEnvOverride {
    readonly publicId: string;
    readonly editionSetCode: string;
    readonly envStage: AppStage;
    readonly envName: string;
    readonly expirationDate: string;
    readonly activatedAt: string;
    readonly activatedBy: string;
    readonly editionSet: {
        readonly friendlyName: string;
        readonly version: string;
        readonly publishedAt?: string;
    };

    readonly productCode: string;
    readonly productName: string;
}

export function getEditionSetStatus(editionSet: EditionSet): EditionSetStatus {
    if (editionSet.publishedAt != null) {
        return EditionSetStatus.Published;
    }

    if (editionSet.archivedAt != null) {
        return EditionSetStatus.Archived;
    }

    return editionSet.isTest ? EditionSetStatus.TestOnly : EditionSetStatus.PreRelease;
}
