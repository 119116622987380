import { ComboBox, Flex, spacing, Text, TextArea } from '@lemonade-hq/blender-ui';
import { trackEvent } from '@lemonade-hq/boutique';
import type { FC } from 'react';
import { AttachmentDialogBase } from './AttachmentDialogBase';
import { useUpdateDetails } from 'components/Attachments/ActionHooks/useUpdateDetails';
import { useAttachmentsData } from 'components/Attachments/context';
import type { AttachmentDTO } from 'components/Attachments/types';
import { getAttachmentAnalyticsParam } from 'components/Attachments/utils';

export const UpdateAttachmentDialog: FC<{
    readonly attachments: AttachmentDTO[];
    readonly source: 'button' | 'single_row_menu';
    readonly onClose: () => void;
}> = ({ attachments, source, onClose }) => {
    const { entityType, entityPublicId } = useAttachmentsData();
    const {
        types,
        type,
        subtypes,
        subtype,
        description,
        isTypesError,
        isTypesLoading,
        isSubmitPending,
        isSubmitError,
        handleSelectionChange,
        handleSubtypeSelectionChange,
        setDescription,
        handleSubmit,
        isInTypeList,
    } = useUpdateDetails({
        entityPublicId,
        entityType,
        attachments,
        onClose,
        savedType: attachments.length === 1 ? attachments[0].type : undefined,
        savedSubtype: attachments.length === 1 ? attachments[0].subtype : undefined,
        savedDescription: attachments.length === 1 ? attachments[0].description : '',
    });

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment: attachments,
                entityType,
                entityId: entityPublicId,
                source,
            }),
            platform: 'hub',
            doc_type: type?.value ?? '',
            doc_subtype: subtype?.value ?? '',
            doc_description: description,
            action_type: 'relabel',
            action: 'submit',
        });
        const attachmentsData = attachments.map(attachment => ({
            attachmentPublicId: attachment.publicId,
            ...(type != null && { type: type.value }),
            ...(subtype != null && { subtype: subtype.value }),
            ...(description !== '' && { description }),
        }));
        await handleSubmit({ attachmentsData });
    };

    const handleOnClose = (): void => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment: attachments,
                entityType,
                entityId: entityPublicId,
                source,
            }),
            platform: 'hub',
            action: 'cancel',
            action_type: 'relabel',
        });
        onClose();
    };

    const isDisabled =
        // to handle legacy types that are not in the list
        ((type == null || isInTypeList === false) && description.trim() === '') ||
        (subtypes != null && subtype == null) ||
        isSubmitPending;

    return (
        <AttachmentDialogBase
            attachments={attachments}
            error={
                isSubmitError
                    ? 'Error updating attachment details'
                    : isTypesError
                      ? 'Error loading attachment types'
                      : undefined
            }
            isLoading={isSubmitPending}
            isSubmitDisabled={isDisabled}
            onClose={handleOnClose}
            onSubmit={onSubmit}
            submitLabel="Done"
            title="Relabel attachments"
        >
            <Flex flexDirection="column" gap={spacing.s12}>
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        Type
                    </Text>
                    <ComboBox
                        defaultValue={type?.label ?? undefined}
                        disabled={isTypesLoading}
                        items={types}
                        onSelectionChange={handleSelectionChange}
                        placeholder="Select attachment type"
                    />
                </Flex>
                {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
                {(subtypes || subtype) && (
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <Text color="primary" type="label-sm">
                            Subtype
                        </Text>
                        <ComboBox
                            defaultValue={subtype?.label ?? undefined}
                            disabled={isTypesLoading}
                            items={subtypes ?? []}
                            onSelectionChange={handleSubtypeSelectionChange}
                            placeholder="Select attachment subtype"
                        />
                    </Flex>
                )}
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        Description
                    </Text>
                    <TextArea
                        autoExpanding
                        maxLength={500}
                        onChange={e => setDescription(e.target.value)}
                        rows={5}
                        value={description}
                    />
                </Flex>
            </Flex>
        </AttachmentDialogBase>
    );
};
