import { Button, Card, Flex, generateTypedFormComponents, spacing, Text } from '@lemonade-hq/blender-ui';
import type { clientToolSchema, CustomerCardSectionName, ToolSchemaValues } from '@lemonade-hq/persisted-tools';
import { useState } from 'react';
import { prettifyField } from '../../shared/tool.helpers';
import { CcExampleDialog } from '../dialogs/CcExampleDialog';
import * as styles from '../PlaygroundToolEditorMain.css';

interface CustomerCardSectionAccordionProps {
    readonly customerCardSections?: Record<CustomerCardSectionName, boolean>;
    readonly toolSchemaValues?: ToolSchemaValues;
    readonly isReadonly?: boolean;
}
const { Accordion, Checkbox } = generateTypedFormComponents<typeof clientToolSchema>();

export const CustomerCardSectionAccordion: React.FC<CustomerCardSectionAccordionProps> = ({
    customerCardSections,
    isReadonly = false,
    toolSchemaValues,
}) => {
    const [isCcExampleDialogOpen, setIsCcExampleDialogOpen] = useState(false);
    const checkedSectionsCount = Object.values(customerCardSections ?? {}).filter(Boolean).length;

    return (
        <>
            <Accordion
                badges={[{ label: checkedSectionsCount }]}
                hasAsteriskMark
                isOpenByDefault
                schemaKey="customerCardSections"
                subTitle="Select the customer info this tool needs to handle tickets properly"
                title={prettifyField('customerCardSections')}
                toolbar={
                    <Button
                        label="example"
                        onClick={() => setIsCcExampleDialogOpen(true)}
                        startIcon="export"
                        variant="inline"
                    />
                }
            >
                <Card
                    borderRadius="sm"
                    display="flex"
                    flexDirection="column"
                    gap={spacing.s16}
                    padding={spacing.s16}
                    variant="primary"
                >
                    {Object.entries(toolSchemaValues?.customerCardSections ?? {}).map(
                        ([sectionName, { label, description, required }]) => (
                            <Flex alignItems="flex-start" gap={spacing.s06} key={sectionName}>
                                <Checkbox
                                    disabled={isReadonly || required}
                                    schemaKey={`customerCardSections.${sectionName as CustomerCardSectionName}`}
                                />
                                <Flex flexDirection="column" gap={spacing.s04}>
                                    <Text className={styles.sectionLabel}> {label}</Text>
                                    <Text color="secondary">{description}</Text>
                                </Flex>
                            </Flex>
                        )
                    )}
                </Card>
            </Accordion>
            {isCcExampleDialogOpen && (
                <CcExampleDialog
                    customerCardSections={customerCardSections}
                    onClose={() => setIsCcExampleDialogOpen(false)}
                />
            )}
        </>
    );
};
