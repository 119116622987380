export type Option = {
    readonly value: string;
    readonly title: string;
    readonly isSelected?: boolean;
    readonly icon?: string;
    readonly key?: number | string;
    readonly className?: string;
    readonly component?: React.ReactElement;
    readonly disabled?: boolean;
    readonly unfilterable?: boolean;
};

export type SelectAction = {
    readonly name: string;
    readonly onClick: () => void;
};

type CommonProps = {
    readonly id?: string;
    readonly options: Option[];
    readonly defaultValue?: string;
    readonly title?: string;
    readonly withFilter?: boolean;
    readonly isDisabled?: boolean;
    readonly onOpen?: () => void;
    readonly onClose?: () => void;
    readonly overrideSelect?: boolean;
    readonly withIcons?: boolean;
    readonly className?: string;
    readonly wrapperClassName?: string;
    readonly selectClassName?: string;
    readonly placeholder?: string;
    readonly above?: boolean;
    readonly maxOptionsListHeight?: number;
    readonly clippingElement?: Element;
    readonly onFilterChange?: (value: string) => void;
    readonly keepFilterValue?: boolean;
    readonly actions?: SelectAction[];
};

export type SingleSelect = CommonProps & {
    readonly multiple?: false;
    readonly selected: string;
    readonly onSelect: (value: string, id?: string) => void;
};

export type MultiSelect = CommonProps & {
    readonly multiple: true;
    readonly selectedList: string[];
    readonly onSelect: (value: string[], id?: string) => void;
};

export type BSelectProps = MultiSelect | SingleSelect;

export function isMultiSelect(select: Partial<BSelectProps>): select is MultiSelect {
    return (select as MultiSelect).multiple;
}

export function isSingleSelect(select: Partial<BSelectProps>): select is SingleSelect {
    return !(select as SingleSelect).multiple;
}

export const getSelectTitle = (options: Option[] = [], selectedList: string[] = []): string => {
    let selectedTitle = '';

    selectedList.forEach(selected => {
        const addedText = options.find(option => option.value === selected)?.title ?? '';

        if (selectedTitle) {
            selectedTitle += `, ${addedText}`;
        } else {
            selectedTitle = addedText;
        }
    });

    return selectedTitle;
};

export const splitTextArray = (text: string, splitStr: string): string[] => {
    const titleArray = text.split(new RegExp(splitStr, 'i'));
    const returnArray: string[] = [];

    titleArray.forEach((str, i) => {
        returnArray.push(str);
        if (i + 1 < titleArray.length) {
            returnArray.push(
                text.substring(
                    text.toLowerCase().indexOf(splitStr.toLowerCase()),
                    text.toLowerCase().indexOf(splitStr.toLowerCase()) + splitStr.length
                )
            );
        }
    });

    return returnArray;
};

export const getOptionsWithSelected = (props: Partial<BSelectProps>): Option[] => {
    const { options = [] } = props;

    if (isMultiSelect(props)) {
        const { selectedList = [] } = props;

        return options.map(option => ({
            ...option,
            isSelected: selectedList.includes(option.value),
        }));
    }

    if (isSingleSelect(props)) {
        const { selected = '' } = props;

        return options.map(option => ({
            ...option,
            isSelected: selected === option.value,
        }));
    }

    return [];
};

export const getOptionsWithFilter = ({
    options,
    filterText,
}: {
    readonly options: Option[];
    readonly filterText: string;
}): Option[] => {
    return options.filter(
        ({ title, unfilterable }) => title.toLowerCase().includes(filterText.toLowerCase()) || unfilterable
    );
};

export const getSortedOptions = ({ options }: { readonly options: Option[] }): Option[] => {
    return options.sort((optA, optB) => (optA.isSelected && !optB.isSelected ? -1 : 1));
};
