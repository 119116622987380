/* eslint-disable @typescript-eslint/naming-convention */
import { EntityTypes } from '@lemonade-hq/bluiza';
import type {
    AttachmentActionType,
    AttachmentDTO,
    AttachmentTypeWithSubtypes,
    DetectionResult,
    Indicator,
    IndicatorType,
} from './types';
import type { Attachment, AttachmentType } from 'models/Attachment';

export const NEW_HUB_FLAG = 'media_attachments_hub';

export const isNewAttachmentsEntityType = (entityType: EntityTypes): boolean => {
    return (
        entityType === EntityTypes.PetClaim ||
        entityType === EntityTypes.HomeClaim ||
        (entityType === EntityTypes.CarClaim && import.meta.env.VITE_ENV_NAME === 'staging')
    );
};

export const MISSING_VALUE = '-';

export function isNewAttachmentData(attachment: Attachment | AttachmentDTO): attachment is AttachmentDTO {
    return (
        'attachmentVersion' in attachment &&
        ['media_attachment', 'potentially_assigned_attachment'].includes(attachment.attachmentVersion ?? '')
    );
}

export function isNewAttachmentType(
    type: AttachmentType | AttachmentTypeWithSubtypes | string
): type is AttachmentTypeWithSubtypes {
    return typeof type === 'object';
}

export const getRaiDetectionResult = (attachment: AttachmentDTO): DetectionResult | undefined =>
    attachment.detections?.find(detection => detection.detectionModel === 'resistant_ai')?.detectionResult;

export const getLmndDetectionResult = (attachment: AttachmentDTO): DetectionResult | undefined =>
    attachment.detections?.find(detection => detection.detectionModel === 'lmnd_ds')?.detectionResult;

export const getFraudDetectionScore = (attachment: AttachmentDTO): string | null =>
    getRaiDetectionResult(attachment)?.score ?? getLmndDetectionResult(attachment)?.score ?? null;

export const getIndicators = (attachment: AttachmentDTO): Partial<Record<IndicatorType, Indicator[]>> | undefined =>
    attachment.detections?.find(detection => detection.detectionResult.indicators != null)?.detectionResult
        .indicators ?? undefined;

export const getTextChanges = (attachment: AttachmentDTO): { readonly [index: string]: string[] } | undefined =>
    attachment.detections?.find(detection => detection.detectionResult.textChanges != null)?.detectionResult
        .textChanges ?? undefined;

export function getProductAndEntity(entityType: EntityTypes): {
    readonly product: string;
    readonly entity: string;
} {
    const [product, entity] = entityType.split('_');

    return { product, entity };
}

export function attachmentHasDetections(attachment: AttachmentDTO): boolean {
    return attachment.detections != null && attachment.detections.length !== 0;
}

export function isFraud(attachment: AttachmentDTO): boolean {
    return Boolean(
        attachment.detections?.some(
            detection =>
                detection.detectionResult.score === 'HIGH_RISK' || detection.detectionResult.score === 'WARNING'
        )
    );
}

export const getInlineUrl = (url?: string | null): string => {
    if (
        !url?.endsWith('?inline=true') &&
        (url?.startsWith('https://files-') ?? url?.startsWith('https://icebox.lemonade'))
    ) {
        if (url.endsWith('?inline=false')) {
            return url.replace('inline=false', 'inline=true');
        }

        return `${url}?inline=true`;
    }

    return url ?? '';
};

export const getAttachmentAnalyticsParam = ({
    attachment,
    entityType,
    entityId,
    source,
}: {
    readonly attachment: AttachmentDTO | AttachmentDTO[];
    readonly entityType: EntityTypes;
    readonly entityId: string;
    readonly source?: 'button' | 'single_row_menu';
}): Record<string, string> => {
    if (Array.isArray(attachment) && source === 'button') {
        return {
            attachment_id: attachment.map(it => it.publicId).join(','),
            bulk_attachments_num: attachment.length.toString(),
            is_bulk: 'true',
            source,
        };
    } else {
        attachment = Array.isArray(attachment) ? attachment[0] : attachment;

        return {
            attachment_id: attachment.publicId,
            entity_id: entityId,
            entity_type: entityType,
            modification_score: getLmndDetectionResult(attachment)?.score ?? '',
            content_type: attachment.contentType ?? '',
            filename: attachment.fileName ?? '',
            attachment_type: attachment.type?.value ?? '',
            attachment_description: attachment.description ?? '',
            ...(attachment.suggestedArchive == null && {
                method: attachment.suggestedArchive ? 'suggested' : 'manual',
            }),
            ...(attachment.archivingReason && { archive_reason: attachment.archivingReason ?? '' }),
            sources: attachment.sources?.map(source => source.description).join(',') ?? '',
            status: attachment.status,
            is_bulk: 'false',
            source: source ?? '',
        };
    }
};

export function getActionLabel(action: AttachmentActionType | undefined): string {
    switch (action) {
        case 'archive':
            return 'Archive';
        case 'unarchive':
            return 'Unarchive';
        case 'relabel':
            return 'Relabel';
        case 'check_for_modifications':
            return 'Check for modifications';
        case 'assign_to_item':
            return 'Assign';
        case 'additional_details':
            return 'Additional details';
        default:
            return '';
    }
}

export function hasAction({
    attachment,
    action,
}: {
    readonly attachment?: AttachmentDTO | null;
    readonly action: AttachmentActionType;
}): boolean {
    return attachment?.actions?.includes(action) ?? false;
}

export enum AttachmentFormat {
    Audio = 'audio',
    Image = 'image',
    Doc = 'doc',
    Video = 'video',
    Pdf = 'pdf',
    Xlc = 'xlc',
    Unknown = 'unknown',
}

export function getAttachmentFormat(identifier?: string | null): AttachmentFormat | null {
    if (identifier == null) {
        return AttachmentFormat.Unknown;
    }

    if (/pdf/i.test(identifier)) {
        return AttachmentFormat.Pdf;
    }

    if (/(doc|docx|txt|rtf|xls|xlsx|sheet)/i.test(identifier)) {
        return AttachmentFormat.Doc;
    }

    if (/(png|jpg|jpeg|gif|bmp|svg|webp)/i.test(identifier)) {
        return AttachmentFormat.Image;
    }

    if (/(mp4|avi|mov|wmv|flv|mkv|mpeg|webm|quicktime|video)/i.test(identifier)) {
        return AttachmentFormat.Video;
    }

    if (/(mp3|wav|ogg|m4a|aac)/i.test(identifier)) {
        return AttachmentFormat.Audio;
    }

    if (/(xls|xlsx)/i.test(identifier)) {
        return AttachmentFormat.Xlc;
    }

    return AttachmentFormat.Unknown;
}

export function getThumbnailUrl(attachment: AttachmentDTO, size?: 'large' | 'medium' | 'tiny'): string {
    if (attachment.visualMedia?.thumbnails == null || size == null) {
        return attachment.url;
    }

    const filesServiceUrl = import.meta.env.VITE_FILES_SERVICE_URL;

    const attachmentId = attachment.visualMedia.thumbnails[size];

    return attachmentId == null
        ? getInlineUrl(attachment.url)
        : getInlineUrl(`${filesServiceUrl}/files/${attachmentId}`);
}

export function isInvalidContentType(contentType?: string | null): boolean {
    if (contentType == null || contentType === '') {
        return false;
    }

    return contentType.includes('application/octet-stream');
}
