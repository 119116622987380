import { Card, Flex, Layout, pageWrapper, Select, spacing, Text } from '@lemonade-hq/blender-ui';
import { EntityTypes } from '@lemonade-hq/bluiza';
import { EntityDataProvider } from '@lemonade-hq/boutique';
import type { Locale } from '@lemonade-hq/lemonation';
import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AttachmentHub } from './AttachmentHub';
import CarAttachments from 'components/car/carClaim/CarClaimAttachments';
import CarClaimContext from 'components/car/CarClaimContext';
import HomeClaimContext from 'components/Claim/HomeClaimContext';
import { Attachments } from 'components/Home/Claims/Attachments';
import PetClaimAttachments from 'components/PetClaim/Overview/PetClaimAttachments';
import PetClaimContext from 'components/PetClaim/PetClaimContext';

const getProductByEntity = (entityType: EntityTypes): 'car' | 'home' | 'pet' => {
    if (entityType.includes('pet')) {
        return 'pet';
    } else if (entityType.includes('car')) {
        return 'car';
    } else {
        return 'home';
    }
};

const getEntityTypeFromId = (id: string | null): EntityTypes | null => {
    if (!id) return null;

    switch (id.slice(0, 3)) {
        case 'LPC':
            return EntityTypes.PetClaim;
        case 'LCC':
            return EntityTypes.CarClaim;
        default:
            return EntityTypes.HomeClaim;
    }
};

const ExampleContent: FC<{
    readonly entityType: EntityTypes;
    readonly entityId: string;
}> = ({ entityType, entityId }) => {
    const homeClaimValue = useMemo(
        () => ({
            claimPublicId: entityId,
            currency: null,
            formatCurrency: () => '',
            reloadClaim: async () => await Promise.resolve(),
            locale: 'en-US' as Locale,
        }),
        [entityId]
    );

    const petClaimValue = useMemo(
        () => ({
            claimId: entityId,
            openDialogByType: () => {},
        }),
        [entityId]
    );

    const carClaimValue = useMemo(
        () => ({
            claimId: entityId,
        }),
        [entityId]
    );

    return (
        <Flex className={pageWrapper} flexDirection="column" gap={spacing.s48}>
            <Text textAlign="center" type="h3">
                Attachments Hub
            </Text>
            <AttachmentHub entityId={entityId} entityType={entityType} />

            {entityType === EntityTypes.HomeClaim && (
                <HomeClaimContext.Provider value={homeClaimValue}>
                    <Layout className={pageWrapper}>
                        <Attachments forceRenderLegacy title="Legacy Attachments" />
                    </Layout>
                </HomeClaimContext.Provider>
            )}

            {entityType === EntityTypes.PetClaim && (
                <PetClaimContext.Provider value={petClaimValue}>
                    <Layout className={pageWrapper}>
                        <PetClaimAttachments forceRenderLegacy />
                    </Layout>
                </PetClaimContext.Provider>
            )}

            {entityType === EntityTypes.CarClaim && (
                <CarClaimContext.Provider value={carClaimValue}>
                    <CarAttachments forceRenderLegacy />
                </CarClaimContext.Provider>
            )}
        </Flex>
    );
};

export const ExamplePage: FC = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(document.location.search);
    const id = params.get('id');

    const [entityId] = useState(id ?? 'LC74DF0A4');
    const [entityType, setEntityType] = useState(getEntityTypeFromId(id) ?? EntityTypes.HomeClaim);

    useEffect(() => {
        navigate(`?id=${entityId}`, { replace: true });
    }, [entityId, navigate]);

    const handleEntityTypeChange = (value: string[] | string): void => setEntityType(value as EntityTypes);

    return (
        <Flex flexDirection="column" gap={spacing.s48} height="85vh">
            <Card margin="auto" p={spacing.s16} width="320px">
                <Flex flexDirection="column" gap={spacing.s24}>
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text fontWeight="semi-bold" type="text-lg">
                            Attachment Data
                        </Text>
                    </Flex>
                    <Flex flexDirection="column" gap={spacing.s12}>
                        <Flex flexDirection="column" gap={spacing.s06}>
                            <Text color="primary" type="label-sm">
                                Entity id
                            </Text>
                            <Text fontWeight="semi-bold">{entityId}</Text>
                        </Flex>
                        <Flex flexDirection="column" gap={spacing.s06}>
                            <Text color="primary" type="label-sm">
                                Type
                                <Text as="span" color="error">
                                    &nbsp;*
                                </Text>
                            </Text>
                            <Flex flexDirection="column" gap={spacing.s06}>
                                <Select<never, { value: string; label: EntityTypes }>
                                    onChange={value => handleEntityTypeChange(value ?? '')}
                                    options={Object.values(EntityTypes).map(type => ({
                                        value: type,
                                        label: type,
                                    }))}
                                    placeholder="Select entity type"
                                    selectedKey={entityType}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Card>

            <EntityDataProvider
                entityId={entityId}
                entityType={entityType}
                productType={getProductByEntity(entityType)}
            >
                <ExampleContent entityId={entityId} entityType={entityType} />
            </EntityDataProvider>
        </Flex>
    );
};
