import { spacing } from '@lemonade-hq/blender-ui';
import { ContentSection, LinkTab, MainButton, TabList, Tabs } from '@lemonade-hq/bluis';
import { Flex } from 'libs/blender-ui/src/base/Flex/Flex';
import { useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ArchivedEditionsTable } from './ArchivedEditionsTable';
import { DialogType, MissionControlDialogs } from './dialogs/MissionControlDialogs';
import type { MissionControlActionData } from './dialogs/MissionControlDialogs';
import { createButton } from './editions.css';
import { PublishedEditionsTable } from './PublishedEditionsTable';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import { ApprovedEditionsTable } from 'components/LoCo/products/ProductMissionControl/ApprovedEditionsTable';
import { DraftEditionsTable } from 'components/LoCo/products/ProductMissionControl/DraftEditionsTable';

export const Editions: React.FC = () => {
    const [dialogData, setDialogData] = useState<MissionControlActionData | null>(null);
    const { pathname } = useLocation();

    const product = useGetProductData();

    const productCode = product.code;

    const ROUTES = {
        editionDrafts: {
            path: 'drafts',
            label: 'Drafts',
            component: <DraftEditionsTable productCode={productCode} setDialogData={setDialogData} />,
        },
        approvedEditions: {
            path: 'approved',
            label: 'Approved',
            component: <ApprovedEditionsTable productCode={productCode} />,
        },
        publishedEditions: {
            path: 'published',
            label: 'Published',
            component: <PublishedEditionsTable productCode={productCode} />,
        },
        archivedEditions: {
            path: 'archived',
            label: 'Archived',
            component: <ArchivedEditionsTable productCode={productCode} />,
        },
    };

    return (
        <ContentSection>
            <Flex flexDirection="column" gap={spacing.s20}>
                <Tabs>
                    <Flex gap={spacing.s20} justifyContent="space-between">
                        <TabList>
                            {Object.values(ROUTES).map(({ label, path }) => (
                                <LinkTab key={label} to={path}>
                                    {label}
                                </LinkTab>
                            ))}
                        </TabList>
                        {pathname.toLocaleLowerCase().endsWith(ROUTES.editionDrafts.path) && (
                            <Flex justifyContent="flex-end">
                                <MainButton
                                    className={createButton}
                                    onClick={() => setDialogData({ type: DialogType.CreateDraftEdition })}
                                >
                                    Create
                                </MainButton>
                            </Flex>
                        )}
                    </Flex>
                    <Routes>
                        <Route element={<Navigate replace to={ROUTES.editionDrafts.path} />} path="/" />

                        {Object.values(ROUTES).map(({ path, component }) => (
                            <Route
                                element={<Flex padding={`${spacing.s32} 0 0 0`}>{component}</Flex>}
                                key={path}
                                path={path}
                            />
                        ))}
                    </Routes>
                </Tabs>
            </Flex>
            {dialogData != null && (
                <MissionControlDialogs
                    dialogData={dialogData}
                    onClose={() => setDialogData(null)}
                    productCode={productCode}
                />
            )}
        </ContentSection>
    );
};
