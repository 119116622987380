/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { blenderGeneral } from '../../../apiClients';
import type { VoiceConversation } from './Voice.types';

const BASE_URL = '/api/v1/voice';

export enum ConversationQueryKey {
    GetConversation = 'GET_CONVERSATION',
    ListConversations = 'LIST_CONVERSATIONS',
}

export const CONVERSATION_PLACEHOLDER: VoiceConversation = {
    publicId: 'mock-public-id',
    startedAt: '2024-01-01:00:00.000Z',
    endedAt: '2024-01-01:00:00.3000Z',
    personaRevisionPublicId: '',
    toolsRevisionPublicId: '',
    inboundExternalId: '',
    outboundExternalId: '',
    timeline: [],
    transcript: [
        { content: 'mock', side: 'assistant', startedAt: 0, endedAt: 15_000, id: 'mock' },
        { content: 'mock', side: 'user', startedAt: 15_000, endedAt: 3000, id: 'mock' },
    ],
    recordingFilePublicId: '',
};

// conversations

export function useGetConversation(
    conversationPublicId: string | undefined,
    { enabled, staleTime }: { readonly enabled?: boolean; readonly staleTime?: number } = {}
): UseQueryResult<VoiceConversation> {
    return useQuery({
        enabled: enabled ?? conversationPublicId != null,
        placeholderData: CONVERSATION_PLACEHOLDER,
        queryKey: [ConversationQueryKey.GetConversation, conversationPublicId],
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: staleTime ?? Infinity,
        queryFn: async () => {
            const url = `${BASE_URL}/conversations/${conversationPublicId}`;
            const response = await blenderGeneral.get<{ readonly data: VoiceConversation }>(url);
            return response.data.data;
        },
    });
}

export function useListConversations(): UseQueryResult<VoiceConversation[]> {
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: [ConversationQueryKey.GetConversation],
        placeholderData: [CONVERSATION_PLACEHOLDER, CONVERSATION_PLACEHOLDER, CONVERSATION_PLACEHOLDER],
        queryFn: async () => {
            const url = `${BASE_URL}/conversations`;
            const response = await blenderGeneral.get<{ readonly data: VoiceConversation[] }>(url);
            const conversations = response.data.data;
            conversations.forEach(conversation => {
                queryClient.setQueryData([ConversationQueryKey.GetConversation, conversation.publicId], conversation);
            });
            return conversations;
        },
    });
}
