import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { VoiceConversationsPage } from './VoiceConversationsPage';
import { VoiceSimulationPage } from './VoiceSimulationPage';

export enum VoiceURLParam {
    ConversationPublicId = 'conversationPublicId',
}

export type VoiceURLParams = Record<VoiceURLParam, string>;

export const VOICE_PATH_NAMESPACE = 'voice';
export const VOICE_FULL_PATH_PREFIX = `/backoffice/${VOICE_PATH_NAMESPACE}`;

export const VOICE_BASE_PATH = `/`;
export const VOICE_CONVERSATIONS_PATH = `/conversations`;
export const VOICE_CONVERSATIONS_PATH_WITH_PARAMS = `${VOICE_CONVERSATIONS_PATH}/:${VoiceURLParam.ConversationPublicId}`;
export const VOICE_SIMULATION_PATH = `/simulation`;

export const VoiceRoutes: FC = () => {
    return (
        <Routes>
            {[VOICE_BASE_PATH, VOICE_CONVERSATIONS_PATH, VOICE_CONVERSATIONS_PATH_WITH_PARAMS].map(path => (
                <Route element={<VoiceConversationsPage />} key={path} path={path} />
            ))}
            {[VOICE_SIMULATION_PATH].map(path => (
                <Route element={<VoiceSimulationPage />} key={path} path={path} />
            ))}
        </Routes>
    );
};
