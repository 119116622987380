import {
    Accordion,
    CardGrid,
    CardGridArea,
    Flex,
    LabelValueTable,
    shimmering,
    shimmeringMockBlock,
    spacing,
    Text,
} from '@lemonade-hq/blender-ui';
import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import type { CustomerCardSectionName } from '@lemonade-hq/persisted-tools';
import { clsx } from 'clsx';
import type { FC } from 'react';
import { useGetCustomerCardExample } from '../../persisted_tools.queries';

interface CcExampleDialogProps {
    readonly onClose: () => void;
    readonly customerCardSections?: Record<CustomerCardSectionName, boolean>;
}

export const CcExampleDialog: FC<CcExampleDialogProps> = ({ onClose, customerCardSections }) => {
    const { data: customerCardData, isPending: isLoading } = useGetCustomerCardExample();
    const actions: DialogAction[] = [
        {
            type: 'close',
            text: 'Close',
        },
    ];

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            onClose={onClose}
            size="x-large"
            title="Customer Card Example"
            withContentPadding
        >
            {isLoading || customerCardData == null ? (
                <Flex
                    alignItems="center"
                    className={clsx({ [shimmering]: isLoading })}
                    flexDirection="column"
                    gap={spacing.s08}
                    height="550px"
                >
                    {Array.from({ length: 5 }).map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className={shimmeringMockBlock} key={index} />
                    ))}
                </Flex>
            ) : (
                <Flex flexDirection="column" gap={spacing.s08} minHeight="550px">
                    {Object.entries(customerCardData.sections).map(([sectionName, sectionData]) => {
                        return (
                            <Accordion
                                disabled={sectionData.fieldGroups.length === 0}
                                key={sectionName}
                                label={`(${
                                    customerCardSections?.[sectionName as CustomerCardSectionName]
                                        ? 'Enabled'
                                        : 'Disabled'
                                })`}
                                subTitle={sectionData.description}
                                title={sectionData.title}
                            >
                                <CardGrid
                                    areas={Array.from({ length: sectionData.fieldGroups.length }, (_, i) => [
                                        `area${i}`,
                                    ])}
                                    borderRadius="md"
                                    showSeparators
                                >
                                    {sectionData.fieldGroups.map(({ fields, title, superTitle }, i) => (
                                        <CardGridArea
                                            areaName={`area${i}`}
                                            display="flex"
                                            flexDirection="column"
                                            gap={spacing.s08}
                                            key={title}
                                        >
                                            {superTitle != null && (
                                                <Text fontWeight="bold" type="h3">
                                                    {superTitle}
                                                </Text>
                                            )}
                                            <Text fontWeight="bold" type="text-md">
                                                {title}
                                            </Text>
                                            <LabelValueTable
                                                columnCount={1}
                                                data={fields.map(field => ({
                                                    label: field.title,
                                                    value: field.value,
                                                }))}
                                                key={title}
                                            />
                                        </CardGridArea>
                                    ))}
                                </CardGrid>
                            </Accordion>
                        );
                    })}
                </Flex>
            )}
        </Dialog>
    );
};
