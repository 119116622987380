/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { FC } from 'react';
import { useSelectedConversation } from './conversations/useSelectedConversation';
import { VoiceConversation } from './conversations/VoiceConversation';
import { VoiceConversationsList } from './conversations/VoiceConversationsList';
import { useListConversations } from './Voice.queries';
import { useForceBlenderUI } from 'hooks/useForceBlenderUI';
import { useFullscreen } from 'hooks/useFullScreen';

export const VoiceConversationsPage: FC = () => {
    useFullscreen();
    useForceBlenderUI();

    const { data: conversations, isPlaceholderData: isListPlaceholderData } = useListConversations();
    const [currentConversationPublicId, setCurrentConversationPublicId] = useSelectedConversation(
        isListPlaceholderData ? undefined : conversations
    );

    return (
        <VoiceConversation
            conversationPublicId={currentConversationPublicId}
            sideBarComponent={
                <VoiceConversationsList
                    conversations={conversations!}
                    currentConversationPublicId={currentConversationPublicId}
                    isLoading={isListPlaceholderData}
                    onChange={setCurrentConversationPublicId}
                />
            }
        />
    );
};
