import type { UseSuspenseQueryResult } from '@tanstack/react-query';
import { insuranceBlender } from '../../../apiClients';
import { useSuspenseQueryWithErrorHandling } from 'components/LoCo/common/hooks/useSuspenseQueryWithErrorHandling';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';
import type { RatingEdition } from 'models/LoCo/Insurance/Rating';

const BASE_PATH = '/api/v1/rating-editions';

export enum RatingEditionQueryKey {
    GetRatingEdition = 'GET_RATING_EDITION',
}

export async function getRatingEdition(editionCode: string): Promise<RatingEdition> {
    const response = await insuranceBlender.get<{ data: RatingEdition }>(`${BASE_PATH}/${editionCode}`);

    return response.data.data;
}

export function useSuspenseGetRatingEdition(
    editionCode: string,
    onSuccess?: (edition: Edition) => void
): UseSuspenseQueryResult<RatingEdition> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [RatingEditionQueryKey.GetRatingEdition, editionCode],
        queryFn: async () => {
            const result = await getRatingEdition(editionCode);
            onSuccess?.(result);
            return result;
        },
    });
}
