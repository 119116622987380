import type { FC } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Text } from '../../base/Text/Text';
import { spacing } from '../../theme/spacing.css';
import { formatMediaTime } from '../../utils/media';
import { Spinner } from '../Spinner/Spinner';
import { Tooltip } from '../Tooltip/Tooltip';
import { useAudioPlayer } from './AudioPlayer.Provider';

export const AudioPlayerDurations: FC = () => {
  const { currentTime, totalTime, isLoading } = useAudioPlayer();

  return (
    <Flex alignItems="center" gap={spacing.s04} minWidth="8.5rem">
      {isLoading ? (
        <Spinner color="grey" size="md" />
      ) : (
        <>
          <Tooltip content={`${Math.round(currentTime)}ms`}>
            <Text>{formatMediaTime(currentTime)}</Text>
          </Tooltip>
          <Text>/</Text>
          <Text color="secondary">{formatMediaTime(totalTime)}</Text>
        </>
      )}
    </Flex>
  );
};
