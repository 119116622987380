/* eslint-disable jsx-a11y/media-has-caption, @typescript-eslint/no-non-null-assertion,  react/function-component-definition */
import type { PropsWithChildren, ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

export interface AudioPlayerZoomProviderProps {
  readonly initialZoom: number;
  readonly maxZoom: number;
}

export interface AudioPlayerZoomContextProps {
  readonly currentZoom: number;
  readonly maxZoom: number;
  readonly setZoom: (zoom: number) => void;
}

export const DEFAULT_AUDIO_PLAYER_ZOOM_CONTEXT = {
  currentZoom: 1,
  maxZoom: 1,
  zoomIn: () => {},
  zoomOut: () => {},
  setZoom: () => {},
};

export const AudioPlayerZoomContext = createContext<AudioPlayerZoomContextProps | null>(
  DEFAULT_AUDIO_PLAYER_ZOOM_CONTEXT,
);

export function AudioPlayerZoomProvider({
  children,
  initialZoom,
  maxZoom,
}: PropsWithChildren<AudioPlayerZoomProviderProps>): ReactNode {
  const [currentZoom, setZoomInternal] = useState(initialZoom);

  const setZoom = useCallback(
    (zoom: number) => {
      setZoomInternal(Math.min(maxZoom, Math.max(1, zoom)));
    },
    [maxZoom],
  );

  const value = useMemo(
    () => ({
      maxZoom,
      currentZoom,
      setZoom,
    }),
    [setZoom, currentZoom, maxZoom],
  );

  return <AudioPlayerZoomContext.Provider value={value}>{children}</AudioPlayerZoomContext.Provider>;
}

export function useAudioPlayerZoom(): AudioPlayerZoomContextProps {
  const context = useContext(AudioPlayerZoomContext);

  if (context == null) {
    throw new Error('useAudioPlayerZoom must be used within a AudioPlayerZoomProvider');
  }

  return context;
}
