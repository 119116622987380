import { useOptimisticMutation } from '@lemonade-hq/blender-ui';
import type { PendingReason } from '@lemonade-hq/bluiza';
import { ProductTypes, TaskStatus } from '@lemonade-hq/bluiza';
import { camelToSnakeCaseKeys } from '@lemonade-hq/ts-helpers';
import type { UseBaseMutationResult, UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { castDraft, produce } from 'immer';
import { SubrogationQueriesQueryKey } from './blender-general/WorkflowsSubrogationQueries';
import { WorkflowsQueryKeys } from './common';
import { InteractionQueryKeys } from './InteractionsQueries';
import { usePessimisticMutation, usePessimisticMutationLegacy } from './MutationHooks';
import { handlePaymentRequest, issuePayment } from 'apis/blender-general/paymentAPI';
import { payInvoice } from 'apis/car/claims/payments';
import type { AttachmentFilterOptions, AttachmentsToUpdate } from 'apis/CarAttachmentsAPI';
import {
    createAttachmentRelations,
    createAttachments,
    deleteAttachmentRelations,
    fetchAttachments,
    updateAttachmentDetection,
    updateAttachments,
} from 'apis/CarAttachmentsAPI';
import type {
    AreItemsPayableResponse,
    CancelCarClaimParams,
    CarClaimCollectionResponse,
    CarClaimResponse,
    CloseCarClaimParams,
    CreateTaskGroupNoteParams,
    InvolvedPersonLiabilityToUpdate,
    InvolvedPersonToAdd,
    InvolvedPersonToUpdate,
    InvolvedVehicleEstimatedTotalLossResponse,
    InvolvedVehicleToAdd,
    InvolvedVehicleToUpdate,
    ItemToAdd,
    ItemToUpdate,
    MedicareRiskResponseResponse,
    ResetExemptMissingDetailsRequest,
    RiskReportSentRequest,
    RoadsideAssistanceTowJobToCreate,
    RoadsideAssistanceTowJobToEdit,
    SetExemptMissingDetailsRequest,
} from 'apis/CarClaimsAPI';
import {
    activateItems,
    addCustomRecipient,
    addInvolvedPerson,
    addInvolvedVehicle,
    addItem,
    areItemsPayable,
    assignInteraction,
    cancelAutoDecline,
    cancelClaim,
    cancelFirstResponse,
    cancelRoadsideAssistanceTowJob,
    closeClaim,
    createEventSummary,
    createNote,
    createRoadsideAssistanceTowJob,
    createTaskGroupNote,
    dismissInteraction,
    dispatchRoadsideAssistanceTowJob,
    editClaim,
    editEventSummary,
    editItem,
    editRoadsideAssistanceTowJob,
    fetchCarClaimCollectionData,
    fetchCarClaimData,
    fetchCarClaimSectionsForExport,
    fetchClaimPdfFile,
    fetchTaskGroupNotes,
    getAuthorityRequestDetails,
    getInvolvedVehicleEstimatedTotalLoss,
    getMedicareRiskReport,
    getModelsByMake,
    manualResponseInteraction,
    markSettled,
    rejectItems,
    reopenClaim,
    resetExemptMissingDetails,
    sendEmail,
    sendFirstResponse,
    setExemptMissingDetails,
    setRiskReportSent,
    simulateAutoDecline,
    startHandlingClaim,
    submitEventSummary,
    updateInvolvedPerson,
    updateInvolvedPersonInjuryInsuranceDetails,
    updateInvolvedPersonLiability,
    updateInvolvedVehicle,
} from 'apis/CarClaimsAPI';
import { createNegotiateSettlement } from 'apis/CarNegotiateSettlementsAPI';
import type { ItemizedCosts } from 'apis/CarPoliciesAPI';
import { getNewPremiumForNewPaymentPlan } from 'apis/CarPoliciesAPI';
import type { SendEmailArgsWithEntityId } from 'apis/EmailsAPI';
import { removeCustomRecipient } from 'apis/InteractionsAPI';
import { approveBill, createDemand, declineBill, sendBill } from 'apis/MitchellBiAPI';
import { createRiskIndicator, editRiskIndicator, removeRiskIndicator } from 'apis/RiskIndicatorsAPI';
import {
    activateSubrogation,
    closeFeatureSubrogation,
    closeSubrogation,
    deactivateSubrogation,
    getSubrogationAllowCloseClaim,
    reopenFeatureSubrogation,
    reopenSubrogation,
    updateSubrogationFeature,
} from 'apis/SubrogationAPI';
import type {
    AddWorkflowCustomTaskParams,
    AddWorkflowLitigationTaskParams,
    AddWorkflowTaskParams,
} from 'apis/WorkflowsCarAPI';
import { addCarWorkflowsCustomTask, addCarWorkflowsLitigationTask, addCarWorkflowsTask } from 'apis/WorkflowsCarAPI';
import { assign, assignTaskToSquad, dismiss, markAsDone, reopenTask, updateTask } from 'apis/WorkflowsV2API';
import { assignTicketToEntity } from 'apis/ZendeskAPI';
import type { AssignTicketToEntityParams } from 'apis/ZendeskAPI';
import { findCamelCasedTask } from 'components/Bluis/Workflows/utils';
import type { Attachment, AttachmentEntityType, AttachmentType } from 'models/Attachment';
import type { CarClaim } from 'models/CarClaim';
import type { CarEventSummaryType } from 'models/CarEventSummary';
import type { CarQuote, PaymentPlan as QuotePaymentPlan } from 'models/CarQuote';
import type { RoadsideAssistanceTowJob } from 'models/CarRoadsideAssistanceTow';
import type { AttachmentRelationEntityType, CarAttachmentType, PageResult, PaginatedResult } from 'models/CarShared';
import { ExportEntityType } from 'models/CarShared';
import type { Demand } from 'models/Demand';
import type { Interaction, NoteCreateRequest } from 'models/Interaction';
import type { InjuryInsuranceDetails, InvolvedParties, InvolvedPerson, InvolvedVehicle } from 'models/InvolvedParty';
import type { NegotiateSettlement } from 'models/NegotiateSettlement';
import type { PaymentMethodType, PaymentPlan, PaymentRequestDetailsResponse, PayoutOpts } from 'models/Payment';
import type { Recipient } from 'models/Recipient';
import type { FeatureSubrogation } from 'models/Subrogation';
import { ClaimPaymentQueryKeys } from 'queries/ClaimPaymentQueries';
import { PayeesQueryKeys } from 'queries/PayeesQueries';

export enum CarClaimQueryKeys {
    GetCarClaim = 'GET_CAR_CLAIM',
    GetCarClaimData = 'GET_CAR_CLAIM_DATA',
    GetCarClaimUser = 'GET_CAR_CLAIM_USER',
    GetCarClaimOtherPolicyUsers = 'GET_CAR_CLAIM_OTHER_POLICY_USERS',
    GetCarClaimLossTypes = 'GET_CAR_CLAIM_LOSS_TYPES',
    GetCarClaimMetromileLossTypes = 'GET_CAR_CLAIM_METROMILE_LOSS_TYPES',
    GetCarClaimFeatures = 'GET_CAR_CLAIM_FEATURES',
    GetCarClaimAddItemData = 'GET_CAR_CLAIM_ADD_ITEM_DATA',
    GetCarClaimPayItemData = 'GET_CAR_CLAIM_ADD_ITEM_DATA',
    GetCarFirstResponse = 'GET_CAR_FIRST_RESPONSE',
    GetPaymentMethods = 'GET_PAYMENT_METHODS',
    GetCarClaimTasks = 'GET_CAR_CLAIM_TASKS',
    GetCarClaimPolicy = 'GET_CAR_CLAIM_POLICY',
    GetCarClaimQuote = 'GET_CAR_CLAIM_QUOTE',
    GetCarClaimPolicyParties = 'GET_CAR_CLAIM_POLICY_PARTIES',
    GetMitchellUrls = 'GET_MITCHELL_URLS',
    GetCarClaimInvolvedParties = 'GET_CAR_CLAIM_INVOLVED_PARTIES',
    GetCarClaimFeatureTypes = 'GET_CAR_CLAIM_FEATURE_TYPES',
    GetCarClaimAddress = 'GET_CAR_CLAIM_ADDRESS',
    GetCarReserveLog = 'GET_CAR_RESERVE_LOG',
    GetCarClaimFeesLogs = 'GET_CAR_CLAIM_FEES_LOGS',
    GetVehicleDescriptors = 'GET_VEHICLE_DESCRIPTORS',
    GetInsuranceCarriers = 'GET_INSURANCE_CARRIERS',
    GetAttachmentsRelations = 'GET_ATTACHMENTS_RELATIONS',
    GetCarClaimAttachments = 'GET_CAR_CLAIM_ATTACHMENTS',
    GetCarClaimAttachmentsPage = 'GET_CAR_CLAIM_ATTACHMENTS_PAGE',
    GetDamageNotifications = 'GET_DAMAGE_NOTIFICATIONS',
    GetCarClaimNegligenceRule = 'GET_CAR_CLAIM_NEGLIGENCE_RULE',
    GetMitchellPdAssignmentsStatuses = 'GET_MITCHELL_PD_ASSIGNMENTS_STATUSES',
    GetCarAvailableConditions = 'GET_CAR_AVAILABLE_CONDITIONS',
    GetInvolvedVehicleDamagePoints = 'GET_INVOLVED_VEHICLE_DAMAGE_POINTS',
    GetCarClaimTaskAssignees = 'GET_CAR_CLAIM_TASK_ASSIGNEES',
    GetInvolvedVehicleEstimatedTotalLoss = 'GET_INVOLVED_VEHICLE_ESTIMATED_TOTAL_LOSS',
    GetInvolvedPersonGenderOptions = 'GET_INVOLVED_PERSON_GENDER_OPTIONS',
    GetRoadsideAssistanceTow = 'GET_ROADSIDE_ASSISTANCE_TOW',
    GetSubrogationLog = 'GET_SUBROGATION_LOG',
    GetCarCoverageData = 'GET_CAR_COVERAGE_DATA',
    GetSubrogation = 'GET_SUBROGATION',
    GetSubrogationCloseReasons = 'GET_SUBROGATION_CLOSE_REASONS',
    GetSubrogationFailedReasons = 'GET_SUBROGATION_CLOSE_REASONS',
    GetCarClaimsPriorities = 'GET_CAR_CLAIMS_PRIORITIES',
    GetCarClaimChatLog = 'GET_CAR_CLAIM_CHAT_LOG',
    GetCarInjuryDemands = 'GET_CAR_INJURY_DEMANDS',
    GetCarInjuryDemandsTypes = 'GET_CAR_INJURY_DEMANDS_TYPES',
    GetNegotiateSettlements = 'GET_NEGOTIATE_SETTLEMENTS',
    GetVehicleCoverage = 'GET_VEHICLE_COVERAGE',
    GetRoadsideAssistanceServiceTypes = 'GET_ROADSIDE_ASSISTANCE_SERVICE_TYPES',
    GetRoadsideAssistanceFuelTypes = 'GET_ROADSIDE_ASSISTANCE_FUEL_TYPES',
    GetRoadsideAssistanceKeyLocationTypes = 'GET_ROADSIDE_ASSISTANCE_KEY_LOCATION_TYPES',
    GetRoadsideAssistanceStuckInTypes = 'GET_ROADSIDE_ASSISTANCE_STUCK_IN_TYPES',
    GetCarGlassStatus = 'GET_CAR_GLASS_STATUS',
    GetCreatableTasks = 'GET_CREATABLE_TASKS',
    GetCarInjuryBiClaims = 'GET_CAR_INJURY_BI_CLAIMS',
    GetCarInjuryBills = 'GET_CAR_INJURY_BILLS',
    GetCarClaimVendors = 'GET_CAR_CLAIM_VENDORS',
    GetCarClaimLocationCountries = 'GET_CAR_CLAIM_LOCATION_COUNTRIES',
    GetCarClaimClaimsHistory = 'GET_CAR_CLAIM_CLAIMS_HISTORY',
    GetCarWorkflowsNote = 'GET_CAR_WORKFLOWS_NOTE',
    GetVehicleMakes = 'GET_VEHICLE_MAKES',
    GetVehicleModels = 'GET_VEHICLE_MODELS',
    GetCarClaimCrashDetection = 'GET_CAR_CLAIM_CRASH_DETECTION',
    GetCarClaimEventSummaries = 'GET_CAR_CLAIM_EVENT_SUMMARIES',
    GetCarClaimTaskGroups = 'GET_CAR_CLAIM_TASK_GROUPS',
    GetCarClaimCancellationReasons = 'GET_CAR_CLAIM_CANCELLATION_REASONS',
    GetCarTaskGroupNotes = 'GET_CAR_TASK_GROUP_NOTES',
    GetCarClaimFlags = 'GET_CAR_CLAIM_FLAGS',
    GetPayableItemsCheck = 'GET_PAYABLE_ITEMS_CHECK',
    GetHealthInsuranceTypes = 'GET_HEALTH_INSURANCE_TYPES',
    GetBiCoverageTypes = 'GET_BI_COVERAGE_TYPES',
    GetMedicalProviderTypes = 'GET_MEDICAL_PROVIDER_TYPES',
    GetCarDemandEvaluations = 'GET_CAR_DEMAND_EVALUATIONS',
    GetDemandEvaluationAllowedRolesMap = 'GET_DEMAND_EVALUATION_ALLOWED_ROLES_MAP',
    GetAuthorityRequestOptions = 'GET_AUTHORITY_REQUEST_OPTIONS',
    getAttachmentOptionalRelations = 'GET_ATTACHMENT_OPTIONAL_RELATIONS',
    GetCarClaimAttachmentsFiltersData = 'GET_CAR_CLAIM_ATTACHMENTS_FILTERS_DATA',
    GetDemandEvaluationLienProviderTypesData = 'GET_DEMAND_EVALUATION_LIEN_PROVIDER_TYPES_DATA',
    GetCarPossibleRecipientsData = 'GET_CAR_POSSIBLE_RECIPIENTS_DATA',
    GetSalvagePickupLocationTypes = 'GET_SALVAGE_PICKUP_LOCATION_TYPES',
    GetSalvageAssignmentCancellationReasons = 'GET_SALVAGE_ASSIGNMENT_CANCELLATION_REASONS',
    GetCarClaimCloseReasonsData = 'GET_CAR_CLAIM_CLOSE_REASONS_DATA',
    GetSubrogationAllowCloseClaim = 'GET_SUBROGATION_ALLOW_CLOSE_CLAIM',
    getCarClaimManulRSAReasons = 'GET_CAR_CLAIM_MANUAL_RSA_REASONS',
    GetMedicareRiskReport = 'GET_MEDICARE_RISK_REPORT',
}

export type CarClaimData = Pick<
    CarClaimResponse,
    | 'adjusters'
    | 'claim_actions'
    | 'claim_coverages'
    | 'claim_summaries'
    | 'claim_tasks_groups'
    | 'claim'
    | 'emailAdjusters'
    | 'first_response'
    | 'glass_status'
    | 'last_known_location'
    | 'litigation_cases'
    | 'policy_pdf_url'
    | 'policy_users'
    | 'policy'
    | 'risk_indicators'
    | 'tags'
    | 'timezone'
    | 'user'
    | 'vendors'
>;

type AddItemData = Pick<CarClaimCollectionResponse, 'expense_types' | 'item_types'>;
type PayItemData = Pick<CarClaimCollectionResponse, 'waive_deductible_reasons'>;
type InvolvedVehicleDamagePointsData = Pick<CarClaimCollectionResponse, 'involved_vehicle_damage_points'>;
type HealthInsuranceTypesData = Pick<CarClaimCollectionResponse, 'healthInsuranceTypes'>;
type BiCoverageTypesData = Pick<CarClaimCollectionResponse, 'biCoverageTypes'>;
type MedicalProviderTypesData = Pick<CarClaimCollectionResponse, 'medicalProviderTypes'>;
type DemandEvaluationAllowedRolesMapData = Pick<CarClaimCollectionResponse, 'demandEvaluationAllowedRolesMap'>;
type DemandEvaluationLienProviderTypesData = Pick<CarClaimCollectionResponse, 'lienProviderTypes'>;
type SalvagePickupLocationTypesData = Pick<CarClaimCollectionResponse, 'salvagePickupLocationTypes'>;
type SalvageAssignmentCancellationReasons = Pick<CarClaimCollectionResponse, 'salvageAssignmentCancellationReasons'>;
type ClaimCloseReasons = Pick<CarClaimCollectionResponse, 'claimCloseReasons'>;
type TortCoverageTypes = Pick<CarClaimCollectionResponse, 'tort_coverage_types'>;
type CarClaimManualRSAReasons = Pick<CarClaimCollectionResponse, 'reasons_for_manual_rsa'>;

type CarCommunicationTimelineData = Pick<
    CarClaimResponse,
    'communication_timeline' | 'first_response' | 'possible_recipients'
>;
type PossibleRecipientsData = Pick<CarClaimResponse, 'possible_recipients'>;
type CarClaimPolicyPartiesData = Pick<CarClaimResponse, 'drivers' | 'vehicles'>;
type CarClaimAttachmentsRelationsData = Pick<CarClaimResponse, 'attachments_relations'>;
type CarAvailableConditionsData = Pick<CarClaimResponse, 'available_car_conditions'>;
type CarClaimCoveragesData = Pick<CarClaimResponse, 'claim_coverages'>;
type CarRoadsideAssistanceTowJobsData = Pick<CarClaimResponse, 'roadside_assistance_tow_jobs'>;
type CarClaimInvolvedParties = Pick<CarClaimResponse, 'involved_parties'>;
type CarClaimCrashDetectionData = Pick<CarClaimResponse, 'crash_predictions'>;
type CarClaimsEventSummaries = Pick<CarClaimResponse, 'claim_summaries'>;
type CarClaimFeatureTypes = Pick<CarClaimResponse, 'feature_types'>;
type CarClaimFlags = Pick<CarClaimResponse, 'risk_indicators'>;
type CarClaimTaskGroupsData = Pick<CarClaimResponse, 'claim_tasks_groups'>;
type CarClaimWorkflowData = Pick<CarClaimResponse, 'workflow'>;
type CarClaimDemandEvaluationsData = Pick<CarClaimResponse, 'demandEvaluations'>;
export type CarClaimAttachmentOptionalRelationsData = Pick<CarClaimResponse, 'attachmentOptionalRelations'>;

async function getMitchellUrls(): Promise<Pick<CarClaimCollectionResponse, 'mitchell_urls'>> {
    return await fetchCarClaimCollectionData(['mitchell_urls']);
}

export function useMitchellUrls(): UseQueryResult<Pick<CarClaimCollectionResponse, 'mitchell_urls'>> {
    return useQuery({ queryKey: [CarClaimQueryKeys.GetMitchellUrls], queryFn: getMitchellUrls });
}

async function getClaimData(claimId: string): Promise<CarClaimData> {
    return await fetchCarClaimData(claimId, [
        'claim',
        'claim_actions',
        'adjusters',
        'emailAdjusters',
        'user',
        'policy_users',
        'timezone',
        'tags',
        'claim_coverages',
        'glass_status',
        'vendors',
        'policy_pdf_url',
        'claim_summaries',
        'risk_indicators',
        'claim_tasks_groups',
        'policy',
        'quote',
        'litigation_cases',
        'first_response',
        'last_known_location',
    ]);
}

export function useCarClaim(claimId: string): UseQueryResult<CarClaimData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaim, claimId],
        queryFn: async () => await getClaimData(claimId),
    });
}

export function useRefreshClaim(claimId: string): () => void {
    const cache = useQueryClient();

    return () => {
        void cache.invalidateQueries({ queryKey: [CarClaimQueryKeys.GetCarClaim, claimId] });
    };
}

export function useRefreshClaimTasks(claimId: string): () => void {
    const cache = useQueryClient();

    return () => {
        void cache.invalidateQueries({ queryKey: [CarClaimQueryKeys.GetCarClaimTasks, claimId] });
    };
}

async function getAttachmentsRelations(claimId: string): Promise<CarClaimAttachmentsRelationsData> {
    return await fetchCarClaimData(claimId, ['attachments_relations']);
}

export function useAttachmentsRelations(
    claimId: string,
    enabled = true
): UseQueryResult<CarClaimAttachmentsRelationsData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetAttachmentsRelations, claimId],
        queryFn: async () => await getAttachmentsRelations(claimId),
        enabled,
    });
}

async function getCarClaimPolicyUsers(claimId: string): Promise<Pick<CarClaimResponse, 'policy_users'>> {
    return await fetchCarClaimData(claimId, ['policy_users']);
}

export function useCarClaimPolicyUsers(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'policy_users'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimOtherPolicyUsers, claimId],
        queryFn: async () => await getCarClaimPolicyUsers(claimId),
    });
}

async function getCarClaimUser(claimId: string): Promise<Pick<CarClaimResponse, 'user'>> {
    return await fetchCarClaimData(claimId, ['user']);
}

export function useCarClaimUser(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'user'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimUser, claimId],
        queryFn: async () => await getCarClaimUser(claimId),
    });
}

async function getCarClaimVendors(claimId: string): Promise<Pick<CarClaimResponse, 'vendors'>> {
    return await fetchCarClaimData(claimId, ['vendors']);
}

export function useCarClaimVendors(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'vendors'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimVendors, claimId],
        queryFn: async () => await getCarClaimVendors(claimId),
    });
}

export async function getCarClaimPolicy(claimId: string): Promise<Pick<CarClaimResponse, 'policy'>> {
    return await fetchCarClaimData(claimId, ['policy']);
}

export function useCarClaimPolicy(claimId: string, enabled = true): UseQueryResult<Pick<CarClaimResponse, 'policy'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimPolicy, claimId],
        queryFn: async () => await getCarClaimPolicy(claimId),
        enabled,
    });
}

export async function getCarClaimQuote(claimId: string): Promise<Pick<CarClaimResponse, 'quote'>> {
    return await fetchCarClaimData(claimId, ['quote']);
}

export function useCarClaimQuote(claimId: string, enabled = true): UseQueryResult<Pick<CarClaimResponse, 'quote'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimQuote, claimId],
        queryFn: async () => await getCarClaimQuote(claimId),
        enabled,
    });
}

async function getCarClaimPolicyParties(claimId: string): Promise<CarClaimPolicyPartiesData> {
    return await fetchCarClaimData(claimId, ['drivers', 'vehicles']);
}

export function useCarClaimPolicyParties(claimId: string): UseQueryResult<CarClaimPolicyPartiesData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimPolicyParties, claimId],
        queryFn: async () => await getCarClaimPolicyParties(claimId),
    });
}

async function getCarClaimCrashDetection(claimId: string): Promise<CarClaimCrashDetectionData> {
    return await fetchCarClaimData(claimId, ['crash_predictions']);
}

export function useCarClaimCrashDetection(
    claimId: string,
    enabled?: boolean
): UseQueryResult<CarClaimCrashDetectionData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimCrashDetection, claimId],
        queryFn: async () => await getCarClaimCrashDetection(claimId),
        enabled,
    });
}

async function getCreatableTasks(claimId: string): Promise<Pick<CarClaimResponse, 'creatable_tasks'>> {
    return await fetchCarClaimData(claimId, ['creatable_tasks']);
}

export function useCreatableTasks(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'creatable_tasks'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCreatableTasks, claimId],
        queryFn: async () => await getCreatableTasks(claimId),
    });
}

async function getCarClaimTaskGroups(claimId: string): Promise<CarClaimTaskGroupsData> {
    return await fetchCarClaimData(claimId, ['claim_tasks_groups']);
}

export function useCarClaimTaskGroups(claimId: string): UseQueryResult<CarClaimTaskGroupsData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimTaskGroups, claimId],
        queryFn: async () => await getCarClaimTaskGroups(claimId),
    });
}

async function getCarClaimInvolvedParties(claimId: string): Promise<CarClaimInvolvedParties> {
    return await fetchCarClaimData(claimId, ['involved_parties']);
}

export function useCarClaimInvolvedParties(
    claimId: string,
    enabled = true
): UseQueryResult<Pick<CarClaimResponse, 'involved_parties'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimId],
        queryFn: async () => await getCarClaimInvolvedParties(claimId),
        enabled,
    });
}

async function getCarClaimFeatureTypes(claimId: string): Promise<CarClaimFeatureTypes> {
    return await fetchCarClaimData(claimId, ['feature_types']);
}

export function useCarClaimFeatureTypes(claimId: string): UseQueryResult<CarClaimFeatureTypes> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimFeatureTypes, claimId],
        queryFn: async () => await getCarClaimFeatureTypes(claimId),
    });
}

export function useCarClaimManualRSAReasons(): UseQueryResult<CarClaimManualRSAReasons> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.getCarClaimManulRSAReasons],
        queryFn: async () => await fetchCarClaimCollectionData(['reasons_for_manual_rsa']),
    });
}

async function getCarClaimFlags(claimId: string): Promise<CarClaimFlags> {
    return await fetchCarClaimData(claimId, ['risk_indicators']);
}

export function useCarClaimFlags(claimId: string): UseQueryResult<CarClaimFlags> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimFlags, claimId],
        queryFn: async () => await getCarClaimFlags(claimId),
    });
}

async function getCarClaimSubrogationLog(claimId: string): Promise<Pick<CarClaimResponse, 'subrogation_logs'>> {
    return await fetchCarClaimData(claimId, ['subrogation_logs']);
}

export function useCarClaimSubrogationLog(
    claimId: string,
    enabled = true
): UseQueryResult<Pick<CarClaimResponse, 'subrogation_logs'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetSubrogationLog, claimId],
        queryFn: async () => await getCarClaimSubrogationLog(claimId),
        enabled,
    });
}

async function getNegotiateSettlements(claimId: string): Promise<Pick<CarClaimResponse, 'negotiate_settlements'>> {
    return await fetchCarClaimData(claimId, ['negotiate_settlements']);
}

export function useCarNegotiateSettlements(
    claimId: string
): UseQueryResult<Pick<CarClaimResponse, 'negotiate_settlements'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetNegotiateSettlements, claimId],
        queryFn: async () => await getNegotiateSettlements(claimId),
    });
}

async function getCarInjuryDemand(claimId: string): Promise<Pick<CarClaimResponse, 'injury_demands'>> {
    return await fetchCarClaimData(claimId, ['injury_demands']);
}

export function useCarInjuryDemand(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'injury_demands'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarInjuryDemands, claimId],
        queryFn: async () => await getCarInjuryDemand(claimId),
    });
}

async function getCarClaimAddress(claimId: string): Promise<Pick<CarClaimResponse, 'address'>> {
    return await fetchCarClaimData(claimId, ['address']);
}

export function useCarClaimAddress(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'address'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimAddress, claimId],
        queryFn: async () => await getCarClaimAddress(claimId),
    });
}

async function getCarClaimChatLog(claimId: string): Promise<Pick<CarClaimResponse, 'chat_log'>> {
    return await fetchCarClaimData(claimId, ['chat_log']);
}

export function useCarClaimChatLog(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'chat_log'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimChatLog, claimId],
        queryFn: async () => await getCarClaimChatLog(claimId),
    });
}

async function getCarClaimSubrogation(claimId: string): Promise<Pick<CarClaimResponse, 'subrogation'>> {
    return await fetchCarClaimData(claimId, ['subrogation']);
}

export function useCarClaimSubrogation(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'subrogation'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetSubrogation, claimId],
        queryFn: async () => await getCarClaimSubrogation(claimId),
    });
}

async function getCarClaimFeatures(
    claimId: string
): Promise<Pick<CarClaimResponse, 'editable_claim_items' | 'features'>> {
    return await fetchCarClaimData(claimId, ['features', 'editable_claim_items']);
}

export function useCarClaimFeatures(
    claimId: string,
    enabled = true
): UseQueryResult<Pick<CarClaimResponse, 'editable_claim_items' | 'features'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimFeatures, claimId],
        queryFn: async () => await getCarClaimFeatures(claimId),
        enabled,
    });
}

async function getCarClaimCollectionLossTypes(): Promise<Pick<CarClaimCollectionResponse, 'loss_types'>> {
    return await fetchCarClaimCollectionData(['loss_types']);
}

export function useCarClaimLossTypes(): UseQueryResult<Pick<CarClaimCollectionResponse, 'loss_types'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimLossTypes],
        queryFn: getCarClaimCollectionLossTypes,
    });
}

async function getCarClaimCollectionMetromileLossTypes(): Promise<
    Pick<CarClaimCollectionResponse, 'metromile_loss_types'>
> {
    return await fetchCarClaimCollectionData(['metromile_loss_types']);
}

export function useCarClaimMetromileLossTypes(): UseQueryResult<
    Pick<CarClaimCollectionResponse, 'metromile_loss_types'>
> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimMetromileLossTypes],
        queryFn: getCarClaimCollectionMetromileLossTypes,
    });
}

async function getCarClaimCollectionCancellationReasons(): Promise<
    Pick<CarClaimCollectionResponse, 'cancellation_reasons'>
> {
    return await fetchCarClaimCollectionData(['cancellation_reasons']);
}

export function useCarClaimCancellationReasons(): UseQueryResult<
    Pick<CarClaimCollectionResponse, 'cancellation_reasons'>
> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimCancellationReasons],
        queryFn: getCarClaimCollectionCancellationReasons,
    });
}

async function getInvolvedPersonGenderOptions(): Promise<
    Pick<CarClaimCollectionResponse, 'involved_person_gender_options'>
> {
    return await fetchCarClaimCollectionData(['involved_person_gender_options']);
}

export function useInvolvedPersonGenderOptions(): UseQueryResult<
    Pick<CarClaimCollectionResponse, 'involved_person_gender_options'>
> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetInvolvedPersonGenderOptions],
        queryFn: getInvolvedPersonGenderOptions,
    });
}

async function getCarClaimAddItemData(): Promise<AddItemData> {
    return await fetchCarClaimCollectionData(['expense_types', 'item_types']);
}

export function useCarClaimAddOrEditItemData(enabled = true): UseQueryResult<AddItemData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimAddItemData],
        queryFn: getCarClaimAddItemData,
        enabled,
    });
}

async function getCarClaimPayItemData(): Promise<PayItemData> {
    return await fetchCarClaimCollectionData(['waive_deductible_reasons']);
}

export function useCarClaimPayItemData(enabled = true): UseQueryResult<PayItemData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimPayItemData],
        queryFn: getCarClaimPayItemData,
        enabled,
    });
}

async function getCarClaimCloseReasonsData(): Promise<ClaimCloseReasons> {
    return await fetchCarClaimCollectionData(['claimCloseReasons']);
}

export function useCarClaimCloseReasonsData(enabled = true): UseQueryResult<ClaimCloseReasons> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimCloseReasonsData],
        queryFn: getCarClaimCloseReasonsData,
        enabled,
    });
}

export function useCarClaimTortCoverageTypes(): UseQueryResult<TortCoverageTypes> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimCloseReasonsData],
        queryFn: async () => await fetchCarClaimCollectionData(['tort_coverage_types']),
    });
}

async function getHealthInsuranceTypes(): Promise<HealthInsuranceTypesData> {
    return await fetchCarClaimCollectionData(['healthInsuranceTypes']);
}

export function useHealthInsuranceTypes(enabled = true): UseQueryResult<HealthInsuranceTypesData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetHealthInsuranceTypes],
        queryFn: getHealthInsuranceTypes,
        enabled,
    });
}

async function getBiCoverageTypes(): Promise<BiCoverageTypesData> {
    return await fetchCarClaimCollectionData(['biCoverageTypes']);
}

export function useBiCoverageTypes(enabled = true): UseQueryResult<BiCoverageTypesData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetBiCoverageTypes],
        queryFn: getBiCoverageTypes,
        enabled,
    });
}

async function getDemandEvaluationAllowedRolesMap(): Promise<DemandEvaluationAllowedRolesMapData> {
    return await fetchCarClaimCollectionData(['demandEvaluationAllowedRolesMap']);
}

export function useDemandEvaluationAllowedRolesMap(): UseQueryResult<DemandEvaluationAllowedRolesMapData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetDemandEvaluationAllowedRolesMap],
        queryFn: getDemandEvaluationAllowedRolesMap,
    });
}

async function getMedicalProviderTypes(): Promise<MedicalProviderTypesData> {
    return await fetchCarClaimCollectionData(['medicalProviderTypes']);
}

export function useMedicalProviderTypes(enabled = true): UseQueryResult<MedicalProviderTypesData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetMedicalProviderTypes],
        queryFn: getMedicalProviderTypes,
        enabled,
    });
}

async function getDemandEvaluationLienProviderTypes(): Promise<DemandEvaluationLienProviderTypesData> {
    return await fetchCarClaimCollectionData(['lienProviderTypes']);
}

export function useDemandEvaluationLienProviderTypes(): UseQueryResult<DemandEvaluationLienProviderTypesData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetDemandEvaluationLienProviderTypesData],
        queryFn: getDemandEvaluationLienProviderTypes,
    });
}

async function getSalvagePickupLocationTypesData(): Promise<SalvagePickupLocationTypesData> {
    return await fetchCarClaimCollectionData(['salvagePickupLocationTypes']);
}

export function useSalvagePickupLocationTypes(): UseQueryResult<SalvagePickupLocationTypesData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetSalvagePickupLocationTypes],
        queryFn: getSalvagePickupLocationTypesData,
    });
}

async function getSalvageAssignmentCancellationReasons(): Promise<SalvageAssignmentCancellationReasons> {
    return await fetchCarClaimCollectionData(['salvageAssignmentCancellationReasons']);
}

export function useSalvageAssignmentCancellationReasons(): UseQueryResult<SalvageAssignmentCancellationReasons> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetSalvageAssignmentCancellationReasons],
        queryFn: getSalvageAssignmentCancellationReasons,
    });
}

async function getCarPossibleRecipients(claimId: string): Promise<PossibleRecipientsData> {
    return await fetchCarClaimData(claimId, ['possible_recipients']);
}

export function useCarPossibleRecipients(claimId: string): UseQueryResult<PossibleRecipientsData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarPossibleRecipientsData],
        queryFn: async () => await getCarPossibleRecipients(claimId),
    });
}

export function useCancelFirstResponseMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly claimId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(cancelFirstResponse, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimId],
        [CarClaimQueryKeys.GetCarFirstResponse, claimId],
    ]);
}

export function useSendFirstResponseMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly claimId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(sendFirstResponse, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimId],
        [CarClaimQueryKeys.GetCarFirstResponse, claimId],
    ]);
}

async function getWorkflowNote(claimId: string): Promise<Pick<CarClaimResponse, 'workflows_note_id'>> {
    return await fetchCarClaimData(claimId, ['workflows_note_id']);
}

export function useCarClaimWorkflowNote(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'workflows_note_id'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarWorkflowsNote, claimId],
        queryFn: async () => await getWorkflowNote(claimId),
    });
}

async function getWorkflow(claimId: string): Promise<Pick<CarClaimResponse, 'workflow' | 'workflows_note_id'>> {
    return await fetchCarClaimData(claimId, ['workflow', 'workflows_note_id']);
}

export function useCarClaimWorkflow(
    claimId: string
): UseQueryResult<Pick<CarClaimResponse, 'workflow' | 'workflows_note_id'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimTasks, claimId],
        queryFn: async () => await getWorkflow(claimId),
    });
}

async function getMitchellPdAssignmentsStatuses(
    claimId: string
): Promise<Pick<CarClaimResponse, 'claim_mitchell_pd_assignments_status'>> {
    return await fetchCarClaimData(claimId, ['claim_mitchell_pd_assignments_status']);
}

export function useMitchellPdAssignmentsStatuses(
    claimId: string
): UseQueryResult<Pick<CarClaimResponse, 'claim_mitchell_pd_assignments_status'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetMitchellPdAssignmentsStatuses, claimId],
        queryFn: async () => await getMitchellPdAssignmentsStatuses(claimId),
    });
}

async function getDamageNotifications(claimId: string): Promise<Pick<CarClaimResponse, 'damage_notifications'>> {
    return await fetchCarClaimData(claimId, ['damage_notifications']);
}

export function useDamageNotification(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'damage_notifications'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetDamageNotifications, claimId],
        queryFn: async () => await getDamageNotifications(claimId),
    });
}

export function useCarClaimCreateAttachmentsMutation(claimId: string): UseBaseMutationResult<
    Attachment[],
    unknown,
    {
        readonly entityId: string;
        readonly entityType: AttachmentEntityType;
        readonly attachments: {
            readonly type: AttachmentType;
            readonly filePublicId: string;
            readonly contentType: string;
            readonly description?: string;
        }[];
    },
    null
> {
    const queryKey = [
        [CarClaimQueryKeys.GetCarClaimAttachments, claimId],
        [CarClaimQueryKeys.GetCarClaimAttachmentsPage, claimId],
        [CarClaimQueryKeys.GetCarClaimAttachmentsFiltersData, claimId],
    ];

    return usePessimisticMutationLegacy(createAttachments, queryKey);
}

export function useCarClaimUpdateAttachmentMutation(claimId: string): UseBaseMutationResult<
    Attachment[],
    unknown,
    {
        readonly attachments: AttachmentsToUpdate[];
    },
    null
> {
    const cache = useQueryClient();
    const queryKey = [
        [CarClaimQueryKeys.GetCarClaimAttachments, claimId],
        [CarClaimQueryKeys.GetCarClaimAttachmentsPage, claimId],
    ];

    function onSuccess() {
        void cache.invalidateQueries({ queryKey: queryKey });
    }

    return usePessimisticMutationLegacy(updateAttachments, queryKey, onSuccess);
}

export function useCreateAttachmentsRelationsMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly entityPublicId: string;
        readonly entityType: AttachmentRelationEntityType;
        readonly attachmentsPublicIds: string[];
    },
    CarClaimAttachmentsRelationsData
> {
    const queryKey = [[CarClaimQueryKeys.GetAttachmentsRelations], claimId];

    function mutateAttachmentRelation({
        data,
        variables,
    }: {
        data: CarClaimAttachmentsRelationsData;
        variables: { entityPublicId: string; entityType: AttachmentRelationEntityType; attachmentsPublicIds: string[] };
    }) {
        const { entityPublicId, entityType, attachmentsPublicIds } = variables;

        return produce(data, draftData => {
            draftData.attachments_relations = {
                ...data.attachments_relations,
                ...{
                    [entityPublicId]: {
                        attachments:
                            data.attachments_relations[entityPublicId]?.attachments.concat(attachmentsPublicIds) ??
                            attachmentsPublicIds,
                        entityType,
                    },
                },
            };
        });
    }

    return useOptimisticMutation({
        mutationFn: createAttachmentRelations,
        invalidateKeys: [queryKey, [CarClaimQueryKeys.GetAttachmentsRelations, claimId]],
        mutate: mutateAttachmentRelation,
        mutateKey: queryKey,
    });
}

export function useDeleteAttachmentsRelationsMutation(
    claimId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly entityPublicId: string; readonly attachmentsPublicIds: string[] },
    CarClaimAttachmentsRelationsData
> {
    const queryKey = [[CarClaimQueryKeys.GetAttachmentsRelations], claimId];

    function mutateAttachmentRelation({
        data,
        variables,
    }: {
        data: CarClaimAttachmentsRelationsData;
        variables: { entityPublicId: string; attachmentsPublicIds: string[] };
    }) {
        const { entityPublicId, attachmentsPublicIds } = variables;

        return produce(data, draftData => {
            const relation = draftData.attachments_relations[entityPublicId];

            if (relation != null) {
                relation.attachments = relation.attachments.filter(att => !attachmentsPublicIds.includes(att));
            }
        });
    }

    return useOptimisticMutation({
        mutationFn: deleteAttachmentRelations,
        invalidateKeys: [queryKey, [CarClaimQueryKeys.GetAttachmentsRelations, claimId]],
        mutate: mutateAttachmentRelation,
        mutateKey: queryKey,
    });
}

export function useMarkSettledMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly settled: boolean; readonly claimId: string }, CarClaimData> {
    const queryKey = [CarClaimQueryKeys.GetCarClaim, claimId];

    function mutateSettled({ data, variables }: { data: CarClaimData; variables: { settled: boolean } }) {
        const { settled } = variables;

        return produce(data, draftData => {
            draftData.claim.settled = settled;
        });
    }

    return useOptimisticMutation<{ settled: boolean; claimId: string }, CarClaimData>({
        mutationFn: markSettled,
        invalidateKeys: [
            queryKey,
            [CarClaimQueryKeys.GetCarClaim, claimId],
            [CarClaimQueryKeys.GetCarClaimsPriorities, claimId],
        ],
        mutate: mutateSettled,
        mutateKey: queryKey,
    });
}

export function useReopenCarClaimMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(reopenClaim, [
        [CarClaimQueryKeys.GetCarClaim, claimId],
        [CarClaimQueryKeys.GetCarClaimFeatures, claimId],
        [PayeesQueryKeys.GetPayees, ProductTypes.Car, claimId],
        [CarClaimQueryKeys.GetCarClaimsPriorities, claimId],
    ]);
}

export function useCancelCarClaimMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly claimId: string;
        readonly cancelParams?: CancelCarClaimParams;
    },
    null
> {
    return usePessimisticMutationLegacy(cancelClaim, [
        [CarClaimQueryKeys.GetCarClaim, claimId],
        [CarClaimQueryKeys.GetCarClaimFeatures, claimId],
        [PayeesQueryKeys.GetPayees, ProductTypes.Car, claimId],
        [CarClaimQueryKeys.GetCarClaimsPriorities, claimId],
    ]);
}

export function useAddManualFlagToClaimMutation(claimId: string) {
    return usePessimisticMutationLegacy(createRiskIndicator, [
        [CarClaimQueryKeys.GetCarClaimFlags, claimId],
        [CarClaimQueryKeys.GetCarClaimTasks, claimId],
    ]);
}

export function useEditManualFlagToClaimMutation(claimId: string) {
    return usePessimisticMutationLegacy(editRiskIndicator, [[CarClaimQueryKeys.GetCarClaimFlags, claimId]]);
}

export function useRemoveManualFlagFromClaimMutation(claimId: string) {
    return usePessimisticMutationLegacy(removeRiskIndicator, [
        [CarClaimQueryKeys.GetCarClaimFlags, claimId],
        [CarClaimQueryKeys.GetCarClaimTasks, claimId],
    ]);
}

export function useCloseCarClaimMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string; readonly closeParams: CloseCarClaimParams }, null> {
    return usePessimisticMutationLegacy(closeClaim, [
        [CarClaimQueryKeys.GetCarClaim, claimId],
        [CarClaimQueryKeys.GetCarClaimFeatures, claimId],
        [PayeesQueryKeys.GetPayees, ProductTypes.Car, claimId],
        [CarClaimQueryKeys.GetCarClaimsPriorities, claimId],
    ]);
}

export function useQaSimulateAutoDeclineMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(simulateAutoDecline, [[CarClaimQueryKeys.GetCarClaim, claimId]]);
}

export function useEditCarClaimMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string; readonly claim: Partial<CarClaim> }, null> {
    return usePessimisticMutationLegacy(editClaim, [
        [CarClaimQueryKeys.GetCarClaim, claimId],
        [CarClaimQueryKeys.GetCarClaimsPriorities, claimId],
    ]);
}

export function useCancelAutoDeclineMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(cancelAutoDecline, [[CarClaimQueryKeys.GetCarClaim, claimId]]);
}

export function useEditCarClaimOptimisticMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string; readonly claim: Partial<CarClaim> }, CarClaimData> {
    const queryKey = [CarClaimQueryKeys.GetCarClaim, claimId];

    function mutateClaim({
        data,
        variables,
    }: {
        data: CarClaimData;
        variables: { claimId: string; claim: Partial<CarClaim> };
    }) {
        const { claim: updatedClaim } = variables;

        return produce(data, draftData => {
            draftData.claim = { ...data.claim, ...updatedClaim };
        });
    }

    return useOptimisticMutation({
        mutationFn: editClaim,
        invalidateKeys: [queryKey, [CarClaimQueryKeys.GetCarClaimsPriorities, claimId]],
        mutate: mutateClaim,
        mutateKey: queryKey,
    });
}

export function useEditCarClaimInvolvedPartiesOptimisticMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string; readonly claim: Partial<CarClaim> }, CarClaimData> {
    const queryKey = [CarClaimQueryKeys.GetCarClaim, claimId];

    function mutateClaim({
        data,
        variables,
    }: {
        data: CarClaimData;
        variables: { claimId: string; claim: Partial<CarClaim> };
    }) {
        const { claim: updatedClaim } = variables;

        return produce(data, draftData => {
            draftData.claim = { ...data.claim, ...updatedClaim };
        });
    }

    return useOptimisticMutation({
        mutationFn: editClaim,
        invalidateKeys: [
            queryKey,
            [CarClaimQueryKeys.GetCarClaimsPriorities, claimId],
            [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimId],
        ],
        mutate: mutateClaim,
        mutateKey: queryKey,
    });
}

export function useMarkTaskDoneMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly taskId: string }, CarClaimWorkflowData> {
    const queryKey = [CarClaimQueryKeys.GetCarClaimTasks, claimId];

    function mutateTasks({ data, variables }: { data: CarClaimWorkflowData; variables: { taskId: string } }) {
        const { taskId } = variables;

        return produce(data, draft => {
            const task = castDraft(
                findCamelCasedTask(draft.workflow.segments.flat(), task => task.publicId === taskId)
            );

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            task!.task.task.status = TaskStatus.Done;
        });
    }

    return useOptimisticMutation({
        mutationFn: markAsDone,
        invalidateKeys: [queryKey],
        mutate: mutateTasks,
        mutateKey: queryKey,
    });
}

export function useReopenTaskMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly taskId: string }, CarClaimWorkflowData> {
    const queryKey = [CarClaimQueryKeys.GetCarClaimTasks, claimId];

    function mutateTasks({ data, variables }: { data: CarClaimWorkflowData; variables: { taskId: string } }) {
        const { taskId } = variables;

        return produce(data, draft => {
            const task = castDraft(
                findCamelCasedTask(draft.workflow.segments.flat(), task => task.publicId === taskId)
            );

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            task!.task.task.status = TaskStatus.Open;
        });
    }

    return useOptimisticMutation({
        mutationFn: reopenTask,
        invalidateKeys: [queryKey],
        mutate: mutateTasks,
        mutateKey: queryKey,
    });
}

export function useAssignTaskMutation(
    claimId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly taskId: string; readonly operatorId: string | null },
    CarClaimWorkflowData
> {
    const queryKey = [CarClaimQueryKeys.GetCarClaimTasks, claimId];

    function mutateTasks({
        data,
        variables,
    }: {
        data: CarClaimWorkflowData;
        variables: { taskId: string; operatorId: string | null };
    }) {
        const { taskId, operatorId } = variables;

        return produce(data, draft => {
            const task = castDraft(
                findCamelCasedTask(draft.workflow.segments.flat(), task => task.publicId === taskId)
            );

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            task!.task.task.operatorPublicId = operatorId!;
        });
    }

    return useOptimisticMutation({
        mutationFn: assign,
        invalidateKeys: [
            [queryKey, WorkflowsQueryKeys.GetAssignableOperators, CarClaimQueryKeys.GetCarClaimTaskAssignees],
        ],
        mutate: mutateTasks,
        mutateKey: queryKey,
    });
}

export function useAssignTaskToSquadMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly taskPublicId: string;
        readonly squad: string;
    },
    null
> {
    return usePessimisticMutationLegacy(assignTaskToSquad, [
        [CarClaimQueryKeys.GetCarClaimTasks, claimId],
        [CarClaimQueryKeys.GetCarClaimTaskAssignees, claimId],
    ]);
}

export function useDismissTaskMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly taskId: string; readonly reason: string }, CarClaimWorkflowData> {
    const queryKey = [CarClaimQueryKeys.GetCarClaimTasks, claimId];

    function mutateTasks({ data, variables }: { data: CarClaimWorkflowData; variables: { taskId: string } }) {
        const { taskId } = variables;

        return produce(data, draft => {
            const task = castDraft(
                findCamelCasedTask(draft.workflow.segments.flat(), task => task.publicId === taskId)
            );

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            task!.task.task.status = TaskStatus.Dismissed;
        });
    }

    return useOptimisticMutation({
        mutationFn: dismiss,
        invalidateKeys: [queryKey, [CarClaimQueryKeys.GetAuthorityRequestOptions]],
        mutate: mutateTasks,
        mutateKey: queryKey,
    });
}

export function useAddItemMutation(
    claimId: string
): UseBaseMutationResult<void, unknown, { readonly claimId: string; readonly item: ItemToAdd }, null> {
    return usePessimisticMutationLegacy(addItem, [
        [CarClaimQueryKeys.GetCarClaimFeatures, claimId],
        [CarClaimQueryKeys.GetCarClaim, claimId],
    ]);
}

export function useEditItemMutation(
    claimId: string
): UseBaseMutationResult<void, unknown, { readonly claimId: string; readonly item: ItemToUpdate }, null> {
    return usePessimisticMutationLegacy(editItem, [
        [CarClaimQueryKeys.GetCarClaimFeatures, claimId],
        [CarClaimQueryKeys.GetCarClaim, claimId],
    ]);
}

export function useRejectItemsMutation(
    claimId: string
): UseBaseMutationResult<
    void,
    unknown,
    { readonly claimId: string; readonly itemsIds: string[]; readonly reason: string },
    null
> {
    return usePessimisticMutationLegacy(rejectItems, [
        [CarClaimQueryKeys.GetCarClaimFeatures, claimId],
        [CarClaimQueryKeys.GetCarClaim, claimId],
    ]);
}

export function useActivateItemsMutation(
    claimId: string
): UseBaseMutationResult<void, unknown, { readonly claimId: string; readonly itemsIds: string[] }, null> {
    return usePessimisticMutationLegacy(activateItems, [
        [CarClaimQueryKeys.GetCarClaimFeatures, claimId],
        [CarClaimQueryKeys.GetCarClaim, claimId],
    ]);
}

export function usePayInvoiceMutation(claimId: string): UseBaseMutationResult<
    void,
    unknown,
    {
        readonly invoiceId: string;
        readonly paymentMethod: PaymentMethodType;
        readonly paymentMethodId?: string;
        readonly payoutOpts?: PayoutOpts;
        readonly waiveDeductibleReason?: string;
        readonly claimId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(payInvoice, [
        [CarClaimQueryKeys.GetCarClaimFeatures, claimId],
        [CarClaimQueryKeys.GetCarClaim, claimId],
        [ClaimPaymentQueryKeys.GetClaimPayments, claimId],
    ]);
}

export function useSendEmailMutation(
    claimId: string
): UseBaseMutationResult<void, unknown, SendEmailArgsWithEntityId, null> {
    return usePessimisticMutationLegacy(sendEmail, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimId],
        [CarClaimQueryKeys.GetCarClaimsPriorities, claimId],
    ]);
}

export function useAddCustomRecipientMutation(
    claimId: string
): UseBaseMutationResult<
    Recipient,
    unknown,
    { readonly claimId: string; readonly recipient: Pick<Recipient, 'email' | 'name' | 'role'> },
    null
> {
    const cache = useQueryClient();
    const queryKey = [[InteractionQueryKeys.GetCommunicationTimeline, claimId]];

    function onSuccess(recipient: Recipient) {
        cache.setQueryData<CarCommunicationTimelineData>(queryKey, data => {
            return produce(data!, draftData => {
                if (typeof draftData !== 'undefined') {
                    draftData.possible_recipients.push(recipient);
                }
            });
        });
    }

    return usePessimisticMutationLegacy(addCustomRecipient, queryKey, onSuccess);
}

export function useStartHandlingMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string }, null> {
    const queryKey = [CarClaimQueryKeys.GetCarClaimTasks, claimId];

    return usePessimisticMutationLegacy(startHandlingClaim, [queryKey]);
}

export function useRemoveCustomRecipient(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly recipientId: string }, CarCommunicationTimelineData> {
    const queryKey = [InteractionQueryKeys.GetCommunicationTimeline, claimId];

    function mutateRecipients({
        data,
        variables,
    }: {
        data: CarCommunicationTimelineData;
        variables: { recipientId: string };
    }) {
        const { recipientId } = variables;

        return produce(data, draftData => {
            draftData.possible_recipients = draftData.possible_recipients.filter(r => String(r.id) !== recipientId);
        });
    }

    return useOptimisticMutation<{ recipientId: string }, CarCommunicationTimelineData>({
        mutationFn: removeCustomRecipient,
        invalidateKeys: [queryKey, [InteractionQueryKeys.GetCommunicationTimeline, claimId]],
        mutate: mutateRecipients,
        mutateKey: queryKey,
    });
}

export function useAssignInteractionMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly interactionPublicId: string; readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(assignInteraction, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
        [CarClaimQueryKeys.GetCarClaimsPriorities, claimPublicId],
    ]);
}

export function useAssignTicketMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, AssignTicketToEntityParams, null> {
    return usePessimisticMutationLegacy(assignTicketToEntity, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
        [CarClaimQueryKeys.GetCarClaimsPriorities, claimPublicId],
    ]);
}

export function useDismissInteractionMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly interactionPublicId: string; readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(dismissInteraction, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
        [(CarClaimQueryKeys.GetCarClaimsPriorities, claimPublicId)],
    ]);
}

export function useManualInteractionMutation(
    claimPublicId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly interactionPublicId: string; readonly claimId: string; readonly medium: string; readonly body: string },
    null
> {
    return usePessimisticMutationLegacy(manualResponseInteraction, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
        [(CarClaimQueryKeys.GetCarClaimsPriorities, claimPublicId)],
    ]);
}

export function useCreateNoteMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly note: NoteCreateRequest }, null> {
    return usePessimisticMutationLegacy(createNote, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
        [CarClaimQueryKeys.GetCarWorkflowsNote, claimPublicId],
    ]);
}

export function useCreateGroupTaskNoteMutation(
    groupPublicId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly groupPublicId: string; readonly note: CreateTaskGroupNoteParams },
    null
> {
    return usePessimisticMutationLegacy(createTaskGroupNote, [[CarClaimQueryKeys.GetCarTaskGroupNotes, groupPublicId]]);
}

export function useInvolvedPeople(claimPublicId: string): UseQueryResult<Pick<CarClaimResponse, 'involved_parties'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId],
        queryFn: async () => await fetchCarClaimData(claimPublicId, ['involved_parties']),
    });
}

export function useAddInvolvedPersonMutation(
    claimPublicId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly claimPublicId: string; readonly involvedPerson: InvolvedPersonToAdd },
    null
> {
    return usePessimisticMutationLegacy(addInvolvedPerson, [
        [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId],
    ]);
}

export function useEditInvolvedPersonMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly involvedPersonToUpdate: InvolvedPersonToUpdate }, null> {
    return usePessimisticMutationLegacy(updateInvolvedPerson, [
        [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId],
    ]);
}

export function useEditInvolvedPersonInjuryInsuranceDetailsMutation(claimPublicId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly involvedPersonPublicId: string;
        readonly operatorId?: string;
        readonly injuryInsuranceDetails: Partial<InjuryInsuranceDetails>;
    },
    null
> {
    return usePessimisticMutationLegacy(updateInvolvedPersonInjuryInsuranceDetails, [
        [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId],
    ]);
}

export function useEditInvolvedPersonOptimisticMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly involvedPersonToUpdate: InvolvedPersonToUpdate }, InvolvedParties> {
    const queryKey = [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId];

    function mutateInvolvedParties({
        data,
        variables,
    }: {
        data: InvolvedParties;
        variables: { involvedPersonToUpdate: InvolvedPersonToUpdate };
    }) {
        const { involvedPersonToUpdate } = variables;

        return produce(data, draftData => {
            let person: InvolvedPerson | undefined = draftData.people.find(
                (involvedPerson: InvolvedPerson) => involvedPerson.publicId === involvedPersonToUpdate.publicId
            );

            if (person) {
                person = { ...person, ...involvedPersonToUpdate };
            }
        });
    }

    return useOptimisticMutation({
        mutationFn: updateInvolvedPerson,
        invalidateKeys: [queryKey],
        mutate: mutateInvolvedParties,
        mutateKey: queryKey,
    });
}

export function useEditInvolvedPersonLiabilityMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly involvedPerson: InvolvedPersonLiabilityToUpdate }, null> {
    return usePessimisticMutationLegacy(updateInvolvedPersonLiability, [
        [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId],
    ]);
}

export function useAddInvolvedVehicleMutation(
    claimPublicId: string
): UseBaseMutationResult<
    void,
    unknown,
    { readonly claimPublicId: string; readonly involvedVehicle: InvolvedVehicleToAdd },
    null
> {
    return usePessimisticMutationLegacy(addInvolvedVehicle, [
        [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId],
    ]);
}

export function useEditInvolvedVehicleMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly involvedVehicle: InvolvedVehicleToUpdate }, null> {
    return usePessimisticMutationLegacy(updateInvolvedVehicle, [
        [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId],
    ]);
}

export function useEditInvolvedVehicleOptimisticMutation(
    claimPublicId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly involvedVehicle: InvolvedVehicleToUpdate },
    Partial<CarClaimResponse>
> {
    const queryKey = [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId];

    function mutateInvolvedParties({
        data,
        variables,
    }: {
        data: CarClaimResponse;
        variables: { involvedVehicle: InvolvedVehicleToUpdate };
    }) {
        const { involvedVehicle } = variables;

        return produce(data, draftData => {
            const vehicle: InvolvedVehicle | undefined = draftData.involved_parties.vehicles.find(
                (iv: InvolvedVehicle) => iv.publicId === involvedVehicle.publicId
            );

            if (vehicle) {
                if (involvedVehicle.damaged != null) {
                    vehicle.damaged = involvedVehicle.damaged;
                }

                vehicle.shopType = involvedVehicle.shopType;
            }
        });
    }

    return useOptimisticMutation({
        mutationFn: updateInvolvedVehicle,
        invalidateKeys: [queryKey],
        mutate: mutateInvolvedParties,
        mutateKey: queryKey,
    });
}

async function getPaymentMethods(claimId: string): Promise<Pick<CarClaimResponse, 'payment_methods'>> {
    return await fetchCarClaimData(claimId, ['payment_methods']);
}

export function usePaymentMethods(
    claimId: string,
    enabled = true
): UseQueryResult<Pick<CarClaimResponse, 'payment_methods'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetPaymentMethods, claimId],
        queryFn: async () => await getPaymentMethods(claimId),
        enabled,
    });
}

async function getReserveLog(claimId: string): Promise<Pick<CarClaimResponse, 'claim_reserves_log'>> {
    return await fetchCarClaimData(claimId, ['claim_reserves_log']);
}

export function useReservesLog(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'claim_reserves_log'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarReserveLog, claimId],
        queryFn: async () => await getReserveLog(claimId),
    });
}

async function getClaimFeesLog(claimId: string): Promise<Pick<CarClaimResponse, 'claim_adjuster_fees'>> {
    return await fetchCarClaimData(claimId, ['claim_adjuster_fees']);
}

export function useClaimFeesLog(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'claim_adjuster_fees'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimFeesLogs, claimId],
        queryFn: async () => await getClaimFeesLog(claimId),
    });
}

async function getInsuranceCarriers(): Promise<Pick<CarClaimCollectionResponse, 'insurance_carriers'>> {
    return await fetchCarClaimCollectionData(['insurance_carriers']);
}

export function useInsuranceCarriers(): UseQueryResult<Pick<CarClaimCollectionResponse, 'insurance_carriers'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetInsuranceCarriers],
        queryFn: getInsuranceCarriers,
    });
}

async function getNegligenceRule(claimId: string): Promise<Pick<CarClaimResponse, 'claim_negligence_rule'>> {
    return await fetchCarClaimData(claimId, ['claim_negligence_rule']);
}

export function useNegligenceRule(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'claim_negligence_rule'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimNegligenceRule, claimId],
        queryFn: async () => await getNegligenceRule(claimId),
    });
}

async function getClaimTaskAssignees(claimId: string): Promise<Pick<CarClaimResponse, 'task_assignees'>> {
    return await fetchCarClaimData(claimId, ['task_assignees']);
}

export function useCarClaimTaskAssignees(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'task_assignees'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimTaskAssignees, claimId],
        queryFn: async () => await getClaimTaskAssignees(claimId),
    });
}

async function getCarAvailableConditions(claimId: string): Promise<CarAvailableConditionsData> {
    return await fetchCarClaimData(claimId, ['available_car_conditions']);
}

export function useCarAvailableConditions(claimId: string): UseQueryResult<CarAvailableConditionsData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarAvailableConditions, claimId],
        queryFn: async () => await getCarAvailableConditions(claimId),
    });
}

async function getInvolvedVehicleDamagePoints(): Promise<InvolvedVehicleDamagePointsData> {
    return await fetchCarClaimCollectionData(['involved_vehicle_damage_points']);
}

export function useInvolvedVehicleDamagePoints(): UseQueryResult<InvolvedVehicleDamagePointsData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetInvolvedVehicleDamagePoints],
        queryFn: getInvolvedVehicleDamagePoints,
    });
}

type NewPremiumPaymentPlanResponse = {
    readonly premium: number;
    readonly chargeAmounts: ItemizedCosts;
    readonly remainingInstallments: number;
    readonly chargeAmountsPerInstallment: ItemizedCosts;
    readonly policyPublicId: string;
    readonly quotePublicId: string;
    readonly pricingId: string;
    readonly paymentPlan: PaymentPlan;
};

export function useNewPremiumForNewPaymentPlan(
    policyPublicId: string,
    paymentPlan: QuotePaymentPlan,
    newPaymentPlan: QuotePaymentPlan,
    quote: CarQuote | null
): UseQueryResult<NewPremiumPaymentPlanResponse> {
    return useQuery({
        queryKey: [newPaymentPlan, quote],
        queryFn: async () => await getNewPremiumForNewPaymentPlan(policyPublicId, paymentPlan),
    });
}

async function getInvolvedVehicleEstimatedTotalLossData(
    involvedVehiclePublicId: string
): Promise<InvolvedVehicleEstimatedTotalLossResponse> {
    return await getInvolvedVehicleEstimatedTotalLoss({ involvedVehiclePublicId });
}

export function useInvolvedVehicleEstimatedTotalLoss(
    involvedVehiclePublicId?: string
): UseQueryResult<InvolvedVehicleEstimatedTotalLossResponse> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetInvolvedVehicleEstimatedTotalLoss, involvedVehiclePublicId],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: async () => await getInvolvedVehicleEstimatedTotalLossData(involvedVehiclePublicId!),
        enabled: involvedVehiclePublicId != null,
    });
}

async function getCarClaimCoverages(claimId: string): Promise<CarClaimCoveragesData> {
    return await fetchCarClaimData(claimId, ['claim_coverages']);
}

export function useCarClaimCoverages(claimId: string): UseQueryResult<CarClaimCoveragesData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarCoverageData, claimId],
        queryFn: async () => await getCarClaimCoverages(claimId),
    });
}

async function getRoadsideAssistanceTowData(claimId: string): Promise<CarRoadsideAssistanceTowJobsData> {
    return await fetchCarClaimData(claimId, ['roadside_assistance_tow_jobs']);
}

export function useRoadsideAssistanceTow(claimId: string): UseQueryResult<CarRoadsideAssistanceTowJobsData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetRoadsideAssistanceTow, claimId],
        queryFn: async () => await getRoadsideAssistanceTowData(claimId),
    });
}

export function useAddRoadsideAssistanceTow(
    claimId: string
): UseBaseMutationResult<
    RoadsideAssistanceTowJob | undefined,
    unknown,
    { readonly roadsideAssistanceTowJobParams: RoadsideAssistanceTowJobToCreate },
    null
> {
    return usePessimisticMutationLegacy(createRoadsideAssistanceTowJob, [
        [CarClaimQueryKeys.GetRoadsideAssistanceTow, claimId],
    ]);
}

export function useEditRoadsideAssistanceTow(
    claimId: string
): UseBaseMutationResult<
    RoadsideAssistanceTowJob,
    unknown,
    { readonly jobPublicId: string; readonly params: RoadsideAssistanceTowJobToEdit },
    null
> {
    return usePessimisticMutation({
        mutationFn: editRoadsideAssistanceTowJob,
        invalidateKeys: [[CarClaimQueryKeys.GetRoadsideAssistanceTow, claimId]],
    });
}

export function useDispatchRoadsideAssistanceTow(
    claimId: string
): UseBaseMutationResult<RoadsideAssistanceTowJob, unknown, { readonly jobPublicId: string }, null> {
    return usePessimisticMutationLegacy(dispatchRoadsideAssistanceTowJob, [
        [CarClaimQueryKeys.GetRoadsideAssistanceTow, claimId],
    ]);
}

export function useCancelRoadsideAssistanceTow(
    claimId: string
): UseBaseMutationResult<RoadsideAssistanceTowJob, unknown, { readonly jobPublicId: string }, null> {
    return usePessimisticMutationLegacy(cancelRoadsideAssistanceTowJob, [
        [CarClaimQueryKeys.GetRoadsideAssistanceTow, claimId],
    ]);
}

export function useActivateSubrogationMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(activateSubrogation, [
        [CarClaimQueryKeys.GetCarClaim, claimPublicId],
        [CarClaimQueryKeys.GetSubrogation, claimPublicId],
    ]);
}

export function useDeactivateSubrogationMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(deactivateSubrogation, [
        [CarClaimQueryKeys.GetCarClaim, claimPublicId],
        [CarClaimQueryKeys.GetSubrogation, claimPublicId],
    ]);
}

export function useCloseSubrogationMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string; readonly closeReason?: string }, null> {
    return usePessimisticMutationLegacy(closeSubrogation, [
        [CarClaimQueryKeys.GetCarClaim, claimPublicId],
        [CarClaimQueryKeys.GetSubrogation, claimPublicId],
    ]);
}

export function useReopenSubrogationMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(reopenSubrogation, [
        [CarClaimQueryKeys.GetCarClaim, claimPublicId],
        [CarClaimQueryKeys.GetSubrogation, claimPublicId],
    ]);
}

export function useUpdateSubrogationFeatureMutation(
    claimPublicId: string,
    entityPublicId?: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly featureSubrogationId: string; readonly featureSubrogation: Partial<FeatureSubrogation> },
    Pick<CarClaimResponse, 'subrogation'>
> {
    const queryKey = [CarClaimQueryKeys.GetSubrogation, claimPublicId];

    function mutateSubrogationFeature({
        data,
        variables,
    }: {
        data: Pick<CarClaimResponse, 'subrogation'>;
        variables: { featureSubrogationId: string; featureSubrogation: Partial<FeatureSubrogation> };
    }) {
        const { featureSubrogation, featureSubrogationId } = variables;

        return produce(data, draftData => {
            const draftFeature = draftData.subrogation.features.find(feat => feat.publicId === featureSubrogationId);

            if (draftFeature != null) {
                Object.entries(featureSubrogation).forEach(([key, val]) => {
                    if (key !== 'publicId') {
                        draftFeature[key] = val;
                    }
                });
            }
        });
    }

    return useOptimisticMutation({
        mutationFn: updateSubrogationFeature,
        invalidateKeys: [
            queryKey,
            [CarClaimQueryKeys.GetSubrogationLog, claimPublicId],
            [SubrogationQueriesQueryKey.GetFeatureSubrogationManage, entityPublicId],
        ],
        mutate: mutateSubrogationFeature,
        mutateKey: queryKey,
    });
}

export function useCloseFeatureSubrogationMutation(
    claimPublicId: string
): UseBaseMutationResult<
    void,
    unknown,
    { readonly featureSubrogationId: string; readonly closeReason?: string },
    null
> {
    return usePessimisticMutationLegacy(closeFeatureSubrogation, [
        [CarClaimQueryKeys.GetSubrogation, claimPublicId],
        [CarClaimQueryKeys.GetSubrogationLog, claimPublicId],
    ]);
}

export function useReopenFeatureSubrogationMutation(
    claimPublicId: string
): UseBaseMutationResult<void, unknown, { readonly featureSubrogationId: string }, null> {
    return usePessimisticMutationLegacy(reopenFeatureSubrogation, [
        [CarClaimQueryKeys.GetSubrogation, claimPublicId],
        [CarClaimQueryKeys.GetSubrogationLog, claimPublicId],
    ]);
}

export async function getSubrogationCloseReasons(): Promise<string[]> {
    return (await fetchCarClaimCollectionData(['subrogation_close_reasons'])).subrogation_close_reasons;
}

export async function getSubrogationFailedReasons(): Promise<string[]> {
    return (await fetchCarClaimCollectionData(['subrogation_failed_reasons'])).subrogation_failed_reasons;
}

async function getClaimPriorities(claimId: string): Promise<Pick<CarClaimResponse, 'priorities'>> {
    return await fetchCarClaimData(claimId, ['priorities']);
}

export function useClaimPriorities(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'priorities'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimsPriorities, claimId],
        queryFn: async () => await getClaimPriorities(claimId),
    });
}

async function getLocationCountries(): Promise<Pick<CarClaimCollectionResponse, 'location_countries'>> {
    return await fetchCarClaimCollectionData(['location_countries']);
}

export function useLocationCountries(): UseQueryResult<Pick<CarClaimCollectionResponse, 'location_countries'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimLocationCountries],
        queryFn: getLocationCountries,
        staleTime: Infinity,
    });
}

export function useCreateDemandMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly demand: Omit<Demand, 'publicId' | 'status'> }, null> {
    return usePessimisticMutationLegacy(createDemand, [[CarClaimQueryKeys.GetCarInjuryDemands, claimPublicId]]);
}

export function useCreateNegotiateSettlementMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly settlement: Omit<NegotiateSettlement, 'publicId'> }, null> {
    return usePessimisticMutationLegacy(createNegotiateSettlement, [
        [CarClaimQueryKeys.GetNegotiateSettlements, claimPublicId],
    ]);
}

async function getMitchellBiDemandTypes(): Promise<string[]> {
    return await fetchCarClaimCollectionData(['mitchell_bi_demand_types']).then(
        ({ mitchell_bi_demand_types }) => mitchell_bi_demand_types
    );
}

export function useMitchellBiDemandTypes(): UseQueryResult<string[]> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarInjuryDemandsTypes],
        queryFn: getMitchellBiDemandTypes,
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
}

async function getRoadsideAssistanceServiceTypes(): Promise<
    Pick<CarClaimCollectionResponse, 'roadside_assistance_service_types'>
> {
    return await fetchCarClaimCollectionData(['roadside_assistance_service_types']);
}

export function useRoadsideAssistanceServiceTypes(): UseQueryResult<
    Pick<CarClaimCollectionResponse, 'roadside_assistance_service_types'>
> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetRoadsideAssistanceServiceTypes],
        queryFn: getRoadsideAssistanceServiceTypes,
        staleTime: Infinity,
    });
}

async function getRoadsideAssistanceFuelTypes(): Promise<
    Pick<CarClaimCollectionResponse, 'roadside_assistance_fuel_types'>
> {
    return await fetchCarClaimCollectionData(['roadside_assistance_fuel_types']);
}

export function useRoadsideAssistanceFuelTypes(): UseQueryResult<
    Pick<CarClaimCollectionResponse, 'roadside_assistance_fuel_types'>
> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetRoadsideAssistanceFuelTypes],
        queryFn: getRoadsideAssistanceFuelTypes,
        staleTime: Infinity,
    });
}

async function getRoadsideAssistanceKeyLocationTypes(): Promise<
    Pick<CarClaimCollectionResponse, 'roadside_assistance_key_location_types'>
> {
    return await fetchCarClaimCollectionData(['roadside_assistance_key_location_types']);
}

export function useRoadsideAssistanceKeyLocationTypes(): UseQueryResult<
    Pick<CarClaimCollectionResponse, 'roadside_assistance_key_location_types'>
> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetRoadsideAssistanceKeyLocationTypes],
        queryFn: getRoadsideAssistanceKeyLocationTypes,
        staleTime: Infinity,
    });
}

async function getRoadsideAssistanceStuckInTypes(): Promise<
    Pick<CarClaimCollectionResponse, 'roadside_assistance_stuck_in_types'>
> {
    return await fetchCarClaimCollectionData(['roadside_assistance_stuck_in_types']);
}

export function useRoadsideAssistanceStuckInTypes(): UseQueryResult<
    Pick<CarClaimCollectionResponse, 'roadside_assistance_stuck_in_types'>
> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetRoadsideAssistanceStuckInTypes],
        queryFn: getRoadsideAssistanceStuckInTypes,
        staleTime: Infinity,
    });
}

export function useAddTaskMutation(
    claimPublicId: string
): UseBaseMutationResult<void, unknown, { readonly data: AddWorkflowTaskParams }, null> {
    return usePessimisticMutationLegacy(addCarWorkflowsTask, [[CarClaimQueryKeys.GetCarClaimTasks, claimPublicId]]);
}

export function useAddCustomTaskMutation(
    claimPublicId: string
): UseBaseMutationResult<void, unknown, { readonly data: AddWorkflowCustomTaskParams }, null> {
    return usePessimisticMutationLegacy(addCarWorkflowsCustomTask, [
        [CarClaimQueryKeys.GetCarClaimTasks, claimPublicId],
    ]);
}

export function useAddLitigationTaskMutation(
    claimPublicId: string
): UseBaseMutationResult<void, unknown, { readonly data: AddWorkflowLitigationTaskParams }, null> {
    return usePessimisticMutationLegacy(addCarWorkflowsLitigationTask, [
        [CarClaimQueryKeys.GetCarClaimTasks, claimPublicId],
    ]);
}

async function getInjuryBiClaims(claimId: string): Promise<Pick<CarClaimResponse, 'injury_claims'>> {
    return await fetchCarClaimData(claimId, ['injury_claims']);
}

export function useInjuryBiClaims(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'injury_claims'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarInjuryBiClaims, claimId],
        queryFn: async () => await getInjuryBiClaims(claimId),
    });
}

async function getInjuryBills(claimId: string): Promise<Pick<CarClaimResponse, 'injury_bills'>> {
    return await fetchCarClaimData(claimId, ['injury_bills']);
}

export function useInjuryBills(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'injury_bills'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarInjuryBills, claimId],
        queryFn: async () => await getInjuryBills(claimId),
    });
}

export function useDeclineBillMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly billPublicId: string }, null> {
    const cache = useQueryClient();
    const queryKey = [CarClaimQueryKeys.GetCarInjuryBills, claimPublicId];

    function onSuccess(data: null, { billPublicId }: { billPublicId: string }) {
        cache.setQueryData<Pick<CarClaimResponse, 'injury_bills'>>(queryKey, data => {
            return produce(data!, draftData => {
                const draftBill = draftData.injury_bills.find(bill => bill.publicId === billPublicId);

                if (draftBill != null) {
                    draftBill.approvePayment = false;
                }
            });
        });
    }

    return usePessimisticMutationLegacy(declineBill, [[CarClaimQueryKeys.GetCarInjuryBills, claimPublicId]], onSuccess);
}

export function useApproveBillMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly billPublicId: string }, null> {
    const cache = useQueryClient();
    const queryKey = [CarClaimQueryKeys.GetCarInjuryBills, claimPublicId];

    function onSuccess(data: null, { billPublicId }: { billPublicId: string }) {
        cache.setQueryData<Pick<CarClaimResponse, 'injury_bills'>>(queryKey, data => {
            return produce(data!, draftData => {
                const draftBill = draftData.injury_bills.find(bill => bill.publicId === billPublicId);

                if (draftBill != null) {
                    draftBill.approvePayment = true;
                }
            });
        });
    }

    return usePessimisticMutationLegacy(approveBill, [[CarClaimQueryKeys.GetCarInjuryBills, claimPublicId]], onSuccess);
}

export function useAddBillReviewMutation(
    claimPublicId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly attachmentPublicIds: string[]; readonly claimPublicId: string; readonly involvedPersonPublicId: string },
    null
> {
    return usePessimisticMutationLegacy(sendBill, [[CarClaimQueryKeys.GetCarInjuryBills, claimPublicId]]);
}

export function useUpdateBillsSummaryMutation(
    claimPublicId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly involvedPersonToUpdate: Pick<InvolvedPersonToUpdate, 'billsSummary' | 'publicId'> },
    null
> {
    return usePessimisticMutationLegacy(updateInvolvedPerson, [
        [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId],
    ]);
}

export function useUpdateUnreachableByPhoneMutation(
    claimPublicId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly involvedPersonToUpdate: Pick<InvolvedPersonToUpdate, 'publicId' | 'unreachableByPhone'> },
    Pick<CarClaimResponse, 'involved_parties'>
> {
    const queryKey = [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId];

    function mutateUnreachableByPhone({
        data,
        variables,
    }: {
        data: Pick<CarClaimResponse, 'involved_parties'>;
        variables: { involvedPersonToUpdate: Pick<InvolvedPersonToUpdate, 'publicId' | 'unreachableByPhone'> };
    }) {
        const { publicId, unreachableByPhone: unreachable } = variables.involvedPersonToUpdate;

        return produce(data, draftData => {
            const draftInvolvedPerson = draftData.involved_parties.people.find(p => p.publicId === publicId);

            if (draftInvolvedPerson != null) {
                draftInvolvedPerson.unreachableByPhone = unreachable;
            }
        });
    }

    return useOptimisticMutation({
        mutationFn: updateInvolvedPerson,
        invalidateKeys: [queryKey],
        mutate: mutateUnreachableByPhone,
        mutateKey: queryKey,
    });
}

export function useUpdateInjuriesUnreachableByPhoneMutation(
    claimPublicId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly involvedPersonToUpdate: Pick<InvolvedPersonToUpdate, 'injuriesUnreachableByPhone' | 'publicId'> },
    Pick<CarClaimResponse, 'involved_parties'>
> {
    const queryKey = [CarClaimQueryKeys.GetCarClaimInvolvedParties, claimPublicId];

    function mutateUnreachableByPhone({
        data,
        variables,
    }: {
        data: Pick<CarClaimResponse, 'involved_parties'>;
        variables: { involvedPersonToUpdate: Pick<InvolvedPersonToUpdate, 'injuriesUnreachableByPhone' | 'publicId'> };
    }) {
        const { publicId, injuriesUnreachableByPhone: unreachable } = variables.involvedPersonToUpdate;

        return produce(data, draftData => {
            const draftInvolvedPerson = draftData.involved_parties.people.find(p => p.publicId === publicId);

            if (draftInvolvedPerson != null) {
                draftInvolvedPerson.injuriesUnreachableByPhone = unreachable;
            }
        });
    }

    return useOptimisticMutation({
        mutationFn: updateInvolvedPerson,
        invalidateKeys: [queryKey],
        mutate: mutateUnreachableByPhone,
        mutateKey: queryKey,
    });
}

async function getCarClaimHistory(claimId: string): Promise<Pick<CarClaimResponse, 'claim_history'>> {
    return await fetchCarClaimData(claimId, ['claim_history']);
}

export function useCarClaimHistory(claimId: string): UseQueryResult<Pick<CarClaimResponse, 'claim_history'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimClaimsHistory, claimId],
        queryFn: async () => await getCarClaimHistory(claimId),
    });
}

export async function getVehicleMakes(): Promise<Pick<CarClaimCollectionResponse, 'vehicle_makes'>> {
    return await fetchCarClaimCollectionData(['vehicle_makes']);
}

export function useVehicleMakes(): UseQueryResult<Pick<CarClaimCollectionResponse, 'vehicle_makes'>> {
    return useQuery({ queryKey: [CarClaimQueryKeys.GetVehicleMakes], queryFn: getVehicleMakes, staleTime: Infinity });
}

export function useVehicleModels(make: string): UseQueryResult<string[]> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetVehicleModels, make],
        queryFn: async () => await getModelsByMake(make),
        enabled: make.length > 0,
        staleTime: Infinity,
    });
}

async function getCarClaimEventSummaries(claimId: string): Promise<CarClaimsEventSummaries> {
    return await fetchCarClaimData(claimId, ['claim_summaries']);
}

export function useCarClaimEventSummaries(claimId: string): UseQueryResult<CarClaimsEventSummaries> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimEventSummaries, claimId],
        queryFn: async () => await getCarClaimEventSummaries(claimId),
    });
}

export function useCreateEventSummary(
    claimId: string
): UseBaseMutationResult<
    void,
    unknown,
    { readonly claimId: string; readonly type: CarEventSummaryType; readonly content: string },
    null
> {
    return usePessimisticMutationLegacy(createEventSummary, [[CarClaimQueryKeys.GetCarClaimEventSummaries, claimId]]);
}

export function useEditEventSummary(claimId: string): UseBaseMutationResult<
    void,
    unknown,
    {
        readonly claimId: string;
        readonly eventSummaryId: string;
        readonly type: CarEventSummaryType;
        readonly content: string;
    },
    null
> {
    return usePessimisticMutationLegacy(editEventSummary, [[CarClaimQueryKeys.GetCarClaimEventSummaries, claimId]]);
}

export function useSubmitEventSummary(
    claimId: string
): UseBaseMutationResult<void, unknown, { readonly claimId: string; readonly eventSummaryId: string }, null> {
    return usePessimisticMutationLegacy(submitEventSummary, [
        [CarClaimQueryKeys.GetCarClaimData, claimId],
        [CarClaimQueryKeys.GetCarClaimEventSummaries, claimId],
    ]);
}

export function useCarClaimAttchmentsPage(
    filters: Omit<AttachmentFilterOptions, 'pageParam'>
): UseInfiniteQueryResult<PaginatedResult, unknown> {
    return useInfiniteQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimAttachmentsPage, filters.claimPublicId, filters],
        queryFn: async ({ pageParam = 1 }) => {
            return await fetchAttachments({
                ...filters,
                description: filters.description?.toLowerCase(),
                pageParam,
            });
        },
        initialPageParam: 1,
        getNextPageParam: lastPage => {
            const page = lastPage as unknown as PageResult;
            return page.paginationStats.currentPage < page.paginationStats.totalPages
                ? page.paginationStats.currentPage + 1
                : undefined;
        },
    });
}

export function useRefreshCarClaimAttchmentsPage(): () => void {
    const cache = useQueryClient();

    return () => {
        cache.setQueriesData({ queryKey: [CarClaimQueryKeys.GetCarClaimAttachmentsPage] }, null);
    };
}

export function useCarClaimAttachments({
    claimPublicId,
    chatSessionId,
    attachmentTypes,
}: {
    readonly claimPublicId: string;
    readonly chatSessionId?: string;
    readonly attachmentTypes?: CarAttachmentType[];
}): UseQueryResult<Attachment[]> {
    const queryKey = [CarClaimQueryKeys.GetCarClaimAttachments, claimPublicId, chatSessionId].filter(
        item => item != null
    );

    return useQuery({
        queryKey: queryKey,
        queryFn: async () => {
            const { attachments, paginationStats } = await fetchAttachments({
                claimPublicId,
                chatSessionId,
                attachmentTypes,
            });

            if (paginationStats.totalPages > 1) {
                const pagaesPromises = [];

                for (let pageIndex = 2; pageIndex <= paginationStats.totalPages; pageIndex++) {
                    pagaesPromises.push(
                        new Promise<Attachment[]>(async resolve => {
                            const { attachments: nextAttachments } = await fetchAttachments({
                                claimPublicId,
                                attachmentTypes,
                                chatSessionId,
                                pageParam: pageIndex,
                            });

                            resolve(nextAttachments);
                        })
                    );
                }

                await Promise.all(pagaesPromises).then(res =>
                    res.forEach(attachmentsPage => attachments.push(...attachmentsPage))
                );
            }

            return attachments.map(att => camelToSnakeCaseKeys(att));
        },
    });
}

export function useUpdateAttachmentDetectionMutation(claimId: string): UseBaseMutationResult<
    void,
    unknown,
    {
        readonly attachmentDetectionPublicId: string;
        readonly manualFeedback: boolean | null;
    },
    null
> {
    return usePessimisticMutationLegacy(updateAttachmentDetection, [
        [CarClaimQueryKeys.GetCarClaimAttachments, claimId],
        [CarClaimQueryKeys.GetCarClaimAttachmentsPage, claimId],
    ]);
}

async function getTaskGroupNotes(groupPublicId: string): Promise<Interaction[]> {
    return await fetchTaskGroupNotes({ groupPublicId });
}

export function useCarClaimTaskGroupNotes(groupPublicId: string): UseQueryResult<Interaction[]> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarTaskGroupNotes, groupPublicId],
        queryFn: async () => await getTaskGroupNotes(groupPublicId),
    });
}

export function useActionDateTaskMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly taskId: string;
        readonly actionDate?: string;
        readonly status?: TaskStatus;
        readonly pendingReason?: PendingReason;
    },
    CarClaimWorkflowData
> {
    const queryKey = [CarClaimQueryKeys.GetCarClaimTasks, claimId];

    function mutateTasks({
        data,
        variables,
    }: {
        data: CarClaimWorkflowData;
        variables: { taskId: string; actionDate?: string; status?: TaskStatus; pendingReason?: PendingReason };
    }) {
        const { taskId, actionDate, status, pendingReason } = variables;

        return produce(data, draft => {
            const task = castDraft(
                findCamelCasedTask(draft.workflow.segments.flat(), task => task.publicId === taskId)
            );

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            task!.task.task.actionDate = actionDate;
            task!.task.task.overdue = false;

            if (status != null) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                task!.task.task.status = status;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                task!.task.task.pendingReason = pendingReason;
            }
        });
    }

    return useOptimisticMutation({
        mutationFn: updateTask,
        invalidateKeys: [queryKey],
        mutate: mutateTasks,
        mutateKey: queryKey,
    });
}

async function getItemsPayableCheck(claimId: string, itemsIds: string[]): Promise<AreItemsPayableResponse> {
    return await areItemsPayable({ claimId, itemsIds });
}

export function useItemsPayableCheck(claimId: string, itemsIds: string[]): UseQueryResult<AreItemsPayableResponse> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetPayableItemsCheck, claimId, itemsIds.join(',')],
        queryFn: async () => await getItemsPayableCheck(claimId, itemsIds),
        enabled: itemsIds.length > 0,
    });
}

async function getCarDemandEvaluations(claimId: string): Promise<CarClaimDemandEvaluationsData> {
    return await fetchCarClaimData(claimId, ['demandEvaluations']);
}

export function useCarDemandEvaluations(claimId: string): UseQueryResult<CarClaimDemandEvaluationsData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarDemandEvaluations, claimId],
        queryFn: async () => await getCarDemandEvaluations(claimId),
    });
}

export function useGetAuthorityRequestDetails(requestPublicId: string): UseQueryResult<PaymentRequestDetailsResponse> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetAuthorityRequestOptions, requestPublicId],
        queryFn: async () => await getAuthorityRequestDetails(requestPublicId),
    });
}

export function usePaymentRequestMutation(requestPublicId: string): UseBaseMutationResult<
    unknown,
    unknown,
    {
        readonly product: ProductTypes;
        readonly taskPublicId: string;
        readonly paymentRequestPublicId: string;
        readonly approved: boolean;
        readonly reviewerNotes: string;
        readonly claimItemIds: string;
        readonly claimPublicId: string;
        readonly operatorId: number;
        readonly requestedAmountInCents: number;
    },
    null
> {
    return usePessimisticMutationLegacy(handlePaymentRequest, [
        [CarClaimQueryKeys.GetAuthorityRequestOptions, requestPublicId],
    ]);
}

export function useIssuePaymentMutation(requestPublicId: string): UseBaseMutationResult<
    unknown,
    unknown,
    {
        readonly product: ProductTypes;
        readonly paymentRequestPublicId: string;
        readonly claimItemIds: string;
        readonly taskPublicId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(issuePayment, [
        [CarClaimQueryKeys.GetAuthorityRequestOptions, requestPublicId],
    ]);
}

async function getAttachmentOptionalRelations(claimId: string): Promise<CarClaimAttachmentOptionalRelationsData> {
    return await fetchCarClaimData(claimId, ['attachmentOptionalRelations']);
}

export function useAttachmentOptionalRelations(
    claimId: string
): UseQueryResult<CarClaimAttachmentOptionalRelationsData> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.getAttachmentOptionalRelations, claimId],
        queryFn: async () => await getAttachmentOptionalRelations(claimId),
    });
}

async function getCarClaimAttachmentsFiltersData(
    claimId: string
): Promise<Pick<CarClaimResponse, 'attachmentsFiltersData'>> {
    return await fetchCarClaimData(claimId, ['attachmentsFiltersData']);
}

export function useCarClaimAttachmentsFiltersData(
    claimId: string
): UseQueryResult<Pick<CarClaimResponse, 'attachmentsFiltersData'>> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetCarClaimAttachmentsFiltersData, claimId],
        queryFn: async () => await getCarClaimAttachmentsFiltersData(claimId),
    });
}

export async function getExportClaimPdfUrl(claimId: string): Promise<string> {
    const sections = await fetchCarClaimSectionsForExport().then(res => res);

    const claimSections = sections[ExportEntityType.CarClaim].options.map(section => section.key);

    return await fetchClaimPdfFile(claimId, claimSections);
}

export function useGetSubrogationAllowCloseClaim(claimId: string): UseQueryResult<boolean> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetSubrogationAllowCloseClaim, claimId],
        queryFn: async () => await getSubrogationAllowCloseClaim({ claimId }),
    });
}

export function useExemptMissingDetailsMutation(
    claimId?: string
): UseBaseMutationResult<void, unknown, { readonly request: SetExemptMissingDetailsRequest }, null> {
    return usePessimisticMutation({
        mutationFn: setExemptMissingDetails,
        invalidateKeys: [[CarClaimQueryKeys.GetCarClaimInvolvedParties, claimId]],
    });
}

export function useResetExemptMissingDetailsMutation(
    claimId?: string
): UseBaseMutationResult<void, unknown, { readonly request: ResetExemptMissingDetailsRequest }, null> {
    return usePessimisticMutation({
        mutationFn: resetExemptMissingDetails,
        invalidateKeys: [[CarClaimQueryKeys.GetCarClaimInvolvedParties, claimId]],
    });
}

export function useMedicareRiskReport(
    reportType: string,
    involvedPersonPublicId?: string
): UseQueryResult<MedicareRiskResponseResponse> {
    return useQuery({
        queryKey: [CarClaimQueryKeys.GetMedicareRiskReport, involvedPersonPublicId],
        queryFn: async () => await getMedicareRiskReport(involvedPersonPublicId ?? '', reportType),
        enabled: involvedPersonPublicId != null,
    });
}

export function useSetRiskReportSentMutation(
    involvedPersonPublicId?: string
): UseBaseMutationResult<void, unknown, { readonly request: RiskReportSentRequest }, null> {
    return usePessimisticMutation({
        mutationFn: setRiskReportSent,
        invalidateKeys: [[CarClaimQueryKeys.GetMedicareRiskReport, involvedPersonPublicId]],
    });
}