import { Button, Flex } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useGetAttachment } from '../../useGetAttachment';
import { archiveButton, backButton } from './TopActions.css';
import { hasAction } from 'components/Attachments/utils';
import { useCarousel } from '../../Carousel/CarouselProvider';

interface Props {
    readonly setShowArchive: (showArchive: boolean) => void;
}

export const TopActionsPanel: FC<Props> = ({ setShowArchive }) => {
    const attachment = useGetAttachment();
    const { onClose } = useCarousel();
    const docAssignedToEntity = attachment?.assignedToEntity ?? true;
    const hasArchiveAction = hasAction({ attachment, action: 'archive' });

    const onClickArchive = useCallback(() => {
        setShowArchive(true);
    }, [setShowArchive]);

    return (
        <Flex alignItems="center" justifyContent="space-between">
            <Button
                className={backButton}
                label="Back"
                onClick={onClose}
                size="sm"
                startIcon="chevron-down"
                variant="secondary"
            />
            {hasArchiveAction && (
                <Button
                    className={archiveButton}
                    disabled={attachment?.status === 'archived' || !docAssignedToEntity}
                    label="Archive"
                    onClick={onClickArchive}
                    startIcon="archive"
                    variant="secondary"
                />
            )}
        </Flex>
    );
};
