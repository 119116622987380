import type { TableItem } from '@lemonade-hq/blender-ui';
import { ActionsMenu, Button, Card, Flex, spacing, Table, Text } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { getFormattedDate } from '../common/helpers/dateHelpers';
import { Dialogs } from '../test-hub/TestHubDialogs';
import type { EditionSetEnvOverride } from 'models/LoCo/Insurance/EditionSets';
import { useSuspenseGetEditionSetEnvOverrides } from 'queries/LoCo/Insurance/EditionSetQueries';

const header = [
    { name: 'Environment', key: 'env' },
    { name: 'Activated', key: 'activated' },
    { name: 'Until', key: 'deactivated' },
    { name: '', key: 'actions', width: '50px' },
];

export const EditionSetTesting: FC<{ readonly editionSetCode: string }> = ({ editionSetCode }) => {
    const { data: envsOverridesResponse } = useSuspenseGetEditionSetEnvOverrides({ editionSetCodes: [editionSetCode] });
    const [dialogType, setDialogType] = useState<'changeExpiration' | 'reactivate' | 'remove' | undefined>();
    const [selectedEnv, setSelectedEnv] = useState<EditionSetEnvOverride | undefined>();
    const getActions = useCallback((env: EditionSetEnvOverride) => {
        if (new Date(env.expirationDate) > new Date()) {
            return [
                {
                    label: 'Remove from Environment',
                    onClick: () => {
                        setDialogType('remove');
                        setSelectedEnv(env);
                    },
                },
                {
                    label: 'Change Expiration in Environment',
                    onClick: () => {
                        setDialogType('changeExpiration');
                        setSelectedEnv(env);
                    },
                },
            ];
        }
    }, []);

    const getRow = useCallback(
        (override: EditionSetEnvOverride): Record<string, TableItem> => {
            const stillActive = new Date(override.expirationDate) >= new Date();
            const actions = getActions(override);

            return {
                env: { value: `${override.envName} (${override.envStage})` },
                activated: { value: getFormattedDate(override.activatedAt) },
                deactivated: {
                    value: stillActive
                        ? `Still active (ends ${getFormattedDate(override.expirationDate)})`
                        : getFormattedDate(override.expirationDate),
                },
                actions: { value: actions != null ? <ActionsMenu items={actions} /> : '' },
            };
        },
        [getActions]
    );

    const rows = useMemo(() => envsOverridesResponse.data.map(getRow), [envsOverridesResponse.data, getRow]);

    const onActivateClick = useCallback(() => {
        setDialogType('reactivate');
    }, []);

    return (
        <Flex flexDirection="column" pt={spacing.s32}>
            <Card p={spacing.s32}>
                <Flex alignItems="center" justifyContent="space-between" pb={spacing.s12}>
                    <Text type="h4">Configuration activation history in environment</Text>
                    <Button label="Activate in an Environment" onClick={onActivateClick} variant="primary" />
                </Flex>
                <Table columns={header} data={rows} emptyMessage="Edition set was not activated in any environment" />
            </Card>
            {dialogType != null && (
                <Dialogs
                    dialogType={dialogType}
                    editionSetCode={editionSetCode}
                    env={selectedEnv}
                    onClose={() => {
                        setDialogType(undefined);
                        setSelectedEnv(undefined);
                    }}
                />
            )}
        </Flex>
    );
};
