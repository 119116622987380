import { Layout, spacing } from '@lemonade-hq/blender-ui';
import { ErrorSection, IconButton, InverseButton, LinkIcon, LoadingSection } from '@lemonade-hq/bluis';
import { EntityTypes, withSectionErrorBoundary } from '@lemonade-hq/bluiza';
import { Tooltip } from '@lemonade-hq/cdk';
import { formatCurrency } from '@lemonade-hq/lemonation';
import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import AssignAttachmentDialog from './AssignAttachmentDialog';
import { homeClaimAttachmentsNoCard } from './HomeAttachments.css';
import ClaimAttachments from 'bluis/ClaimAttachments';
import { AttachmentHub } from 'components/Attachments/AttachmentHub';
import { defaultHeaderColumns } from 'components/Bluis/ClaimAttachments/types';
import HomeClaimContext from 'components/Claim/HomeClaimContext';
import { TrackContentLoaded } from 'components/CustomPageLoadMetric';
import type { Attachment, AttachmentToUpload, HomeClaimAttachmentItem } from 'models/Attachment';
import { AttachmentEntityType, AttachmentType } from 'models/Attachment';
import { ClaimItemType } from 'models/HomeClaim';
import {
    useGetAttachments,
    useGetAttachmentsRelatedData,
    useUpdateAttachment,
    useUploadClaimAttachments,
} from 'queries/HomeClaimQueries';
import { useSelector } from 'store/typedStore';

const ClaimAttachmentContainer = styled.div`
    margin-top: 20px;
`;

const ToolTipItemsList = styled.div`
    padding: 5px 15px;
`;

// bluis/ClaimAttachments.tsx expects a function that returns a promise
const getAttachmentTypes = async () =>
    await Promise.resolve([
        'acknowledgement',
        'adjuster_estimate',
        'attorney_correspondence',
        'authorization',
        'bylaws_copy',
        'claim_details',
        'claimant_correspondence',
        'claimant_demand',
        'contents_inventory',
        'contractor_estimate',
        'coverage_letter',
        'damage_photo',
        'damage_report',
        'defense_counsel',
        'email_trail',
        'fire_report',
        'government_document',
        'lease_copy',
        'letter_of_representation',
        'litigation_documents',
        'loss_of_use',
        'manual_payment',
        'other',
        'police_report',
        'proof_of_loss_subrogation',
        'property_damage_release',
        'property_docs',
        'public_adjuster_correspondence',
        'receipt',
        'reconstruction_documents',
        'recording',
        'refund',
        'supplement',
        'text_message_trail',
        'theft_inventory',
        'vendor_invoice',
        'SIU',
    ]);

const AttachmentsComponent: React.FC<
    React.PropsWithChildren<{
        readonly title?: string;
        readonly subtitle?: string;
        readonly showUnreviewedData?: boolean;
        readonly onCard?: boolean;
        readonly forceRenderLegacy?: boolean;
    }>
> = ({ title, subtitle }) => {
    const { claimPublicId } = useContext(HomeClaimContext);
    const [assignDialogOpen, setAssignDialogOpen] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(null);

    const {
        currencySettings: { currency },
    } = useSelector(state => state.currency);
    const {
        data: attachments,
        isLoading: isLoadingAttachments,
        isError: isErrorAttachments,
    } = useGetAttachments(claimPublicId);
    const {
        data: relatedData,
        isLoading: isLoadingRelatedData,
        isError: isErrorRelatedData,
    } = useGetAttachmentsRelatedData(claimPublicId);
    const { mutateAsync: uploadAttachments, isPending: isUploading } = useUploadClaimAttachments(claimPublicId);
    const { mutate: updateAttachment } = useUpdateAttachment(claimPublicId);
    const { timezone, claim_items } = relatedData ?? {};
    const { items } = claim_items ?? { items: [] };

    const lossItems = useMemo(() => items.filter(item => item.type === ClaimItemType.Loss), [items]);

    if (isLoadingAttachments || isLoadingRelatedData) {
        return <LoadingSection />;
    }

    if (isErrorAttachments || isErrorRelatedData) {
        return <ErrorSection />;
    }

    if (attachments == null || relatedData == null) {
        return null;
    }

    async function addAttachments(entityId: string, toUpload: AttachmentToUpload[]) {
        await uploadAttachments(toUpload);
    }

    async function handleDescriptionUpdate(attachmentId: string, description?: string | undefined) {
        await handleAttachmentUpdate(attachmentId, { description });
    }

    async function handleAttachmentUpdate(
        attachmentId: string,
        details: {
            description?: string | undefined;
            type?: string | undefined;
            claimItemIds?: string[] | undefined;
        }
    ) {
        await updateAttachment({ attachmentId, details });
    }

    function handleAssignClick(attachmentId?: string) {
        const [selectedAttachment] = attachments?.filter(attachment => attachment.file_public_id === attachmentId) ?? [
            null,
        ];

        setSelectedAttachment(selectedAttachment);
        setAssignDialogOpen(true);
    }

    function getRelatedItem(item: HomeClaimAttachmentItem) {
        const { title, valueInCents } = item;
        const priceValue = valueInCents / 100;
        const price = formatCurrency(priceValue, { currency });

        return (
            <div key={title}>
                {title} ({price})
            </div>
        );
    }

    function getHomeAttachmentsRows(attachment: Attachment) {
        const { claimItems = [], type } = attachment;

        const shouldShowRelatedItems = type === AttachmentType.PoliceReport || claimItems.length > 0;
        const relatedItems = shouldShowRelatedItems && (
            <div>{type === 'police_report' ? 'Police Report' : claimItems.map(getRelatedItem)}</div>
        );
        const isAttachmentAssignable =
            !shouldShowRelatedItems && type !== AttachmentType.HomePolicyPDF && type !== AttachmentType.ClaimVideo;

        return isAttachmentAssignable
            ? [
                  {
                      key: 'assign',
                      value: (
                          <InverseButton onClick={() => handleAssignClick(attachment.file_public_id)} size="small">
                              Assign
                          </InverseButton>
                      ),
                      width: 120,
                  },
              ]
            : shouldShowRelatedItems
              ? [
                    {
                        key: 'assign',
                        value: (
                            <Tooltip alignment="top" content={<ToolTipItemsList>{relatedItems}</ToolTipItemsList>}>
                                <IconButton onClick={() => handleAssignClick(attachment.file_public_id)} size="small">
                                    <LinkIcon />
                                </IconButton>
                            </Tooltip>
                        ),
                        width: 120,
                    },
                ]
              : [{ key: 'assign', value: '' }];
    }

    return (
        <ClaimAttachmentContainer>
            <TrackContentLoaded />
            <ClaimAttachments
                addAttachments={addAttachments}
                addAttachmentsLoading={isUploading}
                addedRows={attachment => getHomeAttachmentsRows(attachment)}
                attachments={attachments ?? []}
                columns={[...defaultHeaderColumns, 'assign']}
                entityId={claimPublicId}
                entityType={AttachmentEntityType.Claim}
                getAttachmentTypes={getAttachmentTypes}
                groupByType
                subtitle={subtitle}
                timezone={timezone ?? 'UTC'}
                title={title}
                updateAttachmentData={handleDescriptionUpdate}
            />
            {assignDialogOpen && (
                <AssignAttachmentDialog
                    claimItems={lossItems}
                    currency={currency}
                    id={selectedAttachment?.file_public_id}
                    onClose={() => setAssignDialogOpen(false)}
                    selectedAttachment={selectedAttachment}
                    updateAttachmentData={handleAttachmentUpdate}
                />
            )}
        </ClaimAttachmentContainer>
    );
};

const Hub: React.FC<{
    readonly showUnreviewedData?: boolean;
    readonly onCard?: boolean;
    readonly forceRenderLegacy?: boolean;
    readonly title?: string;
    readonly subtitle?: string;
}> = ({ showUnreviewedData, onCard, forceRenderLegacy, title, subtitle }) => {
    const { claimPublicId } = useContext(HomeClaimContext);

    if (forceRenderLegacy) {
        return <AttachmentsComponent onCard={onCard} subtitle={subtitle} title={title} />;
    }

    return (
        <>
            <TrackContentLoaded />
            <Layout mt={spacing.s20}>
                <AttachmentHub
                    className={onCard ? undefined : homeClaimAttachmentsNoCard}
                    entityId={claimPublicId}
                    entityType={EntityTypes.HomeClaim}
                    showUnreviewedData={showUnreviewedData}
                />
            </Layout>
        </>
    );
};

export const Attachments = withSectionErrorBoundary(Hub);
