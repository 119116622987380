import { toast } from '@lemonade-hq/bluis';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import type { QueryKey, UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import pluralize from 'pluralize';
import { blenderGeneral } from '../../apiClients';
import type { HubFilters } from './AttachmentHub/Filters';
import type { AttachmentDTO, AttachmentStats, AttachmentType, AttachmentTypeWithSubtypes } from './types';
import type { ScanStatusResult } from 'apis/FraudAPI';
import { PAGE_SIZE } from 'components/HOReviews/utils';
import { usePessimisticMutation } from 'queries/MutationHooks';

export enum GalleryAttachmentQueryKey {
    GetAttachments = 'GET_ATTACHMENTS',
    GetAttachmentsByIds = 'GET_ATTACHMENTS_BY_IDS',
    AttachmentTypes = 'ATTACHMENT_TYPES',
    AttachmentArchiveReasons = 'ATTACHMENT_ARCHIVE_REASONS',
    UpdateAttachmentDetails = 'UPDATE_ATTACHMENT_DETAILS',
    GetScanStatus = 'GET_SCAN_STATUS',
    GetAttachmentsFilters = 'GET_ATTACHMENTS_FILTERS',
}

// Attachments Types

export const getAttachmentTypes = async (entityType: EntityTypes): Promise<AttachmentTypeWithSubtypes[]> => {
    return await blenderGeneral
        .get<{ data: AttachmentType[] }>(`/api/v1/attachments/types?entityType=${entityType}`)
        .then(async res => res.data.data);
};

export const useGetAttachmentTypes = ({
    entityType,
}: {
    readonly entityType: EntityTypes;
}): UseQueryResult<AttachmentTypeWithSubtypes[]> => {
    return useQuery({
        queryKey: [GalleryAttachmentQueryKey.AttachmentTypes, entityType],
        queryFn: async () => await getAttachmentTypes(entityType),
        enabled: Boolean(entityType),
        staleTime: Infinity,
    });
};

// Attachments GET

export const getAttachments = async ({
    entityType,
    entityPublicId,
    includes,
    pageSize = 12,
    params,
}: {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly pageSize?: number;
    readonly includes?: string[];
    readonly params?: Partial<HubFilters>;
}): Promise<{ readonly data: AttachmentDTO[]; readonly stats: AttachmentStats }> => {
    const includesString =
        includes?.join(',') ??
        [
            'sources',
            'detections',
            'actions',
            'custom_data',
            'visual_media',
            'custom_additional_data',
            'ai_suggestions',
        ].join(',');
    const filteredParams = params
        ? Object.fromEntries(
              Object.entries(params).filter(([_, value]) => !(!value || (Array.isArray(value) && value.length === 0)))
          )
        : {};

    const queryParams = new URLSearchParams(filteredParams as Record<string, string>).toString();

    return await blenderGeneral
        .get<{
            data: AttachmentDTO[];
            stats: AttachmentStats;
        }>(
            `/api/v1/attachments?entityType=${entityType}&entityPublicId=${entityPublicId}&include=${includesString}&size=${pageSize}${queryParams !== '' ? `&${queryParams}` : ''}`
        )
        .then(res => res.data);
};

export const getAttachmentsByIds = async ({
    publicIds,
}: {
    readonly publicIds?: string[];
}): Promise<AttachmentDTO[]> => {
    const response = await blenderGeneral.get<{
        data: AttachmentDTO[];
    }>(`/api/v1/attachments/by_public_ids`, {
        params: { ids: publicIds },
    });

    return response.data.data;
};

interface GetAttachmentResponse {
    readonly data: AttachmentDTO[];
    readonly stats: AttachmentStats;
}

export const useGetAttachments = ({
    entityType,
    entityPublicId,
    pageSize = PAGE_SIZE,
    includes,
    params,
    enabled = true,
}: {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly pageSize?: number;
    readonly includes?: string[];
    readonly params?: Partial<HubFilters>;
    readonly enabled?: boolean;
}): UseQueryResult<GetAttachmentResponse> => {
    return useQuery({
        queryKey: [
            GalleryAttachmentQueryKey.GetAttachments,
            entityPublicId,
            entityType,
            ...(includes ?? []),
            ...(params ? Object.entries(params) : []),
            pageSize,
        ],
        queryFn: async () => await getAttachments({ entityType, entityPublicId, includes, pageSize, params }),
        placeholderData: keepPreviousData,
        enabled,
    });
};

export const useGetAttachmentsByIds = ({
    publicIds,
    enabled = true,
}: {
    readonly publicIds?: string[];
    readonly enabled?: boolean;
}): UseQueryResult<AttachmentDTO[]> => {
    return useQuery({
        queryKey: [GalleryAttachmentQueryKey.GetAttachmentsByIds, publicIds],
        queryFn: async () => await getAttachmentsByIds({ publicIds }),
        enabled,
    });
};

// Archive reasons

const getArchiveReasons = async (): Promise<string[]> => {
    return await blenderGeneral
        .get<{ data: string[] }>('/api/v1/attachments/archive_reasons')
        .then(res => res.data.data);
};

export const useAttachmentArchiveReasons = (): UseQueryResult<string[]> => {
    return useQuery({
        queryKey: [GalleryAttachmentQueryKey.AttachmentArchiveReasons],
        queryFn: async () => await getArchiveReasons(),
        staleTime: Infinity,
    });
};

// Update attachment details

export type AttachmentDetailsData = {
    readonly attachmentPublicId: string;
    readonly type?: string;
    readonly subtype?: string;
    readonly description?: string;
};

export const useSubmitAttachmentDetails = ({
    entityPublicId,
    entityType,
    invalidateKeys,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly invalidateKeys?: QueryKey[];
}): UseMutationResult<
    null,
    unknown,
    {
        readonly attachmentsData: AttachmentDetailsData[];
    },
    null
> => {
    return usePessimisticMutation({
        mutationFn: async ({ attachmentsData }: { attachmentsData: AttachmentDetailsData[] }) => {
            return await blenderGeneral.patch('/api/v1/attachments/bulk', {
                entityPublicId,
                entityType,
                attachments: attachmentsData,
            });
        },
        invalidateKeys: [
            [GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType],
            ...(invalidateKeys ?? []),
        ],
        onSuccess: (_, variables) => {
            const ids = variables.attachmentsData.map(attachment => attachment.attachmentPublicId);

            toast.success(
                `Successfully updated, the ${pluralize('attachment', ids.length)} ${pluralize('has', ids.length)} moved to the main section.`
            );
        },
    });
};

// Archive attachment

type ArchiveAttachment = {
    readonly reason: string;
    readonly attachmentPublicId: string;
};

export const useArchiveAttachment = ({
    entityPublicId,
    entityType,
    invalidateKeys,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly invalidateKeys?: QueryKey[];
}): UseMutationResult<null, unknown, ArchiveAttachment[], null> => {
    return usePessimisticMutation({
        mutationFn: async (attachmentList: ArchiveAttachment[]) => {
            return await blenderGeneral.post('/api/v1/attachments/archive', {
                entityPublicId,
                entityType,
                attachments: attachmentList,
            });
        },
        invalidateKeys: [
            [GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType],
            ...(invalidateKeys ?? []),
        ],
        onSuccess: (_, variables) => {
            const ids = variables.map(attachment => attachment.attachmentPublicId);

            toast.success(
                `Successfully archived, the ${pluralize('attachment', ids.length)} ${pluralize('has', ids.length)} moved to the archived section.`
            );
        },
    });
};

// Un-archive attachment

export const useUnArchiveAttachment = ({
    entityPublicId,
    entityType,
    invalidateKeys,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly invalidateKeys?: QueryKey[];
}): UseMutationResult<null, unknown, { readonly attachmentPublicId: string }[], null> => {
    return usePessimisticMutation({
        mutationFn: async (attachmentIds: { attachmentPublicId: string }[]) => {
            return await blenderGeneral.post('/api/v1/attachments/unarchive', {
                entityPublicId,
                entityType,
                attachments: attachmentIds,
            });
        },
        invalidateKeys: [
            [GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType],
            ...(invalidateKeys ?? []),
        ],
        onSuccess: (_, variables) => {
            const ids = variables.map(attachment => attachment.attachmentPublicId);

            toast.success(
                `Successfully un-archived, the ${pluralize('attachment', ids.length)} ${pluralize('has', ids.length)} moved to the main section.`
            );
        },
    });
};

// Dismiss archive suggestion

export const useDismissArchiveSuggestion = ({
    entityPublicId,
    entityType,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
}): UseMutationResult<null, unknown, { readonly attachmentPublicId: string }, null> => {
    return usePessimisticMutation({
        mutationFn: async ({ attachmentPublicId }: { attachmentPublicId: string }) => {
            return await blenderGeneral.post(`/api/v1/attachments/${attachmentPublicId}/dismiss_archive_suggestion`, {
                entityPublicId,
                entityType,
            });
        },
        invalidateKeys: [[GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType]],
    });
};

// Scan fraud

export const useScanFraud = (
    entityPublicId: string,
    entityType: EntityTypes
): UseMutationResult<null, unknown, { readonly attachmentPublicId: string; readonly productLine?: string }, null> => {
    return usePessimisticMutation({
        mutationFn: async (body: { attachmentPublicId: string; productLine?: string }) => {
            return await blenderGeneral.post(`/api/v2/fraud_detection`, { ...body, model: 'resistant_ai' });
        },
        invalidateKeys: [[GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType]],
    });
};

export const getScanStatus = async (attachmentPublicIds: string[]): Promise<ScanStatusResult[]> => {
    const url = `/api/v1/fraud_detection?attachmentPublicId=${attachmentPublicIds.join(',')}`;

    return await blenderGeneral.get(url).then(res => res.data?.data);
};

export const useScanStatusQuery = (
    attachmentPublicIds: string[],
    entityPublicId: string,
    entityType: EntityTypes,
    onSuccess: (data: ScanStatusResult[]) => void,
    onError: () => void
): UseQueryResult<ScanStatusResult[] | null> => {
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: [GalleryAttachmentQueryKey.GetScanStatus, attachmentPublicIds],
        queryFn: async () => {
            const response = await getScanStatus(attachmentPublicIds).catch(() => {
                onError();
                throw new Error('Failed to fetch scan status');
            });

            const resistantAiStatusesCompleted = response.filter(
                status => status.detectionModel === 'resistant_ai' && status.detectionStatus === 'completed'
            );

            const shouldInvalidateAttachments = resistantAiStatusesCompleted.some(({ attachmentPublicId }) =>
                attachmentPublicIds.includes(attachmentPublicId)
            );

            if (shouldInvalidateAttachments) {
                void queryClient.invalidateQueries({
                    queryKey: [GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType],
                });
            }

            onSuccess(response);
        },
        refetchInterval: 5000,
        enabled: attachmentPublicIds.length > 0,
        placeholderData: keepPreviousData,
    });
};

// Upload attachment
export type UploadAttachmentData = {
    readonly filePublicId: string;
    readonly type?: string;
    readonly subtype?: string;
    readonly description?: string;
};

export const useAddAttachments = ({
    entityPublicId,
    entityType,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
}) => {
    return usePessimisticMutation({
        mutationFn: async (attachments: UploadAttachmentData[]) => {
            return await blenderGeneral.post('/api/v1/attachments/bulk', { entityPublicId, entityType, attachments });
        },
        invalidateKeys: [
            [GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType],
            [GalleryAttachmentQueryKey.AttachmentArchiveReasons, entityType, entityPublicId],
        ],
    });
};

// Attachment filters
export interface AttachmentFilterOption {
    readonly label: string;
    readonly value: string;
}

export interface AttachmentFilter {
    readonly name: string;
    readonly displayValue?: string;
    readonly label?: string;
    readonly options: AttachmentFilterOption[];
}

const getFilters = async ({
    entityType,
    entityPublicId,
    filters,
}: {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly filters?: Partial<HubFilters>;
}): Promise<AttachmentFilter[]> => {
    const filteredParams = filters
        ? Object.fromEntries(
              Object.entries(filters).filter(([_, value]) => !(!value || (Array.isArray(value) && value.length === 0)))
          )
        : {};

    const queryParams = new URLSearchParams(filteredParams as Record<string, string>).toString();

    return await blenderGeneral
        .get<{
            data: AttachmentFilter[];
        }>(
            `/api/v1/attachments/filters?entityType=${entityType}&entityPublicId=${entityPublicId}${queryParams !== '' ? `&${queryParams}` : ''}`
        )
        .then(res => res.data.data);
};

export const useGetAttachmentsFilters = ({
    entityType,
    entityPublicId,
    filters,
}: {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly filters?: Partial<HubFilters>;
}): UseQueryResult<AttachmentFilter[]> => {
    return useQuery({
        queryKey: [GalleryAttachmentQueryKey.AttachmentArchiveReasons, entityType, entityPublicId, filters],
        queryFn: async () => await getFilters({ entityType, entityPublicId, ...(filters && { filters }) }),
        staleTime: Infinity,
        placeholderData: keepPreviousData,
    });
};

// Ai Suggestion

export type AiSuggestionFeedback = {
    readonly publicId: string;
    readonly accepted: boolean;
    readonly alternativeValue: string;
};

export const useSuggestionFeedback = ({
    entityType,
    entityPublicId,
}: {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
}): UseMutationResult<
    null,
    unknown,
    { readonly attachmentPublicId: string; readonly feedback: AiSuggestionFeedback[] },
    null
> => {
    return usePessimisticMutation({
        mutationFn: async ({
            attachmentPublicId,
            feedback,
        }: {
            attachmentPublicId: string;
            feedback: AiSuggestionFeedback[];
        }) => {
            return await blenderGeneral.patch(`/api/v1/attachments/${attachmentPublicId}/ai_suggestions`, {
                aiSuggestions: feedback,
            });
        },
        invalidateKeys: [[GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType]],
    });
};
