import type { RowItem } from '@lemonade-hq/bluis';
import { TableHeader as BTableHeader, EmptySection, Table, TableRow } from '@lemonade-hq/bluis';
import { font, themedColor } from '@lemonade-hq/boutique';
import { capitalize, snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import React, { useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import AttachmentsPreview from '../AttachmentsPreview';
import { CommonSection, CommonSectionTitle } from '../CommonSection';
import AddAttachments from './AddAttachments';
import AttachmentRow from './AttachmentRow';
import { buildGroupedAttachments } from './helpers';
import type { AttachmentColumn } from './types';
import { defaultHeaders, headers } from './types';
import type { AttachmentType as NewAttachmentType } from 'components/Attachments/types';
import type { Attachment, AttachmentEntityType, AttachmentToUpload, AttachmentType } from 'models/Attachment';

export const EmptyAttachmentsSection = styled(EmptySection)`
    margin-top: 24px;
`;

const StyledCommonSectionTitle = styled(CommonSectionTitle)`
    padding-bottom: 14px;
`;

export const GroupTableRow = styled(TableRow)`
    width: 100%;
    background-color: ${themedColor('elementBackground')};
    border-left: 1px solid ${themedColor('separator')};
    border-right: 1px solid ${themedColor('separator')};

    td {
        padding: 0 20px;
        height: 30px;
    }
`;

export const SeparatorText = styled.p`
    padding: 8px 0;
    margin: 0;
    ${font('main', { fontSize: '12px', lineHeight: 'normal', fontWeight: 'normal' })}
    text-transform: uppercase;
    color: ${themedColor('primaryText')};
`;

export const TableHeader = styled(BTableHeader)<{ readonly headers?: AttachmentColumn[] }>`
    grid-template-columns: 70px repeat(${({ headers }) => headers.length - 1}, 1fr);
`;

export type EntityAttachmentsProps = {
    readonly entityType: AttachmentEntityType.Claim | AttachmentEntityType.Preapproval;
    readonly attachments: Attachment[];
    readonly policyAttachments?: Attachment[];
    readonly timezone: string;
    readonly columns?: string[];
    readonly addedRows?: (attachment: Attachment) => RowItem[];
    readonly groupByType?: boolean;
    readonly title?: string;
    readonly subtitle?: string;
} & (withAdd | withoutAdd) &
    (withEdit | withOutEdit);

export type withAdd = {
    readonly getAttachmentTypes: () => Promise<AttachmentType[] | NewAttachmentType[] | string[]>;
    readonly addAttachments: (entityId: string, toUpload: AttachmentToUpload[]) => Promise<Attachment[] | null | void>;
    readonly addAttachmentsLoading: boolean;
    readonly entityId: string;
    readonly lightBoxTitle?: string;
};

export type withoutAdd = {
    readonly getAttachmentTypes?: never;
    readonly addAttachments?: never;
    readonly addAttachmentsLoading?: never;
    readonly entityId?: never;
};

export type withEdit = {
    readonly updateAttachmentData?: (attachmentId: string, description?: string) => Promise<void>;
};
export type withOutEdit = { readonly updateAttachmentData?: never };

const EntityAttachments: React.FC<React.PropsWithChildren<EntityAttachmentsProps>> = ({
    entityType,
    attachments,
    timezone,
    policyAttachments = [],
    updateAttachmentData,
    columns,
    addedRows,
    groupByType,
    title = 'Attachments',
    subtitle,
    ...withAddProps
}) => {
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showAttachmentsDialog, setShowAttachmentsDialog] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { ref: attachmentsSectionRef, inView } = useInView();

    const allAttachments = [...attachments, ...policyAttachments] as Attachment[];

    function hideAddDialog() {
        setShowAddDialog(false);
    }

    function onAddClicked() {
        setShowAddDialog(true);
    }

    function showAttachmentsPreview(index: number) {
        setShowAttachmentsDialog(true);
        setSelectedIndex(index);
    }

    function closeAttachmentPreview() {
        setShowAttachmentsDialog(false);
    }

    const columnsHeaders = columns
        ? headers.filter((header: { key: string }) => columns.includes(header.key))
        : defaultHeaders;

    const attachmentsRows = useMemo(() => {
        return (
            <>
                <GroupTableRow
                    row={[
                        {
                            key: 'entity-name',
                            colSpan: columnsHeaders.length + 1,
                            value: <SeparatorText>{capitalize(entityType)} Attachments</SeparatorText>,
                        },
                    ]}
                />
                {attachments.map((attachment, index) => (
                    <AttachmentRow
                        addedRows={addedRows}
                        attachment={attachment}
                        columns={columns}
                        inView={inView}
                        key={attachment.file_public_id}
                        showAttachmentsPreview={() => {
                            showAttachmentsPreview(index);
                        }}
                        timezone={timezone}
                        updateAttachmentData={updateAttachmentData}
                    />
                ))}
            </>
        );
    }, [addedRows, attachments, columns, columnsHeaders.length, entityType, inView, timezone, updateAttachmentData]);

    const groupAttachmentsRows = useMemo(() => {
        const groups = buildGroupedAttachments(attachments);

        return groups.map(group => (
            <React.Fragment key={group.type}>
                <GroupTableRow
                    row={[
                        {
                            key: 'group-name',
                            colSpan: columnsHeaders.length + 1,
                            value: <SeparatorText>{capitalize(snakeCaseToReadable(group.displayName))}</SeparatorText>,
                        },
                    ]}
                />
                {group.items.map(attachment => {
                    return (
                        <AttachmentRow
                            addedRows={addedRows}
                            attachment={attachment}
                            columns={columns}
                            inView={inView}
                            key={attachment.file_public_id}
                            showAttachmentsPreview={() => {
                                showAttachmentsPreview(attachment.index);
                            }}
                            timezone={timezone}
                            updateAttachmentData={updateAttachmentData}
                        />
                    );
                })}
            </React.Fragment>
        ));
    }, [addedRows, attachments, columns, columnsHeaders.length, inView, timezone, updateAttachmentData]);

    return (
        <CommonSection ref={attachmentsSectionRef}>
            <StyledCommonSectionTitle
                actions={withAddProps.addAttachments ? { onClick: onAddClicked, text: 'ADD' } : undefined}
                subtitle={subtitle}
                title={title}
            />
            {attachments.length === 0 && (
                <EmptyAttachmentsSection>
                    {`NO ATTACHMENTS WERE UPLOADED FOR THIS ${entityType.toUpperCase()}`}
                </EmptyAttachmentsSection>
            )}
            {attachments.length > 0 && (
                <Table>
                    <TableHeader {...{ headers: columnsHeaders }} />
                    {groupByType ? groupAttachmentsRows : attachmentsRows}

                    {policyAttachments.length > 0 && (
                        <>
                            <GroupTableRow
                                row={[
                                    {
                                        key: 'entity-name',
                                        colSpan: columnsHeaders.length + 1,
                                        value: <SeparatorText>Policy Attachments</SeparatorText>,
                                    },
                                ]}
                            />
                            {policyAttachments.map((attachment, index) => (
                                <AttachmentRow
                                    addedRows={addedRows}
                                    attachment={attachment}
                                    columns={columns}
                                    inView={inView}
                                    key={attachment.file_public_id}
                                    showAttachmentsPreview={() => {
                                        showAttachmentsPreview(attachments.length + index);
                                    }}
                                    timezone={timezone}
                                    updateAttachmentData={updateAttachmentData}
                                />
                            ))}
                        </>
                    )}
                </Table>
            )}
            {showAddDialog && withAddProps.addAttachments && (
                <AddAttachments
                    addAttachments={withAddProps.addAttachments}
                    addAttachmentsLoading={withAddProps.addAttachmentsLoading}
                    close={hideAddDialog}
                    entityId={withAddProps.entityId}
                    getAttachmentTypes={withAddProps.getAttachmentTypes}
                    lightBoxTitle={withAddProps.lightBoxTitle}
                />
            )}
            {showAttachmentsDialog && (
                <AttachmentsPreview
                    attachments={allAttachments}
                    onClose={closeAttachmentPreview}
                    selectedIndex={selectedIndex}
                />
            )}
        </CommonSection>
    );
};

export { EntityAttachments as default, headers };
