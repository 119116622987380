/* eslint-disable jsx-a11y/media-has-caption, @typescript-eslint/no-non-null-assertion,  react/function-component-definition */
import type { PropsWithChildren, ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { AudioPlayerEvent, AudioPlayerPresentedEvent } from './AudioPlayer.EventsProvider.types';

export type AudioPlayerEventPresenter<TEvent extends AudioPlayerEvent> = (
  event: TEvent,
  index: number,
) => AudioPlayerPresentedEvent;

export interface AudioPlayerEventsProviderProps<TEvent extends AudioPlayerEvent = AudioPlayerEvent> {
  readonly events: readonly TEvent[];
  readonly eventPresenter?: AudioPlayerEventPresenter<NoInfer<TEvent>>;
}

export interface AudioPlayerEventsContextProps<TEvent extends AudioPlayerEvent = AudioPlayerEvent> {
  readonly events: readonly TEvent[];
  readonly eventPresenter?: AudioPlayerEventPresenter<TEvent>;
  readonly totalEventsTime: number;
}

export const DEFAULT_AUDIO_PLAYER_EVENTS_CONTEXT = {
  events: [],
  eventPresenter: () => ({ timestamp: 0 }),
  totalEventsTime: 0,
};

export const AudioPlayerEventsContext = createContext<AudioPlayerEventsContextProps | null>(
  DEFAULT_AUDIO_PLAYER_EVENTS_CONTEXT,
);

export function AudioPlayerEventsProvider<TEvent extends AudioPlayerEvent = AudioPlayerEvent>({
  children,
  events,
  eventPresenter,
}: PropsWithChildren<AudioPlayerEventsProviderProps<TEvent>>): ReactNode {
  const value = useMemo(
    () => ({
      events,
      eventPresenter,
      totalEventsTime: Math.max(...events.map(event => event.timestamp)),
    }),
    [events, eventPresenter],
  );

  return <AudioPlayerEventsContext.Provider value={value}>{children}</AudioPlayerEventsContext.Provider>;
}

export function useAudioPlayerEvents<
  TEvent extends AudioPlayerEvent = AudioPlayerEvent,
>(): AudioPlayerEventsContextProps<TEvent> {
  const context = useContext(AudioPlayerEventsContext) as AudioPlayerEventsContextProps<TEvent> | null;

  if (context == null) {
    throw new Error('useAudioPlayerEvents must be used within a AudioPlayerEventsProvider');
  }

  return context;
}
