import { Layout, spacing } from '@lemonade-hq/blender-ui';
import { EntityTypes, withSectionErrorBoundary } from '@lemonade-hq/bluiza';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { getAttachmentTypes } from 'apis/CarAttachmentsAPI';
import PageWrapper from 'bluis/PageWrapper';
import CarClaimContext from 'car/CarClaimContext';
import { AttachmentHub } from 'components/Attachments/AttachmentHub';
import { isNewAttachmentsEntityType } from 'components/Attachments/utils';
import AttachmentsPaginated from 'components/car/carShared/AttachmentsPaginated';
import { TrackContentLoaded } from 'components/CustomPageLoadMetric';
import useAttachmentUpload from 'hooks/useAttachmentUpload';
import type { Attachment, AttachmentType } from 'models/Attachment';
import { AttachmentEntityType } from 'models/Attachment';
import {
    useCarClaim,
    useCarClaimCreateAttachmentsMutation,
    useCarClaimUpdateAttachmentMutation,
} from 'queries/CarClaimQueries';

const StyledPageWrapper = styled(PageWrapper)`
    margin-top: 0;
`;

const CarClaimAttachmentsTable: React.FC<React.PropsWithChildren<{ readonly forceRenderLegacy?: boolean }>> = ({
    forceRenderLegacy,
}) => {
    const { claimId } = useContext(CarClaimContext);
    const { data } = useCarClaim(claimId);
    const { upload, isUploading } = useAttachmentUpload(
        AttachmentEntityType.Claim,
        'car/claim_attachments',
        createAttachments
    );
    const { mutateAsync: createAttachmentsMutation } = useCarClaimCreateAttachmentsMutation(claimId);
    const { mutateAsync: updateAttachmentMutation } = useCarClaimUpdateAttachmentMutation(claimId);

    if (!data) return null;

    const { timezone = 'UTC' } = data;

    async function createAttachments(
        entityId: string,
        entityType: AttachmentEntityType,
        attachments: {
            type: AttachmentType;
            filePublicId: string;
            contentType: string;
            description?: string;
        }[]
    ): Promise<Attachment[] | undefined> {
        return await createAttachmentsMutation({ entityId, entityType, attachments });
    }

    async function updateAttachment(attachmentId: string, description?: string) {
        await updateAttachmentMutation({ attachments: [{ publicId: attachmentId, description }] });
    }

    return (
        <StyledPageWrapper>
            {claimId && (
                <>
                    <TrackContentLoaded />
                    {isNewAttachmentsEntityType(EntityTypes.CarClaim) && !forceRenderLegacy ? (
                        <Layout pt={spacing.s20}>
                            <AttachmentHub entityId={claimId} entityType={EntityTypes.CarClaim} />
                        </Layout>
                    ) : (
                        <AttachmentsPaginated
                            addAttachments={upload}
                            addAttachmentsLoading={isUploading}
                            claimPublicId={data.claim.publicId}
                            entityId={claimId}
                            getAttachmentTypes={getAttachmentTypes}
                            timezone={timezone}
                            updateAttachmentData={updateAttachment}
                        />
                    )}
                </>
            )}
        </StyledPageWrapper>
    );
};

const CarClaimAttachments = withSectionErrorBoundary(CarClaimAttachmentsTable);

export default CarClaimAttachments;
