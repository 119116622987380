import {
    ActionsMenu,
    Button,
    Card,
    ComboBox,
    darkThemeClass,
    Flex,
    Icon,
    IconButton,
    LabelValueTable,
    spacing,
    Text,
    TextArea,
} from '@lemonade-hq/blender-ui';
import { useAnalytics } from '@lemonade-hq/boutique';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useGetAttachment } from '../../useGetAttachment';
import { acceptButton, rejectButton, typeCombobox } from './details.css';
import type { UseAttachmentSuggestionsReturnType } from './useAttachmentSuggestions';
import { useAttachmentSuggestions } from './useAttachmentSuggestions';
import type { UseUpdateDetailsReturnType } from 'components/Attachments/ActionHooks/useUpdateDetails';
import { useUpdateDetails } from 'components/Attachments/ActionHooks/useUpdateDetails';
import { useAttachmentsData } from 'components/Attachments/context';
import { Filename } from 'components/Attachments/Filename';
import type { AttachmentDTO, AttachmentType } from 'components/Attachments/types';
import { getAttachmentAnalyticsParam, hasAction } from 'components/Attachments/utils';

const FeedbackButtons: FC<{
    readonly onClick: (value: boolean) => void;
    readonly accepted?: boolean | null;
}> = ({ onClick, accepted }) => {
    return (
        <Flex>
            <IconButton
                className={acceptButton({ selected: accepted === true })}
                icon="check"
                iconSize="lg"
                onClick={() => onClick(true)}
                size="lg"
                variant="secondary"
            />
            <IconButton
                className={rejectButton({ selected: accepted === false })}
                icon="x"
                iconSize="lg"
                onClick={() => onClick(false)}
                size="lg"
                variant="secondary"
            />
        </Flex>
    );
};

interface GalleryAttachmentDetailsProps {
    readonly onSubmit?: () => void;
}

const EditMode: FC<{
    readonly attachment: AttachmentDTO;
    readonly updateAttachmentHook: UseUpdateDetailsReturnType;
    readonly attachmentSuggestions: UseAttachmentSuggestionsReturnType;
    readonly onSubmit: GalleryAttachmentDetailsProps['onSubmit'];
    readonly setEditMode: (value: boolean) => void;
}> = ({ attachment, onSubmit, setEditMode, updateAttachmentHook, attachmentSuggestions }) => {
    const { entityType, entityPublicId } = useAttachmentsData();
    const { fileName, publicId } = attachment;
    const {
        types,
        subtypes,
        type: selectedType,
        subtype: selectedSubtype,
        description: selectedDescription,
        isTypesError,
        isTypesLoading,
        isSubmitPending,
        isSubmitError,
        handleSelectionChange,
        handleSubtypeSelectionChange,
        setDescription,
        handleSubmit: submitAttachmentDetails,
    } = updateAttachmentHook;

    const { typeSuggestion, descriptionSuggestion, feedback, handleFeedback, submitSuggestionFeedback, getTypeValue } =
        attachmentSuggestions;

    const { trackEvent } = useAnalytics();
    const params = getAttachmentAnalyticsParam({
        attachment,
        entityType,
        entityId: entityPublicId,
    });

    const handleSubmit = useCallback(async () => {
        /* eslint-disable @typescript-eslint/naming-convention */
        trackEvent('docs.clicked', {
            ...params,
            doc_type: selectedType?.value ?? '',
            doc_subtype: selectedSubtype?.value ?? '',
            doc_description: selectedDescription,
            action: 'submit',
            action_type: 'relabel',
            source: 'gallery',
            is_bulk: 'false',
            platform: 'gallery',
        });
        /* eslint-enable @typescript-eslint/naming-convention */
        setEditMode(false);
        onSubmit?.();
        const attachmentsData = [
            {
                attachmentPublicId: publicId,
                ...(selectedType != null && { type: selectedType.value }),
                ...(selectedSubtype != null && { subtype: selectedSubtype.value }),
                ...(selectedDescription !== '' && { description: selectedDescription }),
            },
        ];

        if (feedback != null) {
            const feedbackToSubmit = feedback.map(it => ({
                publicId: it.publicId,
                accepted: it.accepted ?? false,
                alternativeValue:
                    it.accepted === false
                        ? it.field === 'type'
                            ? selectedType?.value ?? ''
                            : selectedDescription
                        : '',
            }));
            await submitSuggestionFeedback({
                feedback: feedbackToSubmit,
                attachmentPublicId: publicId,
            });
        }

        await submitAttachmentDetails({
            attachmentsData,
        });
    }, [
        trackEvent,
        params,
        selectedType,
        selectedSubtype,
        selectedDescription,
        setEditMode,
        onSubmit,
        publicId,
        feedback,
        submitAttachmentDetails,
        submitSuggestionFeedback,
    ]);
    const disabled =
        selectedType == null ||
        selectedDescription.trim() === '' ||
        (subtypes != null && selectedSubtype == null) ||
        feedback?.some(it => it.accepted == null);

    return (
        <Card p={spacing.s16}>
            <Flex flexDirection="column" gap={spacing.s20}>
                <Flex flexDirection="column" gap={spacing.s12}>
                    <Text fontWeight="semi-bold" type="text-lg">
                        File information
                    </Text>
                    <LabelValueTable
                        columnCount={1}
                        data={[
                            {
                                label: 'Filename',
                                value: <Filename fileName={fileName} />,
                            },
                        ]}
                        labelWidth="100px"
                    />
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <Text color="primary" type="label-sm">
                            Type
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                            {feedback !== null && typeSuggestion != null && typeSuggestion.accepted == null && (
                                <Icon name="sparkles" />
                            )}
                        </Text>
                        <Flex flexDirection="column" gap={spacing.s06}>
                            <Flex className={typeCombobox} gap={spacing.s10} justifyContent="space-between">
                                <ComboBox
                                    defaultValue={selectedType?.label ?? undefined}
                                    disabled={
                                        isTypesLoading || feedback?.find(it => it.field === 'type')?.accepted === true
                                    }
                                    items={types}
                                    onSelectionChange={handleSelectionChange}
                                    optionsClassName={darkThemeClass}
                                    placeholder="Select attachment type"
                                />
                                {feedback !== null && typeSuggestion != null && typeSuggestion.accepted == null && (
                                    <FeedbackButtons
                                        accepted={feedback.find(it => it.field === 'type')?.accepted}
                                        onClick={value => {
                                            handleFeedback(value, 'type');
                                            handleSelectionChange(getTypeValue(value));
                                            // if type suggestion is rejected, should empty description
                                            if (!value) {
                                                setDescription('');
                                            }
                                        }}
                                    />
                                )}
                            </Flex>
                            {isTypesError && (
                                <Text color="error" type="label-sm">
                                    Error loading attachment types
                                </Text>
                            )}
                        </Flex>
                    </Flex>
                    {subtypes && (
                        <Flex flexDirection="column" gap={spacing.s06}>
                            <Text color="primary" type="label-sm">
                                Subtype
                                <Text as="span" color="error">
                                    &nbsp;*
                                </Text>
                            </Text>
                            <Flex flexDirection="column" gap={spacing.s06}>
                                <ComboBox
                                    defaultValue={selectedSubtype?.label ?? undefined}
                                    disabled={isTypesLoading}
                                    items={subtypes}
                                    onSelectionChange={handleSubtypeSelectionChange}
                                    optionsClassName={darkThemeClass}
                                    placeholder="Select attachment subtype"
                                />
                            </Flex>
                        </Flex>
                    )}
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <Text color="primary" type="label-sm">
                            Description
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                            {descriptionSuggestion != null &&
                                descriptionSuggestion.accepted == null &&
                                feedback !== null &&
                                feedback.find(it => it.field === 'type')?.accepted !== false && (
                                    <Icon name="sparkles" />
                                )}
                        </Text>
                        <Flex gap={spacing.s10} justifyContent="space-between">
                            <TextArea
                                autoExpanding
                                disabled={feedback?.find(it => it.field === 'description')?.accepted === true}
                                maxLength={500}
                                onChange={e => setDescription(e.target.value)}
                                rows={5}
                                value={selectedDescription}
                            />
                            {feedback !== null &&
                                descriptionSuggestion != null &&
                                descriptionSuggestion.accepted == null &&
                                feedback.find(it => it.field === 'type')?.accepted !== false && (
                                    <FeedbackButtons
                                        accepted={feedback.find(it => it.field === 'description')?.accepted}
                                        onClick={value => {
                                            handleFeedback(value, 'description');
                                            setDescription(descriptionSuggestion.value ?? '');
                                        }}
                                    />
                                )}
                        </Flex>
                    </Flex>
                </Flex>
                {isSubmitError && (
                    <Text color="error" type="label-sm">
                        Error updating attachment details
                    </Text>
                )}

                <Button
                    disabled={disabled}
                    label="Save"
                    loading={isSubmitPending}
                    onClick={handleSubmit}
                    size="md"
                    variant="primary"
                />
            </Flex>
        </Card>
    );
};

const LabelValueMode: FC<{
    readonly fileName: string;
    readonly type: AttachmentType;
    readonly subtype?: AttachmentType | null;
    readonly description: string;
    readonly setEditMode?: (value: boolean) => void;
}> = ({ fileName = '', type, subtype, description = '', setEditMode }) => {
    const data = [
        {
            label: 'Filename',
            value: <Filename fileName={fileName} />,
        },
        { label: 'Type', value: type.label },
        ...(subtype != null ? [{ label: 'Subtype', value: subtype.label }] : []),
        { label: 'Description', value: description },
    ];

    return (
        <Card p={spacing.s16}>
            <Flex alignItems="center" justifyContent="space-between" mb={spacing.s12}>
                <Text fontWeight="semi-bold" type="text-lg">
                    File information
                </Text>
                {setEditMode && (
                    <ActionsMenu
                        className={darkThemeClass}
                        items={[{ label: 'Edit', id: 'edit', onClick: () => setEditMode(true) }]}
                    />
                )}
            </Flex>
            <LabelValueTable columnCount={1} data={data} labelWidth="100px" />
        </Card>
    );
};

export const GalleryAttachmentDetails: FC<GalleryAttachmentDetailsProps> = ({ onSubmit }) => {
    const attachment = useGetAttachment();
    const { entityType, entityPublicId, invalidateKeys } = useAttachmentsData();
    const { fileName, type, subtype, description = '' } = attachment ?? {};
    const attachmentSuggestions = useAttachmentSuggestions(attachment ?? ({} as AttachmentDTO));
    const { typeSuggestion, descriptionSuggestion } = attachmentSuggestions;

    const updateDetailsHookData = useUpdateDetails({
        entityPublicId,
        entityType,
        attachments: [attachment ?? {}] as AttachmentDTO[],
        savedType: type ?? { value: typeSuggestion?.value ?? '', label: typeSuggestion?.label ?? '' },
        savedSubtype: subtype,
        savedDescription: description ?? descriptionSuggestion?.value ?? '',
        invalidateKeys,
    });

    const hasEditAction = hasAction({ attachment, action: 'relabel' });
    const [isEditMode, setEditMode] = useState(
        (description === '' || description == null || (updateDetailsHookData.subtypes != null && subtype == null)) &&
            hasEditAction
    );

    if (attachment == null) return null;
    if (isEditMode) {
        return (
            <EditMode
                attachment={attachment}
                attachmentSuggestions={attachmentSuggestions}
                key={attachment.publicId}
                onSubmit={onSubmit}
                setEditMode={setEditMode}
                updateAttachmentHook={updateDetailsHookData}
            />
        );
    } else {
        return (
            <LabelValueMode
                description={description ?? ''}
                fileName={fileName ?? ''}
                key={attachment.publicId}
                setEditMode={hasEditAction ? setEditMode : undefined}
                subtype={subtype}
                type={type ?? { value: '', label: '' }}
            />
        );
    }
};
