import type { SelectOption } from '@lemonade-hq/bluis';
import { AlertMode, Dialog, Select } from '@lemonade-hq/bluis';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledTextArea } from '../../SharedTableConfig';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { getEditionName } from 'components/LoCo/common/editions/editionHelpers';
import { getEditionUrl } from 'components/LoCo/common/urlBuilders';
import { StyledInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import type { CreateEditionParams, Edition } from 'models/LoCo/Insurance/BaseEdition';
import { EditionStatus, EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { useGetEditionSummary } from 'queries/LoCo/Insurance/BaseEditionQueries';
import { useCreateCoveragesEdition } from 'queries/LoCo/Insurance/CoveragesEditionQueries';
import { useCreateDigitalAgentEdition } from 'queries/LoCo/Insurance/DigitalAgentEditionQueries';
import { useGetLatestsPublished } from 'queries/LoCo/Insurance/EditionTrackerQueries';
import { useGetProductLines } from 'queries/LoCo/Insurance/ProductLineQueries';
import {
    useGetProductEditionsSummary,
    useGetProducts,
    useSuspenseGetProduct,
} from 'queries/LoCo/Insurance/ProductQueries';
import { useCreateUnderwritingFiltersEdition } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

const notices = {
    clone: {
        mode: AlertMode.Info,
        title: 'A new edition will be created with configurations copied from the base edition',
    },
    notLatestMinor: {
        mode: AlertMode.Attention,
        title: 'The new edition can only be published as a Bug Fix',
    },
};

interface AddDraftEditionDialogProps {
    readonly productCode: string;
    readonly onClose: () => void;
    readonly editionType?: EditionType;
    readonly baseEditionCode?: string;
}

export const CreateDraftEditionDialog: React.FC<AddDraftEditionDialogProps> = ({
    productCode: productCodeFromProps,
    editionType,
    baseEditionCode,
    onClose,
}) => {
    const navigate = useNavigate();

    const {
        mutateAsync: createCoveragesEdition,
        isError: isCoveragesMutationError,
        isPending: isCoveragesMutationLoading,
    } = useCreateCoveragesEdition(productCodeFromProps);

    const {
        mutateAsync: createDigitalAgentEdition,
        isError: isDigitalMutationError,
        isPending: isDigitalMutationLoading,
    } = useCreateDigitalAgentEdition();

    const {
        mutateAsync: createUnderwritingFiltersEdition,
        isError: isUnderwritingFiltersMutationError,
        isPending: isUnderwritingFiltersMutationLoading,
    } = useCreateUnderwritingFiltersEdition(productCodeFromProps);

    const { data: productLines, isLoading: productLinesIsLoading, isError: productLinesIsError } = useGetProductLines();
    const { data: baseEdition, isLoading: isLoadingBaseEdition } = useGetEditionSummary(baseEditionCode, editionType);

    const { data: productFromProps } = useSuspenseGetProduct(productCodeFromProps);

    const productLineCodeFromProps = useMemo(
        () => productLines?.find(p => p.code === productFromProps.productLineCode)?.code,
        [productLines, productFromProps.productLineCode]
    );

    const { errors, values, setValue, valid } = useForm({
        fields: {
            editionType: {
                startValue: editionType,
                validations: {
                    required: basicRequiredValidation,
                },
            },
            description: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            product: {
                startValue: productCodeFromProps,
                validations: {
                    required: basicRequiredValidation,
                },
            },
            productLine: {
                startValue: productLineCodeFromProps,
                validations: {
                    required: basicRequiredValidation,
                },
            },
            clone: {
                startValue: baseEditionCode ?? '',
                validations: {},
            },
        },
    });

    const selectClone = useCallback(
        (selectedOption: SelectOption) => {
            setValue('clone', selectedOption.value);
        },
        [setValue]
    );

    const { data: allEditions } = useGetProductEditionsSummary(values.product, {
        status: [EditionStatus.Approved, EditionStatus.Published],
    });

    const {
        data: latestPublished,
        isLoading: isLoadingLatestPublished,
        isError: isErrorLatestPublished,
    } = useGetLatestsPublished(productCodeFromProps);

    useEffect(() => {
        if (values.productLine === undefined && productLineCodeFromProps !== undefined)
            setValue('productLine', productLineCodeFromProps);
    }, [productLineCodeFromProps, values.productLine, setValue]);

    const { data: products, isLoading: productIsLoading, isError: productIsError } = useGetProducts(values.productLine);

    const isError =
        productIsError ||
        isCoveragesMutationError ||
        isDigitalMutationError ||
        isUnderwritingFiltersMutationError ||
        isErrorLatestPublished;

    const onSubmit = useCallback(async () => {
        const createEditionParams: CreateEditionParams = {
            productCode: values.product,
            description: values.description.trim(),
            baseEditionCode: isEmpty(values.clone) ? undefined : values.clone,
        };

        let createdEdition: Edition;

        switch (values.editionType) {
            case EditionType.Coverages:
                createdEdition = await createCoveragesEdition(createEditionParams);
                break;
            case EditionType.DigitalAgent:
                createdEdition = await createDigitalAgentEdition(createEditionParams);
                break;
            case EditionType.UnderwritingFilters:
                createdEdition = await createUnderwritingFiltersEdition(createEditionParams);
                break;
            default:
                throw new Error(`Invalid edition type ${values.editionType}`);
        }

        navigate(getEditionUrl(values.product, createdEdition.code, values.editionType));
        onClose();
    }, [
        values.product,
        values.description,
        values.clone,
        values.editionType,
        navigate,
        onClose,
        createCoveragesEdition,
        createDigitalAgentEdition,
        createUnderwritingFiltersEdition,
    ]);

    const isCloneRequested = isDefined(baseEditionCode);

    const editionsByType = useMemo(
        () => ({
            [EditionType.Coverages]: allEditions?.coveragesEditions,
            [EditionType.DigitalAgent]: allEditions?.digitalAgentEditions,
            [EditionType.UnderwritingFilters]: allEditions?.underwritingFiltersEditions,
            [EditionType.Rating]: allEditions?.ratingEditions,
        }),
        [allEditions]
    );

    const cloneOptions: SelectOption[] = useMemo(() => {
        if (values.editionType == null) {
            return [];
        }

        const options =
            editionsByType[values.editionType]?.map(edition => ({
                id: edition.code,
                value: edition.code,
                label: edition.friendlyName,
            })) ?? [];

        if (isDefined(baseEditionCode) && !options.some(option => option.value === baseEditionCode)) {
            options.unshift({
                id: baseEditionCode,
                value: baseEditionCode,
                label: isLoadingBaseEdition ? '' : isDefined(baseEdition) ? baseEdition.friendlyName : baseEditionCode,
            });
        }

        return options;
    }, [baseEdition, baseEditionCode, editionsByType, isLoadingBaseEdition, values.editionType]);

    const editionTypeOptions: SelectOption[] = useMemo(
        () =>
            [EditionType.Coverages, EditionType.DigitalAgent, EditionType.UnderwritingFilters].map(type => ({
                id: type,
                value: type,
                label: getEditionName(type),
            })),
        []
    );

    const productLineOptions: SelectOption[] = useMemo(
        () =>
            productLines
                ? productLines.map(p => ({
                      id: p.code,
                      value: p.code,
                      label: p.name,
                  }))
                : [],
        [productLines]
    );

    const productOptions: SelectOption[] = useMemo(
        () =>
            products
                ? products
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(p => ({ id: p.code, value: p.code, label: p.name }))
                : [],
        [products]
    );

    const selectEditionType = useCallback(
        (selectedOption: SelectOption) => {
            setValue('editionType', selectedOption.value as EditionType);
        },
        [setValue]
    );

    const selectProductLine = useCallback(
        (selectedOption: SelectOption) => {
            setValue('productLine', selectedOption.value);
        },
        [setValue]
    );

    const selectProduct = useCallback(
        (selectedOption: SelectOption) => {
            setValue('product', selectedOption.value);
        },
        [setValue]
    );

    const isNotLatestMinor = useMemo(() => {
        const selectedEdition =
            values.editionType && editionsByType[values.editionType]?.find(edition => edition.code === values.clone);

        const isDifferentProduct = productCodeFromProps !== values.product;

        if (
            isDifferentProduct ||
            isLoadingLatestPublished ||
            !isDefined(latestPublished) ||
            latestPublished.length === 0 ||
            !isDefined(selectedEdition) ||
            !isDefined(values.clone)
        )
            return false;

        return !latestPublished.some(latest => latest.editionContentCode === selectedEdition.code);
    }, [
        editionsByType,
        isLoadingLatestPublished,
        latestPublished,
        productCodeFromProps,
        values.clone,
        values.editionType,
        values.product,
    ]);

    const isLoading =
        isCoveragesMutationLoading ||
        isDigitalMutationLoading ||
        isUnderwritingFiltersMutationLoading ||
        isLoadingLatestPublished;

    const notice = useMemo(() => {
        const noticesToReturn = [];

        if (isCloneRequested) noticesToReturn.push(notices.clone);
        if (isNotLatestMinor) noticesToReturn.push(notices.notLatestMinor);

        return noticesToReturn.length > 0 ? noticesToReturn : undefined;
    }, [isCloneRequested, isNotLatestMinor]);

    return (
        <Dialog
            actions={[
                {
                    text: 'Cancel',
                    type: 'close',
                    onClick: onClose,
                },
                {
                    text: 'Create',
                    type: 'submit',
                    onClick: onSubmit,
                    disabled: isLoading || isError || !valid,
                },
            ]}
            closeOnOutsideClick
            error={isError ? GENERAL_ERROR_MSG : undefined}
            loading={isLoading}
            notice={notice}
            onClose={onClose}
            title="Create Edition Draft"
        >
            <StyledInputWrapper label="Product Line" showErrors={productLinesIsError}>
                <Select
                    onOptionSelected={selectProductLine}
                    options={productLineOptions}
                    placeholder={productLinesIsLoading ? 'Loading...' : 'Select product line'}
                    value={values.productLine ?? null}
                />
            </StyledInputWrapper>
            <StyledInputWrapper label="Product" showErrors={productLinesIsError}>
                <Select
                    onOptionSelected={selectProduct}
                    options={productOptions}
                    placeholder={productIsLoading ? 'Loading...' : 'Select product'}
                    value={values.product}
                />
            </StyledInputWrapper>
            <StyledInputWrapper label="Edition Type" showErrors={!isEmpty(errors.editionType)}>
                <Select
                    disabled={isCloneRequested || isLoading}
                    onOptionSelected={selectEditionType}
                    options={editionTypeOptions}
                    placeholder="Select"
                    value={values.editionType ?? null}
                />
            </StyledInputWrapper>
            {isDefined(values.editionType) && (
                <StyledInputWrapper label="Clone of">
                    <Select
                        disabled={isCloneRequested || isLoading || isLoadingBaseEdition}
                        onOptionSelected={selectClone}
                        options={cloneOptions}
                        placeholder="Select"
                        value={values.clone}
                    />
                </StyledInputWrapper>
            )}
            <StyledInputWrapper label="Description" showErrors={!isEmpty(errors.description)}>
                <StyledTextArea
                    onChange={event => setValue('description', event.target.value)}
                    value={values.description}
                />
            </StyledInputWrapper>
        </Dialog>
    );
};
