import type { Edition } from 'models/LoCo/Insurance/BaseEdition';
import { EditionStatus, EditionType } from 'models/LoCo/Insurance/BaseEdition';

export function isEditionReadonly(edition: Edition): boolean {
    return edition.status !== EditionStatus.Draft;
}

const EDITION_TYPE_TO_DISPLAY_NAME: Record<EditionType, string> = {
    [EditionType.Rating]: 'Rating',
    [EditionType.Coverages]: 'Coverages',
    [EditionType.DigitalAgent]: 'Digital Agent',
    [EditionType.UnderwritingFilters]: 'Underwriting Filters',
};

export function getEditionName(editionType: EditionType): string {
    return EDITION_TYPE_TO_DISPLAY_NAME[editionType];
}
