import { Button, ComboBox, darkThemeClass, Flex, spacing, Text, TextArea } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent } from '@lemonade-hq/boutique';
import type { QueryKey } from '@tanstack/react-query';
import type { FC } from 'react';
import { useArchive } from '../ActionHooks/useArchive';
import { useUpdateDetails } from '../ActionHooks/useUpdateDetails';
import type { AttachmentActionType, AttachmentDTO } from '../types';
import { getAttachmentAnalyticsParam } from '../utils';
import { PreviewAssignAttachment } from 'components/Home/Claims/Attachments/AssignAttachmentDialog/AssignToItemDialog';

interface PreviewActionProps {
    readonly attachment: AttachmentDTO;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly action: AttachmentActionType | undefined;
    readonly invalidateKeys?: QueryKey[];
}

const PreviewUpdateForm: FC<Omit<PreviewActionProps, 'action'>> = ({
    attachment,
    entityPublicId,
    entityType,
    invalidateKeys,
}) => {
    const {
        types,
        type: selectedType,
        subtypes,
        subtype: selectedSubtype,
        description: selectedDescription,
        isTypesError,
        isTypesLoading,
        isSubmitPending,
        isSubmitError,
        handleSelectionChange,
        handleSubtypeSelectionChange,
        setDescription,
        handleSubmit,
    } = useUpdateDetails({
        entityPublicId,
        entityType,
        attachments: [attachment],
        savedType: attachment.type,
        savedSubtype: attachment.subtype,
        savedDescription: attachment.description,
        invalidateKeys,
    });

    const isDisabled =
        selectedType == null || selectedDescription.trim() === '' || (subtypes != null && selectedSubtype == null);

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment,
                entityType,
                entityId: entityPublicId,
            }),
            doc_type: selectedType?.value ?? '',
            doc_subtype: selectedSubtype?.value ?? '',
            doc_description: selectedDescription,
            action: 'submit',
            action_type: 'relabel',
            platform: 'hub',
            source: 'hover',
        });
        const attachmentsData = [
            {
                attachmentPublicId: attachment.publicId,
                ...(selectedType != null && { type: selectedType.value }),
                ...(selectedSubtype != null && { subtype: selectedSubtype.value }),
                ...(selectedDescription !== '' && { description: selectedDescription }),
            },
        ];
        await handleSubmit({
            attachmentsData,
        });
    };

    return (
        <Flex flexDirection="column" gap={spacing.s08}>
            <Flex flexDirection="column" gap={spacing.s06}>
                <Text color="primary" type="label-sm">
                    Type
                    <Text as="span" color="error">
                        &nbsp;*
                    </Text>
                </Text>
                <Flex flexDirection="column" gap={spacing.s06}>
                    <ComboBox
                        defaultValue={selectedType?.label ?? undefined}
                        disabled={isTypesLoading}
                        items={types}
                        onSelectionChange={handleSelectionChange}
                        optionsClassName={darkThemeClass}
                        placeholder="Select attachment type"
                    />
                    {isTypesError && (
                        <Text color="error" type="label-sm">
                            Error loading attachment types
                        </Text>
                    )}
                </Flex>
            </Flex>
            {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
            {(subtypes || selectedSubtype) && (
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        Subtype
                        <Text as="span" color="error">
                            &nbsp;*
                        </Text>
                    </Text>
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <ComboBox
                            defaultValue={selectedSubtype?.label ?? undefined}
                            disabled={isTypesLoading}
                            items={subtypes ?? []}
                            onSelectionChange={handleSubtypeSelectionChange}
                            optionsClassName={darkThemeClass}
                            placeholder="Select attachment subtype"
                        />
                        {isTypesError && (
                            <Text color="error" type="label-sm">
                                Error loading attachment types
                            </Text>
                        )}
                    </Flex>
                </Flex>
            )}
            <Flex flexDirection="column" gap={spacing.s06}>
                <Text color="primary" type="label-sm">
                    Description
                    <Text as="span" color="error">
                        &nbsp;*
                    </Text>
                </Text>
                <TextArea
                    autoExpanding
                    maxLength={500}
                    onChange={e => setDescription(e.target.value)}
                    rows={5}
                    value={selectedDescription}
                />
            </Flex>
            {isSubmitError && (
                <Text color="error" type="label-sm">
                    Error updating attachment details
                </Text>
            )}
            <Button
                disabled={isDisabled}
                label="Save"
                loading={isSubmitPending}
                onClick={onSubmit}
                size="sm"
                variant="primary"
            />
        </Flex>
    );
};

const ArchiveForm: FC<Omit<PreviewActionProps, 'action'>> = ({
    attachment,
    entityPublicId,
    entityType,
    invalidateKeys,
}) => {
    const { suggestedArchive, archivingReason } = attachment;
    const {
        reasons,
        reason,
        otherReason,
        isReasonsLoading: isLoading,
        isReasonsError: isError,
        setReason,
        setOtherReason,
        handleSubmit,
    } = useArchive({
        entityPublicId,
        entityType,
        attachments: [attachment],
        archivingReason,
        invalidateKeys,
    });

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment,
                entityType,
                entityId: entityPublicId,
            }),
            action: 'submit',
            action_type: 'archive',
            platform: 'hub',
            source: 'hover',
        });

        await handleSubmit();
    };

    return (
        <Flex flexDirection="column" gap={spacing.s08}>
            <Flex flexDirection="column" gap={spacing.s06}>
                <Text color="primary" type="label-sm">
                    {suggestedArchive === true ? 'Suggested archive reason:' : 'Archive reason'}
                    {suggestedArchive == null ||
                        (!suggestedArchive && (
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                        ))}
                </Text>
                <ComboBox
                    defaultValue={reason}
                    disabled={isLoading}
                    items={reasons}
                    onSelectionChange={item => setReason(item?.value ?? '')}
                    optionsClassName={darkThemeClass}
                    placeholder="Select archive reason"
                />
                {isError && (
                    <Text color="error" type="label-sm">
                        Error loading archive reasons
                    </Text>
                )}
            </Flex>
            {reason === 'other' && (
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        Other reason
                        <Text as="span" color="error">
                            &nbsp;*
                        </Text>
                    </Text>
                    <TextArea
                        autoExpanding
                        maxLength={200}
                        onChange={e => setOtherReason(e.target.value)}
                        placeholder="Add reason"
                        value={otherReason}
                    />
                </Flex>
            )}
            <Button
                disabled={reason === '' || (reason === 'other' && otherReason === '')}
                label="Archive"
                onClick={onSubmit}
                size="sm"
                variant="primary"
            />
        </Flex>
    );
};

export const PreviewActionContent: FC<PreviewActionProps> = ({
    action,
    attachment,
    entityPublicId,
    entityType,
    invalidateKeys,
}) => {
    if (action == null) return null;

    switch (action) {
        case 'relabel':
            return (
                <PreviewUpdateForm
                    attachment={attachment}
                    entityPublicId={entityPublicId}
                    entityType={entityType}
                    invalidateKeys={invalidateKeys}
                />
            );
        case 'archive':
            return (
                <ArchiveForm
                    attachment={attachment}
                    entityPublicId={entityPublicId}
                    entityType={entityType}
                    invalidateKeys={invalidateKeys}
                />
            );
        case 'assign_to_item':
            return <PreviewAssignAttachment attachments={[attachment]} entityPublicId={entityPublicId} />;
        default:
            return null;
    }
};
