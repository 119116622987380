import { toast } from '@lemonade-hq/bluis';
import { trackEvent } from '@lemonade-hq/boutique';
import { useEffect } from 'react';
import type { FC } from 'react';
import { useUnArchiveAttachment } from '../AttachmentsQueries';
import { useAttachmentsData } from '../context';
import type { AttachmentActionType, AttachmentDTO } from '../types';
import { getAttachmentAnalyticsParam } from '../utils';
import { AdditionalInfoDialog } from './Dialogs/AdditionalInfoDialog';
import { ArchiveDialog } from './Dialogs/ArchiveDialog';
import { UpdateAttachmentDialog } from './Dialogs/UpdateAttachmentDialog';
import { AssignAttachmentDialog } from 'components/Home/Claims/Attachments/AssignAttachmentDialog/AssignToItemDialog';

interface AttachmentActionsProps {
    readonly action?: AttachmentActionType;
    readonly attachments: AttachmentDTO[];
    readonly source: 'button' | 'single_row_menu';
    readonly onClose: () => void;
}

export const AttachmentActions: FC<AttachmentActionsProps> = ({ action, attachments, source, onClose }) => {
    const { entityPublicId, entityType } = useAttachmentsData();
    const { mutateAsync: unArchive, isError: isUnArchiveError } = useUnArchiveAttachment({
        entityPublicId,
        entityType,
    });

    useEffect(() => {
        const onUnarchive = async (): Promise<void> => {
            await unArchive(
                attachments.map(it => ({
                    attachmentPublicId: it.publicId,
                }))
            );

            trackEvent('docs.clicked', {
                ...getAttachmentAnalyticsParam({
                    attachment: attachments,
                    entityType,
                    entityId: entityPublicId,
                    source,
                }),
                action: 'click',
                action_type: 'unarchive',
                platform: 'hub',
            });

            if (isUnArchiveError) {
                toast.error('Error un-archiving attachment');
            }
        };

        if (action === 'unarchive') {
            void onUnarchive();
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    switch (action) {
        case 'archive':
            return <ArchiveDialog attachments={attachments} onClose={onClose} source={source} />;
        case 'relabel':
            return <UpdateAttachmentDialog attachments={attachments} onClose={onClose} source={source} />;
        case 'assign_to_item':
            return (
                <AssignAttachmentDialog
                    attachments={attachments}
                    entityPublicId={entityPublicId}
                    onClose={onClose}
                    source={source}
                />
            );
        case 'additional_details':
            return <AdditionalInfoDialog attachment={attachments[0]} onClose={onClose} />;
        default:
            return null;
    }
};
