import { Flex, spacing, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip } from '@lemonade-hq/blender-ui';
import type { TableHeaderCellProps } from '@lemonade-hq/bluis';
import {
    EmptySection,
    ErrorSection,
    LoadingSection,
    Table,
    TableHeader,
    TableRow,
    TableTitle,
} from '@lemonade-hq/bluis';
import { LinkComp } from '@lemonade-hq/bluiza';
import { useMemo, useState } from 'react';
import { StyledSectionHeader } from '../../LoCoPagesSharedStyles';
import { Paginator } from 'components/LoCo/common/components/Pagination/Paginator';
import { getFormattedDate, getFormattedDateMaybe } from 'components/LoCo/common/helpers/dateHelpers';
import { getEditionSetUrl, getReleaseUrl } from 'components/LoCo/common/urlBuilders';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';
import { useGetProductEditionSets } from 'queries/LoCo/Insurance/ProductQueries';

interface TableProps {
    readonly productCode: string;
    readonly type: 'draft' | 'published';
}

interface RowProps {
    readonly editionSet: EditionSet;
    readonly productCode: string;
    readonly type: 'draft' | 'published';
}
const headerFields: {
    readonly published: TableHeaderCellProps[];
    readonly draft: TableHeaderCellProps[];
} = {
    published: [
        { key: 'editionSet', value: 'Edition Set' },
        { key: 'newBusiness', value: 'New Business' },
        { key: 'renewal', value: 'Renewal' },
        { key: 'released', value: 'Released' },
    ],
    draft: [
        { key: 'editionSet', value: 'Edition Set' },
        { key: 'createdAt', value: 'Created On' },
        { key: 'source', value: 'Source' },
    ],
};

const EditionSetToRow: React.FC<RowProps> = ({ editionSet, productCode, type }) => {
    const cols = useMemo(() => {
        if (type === 'published') {
            return [
                {
                    key: 'editionSet',
                    value: (
                        <LinkComp url={getEditionSetUrl(productCode, editionSet.code)}>
                            {editionSet.friendlyName} ({editionSet.version})
                        </LinkComp>
                    ),
                },
                { key: 'newBusiness', value: getFormattedDate(editionSet.newBusinessEffectiveAt) },
                { key: 'renewal', value: getFormattedDate(editionSet.renewalEffectiveAt) },
                {
                    key: 'released',
                    value:
                        editionSet.releasePublicId != null && editionSet.publishedAt != null ? (
                            <LinkComp url={getReleaseUrl(productCode, editionSet.releasePublicId)}>
                                {getFormattedDate(editionSet.publishedAt)}
                            </LinkComp>
                        ) : (
                            '-'
                        ),
                },
            ];
        }

        return [
            {
                key: 'editionSet',
                value: (
                    <LinkComp url={getEditionSetUrl(productCode, editionSet.code)}>{editionSet.friendlyName}</LinkComp>
                ),
            },
            {
                key: 'createdAt',
                value: (
                    <Tooltip content={`By ${editionSet.addedByName}`}>
                        {getFormattedDateMaybe(editionSet.addedAt)}
                    </Tooltip>
                ),
            },
            {
                key: 'source',
                value:
                    editionSet.releasePublicId != null && editionSet.releaseFriendlyName != null ? (
                        <LinkComp url={getReleaseUrl(productCode, editionSet.releasePublicId)}>
                            {editionSet.releaseFriendlyName}
                        </LinkComp>
                    ) : (
                        'Created for stand alone test'
                    ),
            },
        ];
    }, [
        editionSet.code,
        editionSet.addedAt,
        editionSet.addedByName,
        editionSet.friendlyName,
        editionSet.newBusinessEffectiveAt,
        editionSet.publishedAt,
        editionSet.releasePublicId,
        editionSet.releaseFriendlyName,
        editionSet.renewalEffectiveAt,
        editionSet.version,
        productCode,
        type,
    ]);

    return <TableRow key={editionSet.code} row={cols} />;
};

export const EditionSetsTable: React.FC<TableProps> = ({ productCode, type }) => {
    const [pageNumber, setPageNumber] = useState(1);
    const {
        data: editionSetsData,
        isError,
        isLoading,
    } = useGetProductEditionSets(productCode, type === 'published', { page: pageNumber, size: 10 });
    const onPageClick = (nextPage: number) => () => {
        setPageNumber(nextPage);
    };

    if (isLoading) {
        return <LoadingSection />;
    }

    if (!editionSetsData || isError) {
        return <ErrorSection title={`Failed fetching ${type} edition sets :(`} />;
    }

    const { data: editionSets, stats } = editionSetsData;

    return (
        <Flex flexDirection="column" pt={spacing.s24}>
            {editionSets.length === 0 ? (
                <EmptySection>{`No ${type} edition sets for this product`}</EmptySection>
            ) : (
                <>
                    <Table>
                        <TableHeader headers={headerFields[type]} />
                        {editionSets.map(edition => (
                            <EditionSetToRow
                                editionSet={edition}
                                key={edition.code}
                                productCode={productCode}
                                type={type}
                            />
                        ))}
                    </Table>
                    <Paginator currentPage={pageNumber} onPageClick={onPageClick} totalPages={stats.totalPages} />
                </>
            )}
        </Flex>
    );
};

export const EditionSetsTables: React.FC<{ readonly productCode: string }> = ({ productCode }) => {
    return (
        <>
            <StyledSectionHeader>
                <TableTitle title="Edition Sets" />
            </StyledSectionHeader>
            <Tabs variant="inline">
                <TabList>
                    <Tab>Published</Tab>
                    <Tab>Drafts</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <EditionSetsTable productCode={productCode} type="published" />
                    </TabPanel>
                    <TabPanel>
                        <EditionSetsTable productCode={productCode} type="draft" />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};
