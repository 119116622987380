import { useQuery, UseQueryResult, UseMutationResult } from '@tanstack/react-query';
import { fetchMedicalExamReviews, updateMedicalExamReview, getVetNotes } from 'apis/Pet/PetMedicalExamReviewsAPI';
import { MedicalRecordLabelStatus, MedicalReview } from 'models/MedicalReview';
import { VetNote } from 'models/PetVetNotes';
import { usePessimisticMutationLegacy } from '../MutationHooks';

export enum PetMedicalExamReviewQueries {
    GetMedicalExamReviews = 'GET_MEDICAL_EXAM_REVIEWS',
    GetVetNotes = 'GET_VET_NOTES',
}

export function useGetMedicalExamReviews(petPublicId: string): UseQueryResult<MedicalReview[]> {
    return useQuery({
        queryKey: [PetMedicalExamReviewQueries.GetMedicalExamReviews, petPublicId],

        queryFn: async () => {
            return await fetchMedicalExamReviews(petPublicId);
        },

        enabled: !!petPublicId,
    });
}

export function useUpdateMedicalExamReview(
    petId: string
): UseMutationResult<
    unknown,
    unknown,
    {
        petId: string;
        filePublicId: string;
        userPublicId: string;
        reviewed: boolean;
        labelStatus?: MedicalRecordLabelStatus;
    },
    null
> {
    return usePessimisticMutationLegacy(updateMedicalExamReview, [
        [PetMedicalExamReviewQueries.GetMedicalExamReviews, petId],
    ]);
}

export function useMedicalExamReviewsMutation<TVariables>(
    petPublicId: string,
    apiMethod: (vars: TVariables) => Promise<unknown>
) {
    return usePessimisticMutationLegacy(apiMethod, [[PetMedicalExamReviewQueries.GetMedicalExamReviews, petPublicId]]);
}

export function useGetVetNotes(petPublicId: string): UseQueryResult<VetNote[]> {
    return useQuery({
        queryKey: [PetMedicalExamReviewQueries.GetVetNotes, petPublicId],
        queryFn: async () => await getVetNotes(petPublicId),
        enabled: !!petPublicId,
    });
}
