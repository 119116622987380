import type { Locale, Product } from '@lemonade-hq/lemonation';
import type { AdjusterRole, Adjusters, AdjusterSquad } from './Adjuster';
import type { Attachment } from './Attachment';
import type { ClaimPriority } from './ClaimPriority';
import type { HomeClaimFeature, HomeFeatureType } from './HomeFeature';
import type { EndorsementType, ValuableItemAttachment, ValuableItemImage } from './HomePolicy';
import type { HomeUser } from './HomeUser';
import type { Transaction } from './Payment';
import type { Litigation, Representative } from 'apis/HomeClaimsAPI';
import type { AttachmentDTO } from 'components/Attachments/types';
import type { HomeVerificationTask } from 'components/Claim/WorkflowsV2/Types';
import type { Address, AddressDTO } from 'models/Address';
import type { AlertDTO, HomeAddress, OptionDTO } from 'models/HomeShared';
import type { UserClaimHistory } from 'models/User';
import type { InformedCustomerVia } from 'models/Workflows';

export interface HomeClaimSearchResult {
    readonly publicId: string;
    readonly adjusterFullName: string | null;
    readonly adjusterPhotoUrl: string | null;
    readonly policyCountry: string | null;
    readonly policyState: string | null;
    readonly lossType: string | null;
    readonly policyPublicId: string | null;
    readonly policyType: string | null;
    readonly settled: boolean;
    readonly status: string;
    readonly submittedAt: string | null;
    readonly totalPaid: number;
    readonly userPublicId: string;
    readonly userFullName: string;
}

export enum Status {
    Pending = 'pending',
    PendingDecline = 'pending_decline',
    PendingInspection = 'pending_inspection',
    Submitted = 'submitted',
    Closed = 'closed',
    Open = 'open',
    Canceled = 'canceled',
    Deleted = 'deleted',
    FinalPayment = 'final_payment',
}

export enum HomeClaimType {
    WEATHER = 'weather',
    THEFT = 'theft',
    WATER = 'water',
    FIRE = 'fire',
    INJURY = 'injury',
    COLLAPSE = 'collapse',
    CATASTROPHE = 'catastrophe',
    POWER = 'power',
    FALLING_OBJECT = 'falling_object',
    LIABILITY = 'liability',
    VANDALISM = 'vandalism',
    ACCIDENTAL_DAMAGE = 'accidental_damage',
    MYSTERIOUS_LOSS = 'mysterious_loss',
    INSECTS_OR_VERMIN_DAMAGE = 'insects_or_vermin_damage',
    OTHER = 'other',
    OTHER_LOSS_TYPE = 'other_loss_type',
    TENANT_PET_DAMAGE = 'tenant_pet_damage',
    INJURY_LIABILITY = 'injury_liability',
    PROPERTY_LIABILITY = 'property_liability',
    MEDICAL = 'medical',
    THEFT_OR_VANDALISM = 'theft_or_vandalism',
    CRIME = 'crime',
    EARTHQUAKE = 'earthquake',
    MINE_SUBSIDENCE = 'mine_subsidence',
    CHILD_PERSONAL_ACCIDENT = 'child_personal_accident',
}

export type HomeClaim = {
    readonly id: string;
    readonly claim_info: {
        readonly id: string;
        readonly external_id: string;
        readonly close_date?: string;
        readonly created_at: string;
        readonly timezone: string;
        readonly deductible: number;
        readonly status: string;
        readonly deductible_applied: boolean;
        readonly deductible_waived: boolean;
        readonly deductible_waive_reason: string | null;
        readonly warnings: Warning[];
    };
    readonly policy_info: {
        readonly id: number;
        readonly address?: {
            readonly address: string;
            readonly address_without_unit: string;
            readonly lat: string | null;
            readonly lng: string | null;
            readonly timezone: string;
            readonly country: string;
            readonly state: string;
            readonly region?: string;
        };
        readonly effective_date: string;
        readonly canceled_date: string | null;
        readonly status: string;
        readonly renewal_date: string;
        readonly renewal_root_policy_effective_date: string;
        readonly root_policy_effective_date: string;
        readonly effective_on_date_of_loss: string;
        readonly policy_timezone: string;
        readonly humanized_form: string;
        readonly currency: Unit;
        readonly external_id: string;
        readonly state: string | null;
        readonly coverages?: Coverage[];
        readonly endorsements: { readonly current?: Endorsement[]; readonly supported?: Endorsement[] };
        readonly previous_policy_connection: { readonly connection_type: string; readonly id: number } | null;
        readonly pending_cancellation: boolean;
        readonly interested_parties: { readonly name: string }[] | null;
        readonly mortgagees: { readonly name: string }[] | null;
        readonly secondary_insured: { readonly first_name: string; readonly last_name: string } | null;
    };
    readonly operator?: HomeOperator;
    readonly subrogation: HomeSubrogation;
    readonly suspicious: HomeSuspicious;
    readonly tpa: HomeTPA;
    readonly instant: boolean;
    readonly item_categories: string[];
    readonly items_waive_ownership_reasons: string[];
    readonly emergency: boolean;
    readonly catastrophe_id?: string;
    readonly accidental_damage: AccidentalDamage;
    readonly rental_property: boolean;
    readonly wind_excluded_area: boolean;
    readonly inspection?: HomeInspection;
    readonly user: HomeUser;
    readonly features: HomeClaimFeature[];
    readonly priorities?: ClaimPriority[];
    readonly transactions: Transaction[];
    readonly submitted_at: number;
    readonly manual?: boolean;
    readonly adjusters: Adjusters;
    readonly payees: { readonly assigned: HomeClaimPayee[] };
    readonly date_of_loss: string;
    readonly designated_policy_id: string;
    readonly locale: Locale;
    readonly address: Address | null;
    readonly claims_history: UserClaimHistory;
    readonly police_report: PoliceReport | null;
    readonly created_at: string;
    readonly settled_at: string;
    readonly settled: boolean;
    readonly closed_at: string;
    readonly first_response_at: string;
    readonly handling_started_at: string;
    readonly answered_date_of_loss: string;
    readonly date_of_loss_from_answers: string;
    readonly loss_type: string;
    readonly loss_sub_type: string;
    readonly coverage_condition: string;
    readonly total_claimed: number;
    readonly valid_police_report: boolean;
    readonly claim_resolution?: 'approved' | 'declined';
    readonly loss_types: {
        readonly [lossType in ClaimLossType]: {
            readonly value: string;
            readonly loss_sub_types: {
                readonly [key: string]: string;
            } | null;
        };
    };
    readonly user_description: string | null;
    readonly loss_of_use: LossOfUse | null;
    readonly answers: Answers;
    readonly user_review?: {
        readonly rating: number;
        readonly review: string;
    };
};

interface Coverage {
    readonly type: string;
    readonly value: number;
    readonly title: string;
}

interface Endorsement {
    readonly title: string;
    readonly value: string;
    readonly deductible?: string;
}

interface Warning {
    readonly text: string;
}

interface LossOfUse {
    readonly status: string;
    readonly assessed_by: string | null;
    readonly max_limit: number;
    readonly actions?: LossOfUseAction[];
    readonly advance_payment?: AdvancePayment;
}

export interface AdvancePayment {
    readonly default_amount: number;
    readonly paid: number | null;
    readonly paid_by: string | null;
    readonly payee_name: string;
    readonly default_payment_method?: {
        readonly type: string;
        readonly title: string;
    };
}

interface LossOfUseAction {
    readonly type: string;
}

interface Answers {
    readonly attributes: Record<string, boolean | string>;
}

export interface Landlord {
    readonly created_at?: string;
    readonly email?: string;
    readonly source?: string;
    readonly operatorId?: number;
    readonly id?: number;
    readonly name?: string;
    readonly phoneNumber?: string;
    readonly policy_id?: number;
    readonly claim_id?: number;
    readonly updated_at?: string;
}

export type createLandlordParams = Pick<Landlord, 'email' | 'name' | 'phoneNumber'>;

export interface Witness {
    readonly created_at?: string;
    readonly email?: string;
    readonly id?: number;
    readonly name?: string;
    readonly phoneNumber?: string;
    readonly claim_id?: number;
    readonly updated_at?: string;
    readonly description?: string;
}

export type WitnessDetails = Pick<Witness, 'description' | 'email' | 'name' | 'phoneNumber'>;

export type HomeClaimPayee = {
    readonly id: string;
    readonly preview_name: string;
    readonly payments_sum: number;
};

export interface DeclineDecision {
    readonly block_user: boolean;
    readonly description: string;
    readonly id: number;
    readonly reason: string;
    readonly informed_customer_via: InformedCustomerVia | null;
}

export interface ClaimVideo {
    readonly id: number;
    readonly url: string;
    readonly name: string;
    readonly type: string;
    readonly description: string;
    readonly status: string;
    readonly created_at: string;
    readonly user_saved: true;
    readonly thumbnail: string;
    readonly taken_at: string;
    readonly data: {
        readonly preview_url: string | null;
        readonly thumbnail_url: string | null;
        readonly text: string;
        readonly reference_data: { readonly attachment_id: 61_903 } | null;
        readonly confidence: string | null;
    };
    readonly address: HomeClaimDataAddress | null;
    readonly dispatchable: boolean;
    readonly editable: boolean;
    readonly claim_items: {
        readonly id: number;
        readonly attachment_id: number;
        readonly claim_item_id: number;
        readonly created_at: string;
        readonly updated_at: string;
    }[];
}

export interface Transcript {
    readonly text: string;
    readonly confidence: number;
}

export interface ClaimVideoV2 {
    readonly id: string;
    readonly url: string;
    readonly name: string;
    readonly description: string;
    readonly type: string;
    readonly takenAt: string;
    readonly transcript: Transcript | null;
}

export interface ClaimVideoAttachments {
    readonly videos: AttachmentDTO[] | ClaimVideoV2[];
    readonly damageVideos: AttachmentDTO[] | ClaimVideoV2[];
}

interface HomeClaimDataAddress {
    readonly address: string | null;
    readonly address_without_unit: string | null;
    readonly lat: string | null;
    readonly lng: string | null;
    readonly place_id: string | null;
}

export interface PoliceReport {
    readonly incident: string | null;
    readonly precinct: string | null;
    readonly officer: string | null;
}

export type ReserveLog = {
    readonly id: number;
    readonly time: string;
    readonly type: string;
    readonly amount: number;
    readonly expenseType: string;
    readonly performedBy: string | null;
    readonly item: {
        readonly id: number;
        readonly name: string;
        readonly type: string;
    };
};

export type SubrogationLog = {
    readonly id: number;
    readonly time: string;
    readonly changeType: string;
    readonly featureType: string;
    readonly recoveryChange: number;
    readonly estimationChange: number;
};

export type FeesLog = {
    readonly date: string;
    readonly type: string;
    readonly amount: number;
    readonly id: number;
    readonly changeType: string;
};

export type ClaimEndorsement = {
    readonly publicId: string;
    readonly type: EndorsementType;
    readonly displayName: string;
    readonly info: string | null;
    readonly effectiveDate: string;
    readonly data: ClaimEndorsementData | null;
};

interface ClaimEndorsementData {
    readonly items: Record<string, string>[];
}

export type ClaimScheduledItem = {
    readonly publicId: string;
    readonly title: string;
    readonly value: string;
    readonly type: string;
    readonly subType?: string;
    readonly status: string;
    readonly description?: string;
    readonly images: ValuableItemImage[];
    readonly attachments: ValuableItemAttachment[];
};

export type LinkedClaimItem = {
    readonly category?: string;
    readonly publicId: string;
    readonly title: string;
    readonly value: string;
};

export type ClaimItemVendor = {
    readonly vendorId: number;
    readonly vendorName: string;
    readonly claimItemId: string;
};

export type LinkedEntityType = 'endorsement' | 'scheduled_item';

export type LinkedEndorsement = {
    readonly linkedEntityType: LinkedEntityType;
    readonly linkedEntityId: string;
    readonly title: string;
    readonly type: string;
    readonly value?: string;
    readonly lossItems: LinkedClaimItem[];
    readonly expenseItems: LinkedClaimItem[];
};

export enum ClaimAttachmentType {
    ClaimVideo = 'claim_video',
    ClaimDamageVideo = 'claim_damage_video',
}

export enum ClaimItemStatus {
    Pending = 'pending',
    Active = 'active',
    Archived = 'archived',
    Rejected = 'rejected',
    Paid = 'paid',
    ProcessingPayment = 'processing_payment',
    PaymentFailed = 'payment_failed',
    PendingApproval = 'pending_approval',
    Approved = 'approved',
}

export type HomeClaimItem = {
    readonly category?: string;
    readonly publicId: string;
    readonly id: number;
    readonly title: string;
    readonly description?: string;
    readonly status: ClaimItemStatus;
    readonly valueInCents: number;
    readonly deductibleApplied: boolean;
    readonly type: ClaimItemType;
    readonly featureName: string;
    readonly featurePublicId: string;
};

export type HomeClaimItems = {
    readonly items: HomeClaimItem[];
    readonly totalClaimed: number;
    readonly totalByFeatures: {
        readonly featureId: string;
        readonly featureName: string;
        readonly featurePublicId: string;
        readonly featureType: HomeFeatureType;
        readonly totalReserved: number;
    }[];
    readonly deductibleApplied: boolean;
};

export type HomeClaimPolicy = {
    readonly carrier: string;
    readonly publicId: string;
    readonly userPublicId: string;
    readonly quotePublicId: string;

    readonly euPolicy: boolean;

    readonly form: string;
    readonly type: string;
    readonly status: string;
    readonly product: string;

    readonly locale: Locale;
    readonly address: HomeAddress;
    readonly lifecycle: {
        readonly cancelDate: string | null;
        readonly createdAt: string;
        readonly renewalDate: string;
        readonly effectiveDate: string;
    };
    readonly deductibles: {
        readonly base: number;
        readonly windstorm?: number;
        readonly naturalDisaster?: number;
    };
};

export enum ClaimLossType {
    Crime = 'crime',
    Liability = 'liability',
    Water = 'water',
    Other = 'other',
    Injury = 'injury',
    Weather = 'weather',
    Fire = 'fire',
    Vandalism = 'vandalism',
    Explosion = 'explosion',
    Earthquake = 'earthquake',
    Theft = 'theft',
    Catastrophe = 'catastrophe',
    TheftOrVandalism = 'theft_or_vandalism',
    Collapse = 'collapse',
    Power = 'power',
    MineSubsidence = 'mine_subsidence',
    InjuryLiability = 'injury_liability',
    PropertyLiability = 'property_liability',
    FallingObject = 'falling_object',
    Riot = 'riot',
    AccidentalDamage = 'accidental_damage',
    InsectsOrVerminDamage = 'insects_or_vermin_damage',
    MysteriousLoss = 'mysterious_loss',
    TenantPetDamage = 'tenant_pet_damage',
    ChildPersonalAccident = 'child_personal_accident',
}

export enum ClaimStatus {
    Pending = 'pending',
    PendingDecline = 'pending_decline',
    PendingInspection = 'pending_inspection',
    PendingApproval = 'pending_approval',
    Open = 'open',
    Closed = 'closed',
    Canceled = 'canceled',
    Deleted = 'deleted',
    FinalPayment = 'final_payment',
}

export type HomeClaimV2 = {
    readonly dateOfLoss: string | null;
    readonly deductible: number | null;
    readonly lossSubType: string | null;
    readonly lossSubTypeDisplayName: string | null;
    readonly lossType: ClaimLossType | null;
    readonly lossTypeDisplayName: string | null;
    readonly lossTypeFullDisplayName: string | null;
    readonly policyPublicId?: string;
    readonly publicId: string;
    readonly status: ClaimStatus;
    readonly submittedAt: string | null;
    readonly userPublicId: string;
    readonly settled: boolean;
    readonly createdAt: string;
    readonly settledAt: string | null;
    readonly lastClosedDate: string | null;
    readonly handlingStartedAt: string | null;
    readonly manual: boolean | null;
    readonly deductibleWaived: boolean | null;
    readonly deductibleWaiveReason: string | null;
    readonly isEmergency: boolean | null;
    readonly instant: boolean;
    readonly autoDeclined: boolean;
    readonly declineReason: string | null;
};

export enum LossItemClassification {
    AdvancePayment = 'advance_payment',
    Inventory = 'inventory',
    Services = 'services',
}

export const lossItemClassificationLabel = {
    [LossItemClassification.AdvancePayment]: 'Advance payment',
    [LossItemClassification.Inventory]: 'Inventory item',
    [LossItemClassification.Services]: 'Contents services',
} as const satisfies Record<LossItemClassification, string>;

export enum LossServiceType {
    AsbestosRemovalCleaning = 'asbestos_removal_cleaning',
    CleaningFees = 'cleaning_fees',
    DryCleaningTextileCleaning = 'dry_cleaning_textile_cleaning',
    ElectronicsCleaning = 'electronics_cleaning',
    MoldRemovalCleaning = 'mold_removal_cleaning',
    Other = 'other',
    PackInPackOut = 'pack_in_pack_out',
    StorageFees = 'storage_fees',
}

export const lossServiceTypeLabel = {
    [LossServiceType.AsbestosRemovalCleaning]: 'Asbestos removal/cleaning',
    [LossServiceType.CleaningFees]: 'Cleaning fees',
    [LossServiceType.DryCleaningTextileCleaning]: 'Dry cleaning / textile cleaning',
    [LossServiceType.ElectronicsCleaning]: 'Electronics cleaning',
    [LossServiceType.MoldRemovalCleaning]: 'Mold removal/cleaning',
    [LossServiceType.Other]: 'Other',
    [LossServiceType.PackInPackOut]: 'Pack-in / pack-out',
    [LossServiceType.StorageFees]: 'Storage fees',
} as const satisfies Record<LossServiceType, string>;

export type HomeClaimLicensedNote = {
    readonly handlingStartedAt: string;
    readonly startHandlingRequired: boolean;

    readonly supportedInfo: {
        readonly reason: string | null;
        readonly supported: string;
    };

    readonly initialAssessment?: {
        readonly claimType: string;
        readonly coverageCondition: string;
    } | null;

    readonly conditionalApproval: {
        readonly approvalStatus: string;
        readonly approvedMaxLimit: number;
    };

    readonly todo: {
        readonly checked: boolean;
        readonly content: string;
    }[];
};

export interface ClaimDefaultEventSummary {
    readonly liabilitySummary: string;
    readonly summary: string;
}

export type HomeClaimReview = {
    readonly liabilityReferralRequired: boolean | null;
    readonly liabilitySummary: string | null;
    readonly summary: string;
    readonly settings: EventSummaryTaskSettings;
};

export interface EventSummaryTaskSettings {
    readonly eclddEditable: boolean;
    readonly liabilityReferralToggleEnabled: boolean;
    readonly showLiabilityReferralSection: boolean;
}

export type Fee = {
    readonly amount: number;
    readonly complexity: string;
    readonly type: string;
};

export type Fees = Fee[];

export enum HomeSuspiciousReasonTypes {
    FirstWeekLoss = 'first_week_loss',
    TestClaims = 'test_claims',
    CanceledVideos = 'canceled_videos',
    MissingPoliceReport = 'missing_police_report',
    OnlinePoliceReport = 'online_police_report',
    MissingReceipts = 'missing_receipts',
    LemonadeClaimHistory = 'lemonade_claim_history',
    IsoClaimHistory = 'iso_claim_history',
    PhoneClaimSameDevice = 'phone_claim_same_device',
    SuspiciousCxInteraction = 'suspicious_cx_interaction',
    LossAfterPolicyUpdates = 'loss_after_policy_updates',
    PrepaidUser = 'prepaid_user',
    Other = 'other',
    Inspection = 'inspection',
    CriminalRecords = 'criminal_records',
}

export interface HomeSuspiciousReason {
    readonly editable: boolean;
    readonly selected: boolean;
    readonly count?: number;
    readonly type: HomeSuspiciousReasonTypes;
    readonly data: { readonly criminal_records: { readonly title: string }[] } | null;
}

export interface HomeSuspicious {
    readonly marked: boolean;
    readonly reasons: HomeSuspiciousReason[];
    readonly note: string | null;
    readonly operator_name: string | null;
    readonly operator_image: string | null;
}

export enum HomeInspectionStatuses {
    InProgress = 'in_progress',
    Passed = 'passed',
    Failed = 'failed',
    Canceled = 'canceled',
}

export interface HomeInspection {
    readonly allowed_to_complete_assessment: boolean;
    readonly assessment_date: string;
    readonly questions: string[];
    readonly reason: string;
    readonly reviewer_operator_name: string;
    readonly status: HomeInspectionStatuses;
    readonly summary: string;
    readonly suspicious_answers: string[];
}

export interface TPACurrent {
    readonly id: number;
    readonly name: string;
    readonly tpa_claim_id: string;
}

export interface TPADetails {
    readonly id: number;
    readonly name: string;
}

export interface HomeTPA {
    readonly current?: TPACurrent;
    readonly options: TPADetails[];
}

export interface AccidentalDamage {
    readonly is_accidental_damage: boolean;
    readonly is_accidental_damage_potential: boolean;
    readonly damage_reason?: string;
    readonly item_category?: string;
}

export interface HomeSubrogation {
    readonly status: string;
    readonly active: boolean;
}

export interface HomeOperator {
    readonly name: string;
}

export enum ClaimActionType {
    MarkAsTest = 'mark_test',
    UnmarkAsTest = 'unmark_test',
    AddLitigation = 'add_litigation',
    MarkSuspicious = 'mark_suspicious',
    Edit = 'edit',
    AssociateCatastrophe = `associate_a_catastrophe`,
    RemoveCatastrophe = 'remove_catastrophe',
    CancelAutoDecline = 'cancel_auto_decline',
    SimulateAutoDecline = 'simulate_auto_decline',
    Reopen = 'reopen',
    Close = 'close',
    Cancel = 'cancel',
    LossReport = 'loss_report',
    ExportAsPdf = 'export_as_pdf',
    ExportClaimFile = 'export_claim_file',
    PreviewPolicyPdf = 'preview_policy_pdf',
    DeclineClaim = 'decline_claim',
}

export type ClaimAction = {
    readonly type: ClaimActionType;
    readonly title: string;
    readonly warning?: boolean;
    readonly disabled: boolean;
    readonly disabledReason: string;
};

export interface ClaimFeaturesAction {
    readonly disabled: boolean;
    readonly disableReason: {
        readonly title: string;
        readonly todo?: string[];
    };
    readonly featureId?: number;
    readonly featurePublicId?: string;
}

export interface ClaimFeaturesActions {
    readonly payActions: ClaimFeaturesAction[];
    readonly rejectLastAction: ClaimFeaturesAction;
}
type ClaimHeaderActionType = 'settle_claim';
export interface ClaimHeaderAction {
    readonly type: ClaimHeaderActionType;
    readonly disabled: boolean;
    readonly disabledReason: string;
}

export enum ClaimUserPreferencesType {
    VendorDispatch = 'vendor_dispatch',
}

export interface VendorDispatchUserPreferences {
    readonly dispatchApproved: boolean;
}

export interface VendorDispatchPreferencesDTO {
    readonly vendorDispatch?: VendorDispatchUserPreferences;
}

// Expand this type when adding new user preferences
export type ClaimUserPreferences = VendorDispatchPreferencesDTO;

export type UserPreferencesPayload = VendorDispatchUserPreferences;

export interface UpdateClaimUserPreferencesDTO {
    readonly preferences: UserPreferencesPayload;
    readonly type: ClaimUserPreferencesType;
}

export enum ClaimTabName {
    Workflows = 'Workflows',
    ClaimInventory = 'Claim Inventory',
    ClaimDetails = 'Claim Details',
    Attachments = 'Attachments',
    Payouts = 'Payouts',
    CommsTimeline = 'Comms. Timeline',
    UserTimeline = 'User Timeline',
    Subrogation = 'Subro.',
    InstantClaimLog = 'Instant Claims Log',
    ClaimChatLog = 'Claim Chat Log',
    QuoteChatLog = 'Quote Chat Log',
    ReserveLog = 'Reserve Log',
    FeesLog = 'Fees Log',
    SubrogationLog = 'Subro. Log',
}

export enum ClaimDetailsRootSections {
    Features = 'features',
}

export enum ClaimDetailsOverviewSections {
    Tags = 'tags',
    Siu = 'siu',
    LossOfUse = 'lossOfUse',
    UserDescription = 'userDescription',
    AccidentalDamage = 'accidentalDamage',
    UserReview = 'userReview',
    SuspiciousClaim = 'suspiciousClaim',
    PolicyMembers = 'policyMembers',
}

export type ClaimDetailsSection = ClaimDetailsOverviewSections | ClaimDetailsRootSections;

export const claimDetailsSectionGroupBySection: Record<ClaimDetailsSection, 'overviewSections' | 'rootSections'> = {
    [ClaimDetailsRootSections.Features]: 'rootSections',
    [ClaimDetailsOverviewSections.Tags]: 'overviewSections',
    [ClaimDetailsOverviewSections.Siu]: 'overviewSections',
    [ClaimDetailsOverviewSections.LossOfUse]: 'overviewSections',
    [ClaimDetailsOverviewSections.UserDescription]: 'overviewSections',
    [ClaimDetailsOverviewSections.AccidentalDamage]: 'overviewSections',
    [ClaimDetailsOverviewSections.UserReview]: 'overviewSections',
    [ClaimDetailsOverviewSections.SuspiciousClaim]: 'overviewSections',
    [ClaimDetailsOverviewSections.PolicyMembers]: 'overviewSections',
};

export interface ClaimPolicyVersionDetails {
    readonly publicId: string | null;
    readonly deductibles: ClaimPolicyVersionDeductible;
}

export interface ClaimPolicyVersionDeductible {
    readonly base?: number;
    readonly windstorm?: number;
    readonly earthquake?: number;
    readonly naturalDisaster?: number;
}

export enum ClaimIncidentDetailsTaskSectionName {
    UserDetails = 'user_details',
    WhereAndWhen = 'where_and_when',
    Damages = 'damages',
    Injuries = 'injuries',
    LossType = 'loss_type',
    PoliceReport = 'police_report',
    Landlord = 'landlord',
    Mortgagees = 'mortgagees',
}

export interface ClaimIncidentDetailsTaskLayout {
    readonly sections: ClaimIncidentDetailsTaskSectionName[];
    readonly pageAlerts: AlertDTO[];
}

export interface ClaimUserExtendedDetails {
    readonly publicId: string;
    readonly email: string;
    readonly language: string;
    readonly lastName: string;
    readonly firstName: string;
    readonly fullName: string;
    readonly phoneNumber: string | null;
    readonly highTier: boolean;
    readonly activePolicyIdsPerProduct: PoliciesPerProduct;
    readonly userSince: string;
}

export type PoliciesPerProduct = Partial<Record<Product, string[]>>;

export interface ClaimantDetailsTask {
    readonly liabilityCase: LiabilityCase;
    readonly settings: ClaimantDetailsTaskSettings;
}

export interface ClaimantDetailsTaskSettings {
    readonly canCreateLitigation: boolean;
    readonly liabilityDemandTypeOptions: OptionDTO[];
}

export interface LiabilityCase {
    readonly adjusterAssessment: string | null;
    readonly demandDetails: LiabilityDemands | null;
    readonly claimant: Claimant | null;
    readonly featurePublicId: string;
    readonly litigation: Litigation | null;
    readonly publicId: string;
}

export interface LiabilityDemands {
    readonly amountClaimed: number;
    readonly dateReceived: string;
    readonly dueDate: string | null;
    readonly type: OptionDTO[];
}

export interface Claimant {
    readonly additionalInfo: string | null;
    readonly address: AddressDTO;
    readonly dateOfBirth: string | null;
    readonly email: string | null;
    readonly externalClaimId: string | null;
    readonly firstName: string;
    readonly fullName: string;
    readonly gender: string | null;
    readonly insuranceCarrier: string | null;
    readonly insurancePocEmail: string | null;
    readonly insurancePocName: string | null;
    readonly insurancePocPhone: string | null;
    readonly phone: string | null;
    readonly lastName: string;
    readonly ssnLastFourDigits: string | null;
    readonly representatives: Representative[];
}

export interface ClaimItemV2 {
    readonly attachments?: Attachment[] | AttachmentDTO[];
    readonly category?: string | null;
    readonly classification: LossItemClassification | null;
    readonly description: string | null;
    readonly disabled: boolean;
    readonly editable: boolean;
    readonly expenseType?: string | null;
    readonly id: number;
    readonly invoicePublicId?: string;
    readonly ownershipVerified?: boolean | null;
    readonly payable?: boolean;
    readonly publicId: string;
    readonly rejectReason: string | null;
    readonly serviceType?: LossServiceType | null;
    readonly status: ClaimItemStatus;
    readonly title: string;
    readonly type: ClaimItemType;
    readonly valueInCents: number;
    readonly valueVerified?: boolean | null;
    readonly vendor?: VendorDetails | null;
    readonly waiveOwnership?: boolean | null;
    readonly waiveOwnershipReason?: string | null;
}

export interface ValuatedClaimItem {
    readonly actualCashValue: number | null;
    readonly claimedAmount: number;
    readonly claimItem: ClaimItemV2;
    readonly leftToPay: number;
    readonly payoutType: string;
    readonly publicId: string | null;
    readonly purchasedAt: string | null;
    readonly quantity: number | null;
    readonly receiptApproved: boolean | null;
    readonly receiptValue: number | null;
    readonly recoverableDepreciation: number | null;
    readonly replacementCostValue: number | null;
    readonly replacementProduct: {
        readonly title: string | null;
        readonly thumbnailUrl: string | null;
        readonly url: string | null;
    } | null;
    readonly title: string;
}

export enum ValuatedItemsViewMode {
    InventoryReview = 'inventory_review',
    OwnershipReview = 'ownership_review',
    PaymentRequest = 'payment_request',
    ReplacementReview = 'replacement_review',
}

export enum AppraisalVendorSyncStatus {
    Error = 'error',
    Finished = 'finished',
    Running = 'running',
    Scheduled = 'scheduled',
}

export enum RealtimeEventType {
    SyncCompleted = 'sync_completed_event',
    AttachmentsSyncCompleted = 'attachments_sync_completed_event',
}

export interface AppraisalVendorSyncRequest {
    readonly endedAt: string | null;
    readonly entityPublicId: string;
    readonly entityType: RealtimeEventType;
    readonly errorMessage: string | null;
    readonly id: string | null;
    readonly startedAt: string | null;
    readonly status: AppraisalVendorSyncStatus;
    readonly totalPulled: number;
}

export interface AttachmentsSyncCompletedRealtimePayload {
    readonly createdAttachmentsCount: number;
    readonly entityPublicId: string;
    readonly entityType: RealtimeEventType;
    readonly syncTaskId: string;
}

export enum ClaimItemType {
    Loss = 'loss',
    Expense = 'expense',
}

export interface FeatureDetails {
    readonly claimant: Claimant | null;
    readonly depreciationCanBeApplied?: boolean;
    readonly displayName: string;
    readonly limitInCents?: number;
    readonly policyDepreciationExclusion: boolean;
    readonly publicId: string;
    readonly status: ClaimItemStatus;
    readonly supportsClaimant: boolean;
    readonly title: string;
    readonly totalActualCashValueInCents?: number;
    readonly totalClaimedAmountInCents?: number;
    readonly totalPaidInCents?: number;
    readonly totalProcessingPaymentInCents?: number;
    readonly totalRecoverableDepreciationInCents?: number;
    readonly totalReplacementCostValueInCents?: number;
    readonly totalReservedInCents?: number;
    readonly type: HomeFeatureType;
}

interface VendorDetails {
    readonly name: string;
    readonly representativeName?: string;
    readonly pdfFileUrl?: string;
}

export interface ValuatedItemsTableDTO {
    readonly table: ValuatedItemsTable[];
    readonly totalOpenFeatures: number;
    readonly totalOpenItems: number;
}

export interface ClaimItemsTableDTO {
    readonly table: ItemsTable[];
    readonly totalOpenItems: number;
    readonly totalOpenFeatures: number;
}

export interface ItemsTable {
    readonly featureDetails: FeatureDetails;
    readonly items: ClaimItemV2[];
    readonly type: ClaimItemType;
}

export interface ValuatedItemsTable {
    readonly featureDetails: FeatureDetails;
    readonly items: ValuatedClaimItem[];
    readonly type: ClaimItemType;
}

export interface ClaimIncidentDetailsDamagesDetails {
    readonly alerts: AlertDTO[];
    readonly totalClaimed: string;
    readonly damageType: string;
    readonly incidentDescription: string | null;
    readonly evacuatedByAuthorities: string;
    readonly homeVandalized: string;
    readonly temporaryAccommodation: string;
}

export interface ClaimInjuryDetails {
    readonly injuredParty: string;
    readonly injuryType: string;
}

export enum CatastropheStatus {
    Warning = 'warning',
    Active = 'active',
    Aftermath = 'aftermath',
    Ended = 'ended',
}

export interface ClaimCatastrophe {
    readonly name: string;
    readonly id: string;
    readonly status: CatastropheStatus;
    readonly classification: string;
    readonly startDate: string;
    readonly endDate: string;
}

export enum IncidentLocation {
    PolicyAddress = 'policy_address',
    Outside = 'outside',
    Abroad = 'abroad',
}

export interface ClaimLocationDetails {
    readonly policyAddress: HomeAddress;
    readonly incidentAddress?: HomeAddress;
    readonly incidentLocation: IncidentLocation;
}

export interface ClaimSelectableUserPolicy {
    readonly publicId: string;
    readonly address: string;
    readonly type: string;
    readonly status: string;
    readonly effectiveDate: string;
    readonly endDate: string;
    readonly region: string;
}

export interface ClaimCurrentAdjuster {
    readonly publicId: string;
    readonly operatorPublicId: string;
    readonly status: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly photoUrl?: string;
    readonly role: AdjusterRole;
    readonly productType?: string;
    readonly squad?: AdjusterSquad;
}

export interface ObtainFilesQuestionnaire {
    readonly obtainDwellingDamages?: {
        readonly insuredHasOwnContractor: boolean | null;
        readonly reasonEstimateNotNeeded: string | null;
        readonly reasonPhotosNotNeeded: string | null;
        readonly shouldObtainEstimateOfDamages: boolean | null;
        readonly shouldObtainPhotosOfDamage: boolean | null;
    };
    readonly obtainPoliceReport?: {
        readonly showIncidentId: boolean;
        readonly obtainIncidentId: boolean;
        readonly incidentIdReasons: string[];
        readonly obtainFullReport: boolean | null;
        readonly fullReportReasons: string[];
        readonly vendorDispatchedAt: string | null;
        readonly dispatchVendorTaskPublicId: string | null;
    };
    readonly obtainLandlordDetails?: {
        readonly obtainLandlordDetails: boolean;
    };
    readonly obtainFireReport?: {
        readonly showFireReport: boolean;
        readonly obtainFireReport: boolean | null;
        readonly fireReportReasons: string[];
        readonly dispatchCauseAndOrigin: boolean | null;
        readonly causeAndOriginReason: string;
        readonly vendorDispatchedAt: string | null;
        readonly dispatchVendorTaskPublicId: string | null;
        readonly causeAndOriginLeadApprovalDecision: boolean | null;
        readonly causeAndOriginLeadDecisionReason: string;
    };
}

export interface ClaimSettings {
    readonly enforceStartHandling: boolean;
    readonly canReassignSquad: boolean;
    readonly isAssignedToCurrentOperator: boolean;
}

export interface BaseVerification<
    TType extends HomeVerificationTask,
    TParams extends Record<string, boolean | string | null | undefined>,
> {
    readonly verificationType: TType;
    readonly verifications: TParams;
}

export type AppleDeviceVerificationParams = {
    readonly device_info?: boolean | null;
    readonly serial_number?: boolean | null;
    readonly hardware_specifications?: boolean | null;
};

export type BikeVerificationParams = {
    readonly proof_of_ownership?: boolean | null;
    readonly evidence_of_damage?: boolean | null;
    readonly frame_number_in_report?: boolean | null;
    readonly keys_in_possession?: boolean | null;
};

export type PhoneVerificationParams = {
    readonly device_registration?: boolean | null;
    readonly receipt?: boolean | null;
    readonly unused?: boolean | null;
    readonly bill_info_match_owner?: boolean | null;
};

export type CarBreakInVerificationParams = {
    readonly details_accuracy?: boolean | null;
    readonly evidence_of_damage?: boolean | null;
    readonly items_in_plain_view?: boolean | null;
};

export type PoliceReportVerificationParams = {
    readonly incident_matching_report?: boolean | null;
    readonly all_items_reported?: boolean | null;
    readonly credible_report?: boolean | null;
};

export type FireReportVerificationParams = {
    readonly c_and_o_conducted?: boolean | null;
    readonly contribution_factors?: string | null;
    readonly product_failure_cause?: boolean | null;
    readonly damage_to_others?: boolean | null;
    readonly has_injuries?: boolean | null;
    readonly has_witness?: boolean | null;
    readonly report_time_match?: boolean | null;
    readonly report_damage_match?: boolean | null;
};

export type LandlordVerificationParams = {
    readonly incident_reported?: boolean | null;
    readonly occurred_on_date_of_loss?: boolean | null;
    readonly reside_on_date_of_loss?: boolean | null;
};

export type ThirdPartyVerificationParams = {
    readonly matching_facts_of_loss?: boolean | null;
    readonly credible_witness?: boolean | null;
};

type AppleDeviceVerifications = BaseVerification<
    HomeVerificationTask.AppleProductVerification,
    AppleDeviceVerificationParams
>;

type BikeVerifications = BaseVerification<HomeVerificationTask.BikeVerification, BikeVerificationParams>;

type PhoneVerification = BaseVerification<HomeVerificationTask.PhoneVerification, PhoneVerificationParams>;

type CarBreakInVerification = BaseVerification<
    HomeVerificationTask.CarBreakInVerification,
    CarBreakInVerificationParams
>;

type LandlordVerification = BaseVerification<HomeVerificationTask.LandlordVerification, LandlordVerificationParams>;

type ThirdPartyVerification = BaseVerification<
    HomeVerificationTask.ThirdPartyVerification,
    ThirdPartyVerificationParams
>;

type PoliceReportVerification = BaseVerification<
    HomeVerificationTask.PoliceReportVerification,
    PoliceReportVerificationParams
>;

type FireReportVerification = BaseVerification<
    HomeVerificationTask.FireReportVerification,
    FireReportVerificationParams
>;

export type ClaimVerificationQuestionnaire =
    | AppleDeviceVerifications
    | BikeVerifications
    | CarBreakInVerification
    | FireReportVerification
    | LandlordVerification
    | PhoneVerification
    | PoliceReportVerification
    | ThirdPartyVerification;

export type ClaimVerificationQuestionnaireType = ClaimVerificationQuestionnaire['verificationType'];

export type ParamsByVerificationType<TType extends ClaimVerificationQuestionnaireType> = Extract<
    ClaimVerificationQuestionnaire,
    { readonly verificationType: TType }
>['verifications'];

export type AllVerificationParams = ClaimVerificationQuestionnaire['verifications'];

export enum DispatchType {
    PoliceReport = 'police_report',
    FireReport = 'fire_report',
}

export interface AvailableFeature {
    readonly type: HomeFeatureType;
    readonly displayName: string;
}

export interface DispatchVendorDialogDefaults {
    readonly availableFeatures: AvailableFeature[];
    readonly preselectedFeature: string | null;
    readonly itemType: ClaimItemType | null;
    readonly itemSubType: string | null;
    readonly title: string | null;
    readonly description: string | null;
    readonly value: number | null;
}

export type DispatchVendorRequestType = { readonly type: string; readonly slug: string };

export interface RejectClaimItemAttributes {
    readonly itemPublicId: (number | string)[];
    readonly reason: string;
    readonly disableClosingClaim?: boolean;
}

export interface ClaimDispatchExpenseVendorTask {
    readonly taskAlerts: AlertDTO[];
}
