import { createCalendar } from '@internationalized/date';
import type { FC } from 'react';
import type { CalendarProps as AriaCalendarProps, AriaDatePickerProps, DateValue } from 'react-aria';
import { useCalendar, useLocale } from 'react-aria';
import { useCalendarState } from 'react-stately';
import type { CalendarProps } from './Calendar';
import { Calendar } from './Calendar';

type DateTimeCalendarProps = AriaCalendarProps<DateValue> &
  Omit<
    CalendarProps,
    | 'calendarProps'
    | 'endFieldProps'
    | 'isRange'
    | 'nextButtonProps'
    | 'prevButtonProps'
    | 'startFieldProps'
    | 'state'
    | 'title'
  > & {
    readonly onChange: (date: DateValue) => void;
    readonly locale?: string;
    readonly fieldProps: AriaDatePickerProps<DateValue>;
  };

export const DateTimeCalendar: FC<DateTimeCalendarProps> = ({ onChange, locale, ...props }) => {
  const { locale: ariaLocale } = useLocale();
  const state = useCalendarState({
    ...props,
    locale: locale ?? ariaLocale,
    createCalendar,
    onChange,
  });

  const calendarProps = useCalendar(props, state);

  return <Calendar {...props} {...calendarProps} isRange={false} state={state} />;
};
