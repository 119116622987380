import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Test Sentry custom performance measurement, to see if we can measure when actual content is loaded
 * if successful, it will be re-written in Monolu
 */

export const TrackContentLoaded: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { pathname } = useLocation();
    const transaction = Sentry.getCurrentHub().getScope().getTransaction();

    useEffect(() => {
        // server navigation
        if (window.htmlLoaded) {
            transaction?.setMeasurement('full_page_content_loaded', performance.now(), 'millisecond');
            window.htmlLoaded = undefined;

            // client navigation
        } else {
            const performanceNow = sessionStorage.getItem('performanceNow');
            const timeToDisplay = performance.now() - Number(performanceNow ?? 0);

            transaction?.setMeasurement('page_content_loaded', timeToDisplay, 'millisecond');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to send a measurement only when path changes
    }, [pathname]);

    return null;
};
