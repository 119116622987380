/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getEntityTypeFromUrl, getEnvId, getProductTypeFromUrl } from '@lemonade-hq/bluiza';
import { useCurrentPrincipal } from '@lemonade-hq/boutique';
import { FlagsProvider, UnleashFlags } from '@lemonade-hq/flags';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

interface EnsureFlagsProps {
    readonly children: React.ReactNode;
}

function useUnleashClient(): UnleashFlags {
    const clientRef = useRef<UnleashFlags | null>();
    const { pathname } = useLocation();
    const { principal, operator } = useCurrentPrincipal();

    if (!clientRef.current) {
        clientRef.current = new UnleashFlags({
            appName: 'blender',
            clientKey: import.meta.env.VITE_UNLEASH_CLIENT_KEY ?? '',
            environment: import.meta.env.VITE_UNLEASH_ENVIRONMENT ?? '',
            refreshInterval: 3 * 60,
            metricsInterval: 3 * 60,
            url: import.meta.env.VITE_UNLEASH_URL ?? '',
        });
    }

    const context = useMemo(
        () => ({
            userId: operator?.id.toString() ?? principal.subject,
            properties: {
                env_id: getEnvId() ?? '',
                stage: import.meta.env.VITE_ENV_NAME ?? '',
                operatorEmail: operator?.email ?? '',
                pageProductLine: getProductTypeFromUrl(pathname) ?? 'unknown',
                entity_type: getEntityTypeFromUrl(pathname) ?? 'unknown',
            },
        }),
        [operator?.email, operator?.id, pathname, principal.subject]
    );

    useEffect(() => {
        clientRef.current?.setContext(context);
    }, [context]);

    if (operator?.id.toString() === '449' || operator?.id.toString() === '229') {
        console.log(JSON.stringify(clientRef.current));
    }

    return clientRef.current;
}

export const EnsureFlags: React.FC<React.PropsWithChildren<EnsureFlagsProps>> = ({ children }) => {
    const unleashClient = useUnleashClient();

    return <FlagsProvider client={unleashClient}>{children}</FlagsProvider>;
};
