import { useMemo } from 'react';
import { useAttachmentsData } from '../context';
import type { AttachmentDTO } from '../types';
import { useCarousel } from './Carousel/CarouselProvider';

export const useGetAttachment: () => AttachmentDTO | null = () => {
    const { attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(
        () => (attachments.length === 0 ? null : attachments[currentIndex]),
        [attachments, currentIndex]
    );

    return attachment;
};
