import type { TooltipContentProps } from '@radix-ui/react-tooltip';
import { Content, Portal, Provider, Root, Trigger } from '@radix-ui/react-tooltip';
import { clsx } from 'clsx';
import type { FC, ReactNode } from 'react';
import { Text } from '../../base/Text/Text';
import * as styles from './Tooltip.css';

export interface TooltipProps {
  readonly children: ReactNode;
  readonly content: ReactNode;
  /** Whether the tooltip should be disabled, independent from the trigger. */
  readonly disabled?: boolean;
  readonly title?: string;
  readonly side?: TooltipContentProps['side'];
  readonly align?: TooltipContentProps['align'];
  readonly defaultOpen?: boolean;
  readonly maxWidth?: string;
  readonly className?: string;
}

/**
 * A tooltip displays a description of an element on hover or focus.
 */
export const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  disabled = false,
  title,
  side,
  align,
  defaultOpen,
  maxWidth,
  className,
}) => {
  return (
    <Provider delayDuration={200}>
      <Root defaultOpen={defaultOpen} open={disabled ? false : undefined}>
        <Trigger asChild>{typeof children === 'string' ? <span>{children}</span> : children}</Trigger>

        <Portal>
          <Content
            align={align}
            className={clsx(styles.content, className)}
            side={side}
            sideOffset={5}
            style={{ maxWidth }}
          >
            {title != null && (
              <Text color="light" fontWeight="bold" type="text-md" wordBreak="break-word">
                {title}
              </Text>
            )}
            {typeof content === 'string' ? (
              <Text color="light" type="text-md">
                {content}
              </Text>
            ) : (
              content
            )}
          </Content>
        </Portal>
      </Root>
    </Provider>
  );
};
