import type { EntityTypes } from '@lemonade-hq/bluiza';
import type { QueryKey } from '@tanstack/react-query';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { AttachmentDTO } from './types';

interface Props {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly attachments?: AttachmentDTO[];
    readonly invalidateKeys?: QueryKey[];
}

interface Context {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly attachments: AttachmentDTO[];
    readonly invalidateKeys?: QueryKey[];
}

const AttachmentsContext = createContext<Context | null>(null);

export const AttachmentsProvider: FC<PropsWithChildren<Props>> = ({
    entityPublicId = '',
    entityType,
    attachments,
    invalidateKeys = [],
    children,
}) => {
    const value = useMemo(
        () => ({
            entityType,
            entityPublicId,
            attachments: attachments ?? [],
            invalidateKeys,
        }),
        [entityType, entityPublicId, attachments, invalidateKeys]
    );

    return <AttachmentsContext.Provider value={value}>{children}</AttachmentsContext.Provider>;
};

export const useAttachmentsData = (): Context => {
    const context = useContext(AttachmentsContext);

    if (!context) {
        throw new Error('useAttachmentsData must be used within a AttachmentsGalleryProvider');
    }

    return context;
};
