import type { ActionsMenuItem } from '@lemonade-hq/blender-ui';
import { ActionsMenu } from '@lemonade-hq/blender-ui';
import { trackEvent } from '@lemonade-hq/boutique';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { useAttachmentsData } from 'components/Attachments/context';
import type { AttachmentActionType, AttachmentDTO } from 'components/Attachments/types';
import { getAttachmentAnalyticsParam } from 'components/Attachments/utils';

export interface AttachmentActionsMenuProps {
    readonly attachment: AttachmentDTO;
    readonly setActionProps: (props: {
        readonly action: AttachmentActionType;
        readonly attachments: AttachmentDTO[];
        readonly source: 'button' | 'single_row_menu';
    }) => void;
}

export const AttachmentActionsMenu: FC<AttachmentActionsMenuProps> = ({ attachment, setActionProps }) => {
    const { entityPublicId, entityType } = useAttachmentsData();

    const onClick = useCallback(
        (action: AttachmentActionType) => () => {
            trackEvent('docs.clicked', {
                ...getAttachmentAnalyticsParam({
                    attachment,
                    entityType,
                    entityId: entityPublicId,
                    source: 'single_row_menu',
                }),
                action: 'click',
                action_type: action,
            });
            setActionProps({
                action,
                attachments: [attachment],
                source: 'single_row_menu',
            });
        },
        [attachment, entityPublicId, entityType, setActionProps]
    );

    const items = useMemo<ActionsMenuItem[]>(
        () =>
            attachment.actions
                ?.filter(it => it !== 'check_for_modifications')
                .map(action => ({
                    label: snakeCaseToReadable(action),
                    onClick: onClick(action),
                })) ?? [],
        [attachment.actions, onClick]
    );

    if (items.length === 0) return null;

    return <ActionsMenu items={items} />;
};
