import type { UseMutationResult, UseQueryResult, UseSuspenseQueryResult } from '@tanstack/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { insuranceBlender } from '../../../apiClients';
import type { GetEditionsForChangesLogResponse } from './ChangesLogQueries';
import { getEditionsForChangesLog } from './ChangesLogQueries';
import type { Pagination } from './QueriesShared';
import { useSuspenseQueryWithErrorHandling } from 'components/LoCo/common/hooks/useSuspenseQueryWithErrorHandling';
import type { PaginationResult } from 'models/CarShared';
import type { VersionType } from 'models/LoCo/Insurance/BaseEdition';
import type { ChangesLogResponse } from 'models/LoCo/Insurance/ChangesLog';
import type { EditionSet, EditionSetEnvOverride } from 'models/LoCo/Insurance/EditionSets';
import type { EditionSetCompatibilityResponse } from 'models/LoCo/Insurance/Product';
import type { SchemaResponse } from 'models/LoCo/Insurance/Schema';
import { usePessimisticMutation } from 'queries/MutationHooks';

const EDITION_SETS_BASE_PATH = '/api/v1/edition-sets';

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE = 1;

const DEFAULT_PAGINATION: Pagination = {
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
};

enum EditionSetQueryKey {
    GetEditionSet = 'GET_EDITION_SET',
    GetEditionSetViolations = 'GET_EDITION_SET_VIOLATIONS',
    ClearEnvAvailability = 'CLEAR_ENV_AVAILABILITY',
    ChangesLog = 'EDITION_SETS_CHANGES_LOG',
    GetEditionSetSchema = 'GET_EDITION_SET_SCHEMA',
    GetEnvsOverrides = 'GET_ENVS_OVERRIDES',
    GetLatestMinorEditionSets = 'GET_LATEST_MINOR_EDITION_SETS',
    CreateEditionSetTest = 'CREATE_EDITION_SET_TEST',
    ActivateEditionSetInEnv = 'ACTIVATE_EDITION_SET_IN_ENV',
    ArchiveEditionSet = 'ARCHIVE_EDITION_SET',
    DeactivateEditionSet = 'DEACTIVATE_EDITION_SET',
    GetEditionSetEnvOverrides = 'GET_EDITION_SET_ENV_OVERRIDES',
    UpdateEnvOverride = 'UPDATE_ENV_OVERRIDE',
}

interface CreateEditionSetTestRequest {
    readonly coveragesEditionCode: string;
    readonly digitalAgentEditionCode: string;
    readonly ratingEditionCode: string;
    readonly underwritingFiltersEditionCode: string;
    readonly newBusinessEffectiveAt: string;
    readonly renewalEffectiveAt: string;
    readonly platformSchemaRevision: number;
    readonly productSchemaRevision: number;
    readonly versionType: VersionType;
    readonly versionMajor: number;
    readonly versionMinor: number;
}

interface ActivateEnvRequest {
    readonly editionSetCodes: string[];
    readonly envStage: 'development' | 'staging';
    readonly envName: string;
    readonly daysUntilExpiration: number;
}

export function useSuspenseGetEditionSet(code: string): UseSuspenseQueryResult<EditionSet> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [EditionSetQueryKey.GetEditionSet, code],
        queryFn: async () => {
            return await getEditionSet(code);
        },
    });
}

async function getEditionSetViolations(code: string): Promise<EditionSetCompatibilityResponse> {
    return await insuranceBlender
        .get<{ data: EditionSetCompatibilityResponse }>(`${EDITION_SETS_BASE_PATH}/${code}/violations`)
        .then(response => response.data.data);
}

export function useSuspenseGetEditionSetViolations(
    code: string
): UseSuspenseQueryResult<EditionSetCompatibilityResponse> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [EditionSetQueryKey.GetEditionSetViolations, code],
        queryFn: async () => {
            return await getEditionSetViolations(code);
        },
    });
}

export async function getEditionSetChangesLog(code: string): Promise<ChangesLogResponse> {
    return await insuranceBlender
        .get<{ data: ChangesLogResponse }>(`${EDITION_SETS_BASE_PATH}/${code}/changes-log`)
        .then(response => response.data.data);
}

export async function getEditionSet(code: string): Promise<EditionSet> {
    return await insuranceBlender
        .get<{ data: EditionSet }>(`${EDITION_SETS_BASE_PATH}/${code}`)
        .then(response => response.data.data);
}

export function useGetEditionSet(code?: string | null): UseQueryResult<EditionSet> {
    return useQuery({
        queryKey: [EditionSetQueryKey.GetEditionSet, code],
        queryFn: async () => await getEditionSet(code ?? ''),
        enabled: Boolean(code),
    });
}

export async function getEditionSetSchema(code: string): Promise<SchemaResponse> {
    return await insuranceBlender
        .get<{ data: SchemaResponse }>(`${EDITION_SETS_BASE_PATH}/${code}/schema`)
        .then(response => response.data.data);
}

export function useGetEditionSetSchema(code?: string | null): UseQueryResult<SchemaResponse> {
    return useQuery({
        queryKey: [EditionSetQueryKey.GetEditionSetSchema, code],
        queryFn: async () => await getEditionSetSchema(code ?? ''),
        enabled: Boolean(code),
    });
}

interface UseGetEditionSetChangesLogDataSuspenseResponse extends GetEditionsForChangesLogResponse {
    readonly changesLog: ChangesLogResponse;
    readonly baseEditionSet: EditionSet | null;
}

export function useGetEditionSetChangesLogDataSuspense(
    editionSet: EditionSet
): UseSuspenseQueryResult<UseGetEditionSetChangesLogDataSuspenseResponse> {
    return useSuspenseQuery({
        queryKey: [EditionSetQueryKey, editionSet.code],
        queryFn: async () => {
            const baseEditionSet =
                editionSet.baseEditionSetCode !== null ? await getEditionSet(editionSet.baseEditionSetCode) : null;

            const [changesLog, editions] = await Promise.all([
                getEditionSetChangesLog(editionSet.code),
                getEditionsForChangesLog({
                    coveragesEditionCode: editionSet.coveragesEdition.code,
                    digitalAgentEditionCode: editionSet.digitalAgentEdition.code,
                    underwritingFiltersEditionCode: editionSet.underwritingFiltersEdition.code,
                    baseCoveragesEditionCode: baseEditionSet?.coveragesEdition.code,
                    baseDigitalAgentEditionCode: baseEditionSet?.digitalAgentEdition.code,
                    baseUnderwritingFiltersEditionCode: baseEditionSet?.underwritingFiltersEdition.code,
                }),
            ]);

            return {
                changesLog,
                baseEditionSet,
                ...editions,
            } satisfies UseGetEditionSetChangesLogDataSuspenseResponse;
        },
    });
}

export interface GetEnvsOverridesRequest {
    readonly envStage?: 'development' | 'staging';
    readonly envName?: string;
    readonly size?: number;
    readonly page?: number;
}

interface GetEnvsOverridesResponse {
    readonly data: EditionSetEnvOverride[];
    readonly stats: {
        readonly page: number;
        readonly totalPages: number;
    };
}

export async function getLatestMinorEditionSets(productCode: string): Promise<EditionSet[]> {
    return await insuranceBlender
        .get<{ data: EditionSet[] }>(`${EDITION_SETS_BASE_PATH}/latest-minors?productCode=${productCode}`)
        .then(response => response.data.data);
}

export function useGetLatestMinorEditionSets(
    productCode: string,
    onSuccess: (editionSets: EditionSet[]) => void
): UseQueryResult<EditionSet[]> {
    return useQuery({
        queryKey: [EditionSetQueryKey.GetLatestMinorEditionSets, productCode],
        queryFn: async () =>
            await getLatestMinorEditionSets(productCode).then(editionSets => {
                onSuccess(editionSets);
                return editionSets;
            }),
        enabled: Boolean(productCode),
    });
}

async function createEditionSetTest(request: CreateEditionSetTestRequest): Promise<{ readonly code: string }> {
    return await insuranceBlender
        .post<{ data: { code: string } }>(`${EDITION_SETS_BASE_PATH}/create-test`, request)
        .then(response => response.data.data);
}

export function useCreateEditionSetTest(): UseMutationResult<
    { readonly code: string },
    unknown,
    CreateEditionSetTestRequest,
    null
> {
    return usePessimisticMutation({
        mutationFn: createEditionSetTest,
        invalidateKeys: [],
        mutationKey: [EditionSetQueryKey.CreateEditionSetTest],
    });
}

async function archiveEditionSet(code: string): Promise<void> {
    await insuranceBlender.put(`${EDITION_SETS_BASE_PATH}/${code}/archive`);
}

export function useArchiveEditionSet(code?: string): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: archiveEditionSet,
        invalidateKeys: [[EditionSetQueryKey.GetEditionSet, code]],
        mutationKey: [EditionSetQueryKey.ArchiveEditionSet],
    });
}

interface EditionSetEnvOverrideFilter {
    readonly editionSetCodes?: string[];
    readonly isActive?: boolean;
}

export function useSuspenseGetEditionSetEnvOverrides(
    filters: EditionSetEnvOverrideFilter,
    { page = DEFAULT_PAGE, size = DEFAULT_PAGE_SIZE }: Pagination = DEFAULT_PAGINATION
): UseSuspenseQueryResult<PaginationResult<EditionSetEnvOverride>> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [EditionSetQueryKey.GetEditionSetEnvOverrides, filters, page, size],
        queryFn: async () => {
            return await insuranceBlender
                .get<
                    PaginationResult<EditionSetEnvOverride>
                >(`${EDITION_SETS_BASE_PATH}/env-overrides`, { params: { ...filters, page, size } })
                .then(response => response.data);
        },
    });
}

export async function getEnvsOverrides(params?: GetEnvsOverridesRequest): Promise<GetEnvsOverridesResponse> {
    return await insuranceBlender
        .get<GetEnvsOverridesResponse>(`${EDITION_SETS_BASE_PATH}/enriched-env-overrides`, { params })
        .then(response => response.data);
}

export function useGetEnvsOverrides(params?: GetEnvsOverridesRequest): UseQueryResult<GetEnvsOverridesResponse> {
    return useQuery({
        queryKey: [EditionSetQueryKey.GetEnvsOverrides, params],
        queryFn: async () => await getEnvsOverrides(params),
        enabled: [params?.envStage, params?.envName, params?.size, params?.page].every(Boolean),
    });
}

async function activateEditionSetInEnv(request: ActivateEnvRequest): Promise<void> {
    await insuranceBlender.post(`${EDITION_SETS_BASE_PATH}/env-overrides/activate`, request);
}

export function useActivateEditionSetInEnv(): UseMutationResult<void, unknown, ActivateEnvRequest, null> {
    return usePessimisticMutation({
        mutationFn: activateEditionSetInEnv,
        invalidateKeys: [[EditionSetQueryKey.GetEnvsOverrides], [EditionSetQueryKey.GetEditionSetEnvOverrides]],
        mutationKey: [EditionSetQueryKey.ActivateEditionSetInEnv],
    });
}

async function deactivateEditionSet(envOverridePublicId: string): Promise<void> {
    await insuranceBlender.put(
        `${EDITION_SETS_BASE_PATH}/env-overrides/deactivate?envOverridePublicId=${envOverridePublicId}`
    );
}

export function useDeactivateEditionSet(): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutation({
        mutationFn: deactivateEditionSet,
        invalidateKeys: [[EditionSetQueryKey.GetEnvsOverrides], [EditionSetQueryKey.GetEditionSetEnvOverrides]],
        mutationKey: [EditionSetQueryKey.DeactivateEditionSet],
    });
}

async function updateEnvOverride({
    envOverridePublicId,
    expirationDate,
}: {
    readonly envOverridePublicId: string;
    readonly expirationDate: string;
}): Promise<void> {
    await insuranceBlender.put(`${EDITION_SETS_BASE_PATH}/env-overrides?envOverridePublicId=${envOverridePublicId}`, {
        expirationDate,
    });
}

export function useUpdateEnvOverride(): UseMutationResult<
    void,
    unknown,
    {
        readonly envOverridePublicId: string;
        readonly expirationDate: string;
    },
    null
> {
    return usePessimisticMutation({
        mutationFn: updateEnvOverride,
        invalidateKeys: [[EditionSetQueryKey.GetEnvsOverrides], [EditionSetQueryKey.GetEditionSetEnvOverrides]],
        mutationKey: [EditionSetQueryKey.UpdateEnvOverride],
    });
}
