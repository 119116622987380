import { DateTimePicker, Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import { Dialog } from '@lemonade-hq/bluis';
import { addDays } from 'date-fns';
import { useCallback, useState } from 'react';
import { TestInStagingProvider } from '../releases/dialogs/TestDialog';
import type { EditionSetEnvOverride } from 'models/LoCo/Insurance/EditionSets';
import { useDeactivateEditionSet, useUpdateEnvOverride } from 'queries/LoCo/Insurance/EditionSetQueries';

const TEXTS = [
    'The Edition Set will be made available in the selected environment with its respective Effective Dates and Version Number.',
    'The Edition Set can be removed from the environment at any time via Blender.',
];

const minDate = addDays(new Date(), 1);

interface RemoveFromEnvDialogProps {
    readonly env: EditionSetEnvOverride;
    readonly onClose: () => void;
}

export const RemoveFromEnvDialog: React.FC<RemoveFromEnvDialogProps> = ({ env, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync: deactivateEditionSet } = useDeactivateEditionSet();
    const deactivate = useCallback(async () => {
        setIsLoading(true);
        await deactivateEditionSet(env.publicId);
        setIsLoading(false);
        onClose();
    }, [deactivateEditionSet, env.publicId, onClose]);

    return (
        <Dialog
            actions={[
                { type: 'close', text: 'Cancel', onClick: onClose },
                { type: 'submit', text: 'Remove from Env', onClick: deactivate },
            ]}
            loading={isLoading}
            size="medium"
            title="Remove from Environment"
        >
            <Text>The edition set will no longer be available in the environment:</Text>
            <br />
            <Text>
                &apos;{env.envName}&apos; ({env.envStage})
            </Text>
            <br />
            <br />
            <Text>It can be reactivated any time via Blender.</Text>
        </Dialog>
    );
};

export const ChangeExpirationDialog: React.FC<RemoveFromEnvDialogProps> = ({ env, onClose }) => {
    const [date, setDate] = useState<Date>(new Date(env.expirationDate));
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync: updateEnvOverride } = useUpdateEnvOverride();

    const updateExpirationDate = useCallback(async () => {
        setIsLoading(true);
        await updateEnvOverride({ envOverridePublicId: env.publicId, expirationDate: date.toDateString() });
        setIsLoading(false);
        onClose();
    }, [updateEnvOverride, env.publicId, date, onClose]);

    return (
        <Dialog
            actions={[
                { type: 'close', text: 'Cancel', onClick: onClose },
                { type: 'submit', text: 'Update Expiration Date', onClick: updateExpirationDate },
            ]}
            loading={isLoading}
            size="medium"
            title="Remove from Environment"
        >
            <Flex alignItems="center" flexDirection="column" gap={spacing.s12}>
                <Flex alignItems="center" flexDirection="column">
                    <Text>The edition set will be available in the environment:</Text>
                    <Text>
                        &apos;{env.envName}&apos; ({env.envStage})
                    </Text>
                </Flex>
                <Text>Until the selected expiration date:</Text>
                <Flex alignSelf="center" width={200}>
                    <DateTimePicker minDate={minDate} onChange={setDate} value={date} />
                </Flex>
            </Flex>
        </Dialog>
    );
};

interface DialogsProps {
    readonly dialogType: 'changeExpiration' | 'reactivate' | 'remove';
    readonly env?: EditionSetEnvOverride;
    readonly editionSetCode?: string;
    readonly onClose: () => void;
}

export const Dialogs: React.FC<DialogsProps> = ({ dialogType, env, editionSetCode, onClose }) => {
    let editionSetsCodes;

    switch (dialogType) {
        case 'reactivate':
            editionSetsCodes =
                env != null ? [env.editionSetCode] : editionSetCode != null ? [editionSetCode] : undefined;
            if (editionSetsCodes != null) {
                return <TestInStagingProvider editionSetsCodes={editionSetsCodes} onClose={onClose} texts={TEXTS} />;
            }

            return null;
        case 'remove':
            if (env != null) {
                return <RemoveFromEnvDialog env={env} onClose={onClose} />;
            }

            return null;
        case 'changeExpiration':
            if (env != null) {
                return <ChangeExpirationDialog env={env} onClose={onClose} />;
            }

            return null;
        default:
            return null;
    }
};
