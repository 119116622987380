import { CommonLayer, Flex } from '@lemonade-hq/cdk';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import type { AttachmentsPreviewProps } from './CarouselPreview';
import { CarouselPreview } from './CarouselPreview';

export const StyledMask = styled(Flex)`
    position: fixed;
    z-index: ${CommonLayer.DialogForeground + 1};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #323232;
    width: 100%;
    flex-direction: column;
`;

export const AttachmentsPreviewComponent: React.FC<React.PropsWithChildren<AttachmentsPreviewProps>> = ({
    attachments,
    onClose,
    selectedIndex,
    onNavigation,
    attachmentTooltipProperty,
    infoPanel,
}) => {
    return (
        <StyledMask>
            <CarouselPreview
                attachmentTooltipProperty={attachmentTooltipProperty}
                attachments={attachments}
                infoPanel={infoPanel}
                onClose={onClose}
                onNavigation={onNavigation}
                selectedIndex={selectedIndex}
            />
        </StyledMask>
    );
};

const AttachmentsPreview: React.FC<React.PropsWithChildren<AttachmentsPreviewProps>> = props => {
    const { onClose } = props;

    useEffect(() => {
        const { overflow } = document.body.style;

        document.body.style.overflow = 'hidden';
        window.history.pushState({ page: 1 }, '');
        window.addEventListener('popstate', onClose);
        return () => {
            document.body.style.overflow = overflow;
            window.removeEventListener('popstate', onClose);
        };
    }, [onClose]);

    return createPortal(<AttachmentsPreviewComponent {...props} />, document.body);
};

export default AttachmentsPreview;
