import type { DialogAction, DialogNoticeType, DialogProps } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import { Text } from '@lemonade-hq/cdk';
import { useMemo } from 'react';

const useDeleteButtons = (onClose: () => void, onSubmit: () => Promise<void>, isLoading: boolean): DialogAction[] => {
    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Remove',
                type: 'submit',
                onClick: onSubmit,
                disabled: isLoading,
            },
        ],
        [isLoading, onClose, onSubmit]
    );

    return actions;
};

type RemoveEntityRulesDialogProps = DialogProps & {
    readonly onSubmit: () => Promise<void>;
    readonly onClose: () => void;
    readonly isLoading: boolean;
    readonly body?: string;
    readonly notice?: DialogNoticeType;
};

export const RemoveRuleDialog: React.FC<RemoveEntityRulesDialogProps> = ({
    onClose,
    onSubmit,
    isLoading,
    title,
    body,
    error,
    notice,
}) => {
    const actions = useDeleteButtons(onClose, onSubmit, isLoading);

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={error}
            loading={isLoading}
            notice={notice}
            onClose={onClose}
            title={title ?? `Remove rule`}
        >
            <Text variant="body-md">{body ?? 'Are you sure you want to remove this rule'}</Text>
        </Dialog>
    );
};
