import type { ActionOption } from '@lemonade-hq/bluis';
import { snakeToCamelCase } from '@lemonade-hq/ts-helpers';
import type { SetStateAction } from 'react';
import { CheckBackwardsCompatibilityDialog } from 'components/LoCo/editions/coverage-editions/BackwardsCompatibility/CheckBackwardsCompatibilityDialog';
import { ApproveEditionDialogDraft } from 'components/LoCo/products/ProductMissionControl/dialogs/ApproveEditionDialogDraft';
import { ArchiveEditionDialogDraft } from 'components/LoCo/products/ProductMissionControl/dialogs/ArchiveEditionDialogDraft';
import { CreateDraftEditionDialog } from 'components/LoCo/products/ProductMissionControl/dialogs/CreateDraftEditionDialog';
import { SimulationDialog } from 'components/LoCo/products/SimulationDialog/SimulationDialog';
import { EditionChangesSummaryDialog } from 'components/LoCo/releases/ChangesSummary/EditionChangesSummaryDialog';
import { TestInEnvironmentProvider } from 'components/LoCo/releases/dialogs/TestInEnvironmentDialog';
import type { Edition, EditionWithType } from 'models/LoCo/Insurance/BaseEdition';
import { EditionStatus, EditionType } from 'models/LoCo/Insurance/BaseEdition';

export enum EditionDialogType {
    CloneEdition = 'cloneEdition',
    ArchiveEdition = 'archiveEdition',
    ApproveEdition = 'approveEdition',
    CheckBackwardsCompatibility = 'checkBackwardsCompatibility',
    EditionSetCompatibilityCheck = 'editionSetCompatibilityCheck',
    ReviewEditionChanges = 'reviewEditionChanges',
    TestInEnvironment = 'testInEnvironment',
}

export type EditionActionData =
    | {
          readonly type: EditionDialogType.ApproveEdition;
          readonly editionCode: string;
          readonly editionType: EditionType;
          readonly editionTrackerPublicId: string;
          readonly referenceEditionContentCode?: string;
      }
    | {
          readonly type: EditionDialogType.ArchiveEdition;
          readonly editionCode: string;
          readonly editionType: EditionType;
          readonly editionFriendlyName: string;
          readonly editionTrackerPublicId: string;
      }
    | {
          readonly type: EditionDialogType.CheckBackwardsCompatibility;
          readonly editionCode: string;
          readonly editionType: EditionType;
          readonly productName: string;
          readonly baseEditionCode?: string;
          readonly referenceEditionContentCode?: string;
      }
    | {
          readonly type: EditionDialogType.CloneEdition;
          readonly editionType: EditionType;
          readonly baseEditionCode: string;
      }
    | {
          readonly type: EditionDialogType.EditionSetCompatibilityCheck;
          readonly editionCode: string;
          readonly editionType: EditionType;
      }
    | {
          readonly type: EditionDialogType.ReviewEditionChanges;
          readonly editionCode: string;
          readonly baseEditionCode?: string;
          readonly editionType: EditionType;
      }
    | {
          readonly type: EditionDialogType.TestInEnvironment;
          readonly editionCode: string;
          readonly editionType: EditionType;
          readonly productName: string;
      };

const APPLICABLE_ACTION: Record<EditionType, EditionDialogType[]> = {
    [EditionType.Rating]: [
        EditionDialogType.ApproveEdition,
        EditionDialogType.EditionSetCompatibilityCheck,
        EditionDialogType.TestInEnvironment,
    ],
    [EditionType.Coverages]: [
        EditionDialogType.CloneEdition,
        EditionDialogType.ArchiveEdition,
        EditionDialogType.CheckBackwardsCompatibility,
        EditionDialogType.ApproveEdition,
        EditionDialogType.EditionSetCompatibilityCheck,
        EditionDialogType.ReviewEditionChanges,
        EditionDialogType.TestInEnvironment,
    ],
    [EditionType.DigitalAgent]: [
        EditionDialogType.CloneEdition,
        EditionDialogType.ArchiveEdition,
        EditionDialogType.ApproveEdition,
        EditionDialogType.EditionSetCompatibilityCheck,
        EditionDialogType.ReviewEditionChanges,
        EditionDialogType.TestInEnvironment,
    ],
    [EditionType.UnderwritingFilters]: [
        EditionDialogType.CloneEdition,
        EditionDialogType.ArchiveEdition,
        EditionDialogType.ApproveEdition,
        EditionDialogType.EditionSetCompatibilityCheck,
        EditionDialogType.ReviewEditionChanges,
        EditionDialogType.TestInEnvironment,
    ],
};

interface DialogsProps {
    readonly productCode: string;
    readonly dialogData: EditionActionData;
    readonly onClose: () => void;
}

const dialogDataOptions: {
    readonly [key in EditionDialogType]: (edition: EditionWithType, productName: string) => EditionActionData;
} = {
    [EditionDialogType.CloneEdition]: (edition: EditionWithType, _productName: string) => ({
        type: EditionDialogType.CloneEdition,
        editionType: edition.type,
        baseEditionCode: edition.code,
    }),
    [EditionDialogType.ArchiveEdition]: (edition: EditionWithType, _productName: string) => ({
        type: EditionDialogType.ArchiveEdition,
        editionType: edition.type,
        editionCode: edition.code,
        editionFriendlyName: edition.friendlyName,
        editionTrackerPublicId: edition.trackerPublicId,
    }),
    [EditionDialogType.ApproveEdition]: (edition: EditionWithType, _productName: string) => ({
        type: EditionDialogType.ApproveEdition,
        editionType: edition.type,
        editionCode: edition.code,
        referenceEditionContentCode: edition.referenceEditionContentCode,
        editionTrackerPublicId: edition.trackerPublicId,
    }),
    [EditionDialogType.CheckBackwardsCompatibility]: (edition: EditionWithType, productName: string) => ({
        type: EditionDialogType.CheckBackwardsCompatibility,
        editionType: edition.type,
        editionCode: edition.code,
        referenceEditionContentCode: edition.referenceEditionContentCode,
        productName,
    }),
    [EditionDialogType.EditionSetCompatibilityCheck]: (edition: EditionWithType, productName: string) => ({
        type: EditionDialogType.EditionSetCompatibilityCheck,
        editionType: edition.type,
        editionCode: edition.code,
        productName,
        baseEditionCode: edition.baseEditionCode,
    }),
    [EditionDialogType.ReviewEditionChanges]: (edition: EditionWithType, productName: string) => ({
        type: EditionDialogType.ReviewEditionChanges,
        editionType: edition.type,
        editionCode: edition.code,
        productName,
        baseEditionCode: edition.baseEditionCode,
    }),
    [EditionDialogType.TestInEnvironment]: (edition: EditionWithType, productName: string) => ({
        type: EditionDialogType.TestInEnvironment,
        editionType: edition.type,
        editionCode: edition.code,
        productName,
    }),
};

export function getEditionActions(
    edition: Edition,
    editionType: EditionType,
    productName: string,
    setDialogData: React.Dispatch<SetStateAction<EditionActionData | null>>
): ActionOption[] {
    const editionWithType = { ...edition, type: editionType };
    const editionApproved = edition.status === EditionStatus.Approved;
    const canCloneEdition = [EditionStatus.Approved, EditionStatus.Published].includes(edition.status);

    return [
        {
            label: 'Approve Edition',
            value: EditionDialogType.ApproveEdition,
            onClick: () =>
                setDialogData(dialogDataOptions[EditionDialogType.ApproveEdition](editionWithType, productName)),
            disabled: edition.status !== EditionStatus.Draft,
            hide: edition.status === EditionStatus.Published,
        },
        {
            label: 'Check Backwards Compatibility',
            value: EditionDialogType.CheckBackwardsCompatibility,
            onClick: () =>
                setDialogData(
                    dialogDataOptions[EditionDialogType.CheckBackwardsCompatibility](editionWithType, productName)
                ),
            disabled: editionApproved,
            hide: edition.status === EditionStatus.Published,
        },
        {
            label: 'Edition Set Compatibility Check',
            value: EditionDialogType.EditionSetCompatibilityCheck,
            onClick: () => {
                setDialogData(
                    dialogDataOptions[EditionDialogType.EditionSetCompatibilityCheck](editionWithType, productName)
                );
            },
        },
        {
            label: 'Review Edition Changes',
            value: EditionDialogType.ReviewEditionChanges,
            onClick: () =>
                setDialogData(dialogDataOptions[EditionDialogType.ReviewEditionChanges](editionWithType, productName)),
        },
        {
            label: 'Test in Environment',
            value: EditionDialogType.TestInEnvironment,
            onClick: () =>
                setDialogData(dialogDataOptions[EditionDialogType.TestInEnvironment](editionWithType, productName)),
            hide: edition.status === EditionStatus.Published || edition.status === EditionStatus.Archived,
        },
        {
            label: 'Clone',
            value: EditionDialogType.CloneEdition,
            disabled: !canCloneEdition,
            tooltip: !canCloneEdition ? 'Only approved edition can be cloned' : undefined,
            onClick: () =>
                setDialogData(dialogDataOptions[EditionDialogType.CloneEdition](editionWithType, productName)),
        },
        {
            label: 'Archive Edition',
            value: EditionDialogType.ArchiveEdition,
            onClick: () =>
                setDialogData(dialogDataOptions[EditionDialogType.ArchiveEdition](editionWithType, productName)),
            disabled: [EditionStatus.Published, EditionStatus.Archived].includes(edition.status),
            warning: true,
            hide: edition.status === EditionStatus.Published,
        },
    ].filter(action => APPLICABLE_ACTION[editionType].includes(action.value));
}

export const EditionActionsDialogs: React.FC<DialogsProps> = ({ productCode, dialogData, onClose }) => {
    switch (dialogData.type) {
        case EditionDialogType.CloneEdition:
            return (
                <CreateDraftEditionDialog
                    baseEditionCode={dialogData.baseEditionCode}
                    editionType={dialogData.editionType}
                    onClose={onClose}
                    productCode={productCode}
                />
            );
        case EditionDialogType.ArchiveEdition:
            return (
                <ArchiveEditionDialogDraft
                    editionFriendlyName={dialogData.editionFriendlyName}
                    editionTrackerPublicId={dialogData.editionTrackerPublicId}
                    editionType={dialogData.editionType}
                    onClose={onClose}
                    productCode={productCode}
                />
            );
        case EditionDialogType.ApproveEdition:
            return (
                <ApproveEditionDialogDraft
                    editionCode={dialogData.editionCode}
                    editionTrackerPublicId={dialogData.editionTrackerPublicId}
                    editionType={dialogData.editionType}
                    onClose={onClose}
                    productCode={productCode}
                    referenceEditionContentCode={dialogData.referenceEditionContentCode}
                />
            );
        case EditionDialogType.CheckBackwardsCompatibility:
            return (
                <CheckBackwardsCompatibilityDialog
                    editionCode={dialogData.editionCode}
                    onClose={onClose}
                    productCode={productCode}
                    productName={dialogData.productName}
                    referenceEditionContentCode={dialogData.referenceEditionContentCode}
                />
            );
        case EditionDialogType.EditionSetCompatibilityCheck: {
            const props = {
                [`${snakeToCamelCase(dialogData.editionType)}EditionCode`]: dialogData.editionCode,
            };

            return <SimulationDialog onClose={onClose} {...props} />;
        }

        case EditionDialogType.ReviewEditionChanges:
            return (
                <EditionChangesSummaryDialog
                    baseEditionCode={dialogData.baseEditionCode}
                    editionCode={dialogData.editionCode}
                    editionType={dialogData.editionType}
                    onClose={onClose}
                />
            );

        case EditionDialogType.TestInEnvironment:
            return (
                <TestInEnvironmentProvider
                    editionCode={dialogData.editionCode}
                    editionType={dialogData.editionType}
                    onClose={onClose}
                    productCode={productCode}
                />
            );

        default:
            return null;
    }
};
