import { useQuery, UseQueryResult, UseMutationResult } from '@tanstack/react-query';
import {
    addPayee,
    editPayee,
    PayeeToEdit,
    PayeeToAdd,
    deletePayee,
    updatePaymentToken,
    PayeeAccountDetails,
    PayeeToAddWithoutToken,
} from 'apis/PayeesAPITyped';
import { usePessimisticMutationLegacy } from 'queries/MutationHooks';
import { Payee, AvailablePayeeType } from 'models/Payee';
import { Vendor } from 'models/Vendor';
import { ProductTypes } from 'models/Products';

export enum PayeesQueryKeys {
    GetPayees = 'GET_PAYEES',
    GetAvailablePayees = 'GET_AVAILABLE_PAYEES',
    GetPayablePartiesTypes = 'GET_PAYABLE_PARTIES_TYPES',
    GetAvailableVendors = 'GET_AVAILABLE_VENDORS',
}

export function usePayees(
    queryKey: string[],
    queryFn: () => Promise<{ payees: Payee[]; editable_claim_payees: boolean }>,
    enabled = true
): UseQueryResult<{ payees: Payee[]; editable_claim_payees: boolean }> {
    return useQuery({
        queryKey: [PayeesQueryKeys.GetPayees, ...queryKey],
        queryFn,
        enabled,
    });
}

export function useAvailablePayeeTypes(
    queryKey: string[],
    queryFn: () => Promise<AvailablePayeeType[]>,
    enabled?: boolean
): UseQueryResult<AvailablePayeeType[]> {
    return useQuery({
        queryKey: [PayeesQueryKeys.GetAvailablePayees, ...queryKey],
        queryFn,
        enabled: enabled ?? true,
    });
}

export function usePayablePartiesTypes(
    queryKey: string[],
    queryFn: () => Promise<string[]>,
    enabled?: boolean
): UseQueryResult<string[]> {
    return useQuery({
        queryKey: [PayeesQueryKeys.GetPayablePartiesTypes, ...queryKey],
        queryFn,
        enabled: enabled ?? true,
    });
}

export function useAddPayeeMutation(
    getPayeesQueryKey: string[],
    getAvailablePayeesQueryKey: string[]
): UseMutationResult<null, unknown, { payee: PayeeToAdd | PayeeToAddWithoutToken; claimPublicId: string }, null> {
    return usePessimisticMutationLegacy(addPayee, [
        [PayeesQueryKeys.GetPayees, ...getPayeesQueryKey],
        [PayeesQueryKeys.GetAvailablePayees, ...getAvailablePayeesQueryKey],
    ]);
}

export function useDeletePayeeMutation(
    getPayeesQueryKey: string[],
    getAvailablePayeesQueryKey: string[]
): UseMutationResult<null, unknown, { payeeId: string }, null> {
    return usePessimisticMutationLegacy(deletePayee, [
        [PayeesQueryKeys.GetPayees, ...getPayeesQueryKey],
        [PayeesQueryKeys.GetAvailablePayees, ...getAvailablePayeesQueryKey],
    ]);
}

export function useEditPayeeMutation(
    getPayeesQueryKey: string[],
    getAvailablePayeesQueryKey: string[]
): UseMutationResult<null, unknown, { payee: PayeeToEdit; product: ProductTypes }, null> {
    return usePessimisticMutationLegacy(editPayee, [
        [PayeesQueryKeys.GetPayees, ...getPayeesQueryKey],
        [PayeesQueryKeys.GetAvailablePayees, ...getAvailablePayeesQueryKey],
    ]);
}

export function useUpdatePaymentTokenMutation(
    getPayeesQueryKey: string[]
): UseMutationResult<null, unknown, { payee: PayeeAccountDetails & { token: string }; product: ProductTypes }, null> {
    return usePessimisticMutationLegacy(updatePaymentToken, [[PayeesQueryKeys.GetPayees, ...getPayeesQueryKey]]);
}

export function useAvailableVendors(
    queryKey: string[],
    queryFn: () => Promise<Vendor[]>,
    enabled?: boolean
): UseQueryResult<Vendor[]> {
    return useQuery({
        queryKey: [PayeesQueryKeys.GetAvailableVendors, ...queryKey],
        queryFn,
        enabled: enabled ?? true,
    });
}
