import { useOptimisticMutation } from '@lemonade-hq/blender-ui';
import type { UseBaseMutationResult, UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { InteractionQueryKeys } from '../InteractionsQueries';
import { usePessimisticMutationLegacy } from '../MutationHooks';
import { ClaimQueryKeys, ClaimQueryKeys as PetBlenderClaimQueryKeys } from './PetBlenderClaimQueries';
import { PetMedicalExamReviewQueries } from './PetMedicalExamReviewQueries';
import type { SendEmailArgsWithEntityId } from 'apis/EmailsAPI';
import { removeCustomRecipient } from 'apis/InteractionsAPI';
import type { AddWorkflowCustomTaskParams, AddWorkflowTaskParams } from 'apis/Pet/PetAPI';
import { addPetWorkflowsCustomTask, addPetWorkflowsTask } from 'apis/Pet/PetAPI';
import { updateAttachments } from 'apis/Pet/PetAttachmentsAPI';
import { reopenClaim as petBlenderReopenClaim } from 'apis/Pet/PetBlenderClaimAPI';
import type { Alert, PetClaimResponse } from 'apis/Pet/PetClaimsAPI';
import {
    addCustomRecipient,
    assignAdjuster,
    assignInteraction,
    cancelAutoApproval,
    cancelAutoDecline,
    cancelClaim,
    cancelFirstResponse,
    closeClaim,
    connectClaimToPreapproval,
    createNote,
    disconnectClaimFromPreapproval,
    dismissInteraction,
    dismissItemListIncomplete,
    editClaim,
    exportClaimPdf,
    fetchPetClaimData,
    fetchPetRejectionReasons,
    getFirstResponse,
    getPetClaimNotifications,
    getPetClaimsByPetId,
    manualResponseInteraction,
    markAsErrorItem,
    markSettled,
    sendEmail,
    sendFirstResponse,
    simulateAutoApproval,
    simulateAutoDecline,
} from 'apis/Pet/PetClaimsAPI';
import {
    getPetIncompletionReasons,
    getPetMedicalExamReview,
    updateMedicalExamReview,
} from 'apis/Pet/PetMedicalExamReviewAPI';
import type { TreatmentPlaceDTO } from 'apis/Pet/PetTreatmentPlaceAPI';
import {
    attachTreatmentPlaceToClaim,
    createTreatmentPlace,
    getClaimTreatmentPlaces,
    getUserTreatmentPlaces,
    updateTreatmentPlace,
} from 'apis/Pet/PetTreatmentPlaceAPI';
import { getClaimUserVets, getUserVets } from 'apis/Pet/PetUserVetAPI';
import { assignTicketToEntity } from 'apis/ZendeskAPI';
import type { AssignTicketToEntityParams } from 'apis/ZendeskAPI';
import type { HttpError } from 'commons/HttpUtils';
import type { IncompletionReason, MedicalExamReview } from 'components/PetPolicy/PreExistingConditions/types';
import type { Adjuster } from 'models/Adjuster';
import type { Attachment } from 'models/Attachment';
import type { FirstResponse, NoteCreateRequest } from 'models/Interaction';
import type { PetClaim, PetRejectionReasonsItem, TreatmentPlace } from 'models/PetClaim';
import type { FeaturesAndItems } from 'models/PetFeaturesAndItems';
import type { Recipient } from 'models/Recipient';

export enum PetClaimQueryKeys {
    GetPetClaim = 'GET_PET_CLAIM',
    GetPetClaimUser = 'GET_PET_CLAIM_USER',
    GetPetClaimFeatures = 'GET_PET_CLAIM_FEATURES',
    GetPetFirstResponse = 'GET_PET_FIRST_RESPONSE',
    GetPetClaimsPriorities = 'GET_PET_CLAIMS_PRIORITIES',
    GetPetClaimFeesLogs = 'GET_PET_CLAIM_FEES_LOGS',
    GetProcedureToInstantClaimErrorMap = 'GET_PROCEDURE_TO_INSTANT_CLAIM_ERROR_MAP',
    GetClaimsByPetId = 'GET_CLAIMS_BY_PET_ID',
    GetRejectedReasons = 'GET_PET_REJECTED_REASONS',
    GetPetPreExistingConditions = 'GET_PET_PRE_EXISTING_CONDITIONS',
    GetPetTreatmentPlaces = 'GET_PET_TREATMENT_PLACES',
    GetPetTreatmentPlacesByClaim = 'GET_PET_TREATMENT_PLACES_BY_CLAIM',
    GetPetCoverageState = 'GET_PET_COVERAGE_STATE',
    GetMedicalExamReview = 'GET_MEDICAL_EXAM_REVIEW',
    GetIncompletionReasons = 'GET_INCOMPLETION_REASONS',
    GetPetClaimNotifications = 'GET_PET_CLAIM_NOTIFICATIONS',
    GetPetPossibleRecipients = 'GET_PET_POSSIBLE_RECIPIENTS',
    GetPetUserVetsByClaim = 'GET_PET_USER_VETS_BY_CLAIM',
    GetPetUserVetsByUser = 'GET_PET_USER_VETS_BY_USER',
    GetPaymentInfo = 'GET_PAYMENT_INFO',
}

export type PetClaimData = Pick<
    PetClaimResponse,
    'adjusters' | 'available_claim_types' | 'claim_coverages' | 'claim' | 'policy' | 'timezone' | 'user'
>;

export type PetPossibleRecipients = Pick<PetClaimResponse, 'possible_recipients_v2'>;

async function getClaimData(claimId: string): Promise<PetClaimData> {
    return await fetchPetClaimData(claimId, [
        'claim',
        'timezone',
        'policy',
        'user',
        'available_claim_types',
        'adjusters',
        'claim_coverages',
    ]);
}

export type AttachmentsToUpdate = Partial<Pick<Attachment, 'description' | 'type'>> & {
    readonly file_public_id: string;
};

export function usePetClaimUpdateAttachmentMutation(claimId: string): UseMutationResult<
    Attachment[],
    unknown,
    {
        readonly attachments: AttachmentsToUpdate[];
    },
    null
> {
    const cache = useQueryClient();
    const queryKey = [PetClaimQueryKeys.GetPetClaim, claimId];

    function onSuccess(attachments: Attachment[]) {
        cache.setQueryData<PetClaimData>(queryKey, data => {
            return produce(data!, draftData => {
                attachments.forEach(attachment => {
                    const draftAttachment = [...draftData.claim.attachments].find(
                        att => att.file_public_id === attachment.file_public_id
                    );

                    if (draftAttachment) {
                        draftAttachment.type = attachment.type;
                        draftAttachment.description = attachment.description;
                    }
                });
            });
        });
    }

    return usePessimisticMutationLegacy(
        updateAttachments,
        [[queryKey], [ClaimQueryKeys.GetClaimReceiptData, claimId]],
        onSuccess
    );
}

export function usePetClaim(claimId: string): UseQueryResult<PetClaimData, HttpError> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetPetClaim, claimId],
        queryFn: async () => await getClaimData(claimId),
    });
}

export function usePetPossibleRecipients(claimId: string): UseQueryResult<PetPossibleRecipients> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetPetPossibleRecipients, claimId],
        queryFn: async () => await fetchPetClaimData(claimId, ['possible_recipients_v2']),
    });
}

export function usePetClaimNotifications(claimId: string): UseQueryResult<Alert[], HttpError> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetPetClaimNotifications, claimId],
        queryFn: async () => await getPetClaimNotifications(claimId),
    });
}

export function useRefreshClaim(claimId: string): () => void {
    const cache = useQueryClient();

    return () => {
        void cache.invalidateQueries({ queryKey: [PetClaimQueryKeys.GetPetClaim, claimId] });
    };
}

export function useRefreshAttachments(claimId: string): () => void {
    const cache = useQueryClient();

    return () => {
        void cache.invalidateQueries({ queryKey: [ClaimQueryKeys.GetClaimReceiptData, claimId] });
    };
}

export function useRefreshClaimAssignee(claimId: string): () => void {
    const cache = useQueryClient();

    return () => {
        void cache.invalidateQueries({ queryKey: [PetBlenderClaimQueryKeys.GetClaimAssignee, claimId] });
    };
}

async function getPetClaimUser(claimId: string): Promise<Pick<PetClaimResponse, 'user'>> {
    return await fetchPetClaimData(claimId, ['user']);
}

export function usePetClaimUser(claimId: string): UseQueryResult<Pick<PetClaimResponse, 'user'>> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetPetClaimUser, claimId],
        queryFn: async () => await getPetClaimUser(claimId),
    });
}

async function getClaimFeesLogs(claimId: string): Promise<Pick<PetClaimResponse, 'claim_adjuster_fees'>> {
    return await fetchPetClaimData(claimId, ['claim_adjuster_fees']);
}

export function useClaimFeesLog(claimId: string): UseQueryResult<Pick<PetClaimResponse, 'claim_adjuster_fees'>> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetPetClaimFeesLogs, claimId],
        queryFn: async () => await getClaimFeesLogs(claimId),
    });
}

export function useFirstResponse(claimId: string): UseQueryResult<FirstResponse> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetPetFirstResponse, claimId],
        queryFn: async () => await getFirstResponse(claimId),
    });
}

export function useRejectionReasons(): UseQueryResult<PetRejectionReasonsItem[]> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetRejectedReasons],
        queryFn: fetchPetRejectionReasons,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
}

export function useExistingMedicalExamReview(petPublicId: string): UseQueryResult<MedicalExamReview> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetMedicalExamReview, petPublicId],
        queryFn: async () => await getPetMedicalExamReview(petPublicId),
    });
}

export function useIncompletionReasons(petPublicId: string): UseQueryResult<IncompletionReason[]> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetIncompletionReasons, petPublicId],
        queryFn: async () => await getPetIncompletionReasons(petPublicId),
    });
}

export function useMedicalExamReviewMutation(petPublicId: string): UseMutationResult<
    MedicalExamReview,
    unknown,
    {
        readonly petPublicId: string;
        readonly medicalExamReview: MedicalExamReview;
    },
    null
> {
    return usePessimisticMutationLegacy(updateMedicalExamReview, [
        [PetClaimQueryKeys.GetMedicalExamReview, petPublicId],
        [PetMedicalExamReviewQueries.GetMedicalExamReviews, petPublicId],
    ]);
}

// This hook is also used for sending Preapprovals - hence the entityType.
// The common endpoint for both Pet Preapproval and Pet Claim is in use till a common one will be introduced at Blender General.
export function useSendEmailMutation(
    claimId: string
): UseBaseMutationResult<void, unknown, SendEmailArgsWithEntityId, null> {
    return usePessimisticMutationLegacy(sendEmail, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimId],
        [(PetClaimQueryKeys.GetPetClaimsPriorities, claimId)],
        [PetClaimQueryKeys.GetPetClaim, claimId],
    ]);
}

export function useCancelFirstResponseMutation(claimId: string): UseMutationResult<
    null,
    unknown,
    {
        readonly claimId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(cancelFirstResponse, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimId],
        [PetClaimQueryKeys.GetPetFirstResponse, claimId],
    ]);
}

export function useSendFirstResponseMutation(claimId: string): UseMutationResult<
    null,
    unknown,
    {
        readonly claimId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(sendFirstResponse, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimId],
        [PetClaimQueryKeys.GetPetFirstResponse, claimId],
    ]);
}

type ClaimFeaturesData = Pick<
    PetClaimResponse,
    'available_feature_types' | 'claim_features' | 'coinsurance_percent' | 'editable_claim_items'
>;

async function getClaimFeatures(claimId: string): Promise<ClaimFeaturesData> {
    return await fetchPetClaimData(claimId, [
        'available_feature_types',
        'editable_claim_items',
        'claim_features',
        'coinsurance_percent',
    ]);
}

export function useClaimFeatures(claimId: string): UseQueryResult<ClaimFeaturesData> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetPetClaimFeatures, claimId],
        queryFn: async () => await getClaimFeatures(claimId),
    });
}

async function getProcedureToInstantClaimErrorMap(
    claimId: string
): Promise<Pick<PetClaimResponse, 'procedure_to_instant_claim_error_map'>> {
    return await fetchPetClaimData(claimId, ['procedure_to_instant_claim_error_map']);
}

export function useProcedureToInstantClaimErrorMap(
    claimId: string
): UseQueryResult<Pick<PetClaimResponse, 'procedure_to_instant_claim_error_map'>> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetProcedureToInstantClaimErrorMap, claimId],
        queryFn: async () => await getProcedureToInstantClaimErrorMap(claimId),
    });
}

export function useMarkSettledMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly settled: boolean; readonly claimId: string }, PetClaimData> {
    const queryKey = [PetClaimQueryKeys.GetPetClaim, claimId];

    function mutateSettled({ data, variables }: { data: PetClaimData; variables: { settled: boolean } }) {
        const { settled } = variables;

        return produce(data, draftData => {
            draftData.claim.settled = settled;
        });
    }

    return useOptimisticMutation<{ settled: boolean; claimId: string }, PetClaimData>({
        mutationFn: markSettled,
        invalidateKeys: [
            queryKey,
            [PetClaimQueryKeys.GetPetClaimsPriorities, claimId],
            [PetBlenderClaimQueryKeys.GetClaim, claimId],
            [PetBlenderClaimQueryKeys.GetClaimActions, claimId],
            [PetBlenderClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
        ],
        mutate: mutateSettled,
        mutateKey: queryKey,
    });
}

export function useAssignAdjusterMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly adjuster: Adjuster | null; readonly claimId: string }, PetClaimData> {
    const queryKey = [PetClaimQueryKeys.GetPetClaim, claimId];

    function mutateAdjuster({ data, variables }: { data: PetClaimData; variables: { adjuster: Adjuster | null } }) {
        const { adjuster } = variables;

        return produce(data, draftData => {
            draftData.adjusters.current = adjuster;
        });
    }

    return useOptimisticMutation<{ adjuster: Adjuster | null; claimId: string }, PetClaimData>({
        mutationFn: assignAdjuster,
        invalidateKeys: [
            queryKey,
            [PetClaimQueryKeys.GetPetFirstResponse],
            [PetClaimQueryKeys.GetPetClaimsPriorities],
            [PetBlenderClaimQueryKeys.GetPetWorkflowsHomeTaskData, claimId],
            [PetBlenderClaimQueryKeys.GetWorkflowAvailable, claimId],
            [PetBlenderClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
        ],
        mutate: mutateAdjuster,
        mutateKey: queryKey,
    });
}

export function useClosePetClaimMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(closeClaim, [
        [PetClaimQueryKeys.GetPetClaim, claimId],
        [PetClaimQueryKeys.GetPetClaimFeatures, claimId],
        [PetClaimQueryKeys.GetPetClaimFeesLogs, claimId],
        [PetClaimQueryKeys.GetPetClaimsPriorities, claimId],
        [PetBlenderClaimQueryKeys.GetClaim, claimId],
        [PetBlenderClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
        [PetBlenderClaimQueryKeys.GetClaimActions, claimId],
    ]);
}

export function useQaSimulateAutoDeclineMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(simulateAutoDecline, [[PetClaimQueryKeys.GetPetClaim, claimId]]);
}

export function useQaSimulateAutoApprovalMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(simulateAutoApproval, [[PetClaimQueryKeys.GetPetClaim, claimId]]);
}

export function useExportClaimPdfMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(exportClaimPdf, [[PetClaimQueryKeys.GetPetClaim, claimId]]);
}

// should move to PetBlenderQueries when all invalidate keys will also migrate to pet-blender endpoints
export function useReopenPetClaimMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(petBlenderReopenClaim, [
        [PetClaimQueryKeys.GetPetClaim, claimId],
        [PetClaimQueryKeys.GetPetClaimFeatures, claimId],
        [PetClaimQueryKeys.GetPetClaimFeesLogs, claimId],
        [PetClaimQueryKeys.GetPetClaimsPriorities, claimId],
        [PetBlenderClaimQueryKeys.GetClaim, claimId],
        [PetBlenderClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
        [PetBlenderClaimQueryKeys.GetWorkflowAvailable, claimId],
        [PetBlenderClaimQueryKeys.GetClaimActions, claimId],
    ]);
}

export function useCancelPetClaimMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(cancelClaim, [
        [PetClaimQueryKeys.GetPetClaim, claimId],
        [PetClaimQueryKeys.GetPetClaimFeatures, claimId],
        [PetClaimQueryKeys.GetPetClaimFeesLogs, claimId],
        [PetClaimQueryKeys.GetPetClaimsPriorities, claimId],
        [PetBlenderClaimQueryKeys.GetClaim, claimId],
        [PetBlenderClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
    ]);
}

export function useCancelAutoDeclineMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(cancelAutoDecline, [[PetClaimQueryKeys.GetPetClaim, claimId]]);
}

export function useCancelAutoApprovalMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(cancelAutoApproval, [[PetClaimQueryKeys.GetPetClaim, claimId]]);
}

export function useConnectClaimToPreapproval(
    claimId: string
): UseMutationResult<
    void,
    unknown,
    { readonly claimId: string; readonly preapprovalPublicId: string; readonly operatorId: string },
    null
> {
    return usePessimisticMutationLegacy(connectClaimToPreapproval, [[PetClaimQueryKeys.GetPetClaim, claimId]]);
}

export function useDisconnectClaimFromPreapproval(
    claimId: string
): UseMutationResult<
    void,
    unknown,
    { readonly claimId: string; readonly preapprovalPublicId: string; readonly operatorId: string },
    null
> {
    return usePessimisticMutationLegacy(disconnectClaimFromPreapproval, [[PetClaimQueryKeys.GetPetClaim, claimId]]);
}

export function useEditPetClaimMutation(
    claimId: string
): UseMutationResult<
    null,
    unknown,
    { readonly claimId: string; readonly claim: Partial<PetClaim>; readonly operatorId?: string },
    null
> {
    return usePessimisticMutationLegacy(editClaim, [
        [PetClaimQueryKeys.GetPetClaim, claimId],
        [PetClaimQueryKeys.GetPetClaimFeatures, claimId],
        [PetClaimQueryKeys.GetPetClaimFeesLogs, claimId],
        [PetClaimQueryKeys.GetPetClaimsPriorities, claimId],
        [PetBlenderClaimQueryKeys.GetClaim, claimId],
        [PetBlenderClaimQueryKeys.GetWorkflowAvailable, claimId],
        [PetBlenderClaimQueryKeys.GetReviewClaimItemsData, claimId],
        [PetBlenderClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
        [PetBlenderClaimQueryKeys.GetRiskAssessmentAdditionalTaskData, claimId],
        [PetBlenderClaimQueryKeys.GetPetValidateInvoiceDetailsTaskData, claimId],
    ]);
}

export function useAddTaskMutation(
    claimId: string
): UseBaseMutationResult<void, unknown, { readonly data: AddWorkflowTaskParams }, null> {
    return usePessimisticMutationLegacy(addPetWorkflowsTask, [
        [PetBlenderClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
    ]);
}

export function useAddCustomTaskMutation(
    claimId: string
): UseBaseMutationResult<void, unknown, { readonly data: AddWorkflowCustomTaskParams }, null> {
    return usePessimisticMutationLegacy(addPetWorkflowsCustomTask, [
        [PetBlenderClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
    ]);
}

export function useMarkAsErrorItemMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly claimId: string; readonly itemPublicId: string }, null> {
    return usePessimisticMutationLegacy(markAsErrorItem, [[PetClaimQueryKeys.GetPetClaimFeatures, claimId]]);
}

async function getClaimPriorities(claimId: string): Promise<Pick<PetClaimResponse, 'priorities'>> {
    return await fetchPetClaimData(claimId, ['priorities']);
}

export function useClaimPriorities(claimId: string): UseQueryResult<Pick<PetClaimResponse, 'priorities'>> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetPetClaimsPriorities, claimId],
        queryFn: async () => await getClaimPriorities(claimId),
    });
}

export function useAddCustomRecipientMutation(
    claimId: string
): UseMutationResult<
    Recipient,
    unknown,
    { readonly claimId: string; readonly recipient: Pick<Recipient, 'email' | 'name' | 'role'> },
    null
> {
    const cache = useQueryClient();
    const petClaimKey = [PetClaimQueryKeys.GetPetPossibleRecipients, claimId];

    function onSuccess(recipient: Recipient) {
        cache.setQueryData<PetPossibleRecipients>(petClaimKey, data => {
            return produce(data!, draftData => {
                draftData.possible_recipients_v2.push(recipient);
            });
        });
    }

    return usePessimisticMutationLegacy(addCustomRecipient, [petClaimKey], onSuccess);
}

export function useRemoveCustomRecipient(
    claimId: string
): UseMutationResult<null, unknown, { readonly recipientId: string }, PetPossibleRecipients> {
    const queryKey = [PetClaimQueryKeys.GetPetPossibleRecipients, claimId];

    function mutateRecipients({
        data,
        variables,
    }: {
        data: PetPossibleRecipients;
        variables: { recipientId: string };
    }) {
        const { recipientId } = variables;

        return produce(data, draftData => {
            draftData.possible_recipients_v2 = draftData.possible_recipients_v2.filter(
                r => String(r.id) !== recipientId
            );
        });
    }

    return useOptimisticMutation<{ recipientId: string }, PetPossibleRecipients>({
        mutationFn: removeCustomRecipient,
        invalidateKeys: [queryKey],
        mutate: mutateRecipients,
        mutateKey: queryKey,
    });
}

export function useAssignInteractionMutation(
    claimPublicId: string
): UseMutationResult<null, unknown, { readonly interactionPublicId: string; readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(assignInteraction, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
        [PetClaimQueryKeys.GetPetClaimsPriorities, claimPublicId],
    ]);
}

export function useAssignTicketMutation(
    claimPublicId: string
): UseMutationResult<null, unknown, AssignTicketToEntityParams, null> {
    return usePessimisticMutationLegacy(assignTicketToEntity, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
        [PetClaimQueryKeys.GetPetClaimsPriorities, claimPublicId],
    ]);
}

export function useDismissInteractionMutation(
    claimPublicId: string
): UseMutationResult<null, unknown, { readonly interactionPublicId: string; readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(dismissInteraction, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
        [PetClaimQueryKeys.GetPetClaimsPriorities, claimPublicId],
    ]);
}

export function useManualInteractionMutation(
    claimPublicId: string
): UseMutationResult<
    null,
    unknown,
    { readonly interactionPublicId: string; readonly claimId: string; readonly medium: string; readonly body: string },
    null
> {
    return usePessimisticMutationLegacy(manualResponseInteraction, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
        [PetClaimQueryKeys.GetPetClaimsPriorities, claimPublicId],
    ]);
}

export function useCreateCommsTimelineNoteMutation(
    claimPublicId: string
): UseMutationResult<null, unknown, { readonly note: NoteCreateRequest }, null> {
    return usePessimisticMutationLegacy(createNote, [[InteractionQueryKeys.GetCommunicationTimeline, claimPublicId]]);
}

export function useCreateWorkflowsNoteMutation(
    claimPublicId: string
): UseMutationResult<null, unknown, { readonly note: NoteCreateRequest }, null> {
    return usePessimisticMutationLegacy(createNote, [[PetBlenderClaimQueryKeys.GetWorkflowsNote, claimPublicId]]);
}

export function useDismissItemListIncompleteMutation(
    claimId: string
): UseMutationResult<null, unknown, { readonly claimId: string }, FeaturesAndItems> {
    const queryKey = [PetBlenderClaimQueryKeys.GetReviewClaimItemsData, claimId];

    function mutateItemsIncomplete({ data }: { data: FeaturesAndItems; variables: { claimId: string } }) {
        return produce(data, draftData => {
            draftData.item_list_incomplete = false;
        });
    }

    return useOptimisticMutation({
        mutationFn: dismissItemListIncomplete,
        invalidateKeys: [queryKey],
        mutate: mutateItemsIncomplete,
        mutateKey: queryKey,
    });
}

export function useGetClaimsByPetId(petId: string): UseQueryResult<PetClaim[]> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetClaimsByPetId, petId],
        queryFn: async () => await getPetClaimsByPetId(petId),
    });
}

export function useUpdateTreatmentPlaceMutation(
    userPublicId: string,
    claimId: string
): UseMutationResult<
    TreatmentPlace,
    unknown,
    { readonly publicId: string; readonly treatmentPlace: Partial<TreatmentPlace>; readonly claimId?: string },
    null
> {
    return usePessimisticMutationLegacy(updateTreatmentPlace, [
        [PetClaimQueryKeys.GetPetClaim, claimId],
        [PetClaimQueryKeys.GetPetTreatmentPlaces, userPublicId],
        [PetClaimQueryKeys.GetPetTreatmentPlacesByClaim, claimId],
    ]);
}

export function useCreateTreatmentPlaceMutation(
    userPublicId: string
): UseMutationResult<TreatmentPlace, unknown, { readonly treatmentPlace: Partial<TreatmentPlace> }, null> {
    return usePessimisticMutationLegacy(createTreatmentPlace, [
        [PetClaimQueryKeys.GetPetTreatmentPlaces, userPublicId],
    ]);
}

export function useGetClaimTreatmentPlaces(claimPublicId: string): UseQueryResult<TreatmentPlace[]> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetPetTreatmentPlacesByClaim, claimPublicId],
        queryFn: async () => await getClaimTreatmentPlaces(claimPublicId),
    });
}

export function useGetClaimUserVets(claimPublicId: string): UseQueryResult<TreatmentPlaceDTO[]> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetPetUserVetsByClaim, claimPublicId],
        queryFn: async () => await getClaimUserVets(claimPublicId),
    });
}

export function useUserTreatmentPlaces(userPublicId?: string): UseQueryResult<TreatmentPlace[]> {
    return useQuery({
        queryKey: [PetClaimQueryKeys.GetPetTreatmentPlaces, userPublicId],
        queryFn: async () => (userPublicId ? await getUserTreatmentPlaces(userPublicId) : []),
    });
}

export function useUserVets({
    onSuccess,
    userPublicId,
}: {
    readonly onSuccess?: (data: TreatmentPlaceDTO[]) => void;
    readonly userPublicId?: string;
}): UseQueryResult<TreatmentPlaceDTO[]> {
    return useQuery({
        queryKey:
            userPublicId != null
                ? [PetClaimQueryKeys.GetPetUserVetsByUser, userPublicId]
                : [PetClaimQueryKeys.GetPetUserVetsByUser],
        queryFn: async () => {
            if (userPublicId != null) {
                const response = await getUserVets(userPublicId);
                onSuccess?.(response);
                return response;
            }

            return [];
        },
    });
}

export function useAttachTreatmentPlaceToClaimMutation(claimId: string): UseMutationResult<
    null,
    unknown,
    {
        readonly treatmentPlacePublicIds: string[];
        readonly claimPublicId: string;
        readonly treatmentPlace: TreatmentPlace;
    },
    PetClaimData
> {
    const queryKey = [PetClaimQueryKeys.GetPetClaim, claimId];

    function mutateTreatmentPlace({
        data,
        variables,
    }: {
        data: PetClaimData;
        variables: {
            treatmentPlacePublicIds: string[];
            claimPublicId: string;
            treatmentPlace: TreatmentPlace;
        };
    }) {
        const { treatmentPlace } = variables;

        return produce(data, draftData => {
            draftData.claim.treatment_places[0] = {
                ...treatmentPlace,
                user_public_id: draftData.user.public_id,
            };
        });
    }

    return useOptimisticMutation<
        {
            treatmentPlacePublicIds: string[];
            claimPublicId: string;
            treatmentPlace: TreatmentPlace;
        },
        PetClaimData
    >({
        mutationFn: attachTreatmentPlaceToClaim,
        invalidateKeys: [
            queryKey,
            [PetClaimQueryKeys.GetPetTreatmentPlacesByClaim, claimId],
            [PetClaimQueryKeys.GetPetUserVetsByClaim, claimId],
        ],
        mutate: mutateTreatmentPlace,
        mutateKey: queryKey,
    });
}
