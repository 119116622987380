import type { Currency, Locale } from '@lemonade-hq/lemonation';
import { isDefined } from '@lemonade-hq/ts-helpers';
import type { UseMutationResult, UseQueryResult, UseSuspenseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { insuranceBlender } from '../../../apiClients';
import type { Pagination } from './QueriesShared';
import { useSuspenseQueryWithErrorHandling } from 'components/LoCo/common/hooks/useSuspenseQueryWithErrorHandling';
import type { PaginationResult } from 'models/CarShared';
import type { BaseEditionFilter } from 'models/LoCo/Insurance/BaseEdition';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';
import type { EditionSetCompatibilityResponse, Product, ProductEditions } from 'models/LoCo/Insurance/Product';
import type { ProductLine } from 'models/LoCo/Insurance/ProductLine';
import type { SchemaResponse, SchemaRevisionsResponse } from 'models/LoCo/Insurance/Schema';
import { usePessimisticMutation } from 'queries/MutationHooks';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_PAGE = 1;

const DEFAULT_PAGINATION: Pagination = {
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
};

const PRODUCT_BASE_PATH = '/api/v1/products';

export enum ProductsQueryKey {
    GetProducts = 'GET_PRODUCTS',
    CreateProduct = 'CREATE_PRODUCT',
    GetProductSchemaRevisions = 'GET_PRODUCT_SCHEMA_REVISIONS',
    GetProductEditionsSummary = 'GET_PRODUCT_EDITIONS_SUMMARY',
    GetProductSchemaAttributes = 'GET_PRODUCT_SCHEMA_ATTRIBUTES',
    GetProductPublishedEditionSets = 'GET_PRODUCT_PUBLISHED_EDITION_SETS',
    GetLatestProductSchemaAttributes = 'GET_LATEST_PRODUCT_SCHEMA_ATTRIBUTES',
    GetProductSpecificEditionsSummary = 'GET_PRODUCT_SPECIFIC_EDITIONS_SUMMARY)',
    CheckEditionSetCompatibility = 'CHECK_EDITION_SET_COMPATIBILITY',
}

export async function getProduct(productCode: string): Promise<Product> {
    return await insuranceBlender
        .get<{ data: Product }>(`${PRODUCT_BASE_PATH}/${productCode}`)
        .then(response => response.data.data);
}

export function useSuspenseGetProduct(productCode: string): UseSuspenseQueryResult<Product> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [ProductsQueryKey.GetProducts, productCode],
        queryFn: async () => await getProduct(productCode),
    });
}

async function getProducts(productLineCode: string): Promise<Product[]> {
    return await insuranceBlender
        .get<{ data: Product[] }>(`${PRODUCT_BASE_PATH}?productLineCode=${productLineCode}`)
        .then(response => response.data.data);
}

export function useGetProducts(productLineCode?: string): UseQueryResult<Product[]> {
    return useQuery({
        queryKey: [ProductsQueryKey.GetProducts, productLineCode],
        queryFn: async () => await getProducts(productLineCode ?? ''),
        enabled: Boolean(productLineCode),
    });
}

interface CreateProductBody {
    readonly name: string;
    readonly code?: string;
    readonly description: string;
    readonly region: string;
    readonly state?: string;
    readonly country: string;
    readonly productLineCode: string;
    readonly currency: Currency;
    readonly locales: Locale[];
}

async function createProduct({
    name,
    code,
    description,
    region,
    state,
    country,
    productLineCode,
    currency,
    locales,
}: CreateProductBody): Promise<ProductLine> {
    return await insuranceBlender
        .post<{ data: ProductLine }>(PRODUCT_BASE_PATH, {
            name,
            code,
            description,
            region,
            state,
            country,
            productLineCode,
            currency,
            locales,
        })
        .then(response => response.data.data);
}

export function useCreateProduct(): UseMutationResult<ProductLine, unknown, CreateProductBody, null> {
    return usePessimisticMutation({
        mutationFn: createProduct,
        invalidateKeys: [[ProductsQueryKey.GetProducts]],
        mutationKey: [ProductsQueryKey.CreateProduct],
    });
}

async function getProductSchemaRevisions(productCode: string): Promise<SchemaRevisionsResponse> {
    return await insuranceBlender
        .get<{ data: SchemaRevisionsResponse }>(`${PRODUCT_BASE_PATH}/${productCode}/schemas/revisions`)
        .then(response => response.data.data);
}

export function useGetProductSchemaRevisions(productCode: string): UseQueryResult<SchemaRevisionsResponse> {
    return useQuery({
        queryKey: [ProductsQueryKey.GetProductSchemaRevisions, productCode],
        queryFn: async () => {
            return await getProductSchemaRevisions(productCode);
        },
        enabled: Boolean(productCode),
    });
}

async function getProductSchema(
    productCode: string,
    platformRevision: string | null,
    productRevision: string | null
): Promise<SchemaResponse> {
    if (platformRevision == null || productRevision == null) {
        throw new Error('Platform and product revision must be provided');
    }

    const query = qs.stringify({ productRevision, platformRevision });

    return await insuranceBlender
        .get<{
            data: SchemaResponse;
        }>(`${PRODUCT_BASE_PATH}/${productCode}/schemas?${query}`)
        .then(response => response.data.data);
}

export function useGetProductSchemaAttributes(
    productCode: string,
    platformRevision: string | null,
    productRevision: string | null,
    enabled = false
): UseQueryResult<SchemaResponse> {
    return useQuery({
        queryKey: [ProductsQueryKey.GetProductSchemaAttributes, productCode, platformRevision, productRevision],
        queryFn: async () => {
            return await getProductSchema(productCode, platformRevision, productRevision);
        },
        enabled,
    });
}

async function getLatestProductSchema(productCode: string): Promise<SchemaResponse> {
    return await insuranceBlender
        .get<{ data: SchemaResponse }>(`${PRODUCT_BASE_PATH}/${productCode}/schemas/latest`)
        .then(response => response.data.data);
}

export function useGetLatestProductSchemaAttributes(
    productCode: string,
    enabled = false
): UseQueryResult<SchemaResponse> {
    return useQuery({
        queryKey: [ProductsQueryKey.GetLatestProductSchemaAttributes, productCode],
        queryFn: async () => {
            return await getLatestProductSchema(productCode);
        },
        enabled,
    });
}

async function getProductEditionsSummary(
    productCode: string,
    filters: BaseEditionFilter = {}
): Promise<ProductEditions> {
    const query = qs.stringify({ ...filters });
    return await insuranceBlender
        .get<{ data: ProductEditions }>(`${PRODUCT_BASE_PATH}/${productCode}/editions-summary?${query}`)
        .then(response => response.data.data);
}

export function useGetProductEditionsSummary(
    productCode: string,
    filters: BaseEditionFilter = {}
): UseQueryResult<ProductEditions> {
    return useQuery({
        queryKey: [ProductsQueryKey.GetProductEditionsSummary, productCode, filters],
        queryFn: async () => {
            return await getProductEditionsSummary(productCode, filters);
        },
    });
}

async function getProductEditionSets(
    productCode: string,
    published: boolean | undefined,
    page: number,
    size: number
): Promise<PaginationResult<EditionSet>> {
    const publishedQuery = isDefined(published) ? `&published=${published.toString()}` : '';
    return await insuranceBlender
        .get<
            PaginationResult<EditionSet>
        >(`${PRODUCT_BASE_PATH}/${productCode}/edition-sets?page=${page}&size=${size}${publishedQuery}`)
        .then(response => response.data);
}

export function useGetProductEditionSets(
    productCode: string,
    published?: boolean,
    { page = DEFAULT_PAGE, size = DEFAULT_PAGE_SIZE }: Pagination = DEFAULT_PAGINATION
): UseQueryResult<PaginationResult<EditionSet>> {
    return useQuery({
        queryKey: [ProductsQueryKey.GetProductPublishedEditionSets, productCode, published, page, size],
        queryFn: async () => {
            return await getProductEditionSets(productCode, published, page, size);
        },
    });
}

interface CheckEditionSetCompatibilityBody {
    readonly productCode: string;
    readonly coveragesEditionCode?: string;
    readonly digitalAgentEditionCode?: string;
    readonly ratingEditionCode?: string;
    readonly productSchemaRevision?: number;
    readonly platformSchemaRevision?: number;
    readonly underwritingFiltersEditionCode?: string;
}

export async function checkEditionSetCompatibility(
    body: CheckEditionSetCompatibilityBody
): Promise<EditionSetCompatibilityResponse> {
    const response = await insuranceBlender.post<{ data: EditionSetCompatibilityResponse }>(
        `${PRODUCT_BASE_PATH}/check-compatibility`,
        body
    );

    return response.data.data;
}

export function useCheckEditionSetCompatibility(): UseMutationResult<
    EditionSetCompatibilityResponse,
    unknown,
    CheckEditionSetCompatibilityBody,
    null
> {
    return usePessimisticMutation({
        mutationFn: checkEditionSetCompatibility,
        invalidateKeys: [],
        mutationKey: [ProductsQueryKey.CheckEditionSetCompatibility],
    });
}
