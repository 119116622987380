import { Flex } from '@lemonade-hq/cdk';
import { DATE_FNS_FORMATS } from '@lemonade-hq/lemonation';
import { formatInTimeZone } from 'date-fns-tz';
import { ellipsis } from 'polished';
import React from 'react';
import styled from 'styled-components';
import type { Attachment } from 'models/Attachment';

const InfoPanelWrapper = styled.div`
    position: absolute;
    border-radius: 5px;
    border: 1px solid rgba(140, 140, 140, 0.5);
    background: rgba(0, 0, 0, 0.8);
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% + 5px);
`;

const InfoPanelRow = styled(Flex)`
    height: 28px;
    padding: 0 20px;
    align-items: center;
    gap: 10px;
    &:nth-child(even) {
        background: rgba(0, 0, 0, 0.8);
    }
    &:nth-child(odd) {
        background: rgba(140, 140, 140, 0.1);
    }
    > div {
        ${ellipsis}
        flex-shrink: 0;
        &:first-child {
            width: 144px;
            text-transform: uppercase;
        }
        &:last-child {
            width: 230;
        }
    }
`;

const infoPanelEntries: {
    readonly [key: string]: {
        readonly title: string;
        readonly contentFn: (attachment: Attachment, timezone?: string) => React.ReactNode | null;
    };
} = {
    taken_at: {
        title: 'Date taken',
        contentFn: (attachment, timezone = 'UTC') => {
            if (!attachment.taken_at) return null;

            return formatInTimeZone(attachment.taken_at, timezone, DATE_FNS_FORMATS.iso8601UtcHumanizedNoSeconds);
        },
    },
};

export function shouldShowInfoPanelCarouselMenuButton(attachment: Attachment): boolean {
    return Object.keys(infoPanelEntries).some(key => attachment[key] != null);
}

interface InfoPanelProps {
    readonly attachment: Attachment;
    readonly timezone?: string;
}

export const CarouselInfoPanel: React.FC<React.PropsWithChildren<InfoPanelProps>> = ({ attachment, timezone }) => {
    return (
        <InfoPanelWrapper>
            {Object.entries(infoPanelEntries).map(([key, value]) => {
                const content = value.contentFn(attachment, timezone);

                if (content == null) return null;

                return (
                    <InfoPanelRow key={key}>
                        <div>{value.title}</div>
                        <div>{content}</div>
                    </InfoPanelRow>
                );
            })}
        </InfoPanelWrapper>
    );
};
