import { Box } from '@lemonade-hq/cdk';
import React, { useState } from 'react';
import styled from 'styled-components';
import AttachmentDescriptionEditor from 'bluis/ClaimAttachments/AttachmentDescriptionEditor';
import { EditIndicator } from 'bluis/InlineInputEditor';
import { toReadable } from 'commons/StringUtils';
import type { Attachment } from 'models/Attachment';

const DescriptionValue = styled.div`
    display: flex;
    position: relative;
`;

const DescriptionValueContent = styled.div`
    position: relative;
    max-width: 200px;
    overflow-wrap: break-word;
`;

const DescriptionValueEditWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const DescriptionValueEdit = styled.div`
    position: relative;
    cursor: pointer;
`;

const DescriptionValueEditContainer = styled.div`
    position: relative;
`;

export type UpdateAttachment = (
    attachmentId: string,
    description?: string
) => Promise<Attachment | null | undefined | void>;

interface AttachmentDescriptionProps {
    readonly description?: string;
    readonly editable: boolean;
    readonly id: string;
    readonly updateAttachmentData?: UpdateAttachment;
    readonly editIndicatorVisible: boolean;
    readonly hideEditIndicator: () => void;
    readonly onClick?: () => void;
}

const AttachmentDescription: React.FC<React.PropsWithChildren<AttachmentDescriptionProps>> = ({
    description,
    editable,
    id,
    updateAttachmentData,
    editIndicatorVisible,
    hideEditIndicator,
    onClick,
}) => {
    const [editorVisible, setEditorVisible] = useState(false);

    function closeEditor() {
        setEditorVisible(false);
        hideEditIndicator();
    }

    return (
        <DescriptionValue>
            <DescriptionValueContent>{toReadable(description)}</DescriptionValueContent>
            {updateAttachmentData && editable && (
                <DescriptionValueEditWrapper>
                    <DescriptionValueEdit
                        onClick={() => {
                            setEditorVisible(true);
                            onClick?.();
                        }}
                    >
                        <Box ml={10}>
                            <EditIndicator active={editorVisible || editIndicatorVisible} />
                        </Box>
                    </DescriptionValueEdit>
                    <DescriptionValueEditContainer>
                        {editorVisible && (
                            <AttachmentDescriptionEditor
                                closeEditor={closeEditor}
                                description={description ?? ''}
                                editorVisible={editorVisible}
                                id={id}
                                updateAttachmentData={updateAttachmentData}
                            />
                        )}
                    </DescriptionValueEditContainer>
                </DescriptionValueEditWrapper>
            )}
        </DescriptionValue>
    );
};

export default AttachmentDescription;
