import type { RowItem } from '@lemonade-hq/bluis';
import { Attachment, TableRow } from '@lemonade-hq/bluis';
import { themedColor, Tooltip } from '@lemonade-hq/boutique';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { defaultHeaderColumns } from './types';
import AttachmentDescription from 'bluis/ClaimAttachments/AttachmentDescription';
import { isFraud, mapAttachmentType } from 'commons/AttachmentsUtils';
import { involvedPartyTitle } from 'commons/CarUtils';
import { FORMATS } from 'commons/DateFormatter';
import { toReadable } from 'commons/StringUtils';
import type { Attachment as IAttachment } from 'models/Attachment';
import { AttachmentType } from 'models/Attachment';
import type { InvolvedParty } from 'models/InvolvedParty';

interface AttachmentRowProps {
    readonly attachment: IAttachment;
    readonly timezone: string;
    readonly inView: boolean;
    readonly updateAttachmentData?: (
        attachmentId: string,
        description?: string
    ) => Promise<IAttachment | undefined | void>;
    readonly showAttachmentsPreview?: () => void;
    // to filter out keys from the default columns
    readonly columns?: string[];
    // rows to be added to the default rows
    readonly addedRows?: (attachment: IAttachment) => RowItem[];
    readonly involvedParties?: InvolvedParty[];
}

const Row = styled(TableRow)<{ readonly row: RowItem[] }>`
    &&& {
        grid-template-columns: 70px repeat(${({ row }) => row.length - 1}, 1fr);
        align-items: center;
        &:hover {
            border: 1px solid ${themedColor('primary')};

            + ul {
                border-top: none;
            }
        }
    }
`;

const DateWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

const UploadedAtText: React.FC<
    React.PropsWithChildren<{ readonly uploadedAtDate: string; readonly uploadedAtTime: string }>
> = ({ uploadedAtDate, uploadedAtTime }) => (
    <DateWrapper>
        <span key="uploadedAtDate">{uploadedAtDate}</span>
        <span key="uploadedAtTime">{uploadedAtTime}</span>
    </DateWrapper>
);

const TakenAtText: React.FC<
    React.PropsWithChildren<{ readonly takenAtDate: string; readonly takenAtTime: string }>
> = ({ takenAtDate, takenAtTime }) => (
    <DateWrapper>
        <span key="takenAtDate">{takenAtDate}</span>
        <span key="takenAtTime">{takenAtTime}</span>
    </DateWrapper>
);

const NON_EDITABLE_ATTACHMENT_TYPES: AttachmentType[] = [
    AttachmentType.PolicyPDF, // 'policy_pdf'
    AttachmentType.HomePolicyPDF, // 'Policy PDF'
    AttachmentType.ClaimVideo,
    AttachmentType.UserSignature,
];

const AttachmentRow: React.FC<React.PropsWithChildren<AttachmentRowProps>> = ({
    attachment,
    timezone,
    updateAttachmentData,
    showAttachmentsPreview,
    columns = defaultHeaderColumns,
    addedRows,
    involvedParties,
}) => {
    const [editPencilVisible, setEditPencilVisible] = useState(false);

    const {
        type,
        subtype,
        description,
        created_at: createdAt,
        taken_at: takenAt,
        location,
        source,
        entity_public_id: entityPublicId,
    } = attachment;

    const uploadedAtMoment = createdAt != null ? moment.tz(createdAt, timezone) : null;

    const uploadedAtDate = uploadedAtMoment ? uploadedAtMoment.format(FORMATS.ANOTHER_SIMPLE_DATE_NO_TIME) : '';

    const uploadedAtTime = uploadedAtMoment ? uploadedAtMoment.format(FORMATS.ANOTHER_SIMPLE_DATE_ONLY_TIME) : '';

    const uploadedAtUTC = moment.tz(uploadedAtMoment, 'UTC').format(FORMATS.ANOTHER_SIMPLE_DATE);

    const takenAtMoment = takenAt ? moment.tz(takenAt, timezone) : null;

    const takenAtDate = takenAtMoment ? takenAtMoment.format(FORMATS.ANOTHER_SIMPLE_DATE_NO_TIME) : '';

    const takenAtTime = takenAtMoment ? takenAtMoment.format(FORMATS.ANOTHER_SIMPLE_DATE_ONLY_TIME) : '';

    const takenAtUTC = moment.tz(takenAtMoment, 'UTC').format(FORMATS.ANOTHER_SIMPLE_DATE);

    const descriptionEditable =
        updateAttachmentData != null && !NON_EDITABLE_ATTACHMENT_TYPES.includes(attachment.type);

    const row = useMemo(() => {
        const involvedParty = involvedPartyTitle(
            involvedParties?.find(involvedParty => involvedParty.publicId === entityPublicId)
        );

        const rowData: RowItem[] = [
            {
                key: 'attachment',
                value: (
                    <Attachment
                        attachment={attachment}
                        isFraud={isFraud(attachment)}
                        key="attachment"
                        onClick={showAttachmentsPreview}
                        type={attachment.content_type}
                        url={attachment.download_url}
                    />
                ),
                width: 70,
            },
            {
                key: 'type',
                value: (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <span onClick={showAttachmentsPreview} role="button" tabIndex={0}>
                        {toReadable(subtype ?? mapAttachmentType(type))}
                    </span>
                ),
            },
            {
                key: 'description',
                value: (
                    <AttachmentDescription
                        description={description}
                        editIndicatorVisible={editPencilVisible}
                        editable={descriptionEditable}
                        hideEditIndicator={() => setEditPencilVisible(false)}
                        id={attachment.public_id ?? attachment.file_public_id}
                        updateAttachmentData={updateAttachmentData}
                    />
                ),
            },
            { key: 'involvedParty', value: toReadable(involvedParty) },
            { key: 'source', value: toReadable(source) },
            {
                key: 'createdAt',
                value: createdAt ? (
                    <Tooltip alignment="top-center" content={uploadedAtUTC}>
                        <UploadedAtText uploadedAtDate={uploadedAtDate} uploadedAtTime={uploadedAtTime} />
                    </Tooltip>
                ) : (
                    '-'
                ),
            },
            {
                key: 'takenAt',
                value: takenAt ? (
                    <Tooltip alignment="top-center" content={takenAtUTC}>
                        <TakenAtText takenAtDate={takenAtDate} takenAtTime={takenAtTime} />
                    </Tooltip>
                ) : (
                    '-'
                ),
            },
            { key: 'location', value: toReadable(location) },
        ];

        return rowData.concat(addedRows?.(attachment) ?? []).filter(col => columns.includes(col.key));
    }, [
        involvedParties,
        attachment,
        showAttachmentsPreview,
        subtype,
        type,
        description,
        descriptionEditable,
        updateAttachmentData,
        editPencilVisible,
        source,
        createdAt,
        uploadedAtUTC,
        uploadedAtDate,
        uploadedAtTime,
        takenAt,
        takenAtUTC,
        takenAtDate,
        takenAtTime,
        location,
        addedRows,
        entityPublicId,
        columns,
    ]);

    return (
        <Row
            onMouseEnter={() => setEditPencilVisible(true)}
            onMouseLeave={() => setEditPencilVisible(false)}
            row={row}
        />
    );
};

export default AttachmentRow;
