import { Select, Text } from '@lemonade-hq/blender-ui';
import { Flex } from '@lemonade-hq/cdk';
import { useEffect } from 'react';
import { dialogStepItemsWrapper, editionItemWrapper, stepTitle, stepWrapper } from './ManageReleaseDialog.css';
import { DialogStep } from 'components/LoCo/common/components/Dialog/Dialog';
import { EditionStatus, EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { LeanEdition } from 'models/LoCo/Insurance/BaseEdition';
import { useGetProductEditionsSummary } from 'queries/LoCo/Insurance/ProductQueries';

interface RolloutStrategyBugFixProps {
    readonly productCode: string;
    readonly selectedEditions: LeanEdition[];
    readonly selectedRatingEdition: string | null;
    readonly onChange: (values: { readonly ratingReferenceEditionCode?: string }) => void;
}

export const RolloutStrategyBugFix: React.FC<RolloutStrategyBugFixProps> = ({
    productCode,
    selectedEditions,
    selectedRatingEdition,
    onChange,
}) => {
    const { data: { coveragesEditions, digitalAgentEditions, underwritingFiltersEditions, ratingEditions } = {} } =
        useGetProductEditionsSummary(productCode, { status: EditionStatus.Published });

    const selectedRating = selectedEditions.find(edition => edition.type === EditionType.Rating);
    const ratingReferenceEdition = selectedRating?.referenceEditionContentCode;

    useEffect(() => {
        if (ratingReferenceEdition != null) {
            onChange({ ratingReferenceEditionCode: ratingReferenceEdition });
        }
    }, []);

    return (
        <DialogStep padding="40px 64px">
            <Flex className={stepWrapper}>
                <Flex className={stepTitle}>
                    The rollout strategy will be determined automatically according to Edition Sets containing:
                </Flex>
                <Flex className={dialogStepItemsWrapper}>
                    <div className={editionItemWrapper}>
                        <Text>Coverages</Text>
                        <Select
                            disabled
                            onChange={() => {}}
                            options={
                                coveragesEditions?.map(edition => ({
                                    value: edition.code,
                                    label: edition.friendlyName,
                                })) ?? []
                            }
                            placeholder="Select Edition"
                            selectedKey={
                                selectedEditions.find(edition => edition.type === EditionType.Coverages)
                                    ?.referenceEditionContentCode
                            }
                        />
                        <Text>Digital Agents</Text>
                        <Select
                            disabled
                            onChange={() => {}}
                            options={
                                digitalAgentEditions?.map(edition => ({
                                    value: edition.code,
                                    label: edition.friendlyName,
                                })) ?? []
                            }
                            placeholder="Select Edition"
                            selectedKey={
                                selectedEditions.find(edition => edition.type === EditionType.DigitalAgent)
                                    ?.referenceEditionContentCode
                            }
                        />
                        <Text>Underwriting Filters</Text>
                        <Select
                            disabled
                            onChange={() => {}}
                            options={
                                underwritingFiltersEditions?.map(edition => ({
                                    value: edition.code,
                                    label: edition.friendlyName,
                                })) ?? []
                            }
                            placeholder="Select Edition"
                            selectedKey={
                                selectedEditions.find(
                                    edition => edition.type === EditionType.UnderwritingFilters
                                )?.referenceEditionContentCode
                            }
                        />
                        <Text>Rating</Text>
                        <Select
                            disabled={ratingReferenceEdition != null}
                            onChange={value => value != null && onChange({ ratingReferenceEditionCode: value })}
                            options={
                                ratingEditions?.map(edition => ({
                                    value: edition.code,
                                    label: edition.friendlyName,
                                })) ?? []
                            }
                            placeholder="Select Edition with a bug"
                            selectedKey={selectedRatingEdition}
                        />
                    </div>
                </Flex>
            </Flex>
        </DialogStep>
    );
};
