import { darkThemeClass, Layout, Text, Tooltip } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { filenameEllipsis } from './AttachmentHub/Attachments.css';
import type { AttachmentDTO } from './types';
import { MISSING_VALUE } from './utils';

export const Filename: FC<{ readonly fileName?: AttachmentDTO['fileName'] }> = ({ fileName }) => {
    const ref = useRef<HTMLElement>(null);
    const [isEllipsisActive, setIsEllipsisActive] = useState(
        () => (ref.current?.offsetWidth ?? 0) < (ref.current?.scrollWidth ?? 0)
    );

    useEffect(() => {
        if (ref.current != null) {
            setIsEllipsisActive(ref.current.offsetWidth < ref.current.scrollWidth);
        }
    }, [fileName]);

    return (
        <Tooltip
            className={darkThemeClass}
            content={<Layout wordBreak="break-word">{fileName}</Layout>}
            disabled={!isEllipsisActive}
        >
            <Text className={filenameEllipsis} ref={ref}>
                {fileName ?? MISSING_VALUE}
            </Text>
        </Tooltip>
    );
};
