import type { DialogProps } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import { Text } from '@lemonade-hq/cdk';
import { useNavigate } from 'react-router-dom';
import { getProductUrl } from '../../../common/urlBuilders';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { getEditionName } from 'components/LoCo/common/editions/editionHelpers';
import type { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { useArchiveEdition } from 'queries/LoCo/Insurance/EditionTrackerQueries';

interface ArchiveEditionDialogDraftProps {
    readonly editionTrackerPublicId: string;
    readonly editionFriendlyName: string;
    readonly editionType: EditionType;
    readonly productCode?: string;
    readonly onClose: () => void;
}

export const ArchiveEditionDialogDraft: React.FC<React.PropsWithChildren<ArchiveEditionDialogDraftProps>> = ({
    editionTrackerPublicId,
    editionFriendlyName,
    editionType,
    productCode,
    onClose,
}) => {
    const { mutateAsync: archiveEdition, isError, isPending: isLoading } = useArchiveEdition(editionType, productCode);

    const navigate = useNavigate();

    const onSubmit = async (): Promise<void> => {
        await archiveEdition({ editionTrackerPublicId });

        if (productCode != null) {
            navigate(getProductUrl(productCode));
        } else {
            onClose();
        }
    };

    const dialogProps: DialogProps = {
        title: `Archive ${getEditionName(editionType)} Edition Draft`,
        onClose,
        loading: isLoading,
        error: isError ? GENERAL_ERROR_MSG : undefined,
        actions: [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Archive',
                type: 'submit',
                onClick: onSubmit,
                disabled: isLoading || isError,
            },
        ],
        closeOnOutsideClick: true,
    };

    return (
        <Dialog {...dialogProps}>
            <Text variant="body-md">
                You are about to archive edition draft <b>{editionFriendlyName}</b>
            </Text>
        </Dialog>
    );
};
