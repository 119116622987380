import type { AutocompleteOption } from '@lemonade-hq/bluis';
import { Autocomplete, ErrorSection, Spinner, Table, TableRow, useListFilters, useOrderBy } from '@lemonade-hq/bluis';
import { themedColor } from '@lemonade-hq/boutique';
import { Flex } from '@lemonade-hq/cdk';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InView, useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import AttachmentsPreview from 'bluis/AttachmentsPreview';
import type { withAdd, withEdit, withoutAdd, withOutEdit } from 'bluis/ClaimAttachments';
import { EmptyAttachmentsSection, headers, TableHeader } from 'bluis/ClaimAttachments';
import AddAttachments from 'bluis/ClaimAttachments/AddAttachments';
import type { AttachmentToAdd } from 'bluis/ClaimAttachments/AddAttachments';
import AttachmentRow from 'bluis/ClaimAttachments/AttachmentRow';
import { CommonSection, CommonSectionTitle } from 'bluis/CommonSection';
import { carAttachmentToAttachment } from 'commons/AttachmentsUtils';
import { involvedPartyTitle } from 'commons/CarUtils';
import { toReadable } from 'commons/StringUtils';
import { AttachmentRowItemType, TableRowItem } from 'components/Bluis/ClaimAttachments/AddAttachmentsTable';
import type { AdditionalInputInfo } from 'components/Bluis/ClaimAttachments/AddAttachmentsTable';
import { SearchFilters } from 'components/Bluis/ClaimAttachments/SearchFilters';
import { INIT_SEARCH_PROPS } from 'components/Bluis/ClaimAttachments/types';
import type {
    CarClaimsAttachmentsFilterFields,
    CarClaimsAttachmentsFiltersOptions,
} from 'components/Bluis/ClaimAttachments/types';
import { FiltersContainer } from 'components/Bluis/EntitiesTable/filters-styles';
import { AttachmentType } from 'models/Attachment';
import type { Attachment, AttachmentOptionalRelations, AttachmentToUpload } from 'models/Attachment';
import { AttachmentRelationEntityType, previewableAttachments } from 'models/CarShared';
import type { CarAttachmentToAdd, CarAttachmentType } from 'models/CarShared';
import { InvolvedPartyType } from 'models/InvolvedParty';
import type { InvolvedParty } from 'models/InvolvedParty';
import {
    useAttachmentOptionalRelations,
    useCarClaimAttachmentsFiltersData,
    useCarClaimAttchmentsPage,
    useCarClaimInvolvedParties,
    useRefreshCarClaimAttchmentsPage,
} from 'queries/CarClaimQueries';

const PAGE_SIZE = 10;
const CAR_ATTACHMENTS_ADDITIONAL_INPUTS_SCHEME: AdditionalInputInfo[] = [
    { title: 'Involved Party', type: AttachmentRowItemType.Select },
];

const StyledCommonSection = styled(CommonSection)`
    margin-top: 28px;
`;

const SpinnerWrapper = styled(Flex)`
    justify-content: center;
    padding: 10px 0 20px 0;
    border: 1px solid ${themedColor('separator')};
    border-top: none;
    border-radius: 0 0 5px 5px;
`;

const NoMoreResults = styled(Flex)`
    padding: 8px 0;
    align-items: center;
    justify-content: center;
`;

const StyledFiltersContainer = styled(FiltersContainer)`
    background-color: ${themedColor('generalBackground')};
`;

type InvolvedPartySelectProps = {
    readonly involvedParties: InvolvedParty[];
    readonly attachmentOptionalRelations?: AttachmentOptionalRelations;
    readonly onAttachmentInvolvedPartySelect: (involvedPartyId: string, attachmentId?: string) => void;
    readonly attachmentAdditionalInfo: CarAttachmentToAdd;
};

const carAttachmentsHeaders = [
    'attachment',
    'type',
    'description',
    'involvedParty',
    'source',
    'createdAt',
    'takenAt',
    'location',
];

const InvolvedPartySelect: React.FC<React.PropsWithChildren<InvolvedPartySelectProps>> = ({
    involvedParties,
    attachmentOptionalRelations,
    onAttachmentInvolvedPartySelect,
    attachmentAdditionalInfo: { id, type = '', involvedPartyId = '' },
}) => {
    const relations: string[] = useMemo(
        () => (attachmentOptionalRelations == null ? [] : attachmentOptionalRelations[type] ?? []),

        [attachmentOptionalRelations, type]
    );

    const involvedPartyOptions = useMemo(
        () =>
            relations.reduce<AutocompleteOption[]>(
                (acc, involvedPartyid) => {
                    const involvedParty = involvedParties.find(p => p.publicId === involvedPartyid);

                    if (involvedParty != null) {
                        acc.push({
                            value: involvedParty.publicId,
                            label: involvedPartyTitle(involvedParty),
                            id: involvedParty.publicId,
                        });
                    }

                    return acc;
                },
                [
                    {
                        value: '',
                        label: 'None',
                        id: '',
                    },
                ]
            ),
        [relations, involvedParties]
    );
    const [options, setOptions] = useState<AutocompleteOption[]>(involvedPartyOptions);

    const onSearch = (term: string) => {
        const filteredOptions = involvedPartyOptions.filter(option => option.label.toLowerCase().includes(term));

        setOptions(filteredOptions);
    };

    return (
        <TableRowItem key={`attachment-involved-party-${id}`}>
            <Autocomplete
                disabled={involvedPartyOptions.length <= 1}
                onOptionSelected={option => onAttachmentInvolvedPartySelect(option.value ?? '', String(id))}
                onSearch={onSearch}
                options={options}
                placeholder="Select"
                value={involvedPartyId}
                width={170}
            />
        </TableRowItem>
    );
};

type AttachmentsPaginatedProps = {
    readonly claimPublicId: string;
    readonly timezone: string;
} & (withAdd | withoutAdd) &
    (withEdit | withOutEdit);

const AttachmentsPaginated: React.FC<React.PropsWithChildren<AttachmentsPaginatedProps>> = ({
    claimPublicId,
    timezone,
    updateAttachmentData,
    ...withAddProps
}) => {
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showAttachmentsDialog, setShowAttachmentsDialog] = useState(false);
    const [attachmentsAdditionalInfo, setAttachmentsAdditionalInfo] = useState<CarAttachmentToAdd[]>([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const refreshPage = useRefreshCarClaimAttchmentsPage();

    const { filters, updateFilters } = useListFilters<CarClaimsAttachmentsFilterFields & { claimPublicId: string }>(
        'car-claim-attachments',
        { ...INIT_SEARCH_PROPS, claimPublicId }
    );

    useEffect(() => {
        if (filters.claimPublicId !== claimPublicId) {
            const { types, description, involvedPartyIds, sources, offset, orderBy, orderByDir } = INIT_SEARCH_PROPS;

            updateFilters({
                claimPublicId,
                types,
                description,
                involvedPartyIds,
                sources,
                offset,
                orderBy,
                orderByDir,
            });
        }
    }, [claimPublicId]);

    const attachmentTypes = useMemo(
        () => filters.types?.filter(type => previewableAttachments.includes(type)),
        [filters.types]
    );

    const [ref, inView] = useInView();

    const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage } = useCarClaimAttchmentsPage({
        claimPublicId,
        attachmentTypes,
        description: filters.description,
        entityPublicIds: filters.involvedPartyIds,
        sources: filters.sources,
        pageSize: filters.pageSize,
        sortBy: filters.orderBy,
        sortingOrder: filters.orderByDir,
    });

    const { data: involvedPartiesData } = useCarClaimInvolvedParties(claimPublicId);

    const { data: filterData } = useCarClaimAttachmentsFiltersData(claimPublicId);

    const { data: attachmentOptionalRelationsData } = useAttachmentOptionalRelations(claimPublicId);

    const attachmentOptionalRelations = useMemo(
        () => attachmentOptionalRelationsData?.attachmentOptionalRelations,
        [attachmentOptionalRelationsData]
    );

    const involvedParties = useMemo(() => {
        if (involvedPartiesData?.involved_parties != null) {
            return [...involvedPartiesData.involved_parties.people, ...involvedPartiesData.involved_parties.vehicles];
        }

        return [];
    }, [involvedPartiesData]);

    const filterOptions: CarClaimsAttachmentsFiltersOptions = useMemo(() => {
        const {
            types: typeData = [],
            entityPublicIds: involvedPartyIds = [],
            sources = [],
        } = filterData?.attachmentsFiltersData ?? {};

        return {
            type: typeData
                .filter((typeOption): boolean => previewableAttachments.includes(typeOption as CarAttachmentType))
                .map(option => ({ title: toReadable(option), value: option })),
            involvedParty: involvedPartyIds
                .filter(option => option != null)
                .map(option => {
                    const partyTitle = involvedPartyTitle(
                        involvedParties.find(involvedParty => involvedParty.publicId === option)
                    );

                    return { title: partyTitle, value: option! };
                }),
            source: sources.map(option => ({ title: toReadable(option), value: option })),
        };
    }, [filterData?.attachmentsFiltersData, involvedParties]);

    const loadMoreInViewRef = useRef(null);

    const allAttachments = useMemo(
        () =>
            (data?.pages ?? []).flatMap(({ attachments }) =>
                attachments.map(attachment => carAttachmentToAttachment(attachment))
            ),
        [data?.pages]
    );

    const hideAddDialog = useCallback(() => setShowAddDialog(false), []);

    const onAddClicked = useCallback(() => setShowAddDialog(true), []);

    const showAttachmentsPreview = useCallback((index: number) => {
        setShowAttachmentsDialog(true);
        setSelectedIndex(index);
    }, []);

    const closeAttachmentPreview = useCallback(() => setShowAttachmentsDialog(false), []);

    const onInViewChange = useCallback(
        (inView: boolean) => {
            if (inView) {
                fetchNextPage();
            }
        },
        [fetchNextPage]
    );

    const updateFiltersAndReloadPages = useCallback(
        (filters: Partial<CarClaimsAttachmentsFilterFields>) => {
            updateFilters(filters);
            refreshPage();
        },
        [refreshPage, updateFilters]
    );

    const orderBy = useOrderBy<CarClaimsAttachmentsFilterFields>({
        updateFilters: updateFiltersAndReloadPages,
        filters,
    });

    const filtersApplied = useMemo(
        () =>
            filters.types !== INIT_SEARCH_PROPS.types ||
            filters.description !== INIT_SEARCH_PROPS.description ||
            filters.involvedPartyIds !== INIT_SEARCH_PROPS.involvedPartyIds ||
            filters.sources !== INIT_SEARCH_PROPS.sources,
        [filters.description, filters.involvedPartyIds, filters.sources, filters.types]
    );

    const isEmpty = useMemo(
        () => data != null && allAttachments.length === 0 && !filtersApplied,
        [allAttachments.length, data, filtersApplied]
    );

    const involvedPartiesIdToType = useMemo(
        () =>
            new Map(
                involvedParties.map(party => [
                    party.publicId,
                    party.type === InvolvedPartyType.Vehicle
                        ? AttachmentRelationEntityType.InvolvedVehicle
                        : AttachmentRelationEntityType.InvolvedPerson,
                ])
            ),
        [involvedParties]
    );

    const formatAttachment = useCallback(
        (attachment: AttachmentToAdd): AttachmentToUpload => {
            const involvedPartyId = attachmentsAdditionalInfo.find(info => info.id === attachment.id)?.involvedPartyId;

            const attachmentWithType = { ...attachment, type: attachment.type ?? AttachmentType.Attachment };

            return {
                ...attachmentWithType,
                involvedPartyId,
                involvedPartyType: involvedPartiesIdToType.get(involvedPartyId ?? ''),
            };
        },
        [involvedPartiesIdToType, attachmentsAdditionalInfo]
    );

    const formatAttachmentsToUpload = (attachments: AttachmentToAdd[]): AttachmentToUpload[] => {
        return attachments.reduce<AttachmentToUpload[]>((acc, att) => {
            const attToUpload = formatAttachment({
                ...att,
            });

            acc.push(attToUpload);

            return acc;
        }, []);
    };

    const handleAttachmentsUpdate = useCallback(
        (attachments: AttachmentToAdd[]) => {
            setAttachmentsAdditionalInfo(
                attachments.map(attachment => {
                    const existingAttachmentInfo = attachmentsAdditionalInfo.find(info => info.id === attachment.id);

                    const involvedPartyId =
                        existingAttachmentInfo != null && existingAttachmentInfo.type !== attachment.type
                            ? ''
                            : existingAttachmentInfo?.involvedPartyId;

                    return { ...existingAttachmentInfo, ...attachment, involvedPartyId };
                })
            );
        },
        [attachmentsAdditionalInfo]
    );

    const onAttachmentInvolvedPartySelect = useCallback(
        (involvedPartyId: string, attachmentId?: string) => {
            setAttachmentsAdditionalInfo(
                attachmentsAdditionalInfo.map(info => {
                    if (info.id === Number(attachmentId!)) {
                        return { ...info, involvedPartyId };
                    }

                    return info;
                })
            );
        },
        [attachmentsAdditionalInfo]
    );

    const attachmentsAdditionalInputs = useMemo(
        () =>
            attachmentsAdditionalInfo.map(info => ({
                attachmentId: info.id,
                components: [
                    <InvolvedPartySelect
                        attachmentAdditionalInfo={info}
                        attachmentOptionalRelations={attachmentOptionalRelations}
                        involvedParties={involvedParties}
                        key={info.id}
                        onAttachmentInvolvedPartySelect={onAttachmentInvolvedPartySelect}
                    />,
                ],
            })),
        [attachmentOptionalRelations, attachmentsAdditionalInfo, involvedParties, onAttachmentInvolvedPartySelect]
    );

    const additionalInputs = useMemo(
        () => ({
            inputs: attachmentsAdditionalInputs,
            scheme: CAR_ATTACHMENTS_ADDITIONAL_INPUTS_SCHEME,
        }),
        [attachmentsAdditionalInputs]
    );

    if (error != null) {
        return <ErrorSection />;
    }

    return (
        <>
            <StyledFiltersContainer>
                <SearchFilters
                    options={filterOptions}
                    selectedFilters={filters}
                    updateFilters={updateFiltersAndReloadPages}
                />
            </StyledFiltersContainer>
            <StyledCommonSection ref={ref}>
                <CommonSectionTitle
                    actions={withAddProps.addAttachments ? { onClick: onAddClicked, text: 'ADD' } : undefined}
                    title="Attachments"
                />
                {isEmpty ? (
                    <EmptyAttachmentsSection>NO ATTACHMENTS WERE UPLOADED FOR THIS CLAIM</EmptyAttachmentsSection>
                ) : (
                    data != null &&
                    allAttachments.length === 0 &&
                    filtersApplied && (
                        <EmptyAttachmentsSection>NO ATTACHMENTS MATCH THE SELECTED FILTERS</EmptyAttachmentsSection>
                    )
                )}
                {!isEmpty && (
                    <Table>
                        <TableHeader
                            headers={headers.filter(header => header.key !== 'assign')}
                            sortableSet={{
                                sort: orderBy,
                                sortingBy: filters.orderBy,
                                sortDirection: filters.orderByDir,
                            }}
                        />

                        {(data?.pages ?? []).map((page, pageIdx: number) => {
                            const pageAttachments = page.attachments.map(attachment =>
                                carAttachmentToAttachment(attachment)
                            );

                            return (
                                <Fragment key={pageIdx}>
                                    {pageAttachments.map((attachment: Attachment, index: number) => (
                                        <AttachmentRow
                                            attachment={attachment}
                                            columns={carAttachmentsHeaders}
                                            inView={inView}
                                            involvedParties={involvedParties}
                                            key={attachment.file_public_id}
                                            showAttachmentsPreview={() => {
                                                showAttachmentsPreview(pageIdx * PAGE_SIZE + index);
                                            }}
                                            timezone={timezone}
                                            updateAttachmentData={updateAttachmentData}
                                        />
                                    ))}
                                </Fragment>
                            );
                        })}
                        {(data == null || hasNextPage) && (
                            <InView onChange={onInViewChange} ref={loadMoreInViewRef}>
                                <SpinnerWrapper>
                                    {(data == null || isFetchingNextPage) && <Spinner size={25} />}
                                </SpinnerWrapper>
                            </InView>
                        )}
                        {data != null && data.pages.length !== 1 && !hasNextPage && (
                            <TableRow
                                row={[
                                    {
                                        key: 'no-more-results',
                                        value: <NoMoreResults>No more attachments 💪</NoMoreResults>,
                                    },
                                ]}
                            />
                        )}
                    </Table>
                )}
                {showAddDialog && withAddProps.addAttachments && (
                    <AddAttachments
                        addAttachments={withAddProps.addAttachments}
                        addAttachmentsLoading={withAddProps.addAttachmentsLoading}
                        additionalInputs={additionalInputs}
                        close={hideAddDialog}
                        entityId={withAddProps.entityId}
                        formatAttachmentsToUpload={formatAttachmentsToUpload}
                        getAttachmentTypes={withAddProps.getAttachmentTypes}
                        onAttachmentsWillUpdate={handleAttachmentsUpdate}
                    />
                )}
                {showAttachmentsDialog && (
                    <AttachmentsPreview
                        attachments={allAttachments}
                        infoPanel={{
                            active: true,
                            timezone,
                        }}
                        onClose={closeAttachmentPreview}
                        selectedIndex={selectedIndex}
                    />
                )}
            </StyledCommonSection>
        </>
    );
};

export default AttachmentsPaginated;
