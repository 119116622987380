import type { ReactElement, ReactNode, Ref } from 'react';
import { Children, cloneElement, forwardRef, isValidElement } from 'react';
import type { FlexProps } from '../../base/Flex/Flex';
import { Flex } from '../../base/Flex/Flex';
import { spacing } from '../../theme/spacing.css';
import { Button } from '../Button/Button';
import type { ButtonProps } from '../Button/Button';

export type ButtonGroupButton = Omit<ButtonProps, 'size'> & { readonly label: string };

type ButtonGroupWithChildren = {
  readonly children: ReactElement | ReactNode[];
  readonly buttons?: never;
};
type ButtonGroupWithButtons = { readonly children?: never; readonly buttons: ButtonGroupButton[] };

export type ButtonGroupProps = {
  /** The `size` prop cannot be passed to the buttons directly, it will be applied to all buttons in the group. */
  readonly size?: 'lg' | 'md' | 'sm';
} & (ButtonGroupWithButtons | ButtonGroupWithChildren);

const gapBySize = {
  sm: spacing.s06,
  md: spacing.s06,
  lg: spacing.s08,
};

/**
 * `ButtonGroup` is a component that groups buttons together. It can be used with either `buttons` prop or `children` prop.
 */
export const ButtonGroup = forwardRef(({ buttons, children, size = 'md' }: ButtonGroupProps, ref: Ref<FlexProps>) => {
  const gap = gapBySize[size];

  const childrenWithSize = Children.map(children, child => {
    if (isValidElement(child) && child.type === Button) {
      return cloneElement(child as ReactElement<ButtonProps>, { size });
    }

    return child;
  });

  return (
    <Flex alignItems="center" gap={gap} ref={ref}>
      {buttons == null
        ? childrenWithSize
        : buttons.map(button => <Button {...button} key={button.label} size={size} />)}
    </Flex>
  );
});
