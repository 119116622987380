import type { AlertMode } from '@lemonade-hq/bluis';
import type { Address } from '@lemonade-hq/lemonation';
import { snakeToCamelCaseKeys } from '@lemonade-hq/ts-helpers';
import type { SnakeCaseToCamelCase } from '@lemonade-hq/ts-helpers';
import axios from 'axios';
import type { VehiclesPhotoCollectionsResponse } from 'apis/CarVehiclesPhotoCollections';
import { getUrlResolver } from 'commons/UrlResolver';
import type { CarPolicyListItem } from 'components/car/models/entity';
import type { CarEntitySearchParams, CarFilterOptions, PaginationDTO } from 'components/car/models/shared';
import type { Address as LegacyAddress } from 'models/Address';
import type { CarCoverages } from 'models/CarCoverages';
import type { DriverViolationsType } from 'models/CarDriverViolation';
import type {
    CancelCarPolicyData,
    CancellationReason,
    CarPolicy,
    CarPolicyAction,
    CarPolicyBackdateCancellationsData,
    CarPolicyCancellationData,
    Status as CarPolicyStatus,
    ForceAdditionalDriverEnrollmentData,
    PolicyEmailType,
    RenewalPremiumChanges,
    UwCancelCarPolicyRevertData,
    UwCancelConfirmationData,
    UWCancellationReason,
} from 'models/CarPolicy';
import type { BillingType, CarQuote, CarVendor, PaymentPlan, PaymentPlan as QuotePaymentPlan } from 'models/CarQuote';
import type { CarClaimReporter, ClaimHistory, FeesBreakdown, QuoteAddress } from 'models/CarShared';
import { ExportEntityType } from 'models/CarShared';
import type { CarUser } from 'models/CarUser';
import type { CarUwReview, CarUwReviewAvailable, CarUwReviewReviewer } from 'models/CarUwReview';
import type { VehicleDeviceTimeline, VehicleWithAccessories } from 'models/CarVehicleAccessories';
import type { CarVendorDataVehicleHistory } from 'models/CarVehicleHistory';
import type { Driver, DriverEditData, DriverTelematicsStatus } from 'models/Driver';
import type { Dunning } from 'models/Dunning';
import type { NonrenewalContent, ScheduleNonrenewalData } from 'models/Nonrenewal';
import type { PaymentPlan as PaymentPlanPayment, PpmSubscriptionPayments, SubscriptionPayments } from 'models/Payment';
import type { PolicyVariation } from 'models/PolicyVariation';
import type { SendReturnKitDTO, TelematicShipmentResponse } from 'models/Shipment';
import type { TelematicsDriver } from 'models/Telematics';
import type { UsageType, Vehicle } from 'models/Vehicle';

export interface Item {
    readonly type: PricingItemType;
    readonly code: string;
    readonly amount: string;
    readonly description?: string;
    readonly metadata?: object;
}

export interface FeeItem extends Item {
    readonly chargeStrategy?: PricingItemChargeStrategy;
}

export interface TaxItem extends Item {
    readonly chargeStrategy?: PricingItemChargeStrategy;
}

export interface PremiumItem extends Item {
    readonly premiumCode: string;
}

export type ItemizedCost = FeeItem | PremiumItem | TaxItem;
export type ItemizedCosts = ItemizedCost[];

export interface UpdateEvaluationResponse {
    readonly paymentPlan: PaymentPlanPayment;
    readonly chargeAmounts: ItemizedCosts;
    readonly remainingInstallments: number;
    readonly chargeAmountsPerInstallment: ItemizedCosts;
    readonly policyPublicId: string;
    readonly quotePublicId: string;
    readonly pricingId: string;
    readonly premium: number;
}

export interface SendAccessoryTypesReasons {
    readonly type: string;
    readonly reasons: string[];
}

export interface ReplaceDeviceReasons {
    readonly reason: string;
    readonly subReasons: string[];
}

export type CarPolicyResponse = {
    readonly policy_changes: CarPolicyChanges[];
    readonly quote: CarQuote;
    readonly timezone: string;
    readonly user: CarUser;
    readonly fcra_attachment: string;
    readonly policy: CarPolicy;
    readonly policy_insurance_id_pdfs: Record<string, string>;
    readonly policy_actions: CarPolicyAction[];
    readonly address: QuoteAddress;
    readonly cause: { readonly title: string };
    readonly drivers: Driver[];
    readonly vehicles: Vehicle[];
    readonly coverages: CarCoverages;
    readonly dunning: SnakeCaseToCamelCase<Dunning> | null;
    readonly policy_pdf_url: string | null;
    readonly policy_cancellation: CarPolicyCancellationData;
    readonly policy_backdate_cancellations: CarPolicyBackdateCancellationsData;
    readonly available_reporter_types: CarClaimReporter[];
    readonly drivers_telematics_status: Record<Driver['publicId'], DriverTelematicsStatus>;
    readonly vehicle_history: CarVendorDataVehicleHistory[];
    readonly driver_violations: DriverViolationsType;
    readonly claim_history: ClaimHistory[];
    readonly telematics_drivers: TelematicsDriver[];
    readonly policy_transactions: SnakeCaseToCamelCase<SubscriptionPayments>;
    readonly policy_ppm_payments: SnakeCaseToCamelCase<PpmSubscriptionPayments>;
    readonly renewal_policy_premium_changes: RenewalPremiumChanges[];
    readonly nonrenewal_content: NonrenewalContent;
    readonly driverActions: CarDriverActions[];
    readonly uwReviewAvailable: CarUwReviewAvailable;
    readonly uwReview: CarUwReview;
    readonly fees_breakdown: FeesBreakdown;
    readonly vehicle_accessories: Record<string, VehicleWithAccessories>;
    readonly vehicle_devices_timeline: Partial<Record<string, VehicleDeviceTimeline[]>>;
    readonly vehicles_photo_collections: VehiclesPhotoCollectionsResponse;
    readonly telematics_shipments: TelematicShipmentResponse;
};

export interface CarUnderWritingCollectionResponse {
    readonly uwReviewStatusOptions: string[];
    readonly uwReviewHandlingStatusOptions: string[];
    readonly uwReviewers: CarUwReviewReviewer[];
}

export interface TimelessDate {
    readonly year: number;
    readonly month: number;
    readonly day: number;
}

export enum SubEntityTypes {
    VehicleQuote = 'VEHICLE_QUOTE',
    DriverQuote = 'DRIVER_QUOTE',
    Address = 'ADDRESS',
    Driver = 'DRIVER',
}

export enum DriverChangesKeys {
    FirstName = 'firstName',
    LastName = 'lastName',
    Type = 'type',
    Role = 'role',
    Birthdate = 'birthdate',
    MaritalStatus = 'maritalStatus',
    LicenseStatus = 'licenseStatus',
    LicenseIssueDate = 'licenseIssueDate',
    LicenseState = 'licenseState',
    LicenseId = 'licenseId',
    Gender = 'gender',
    Status = 'status',
}

export enum GeneralChangeKeys {
    Drivers = 'drivers',
    Vehicles = 'vehicles',
    Address = 'address',
    EffectiveAt = 'effectiveAt',
    PaymentPeriod = 'paymentPeriod',
    Coverages = 'coverages',
    GaragingInfo = 'garagingInfo',
    Discounts = 'discounts',
    RegisteredOwners = 'registeredOwners',
    VehicleCoverages = 'vehicleCoverages',
}

interface CarPolicyPdfExport {
    readonly [ExportEntityType.CarPolicy]: {
        readonly options: { readonly key: string; readonly display_name: string }[];
    };
}

export const PolicyChangeKeys = {
    ...GeneralChangeKeys,
    ...DriverChangesKeys,
} as const;

export interface BasePolicyChange {
    readonly key: unknown;
    readonly currentValue: unknown;
    readonly previousValue: unknown;
    readonly subEntityType?: SubEntityTypes;
    readonly subEntityPublicId?: string;
}

interface DriverDateTypePropertyPolicyChange extends BasePolicyChange {
    readonly key: DriverChangesKeys.Birthdate | DriverChangesKeys.LicenseIssueDate;
    readonly currentValue: TimelessDate;
    readonly previousValue: TimelessDate;
    readonly subEntityType: SubEntityTypes.Driver | SubEntityTypes.DriverQuote;
}

interface DriverStringTypePropertyPolicyChange extends BasePolicyChange {
    readonly key: Exclude<DriverChangesKeys, DriverChangesKeys.Birthdate | DriverChangesKeys.LicenseIssueDate>;
    readonly currentValue: string | null;
    readonly previousValue: string | null;
    readonly subEntityType: SubEntityTypes.Driver | SubEntityTypes.DriverQuote;
}

export type DriverPropertyPolicyChange = DriverDateTypePropertyPolicyChange | DriverStringTypePropertyPolicyChange;

export interface DriverOnPolicyChange extends BasePolicyChange {
    readonly key: 'drivers';
    readonly addedQuoteDrivers: Driver[];
    readonly excludedQuoteDrivers: Driver[];
    readonly removedQuoteDrivers: Driver[];
}

export interface VehicleOnPolicyChange extends BasePolicyChange {
    readonly key: 'vehicles';
    readonly addedQuoteVehicles: Vehicle[];
    readonly removedQuoteVehicles: Vehicle[];
}

export interface GeneralPolicyChange extends BasePolicyChange {
    readonly key: Exclude<GeneralChangeKeys, 'drivers' | 'vehicles'>;
    readonly coverage: string;
    readonly currentValue: string;
    readonly previousValue: string;
    readonly subEntityType?: Exclude<SubEntityTypes, SubEntityTypes.Driver | SubEntityTypes.DriverQuote>;
}

export type PolicyChange =
    | DriverOnPolicyChange
    | DriverPropertyPolicyChange
    | GeneralPolicyChange
    | VehicleOnPolicyChange;

export enum PolicyVersionBackdateReason {
    VehicleInspectionOverdue = 'vehicle_inspection_overdue',
    ManualBackdateToPreviousVersion = 'manual_backdate_to_previous_version',
}

interface PolicyVersionMetadata {
    readonly backdateReason: PolicyVersionBackdateReason;
}

export interface CarPolicyChanges {
    readonly id: string;
    readonly effectiveFrom: string;
    readonly updatedAt: string;
    readonly createdAt: string;
    readonly changes: PolicyChange[];
    readonly pdfUrl: string;
    readonly premium?: number;
    readonly premiumDiff?: number;
    readonly baseMonthlyPremium?: number;
    readonly averagePerMilePremium?: number;
    readonly metadata?: PolicyVersionMetadata;
}

export type DriverActionType = 'change_to_personal_use' | 'edit_driver' | 'remove_driver';

export interface DriverAction {
    readonly action: DriverActionType;
    readonly enabled: boolean;
    readonly reason: string;
}

export interface CarDriverActions {
    readonly driverPublicId: string;
    readonly actions: DriverAction[];
}

export interface CarPolicySimulateCancelResponse {
    readonly affectedPolicies: PolicyVariation[];
}

export interface CarVendorResponse {
    readonly vehicleDetailsVendor: CarVendor;
}

interface Alert {
    readonly description: string;
    readonly type: AlertMode;
}

export type NewQuoteData = {
    readonly quote: CarQuote;
    readonly premiumDiff: number;
    readonly alerts: Alert[];
    readonly vehicles?: Vehicle[];
    readonly policyPublicId?: string;
};

export type CarEditDriverSimulationResponse = NewQuoteData[];
export type CarRemoveDriverSimulationResponse = NewQuoteData;
export type CarRemoveVehicleSimulationResponse = NewQuoteData;
export type CarEditPrimaryUseSimulationResponse = NewQuoteData[];
export type CarEditAddressSimulationResponse = NewQuoteData[];

export type VehicleBillingStrategiesOption = {
    readonly billingStrategy: string;
    readonly enabled: boolean;
};

export type VehicleBillingStrategyOptionsResponse = {
    readonly vehicleBillingStrategiesOptions: VehicleBillingStrategiesOption[];
};

export interface CarRenewalNoticePdfResponse {
    readonly url: string;
}

export interface ReplaceDevicePayload {
    readonly replacementReason: string;
    readonly replacementSubReason: string;
    readonly otherReason: string;
    readonly policyPublicId: string;
    readonly vehiclePublicId: string;
    readonly shippingAddress: Address;
}

export interface UpdateVehicleBillingStrategyPayload {
    readonly vehiclePublicId: string;
    readonly billingStrategy: string;
    readonly reason: string;
    readonly policyPublicId: string;
}

export interface PolicyPdfUrlResponse {
    readonly url: string;
}

export interface UpdatePaymentMethodUrlResponse {
    readonly url: string;
}

export interface EditVehiclePrimaryUseRequest {
    readonly vehiclePublicId: string;
    readonly usageType: string;
    readonly quotePublicId: string;
    readonly operatorId: string;
    readonly additionalPolicies?: {
        readonly policyPublicId: string;
        readonly quotePublicId: string;
    }[];
}

const carBlenderUrlResolver = getUrlResolver('car-blender', '/api/v1/policies');
const carBlenderDevicesUrlResolver = getUrlResolver('car-blender', '/api/v1/devices');
const carBlenderVehicleBillingStrategyResolver = getUrlResolver('car-blender', '/api/v1/vehicle_billing_strategy');
const carBlenderShipments = getUrlResolver('car-blender', '/api/v1/shipments');
const policyCancelUrlResolver = getUrlResolver('car-blender', '/api/v1/policy_cancellations');

export async function fetchCarPolicyData<K extends keyof CarPolicyResponse>(
    policyId: string,
    keys: K[]
): Promise<Pick<CarPolicyResponse, K>> {
    const mapKeys = (key: string) => `include[]=${key}`;
    const url = carBlenderUrlResolver(`/${policyId}?${encodeURI(keys.map(mapKeys).join('&'))}`);

    return await axios.get<{ data: Pick<CarPolicyResponse, K> }>(url).then(response => response.data.data);
}

export async function fetchCarRenewalNoticePdf(renewalPolicyId: string): Promise<CarRenewalNoticePdfResponse> {
    const url = carBlenderUrlResolver(`/${renewalPolicyId}/renewal_notice/pdf`);

    return await axios.get<{ data: CarRenewalNoticePdfResponse }>(url).then(response => response.data.data);
}

export async function fetchCarSimulateCancelData(policyId: string): Promise<CarPolicySimulateCancelResponse> {
    const url = carBlenderUrlResolver(`/${policyId}/simulate_cancel`);

    return await axios.get<{ data: CarPolicySimulateCancelResponse }>(url).then(response => response.data.data);
}

export async function fetchCarVendor(
    policyId: string,
    billingType: BillingType,
    state: string
): Promise<CarVendorResponse> {
    const url = carBlenderUrlResolver(
        `/${policyId}/vehicle_details_options_metadata?product=${billingType}&state=${state}`
    );
    return await axios.get<{ data: CarVendorResponse }>(url).then(response => response.data.data);
}

export async function fetchCarPolicyCancellationData(
    policyId: string
): Promise<Pick<CarPolicyResponse, 'policy_cancellation'>> {
    return await fetchCarPolicyData(policyId, ['policy_cancellation']);
}

export async function fetchCarPolicyBackdateCancellationsData(
    policyId: string
): Promise<Pick<CarPolicyResponse, 'policy_backdate_cancellations'>> {
    return await fetchCarPolicyData(policyId, ['policy_backdate_cancellations']);
}

export async function getUpdatePaymentMethodurl(policyPublicId: string): Promise<string> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/get_update_payment_method_url`);

    return await axios.get<{ data: UpdatePaymentMethodUrlResponse }>(url).then(response => response.data.data.url);
}

export async function getDownloadPolicyPdfurl(policyPublicId: string): Promise<string> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/get_policy_pdf_url`);

    return await axios.get<{ data: PolicyPdfUrlResponse }>(url).then(response => response.data.data.url);
}

export async function forceAdditionalDriverEnrollment({
    policyPublicId,
    data,
}: {
    readonly policyPublicId: string;
    readonly data: ForceAdditionalDriverEnrollmentData;
}): Promise<void> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/force_additional_driver_enrollment`);

    await axios.post(url, data);
}

export async function cancelPolicy({
    policyPublicId,
    cancellationData,
}: {
    readonly policyPublicId: string;
    readonly cancellationData: CancelCarPolicyData;
}): Promise<CarPolicy> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/cancel`);

    return await axios.post<{ data: CarPolicy }>(url, cancellationData).then(response => response.data.data);
}

export async function requestSr22Report({ driverPublicId }: { readonly driverPublicId: string }): Promise<void> {
    const url = carBlenderUrlResolver('/request_sr22');

    await axios.post(url, { driverPublicId });
}

export async function uwCancelPolicy({
    policyPublicId,
    cancellationData,
}: {
    readonly policyPublicId: string;
    readonly cancellationData: CancelCarPolicyData;
}): Promise<void> {
    const url = policyCancelUrlResolver(`/${policyPublicId}/uw_cancellation`);

    await axios.post(url, cancellationData);
}

export async function revertUWCancelPolicy({
    policyPublicId,
    data,
}: {
    readonly policyPublicId: string;
    readonly data: UwCancelCarPolicyRevertData;
}): Promise<void> {
    const url = policyCancelUrlResolver(`/${policyPublicId}/uw_cancellation_revert`);

    await axios.post(url, data);
}

export async function simulateUWCancelConfirmationPolicy({
    policyPublicId,
    data,
}: {
    readonly policyPublicId: string;
    readonly data: UwCancelConfirmationData;
}): Promise<void> {
    const url = policyCancelUrlResolver(
        `/${policyPublicId}/simulate_uw_cancel_confirmation?operatorId=${data.operatorId}&simulateAction=${data.simulateAction}`
    );

    await axios.get(url);
}

export interface CarPolicyCollectionResponse {
    readonly user_cancellation_reasons: CancellationReason[];
    readonly underwriting_cancellation_reasons: UWCancellationReason[];
    readonly sendAccessoryTypesReasons: SendAccessoryTypesReasons;
    readonly replaceDeviceReasons: ReplaceDeviceReasons[];
}

export async function fetchCarPolicyCollectionData<K extends keyof CarPolicyCollectionResponse>(
    keys: K[]
): Promise<Pick<CarPolicyCollectionResponse, K>> {
    const mapKeys = (key: string) => `include[]=${key}`;
    const url = carBlenderUrlResolver(`?${encodeURI(keys.map(mapKeys).join('&'))}`);

    return await axios.get<{ data: Pick<CarPolicyCollectionResponse, K> }>(url).then(response => response.data.data);
}

export async function getPolicyUWCancellationNotice(editionPublicId?: string): Promise<string> {
    const url = policyCancelUrlResolver(`/uw_cancellation_notice_period?editionPublicId=${editionPublicId}`);

    return await axios
        .get<{ data: { uwCancellationNoticePeriod: number } }>(url)
        .then(response => `${response.data.data.uwCancellationNoticePeriod}`);
}

export async function getPolicyUWCancellationPeriodDays(policyPublicId?: string): Promise<number> {
    const url = policyCancelUrlResolver(`/${policyPublicId}/uwCancellationPeriodDays`);

    return await axios.get(url).then(response => response.data);
}

export async function createRenewalPolicy({ policyPublicId }: { readonly policyPublicId: string }): Promise<void> {
    const url = carBlenderUrlResolver(`/renewals/${policyPublicId}`);

    await axios.post(url);
}

export async function activateRenewalPolicy({ policyPublicId }: { readonly policyPublicId: string }): Promise<void> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/activate_renewal_policy`);

    await axios.post(url);
}

export async function revertScheduledNonrenewal({
    policyPublicId,
}: {
    readonly policyPublicId: string;
}): Promise<void> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/revert_scheduled_nonrenewal`);

    await axios.post(url);
}

export async function scheduleNonrenewal({
    policyPublicId,
    data,
}: {
    readonly policyPublicId: string;
    readonly data: ScheduleNonrenewalData;
}): Promise<void> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/schedule_nonrenewal`);

    await axios.post(url, data);
}

export async function sendNonrenewalNotice({ policyPublicId }: { readonly policyPublicId: string }): Promise<void> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/send_nonrenewal_notice`);

    await axios.post(url);
}

export async function simulateCarPolicyChangeAddress({
    policyPublicId,
    address,
}: {
    readonly policyPublicId: string;
    readonly address: LegacyAddress;
}): Promise<CarEditAddressSimulationResponse> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/simulate_edit_address`);

    return await axios
        .post<{
            data: CarEditAddressSimulationResponse;
        }>(url, { ...snakeToCamelCaseKeys({ zipCode: address.postal_code, ...address }) })
        .then(response => response.data.data);
}

export async function simulateNonrenewalReview({ policyPublicId }: { readonly policyPublicId: string }): Promise<void> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/simulate_nonrenewal_review`);

    await axios.post(url);
}

interface ChangePaymentPlanDetails {
    readonly paymentPlan: QuotePaymentPlan;
    readonly policyPublicId: string;
    readonly quotePublicId: string;
    readonly pricingId: string;
}

export async function changePaymentPlan({
    paymentPlan,
    policyPublicId,
    quotePublicId,
    pricingId,
}: ChangePaymentPlanDetails): Promise<void> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/changePaymentPlan`);

    return await axios
        .post(url, { paymentPlan, policyPublicId, quotePublicId, pricingId })
        .then(({ data }) => data.data);
}

export enum PricingItemType {
    Premium = 'premium',
    Fee = 'fee',
    Tax = 'tax',
}

export enum PricingItemChargeStrategy {
    Split = 'split',
    OneTime = 'one_time',
}

export interface PricingItemDTO {
    readonly type: PricingItemType;
    readonly code: string;
    readonly amount: number;
    readonly premium_code: string;
    readonly charge_strategy: PricingItemChargeStrategy;
}

export async function getNewPremiumForNewPaymentPlan(
    policyPublicId: string,
    paymentPlan: PaymentPlan
): Promise<UpdateEvaluationResponse> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/new_premium/${paymentPlan}`);

    return await axios.get(url).then(({ data }) => data);
}

export async function getExcludeDriverUrl(policyPublicId: string): Promise<string> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/exclude_driver_micro_flow_url`);

    return await axios.get<{ data: { url: string } }>(url).then(response => response.data.data.url);
}

export async function fetchSimulateRemoveDriver(
    policyPublicId: string,
    driverPublicId: string
): Promise<CarRemoveDriverSimulationResponse> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/simulate_remove_driver`);

    return await axios
        .post<{ data: CarRemoveDriverSimulationResponse }>(url, { driverPublicId })
        .then(response => response.data.data);
}

export async function removeDriver({
    policyPublicId,
    quotePublicId,
    driverPublicId,
}: {
    readonly policyPublicId: string;
    readonly quotePublicId: string;
    readonly driverPublicId: string;
}): Promise<void> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/remove_driver`);

    await axios.put(url, { quotePublicId, driverPublicId });
}

export interface RemoveVehicleParams {
    readonly policyPublicId: string;
    readonly quotePublicId: string;
    readonly vehiclePublicId: string;
}

export async function removeVehicle({
    policyPublicId,
    quotePublicId,
    vehiclePublicId,
}: RemoveVehicleParams): Promise<void> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/remove_vehicle`);

    const body = {
        quotePublicId,
        vehiclePublicId,
    };

    await axios.put(url, body);
}

export async function fetchSimulatePolicyEditDriver(
    policyPublicId: string,
    driverEditData: DriverEditData,
    reorderReports: boolean
): Promise<CarEditDriverSimulationResponse> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/simulate_edit_driver`);

    return await axios
        .post<{ data: CarEditDriverSimulationResponse }>(url, { reorderReports, ...driverEditData })
        .then(response => response.data.data);
}

export interface EditDriverParams {
    readonly entityPublicId: string;
    readonly quotePublicId: string;
    readonly driverEditData: DriverEditData;
}

export async function editPolicyDriver({
    entityPublicId,
    quotePublicId,
    driverEditData,
}: EditDriverParams): Promise<void> {
    const url = carBlenderUrlResolver(`/${entityPublicId}/edit_driver`);

    await axios.put(url, { quotePublicId, ...driverEditData });
}

export async function fetchCarPolicyReviewCollectionData<K extends keyof CarUnderWritingCollectionResponse>(
    policyId: string,
    keys: K[]
): Promise<Pick<CarUnderWritingCollectionResponse, K>> {
    const mapKeys = (key: string) => `include[]=${key}`;
    const url = carBlenderUrlResolver(`/${policyId}?${encodeURI(keys.map(mapKeys).join('&'))}`);

    return await axios
        .get<{ data: Pick<CarUnderWritingCollectionResponse, K> }>(url)
        .then(response => response.data.data);
}

export async function fetchCarPolicyUwReviewData(policyId: string): Promise<Pick<CarPolicyResponse, 'uwReview'>> {
    return await fetchCarPolicyData(policyId, ['uwReview']);
}

export async function fetchCarPolicySectionsForExport(): Promise<CarPolicyPdfExport> {
    const url = getUrlResolver('car-blender', '/api/v1/exports/sections')();

    return await axios.get(url).then(response => response.data);
}

export async function fetchPolicyPdfFile(policyId: string, sections: string[]): Promise<string> {
    const exportUrl = getUrlResolver('car-blender', '/api/v1/exports')();

    return await axios
        .post(exportUrl, { entityType: ExportEntityType.CarPolicy, entityId: policyId, sections })
        .then(response => response.data);
}

export async function replaceDevice(data: ReplaceDevicePayload): Promise<void> {
    const url = carBlenderDevicesUrlResolver('/replace');

    await axios.post(url, data);
}

export async function updateVehicleBillingStrategy(data: UpdateVehicleBillingStrategyPayload): Promise<void> {
    const url = carBlenderVehicleBillingStrategyResolver('/update_billing_strategy');

    await axios.post(url, data);
}

export interface SendAccessoryPayload {
    readonly accessoryType: string;
    readonly reason: string;
    readonly policyPublicId: string;
    readonly vehiclePublicId: string;
    readonly shippingAddress: Address;
}

export async function sendAccessory(data: SendAccessoryPayload): Promise<void> {
    const url = getUrlResolver('car-blender', '/api/v1/vehicle_accessories/send')();

    await axios.post(url, data);
}

export interface SearchCarPoliciesAPIResponse extends PaginationDTO {
    readonly policies: CarPolicyListItem[];
    readonly offset: number;
}

export type FetchCarSearchFiltersAPIResponse = CarFilterOptions<CarPolicyStatus>;

export async function searchCarQuotes(searchParams: CarEntitySearchParams): Promise<SearchCarPoliciesAPIResponse> {
    const url = `/backoffice/car/policies/search`;

    return await axios
        .post<{ data: SearchCarPoliciesAPIResponse }>(url, searchParams)
        .then(response => response.data.data);
}

export async function fetchSearchParams(): Promise<FetchCarSearchFiltersAPIResponse> {
    const url = `/backoffice/car/policies/search_params`;

    return await axios.get<{ data: FetchCarSearchFiltersAPIResponse }>(url).then(response => response.data.data);
}

export type EvaluateBackdateCancellationResponse = {
    readonly data: number;
};

export async function evaluateBackdateCancellation(
    publicId: string,
    date: string
): Promise<EvaluateBackdateCancellationResponse> {
    const url = carBlenderUrlResolver(`/${publicId}/evaluate_backdate_cancellation_refund`);

    return await axios.post(url, { date }).then(response => response.data);
}

export async function removeVehicleSimulate({
    policyPublicId,
    vehiclePublicId,
}: {
    readonly policyPublicId: string;
    readonly vehiclePublicId: string;
}): Promise<CarRemoveDriverSimulationResponse> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/simulate_remove_vehicle`);

    const body = { vehiclePublicId };

    return await axios
        .post<{ data: CarRemoveDriverSimulationResponse }>(url, body)
        .then(response => response.data.data);
}

export async function fetchVehicleBillingStrategyOptions(
    vehiclePublicId: string
): Promise<VehicleBillingStrategyOptionsResponse> {
    const url = carBlenderVehicleBillingStrategyResolver(`/billing_strategies/${vehiclePublicId}`);

    return await axios.get<VehicleBillingStrategyOptionsResponse>(url).then(response => response.data);
}

export async function sendReturnKit(body: SendReturnKitDTO): Promise<void> {
    const url = carBlenderShipments('/send-return-kit');
    await axios.post<VehicleBillingStrategyOptionsResponse>(url, body).then(response => response.data);
}

export async function editPrimaryUsageSimulate({
    policyPublicId,
    vehiclePublicId,
    primaryUsage,
}: {
    readonly policyPublicId: string;
    readonly vehiclePublicId: string;
    readonly primaryUsage: UsageType;
}): Promise<CarEditPrimaryUseSimulationResponse> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/simulate_edit_primary_use`);

    return await axios
        .post<{ data: CarEditPrimaryUseSimulationResponse }>(url, { vehiclePublicId, usageType: primaryUsage })
        .then(response => response.data.data);
}

export async function editPrimaryUse(policyPublicId: string, request: EditVehiclePrimaryUseRequest): Promise<void> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/edit_primary_use`);
    await axios.put(url, request);
}

export interface AdditionalPolicy {
    readonly policyPublicId: string;
    readonly quotePublicId: string;
}

export interface ApplySimulatedQuotesRequest {
    readonly quotePublicId: string;
    readonly additionalPolicies: AdditionalPolicy[];
    readonly performedBy: string;
    readonly emailType: PolicyEmailType;
}

export async function editAddress({
    policyPublicId,
    request,
}: {
    readonly policyPublicId: string;
    readonly request: ApplySimulatedQuotesRequest;
}): Promise<void> {
    const url = carBlenderUrlResolver(`/${policyPublicId}/edit_address`);

    await axios.put(url, request);
}
