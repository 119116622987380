import type { Milliseconds, Proportion } from '@lemonade-hq/ts-helpers';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useAudioPlayer } from './AudioPlayer.Provider';

function calculateAmplitudes(channelData: Float32Array, frameSize: number): number[] {
  const amplitudes: number[] = [];
  for (let i = 0; i < channelData.length; i += frameSize) {
    const frame = channelData.slice(i, i + frameSize);
    const rms = Math.sqrt(frame.reduce((sum, value) => sum + value ** 2, 0) / frame.length);
    amplitudes.push(rms);
  }

  return amplitudes;
}

function normalizeWithPercentile(values: number[], percentile = 0.95): number[] {
  const sorted = [...values].sort((a, b) => a - b);
  const maxIndex = Math.floor(sorted.length * percentile);
  const normalizeValue = sorted[maxIndex];

  return values.map(v => v / normalizeValue);
}

export interface AudioPlayerWaveformProviderProps {
  readonly frameDuration: Milliseconds;
  readonly normalize?: boolean;
  readonly reverseChannels?: boolean;
}

export interface AudioPlayerWaveformContextProps {
  readonly channels: Proportion[][]; // 0..1 values in multiple channels
  readonly mergedChannels: Proportion[];
}

export const DEFAULT_AUDIO_PLAYER_WAVEFORM_CONTEXT = {
  channels: [[], []] as [Proportion[], Proportion[]],
  mergedChannels: [] as Proportion[],
};

export const AudioPlayerWaveformContext = createContext<AudioPlayerWaveformContextProps | null>(
  DEFAULT_AUDIO_PLAYER_WAVEFORM_CONTEXT,
);

export const AudioPlayerWaveformProvider: FC<PropsWithChildren<AudioPlayerWaveformProviderProps>> = ({
  frameDuration,
  normalize,
  reverseChannels,
  children,
}) => {
  const { audioBuffer } = useAudioPlayer();

  const channels = useMemo(() => {
    if (audioBuffer == null) return [[], []] as [Proportion[], Proportion[]];

    const sampleRate = audioBuffer.sampleRate;
    const frameSize = Math.floor((sampleRate * frameDuration) / 1000);

    const result: Proportion[][] = [];
    for (let c = 0; c < audioBuffer.numberOfChannels; c += 1) {
      const channelData = audioBuffer.getChannelData(c);
      const amplitudes = calculateAmplitudes(channelData, frameSize);
      result.push(amplitudes);
    }

    if (normalize) {
      result[0] = normalizeWithPercentile(result[0]);
      result[1] = normalizeWithPercentile(result[1]);
    }

    return reverseChannels ? result.reverse() : result;
  }, [audioBuffer, frameDuration, normalize, reverseChannels]);

  const mergedChannels = useMemo(() => {
    if (channels.length === 0) return [];

    return channels[0].map((value, i) => (value + channels[1][i]) / 2);
  }, [channels]);

  const contextValue = useMemo(() => ({ channels, mergedChannels }), [channels, mergedChannels]);

  return <AudioPlayerWaveformContext.Provider value={contextValue}>{children}</AudioPlayerWaveformContext.Provider>;
};

export function useAudioPlayerWaveform(): AudioPlayerWaveformContextProps {
  const context = useContext(AudioPlayerWaveformContext);

  if (context == null) {
    throw new Error('useAudioPlayerWaveform must be used within a AudioPlayerWaveformProvider');
  }

  return context;
}
