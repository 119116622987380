import { LinkTab, PageWrapper, TabList, Tabs } from '@lemonade-hq/bluis';
import { useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { RatingMetadata } from './RatingMetadata';
import { RatingSchema } from './RatingSchema';
import { HeaderPortal } from 'components/Header';
import { EditionHeader } from 'components/LoCo/common/editions/EditionHeader';
import type { EditionActionData } from 'components/LoCo/common/editions/editionSharedActions';
import { EditionActionsDialogs } from 'components/LoCo/common/editions/editionSharedActions';
import { EditionSummarySection } from 'components/LoCo/common/editions/EditionSummarySection';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { useSuspenseGetRatingEdition } from 'queries/LoCo/Insurance/RatingEditionQueries';

export const RatingEdition: React.FC = () => {
    const product = useGetProductData();
    const [dialogData, setDialogData] = useState<EditionActionData | null>(null);
    const { ratingEditionCode = '' } = useParams<{ ratingEditionCode: string }>();

    const { data: editionData } = useSuspenseGetRatingEdition(ratingEditionCode);

    const ROUTES = {
        details: {
            path: 'details',
            label: 'Details',
            component: <EditionSummarySection edition={editionData} product={product} type={EditionType.Rating} />,
        },
        metadata: {
            path: 'metadata',
            label: 'Coverages and Settings',
            component: <RatingMetadata coverages={editionData.coverages} settings={editionData.settings} />,
        },
        schema: {
            path: 'schema',
            label: 'Schema',
            component: <RatingSchema schemaResponse={editionData.schema} />,
        },
    };

    return (
        <>
            <EditionHeader
                edition={editionData}
                product={product}
                setDialogData={setDialogData}
                type={EditionType.Rating}
            />

            <PageWrapper>
                <Tabs>
                    <HeaderPortal>
                        <TabList pageTabs>
                            {Object.values(ROUTES).map(({ label, path }) => (
                                <LinkTab key={label} to={path}>
                                    {label}
                                </LinkTab>
                            ))}
                        </TabList>
                    </HeaderPortal>
                    <Routes>
                        <Route element={<Navigate replace to="details" />} path="/" />

                        {Object.values(ROUTES).map(({ path, component }) => (
                            <Route element={component} key={path} path={path} />
                        ))}
                    </Routes>
                </Tabs>
                {dialogData != null && (
                    <EditionActionsDialogs
                        dialogData={dialogData}
                        onClose={() => setDialogData(null)}
                        productCode={product.code}
                    />
                )}
            </PageWrapper>
        </>
    );
};
