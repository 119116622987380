/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-array-index-key */

import { cssPaint as waveform } from '@lemonade-hq/houdini-kit/waveform';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { getVarName } from '@vanilla-extract/private';
import { clsx } from 'clsx';
import groupBy from 'lodash/groupBy';
import type { FC } from 'react';
import React, { Fragment, useCallback, useMemo, useRef } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Layout } from '../../base/Layout/Layout';
import { Text } from '../../base/Text/Text';
import { overflowXContainer } from '../../theme/overflow_indication.css';
import { shimmering } from '../../theme/shimmering.css';
import { spacing } from '../../theme/spacing.css';
import { flexDoNotShrink } from '../../theme/utils.css';
import { formatMediaTime } from '../../utils/media';
import { Avatar } from '../Avatar/Avatar';
import { Icon } from '../Icon/Icon';
import { Tooltip } from '../Tooltip/Tooltip';
import * as styles from './AudioPlayer.css';
import { useAudioPlayerEvents } from './AudioPlayer.EventsProvider';
import type { AudioPlayerPresentedEvent } from './AudioPlayer.EventsProvider.types';
import { useAudioPlayer } from './AudioPlayer.Provider';
import { useAudioPlayerSegments } from './AudioPlayer.SegmentsProvider';
import type { AudioPlayerPresentedSegment } from './AudioPlayer.SegmentsProvider.types';
import { AudioPlayerPresentedSegmentType } from './AudioPlayer.SegmentsProvider.types';
import { useAudioPlayerWaveform } from './AudioPlayer.WaveformProvider';
import { useAudioPlayerZoom } from './AudioPlayer.ZoomProvider';
import { useTimelineHeadScroll } from './useTimelineHeadScroll';

export interface AudioPlayerSegmentedTimelineProps {
  readonly durationSegments?: number;
  readonly showEventsDistances?: boolean;
  readonly hideAudioSegmentsDetails?: boolean;
}

export const AudioPlayerSegmentedTimeline: FC<AudioPlayerSegmentedTimelineProps> = ({
  durationSegments = 8,
  showEventsDistances,
  hideAudioSegmentsDetails,
}) => {
  const { currentTime, totalTime, isLoading, seek, togglePlayState } = useAudioPlayer();
  const { channels, mergedChannels } = useAudioPlayerWaveform();
  const { segments, segmentPresenter, currentSegmentIndex, goToSegmentIndex } = useAudioPlayerSegments();
  const { events, eventPresenter, totalEventsTime } = useAudioPlayerEvents();
  const { currentZoom } = useAudioPlayerZoom();
  const scrollerRef = useRef<HTMLDivElement | null>(null);
  const headRef = useRef<HTMLDivElement | null>(null);
  const seekHeadRef = useRef<HTMLDivElement | null>(null);
  const actualTotalTime = Math.max(totalTime, totalEventsTime);

  useTimelineHeadScroll({ scrollerRef, headRef, scrollThreshold: 50, manualScrollDuration: 4000 });

  const mappedSegments = useMemo(
    () =>
      segments.map((segment, index) => {
        const props = segmentPresenter?.(segment, index) ?? ({} as AudioPlayerPresentedSegment);
        return {
          ...props,
          index,
          startRatio: segment.startedAt / actualTotalTime,
          widthRatio: (segment.endedAt - segment.startedAt) / actualTotalTime,
        };
      }),
    [segmentPresenter, segments, actualTotalTime],
  );
  const separatedSegments = Object.values(groupBy(mappedSegments, 'group'));
  const mappedEvents = useMemo(
    () =>
      events
        .map((event, index) => {
          const props = eventPresenter?.(event, index) ?? ({} as AudioPlayerPresentedEvent);
          return {
            ...props,
            index,
            startRatio: event.timestamp / actualTotalTime,
          };
        })
        .filter(event => !event.hidden),
    [events, eventPresenter, actualTotalTime],
  );

  const currentlyPlayingRow = separatedSegments.findIndex(group =>
    group.some(segment => segment.index === currentSegmentIndex),
  );
  const finalDurationSegments = Math.floor(durationSegments * currentZoom);

  const handleSeekHeadHover = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (seekHeadRef.current) {
      seekHeadRef.current.style.left = `min(100%, max(0%, calc(${e.clientX}px - ${styles.timelinePadding} - ${e.currentTarget.getBoundingClientRect().left}px)))`;
    }
  }, []);

  const handleSeekHeadMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      if (seekHeadRef.current) {
        const padding = parseInt(
          window.getComputedStyle(seekHeadRef.current).getPropertyValue(getVarName(styles.timelinePadding)),
        );
        const left = parseInt(window.getComputedStyle(seekHeadRef.current).getPropertyValue('left'));
        const leftPercent = left / (e.currentTarget.clientWidth - padding * 2);
        seek(leftPercent * actualTotalTime);
      }
    },
    [seek, actualTotalTime],
  );

  const handleTogglePlayState = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>): void => {
      if (e.code === 'Space' && !isLoading) {
        togglePlayState();
      }
    },
    [isLoading, togglePlayState],
  );

  return (
    <Layout
      className={overflowXContainer({ paddingInline: 's00' })}
      onKeyDown={handleTogglePlayState}
      ref={scrollerRef}
      tabIndex={-1}
    >
      <Flex
        backgroundColor="$backgroundSecondary"
        className={clsx(styles.timeline, { [shimmering]: isLoading })}
        flexDirection="column"
        gap={spacing.s02}
        onClick={handleSeekHeadMove}
        onMouseMove={handleSeekHeadHover}
        width={`${currentZoom * 100}%`}
      >
        <Layout position="relative">
          <Layout alignItems="center" className={styles.timelineDurations} position="relative">
            {/* Durations */}
            {Array.from({ length: finalDurationSegments }).map((_, index) => (
              <div
                className={styles.timelineDurationSegment}
                key={index}
                style={assignInlineVars({
                  [styles.timelineDurationSegmentsCount]: finalDurationSegments.toString(),
                  [styles.timelineDurationSegmentIndex]: index.toString(),
                })}
              >
                <Text color="tertiary" type="text-sm">
                  ❘ {formatMediaTime((index * actualTotalTime) / finalDurationSegments)}
                </Text>
              </div>
            ))}
          </Layout>
          <>
            {/* Grid Lines */}
            {mappedEvents.length > 0 && !isLoading && (
              <div>
                {mappedEvents.map((event, index) => (
                  <div
                    className={styles.timelineGridLine}
                    key={index}
                    style={{
                      left: `${event.startRatio * 100}%`,
                    }}
                  />
                ))}
              </div>
            )}

            {/* Segments */}
            {separatedSegments.map((group, row) => {
              const isPlaying = currentlyPlayingRow === row;
              const isAudio = group[0].type === AudioPlayerPresentedSegmentType.Audio;
              const baseStyle = assignInlineVars({
                [styles.timelineRowBackgroundColor]: group[0].backgroundColor ?? 'transparent',
                [styles.timelineRowBaseColor]: group[0].color ?? 'transparent',
              });
              return (
                <Flex
                  alignItems="center"
                  className={styles.timelineRow}
                  data-playing={isPlaying}
                  height="3.6rem"
                  key={row}
                  position="relative"
                  style={
                    isAudio && !isLoading
                      ? {
                          ...baseStyle,
                          ...waveform(
                            {
                              values: separatedSegments.length < channels.length ? mergedChannels : channels[row],
                              color: styles.timelineRowBaseColor,
                            },
                            { afterBackground: styles.timelineRowBackgroundColor },
                          ),
                          backgroundSize: `${100 * (totalTime / actualTotalTime)}%`,
                          backgroundRepeat: 'no-repeat',
                        }
                      : baseStyle
                  }
                >
                  {group.map(segment => (
                    <Tooltip
                      content={
                        segment.content ??
                        (segment.metadata != null
                          ? JSON.stringify(segment.metadata, null, 2).replaceAll(/{|}/gi, '').trim()
                          : '')
                      }
                      key={segment.index}
                      maxWidth="45rem"
                    >
                      <Flex
                        alignItems="center"
                        className={styles.timelineSegment}
                        data-playing={currentSegmentIndex === segment.index}
                        data-type={segment.type}
                        onClick={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          goToSegmentIndex(segment.index);
                        }}
                        padding={spacing.s02}
                        role="button"
                        style={{
                          left: `${segment.startRatio * 100}%`,
                          width: `${segment.widthRatio * 100}%`,
                        }}
                      >
                        {(!isAudio || !hideAudioSegmentsDetails) && (
                          <Flex
                            alignItems="center"
                            className={styles.timelineSegmentDetails}
                            flexGrow={0}
                            flexShrink={0}
                            gap={spacing.s04}
                            padding={spacing.s04}
                          >
                            {segment.icon != null ? (
                              <Icon color="neutral7" name={segment.icon} size="sm" />
                            ) : (
                              <Avatar
                                alt={segment.title}
                                className={flexDoNotShrink}
                                name={segment.title}
                                size="xs"
                                src={segment.image}
                              />
                            )}
                            <Text className={styles.timelineSegmentDetailsText} type="text-sm">
                              {segment.title}
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    </Tooltip>
                  ))}
                </Flex>
              );
            })}

            {/* Events */}
            {mappedEvents.length > 0 && (
              <Flex alignItems="center" className={styles.timelineEventsRow} height="3.6rem" position="relative">
                {!isLoading &&
                  mappedEvents.map((event, index) => {
                    const duration = `${event.timestamp - (mappedEvents[index - 1]?.timestamp ?? 0)}ms`;
                    return (
                      <Fragment key={index}>
                        {showEventsDistances && (
                          <Tooltip content={duration}>
                            <div
                              className={styles.timelineEventDistance}
                              style={{
                                left: `calc(${(mappedEvents[index - 1]?.startRatio ?? 0) * 100}% + ${styles.timelineEventSize} / 2)`,
                                width: `calc(${event.startRatio * 100}% - ${(mappedEvents[index - 1]?.startRatio ?? 0) * 100}% - ${styles.timelineEventSize})`,
                              }}
                            >
                              <div className={styles.timelineEventDistanceDetails} data-duration={duration} />
                            </div>
                          </Tooltip>
                        )}
                        <Tooltip
                          content={
                            <>
                              <b>{event.title}</b>&nbsp;({event.timestamp}ms)
                              {event.description != null && <p>{event.description}</p>}
                            </>
                          }
                          maxWidth="35rem"
                        >
                          <Flex
                            alignItems="center"
                            className={styles.timelineEvent}
                            padding={spacing.s02}
                            style={{
                              left: `${event.startRatio * 100}%`,
                            }}
                          >
                            <Icon color={event.iconColor ?? 'neutral7'} name={event.icon ?? 'flag-solid'} size="sm" />
                          </Flex>
                        </Tooltip>
                      </Fragment>
                    );
                  })}
              </Flex>
            )}
          </>
          <div className={styles.timelineSeekHead} ref={seekHeadRef} />
          <div
            className={styles.timelineHead}
            ref={headRef}
            style={{ left: `${(currentTime / actualTotalTime) * 100}%` }}
          />
        </Layout>
      </Flex>
    </Layout>
  );
};
