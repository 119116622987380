import type { QuoteAddress } from './CarShared';
import type { CarUwReviewAvailable } from 'models/CarUwReview';

export enum PricingItemCode {
    InstallmentFees = 'installment-fees',
    MvcpaFees = 'mvcpa-fees',
    CvaFees = 'cva-fees',
    AtaaFees = 'ataa-fees',
    CatpaFees = 'catpa-fees',
}

export function isCarPricingItemCode(code: string): code is PricingItemCode {
    return Object.values(PricingItemCode).includes(code as PricingItemCode);
}

export enum Carrier {
    LemonadeInsuranceCompany = 'lemonade_insurance_company',
    HomeStateCountyMutual = 'home_state_county_mutual',
    MetromileInsuranceCompany = 'metromile_insurance_company',
}

export enum ActionType {
    ToggleImmediateActivationAllowed = 'toggle_immediate_activation_allowed',
    EditQuoteDriver = 'edit_driver',
    AssignQuote = 'assign_quote',
    ScheduleMetromileNonrenewal = 'schedule_nonrenewal',
    RevertMetromileScheduledNonrenewal = 'revert_scheduled_nonrenewal',
}

export type CarQuoteAction = { readonly action: ActionType; readonly enabled?: boolean };

export enum Status {
    Pending = 'pending',
    Bindable = 'bindable',
    InReview = 'in_review',
    UnderwritingDeclined = 'uw_declined',
    Paid = 'paid',
    Canceled = 'canceled',
    Discarded = 'discarded',
}

export enum QuoteSource {
    Estimate = 'estimate',
    MobileWeb = 'mobile_web',
    Web = 'web',
    App = 'app',
    Api = 'api',
}

export enum QuoteChannel {
    MobileWeb = 'mobile_web',
    Web = 'web',
    App = 'app',
    Api = 'api',
    Metromile = 'metromile',
    Estimate = 'estimate',
    EditCoveragesMobile = 'edit_coverages_mobile',
    EditCoveragesBlender = 'edit_coverages_blender',
    Endorsement = 'endorsement',
    UniclientWeb = 'uniclient_web',
    UniclientApp = 'uniclient_app',
    UniclientMobileWeb = 'uniclient_mobile_web',
}

export enum CarQuoteBadge {
    AwaitingRenewal = 'awaiting_renewal',
    PendingNonrenewal = 'pending_nonrenewal',
    Expired = 'expired',
}

export type CarQuoteBadgeType = CarQuoteBadge | Status;

export enum PaymentPlan {
    FullPayment = 'full_payment',
    Monthly = 'monthly',
}

export enum PaymentPeriod {
    Annual = 'annual',
    SemiAnnual = 'semi_annual',
}

export enum PricingItemType {
    Premium = 'premium',
    Fee = 'fee',
    Tax = 'tax',
}

export enum PricingItemChargeStrategy {
    Split = 'split',
    OneTime = 'one_time',
}

export interface CarPolicySummary {
    readonly public_id: string;
}

interface EntityUnderwritingFilters {
    readonly entityPublicId: string;
    readonly enforced: RatingUwFilterType[] | string[];
}
export interface UnderwritingFilters {
    readonly policy: RatingUwFilterType[];
    readonly drivers: EntityUnderwritingFilters[];
    readonly vehicles: EntityUnderwritingFilters[];
}

interface RatingCalculationItem {
    readonly count: number;
    readonly threshold: number;
}

type RatingOutputUwFiltersExplain = Record<RatingUwFilterType | string, RatingCalculationItem>;

interface RatingOutputUwFiltersEntityExplain {
    readonly entityPublicId?: string;
    readonly breakdown?: RatingOutputUwFiltersExplain;
}

export interface RatingCalculation {
    readonly uwFilters?: {
        readonly explain?: {
            readonly policy?: RatingOutputUwFiltersExplain;
            readonly drivers?: RatingOutputUwFiltersEntityExplain[];
        };
    };
}

export interface UnderwritingAdditionalFilters {
    readonly enforced: string[];
}

interface FeePricingItem {
    readonly type: PricingItemType.Fee;
    readonly code: PricingItemCode;
    readonly amount: number;
    readonly charge_strategy: PricingItemChargeStrategy;
}

export interface MvcpaPricingItem extends FeePricingItem {
    readonly code: PricingItemCode.MvcpaFees;
    readonly perVehicle: number;
}

export interface PricingItem {
    readonly type: PricingItemType;
    readonly code: PricingItemCode;
    readonly amount: number;
    readonly premium_code?: string;
    readonly charge_strategy?: PricingItemChargeStrategy;
}

interface TotalPricing {
    readonly price: number;
    readonly premium: number;
}

export enum BillingType {
    Fixed = 'fixed',
    PayPerMile = 'pay_per_mile',
}

export enum CarVendor {
    HLDI = 'HLDI',
    KBB = 'KBB',
}

export interface QuoteRiskAssessment {
    readonly fullPayment?: boolean;
}

export enum EditionOptionsType {
    Basic = 'basic',
    Standard = 'standard',
}

export interface CarQuote {
    readonly publicId: string;
    readonly status: Status;
    readonly sessionId: Status;
    readonly source: QuoteSource;
    readonly badge: CarQuoteBadgeType;
    readonly editionPublicId: string;
    readonly paymentPlan: PaymentPlan;
    readonly paymentPeriod: PaymentPeriod;
    readonly premium?: number;
    readonly estimatedMonthlyPremium?: number;
    readonly estimatedMonthlyPremiumForRenewal?: number;
    readonly monthlyPremium?: number;
    readonly createdAt: string;
    readonly test: boolean;
    readonly discounts?: Discounts;
    readonly uwFilters: UnderwritingFilters;
    readonly additionalUwFilters: UnderwritingAdditionalFilters;
    readonly pricingItems?: (FeePricingItem | MvcpaPricingItem | PricingItem)[];
    readonly ratingCalculation: RatingCalculation;
    readonly immediateActivationAllowed: boolean;
    readonly effectiveAt: string;
    readonly minimumEffectiveAt?: string;
    readonly minimumEffectiveAtReasons?: string[];
    readonly totalPricing?: TotalPricing;
    readonly billingType: BillingType;
    readonly uwReviewAvailable: CarUwReviewAvailable;
    readonly uwDeclinedReasons: UwDeclinedReasonsDTO;
    readonly previousPolicyPublicId?: string;
    readonly previousMigratedPolicyPublicId?: string;
    readonly isMetromileMigration: boolean;
    readonly carrier: string;
    readonly shippingAddress?: QuoteAddress;
    readonly riskAssessment?: QuoteRiskAssessment;
    readonly userJoined?: string;
    readonly trialData?: CarTrialData;
    readonly editionOptionsType: EditionOptionsType;
    readonly primaryPipInsurer?: HealthInsurer;
    readonly hasGoodDriverOnPolicy?: boolean;
    readonly thirdPartyTelematics?: { readonly score: number };
    readonly channel?: QuoteChannel;
}

export interface HealthInsurer {
    readonly carrierName: string;
    readonly userIdentifier: string;
}
export interface CarTrialData {
    readonly trialType: string;
    readonly trialStatus: string;
}

export interface CarQuoteChatLog {
    readonly [key: string]: unknown;
}

export interface DiscountBreakdown {
    readonly [key: string]: {
        readonly percent: string;
        readonly total: string;
    };
}

export interface Discounts {
    readonly breakdown?: DiscountBreakdown;
    readonly total: string;
}

export interface UwDeclinedReasonsDTO {
    readonly policy: string[];
    readonly drivers: EnrichedDriverUwDeclineMessagesDTO[];
    readonly vehicles: EnrichedVehicleUwDeclineMessagesDTO[];
}

interface EnrichedDriverUwDeclineMessagesDTO {
    readonly publicId: string;
    readonly messages: string[];
    readonly firstName: string;
    readonly lastName: string;
}

interface EnrichedVehicleUwDeclineMessagesDTO {
    readonly publicId: string;
    readonly messages: string[];
    readonly vin: string;
    readonly year: number;
    readonly make: string;
    readonly model: string;
}

export enum RatingUwFilterType {
    // Policy level
    PniLearnersPermit = 'pniLearnersPermit',
    PniUnder18 = 'pniUnder18',
    PniNoCurrentInsurance = 'pniNoCurrentInsurance',
    StolenCar = 'stolenCar',
    HouseholdMajorViolation = 'householdMajorVio',
    NewCustomerHouseholdAfs = 'newCustomerHouseholdAFs',
    LemonadeCustomerHouseholdAfs = 'lemonadeCustomerHouseholdAFs',
    NewCustomerHouseholdNafs = 'newCustomerHouseholdNAFs',
    LemonadeCustomerHouseholdNafs = 'lemonadeCustomerHouseholdNAFs',
    NewCustomerHouseholdMinorViolation = 'newCustomerHouseholdMinorVio',
    LemonadeCustomerHouseholdMinorViolation = 'lemonadeCustomerHouseholdMinorVio',
    NewCustomerHouseholdComp = 'newCustomerHouseholdComp',
    LemonadeCustomerHouseholdComp = 'lemonadeCustomerHouseholdComp',
    NewCustomerHouseholdGlass = 'newCustomerHouseholdGlass',
    LemonadeCustomerHouseholdGlass = 'lemonadeCustomerHouseholdGlass',
    NewCustomerHouseholdIncidents = 'newCustomerHouseholdIncidents',
    LemonadeCustomerHouseholdIncidents = 'lemonadeCustomerHouseholdIncidents',
    NewCustomerHouseholdPIPOver50000 = 'newCustomerHouseholdPIPOver50000',
    NewCustomerHouseholdPIPUnder50000 = 'newCustomerHouseholdPIPUnder50000',
    UnacceptableArea = 'unacceptableArea',
    PreviouslyNonRenewed = 'previouslyNonRenewed',
    PreviouslyCanceled = 'previouslyCanceled',
    PreviouslyDeclined = 'previouslyDeclined',

    // Driver level
    NoLicense = 'noLicense',
    DriverAfs = 'driverAFs',
    DriverPermissiveUse = 'driverPermissiveUse',
    DriverMajorViolation = 'driverMajorVio',
    DriverMinorViolation = 'driverMinorVio',
    NewCustomerDriverNafs = 'newCustomerDriverNAFs',
    LemonadeCustomerDriverNafs = 'lemonadeCustomerDriverNAFs',
    NewCustomerDriverIncidents = 'newCustomerDriverIncidents',
    LemonadeCustomerDriverIncidents = 'lemonadeCustomerDriverIncidents',

    // Vehicle level
    BusinessRideshare = 'businessRideshare',
    LuxuryCarPrice = 'luxuryCarPrice',
    LuxuryCarMake = 'luxuryCarMake',
    SalvageOrBrandedTitle = 'salvageOrBrandedTitle',
    PreviousSevereDamage = 'previousSevereDamage',
    GaragingAddress = 'garagingAddress',
    RegistrationStateOwner = 'registrationStateOwner',
    CarAge = 'carAge',
    ModifiedCar = 'modifiedCar',
    HighPerformance = 'highPerformance',
    NewlyAcquiredAutoGrossVehicleWeight = 'newlyAcquiredAutoGrossVehicleWeight',
    NewlyAcquiredAutoNotPermittedUse = 'newlyAcquiredAutoNotPermittedUse',
    PayloadCapacity = 'payloadCapacity',

    // Additional filters
    BlockedUser = 'blockedUser',
}
