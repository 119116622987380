import { Flex, Layout, spacing } from '@lemonade-hq/blender-ui';
import type { ImageProps } from '@lemonade-hq/bluis';
import { ArrowIcon, Image, InfoOutlinedIcon, SecondaryButton } from '@lemonade-hq/bluis';
import { font, themedColor, Tooltip } from '@lemonade-hq/boutique';
import { Box, useClickOutside } from '@lemonade-hq/cdk';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { StatefulTooltip } from '../Tooltip';

const Wrapper = styled.div`
    position: relative;
`;

const MainButton = styled(SecondaryButton)<{ readonly disabled: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
        `}
`;

const WarningIcon = styled(Image)<ImageProps>`
    margin-right: 8px;
    margin-bottom: 2px;
`;

const ActionsList = styled.ul<{ readonly open: boolean }>`
    display: flex;
    flex-direction: column;
    position: absolute;
    list-style: none;
    transition:
        transform 0.2s ease-in-out,
        opacity 0.2s ease-in-out;
    background: ${themedColor('componentBackground')};
    border: 1px solid ${themedColor('separator')};
    min-width: max-content;
    width: 146px;
    border-radius: 5px;
    opacity: 0;
    right: 0;
    z-index: 1;

    transform: scale(0);
    transform-origin: top right;

    ${({ open }) => {
        if (open) {
            return css`
                opacity: 1;
                padding: 6px;
                transform: scale(1) translateY(5px);
            `;
        }

        return '';
    }};
`;

const InfoTooltip = styled(Tooltip)`
    &:before,
    &:after {
        bottom: calc(100% + 4px) !important;
    }
`;

export interface Action {
    readonly onClick: (entityId?: string) => void;
    readonly text: string;
    readonly name: string;
    readonly disabled?: boolean;
    readonly warning?: boolean;
    readonly hide?: boolean;
    readonly info?: string;
}

interface ActionsMenuProps {
    readonly text?: string;
    readonly entityId?: string;
    readonly actions: Action[];
    readonly disabled?: boolean;
    readonly warning?: string;
    readonly keepTextTransform?: boolean;
    readonly arrow?: {
        readonly width: number;
        readonly height: number;
    };
    readonly onOpen?: () => void;
}

interface ActionProps {
    readonly action: Action;
    readonly close: () => void;
    readonly entityId?: string;
    readonly keepTextTransform?: boolean;
}

const ActionItem = styled.li`
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
`;

const ActionButton = styled.button<{
    readonly disabled?: boolean;
    readonly warning?: boolean;
    readonly keepTextTransform?: boolean;
}>`
    background-color: ${themedColor('componentBackground')};
    color: ${themedColor('primaryText')};
    padding-left: 15px;
    width: 100%;

    border: 0;
    outline: none;
    ${font('main', { fontSize: '14px', lineHeight: '30px' })}
    text-align: left;
    text-transform: ${({ keepTextTransform }) => (keepTextTransform ? 'none' : 'capitalize')};

    &:hover:not(:disabled) {
        background-color: ${themedColor('elementBackground')};
    }

    &:disabled {
        opacity: 0.6;
    }

    ${({ disabled, warning }) => {
        if (warning && !disabled) {
            return css`
                color: ${themedColor('error')};
            `;
        }

        return '';
    }}
`;

const Action: React.FC<React.PropsWithChildren<ActionProps>> = ({ action, close, entityId, keepTextTransform }) => {
    const { onClick, text, name, disabled, warning, hide, info } = action;

    if (hide) {
        return null;
    }

    function onActionClick() {
        onClick(entityId);
        close();
    }

    return (
        <ActionItem>
            <ActionButton
                disabled={disabled}
                keepTextTransform={keepTextTransform}
                name={name}
                onClick={onActionClick}
                type="button"
                warning={warning}
            >
                {text}
            </ActionButton>
            {info != null && (
                <InfoTooltip alignment="top-left" content={info}>
                    <Box height={16} pr="6px">
                        <InfoOutlinedIcon />
                    </Box>
                </InfoTooltip>
            )}
        </ActionItem>
    );
};

/**
 * @deprecated
 * use ActionsMenu from '@lemonade-hq/bluis' instead
 */
const ActionsMenu: React.FC<React.PropsWithChildren<ActionsMenuProps>> = ({
    text,
    actions,
    entityId,
    disabled = false,
    warning,
    keepTextTransform = false,
    arrow,
    onOpen,
    ...styles
}) => {
    const [open, setOpen] = useState(false);
    const menuRef = useRef(null);

    useClickOutside(menuRef, open, close);

    function close() {
        setOpen(false);
    }

    function toggle() {
        if (!open) {
            onOpen?.();
        }

        setOpen(prevOpen => !prevOpen);
    }

    return (
        <Wrapper ref={menuRef}>
            <MainButton disabled={disabled} onClick={toggle} {...styles}>
                <Flex alignItems="center">
                    {warning != null && warning !== '' && (
                        <StatefulTooltip
                            parent={
                                <WarningIcon
                                    img={{
                                        lightSrc: 'actions_dropdown_warning.svg',
                                        darkSrc: 'actions_dropdown_warning_dark.svg',
                                    }}
                                />
                            }
                            position="bottom"
                            style={{ width: '200px' }}
                        >
                            {warning}
                        </StatefulTooltip>
                    )}
                    {text}
                    <Layout ml={spacing.s06}>
                        <ArrowIcon height={12} width={12} />
                    </Layout>
                </Flex>
            </MainButton>
            <ActionsList {...{ open }}>
                {actions.map(action => (
                    <Action key={action.name} {...{ action, close, entityId }} keepTextTransform={keepTextTransform} />
                ))}
            </ActionsList>
        </Wrapper>
    );
};

export default ActionsMenu;
