import {
    Button,
    Card,
    Flex,
    Input,
    Layout,
    pageWrapper,
    spacing,
    TabButtons,
    Text,
    useCheckableTable,
} from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent } from '@lemonade-hq/boutique';
import { clsx } from 'clsx';
import { useMemo, useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import { debounce } from 'throttle-debounce';
import { AttachmentGallery } from '../AttachmentGallery';
import type { AttachmentFilter } from '../AttachmentsQueries';
import {
    getAttachmentTypes,
    useAddAttachments,
    useGetAttachments,
    useGetAttachmentsFilters,
} from '../AttachmentsQueries';
import { AttachmentsProvider, useAttachmentsData } from '../context';
import type { AttachmentActionType, AttachmentDTO, AttachmentStats } from '../types';
import { AttachmentActions } from './AttachmentActions';
import { addFileButton, animateFilters, filtersButton, searchInput } from './Attachments.css';
import { BulkActionButtons } from './BulkActionButtons';
import type { HubFilters, Reviewed } from './Filters';
import { Filters, getInitialFilters, resetComboBoxFilters } from './Filters';
import { HubPagination } from './HubPagination';
import { Results } from './Stats';
import { ActiveAttachmentsTable } from './Tables/ActiveAttachmentsTable';
import { ArchivedAttachmentsTable } from './Tables/ArchivedAttachmentsTable';
import { UnreviewedAttachmentsTable } from './Tables/UnreviewedAttachmentsTable';
import { uploadFile } from 'apis/FilesAPI';
import AddAttachments from 'components/Bluis/ClaimAttachments/AddAttachments';
import type { AttachmentToUpload } from 'models/Attachment';

const PAGE_SIZE = 30;

type Tab = 'all' | 'archived' | 'unreviewed';

const getActiveTab = ({
    filters,
    hasUnreviewedAttachments,
}: {
    readonly filters: HubFilters;
    readonly hasUnreviewedAttachments: boolean;
    readonly showUnreviewedData?: boolean;
}): Tab => {
    if (filters.reviewed === 'false' && hasUnreviewedAttachments) {
        return 'unreviewed';
    } else if (filters.status === 'archived') {
        return 'archived';
    } else {
        return 'all';
    }
};

const getTabIndex = (tab: Tab, showUnreviewedData?: boolean): number => {
    if (showUnreviewedData && tab === 'unreviewed') {
        return 0;
    } else if (tab === 'archived') {
        return showUnreviewedData ? 2 : 1;
    } else {
        return showUnreviewedData ? 1 : 0;
    }
};

interface AttachmentHubProps {
    readonly entityType: EntityTypes;
    readonly entityId: string;
    readonly className?: string;
    readonly pageSize?: number;
    readonly shard?: string;
    readonly showUnreviewedData?: boolean;
}

const HubTabs: FC<{
    readonly hasUnreviewedAttachments: boolean;
    readonly showUnreviewedData?: boolean;
    readonly onClickTab: (tab: Tab, newFilters: Partial<HubFilters>) => void;
    readonly selected: Tab;
}> = ({ hasUnreviewedAttachments, onClickTab, showUnreviewedData, selected }) => {
    const tabProps = {
        buttons: [
            ...(showUnreviewedData === true
                ? [
                      {
                          label: 'Unreviewed',
                          onClick: () => onClickTab('unreviewed', { status: 'active', reviewed: 'false' as Reviewed }),
                          value: 'unreviewed',
                          disabled: !hasUnreviewedAttachments,
                          tooltip: {
                              content: (
                                  <Text as="p" color="light" textAlign="center">
                                      Docs that are missing type or description, or are suggested archive
                                  </Text>
                              ),
                          },
                      },
                  ]
                : []),
            {
                label: 'All',
                onClick: () =>
                    onClickTab('all', {
                        status: 'active',
                        reviewed: undefined,
                    }),
                value: 'all',
            },
            {
                label: 'Archived',
                onClick: () =>
                    onClickTab('archived', {
                        status: 'archived',
                        reviewed: undefined,
                    }),
                value: 'archived',
            },
        ],
        startAtTabIndex: getTabIndex(selected, showUnreviewedData),
    };

    return <TabButtons {...tabProps} />;
};

const AttachmentHubTabs: FC<{
    readonly className?: string;
    readonly hasUnreviewedAttachments: boolean;
    readonly handleFilterClick: () => void;
    readonly filters: HubFilters;
    readonly updateFilters: Dispatch<SetStateAction<HubFilters>>;
    readonly showFilters: boolean;
    readonly setShowFilters: Dispatch<SetStateAction<boolean>>;
    readonly data: { readonly stats: AttachmentStats; readonly data: AttachmentDTO[] };
    readonly isLoading: boolean;
    readonly shard: string;
    readonly showUnreviewedData?: boolean;
    readonly filterData: {
        readonly data: AttachmentFilter[];
        readonly isLoading: boolean;
        readonly isError: boolean;
    };
}> = ({
    className,
    hasUnreviewedAttachments,
    handleFilterClick,
    filters,
    updateFilters,
    showFilters,
    setShowFilters,
    data,
    isLoading,
    shard,
    showUnreviewedData,
    filterData,
}) => {
    const { entityPublicId, entityType } = useAttachmentsData();
    const [actionProps, setActionProps] = useState<
        { action: AttachmentActionType; attachments: AttachmentDTO[]; source: 'button' | 'single_row_menu' } | undefined
    >();
    const { selectedRows, handleToggleRow, handleToggleAll } = useCheckableTable([]);
    const [tab, setTab] = useState(getActiveTab({ filters, hasUnreviewedAttachments }));
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [{ selectedIndex, showGallery }, setShowGallery] = useState({ selectedIndex: 0, showGallery: false });
    const [searchValue, setSearchValue] = useState(filters.searchTerm ?? '');

    const { mutateAsync: addAttachments } = useAddAttachments({ entityPublicId, entityType });

    const sortableSet = {
        sort: () => {
            updateFilters(prev => ({ ...prev, sortDirection: filters.sortDirection === 'ASC' ? 'DESC' : 'ASC' }));
        },
        sortingBy: 'uploadDate',
        sortDirection: filters.sortDirection.toLocaleLowerCase() as 'asc' | 'desc',
    };

    const initialFilters = useMemo(
        () => getInitialFilters(hasUnreviewedAttachments, filterData.data),
        [filterData.data, hasUnreviewedAttachments]
    );

    const openAddDialog = (): void => {
        trackEvent('docs.clicked', {
            entity_id: entityPublicId,
            entity_type: entityType,
            action: 'click',
            action_type: 'add_attachment',
        });
        setShowAddDialog(true);
    };

    const handleAddAttachments = async (_: string, attachments: AttachmentToUpload[]) => {
        const attachmentsList = attachments.map(doc => ({
            filePublicId: doc.filePublicId ?? '',
            type: doc.type,
            description: doc.description,
        }));

        await addAttachments(attachmentsList);
    };

    const debouncedSearch = debounce(500, false, (searchTerm: string) => {
        updateFilters(prev => ({ ...prev, searchTerm }));
    });

    const handleSearch = (searchTerm: string): void => {
        setSearchValue(searchTerm);
        debouncedSearch(searchTerm);
    };

    const onClickTab = (tab: Tab, newFilters: Partial<HubFilters>): void => {
        trackEvent('docs.clicked', {
            entity_id: entityPublicId,
            entity_type: entityType,
            action: 'tab',
            action_type: 'tab',
            tab: tab,
        });
        // go to tab
        setTab(tab);
        // remove checked rows
        handleToggleAll([]);
        // update filters to initial filters + the selected tab
        updateFilters(prev => ({
            ...prev,
            ...initialFilters,
            searchTerm: filters.searchTerm,
            ...newFilters,
        }));
        // close filters
        setShowFilters(false);
    };

    const actionOnClose = (): void => {
        setActionProps(undefined);
        handleToggleAll([]);
    };

    const handleGallery = (index?: number): void => {
        setShowGallery({ selectedIndex: index ?? 0, showGallery: true });
    };

    const trackSearchClick = (): void => {
        trackEvent('docs.clicked', {
            entity_id: entityPublicId,
            entity_type: entityType,
            action: 'click',
            action_type: 'search',
        });
    };

    return (
        <>
            <Card className={clsx(pageWrapper, className)}>
                <Card padding={0}>
                    <Flex alignItems="center" justifyContent="space-between" p={spacing.s12}>
                        <Flex gap={spacing.s08}>
                            <HubTabs
                                hasUnreviewedAttachments={hasUnreviewedAttachments}
                                onClickTab={onClickTab}
                                selected={tab}
                                showUnreviewedData={showUnreviewedData}
                            />
                            <Results stats={data.stats} />
                        </Flex>

                        <Flex gap={spacing.s08}>
                            <Input
                                className={searchInput}
                                icon="search-solid"
                                onChange={e => handleSearch(e.target.value)}
                                onClear={() => handleSearch('')}
                                onFocus={trackSearchClick}
                                placeholder="Search..."
                                value={searchValue}
                            />
                            <Button
                                className={filtersButton({ active: showFilters })}
                                label={showFilters ? 'Clear' : 'Filters'}
                                onClick={handleFilterClick}
                                startIcon="filter-lines"
                                variant="secondary"
                            />
                            <Button
                                className={addFileButton}
                                label="Add"
                                onClick={openAddDialog}
                                startIcon="plus-solid"
                                variant="primary"
                            />
                        </Flex>
                    </Flex>

                    <Flex
                        alignItems="center"
                        className={animateFilters({ show: showFilters })}
                        justifyContent="space-between"
                    >
                        {showFilters && <Filters filters={filters} updateFilters={updateFilters} />}
                    </Flex>

                    <Layout>
                        {tab === 'unreviewed' && showUnreviewedData === true && (
                            <Layout>
                                <UnreviewedAttachmentsTable
                                    attachments={data.data}
                                    handleToggleAll={handleToggleAll}
                                    handleToggleRow={handleToggleRow}
                                    isLoading={isLoading}
                                    selectedRows={selectedRows}
                                    setActionProps={setActionProps}
                                    showGallery={handleGallery}
                                    sortableSet={sortableSet}
                                />
                            </Layout>
                        )}
                        {tab === 'all' && (
                            <Layout>
                                <ActiveAttachmentsTable
                                    attachments={data.data}
                                    handleToggleAll={handleToggleAll}
                                    handleToggleRow={handleToggleRow}
                                    isLoading={isLoading}
                                    selectedRows={selectedRows}
                                    setActionProps={setActionProps}
                                    showGallery={handleGallery}
                                    sortableSet={sortableSet}
                                />
                            </Layout>
                        )}
                        {tab === 'archived' && (
                            <Layout>
                                <ArchivedAttachmentsTable
                                    attachments={data.data}
                                    handleToggleAll={handleToggleAll}
                                    handleToggleRow={handleToggleRow}
                                    isLoading={isLoading}
                                    selectedRows={selectedRows}
                                    setActionProps={setActionProps}
                                    showGallery={handleGallery}
                                />
                            </Layout>
                        )}
                    </Layout>
                    <Flex alignItems="center" justifyContent="space-between" p={spacing.s12}>
                        <Layout>
                            <HubPagination stats={data.stats} updateFilters={updateFilters} />
                        </Layout>
                        <BulkActionButtons
                            attachments={data.data}
                            isArchivedTab={filters.status === 'archived'}
                            selectedRows={selectedRows}
                            setActionProps={setActionProps}
                        />
                    </Flex>
                </Card>
            </Card>

            {actionProps != null && <AttachmentActions {...actionProps} onClose={actionOnClose} />}
            {showAddDialog && (
                <AddAttachments
                    addAttachments={handleAddAttachments}
                    addAttachmentsLoading={false}
                    checkForDuplicates
                    close={() => setShowAddDialog(false)}
                    entityId={entityPublicId}
                    getAttachmentTypes={async () => await getAttachmentTypes(entityType)}
                    uploadFile={async file => await uploadFile(file, shard)}
                />
            )}
            {showGallery && (
                <AttachmentGallery
                    attachments={data.data}
                    entityPublicId={entityPublicId}
                    entityType={entityType}
                    filters={filters}
                    index={selectedIndex}
                    onClose={() => setShowGallery({ selectedIndex: 0, showGallery: false })}
                    stats={data.stats}
                />
            )}
        </>
    );
};

const AttachmentHubContent: FC<
    AttachmentHubProps & {
        readonly hasUnreviewedAttachments: boolean;
        readonly filterData: {
            readonly data: AttachmentFilter[];
            readonly isLoading: boolean;
            readonly isError: boolean;
        };
    }
> = ({ className, hasUnreviewedAttachments, pageSize = PAGE_SIZE, shard, filterData, showUnreviewedData }) => {
    const { entityPublicId, entityType } = useAttachmentsData();
    const [showFilters, setShowFilters] = useState(false);
    const [filters, updateFilters] = useState<HubFilters>(getInitialFilters(hasUnreviewedAttachments, filterData.data));
    const { data, isPending } = useGetAttachments({
        entityType,
        entityPublicId,
        params: filters,
        pageSize,
    });

    const handleFilterClick = (): void => {
        trackEvent('docs.clicked', {
            entity_id: entityPublicId,
            entity_type: entityType,
            action_type: 'filter',
            action: showFilters ? 'collapse' : 'expand',
        });

        // reset filters when closing
        if (showFilters) {
            updateFilters(resetComboBoxFilters(filters, filterData.data));
        }

        setShowFilters(!showFilters);
    };

    return (
        <AttachmentHubTabs
            className={className}
            data={data ?? { data: [], stats: { totalPages: 0, currentPage: 0, totalRecords: 0, pageSize: 0 } }}
            filterData={filterData}
            filters={filters}
            handleFilterClick={handleFilterClick}
            hasUnreviewedAttachments={hasUnreviewedAttachments}
            isLoading={isPending}
            setShowFilters={setShowFilters}
            shard={shard ?? `${entityType}/attachments`}
            showFilters={showFilters}
            showUnreviewedData={showUnreviewedData}
            updateFilters={updateFilters}
        />
    );
};

export const AttachmentHub: FC<AttachmentHubProps> = ({
    entityId,
    entityType,
    className,
    pageSize = PAGE_SIZE,
    shard = `${entityType}/attachments`,
    showUnreviewedData = true,
}) => {
    // get filters
    const { data, isError, isLoading } = useGetAttachmentsFilters({ entityType, entityPublicId: entityId });
    // check if there are unreviewed attachments
    const { data: unreviewedAttachments, isPending: isLoadingUnreviewedAttachments } = useGetAttachments({
        entityType,
        entityPublicId: entityId,
        params: { reviewed: 'false' as Reviewed },
        pageSize,
        enabled: showUnreviewedData,
    });

    const hasUnreviewedAttachments = useMemo(
        () => unreviewedAttachments?.data != null && unreviewedAttachments.data.length > 0 && showUnreviewedData,
        [unreviewedAttachments, showUnreviewedData]
    );

    if ((showUnreviewedData && isLoadingUnreviewedAttachments) || data == null) return null;

    return (
        <AttachmentsProvider entityPublicId={entityId} entityType={entityType}>
            <Layout py={20}>
                <AttachmentHubContent
                    className={className}
                    entityId={entityId}
                    entityType={entityType}
                    filterData={{ data, isLoading, isError }}
                    hasUnreviewedAttachments={hasUnreviewedAttachments}
                    pageSize={pageSize}
                    shard={shard}
                    showUnreviewedData={showUnreviewedData}
                />
            </Layout>
        </AttachmentsProvider>
    );
};
