/* eslint-disable @typescript-eslint/naming-convention */
import type { Tool, ToolSchemaValues, ToolsRevision } from '@lemonade-hq/persisted-tools';
import {
    CustomerCardSectionName,
    ToolChangeStatus,
    ToolInstructionType,
    ToolMode,
    ToolParamName,
    ToolProduct,
    ToolRegion,
    ToolsRevisionStatus,
    ToolSubclassifierActionType,
    ToolSubclassifierType,
    ToolType,
    ToolUserGroup,
} from '@lemonade-hq/persisted-tools';
import { randomUuid } from '@lemonade-hq/ts-helpers';
import { macrosList } from 'components/Llmnd/shared/fixtures/macros.fixtures';
import type { CustomerCardData } from 'components/Llmnd/shared/types';

export function generateMockEmptyTool(): Tool {
    return {
        publicId: randomUuid(),
        name: randomUuid(),
        parentToolPublicId: 'test',
        region: ToolRegion.US,
        mode: ToolMode.Draft,
        description: '',
        questions: [],
        subClassifiers: [],
        instructions: [],
        type: ToolType.GeneralKnowledge,
        createdBy: '',
        userGroups: [],
        changeStatus: ToolChangeStatus.Cloned,
    };
}

export const MOCK_VALID_TOOL: Tool = {
    publicId: 'TOOL-1',
    name: 'tool_1',
    parentToolPublicId: 'test',
    region: ToolRegion.US,
    mode: ToolMode.InProgress,
    description: 'This is a tool description.',
    userGroups: [ToolUserGroup.Unrecognized, ToolUserGroup.Inactive],
    products: [ToolProduct.Homeowners, ToolProduct.Renters],
    lastEditedAt: '2024-08-07T00:00:00.000Z',
    macros: ['Homeowners::Bugs::Dunning Email Bug'],
    topic: 'Cancellation',
    subtopic: 'Immediate Cancellation',
    ticketsIdsExamples: ['123456', '654321'],
    customerCardSections: [
        CustomerCardSectionName.ActivePolicies,
        CustomerCardSectionName.CustomerInfo,
        CustomerCardSectionName.UpcomingPolicies,
    ],
    questions: [
        { content: 'Can I pay another way?' },
        { content: 'Can I pay with my checking account while I wait for a new card?' },
        {
            content:
                "What should I do if I can't make my payment on time because I'm waiting for a new debit or credit card?",
        },
        {
            content:
                "How can I update my payment method if my old card is no longer active and I'm waiting for a new one?",
        },
        { content: "Is there a grace period for payments if I'm waiting for a new card to arrive?" },
        {
            content: 'Can I still make a payment if my scheduled due date arrives before my new debit or credit card?',
        },
        { content: 'What options do I have for making a payment if my card is lost or being replaced?' },
        { content: 'How do I notify you that my payment will be late due to waiting for a new bank card?' },
        { content: "Are there any fees for late payments if my delay is because I'm waiting for a new card?" },
        { content: 'Can I extend my payment due date while I wait for my new debit or credit card?' },
        {
            content: 'What information do you need from me if I need to delay my payment until my new card arrives?',
        },
        { content: "How long can my payment be deferred if my replacement card hasn't arrived yet?" },
        { content: 'Will waiting for a new card to make a payment affect my account status?' },
        { content: "What's the process for updating my payment details once my new card arrives?" },
        {
            content:
                "Do you offer any alternative payment methods if I can't use my debit or credit card on the due date?",
        },
        { content: "What proof do I need to show that I'm waiting for a new card to avoid late fees?" },
        {
            content: "Are there any special arrangements for customers who can't pay on time due to card replacement?",
        },
        {
            content:
                "How do I avoid service interruption if I can't pay by the due date because my card is being replaced?",
        },
        { content: 'Is there a way to temporarily change my payment method while I wait for my new card?' },
    ],
    subClassifiers: [
        {
            type: ToolSubclassifierType.Standard,
            ifContent: 'the customer is asking to update the card on file',
            thenAction: { type: ToolSubclassifierActionType.Reroute, toolName: 'public_1' },
        },
        {
            type: ToolSubclassifierType.Standard,
            ifContent: 'the customer is asking to change their payment plan',
            thenAction: { type: ToolSubclassifierActionType.Reroute, toolName: 'public_1' },
        },
        {
            type: ToolSubclassifierType.Standard,
            ifContent: "CUSTOMER ACCOUNT OVERVIEW contains 'policy_status: cancelled'",
            thenAction: { type: ToolSubclassifierActionType.Escalate, reason: 'dunning_cancellation' },
        },
        {
            type: ToolSubclassifierType.Standard,
            ifContent: 'the customer is asking who their lender is',
            thenAction: { type: ToolSubclassifierActionType.Escalate, reason: 'seeking_lender' },
        },
        {
            type: ToolSubclassifierType.Standard,
            ifContent: 'someone is asking about our phone number',
            thenAction: { type: ToolSubclassifierActionType.Reroute, toolName: 'shell_1' },
        },
        {
            type: ToolSubclassifierType.Standard,
            ifContent: 'the customer is talking about a device being plugged in',
            thenAction: { type: ToolSubclassifierActionType.Escalate, reason: 'telematics' },
        },
        {
            type: ToolSubclassifierType.Standard,
            ifContent: 'the customer has problems with the app, or their cell phone',
            thenAction: { type: ToolSubclassifierActionType.Escalate, reason: 'not_payment_related' },
        },
        {
            type: ToolSubclassifierType.Standard,
            ifContent: "they say their card isn't being accepted or they have a technical issue updating the card",
            thenAction: { type: ToolSubclassifierActionType.Reroute, toolName: 'shell_1' },
        },
        {
            type: ToolSubclassifierType.Standard,
            ifContent: 'the customer should be rerouted to tool_3',
            thenAction: { type: ToolSubclassifierActionType.Reroute, toolName: 'tool_3' },
        },
    ],
    instructions: [
        {
            type: ToolInstructionType.Condition,
            ifContent: 'The customer references fraud is causing them to wait for a new card',
            thenContent: 'apologize to the customer for that unfortunate situation',
        },
        {
            type: ToolInstructionType.Rule,
            content: 'Thank the customer for letting us know they are waiting for a new card.',
        },
        {
            type: ToolInstructionType.Rule,
            content:
                'Tell them they can update their payment method in the Lemonade app or at Lemonade.com when it arrives.',
        },
        {
            type: ToolInstructionType.Rule,
            content: 'Follow the below conditions and use each of them only when fully met:',
        },
        {
            type: ToolInstructionType.Condition,
            ifContent: "CUSTOMER ACCOUNT OVERVIEW contains 'policy_is_in_dunning: true'",
            thenContent:
                "Let them know that they may continue to receive automated reminders from us regarding their past due payment in the coming days, but as long as they're able to update their payment method and make their payment before the grace period mentioned in the reminder email, we will be able to keep your policy active.",
        },
        {
            type: ToolInstructionType.Condition,
            ifContent: "CUSTOMER ACCOUNT OVERVIEW contains 'policy_is_in_dunning: false'",
            thenContent:
                "let them know that if the card doesn't arrive before their payment due date and the payment is missed, they will receive an automated reminder email from us, but as long as they can update their payment method and make their payment before the 60 day grace period from the time the payment was originally due is up, we'll be able to keep their policy active.",
        },
    ],
    type: ToolType.DIY,
    createdBy: 'someone',
    changeStatus: ToolChangeStatus.Cloned,
    notes: 'This is a random text\nShowing this to everyone!',
} as Tool;

export const MOCK_TOOL_REVISION: ToolsRevision = {
    publicId: 'SIM-FAKE',
    status: ToolsRevisionStatus.Pending,
    createdBy: 'someone',
    indexPublicId: 'does not matter',
    addedAt: '2022-01-01T00:00:00.000Z',
    updatedAt: '2022-01-01T00:00:00.000Z',
    parentToolsRevisionPublicId: 'TR-PUBLISHED',
    syncStatus: {
        canSync: true,
        updatedTools: [
            {
                name: 'tool_1',
                mode: ToolMode.InProgress,
            } as Tool,
        ],
    },
    tools: [
        MOCK_VALID_TOOL,
        {
            publicId: 'TOOL-2',
            name: 'tool_2',
            parentToolPublicId: 'test',
            mode: ToolMode.InProgress,
            region: ToolRegion.US,
            description: 'This is a tool description.',
            questions: [
                {
                    content: 'Is this a question?',
                },
                {
                    content: 'Is this another question?',
                },
                {
                    content: 'Is this a question?',
                },
                {
                    content: 'Is this a question?',
                },
                {
                    content: 'Is this a question?',
                },
                {
                    content: 'Is this a question?',
                },
                {
                    content: 'Is this a question?',
                },
                {
                    content: 'Is this a question?',
                },
                {
                    content: 'Is this the semi-last question?',
                },
                {
                    content: 'Is this the last question?',
                },
            ],
            type: ToolType.GeneralKnowledge,
            createdBy: 'someone',
            changeStatus: ToolChangeStatus.Cloned,
            userGroups: [],
        },
        {
            publicId: 'TOOL-3',
            name: 'tool_3',
            parentToolPublicId: 'test',
            region: ToolRegion.US,
            mode: ToolMode.InProgress,
            description: 'Description for Tool 3.',
            params: [{ name: ToolParamName.PolicyId, description: '', required: true }],
            questions: [
                {
                    content: 'Is this a question?',
                },
            ],
            type: ToolType.GeneralKnowledge,
            createdBy: 'someone',
            changeStatus: ToolChangeStatus.Cloned,
            userGroups: [],
        },
        {
            publicId: 'TOOL-4',
            name: 'tool_4',
            parentToolPublicId: 'test',
            region: ToolRegion.US,
            mode: ToolMode.InProgress,
            description: 'Description for Tool 4.',
            questions: [
                {
                    content: 'Is this a question?',
                },
            ],
            type: ToolType.GeneralKnowledge,
            createdBy: 'someone',
            changeStatus: ToolChangeStatus.Cloned,
            userGroups: [],
        },
        {
            publicId: 'TOOL-5',
            name: 'tool_5',
            parentToolPublicId: 'test',
            region: ToolRegion.US,
            mode: ToolMode.InProgress,
            description: 'Description for Tool 5.',
            questions: [
                {
                    content: 'Is this a question?',
                },
            ],
            type: ToolType.GeneralKnowledge,
            createdBy: 'someone',
            changeStatus: ToolChangeStatus.Cloned,
            userGroups: [],
        },
        {
            publicId: 'TOOL-6',
            name: 'tool_6',
            parentToolPublicId: 'test',
            region: ToolRegion.US,
            mode: ToolMode.InProgress,
            description: 'Description for Tool 6.',
            questions: [
                {
                    content: 'Is this a question?',
                },
            ],
            type: ToolType.GeneralKnowledge,
            createdBy: 'someone',
            changeStatus: ToolChangeStatus.Cloned,
            userGroups: [],
        },
        { ...MOCK_VALID_TOOL, mode: ToolMode.Shell, publicId: 'shell_1', name: 'shell_1', subClassifiers: [] },
        { ...MOCK_VALID_TOOL, mode: ToolMode.Draft, publicId: 'draft_1', name: 'draft_1', subClassifiers: [] },
        { ...MOCK_VALID_TOOL, mode: ToolMode.Public, publicId: 'public_1', name: 'public_1', subClassifiers: [] },
    ],
};

export const TOOL_EDITOR_SCHEMA_VALUES_RESPONSE: { readonly data: ToolSchemaValues } = {
    data: {
        macros: {
            [ToolRegion.FR]: [
                'Car::Baggett Management::Backdate My Eiffel Tower [ Premium / Refund ]',
                'Car::Baggett Management::Can You Cancel My Previous Croissant?',
                'Pet::Baggett Management::Cancel Bree Cheese (Automatic Renewal)',
                "Pet::Baggett Management::Why Didn't My Poodle's Age Update on Their Birthday?",
            ],
            [ToolRegion.US]: macrosList,
            [ToolRegion.GB]: [
                'Car::Baggett Management::Backdate My Big Ben [ Premium / Refund ]',
                'Car::Baggett Management::Can You Cancel My Previous Big Ben?',
                'Pet::Baggett Management::Cancel Cup of tea (Automatic Renewal)',
                "Pet::Baggett Management::Why Didn't My Poodle's Age Update on Their Birthday?",
            ],
        },
        codeManagedTools: ['goodbye', 'fr_goodbye', 'gb_goodbye'],
        pvps: [
            'policy_general_receipt_url',
            'upload_medical_record_email_address',
            'upload_medical_record_url',
            'change_payment_plan_diy_url',
            'cancel_policy_diy_url',
            'change_start_date_diy_url',
            'cancel_renewal_diy_url',
            'dunning_charge_now_diy_url',
            'update_card_diy_url',
            'moving_diy_urls',
            'change_giveback_diy_url',
            'manage_policy_members_diy_url',
            'add_extra_coverage_diy_url',
            'get_a_quote_diy_url',
            'policies_docs',
            'do_not_use_policy_payment_history_deep_link_url',
        ],
        topics: {
            Cancellation: ['Immediate Cancellation', 'Schedule Cancellation', 'Random Cancellation'],
            'Policy Changes & Info': ['Adding people to policy', 'Adding valuable items to policy'],
        },
        customerCardSections: {
            [CustomerCardSectionName.CustomerInfo]: {
                // Persistent: Always return customer information
                label: 'Customer information',
                description: 'Customer details and account context to support personalized responses',
                required: true,
            },
            [CustomerCardSectionName.QuotesLast30Days]: {
                // Optional: Configurable on Playground
                label: 'Quotes',
                description: 'Quotes created by the customer in the 30 days prior to their inquiry',
                required: false,
            },
            [CustomerCardSectionName.ActivePolicies]: {
                // Persistent: Always return if customer has ≥ 1 policy with status = active
                label: 'Active policies',
                description: 'Policies currently in effect',
                required: true,
            },
            [CustomerCardSectionName.InactivePolicies]: {
                // Optional: Configurable on Playground. Return if customer has ≥ 1 policy with status = expired, canceled, renewed or canceled renewal
                label: 'Inactive policies',
                description: 'Policies that are no longer active',
                required: false,
            },
            [CustomerCardSectionName.UpcomingPolicies]: {
                // Persistent: Always return if customer has ≥ 1 policy with status = renewal or future
                label: 'Upcoming policies',
                description: 'Policies scheduled to start in the future, including renewals',
                required: true,
            },
        },
    },
};

export const CUSTOMER_CARD_DATA_RESPONSE: { readonly data: CustomerCardData } = {
    data: {
        sections: {
            customerInfo: {
                title: 'Customer Information',
                description: 'This section provides key details about the customer who submitted the inquiry.',
                fieldGroups: [
                    {
                        title: 'General',
                        fields: [
                            {
                                title: 'CXLLM response date',
                                value: 'Dec 22, 2024',
                            },
                        ],
                    },
                    {
                        title: 'Customer Details',
                        fields: [
                            {
                                title: 'Full name',
                                value: 'John Snow',
                            },
                            {
                                title: 'User group',
                                value: 'Active Lemonade Users',
                            },
                            {
                                title: 'Join date',
                                value: 'Dec 7, 2024',
                            },
                            {
                                title: 'Timezone',
                                value: 'America/New_York',
                            },
                        ],
                    },
                    {
                        title: 'Alerts',
                        fields: [
                            {
                                title: 'Has underwriting decline quotes',
                                value: 'True',
                            },
                        ],
                    },
                    {
                        title: 'Insurance',
                        fields: [
                            {
                                title: 'Number of active policies',
                                value: '3',
                            },
                            {
                                title: 'Number of upcoming policies',
                                value: '1',
                            },
                            {
                                title: 'Number of quotes (last 30 days)',
                                value: '5',
                            },
                        ],
                    },
                ],
            },
            quotesLast30Days: {
                title: 'Quotes (last 30 days)',
                description:
                    'This section includes quotes created by the customer in the 30 days prior to their inquiry. The customer has created such 5 quotes. Use this information only if relevant to their inquiry.',
                fieldGroups: [
                    {
                        title: 'Overview',
                        fields: [
                            {
                                title: 'Country',
                                value: 'US',
                            },
                            {
                                title: 'Currency',
                                value: '$',
                            },
                            {
                                title: 'Quote ID',
                                value: 'LQ1234',
                            },
                            {
                                title: 'Creation date',
                                value: 'Dec 2, 2024',
                            },
                            {
                                title: 'Status',
                                value: 'Paid',
                            },
                            {
                                title: 'Address',
                                value: '123 Main St, Apt. 1, New York, NY, 10001',
                            },
                        ],
                        superTitle: '123 Main St, Apt. 1, New York, NY, 10001 homeowners quote',
                    },
                    {
                        title: 'Overview',
                        fields: [
                            {
                                title: 'Country',
                                value: 'US',
                            },
                            {
                                title: 'Currency',
                                value: '$',
                            },
                            {
                                title: 'Quote ID',
                                value: 'LQ1235',
                            },
                            {
                                title: 'Creation date',
                                value: 'Dec 12, 2024',
                            },
                            {
                                title: 'Status',
                                value: 'Bindable',
                            },
                            {
                                title: 'Address',
                                value: '456 Oak Avenue, Apt. 2, Los Angeles, CA, 90001',
                            },
                        ],
                        superTitle: '456 Oak Avenue, Apt. 2, Los Angeles, CA, 90001 renters quote',
                    },
                    {
                        title: 'Overview',
                        fields: [
                            {
                                title: 'Country',
                                value: 'US',
                            },
                            {
                                title: 'Currency',
                                value: '$',
                            },
                            {
                                title: 'Quote ID',
                                value: 'LPQ1234',
                            },
                            {
                                title: 'Creation date',
                                value: 'Dec 17, 2024',
                            },
                            {
                                title: 'Status',
                                value: 'Bindable',
                            },
                            {
                                title: 'Address',
                                value: '123 Main St, Apt. 1, New York, NY, 10001',
                            },
                        ],
                        superTitle: 'Ghost’s pet quote',
                    },
                    {
                        title: 'Pet details',
                        fields: [
                            {
                                title: 'Type',
                                value: 'Dog',
                            },
                            {
                                title: 'Name',
                                value: 'Ghost',
                            },
                            {
                                title: 'Breed',
                                value: 'Husky',
                            },
                            {
                                title: 'Sex',
                                value: 'Male',
                            },
                            {
                                title: 'Recorded birth date',
                                value: 'Dec 22, 2023',
                            },
                            {
                                title: 'Age',
                                value: '1 year',
                            },
                        ],
                    },
                    {
                        title: 'Overview',
                        fields: [
                            {
                                title: 'Country',
                                value: 'US',
                            },
                            {
                                title: 'Currency',
                                value: '$',
                            },
                            {
                                title: 'Quote ID',
                                value: 'LCQ1234',
                            },
                            {
                                title: 'Creation date',
                                value: 'Dec 7, 2024',
                            },
                            {
                                title: 'Status',
                                value: 'Paid',
                            },
                            {
                                title: 'Address',
                                value: '123 Main St, Apt. 1, New York, NY, 10001',
                            },
                        ],
                        superTitle: '2020 Tesla Model 3 car quote',
                    },
                    {
                        title: 'Overview',
                        fields: [
                            {
                                title: 'Country',
                                value: 'US',
                            },
                            {
                                title: 'Currency',
                                value: '$',
                            },
                            {
                                title: 'Quote ID',
                                value: 'LCQ1235',
                            },
                            {
                                title: 'Creation date',
                                value: 'Dec 7, 2024',
                            },
                            {
                                title: 'Status',
                                value: 'Uw Declined',
                            },
                            {
                                title: 'Address',
                                value: '123 Main St, Apt. 1, New York, NY, 10001',
                            },
                        ],
                        superTitle: '1970 Unknown Unknown car quote',
                    },
                ],
            },
            activePolicies: {
                title: 'Active policies',
                description:
                    'This section includes policies currently in effect. The customer has 3 active policies. Refer to these only when they are relevant to the customer’s inquiry.',
                fieldGroups: [
                    {
                        title: 'Overview',
                        fields: [
                            {
                                title: 'Policy ID',
                                value: 'LP1234',
                            },
                            {
                                title: 'Address',
                                value: '123 Main St, Apt. 1, New York, NY, 10001',
                            },
                            {
                                title: 'Status',
                                value: 'Active',
                            },
                            {
                                title: 'Purchase date',
                                value: 'Dec 12, 2024',
                            },
                            {
                                title: 'Effective date',
                                value: 'Dec 16, 2024',
                            },
                            {
                                title: 'Expiration date',
                                value: 'Dec 16, 2025',
                            },
                            {
                                title: 'Renewal policy ID',
                                value: 'LP1235',
                            },
                            {
                                title: 'Renewal policy status',
                                value: 'Renewal',
                            },
                            {
                                title: 'Payment plan',
                                value: 'Annual',
                            },
                            {
                                title: 'Installment fees',
                                value: '0',
                            },
                            {
                                title: 'Annual premium',
                                value: '1200',
                            },
                        ],
                        superTitle: '123 Main St, Apt. 1, New York, NY, 10001 homeowners policy',
                    },
                    {
                        title: 'Overview',
                        fields: [
                            {
                                title: 'Policy ID',
                                value: 'LPP1234',
                            },
                            {
                                title: 'Address',
                                value: '123 Main St, Apt. 1, New York, NY, 10001',
                            },
                            {
                                title: 'Status',
                                value: 'Active',
                            },
                            {
                                title: 'Purchase date',
                                value: 'Dec 18, 2024',
                            },
                            {
                                title: 'Effective date',
                                value: 'Apr 22, 2024',
                            },
                            {
                                title: 'Expiration date',
                                value: 'Dec 18, 2025',
                            },
                            {
                                title: 'Payment plan',
                                value: 'Annual',
                            },
                            {
                                title: 'Installment fees',
                                value: '10',
                            },
                            {
                                title: 'Annual premium',
                                value: '240',
                            },
                            {
                                title: 'Annual premium with fees',
                                value: '250',
                            },
                            {
                                title: 'In coverage update window',
                                value: 'False',
                            },
                        ],
                        superTitle: 'Ghost’s pet policy',
                    },
                    {
                        title: 'Pet details',
                        fields: [
                            {
                                title: 'Type',
                                value: 'Dog',
                            },
                            {
                                title: 'Name',
                                value: 'Ghost',
                            },
                            {
                                title: 'Breed',
                                value: 'Husky',
                            },
                            {
                                title: 'Sex',
                                value: 'Male',
                            },
                            {
                                title: 'Recorded birth date',
                                value: 'Dec 22, 2023',
                            },
                            {
                                title: 'Age',
                                value: '1 year',
                            },
                        ],
                    },
                    {
                        title: 'Add-ons',
                        fields: [
                            {
                                title: 'Add-ons',
                                value: 'Dental Illness, Physical Therapy',
                            },
                            {
                                title: 'Preventative Care package',
                                value: 'Preventative Puppy Care',
                            },
                        ],
                    },
                    {
                        title: 'Overview',
                        fields: [
                            {
                                title: 'Policy ID',
                                value: 'LCP1234',
                            },
                            {
                                title: 'Address',
                                value: '123 Main St, Apt. 1, New York, NY, 10001',
                            },
                            {
                                title: 'Status',
                                value: 'Active',
                            },
                            {
                                title: 'Purchase date',
                                value: 'Dec 7, 2024',
                            },
                            {
                                title: 'Effective date',
                                value: 'Apr 22, 2025',
                            },
                            {
                                title: 'Days until effective',
                                value: '121',
                            },
                            {
                                title: 'Expiration date',
                                value: 'Oct 22, 2025',
                            },
                            {
                                title: 'Payment plan',
                                value: 'Semi Annual',
                            },
                            {
                                title: 'Installment fees',
                                value: '0',
                            },
                            {
                                title: 'Semi annual premium',
                                value: '780',
                            },
                            {
                                title: 'Insured cars',
                                value: '2020 Tesla Model 3',
                            },
                            {
                                title: 'Billing type',
                                value: 'Fixed',
                            },
                        ],
                        superTitle: 'Car policy',
                    },
                ],
            },
            upcomingPolicies: {
                title: 'Upcoming policies',
                description:
                    'This section includes policies scheduled to start in the future. The customer has 1 upcoming policy. Use this information only if it pertains to their inquiry.',
                fieldGroups: [
                    {
                        title: 'Overview',
                        fields: [
                            {
                                title: 'Policy ID',
                                value: 'LP1235',
                            },
                            {
                                title: 'Address',
                                value: '123 Main St, Apt. 1, New York, NY, 10001',
                            },
                            {
                                title: 'Status',
                                value: 'Renewal',
                            },
                            {
                                title: 'Purchase date',
                                value: 'Dec 22, 2024',
                            },
                            {
                                title: 'Effective date',
                                value: 'Dec 16, 2026',
                            },
                            {
                                title: 'Days until effective',
                                value: '724',
                            },
                            {
                                title: 'Expiration date',
                                value: 'Dec 16, 2026',
                            },
                            {
                                title: 'Renewal policy ID',
                                value: 'LP1235',
                            },
                            {
                                title: 'Renewal policy status',
                                value: 'Renewal',
                            },
                            {
                                title: 'Payment plan',
                                value: 'Annual',
                            },
                            {
                                title: 'Installment fees',
                                value: '0',
                            },
                            {
                                title: 'Annual premium',
                                value: '1200',
                            },
                        ],
                        superTitle: '123 Main St, Apt. 1, New York, NY, 10001 homeowners policy',
                    },
                ],
            },
            inactivePolicies: {
                title: 'Inactive policies',
                description: 'The customer has no inactive policies on record.',
                fieldGroups: [],
            },
        },
    },
};

export const GENERATED_DESCRIPTION_RESPONSE: { readonly data: { readonly generated: string } } = {
    data: {
        generated: 'This is a generated description from the API.',
    },
};
