import axios from 'axios';
import { snakeToCamelCaseKeys } from '@lemonade-hq/ts-helpers';
import { TreatmentPlace } from 'models/PetClaim';
import { getUrlResolver } from 'commons/UrlResolver';
import { MedicalExamStatus } from 'models/Pet/VetMedicalExam';

const petUrlResolver = getUrlResolver('pet-blender', '/api/v1/treatment_places');

export interface TreatmentPlaceDTO {
    publicId: string;
    name: string;
    email?: string;
    phoneNumber: string;
    userPublicId: string;
    vetPublicId?: string;
    websiteUrl?: string;
    contactPreference?: string;
    isCustomVet?: boolean;
    medicalRecordStatus?: MedicalExamStatus;
}

export async function createTreatmentPlace({
    treatmentPlace,
}: {
    treatmentPlace: Partial<TreatmentPlace>;
}): Promise<TreatmentPlace> {
    const body = snakeToCamelCaseKeys({ ...treatmentPlace } as Record<string, string>);

    const newTreatmentPlace = await axios
        .post<{ data: TreatmentPlaceDTO }>(petUrlResolver(), body)
        .then(response => response.data.data);

    return deserializerTreatmentPlace(newTreatmentPlace);
}

export async function updateTreatmentPlace({
    publicId,
    treatmentPlace,
    claimId,
}: {
    publicId: string;
    treatmentPlace: Partial<TreatmentPlace>;
    claimId?: string;
}): Promise<TreatmentPlace> {
    const url = petUrlResolver(`/${publicId}`);
    const body = snakeToCamelCaseKeys({ ...treatmentPlace, claimId } as Record<string, string>);

    const updatedTreatmentPlace = await axios
        .put<{ data: TreatmentPlaceDTO }>(url, body)
        .then(response => response.data.data);

    return deserializerTreatmentPlace(updatedTreatmentPlace);
}

export async function getUserTreatmentPlaces(userPublicId: string): Promise<TreatmentPlace[]> {
    const treatmentPlaces = await axios
        .get<{ data: TreatmentPlaceDTO[] }>(petUrlResolver(), { params: { userPublicId } })
        .then(response => response.data.data);

    return treatmentPlaces.map((treatmentPlace: TreatmentPlaceDTO) => deserializerTreatmentPlace(treatmentPlace));
}

export async function attachTreatmentPlaceToClaim({
    treatmentPlacePublicIds,
    claimPublicId,
}: {
    treatmentPlacePublicIds: string[];
    claimPublicId: string;
}): Promise<null> {
    const url = petUrlResolver(`/${claimPublicId}/attach`);
    const body = { treatmentPlacePublicIds };

    return axios.put(url, body).then(response => response.data.data);
}

function deserializerTreatmentPlace(treatmentPlace: TreatmentPlaceDTO): TreatmentPlace {
    const {
        publicId,
        userPublicId,
        name,
        phoneNumber,
        email,
        vetPublicId,
        websiteUrl,
        contactPreference,
    } = treatmentPlace;

    return {
        public_id: publicId,
        user_public_id: userPublicId,
        phone_number: phoneNumber,
        name,
        email: email ?? '',
        vet_public_id: vetPublicId,
        website_url: websiteUrl,
        contact_preference: contactPreference,
    };
}

export async function getClaimTreatmentPlaces(claimPublicId: string): Promise<TreatmentPlace[]> {
    const url = petUrlResolver(`/${claimPublicId}`);
    const treatmentPlaces = await axios.get<{ data: TreatmentPlaceDTO[] }>(url).then(response => response.data.data);

    return treatmentPlaces.map((treatmentPlace: TreatmentPlaceDTO) => deserializerTreatmentPlace(treatmentPlace));
}
