import { Flex } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { EditionSelectInputs } from './EditionSelectInputs';
import type { EditionSelectionData } from './EditionSelectInputs';
import { editionItemWrapper, stepTitle, stepWrapper } from './ManageReleaseDialog.css';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import { Step } from 'components/LoCo/common/components/CoverageRules/ManageRuleCommon';
import type { ReleaseType } from 'models/LoCo/Insurance/BaseEdition';
import { useGetProductEditionSets } from 'queries/LoCo/Insurance/ProductQueries';

interface EditionsSelectionProps {
    readonly productCode: string;
    readonly editionSelectionData: DeepNullable<EditionSelectionData>;
    readonly releaseType: ReleaseType | null;
    readonly onChange: (newEditionStepData: DeepNullable<EditionSelectionData>) => void;
}

export const EditionsSelection: React.FC<EditionsSelectionProps> = ({
    productCode,
    editionSelectionData,
    releaseType,
    onChange,
}) => {
    const { data: productPublishedEditionSets } = useGetProductEditionSets(productCode, true, { size: 1 });

    const allEditionsAreRequired =
        isDefined(productPublishedEditionSets?.data) && !productPublishedEditionSets.data.length;

    return (
        <Step>
            <Flex className={stepWrapper}>
                <Flex className={stepTitle}>Which new editions would you like to release?</Flex>
                <Flex>
                    {allEditionsAreRequired
                        ? 'Select an edition for each type'
                        : 'Select up to one edition of each type'}
                </Flex>
                <div className={editionItemWrapper}>
                    <EditionSelectInputs
                        editionSelectionData={editionSelectionData}
                        onChange={onChange}
                        productCode={productCode}
                        releaseType={releaseType}
                    />
                </div>
            </Flex>
        </Step>
    );
};
