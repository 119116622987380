import { Flex } from '@lemonade-hq/blender-ui';
// import { getEnv, getEnvId } from '@lemonade-hq/bluiza';
import { useCurrentPrincipal } from '@lemonade-hq/boutique';

import type { FC } from 'react';
import { useFullscreen } from 'hooks/useFullScreen';

export function getAwsRegion(): string {
    try {
        return (import.meta.env.VITE_AWS_REGION as string | undefined) ?? 'us-east-1';
    } catch {
        return 'us-east-1';
    }
}

export const ChatEditor: FC = () => {
    useFullscreen();
    const { operator } = useCurrentPrincipal();

    if (operator == null) return null;

    return (
        <Flex backgroundColor="$backgroundPrimary" height="100%" position="relative">
            {/* <ChatEditorClient
                env={{
                    stage: getEnv(),
                    region: getAwsRegion(),
                    envId: getEnvId() ?? 'master',
                }}
                pathPrefix="/backoffice/loco/chat_editor/"
                user={{ id: operator.id, name: operator.firstName ?? operator.email, imageUrl: operator.imageUrl }}
            /> */}
            <div>chat-editor placeholder</div>
        </Flex>
    );
};
