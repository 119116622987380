import { TagMode } from '@lemonade-hq/bluis';
import type { Descendant } from '@lemonade-hq/bluiza';
import { slateFromHtml } from '@lemonade-hq/cdk';
import type { MultiselectOption, SelectOptionBase } from '@lemonade-hq/cdk';
import type { Locale } from '@lemonade-hq/lemonation';
import { DatetimeFormat, formatDatetime } from '@lemonade-hq/lemonation';
import type { ToolRegion } from '@lemonade-hq/persisted-tools';
import type { Timezone } from '@lemonade-hq/ts-helpers';
import { snakeCaseToReadable, titlize } from '@lemonade-hq/ts-helpers';
import { prettifyToolRegion } from '../Playground/shared/tool.helpers';
import type { Message, SupervisorTicketFilters, TicketAiReview, TicketParam, TimelineItem } from '../shared/types';
import { TimelineItemType } from '../shared/types';
import { defaultFilters } from './supervisor.const';

export function convertNewLinesToHtmlBreaks(input: string): string {
    return input.split('\n').join('<br>');
}

export function convertHtmlBreaksToNewLines(input: string): string {
    return input.split(/<br\s*\/?>/g).join('\n');
}

export function slateFromAdjustedHtml(string: string): Descendant[] {
    const editorFormatResponse = convertHtmlBreaksToNewLines(string);

    return slateFromHtml(editorFormatResponse);
}

export function removeStyleAttributesFromHtml(htmlString: string): string {
    return htmlString.replace(/style="([^"]*)"/g, (_, styles: string) => {
        const styleAttributes = ['font-family', 'font-size', 'font', 'width', 'height'];
        const newStyles = styles
            .split(';')
            .filter(style => {
                return !styleAttributes.some(attr => style.trim().startsWith(attr));
            })
            .join(';');

        return `style="${newStyles}"`;
    });
}

export function buildTicketParams(ticket: TicketAiReview): TicketParam[] {
    const params: TicketParam[] = [];

    params.push({
        id: 'toolInvoked',
        mode: TagMode.Alert,
        text: `Intent: ${titlize(snakeCaseToReadable(ticket.toolInvoked))}`,
    });

    params.push({
        id: 'entityPublicId',
        mode: TagMode.Info,
        text: `Policy ID: ${ticket.entityPublicId ?? 'N/A'}`,
    });

    params.push({
        id: 'entityProduct',
        mode: TagMode.Info,
        text: `LOB: ${ticket.entityProduct != null ? snakeCaseToReadable(ticket.entityProduct) : 'N/A'}`,
    });

    return params;
}

export function getFiltersFromLocalStorage(): SupervisorTicketFilters {
    const storedFilters = localStorage.getItem('supervisorTicketFilters');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return storedFilters != null && storedFilters.length > 0 ? JSON.parse(storedFilters) : defaultFilters;
}

export function updateFiltersInLocalStorage(filters: SupervisorTicketFilters): void {
    localStorage.setItem('supervisorTicketFilters', JSON.stringify(filters));
}

export function isMessage(item: TimelineItem): item is Message {
    return item.type === TimelineItemType.Message || item.type === undefined;
}

export function formatDateTime({
    date,
    locale,
    timezone,
}: {
    readonly date?: string;
    readonly locale?: Locale;
    readonly timezone?: Timezone;
}): string {
    if (date == null) return '';

    const dateOnly = formatDatetime(new Date(date), {
        ...(locale != null && { locale }),
        timezone,
        datetimeFormat: DatetimeFormat.DateOrdinalShortMonth,
    });
    const timeOnly = formatDatetime(new Date(date), {
        ...(locale != null && { locale }),
        timezone,
        datetimeFormat: DatetimeFormat.TimeDefault,
    });

    return `${dateOnly}, ${timeOnly}`; // e.g. Sep 17, 2023, 02:00pm
}

export function findSecondLastMessageIndex(timeline: TimelineItem[]): number {
    let messageCount = 0;

    for (let i = timeline.length - 1; i >= 0; i--) {
        if (isMessage(timeline[i])) {
            messageCount++;
            if (messageCount === 3) {
                return i + 1;
            }
        }
    }

    return 0;
}

export function generateMultiSelectOptions(list: string[]): MultiselectOption[] {
    return list.map(item => ({
        label: snakeCaseToReadable(item),
        id: item,
    }));
}

export function generateSelectOptions(list: string[]): SelectOptionBase[] {
    return list.map(item => ({
        label: item,
        id: item,
        value: item,
    }));
}

export function generateRegionSelectOptions(list: ToolRegion[]): SelectOptionBase[] {
    return list.map(item => ({
        label: prettifyToolRegion(item),
        id: item as string,
        value: item as string,
    }));
}

export function generateIntentsOptions(intents: Record<string, string>): MultiselectOption[] {
    return Object.keys(intents).map(key => ({
        label: intents[key],
        id: key,
    }));
}

// This function extracts images from paragraphs that contain only an image, with optional whitespace/br tags
export function extractImagesFromParagraphs(html: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Find all paragraphs containing images
    const paragraphsWithImages = doc.querySelectorAll('p:has(img)');

    paragraphsWithImages.forEach(p => {
        const images = p.querySelectorAll('img');
        if (images.length === 1) {
            p.replaceWith(images[0]);
        } else if (images.length > 1) {
            // Create a document fragment to hold all images
            const fragment = document.createDocumentFragment();
            images.forEach(img => fragment.appendChild(img));
            p.replaceWith(fragment);
        }
    });

    return doc.body.innerHTML;
}

export function replaceBrTagsWithNewLines(html: string): string {
    return html.replace(/<br\s*\/?>/gi, '\n');
}
