import { FormProvider } from '@lemonade-hq/blender-ui';
import type { Infer, RecordValidationResult } from '@lemonade-hq/maschema-schema';
import { va } from '@lemonade-hq/maschema-schema';
import type { ValidationUIExtension } from '@lemonade-hq/maschema-validations-ui';
import type { BaseValidation } from '@lemonade-hq/ts-helpers';
import type { FC, PropsWithChildren } from 'react';
import type { PartialDeep } from 'type-fest';
import type { ManageSettingDialogFormProps } from './ManageSettingDialog';
import { manageSettingDialogSchema } from './manageSettingDialogSchema';
import { InsuranceScopeType, ValueType } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

export const ManageSettingDialogProvider: FC<
    PropsWithChildren<
        ManageSettingDialogFormProps & { readonly editedSettingInstance?: Infer<typeof manageSettingDialogSchema> }
    >
> = ({
    settingsRegistry,
    insurableEntities,
    editionSettingInstances,
    editionCoverageInstances,
    children,
    editedSettingInstance,
}) => {
    const existingTemplateCodes = editionSettingInstances
        .map(({ templateCode }) => templateCode)
        .filter(code => code !== editedSettingInstance?.templateCode);

    return (
        <FormProvider
            initialConfig={{
                additionalValidations: {
                    'scope.type':
                        insurableEntities.length > 0 ? [va.oneOf([InsuranceScopeType.InsuredEntity])] : undefined,
                    'scope.insuredEntityCode':
                        insurableEntities.length > 0
                            ? [va.oneOf(insurableEntities.map(({ code }) => code))]
                            : undefined,
                    templateCode: [
                        va.oneOf(
                            settingsRegistry
                                .filter(({ code }) => !existingTemplateCodes.includes(code))
                                .map(({ code }) => code)
                        ),
                    ],
                    parentLimitTemplateCode: [
                        va.oneOf(
                            editionSettingInstances
                                .filter(
                                    s =>
                                        s.type === SettingType.Limit &&
                                        s.templateCode !== editedSettingInstance?.templateCode
                                )
                                .map(({ templateCode }) => templateCode)
                        ),
                    ],
                    'coupledSetting.coupledSettingTemplateCode': [
                        va.oneOf(
                            editionSettingInstances
                                .filter(
                                    s =>
                                        s.values.type === ValueType.List &&
                                        s.templateCode !== editedSettingInstance?.templateCode &&
                                        (s.coupledSetting?.coupledSettingTemplateCode ===
                                            editedSettingInstance?.templateCode ||
                                            s.coupledSetting === undefined)
                                )
                                .map(({ templateCode }) => templateCode)
                        ),
                    ],
                    'relatedCoverages[*]': [va.oneOf(editionCoverageInstances.map(e => e.templateCode))],
                },
                schemaKeysRules: {
                    parentLimitTemplateCode: {
                        rules: [],
                        visible: editionSettingInstances.some(s => s.type === SettingType.Limit),
                    },
                    duration: {
                        rules: [
                            {
                                conditions: { type: [va.noneOf([SettingType.Limit, SettingType.Deductible])] },
                                actions: [
                                    {
                                        type: 'setValue',
                                        value: undefined,
                                    },
                                ],
                            },
                        ],
                    },
                    templateCode: {
                        rules: [],
                        disabled: Boolean(editedSettingInstance),
                    },
                    scope: {
                        rules: [
                            {
                                conditions: { type: [va.noneOf([SettingType.Limit, SettingType.Deductible])] },
                                actions: [
                                    {
                                        type: 'setValue',
                                        value: undefined,
                                    },
                                ],
                            },
                        ],
                    },
                    'values.values': {
                        rules: [
                            {
                                conditions: {
                                    'values.type': [va.is(ValueType.List)],
                                    'values.values': [va.is(undefined)],
                                },
                                actions: [
                                    {
                                        type: 'setValue',
                                        // @ts-expect-error -- types are not properly inferred because of maschema
                                        // type inference doesn't support union types
                                        value: [],
                                    },
                                ],
                            },
                        ],
                    },
                },
                showErrorsOnBlur: true,
                dynamicValidations: (values): RecordValidationResult => {
                    if (
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- ESLint is wrong here
                        values !== undefined &&
                        values.values?.type === ValueType.Range &&
                        values.values.min !== undefined &&
                        values.values.max !== undefined
                    ) {
                        if (values.values.min >= values.values.max) {
                            return {
                                valid: false,
                                failingValidations: {
                                    'values.max': [
                                        {
                                            error: 'Max value must be greater than min value',
                                        } as BaseValidation & ValidationUIExtension,
                                    ],
                                    'values.min': [
                                        { error: 'Min value must be less than max value' } as BaseValidation &
                                            ValidationUIExtension,
                                    ],
                                },
                            };
                        }

                        if (
                            values.values.step !== undefined &&
                            values.values.max - values.values.min < values.values.step
                        ) {
                            return {
                                valid: false,
                                failingValidations: {
                                    'values.step': [
                                        {
                                            error: 'Interval size must be smaller than or equal to the range (max - min)',
                                        } as BaseValidation & ValidationUIExtension,
                                    ],
                                },
                            };
                        }
                    }

                    return {
                        valid: true,
                        failingValidations: {},
                    };
                },
            }}
            initialValues={editedSettingInstance as PartialDeep<Infer<typeof manageSettingDialogSchema>>}
            schema={manageSettingDialogSchema}
        >
            {children}
        </FormProvider>
    );
};
