import { DateTimePicker, FormInputWrapper, InfoIcon } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import moment from 'moment';
import { useCallback } from 'react';
import styled from 'styled-components';
import { dialogStepItemsWrapper, NoticeWrapper, stepTitle, stepWrapper } from './ManageReleaseDialog.css';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import { DialogStep, DialogStepWrapper } from 'components/LoCo/common/components/Dialog/Dialog';
import { ReleaseType, VersionType } from 'models/LoCo/Insurance/BaseEdition';

export type EffectiveDatesStepType = {
    readonly newBusiness: Date | null;
    readonly renewals: Date | null;
    readonly isValid: boolean | null;
};

const StyledFormInputWrapper = styled(FormInputWrapper)`
    width: 360px;
`;

function validateStep(
    releaseType: ReleaseType | null,
    versionType: VersionType | null,
    newBusiness: Date | null,
    renewals: Date | null
): boolean {
    if (releaseType === ReleaseType.BugFix) {
        return true;
    }

    const dates = [newBusiness];

    if (versionType === VersionType.Major) {
        dates.push(renewals);
    }

    return dates.every(date => {
        const res = date !== null && moment(date).isBetween(moment(), moment().add(2, 'month'), 'day', '[]');
        return res;
    });
}

function getNoticeText(releaseType: ReleaseType | null): JSX.Element | null {
    switch (releaseType) {
        case ReleaseType.BugFix:
            return (
                <>
                    As a <b>bug fix</b>, the effective dates will be set automatically to match those of the edition
                    set(s) being targeted for the fix. After the release is published, impacted quotes and policies
                    should be migrated to the fixed configurations via a separate process.
                </>
            );
        default:
            return null;
    }
}

interface EffectiveDatesProps {
    readonly versionType: VersionType | null;
    readonly releaseType: ReleaseType | null;
    readonly effectiveDates: DeepNullable<EffectiveDatesStepType>;
    readonly onChange: (newEffectiveDates: DeepNullable<EffectiveDatesStepType>) => void;
}

export const EffectiveDates: React.FC<EffectiveDatesProps> = ({
    versionType,
    releaseType,
    onChange,
    effectiveDates,
}) => {
    const bannerText = getNoticeText(releaseType);

    const handleDateChange = useCallback(
        (key: 'newBusiness' | 'renewals', date: Date) => {
            const dates = {
                ...effectiveDates,
                [key]: date,
            };
            onChange({
                ...dates,
                isValid: validateStep(releaseType, versionType, dates.newBusiness, dates.renewals),
            });
        },
        [effectiveDates, onChange, releaseType, versionType]
    );

    const minDate = moment().add(-1, 'day').toDate();
    const maxDate = moment().add(2, 'month').toDate();

    return (
        <DialogStepWrapper>
            <DialogStep padding="40px 64px">
                <Flex className={stepWrapper}>
                    <Flex className={stepTitle}>When should these changes take effect?</Flex>
                    <Flex className={dialogStepItemsWrapper}>
                        <StyledFormInputWrapper
                            label={
                                releaseType === ReleaseType.ProductChange && versionType === VersionType.Minor
                                    ? ''
                                    : 'New Business'
                            }
                        >
                            <DateTimePicker
                                disabled={releaseType === ReleaseType.BugFix}
                                maxDate={maxDate}
                                minDate={minDate}
                                onChange={date => handleDateChange('newBusiness', date)}
                                value={effectiveDates.newBusiness ?? undefined}
                            />
                        </StyledFormInputWrapper>
                        {(releaseType === ReleaseType.BugFix || versionType === VersionType.Major) && (
                            <StyledFormInputWrapper label="Renewals">
                                <DateTimePicker
                                    disabled={releaseType === ReleaseType.BugFix}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    onChange={date => handleDateChange('renewals', date)}
                                    value={effectiveDates.renewals ?? undefined}
                                />
                            </StyledFormInputWrapper>
                        )}
                    </Flex>
                </Flex>
            </DialogStep>
            {bannerText != null && (
                <Flex className={NoticeWrapper}>
                    <InfoIcon />
                    <div>{bannerText}</div>
                </Flex>
            )}
        </DialogStepWrapper>
    );
};
