import type { IconName } from '@lemonade-hq/blender-ui';
import { ComboBox, Flex, Select } from '@lemonade-hq/blender-ui';
import { clsx } from 'clsx';
import type { ComboBoxItem } from 'libs/blender-ui/src/components/ComboBox/ComboBox';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { useCallback, useMemo } from 'react';
import type { Key } from 'react-aria-components';
import { getTypeFromSchema } from './ExpressionSimpleEditor/ExpressionSimpleEditorShared';
import type { Argument } from './ExpressionSimpleEditor/expressionTypes';
import { ArgumentType } from './ExpressionSimpleEditor/expressionTypes';
import type { InputFunction } from './ExpressionSimpleEditor/operators';
import * as schemaAutocompleteStyles from './SchemaAutocomplete.css';
import type { FieldEntry } from 'models/LoCo/Insurance/Schema';

function getArgumentsFromFunctions(functions: Record<string, InputFunction> = {}): ExtendedComboBoxOption[] {
    return uniqWith(
        Object.keys(functions)
            .filter(key => !functions[key].collapse)
            .flatMap(
                key =>
                    functions[key].argument?.symbols.map(symbol => ({
                        label: `${symbol.label} ${functions[key].argument?.enumName === 'Coverages' ? 'coverage' : 'value'}`,
                        value: `${key}-${symbol.value}`,
                        actualValue: symbol.value,
                        icon: 'magic-wand',
                    })) ?? []
            ),
        isEqual
    );
}

function getSupportedFunctionsForArgument(
    additionalFunctions: Record<string, InputFunction>,
    argumentValue: string
): string[] {
    return Object.entries(additionalFunctions)
        .filter(([_, func]) => func.argument?.symbols.map(s => s.value).includes(argumentValue))
        .map(([key]) => key);
}

interface ExtendedComboBoxOption extends ComboBoxItem {
    readonly func?: string;
    /**
     * We separate the value of the combobox from the "actualValue" which is the value that will be used
     * in the expression.
     * The reason for that is to create a namespace for different functions.
     * So, for example we can have the same actualValue for the input function and the selectedCoverage function
     * but their value would be namespaced with the function name so they don't collide.
     */
    readonly actualValue: string;
}

interface OptionBase {
    readonly type: 'function' | 'value';
    readonly value: string;
}

interface FunctionOption extends OptionBase {
    readonly type: 'function';
    readonly argument: string;
}

export interface ValueOption extends OptionBase {
    readonly type: 'value';
}

export type SchemaAutocompleteOption = FunctionOption | ValueOption;

interface SchemaAutocompleteProps {
    /**
     * determines the general type of the expression - used to filter out the options
     * that are not compatible with the expression type.
     * For example, if the expression type is a number, only fields that are of type number
     * will be shown in the autocomplete
     */
    readonly expressionType?: Argument;
    /**
     * The schema of the product from which the fields are taken.
     */
    readonly productSchema: Record<string, FieldEntry>;
    /**
     * Additional functions on top of the product's schema.
     *
     * For example - `Coverage (this term)` - a function that takes a coverage name as an argument
     * and returns the coverage value (`ON` or `OFF`).
     *
     * Another example - `Setting (this term)` - a function that takes a setting name as an argument
     * and returns the setting value.
     */
    readonly additionalFunctions?: Record<string, InputFunction>;
    readonly value: SchemaAutocompleteOption;
    readonly placeholder?: string;
    readonly onChange: (item: SchemaAutocompleteOption | null) => void;
    readonly error?: boolean;
    readonly onClear?: () => void;
    readonly width?: number;
    readonly className?: string;
}

const SchemaTypeIcon: Record<ArgumentType, IconName> = {
    [ArgumentType.Number]: 'hash',
    [ArgumentType.Enum]: 'list',
    [ArgumentType.String]: 'text',
    [ArgumentType.Boolean]: 'toggle-left',
    [ArgumentType.Unknown]: 'x-circle-solid',
};

export const SchemaAutocomplete: React.FC<SchemaAutocompleteProps> = ({
    productSchema,
    additionalFunctions = {},
    value,
    placeholder,
    onChange,
    error = false,
    expressionType,
    className,
}) => {
    const items: ExtendedComboBoxOption[] = useMemo(() => {
        return Object.values(productSchema)
            .filter(field => {
                const fieldType = getTypeFromSchema(productSchema, field.name);

                if (fieldType.type === ArgumentType.Unknown) return false;
                if (!expressionType) return true;
                if (expressionType.type === fieldType.type) {
                    return true;
                }

                return false;
            })
            .map(field => {
                const fieldName = field.name;

                const fieldType = getTypeFromSchema(productSchema, fieldName);

                const option: ExtendedComboBoxOption = {
                    value: `input-${fieldName}`,
                    actualValue: fieldName,
                    label: fieldName,
                    func: 'input',
                    icon: SchemaTypeIcon[fieldType.type],
                };

                return option;
            })
            .concat(getArgumentsFromFunctions(additionalFunctions));
    }, [productSchema, expressionType, additionalFunctions]);
    const isANonInputFunction = value.type === 'function' && value.value !== 'input';

    const handleSelectionChange = useCallback(
        (option: ExtendedComboBoxOption | null) => {
            const supportedFunctions =
                option !== null && !option.customValue && option.func !== 'input'
                    ? getSupportedFunctionsForArgument(additionalFunctions, option.actualValue)
                    : undefined;

            onChange(
                option === null
                    ? null
                    : option.customValue
                      ? { type: 'value', value: option.label }
                      : {
                            type: 'function',
                            value: option.func ?? supportedFunctions?.[0] ?? 'input',
                            argument: option.actualValue,
                        }
            );
        },
        [additionalFunctions, onChange]
    );

    const updateFunction = useCallback(
        (newValue: Key | null): void => {
            if (!isFunction(value) || typeof newValue !== 'string') return;

            onChange({
                type: 'function',
                value: newValue,
                argument: value.argument,
            });
        },
        [onChange, value]
    );

    const valueIsFunction = isFunction(value);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const isCollapsed = valueIsFunction && additionalFunctions[value.value]?.collapse;
    const actualValue = value.value ? (isCollapsed ? `${value.value}-${value.argument}` : value.value) : undefined;

    return (
        <Flex className={clsx(schemaAutocompleteStyles.autocompleteContainer, className)}>
            <ComboBox<'single', ExtendedComboBoxOption>
                allowCustomValue={expressionType?.type !== undefined}
                defaultValue={actualValue}
                hasError={error}
                items={items}
                onSelectionChange={handleSelectionChange}
                placeholder={placeholder ?? 'select'}
            />
            {isANonInputFunction && (
                <Flex alignItems="center" gap="1rem" justifyContent="space-between" width="100%">
                    <Select
                        className={schemaAutocompleteStyles.argumentSelector}
                        onChange={updateFunction}
                        options={getSupportedFunctionsForArgument(additionalFunctions, value.argument).map(func => ({
                            value: func,
                            label: additionalFunctions[func].display,
                        }))}
                        placeholder={`Select function`}
                        selectedKey={value.value}
                    />
                </Flex>
            )}
            {error && <Flex className={schemaAutocompleteStyles.error}>This field is empty</Flex>}
        </Flex>
    );
};

function isFunction(value: SchemaAutocompleteOption): value is FunctionOption {
    return value.type === 'function';
}
