import { Input, MainButton, SecondaryButton } from '@lemonade-hq/bluis';
import { themedColor } from '@lemonade-hq/boutique';
import { Box } from '@lemonade-hq/cdk';
import styled, { css } from 'styled-components';
import { BSelectContainer, BSelectTitle } from '../BSelect/select-styles';
import BSelect from 'bluis/BSelect';
import type { MultiSelect as IMultiSelect, SingleSelect as ISingleSelect } from 'bluis/BSelect/helpers';
import { ThemedColors } from 'bluis/Colors';

const BSwitch = require('../BSwitch').default;

const MORE_ICON = `${__assets_url}blender_assets/backoffice/bluis/more_icon.png`;

export const FiltersContainer = styled(Box)`
    position: relative;
    display: flex;
    flex-flow: row wrap;
    transition: height 0.3s ease;
    background-color: ${themedColor('elementBackground')};
    width: 1386px;
    margin: auto;

    @media screen and (min-width: 1600px) {
        width: 1566px;
    }
`;

export const TopFilters = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 40px 0 27px;
    height: 62px;
    width: 100%;
`;

export const AssigneeFilterContainer = styled(TopFilters)`
    margin: 36px 0 24px;
    align-items: center;
    gap: 16px;
    ${BSelectContainer} {
        display: flex;
        align-items: center;
        gap: 16px;
    }
    ${BSelectTitle} {
        margin-bottom: 0;
    }
    height: auto;
`;

export const MoreFilters = styled.div<{ readonly showMore?: boolean }>`
    display: flex;
    margin: 0 0 71px 0;
    height: 62px;
    opacity: ${props => (props.showMore ? '1' : '0')};
    transition: opacity 0.5s;
`;

const Select = styled(BSelect)`
    width: 191px;
    margin-right: 40px;
    display: inline-block;

    @media screen and (min-width: 1600px) {
        margin-right: 77px;
    }
`;

export const TextBoxTitle = styled.div`
    font-size: 12px;
    color: ${themedColor('primaryText')};
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-left: 3px;
`;

export const TextBoxContainer = styled.div`
    width: 191px;
    margin-right: 40px;
    min-width: 120px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    height: 39px;

    @media screen and (min-width: 1600px) {
        margin-right: 77px;
    }

    div {
        height: 100%;
        width: 100%;

        input {
            border-color: ${themedColor('separator')};
            transition-property: none !important;

            &:focus {
                border-color: ${themedColor('primaryText')};
                box-shadow: none;

                &:hover {
                    border-color: ${themedColor('primaryText')};
                }
            }

            &:hover {
                border-color: ${themedColor('separator')};
            }
        }
    }
`;

export const TextBoxWrapper = styled.div`
    display: inline-block;
`;

export const MultiSelect = styled(Select)<IMultiSelect>``;
export const SingleSelect = styled(Select)<ISingleSelect>``;
export const AssigneeSelect = styled(Select)<ISingleSelect>``;

export const Switch = styled(BSwitch)`
    margin-right: 40px;

    @media screen and (min-width: 1600px) {
        margin-right: 77px;
    }
`;

export const ButtonsContainer = styled.div`
    padding-top: 20px;
`;

export const ClearButton = styled(SecondaryButton)<{ readonly loading?: boolean }>`
    background-color: ${ThemedColors.componentBackground};
    width: 100px;
    margin-right: 9px;
    ${({ loading }) =>
        loading &&
        css`
            display: inline-flex;
        `}
`;

export const SearchButton = styled(MainButton)<{ readonly loading?: boolean }>`
    width: 102px;
    ${({ loading }) =>
        loading &&
        css`
            display: inline-flex;
        `}
`;

export const ShowMore = styled.div`
    width: 125px;
    height: 27px;
    line-height: 25px;
    position: absolute;
    bottom: -13px;
    left: 50%;
    background: ${ThemedColors.componentBackground};
    border: 1px solid ${ThemedColors.separatorColor};
    border-radius: 15.5px;
    transform: translateX(-50%);
    font-size: 12px;
    color: ${ThemedColors.primaryText};
    cursor: pointer;
    z-index: 3;

    &:hover {
        border: 1px solid ${ThemedColors.primaryText};
    }
`;

export const MoreText = styled.div`
    width: calc(100% - 10px);
    text-align: center;
`;

export const MoreIcon = styled.div`
    width: 6px;
    height: 8px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('${MORE_ICON}');
    position: absolute;
    right: 10px;
    top: 9px;
`;

export const LessIcon = styled(MoreIcon)`
    transform: rotate(180deg);
`;

export const Text = styled(Input)`
    width: 191px;
`;

export const TextContainer = styled.div`
    margin-right: 20px;

    label {
        display: block;
        margin-bottom: 5px;
    }
`;

export const TextsContainer = styled.div`
    display: flex;
`;
