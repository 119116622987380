import styled, { css } from 'styled-components';
import { ThemedColors } from '../Colors';

const CHECKBOX_SELECTED = `${__assets_url}blender_assets/backoffice/bluis/checkbox_selected.png`;

export const OptionsContainer = styled.div<{
    readonly isOpen?: boolean;
    readonly above: boolean;
    readonly maxHeight: number;
    readonly overrideSelect?: boolean;
}>`
    visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
    position: absolute;
    top: 41px;
    left: 0;
    border: 1px solid ${ThemedColors.separatorColor};
    ${props =>
        props.overrideSelect
            ? css`
                  border-radius: 5px;
              `
            : ''}
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    max-height: ${({ maxHeight, isOpen }) => (isOpen ? `${maxHeight}px` : 0)};
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(155, 155, 155, 0.15);

    ${({ above }) =>
        above &&
        css`
            top: unset;
            bottom: 41px;
        `}
`;

export const Option = styled.div<{
    readonly isSelected?: boolean;
    readonly overrideSelect?: boolean;
    readonly disabled?: boolean;
}>`
    width: 100%;
    height: 39px;
    line-height: 39px;
    padding-left: 10px;
    border-top: 1px solid ${ThemedColors.separatorColor};
    box-sizing: border-box;
    white-space: nowrap;
    background-color: ${props =>
        props.isSelected ? ThemedColors.elementBackground : ThemedColors.componentBackground};

    span {
        height: 100%;
    }

    &:hover {
        background-color: ${ThemedColors.separatorColor};
    }

    &:first-child {
        border-radius: ${props => (props.overrideSelect ? '5px' : 0)};
        border-top: ${props => (props.overrideSelect ? '' : 0)};
    }

    &:last-child {
        border-radius: 0 0 4px 4px;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            background-color: ${ThemedColors.disabledBackground};
            color: ${ThemedColors.disabledText};
            pointer-events: none;
            cursor: not-allowed;
        `}
`;

export const OptionTitle = styled.div<{
    readonly withIcons?: boolean;
    readonly multiple?: boolean;
    readonly disabled?: boolean;
}>`
    display: inline-block;
    vertical-align: middle;
    color: ${({ disabled }) => (disabled ? ThemedColors.disabledText : ThemedColors.primaryText)};
    width: ${props =>
        // eslint-disable-next-line no-nested-ternary
        props.withIcons
            ? props.multiple
                ? 'calc(100% - 70px)'
                : 'calc(100% - 40px)'
            : props.multiple
              ? 'calc(100% - 40px)'
              : 'calc(100% - 10px)'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Checkbox = styled.div<{ readonly isSelected?: boolean }>`
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    width: 19px;
    height: 19px;
    background: ${ThemedColors.componentBackground};
    border: ${props => (props.isSelected ? '0' : '1px')} solid ${ThemedColors.separatorColor};
    border-radius: 5px;
    background-image: url('${props => (props.isSelected ? CHECKBOX_SELECTED : '')}');
    background-size: contain;
    background-repeat: no-repeat;
`;

export const NoResults = styled.div`
    width: 100%;
    height: 39px;
    line-height: 39px;
    background: ${ThemedColors.componentBackground};
    padding-left: 10px;
    box-sizing: border-box;
    white-space: nowrap;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 13px;
    color: ${ThemedColors.secondaryText};
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Icon = styled.div<{ readonly src: string }>`
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
    width: 23px;
    height: 23px;
    background: ${ThemedColors.componentBackground};
    border-radius: 50%;
    background-image: url('${props => props.src}');
    background-size: contain;
    background-repeat: no-repeat;
`;
