/* eslint-disable @typescript-eslint/naming-convention */
import type { TaskAssignee, WorkflowSegment } from '@lemonade-hq/bluiza';
import type { CamelCaseToSnakeCase, SnakeCaseToCamelCase, Writeable } from '@lemonade-hq/ts-helpers';
import { snakeToCamelCaseKeys } from '@lemonade-hq/ts-helpers';
import axios from 'axios';
import { stringifyUrl } from 'query-string';
import { carBlender } from '../apiClients';
import type { SendEmailArgsWithEntityId } from 'apis/EmailsAPI';
import { sendEmail as sendEmailResolver } from 'apis/EmailsAPI';
import {
    addCustomRecipient as addCustomRecipientGeneric,
    assignInteraction as assignInteractionGeneric,
    createNote as createNoteGeneric,
    dismissInteraction as dismissInteractionGeneric,
    manualResponseInteraction as manualResponseInteractionGeneric,
} from 'apis/InteractionsAPI';
import type { ReferenceType } from 'car/carClaim/workflows/ReferenceType';
import type { TagType } from 'car/carClaim/workflows/TagType';
import type { TaskType } from 'car/carClaim/workflows/TaskType';
import { getUrlResolver } from 'commons/UrlResolver';
import type { Adjusters as AdjustersState, EmailAdjustersState } from 'models/Adjuster';
import type { AttachmentOptionalRelations, AttachmentsFiltersData } from 'models/Attachment';
import type {
    AvailableCarConditions,
    CarAdjusterFees,
    CarClaim,
    CarClaimAction,
    CarClaimChatLog,
    CarClaimLossSubTypes,
    CarClaimLossTypes,
    CarClaimLossTypesSubTypeMap,
    CarClaimSearchResult,
    CarClaimStatus,
    CarClaimTags,
    CarClaimTaskGroup,
    CarReserveLog,
    CarRiskIndicator,
    ChildSeatStatus,
    CrashPrediction,
    LastKnownLocation,
    LocationCountry,
    ReportedByType,
} from 'models/CarClaim';
import type { CarCoverages, GlassCoverage, GlassStatus } from 'models/CarCoverages';
import type { CarDemandEvaluation } from 'models/CarDemandEvaluation';
import type { CarEventSummary, CarEventSummaryType } from 'models/CarEventSummary';
import type { FeatureName, Features, ItemType, LossItemType, PossibleFeature } from 'models/CarFeatures';
import type { LitigationCase } from 'models/CarLitigation';
import type { CarPolicy } from 'models/CarPolicy';
import type { CarQuote } from 'models/CarQuote';
import type {
    FuelType,
    KeysLocationType,
    RoadsideAssistanceTowJob,
    ServiceType,
    StuckInType,
} from 'models/CarRoadsideAssistanceTow';
import type {
    BiCoverageTypes,
    CarClaimReporter,
    ClaimHistory,
    GroupedAttachmentRelations,
    InvolvedVehicleDamagePoints,
    Location,
    QuoteAddress,
} from 'models/CarShared';
import { ExportEntityType } from 'models/CarShared';
import type { CarUser } from 'models/CarUser';
import type { ClaimPriority } from 'models/ClaimPriority';
import type { DamageNotification } from 'models/DamageNotification';
import type { Demand } from 'models/Demand';
import type { Driver } from 'models/Driver';
import type { InjuryBill } from 'models/InjuryBill';
import type { InjuryClaim } from 'models/InjuryClaim';
import type { FirstResponse, Interaction, NoteCreateRequest } from 'models/Interaction';
import type {
    InjuryInsuranceDetails,
    InvolvedParties,
    InvolvedPerson,
    InvolvedPersonRole,
    InvolvedVehicle,
    OwnedBy,
} from 'models/InvolvedParty';
import type { MitchellPdAssignmentsStatus, MitchellUrls } from 'models/Mitchell';
import type { AvailablePayeeType, Payee, PayeesPayoutMethods } from 'models/Payee';
import type {
    ItemNotPayable,
    ItemPayable,
    PaymentInfo,
    PaymentRequestDetailsResponse,
    Transaction,
} from 'models/Payment';
import { EntityTypes, ProductTypes } from 'models/Products';
import type { Recipient } from 'models/Recipient';
import type { Subrogation, SubrogationLog } from 'models/Subrogation';
import type { Vehicle } from 'models/Vehicle';
import type { CarVendor } from 'models/Vendor';

const BASE_URL = '/backoffice/car/claims';
const CURRENT_PRODUCT_PARAMS = { productType: 'car' };
const CAR_BLENDER_CLAIMS_BASE_URL = '/api/v1/claims';

const carBlenderClaimsUrlResolver = getUrlResolver('car-blender', '/api/v1/claims');
const carBlenderPaymentControlUrlResolver = getUrlResolver('car-blender', '/api/v1/payments_control');
const carBlenderVehicleTypesUrlResolver = getUrlResolver('car-blender', '/api/v1/vehicle_types');
const carBlenderInvolvedVehiclesUrlResolver = getUrlResolver('car-blender', '/api/v1/involved_vehicles');
const carBlenderInvolvedPeopleUrlResolver = getUrlResolver('car-blender', '/api/v1/involved_people');
const carBlenderGroupNotesUrlResolver = getUrlResolver('car-blender', '/api/v1/task-group-notes');
const blenderGeneralClaimsUrlResolver = getUrlResolver('blender-general', '/api/v1/claims');
const carBlenderRSATowJobResolver = getUrlResolver('car-blender', '/api/v1/roadside_assistance_tow_jobs');
const carBlenderMedicareManagementUrlResolver = getUrlResolver('car-blender', '/api/v1/medicare_management');
const carBlenderMedicareRiskReportResolver = getUrlResolver('car-blender', '/api/v1/medicare_risk_report');

export type SearchOrderByFields = 'loss_type' | 'settled' | 'status' | 'submitted_at' | 'user.first_name.raw';

export type RSAProviderType = 'automatic' | 'manual';
export const AUTOMATIC_RSA_PROVIDER_NAME = 'Honk';

export interface CancelCarClaimParams {
    readonly reason?: string;
    readonly description?: string;
}

export type CloseCarClaimParamsBase = {
    readonly claimCloseReason: string;
};

export type CloseCarClaimParamsOther = {
    readonly claimCloseReason: 'other';
    readonly claimCloseDescription?: string;
};
export type CloseCarClaimParams = CloseCarClaimParamsBase | CloseCarClaimParamsOther;

interface CarClaimsSearchParams {
    readonly offset: number;
    readonly order_by: SearchOrderByFields;
    readonly order_direction: 'asc' | 'desc';
    readonly status?: CarClaimStatus[];
    readonly settled?: boolean;
    readonly loss_type?: CarClaimLossTypes;
    readonly adjuster_public_id: string;
}

interface CarClaimsSearchResponse {
    readonly claims: CarClaimSearchResult[];
    readonly offset: number;
    readonly page_size: number;
    readonly size: number;
    readonly total: number;
}

export interface AlternativeTowDestination {
    readonly name: string;
    readonly phoneNumber?: string;
    readonly location: Location;
}

export interface RoadsideAssistanceTowJobToCreate {
    readonly claimPublicId: string;
    readonly involvedPersonPublicId?: string;
    readonly involvedVehiclePublicId?: string;
    readonly serviceType: ServiceType;
    readonly providerType: RSAProviderType;
    readonly providerName: string;
    readonly involvedInAccident: boolean;
    readonly vehicleLocation: CamelCaseToSnakeCase<Location>;
    readonly isPersonWithVehicle: boolean;
    // eslint-disable-next-line functional/prefer-readonly-type
    insideParkingGarage?: boolean;
    readonly garageHasHeightLimit?: boolean;
    // eslint-disable-next-line functional/prefer-readonly-type
    garageClearanceFeet?: number;
    // eslint-disable-next-line functional/prefer-readonly-type
    garageClearanceInches?: number;
    // eslint-disable-next-line functional/prefer-readonly-type
    shopPublicId?: string;
    // eslint-disable-next-line functional/prefer-readonly-type
    drpShopPublicId?: string;
    // eslint-disable-next-line functional/prefer-readonly-type
    alternativeTowDestination?: AlternativeTowDestination;
    // eslint-disable-next-line functional/prefer-readonly-type
    isSecondaryTow?: boolean;
    readonly requiredFuelType?: FuelType;
    readonly singleFlatTire?: boolean;
    readonly workingSpareTire?: boolean;
    readonly keysLocationInVehicle?: KeysLocationType;
    readonly trunkAccessibleFromCabin?: boolean;
    readonly brokenOrMissingKeys?: boolean;
    readonly vehicleStalledWhileDriving?: boolean;
    readonly alreadyJumpstarted?: boolean;
    readonly vehicleUnderTenFeetFromRoad?: boolean;
    readonly stuckInType?: StuckInType;
    readonly requiresWinch?: boolean;
    readonly vehicleEntersNeutral?: boolean;
    readonly driverNotes?: string;
    // eslint-disable-next-line functional/prefer-readonly-type
    impoundLotName?: string;
    // eslint-disable-next-line functional/prefer-readonly-type
    impoundLotPhoneNumber?: string;
    readonly reasonForManual?: string;
    readonly reasonForManualInfo?: string | null;
}

export type RoadsideAssistanceTowJobToEdit = Partial<RoadsideAssistanceTowJobToCreate>;

interface CarClaimsSearchParamsResponse {
    readonly statuses: CarClaimStatus[];
    readonly settled: boolean[];
    readonly loss_types: CarClaimLossTypes[];
    readonly adjusters: {
        readonly full_name: string;
        readonly photo_url: string;
        readonly public_id: string;
        readonly operator_public_id: string;
    }[];
}

interface DetailedIndicators {
    readonly name: string;
    readonly score: number;
    readonly wasApplied: boolean;
}

export interface InvolvedVehicleEstimatedTotalLossResponse {
    readonly isTotalLoss: boolean;
    readonly totalScore: number;
    readonly detailedIndicators: DetailedIndicators[];
}

export interface CreateTaskGroupNoteParams extends NoteCreateRequest {
    readonly taskPublicId: string;
    readonly groupReferenceType?: string;
    readonly groupReferenceId?: string;
    readonly operatorPublicId: string;
}

export interface NegotiateSettlementResponse {
    readonly publicId: string;
    readonly involvedPersonPublicId: string;
    readonly evaluation: string;
    readonly createdAt: string;
}

interface CarClaimPdfExport {
    readonly [ExportEntityType.CarClaim]: {
        readonly options: { readonly key: string; readonly display_name: string }[];
    };
}

export interface DemandEvaluationAllowedRolesMap {
    readonly firstParty: InvolvedPersonRole[];
    readonly thirdParty: InvolvedPersonRole[];
}

export interface SetExemptMissingDetailsRequest {
    readonly entityPublicId: string;
    readonly reportedByPublicId: string;
    readonly reason: string;
}

export interface ResetExemptMissingDetailsRequest {
    readonly entityPublicId: string;
}

export interface MedicareRiskResponseResponse {
    readonly isReported: boolean;
    readonly reportType: string;
}

export interface RiskReportSentRequest {
    readonly reportName: string;
    readonly entityPublicId: string;
    readonly reportedByPublicId: string;
    readonly isReportSent: boolean;
}

export async function fetchSearchParams(): Promise<CarClaimsSearchParamsResponse> {
    const url = `${BASE_URL}/search_params`;

    return await axios.get<{ data: CarClaimsSearchParamsResponse }>(url).then(response => response.data.data);
}

export async function searchCarClaims(searchParams: Partial<CarClaimsSearchParams>): Promise<CarClaimsSearchResponse> {
    const url = `${BASE_URL}/search`;

    return await axios.post<{ data: CarClaimsSearchResponse }>(url, searchParams).then(response => response.data.data);
}

export interface CarClaimResponse {
    readonly user: CarUser;
    readonly policy: CarPolicy;
    readonly quote: CarQuote;
    readonly address: QuoteAddress;
    readonly timezone: string;
    readonly drivers: Driver[];
    readonly vehicles: Vehicle[];
    readonly claim_coverages: CarCoverages;
    readonly tags: CarClaimTags;
    readonly crash_predictions: CrashPrediction[];
    readonly claim_summaries: CarEventSummary[];
    readonly available_reporter_types: CarClaimReporter[];
    readonly claim_history: ClaimHistory[];
    readonly claim: CarClaim;
    readonly adjusters: SnakeCaseToCamelCase<AdjustersState>;
    readonly emailAdjusters: EmailAdjustersState;
    readonly features: Features;
    readonly claim_actions: CarClaimAction[];
    readonly editable_claim_items: boolean;
    readonly editable_claim_payees: boolean;
    readonly injury_demands: Demand[];
    readonly negotiate_settlements: NegotiateSettlementResponse[];
    readonly creatable_tasks: { readonly [taskType: string]: string[] };
    readonly vendors: CarVendor[];
    readonly glass_status: GlassStatus;
    readonly injury_claims: InjuryClaim[];
    readonly injury_bills: InjuryBill[];
    readonly involved_parties: InvolvedParties;
    readonly claim_mitchell_pd_assignments_status: MitchellPdAssignmentsStatus[];
    readonly roadside_assistance_tow_jobs: RoadsideAssistanceTowJob[];
    readonly subrogation: Subrogation;
    readonly subrogation_logs: SubrogationLog[];
    readonly damage_notifications: DamageNotification[];
    readonly available_car_conditions: AvailableCarConditions;
    readonly claim_negligence_rule: string;
    readonly chat_log: CarClaimChatLog;
    readonly claim_adjuster_fees: CarAdjusterFees;
    readonly workflow: {
        readonly segments: WorkflowSegment<typeof TaskType, typeof TagType, typeof ReferenceType>[];
    };
    readonly claim_reserves_log: CarReserveLog;
    readonly task_assignees: TaskAssignee[];
    readonly first_response: SnakeCaseToCamelCase<FirstResponse>;
    readonly workflows_note_id: string | null;
    readonly communication_timeline: Interaction[];
    readonly payees: SnakeCaseToCamelCase<Payee>[];
    readonly attachments_relations: GroupedAttachmentRelations;
    readonly policy_users: CarUser[];
    readonly policy_pdf_url: string;
    readonly claim_transactions: SnakeCaseToCamelCase<Transaction>[][];
    readonly payment_methods: SnakeCaseToCamelCase<PayeesPayoutMethods>[];
    readonly available_payees_types: SnakeCaseToCamelCase<AvailablePayeeType>[];
    readonly priorities: SnakeCaseToCamelCase<ClaimPriority>[];
    readonly possible_recipients: SnakeCaseToCamelCase<Recipient>[];
    readonly feature_types: PossibleFeature[];
    readonly risk_indicators: CarRiskIndicator[];
    readonly claim_tasks_groups: CarClaimTaskGroup[];
    readonly demandEvaluations: CarDemandEvaluation[];
    readonly attachmentOptionalRelations: AttachmentOptionalRelations;
    readonly litigation_cases: LitigationCase[];
    readonly attachmentsFiltersData: AttachmentsFiltersData;
    readonly last_known_location: LastKnownLocation;
}

export interface CarClaimCollectionResponse {
    readonly involved_person_gender_options: string[];
    readonly vehicle_makes: string[];
    readonly roadside_assistance_stuck_in_types: StuckInType[];
    readonly subrogation_failed_reasons: string[];
    readonly location_countries: LocationCountry;
    readonly roadside_assistance_key_location_types: KeysLocationType[];
    readonly roadside_assistance_service_types: ServiceType[];
    readonly roadside_assistance_fuel_types: FuelType[];
    readonly subrogation_close_reasons: string[];
    readonly involved_vehicle_damage_points: InvolvedVehicleDamagePoints[];
    readonly mitchell_bi_demand_types: string[];
    readonly waive_deductible_reasons: string[];
    readonly mitchell_urls: MitchellUrls;
    readonly item_types: ItemType[];
    readonly payable_parties_types: string[];
    readonly involved_vehicle_owner_types: OwnedBy[];
    readonly insurance_carriers: string[];
    readonly expense_types: string[];
    readonly loss_types: CarClaimLossTypesSubTypeMap;
    readonly cancellation_reasons: string[];
    readonly healthInsuranceTypes: string[];
    readonly biCoverageTypes: BiCoverageTypes;
    readonly medicalProviderTypes: string[];
    readonly demandEvaluationAllowedRolesMap: DemandEvaluationAllowedRolesMap;
    readonly lienProviderTypes: string[];
    readonly litigationTypes: string[];
    readonly courtTypes: string[];
    readonly litigationCauses: string[];
    readonly litigationCountyOptions: string[];
    readonly litigationStages: string[];
    readonly litigationSettlementReasons: string[];
    readonly salvagePickupLocationTypes: string[];
    readonly salvageAssignmentCancellationReasons: string[];
    readonly claimCloseReasons: string[];
    readonly tort_coverage_types: string[];
    readonly trackingMethodOptions: string[];
    readonly reasons_for_manual_rsa: string[];
    readonly metromile_loss_types: string[];
}

export async function fetchCarClaimData<K extends keyof CarClaimResponse>(
    claimId: string,
    keys: K[]
): Promise<Pick<CarClaimResponse, K>> {
    const mapKeys = (key: string) => `include[]=${key}`;
    const url = carBlenderClaimsUrlResolver(`/${claimId}?${encodeURI(keys.map(mapKeys).join('&'))}`);

    return await axios.get<{ data: Pick<CarClaimResponse, K> }>(url).then(response => response.data.data);
}

export async function fetchCarClaimCollectionData<K extends keyof CarClaimCollectionResponse>(
    keys: K[]
): Promise<Pick<CarClaimCollectionResponse, K>> {
    const mapKeys = (key: string) => `include[]=${key}`;
    const url = carBlenderClaimsUrlResolver(`?${encodeURI(keys.map(mapKeys).join('&'))}`);

    return await axios.get<{ data: Pick<CarClaimCollectionResponse, K> }>(url).then(response => response.data.data);
}

export async function markSettled({
    claimId,
    settled,
}: {
    readonly claimId: string;
    readonly settled: boolean;
}): Promise<null> {
    const url = `${CAR_BLENDER_CLAIMS_BASE_URL}/${claimId}/${settled ? 'mark_settled' : 'mark_not_settled'}`;

    return await carBlender.post(url);
}

export async function cancelClaim({
    claimId,
    cancelParams = {},
}: {
    readonly claimId: string;
    readonly cancelParams?: CancelCarClaimParams;
}): Promise<null> {
    const url = `${CAR_BLENDER_CLAIMS_BASE_URL}/${claimId}/cancel`;

    return await carBlender.post(url, cancelParams);
}

export async function editClaim({
    claimId,
    claim: { publicId, ...otherFields },
}: {
    readonly claimId: string;
    readonly claim: Partial<CarClaim>;
}): Promise<null> {
    const url = `${CAR_BLENDER_CLAIMS_BASE_URL}/${claimId}/update`;

    return await carBlender.patch(url, otherFields);
}

export async function closeClaim({
    claimId,
    closeParams,
}: {
    readonly claimId: string;
    readonly closeParams: CloseCarClaimParams;
}): Promise<null> {
    const url = `${CAR_BLENDER_CLAIMS_BASE_URL}/${claimId}/close`;

    return await carBlender.post(url, closeParams);
}

export async function reopenClaim({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = `${CAR_BLENDER_CLAIMS_BASE_URL}/${claimId}/reopen`;

    return await carBlender.post(url);
}

export type ItemToAdd = {
    readonly featureName: FeatureName;
    readonly cost: number;
    readonly description?: string;
    readonly referenceId?: string;
} & (ItemToAddExpenseType | ItemToAddLossType) &
    (ItemToAddPerson | ItemToAddVehicle);

export type ItemToAddPerson = {
    readonly involvedPersonPublicId: string;
};

export type ItemToAddVehicle = {
    readonly involvedVehiclePublicId: string;
};

type ItemToAddLossType = {
    readonly itemType: ItemType.Loss;
    readonly lossItemType: LossItemType;
};

type ItemToAddExpenseType = {
    readonly itemType: ItemType.Expense;
    readonly expenseType: string;
};

type ItemToUpdateBase = {
    readonly publicId: string;
    readonly cost: number;
    readonly referenceId?: string;
    readonly description?: string;
};

type LossItemToUpdate = ItemToUpdateBase & { readonly lossItemType: string };

type ExpenseItemToUpdate = ItemToUpdateBase & { readonly expenseType: string };

export type ItemToUpdate = ExpenseItemToUpdate | LossItemToUpdate;

export async function addItem({
    claimId,
    item,
}: {
    readonly claimId: string;
    readonly item: ItemToAdd;
}): Promise<void> {
    const url = carBlenderClaimsUrlResolver(`/${claimId}/items`);

    await axios.post(url, item);
}

export async function editItem({
    claimId,
    item,
}: {
    readonly claimId: string;
    readonly item: ItemToUpdate;
}): Promise<void> {
    const { publicId: itemPublicId, ...body } = item;
    const url = carBlenderClaimsUrlResolver(`/${claimId}/items/${itemPublicId}`);

    await axios.patch(url, body);
}

export async function rejectItems({
    claimId,
    itemsIds,
    reason,
}: {
    readonly claimId: string;
    readonly itemsIds: string[];
    readonly reason: string;
}): Promise<void> {
    const url = carBlenderClaimsUrlResolver(`/${claimId}/items/reject`);

    await axios.post(url, { itemPublicIds: itemsIds, rejectReason: reason });
}

export async function activateItems({
    claimId,
    itemsIds,
}: {
    readonly claimId: string;
    readonly itemsIds: string[];
}): Promise<void> {
    const url = await carBlenderClaimsUrlResolver(`/${claimId}/items/activate`);

    await axios.post(url, { itemPublicIds: itemsIds });
}

export async function sendEmail(args: SendEmailArgsWithEntityId): Promise<void> {
    await sendEmailResolver(args, BASE_URL);
}

export async function cancelFirstResponse({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = blenderGeneralClaimsUrlResolver(`/${claimId}/first_response/cancel`);

    return await axios.post(url, CURRENT_PRODUCT_PARAMS);
}

export async function sendFirstResponse({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = blenderGeneralClaimsUrlResolver(`/${claimId}/first_response/dispatch`);

    return await axios.post(url, CURRENT_PRODUCT_PARAMS);
}

export async function addCustomRecipient({
    recipient,
    claimId,
}: {
    readonly recipient: Pick<Recipient, 'email' | 'name' | 'role'>;
    readonly claimId: string;
}): Promise<Recipient> {
    return await addCustomRecipientGeneric(recipient, EntityTypes.CarClaim, claimId);
}

export async function assignInteraction({
    interactionPublicId,
    claimId,
}: {
    readonly interactionPublicId: string;
    readonly claimId: string;
}): Promise<null> {
    return await assignInteractionGeneric({ entityType: EntityTypes.CarClaim, entityId: claimId, interactionPublicId });
}

export async function dismissInteraction({
    interactionPublicId,
    claimId,
}: {
    readonly interactionPublicId: string;
    readonly claimId: string;
}): Promise<null> {
    return await dismissInteractionGeneric({
        entityType: EntityTypes.CarClaim,
        entityId: claimId,
        interactionPublicId,
    });
}

export async function manualResponseInteraction({
    interactionPublicId,
    claimId,
    medium,
    body,
}: {
    readonly interactionPublicId: string;
    readonly claimId: string;
    readonly medium: string;
    readonly body: string;
}): Promise<null> {
    return await manualResponseInteractionGeneric({
        entityType: EntityTypes.CarClaim,
        entityId: claimId,
        medium,
        body,
        interactionPublicId,
    });
}

export async function createNote({ note }: { readonly note: NoteCreateRequest }): Promise<null> {
    return await createNoteGeneric({ note });
}

export async function fetchTaskGroupNotes({
    groupPublicId,
}: {
    readonly groupPublicId: string;
}): Promise<Interaction[]> {
    const url = carBlenderGroupNotesUrlResolver(`?groupPublicId=${groupPublicId}`);

    return await axios.get(url).then(response => response.data.data);
}

export async function createTaskGroupNote({
    groupPublicId,
    note,
}: {
    readonly groupPublicId: string;
    readonly note: CreateTaskGroupNoteParams;
}): Promise<null> {
    const url = carBlenderGroupNotesUrlResolver(`/${groupPublicId}`);

    return await axios.post(url, note).then(response => response.data.data);
}

type ItemNotPayableResponse = ItemNotPayable & { readonly payable: false };

type ItemPayableResponse = ItemPayable & { readonly payable: true };

export type AreItemsPayableResponse = ItemNotPayableResponse | ItemPayableResponse;

export async function areItemsPayable({
    claimId,
    itemsIds,
}: {
    readonly claimId: string;
    readonly itemsIds: string[];
}): Promise<AreItemsPayableResponse> {
    const mapKeys = (key: string) => `itemPublicIds[]=${key}`;
    const url = carBlenderClaimsUrlResolver(
        `/${claimId}/check_payable_items?${encodeURI(itemsIds.map(mapKeys).join('&'))}`
    );

    return await axios.get<AreItemsPayableResponse>(url).then(response => response.data);
}

type ReportedByInsuredParams = {
    readonly submitting_user_public_id?: string;
};

type ReportedByDriverParams = {
    readonly submitting_driver_public_id?: string;
};

type ReportedByOtherParams = {
    readonly submission_additional_info?: string;
};

type ReportedByClaimantParams = ReportedByOtherParams & {
    readonly submitting_claimant_name?: string;
    readonly submission_additional_info?: string;
};

type ReportedByAttorneyParams = ReportedByClaimantParams & {
    readonly submitting_attorney_name?: string;
};

type ReportedByCarrierParams = ReportedByClaimantParams & {
    readonly submitting_carrier?: string;
};

export type ReportedByParams =
    | ReportedByAttorneyParams
    | ReportedByCarrierParams
    | ReportedByClaimantParams
    | ReportedByDriverParams
    | ReportedByInsuredParams
    | ReportedByOtherParams;

export type ReportedByRSAParams = ReportedByDriverParams | ReportedByInsuredParams;

export type ManualClaimToCreate = {
    readonly loss_type: CarClaimLossTypes;
    readonly loss_sub_type: CarClaimLossSubTypes | null;
    readonly incident_date: string;
    readonly policy_public_id: string;
    readonly description?: string;
    readonly billing_token?: string;
    readonly carrier?: string;
    // eslint-disable-next-line functional/prefer-readonly-type
    loss_type_additional_info?: string;
    // eslint-disable-next-line functional/prefer-readonly-type
    reported_at_the_scene?: boolean;
    // eslint-disable-next-line functional/prefer-readonly-type
    incident_location?: CamelCaseToSnakeCase<Location>;
    readonly color?: string;
    readonly primary_insured_public_id?: string;
    readonly submission_type?: ReportedByType;
    readonly submitting_user_public_id?: string;
    readonly submitting_driver_public_id?: string;
    readonly submitting_attorney_name?: string;
    readonly submitting_claimant_name?: string;
    readonly submitting_carrier?: string;
    readonly submission_additional_info?: string;
    readonly original_pending_claim_public_id?: string;
    readonly original_involved_vehicle_public_id?: string;
    readonly original_involved_person_public_id?: string;
    // eslint-disable-next-line functional/prefer-readonly-type
    child_seat_status?: ChildSeatStatus | null;
} & (
    | ({
          readonly contact_person_phone_number?: string;
      } & ({ readonly contact_person_full_name?: string } | { readonly driver_public_id?: string }))
    | ({ readonly driver_phone_number?: string | null } & (
          | { readonly driver_full_name?: string }
          | { readonly driver_public_id?: string }
      ))
) &
    (
        | {
              readonly damages: true;
              readonly insured_car_damages: boolean;
              readonly other_cars_damages: boolean;
              readonly other_damages: boolean;
          }
        | { readonly damages: false }
    ) &
    (
        | {
              readonly injuries: true;
              readonly first_party_driver_injured: boolean;
              readonly first_party_passenger_injured: boolean;
              readonly third_party_driver_or_passenger_injured: boolean;
              readonly third_party_cyclist_or_pedestrian_injured: boolean;
          }
        | { readonly injuries: false }
    ) &
    (
        | { readonly car_public_id?: string }
        | { readonly make: string; readonly model?: string; readonly year?: number; readonly license_plate?: string }
    );

export async function createManualClaim(manualClaim: ManualClaimToCreate): Promise<CamelCaseToSnakeCase<CarClaim>> {
    const url = `${BASE_URL}/manual_claim`;

    return await axios
        .post<{ data: CamelCaseToSnakeCase<CarClaim> }>(url, manualClaim)
        .then(response => response.data.data);
}

export async function getPaymentInfo({
    claimId,
    itemIds,
    payeeId,
    ...rest
}: {
    readonly claimId: string;
    readonly itemIds: string[];
    readonly payeeId: string;
} & (
    | { readonly waiveDeductible: false }
    | { readonly waiveDeductible: true; readonly waiveReason: string }
)): Promise<PaymentInfo> {
    const url = `${BASE_URL}/${claimId}/payment_info`;

    return await axios
        .post<{ data: PaymentInfo }>(url, {
            claim_id: claimId,
            item_ids: itemIds,
            payee_id: payeeId,
            waive_deductible: rest.waiveDeductible,
            ...(rest.waiveDeductible ? { waive_deductible_reason: rest.waiveReason } : null),
        })
        .then(response => response.data.data);
}

export async function startHandlingClaim({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = carBlenderClaimsUrlResolver(`/${claimId}/start_handling`);

    return await axios.post(url);
}

export async function submitClaim({ claimPublicId }: { readonly claimPublicId: string }): Promise<void> {
    const url = carBlenderClaimsUrlResolver(`/${claimPublicId}/submit`);

    await axios.post(url);
}

export type InvolvedPersonBase = Omit<
    InvolvedPerson,
    'firstParty' | 'injured' | 'minor' | 'publicId' | 'ssnLastFour' | 'ssnLastFourOfLastFive' | 'type'
> & {
    readonly ssn?: string | null;
    readonly ssnLastFiveDigits?: string | null;
    readonly injured?: boolean;
    readonly firstParty?: boolean;
};
export type InvolvedPersonToAdd = Omit<InvolvedPersonBase, 'emergencyEvacuation'> & {
    readonly evacuatedByEmergencyServices?: boolean;
};
export type InvolvedPersonToUpdate = Partial<Omit<InvolvedPersonBase, 'emergencyEvacuation'>> & {
    readonly publicId: string;
    // eslint-disable-next-line functional/prefer-readonly-type
    evacuatedByEmergencyServices?: boolean;
};

export type InvolvedPersonLiabilityToUpdate = Pick<
    InvolvedPerson,
    | 'breachCausedDamages'
    | 'breachOfDuties'
    | 'failedRulesOfTheRoad'
    | 'incidentStatement'
    | 'incidentStatementDate'
    | 'liabilityPercentage'
    | 'publicId'
    | 'unreachableByPhone'
>;

export async function getInvolvedPeople({
    claimPublicId,
}: {
    readonly claimPublicId: string;
}): Promise<InvolvedPerson[]> {
    const url = carBlenderInvolvedPeopleUrlResolver(`?claimPublicId=${claimPublicId}`);

    return await axios.get<{ data: InvolvedPerson[] }>(url).then(response => response.data.data);
}

export async function addInvolvedPerson({
    claimPublicId,
    involvedPerson,
}: {
    readonly claimPublicId: string;
    readonly involvedPerson: InvolvedPersonToAdd;
}): Promise<null> {
    const url = carBlenderInvolvedPeopleUrlResolver(`?claimPublicId=${claimPublicId}`);

    return await axios.post(url, involvedPerson);
}

export async function updateInvolvedPerson({
    involvedPersonToUpdate,
}: {
    readonly involvedPersonToUpdate: InvolvedPersonToUpdate;
}): Promise<null> {
    const url = carBlenderInvolvedPeopleUrlResolver(`/${involvedPersonToUpdate.publicId}`);

    return await axios.patch(url, involvedPersonToUpdate);
}

export type UpdateNJAIREUpdateParams = Writeable<
    Pick<
        InjuryInsuranceDetails,
        | 'additionalLossesIncurred'
        | 'claimantFamilyHasPipPolicy'
        | 'claimantHasPipPolicy'
        | 'confirmedPenaltyAdded'
        | 'fullOrLimitedTortCoverage'
        | 'lossesAdditionalInfo'
    >
>;

export async function updateInvolvedPersonInjuryInsuranceDetails({
    involvedPersonPublicId,
    operatorId,
    injuryInsuranceDetails,
}: {
    readonly involvedPersonPublicId: string;
    readonly operatorId?: string;
    readonly injuryInsuranceDetails: Partial<InjuryInsuranceDetails>;
}): Promise<null> {
    const url = carBlenderInvolvedPeopleUrlResolver(
        `/${involvedPersonPublicId}/injury_insurance_details${operatorId != null ? `?operatorId=${operatorId}` : ''}`
    );

    return await axios.patch(url, injuryInsuranceDetails);
}

export async function updateInvolvedPersonLiability({
    involvedPerson,
}: {
    readonly involvedPerson: InvolvedPersonLiabilityToUpdate;
}): Promise<null> {
    const url = carBlenderInvolvedPeopleUrlResolver(`/${involvedPerson.publicId}`);

    return await axios.patch(url, involvedPerson).then(response => response.data.data);
}

export type InvolvedVehicleToAdd = Omit<InvolvedVehicle, 'damaged' | 'publicId' | 'type'> & {
    readonly damaged?: boolean;
};

export type InvolvedVehicleToUpdate = Partial<Omit<InvolvedVehicle, 'type'>> & { readonly publicId: string };

export async function addInvolvedVehicle({
    claimPublicId,
    involvedVehicle,
}: {
    readonly claimPublicId: string;
    readonly involvedVehicle: InvolvedVehicleToAdd;
}): Promise<void> {
    const url = carBlenderInvolvedVehiclesUrlResolver(`?claimPublicId=${claimPublicId}`);

    await axios.post(url, involvedVehicle);
}

export async function updateInvolvedVehicle({
    involvedVehicle,
}: {
    readonly involvedVehicle: InvolvedVehicleToUpdate;
}): Promise<null> {
    const url = carBlenderInvolvedVehiclesUrlResolver(`/${involvedVehicle.publicId}`);

    return await axios.patch(url, involvedVehicle);
}

export type ClaimLiabilityFieldsToUpdate = {
    readonly evidenceCollected: boolean;
    readonly evidenceSupportsLosses: boolean;
    readonly evidenceCredible: boolean;
};

export async function getInvolvedVehicleEstimatedTotalLoss({
    involvedVehiclePublicId,
}: {
    readonly involvedVehiclePublicId: string;
}): Promise<InvolvedVehicleEstimatedTotalLossResponse> {
    const url = carBlenderInvolvedVehiclesUrlResolver(`/${involvedVehiclePublicId}/estimated_total_loss`);

    return await axios.get(url).then(response => response.data.data);
}

export async function createRoadsideAssistanceTowJob({
    roadsideAssistanceTowJobParams,
}: {
    readonly roadsideAssistanceTowJobParams: RoadsideAssistanceTowJobToCreate;
}): Promise<RoadsideAssistanceTowJob> {
    const url = carBlenderRSATowJobResolver('');

    const params = snakeToCamelCaseKeys(roadsideAssistanceTowJobParams as unknown as Record<string, unknown>);

    const response = await axios.post(url, params);

    return response.data.data;
}

export async function editRoadsideAssistanceTowJob({
    jobPublicId,
    params,
}: {
    readonly jobPublicId: string;
    readonly params: RoadsideAssistanceTowJobToEdit;
}): Promise<RoadsideAssistanceTowJob> {
    const url = carBlenderRSATowJobResolver(`/${jobPublicId}`);

    const response = await axios.patch(url, params);

    return response.data.data;
}

export async function dispatchRoadsideAssistanceTowJob({
    jobPublicId,
}: {
    readonly jobPublicId: string;
}): Promise<RoadsideAssistanceTowJob> {
    const url = carBlenderRSATowJobResolver(`/${jobPublicId}/dispatch`);

    const response = await axios.post(url);

    return response.data.data;
}

export async function getGlassCoverage({
    vehiclePublicId,
    policyPublicId,
    incidentDate,
    country,
    state,
}: {
    readonly vehiclePublicId: string;
    readonly policyPublicId: string;
    readonly incidentDate: string;
    readonly country?: string;
    readonly state?: string;
}): Promise<GlassCoverage> {
    const url = `${BASE_URL}/glass_coverage`;

    const params = {
        policy_public_id: policyPublicId,
        vehicle_public_id: vehiclePublicId,
        incident_date: incidentDate,
        country,
        state,
    };

    return await axios.get(url, { params }).then(response => response.data);
}

export async function cancelRoadsideAssistanceTowJob({
    jobPublicId,
}: {
    readonly jobPublicId: string;
}): Promise<RoadsideAssistanceTowJob> {
    const url = carBlenderRSATowJobResolver(`/${jobPublicId}/cancel`);

    const response = await axios.post(url);

    return await response.data.data;
}

export async function cancelAutoDecline({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = `${CAR_BLENDER_CLAIMS_BASE_URL}/${claimId}/cancel_auto_decline`;

    return await carBlender.post(url, { product_type: ProductTypes.Car }).then(response => response.data.data);
}

export async function simulateAutoDecline({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = `${CAR_BLENDER_CLAIMS_BASE_URL}/${claimId}/simulate_auto_decline`;

    return await carBlender.post(url).then(response => response.data.data);
}

export async function getModelsByMake(make: string): Promise<string[]> {
    const url = carBlenderVehicleTypesUrlResolver(`/models?make=${make}`);

    return await axios.get<{ data: string[] }>(url).then(response => response.data.data);
}

export async function createEventSummary({
    claimId,
    type,
    content,
}: {
    readonly claimId: string;
    readonly type: CarEventSummaryType;
    readonly content: string;
}): Promise<void> {
    const url = carBlenderClaimsUrlResolver(`/${claimId}/summaries`);

    const body = {
        type,
        content,
    };

    await axios.post(url, body).then(response => response.data.data);
}

export async function editEventSummary({
    claimId,
    eventSummaryId,
    type,
    content,
}: {
    readonly claimId: string;
    readonly eventSummaryId: string;
    readonly type: CarEventSummaryType;
    readonly content: string;
}): Promise<void> {
    const url = carBlenderClaimsUrlResolver(`/${claimId}/summaries/${eventSummaryId}`);

    const body = {
        type,
        content,
    };

    await axios.patch(url, body).then(response => response.data.data);
}

export async function submitEventSummary({
    claimId,
    eventSummaryId,
}: {
    readonly claimId: string;
    readonly eventSummaryId: string;
}): Promise<void> {
    const url = carBlenderClaimsUrlResolver(`/${claimId}/summaries/${eventSummaryId}/submit`);

    await axios.post(url).then(response => response.data.data);
}

export type PaymentDetailsIncludeKeysResponse<K extends keyof PaymentRequestDetailsResponse> = {
    readonly data: Pick<PaymentRequestDetailsResponse, K>;
};

export async function fetchPaymentRequestIncludeKeys<K extends keyof PaymentRequestDetailsResponse>(
    publicId: string,
    keys: K[]
): Promise<PaymentDetailsIncludeKeysResponse<K>> {
    const url = stringifyUrl(
        {
            url: carBlenderPaymentControlUrlResolver(`/payment_request/${publicId}`),
            query: {
                include: keys,
            },
        },
        { arrayFormat: 'comma' }
    );

    return await axios.get(url).then(response => response.data.data);
}

export async function fetchPaymentRequestData<K extends keyof PaymentRequestDetailsResponse>(
    publicId: string,
    keys: K[]
): Promise<Pick<PaymentRequestDetailsResponse, K>> {
    return (await fetchPaymentRequestIncludeKeys(publicId, keys)).data;
}

export async function getAuthorityRequestDetails(requestPublicID: string): Promise<PaymentRequestDetailsResponse> {
    return await fetchPaymentRequestData(requestPublicID, [
        'payment_request_details',
        'payment_breakdown',
        'payment_control_permissions',
        'payment_request',
    ]);
}

export async function fetchCarClaimSectionsForExport(): Promise<CarClaimPdfExport> {
    const url = getUrlResolver('car-blender', '/api/v1/exports/sections')();

    return await axios.get(url).then(response => response.data);
}

export async function fetchClaimPdfFile(claimId: string, sections: string[]): Promise<string> {
    const exportUrl = getUrlResolver('car-blender', '/api/v1/exports')();

    return await axios
        .post(exportUrl, { entityType: ExportEntityType.CarClaim, entityId: claimId, sections })
        .then(response => response.data);
}

export async function setExemptMissingDetails({
    request,
}: {
    readonly request: SetExemptMissingDetailsRequest;
}): Promise<void> {
    const url = carBlenderMedicareManagementUrlResolver('/exempt_missing_details');

    await axios.post(url, request);
}

export async function resetExemptMissingDetails({
    request,
}: {
    readonly request: ResetExemptMissingDetailsRequest;
}): Promise<void> {
    const url = carBlenderMedicareManagementUrlResolver('/exempt_missing_details/reset');

    await axios.post(url, request);
}

export async function getMedicareRiskReport(
    involvedPersonPublicId: string,
    reportType: string
): Promise<MedicareRiskResponseResponse> {
    const url = carBlenderMedicareRiskReportResolver(
        `?involvedPersonPublicId=${involvedPersonPublicId}&reportType=${reportType}`
    );

    return await axios.get<{ data: MedicareRiskResponseResponse }>(url).then(response => response.data.data);
}

export async function setRiskReportSent({ request }: { readonly request: RiskReportSentRequest }): Promise<void> {
    const url = carBlenderMedicareManagementUrlResolver('/set_risk_report_sent');

    await axios.post(url, request);
}
