import { Box } from '@lemonade-hq/cdk';
import React from 'react';

/**
 * @deprecated
 * use PageWrapper from '@lemonade-hq/bluis' instead
 */
const PageWrapper: React.FC<React.PropsWithChildren<{ readonly className?: string }>> = ({ children, className }) => (
    <Box className={className} maxWidth={1480} mt={20} mx="auto" padding="0 20px">
        {children}
    </Box>
);

export default PageWrapper;
