import { getUrlResolver, ServiceName } from '@lemonade-hq/bluiza';
import axios from 'axios';
import { PaymentProviderType } from 'models/Payment';

export interface PayInvoice {
    readonly invoiceId: string;
    readonly providerType: PaymentProviderType;
}

export async function payInvoice({ invoiceId, providerType: provider_type = PaymentProviderType.Stripe }: PayInvoice) {
    const url = `/backoffice/payouts/${invoiceId}/pay`;

    const response = await axios.post(url, { provider_type });

    return response.data;
}

export async function cancelInvoice({ invoiceId }: { readonly invoiceId: string }): Promise<void> {
    const url = `/backoffice/billing/invoices/${invoiceId}/cancel`;

    await axios.post(url);
}

export async function cancelTransaction({
    transactionId,
    reason,
}: {
    readonly transactionId: string;
    readonly reason?: string;
}) {
    const url = `/backoffice/payout_transactions/${transactionId}/cancel`;

    const response = await axios.post(url, { reason });

    return response.data;
}

export async function getSimulationPayoutsErrorReasons(
    paymentMethodType: string
): Promise<{ readonly code: string; readonly message: string }[]> {
    const url = `/backoffice/payout_transactions/simulate_payout_failure_reasons?payment_method_type=${paymentMethodType}`;

    const response = await axios.get<{ data: { code: string; message: string }[] }>(url);

    return response.data.data;
}

export async function simulatePayoutSuccess({ transactionId }: { readonly transactionId: string }): Promise<null> {
    const url = `/backoffice/payout_transactions/${transactionId}/simulate_payout_success`;

    const response = await axios.post(url);

    return response.data;
}

export async function simulatePayoutFailure({
    transactionId,
    paymentMethodType,
    errorCode,
}: {
    readonly transactionId: string;
    readonly errorCode: string;
    readonly paymentMethodType: string;
}): Promise<null> {
    const url = `/backoffice/payout_transactions/${transactionId}/simulate_payout_failure`;

    const response = await axios.post(url, { error_code: errorCode, payment_method_type: paymentMethodType });

    return response.data;
}

const blenderGeneralUrlResolver = getUrlResolver({ service: ServiceName.BlenderGeneral, fallbackMap: new Set() });

export async function cancelPayment(invoiceId: string): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v1/payouts/${invoiceId}/cancel`);

    const response = await axios.post(url);

    return response.data;
}

export interface PayoutDuplicate {
    readonly date: string;
    readonly invoicePublicId: string;
}

export async function getPayoutDuplicates(invoiceId: string): Promise<PayoutDuplicate[]> {
    const url = blenderGeneralUrlResolver(`/api/v1/payouts/duplicates?invoicePublicId=${invoiceId}`);

    const response = await axios.get(url);

    return response.data;
}
