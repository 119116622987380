import type { ReactElement, ReactNode } from 'react';
import type { SelectOptionBase } from './Select';
import { SelectButtonChip } from './SelectButtonChip';

interface ChipsProps<TOption extends SelectOptionBase> {
  readonly options: TOption[];
  readonly selectedValueTemplate?: (selectedValue: ReactNode) => JSX.Element;
  readonly onDeselect?: (value: TOption['value']) => void;
  readonly maxAmountOfChips?: number;
  readonly disabled?: boolean;
}

export const SelectChips = <TOption extends SelectOptionBase>({
  selectedValueTemplate,
  options,
  onDeselect,
  disabled,
  maxAmountOfChips = 4,
}: ChipsProps<TOption>): ReactElement => {
  if (options.length < maxAmountOfChips) {
    return (
      <>
        {options.map(({ label, value }) => (
          <SelectButtonChip
            disabled={disabled}
            key={value}
            label={selectedValueTemplate ? selectedValueTemplate(label) : label}
            onDelete={onDeselect ? () => onDeselect(value) : undefined}
            unlimitedWidth={maxAmountOfChips === Infinity}
            value={value}
          />
        ))}
      </>
    );
  }

  const [{ label, value }] = options;

  return (
    <>
      <SelectButtonChip
        isCounter
        label={`+${options.length - 1}`}
        tooltipLabel={options
          .slice(1)
          .map(option => option.label)
          .join(', ')}
      ></SelectButtonChip>
      <SelectButtonChip
        label={selectedValueTemplate ? selectedValueTemplate(label) : label}
        onDelete={onDeselect ? () => onDeselect(value) : undefined}
        value={value}
      ></SelectButtonChip>
    </>
  );
};
