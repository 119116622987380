/* eslint-disable @lemonade-hq/frontend/jsx-no-explicit-props-spread */
import { Combobox, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { randomUuid, wrap } from '@lemonade-hq/ts-helpers';
import { clsx } from 'clsx';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from '../../base/Flex/Flex';
import * as listStyles from '../../theme/select.css';
import type { SelectionMode } from '../../theme/selection';
import { useQuickFuzzyFilter } from '../../utils/fuzzy';
import type { Variants as CheckboxVariants } from '../Checkbox/Checkbox';
import type { IconName } from '../Icon/types';
import type { ListItemProps } from '../ListItem/ListItem';
import { ListItem } from '../ListItem/ListItem';
import * as listItemStyles from '../ListItem/ListItem.css';
import * as styles from './ComboBox.css';
import { ComboBoxTrigger } from './ComboBoxTrigger';
import { getAnchorNameFromId } from './utils';

export type ComboboxValue<TMode extends SelectionMode, TItem extends ComboBoxItem> =
  NoInfer<TMode> extends 'single' ? TItem : TItem[];
export interface ComboBoxItem {
  readonly value: string;
  readonly label: string;
  readonly icon?: IconName;
  readonly customIcon?: JSX.Element;
  readonly disabled?: boolean;
  readonly render?: React.ComponentType<ListItemProps>;
  readonly customValue?: boolean;
}

export interface ComboBoxProps<TMode extends SelectionMode = 'single', TItem extends ComboBoxItem = ComboBoxItem> {
  readonly items: TItem[];
  readonly disabled?: boolean;
  readonly onSelectionChange: (item: (NoInfer<TMode> extends 'single' ? TItem : TItem[]) | null) => void;
  readonly defaultValue?: NoInfer<TMode> extends 'single' ? TItem['value'] : TItem['value'][];
  readonly value?: (NoInfer<TMode> extends 'single' ? TItem['value'] : TItem['value'][]) | null;
  readonly variant?: CheckboxVariants;
  readonly hasError?: boolean;
  readonly placeholder?: string;
  readonly className?: string;
  readonly optionsClassName?: string;
  readonly showIcon?: boolean;
  readonly allowCustomValue?: boolean;
  readonly cancelable?: boolean;
  readonly mode?: TMode;
  readonly optionItemAs?: React.ComponentType<ListItemProps>;
  readonly maxAmountOfChips?: number;
  readonly spreadChipsMode?: boolean;
  readonly popoverWidthRem?: number;
  readonly optionsBoxPositioningStrategy?: 'anchor' | 'events' | 'requestAnimationFrame';
  readonly portal?: boolean;
}

function getItemLabel<TItem extends ComboBoxItem>(item: TItem): string {
  return item.label;
}
const ComboBoxListItem = <TItem extends ComboBoxItem>({
  item,
  mode,
  checked,
  variant,
  // we need to pass the rest of the props to the ComboboxOption
  // because additional props are passed for virtualization
  ...props
}: {
  readonly item: TItem;
  readonly mode: SelectionMode;
  readonly checked: boolean;
  readonly variant?: CheckboxVariants;
}): ReactNode => {
  return (
    <ComboboxOption
      as={item.render ?? ListItem}
      checked={mode === 'multiple' ? checked : undefined}
      icon={item.icon}
      id={item.value}
      key={item.value}
      label={item.label}
      value={item}
      variant={variant}
      {...props}
    />
  );
};

export const ComboBox = <TMode extends SelectionMode = 'single', TItem extends ComboBoxItem = ComboBoxItem>(
  props: ComboBoxProps<TMode, TItem>,
): ReactNode => {
  const {
    items,
    onSelectionChange,
    defaultValue,
    value,
    disabled,
    optionsClassName,
    allowCustomValue = false,
    mode = 'single',
    variant = 'neutral',
    popoverWidthRem,
    optionsBoxPositioningStrategy = 'anchor',
    portal = true,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const comboboxId = useRef(randomUuid());

  const [isOverflown, setIsOverflown] = useState(false);
  const [selectedItemsIds, setSelectedItemIds] = useState<Set<TItem['value']>>(
    defaultValue != null ? new Set<TItem['value']>(wrap(defaultValue)) : new Set<TItem['value']>([]),
  );

  const [customItems, setCustomItems] = useState<TItem[]>((): TItem[] => {
    const fromDefaultValue =
      defaultValue == null || defaultValue.length === 0
        ? []
        : wrap(defaultValue)
            .filter(dv => !items.map(i => i.value).includes(dv))
            .map(i => ({ label: i, value: i }) as TItem);

    const diff =
      value !== null && value !== undefined
        ? difference(
            wrap(value),
            items.map(i => i.value),
          ).map(i => ({ label: i, value: i }) as TItem)
        : [];

    return [...fromDefaultValue, ...diff];
  });

  const [query, setQuery] = useState('');

  const onSelection = useCallback(
    (item: ComboboxValue<TMode, TItem> | null) => {
      setSelectedItemIds(item == null ? new Set() : new Set(wrap(item).map(i => i.value)));
      setCustomItems(val =>
        uniqBy(
          [...val, ...(isEmpty(item) ? [] : wrap(item).filter(i => !items.map(it => it.value).includes(i.value)))],
          'value',
        ),
      );
      onSelectionChange(item);
      setQuery('');
    },
    [onSelectionChange, items],
  );

  const controlledValue = useMemo(
    () => (value !== undefined ? new Set<TItem['value']>(value !== null ? wrap(value) : undefined) : value),
    [value],
  );

  const selectedItems = useMemo(
    () =>
      [...items, ...customItems].filter(item =>
        controlledValue == null ? selectedItemsIds.has(item.value) : controlledValue.has(item.value),
      ),
    [items, customItems, controlledValue, selectedItemsIds],
  );
  const firstSelectItemForSingleMode = (selectedItems[0] ?? null) as TItem | null;

  const mergedItems = useMemo(
    () => (allowCustomValue ? [...customItems, ...items] : items),
    [allowCustomValue, customItems, items],
  );
  const filteredItems = useQuickFuzzyFilter(query, mergedItems, getItemLabel);

  const showCustomValueOption = useMemo(
    () => allowCustomValue && query.length > 0 && !items.some(item => item.label.toLowerCase() === query.toLowerCase()),
    [allowCustomValue, items, query],
  );

  useEffect(() => {
    const inputElement = inputRef.current;
    if (!inputElement) return;

    const myObserver = new MutationObserver(() => {
      setIsOverflown(inputElement.scrollWidth > inputElement.clientWidth);
    });
    myObserver.observe(inputElement, { attributes: true, attributeFilter: ['value'] });
  }, []);

  const anchorPositionStyles = useMemo(
    () =>
      optionsBoxPositioningStrategy === 'anchor' || !portal
        ? {
            positionAnchor: getAnchorNameFromId(comboboxId.current),
            top: `calc(anchor(bottom) + 0.4rem)`,
            left: 'anchor(left)',
          }
        : undefined,
    [optionsBoxPositioningStrategy, portal],
  );
  const virtualize = !allowCustomValue && process.env.NODE_ENV !== 'test'; // virtualization doesn't work in jsdom

  return (
    <Combobox<TItem | null>
      disabled={disabled}
      immediate
      // @ts-expect-error: due to hard inference problem with headless-ui, since all our values here are dynamic
      multiple={mode === 'multiple'}
      onChange={onSelection as (value: NoInfer<TItem | null> | null) => void}
      value={(mode === 'multiple' ? selectedItems : firstSelectItemForSingleMode) as TItem}
      virtual={virtualize ? { options: filteredItems } : undefined}
    >
      <Flex
        flexDirection="column"
        position="relative"
        {...{ [styles.MODE_DATA_ATTRIBUTE]: mode, [styles.SELECTED_ITEMS_DATA_ATTRIBUTE]: selectedItems.length }}
      >
        <ComboBoxTrigger<TMode, TItem>
          comboboxId={comboboxId.current}
          firstSelectItemForSingleMode={firstSelectItemForSingleMode}
          inputRef={inputRef}
          isOverflown={isOverflown}
          onSelection={onSelection}
          selectedItems={selectedItems}
          setQuery={setQuery}
          {...props}
        />
        <ComboboxOptions
          className={clsx(
            listStyles.listBox,
            listStyles.popover({
              positioning:
                optionsBoxPositioningStrategy === 'anchor' ? 'portalWithAnchor' : portal ? 'portal' : 'noPortal',
            }),
            optionsClassName,
          )}
          portal={portal}
          {...{ [listStyles.EMPTY_MESSAGE_DATA_ATTRIBUTE]: 'No results found' }}
          style={{
            ...anchorPositionStyles,
            width: popoverWidthRem !== undefined ? `${popoverWidthRem}rem` : `anchor-size(width)`,
          }}
        >
          {virtualize ? (
            // virtualize options only when custom options are not allowed
            ({ option: item }: { option: TItem }) => (
              <ComboBoxListItem
                checked={controlledValue == null ? selectedItemsIds.has(item.value) : controlledValue.has(item.value)}
                item={item}
                mode={mode}
                variant={variant}
              />
            )
          ) : (
            <>
              {showCustomValueOption && (
                <ComboboxOption<'div', ComboBoxItem>
                  className={listItemStyles.listItem}
                  value={{ value: query, label: query, customValue: true }}
                >
                  Create <span className="font-bold">{query}</span>
                </ComboboxOption>
              )}
              {filteredItems.map(item => (
                <ComboBoxListItem
                  checked={controlledValue == null ? selectedItemsIds.has(item.value) : controlledValue.has(item.value)}
                  item={item}
                  key={item.value}
                  mode={mode}
                  variant={variant}
                />
              ))}
            </>
          )}
        </ComboboxOptions>
      </Flex>
    </Combobox>
  );
};
