import { ComboboxButton, ComboboxInput } from '@headlessui/react';
import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { Fragment } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { input as inputStyles } from '../../theme/input.css';
import * as listStyles from '../../theme/select.css';
import type { SelectionMode } from '../../theme/selection';
import { spacing } from '../../theme/spacing.css';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';
import * as listItemStyles from '../ListItem/ListItem.css';
import { SelectChips } from '../Select/SelectChips';
import { Tooltip } from '../Tooltip/Tooltip';
import type { ComboBoxItem, ComboBoxProps, ComboboxValue } from './ComboBox';
import * as styles from './ComboBox.css';

interface ComboBoxTriggerProps<TMode extends SelectionMode, TItem extends ComboBoxItem>
  extends ComboBoxProps<TMode, TItem> {
  readonly firstSelectItemForSingleMode: TItem | null;
  readonly inputRef: React.RefObject<HTMLInputElement>;
  readonly selectedItems: TItem[];
  readonly onSelection: (item: ComboboxValue<TMode, TItem> | null) => void;
  readonly wrapperRef: React.RefObject<HTMLDivElement>;
  readonly isOverflown: boolean;
  readonly setQuery: (query: string) => void;
}

export const ComboBoxTrigger = <TMode extends SelectionMode, TItem extends ComboBoxItem>({
  firstSelectItemForSingleMode,
  mode = 'single' as TMode,
  isOverflown,
  setQuery,
  placeholder,
  inputRef,
  selectedItems,
  onSelection,
  disabled,
  hasError,
  className,
  wrapperRef,
  cancelable,
  maxAmountOfChips,
  showIcon = true,
  spreadChipsMode = false,
}: ComboBoxTriggerProps<TMode, TItem>): ReactNode => {
  const showSelectedIcon =
    mode === 'single' &&
    (firstSelectItemForSingleMode?.icon != null || firstSelectItemForSingleMode?.customIcon != null) &&
    showIcon;
  const inputMarkup = (
    <Tooltip
      content={firstSelectItemForSingleMode ? firstSelectItemForSingleMode.label : ''}
      disabled={mode === 'multiple' || !isOverflown}
      side="top"
    >
      <ComboboxInput<TItem | null>
        autoComplete="off"
        className={styles.inputContainer({ width: spreadChipsMode ? 'flexible' : undefined })}
        disabled={disabled}
        displayValue={item => item?.label ?? ''}
        onChange={event => setQuery(event.target.value)}
        placeholder={placeholder}
        ref={inputRef}
      />
    </Tooltip>
  );

  const chips = (
    <SelectChips
      disabled={disabled}
      maxAmountOfChips={spreadChipsMode ? Infinity : maxAmountOfChips}
      onDeselect={v => {
        onSelection(selectedItems.filter(item => item.value !== v) as ComboboxValue<TMode, TItem>);
      }}
      options={selectedItems}
    />
  );

  const collapsedChips = (
    <>
      {inputMarkup}
      {mode === 'multiple' && <Flex gap={spacing.s04}>{chips}</Flex>}
    </>
  );

  const spreadChips = (
    <Flex
      flex={1}
      flexWrap="wrap"
      gap={spacing.s04}
      height="100%"
      maxHeight="10.4rem"
      minWidth={0}
      overflowY="auto"
      padding={spacing.s04}
    >
      {chips}
      {inputMarkup}
    </Flex>
  );

  return (
    <div
      className={clsx(
        listStyles.selectTrigger({ height: spreadChipsMode ? 'flexible' : undefined }),
        inputStyles(),
        styles.inputBox,
        className,
      )}
      data-disabled={disabled === true ? 'true' : undefined}
      data-has-error={Boolean(hasError) || undefined}
      data-testid="container"
      ref={wrapperRef}
      role="search"
    >
      {showSelectedIcon &&
        (firstSelectItemForSingleMode.icon != null ? (
          <Icon className={listItemStyles.listItemIcon} name={firstSelectItemForSingleMode.icon} size="sm" />
        ) : (
          firstSelectItemForSingleMode.customIcon
        ))}

      {mode === 'single' ? inputMarkup : spreadChipsMode ? spreadChips : collapsedChips}

      <Flex gap={spacing.s04}>
        {cancelable && firstSelectItemForSingleMode && (
          <IconButton
            color={disabled ? 'textDisabled' : 'neutral7'}
            icon="x"
            iconSize="sm"
            onClick={
              disabled
                ? undefined
                : () => onSelection(mode === 'single' ? null : ([] as TItem[] as ComboboxValue<TMode, TItem>))
            }
            size="sm"
            variant="inline"
          />
        )}
        <ComboboxButton as={Fragment}>
          <IconButton
            className={styles.arrowIcon}
            color={disabled ? 'textDisabled' : 'neutral7'}
            icon="arrow-drop-down-solid"
            iconSize="xs"
            size="sm"
            variant="inline"
          />
        </ComboboxButton>
      </Flex>
    </div>
  );
};
