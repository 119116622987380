/* eslint-disable jsx-a11y/media-has-caption, @typescript-eslint/no-non-null-assertion,  react/function-component-definition */
import type { IconName } from '../Icon/types';

export enum AudioPlayerPresentedSegmentType {
  Audio = 'audio',
  Process = 'process',
}

export interface AudioPlayerSegment {
  readonly startedAt: number;
  readonly endedAt: number;
}

export interface AudioPlayerPresentedSegment {
  readonly title?: string;
  readonly group?: string;
  readonly content?: string | null;
  readonly image?: string;
  readonly icon?: IconName;
  readonly metadata?: Record<string, unknown>;
  readonly type: AudioPlayerPresentedSegmentType;
  readonly color?: string;
  readonly backgroundColor?: string;
}
