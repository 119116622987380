import type { RefObject } from 'react';
import { useCallback, useEffect } from 'react';
import { autoUpdate } from './autoUpdate';

export function setCssVarsForRect(rect: Pick<DOMRectReadOnly, 'bottom' | 'left'>, id: string): void {
  document.body.style.setProperty(getPositionCssVar(id, 'left'), `${rect.left}px`);
  document.body.style.setProperty(getPositionCssVar(id, 'top'), `${rect.bottom}px`);
}

export function removeCssVarsForRect(id: string): void {
  document.body.style.removeProperty(getPositionCssVar(id, 'left'));
  document.body.style.removeProperty(getPositionCssVar(id, 'top'));
}

export function getPositionCssVar(id: string, position: 'left' | 'top'): string {
  return `--${id}-${position}`;
}

/**
 * Sets CSS variables for an element's position relative to the document body.
 * It uses a MutationObserver to track changes in the element's position and updates the CSS
 * variables accordingly.
 *
 * @param {RefObject<HTMLDivElement>} params.wrapperRef - A ref object pointing to the wrapper element.
 * @param {string} params.uniqueElementId - A unique identifier for the element.
 * @param {boolean} params.computeOptionsBoxPositionOnEveryFrame - Whether to compute the
 * position on every frame. Use cautiously as it can cause performance issues.
 */
export function usePositionToCssVars({
  uniqueElementId,
  elementRef,
  computeOptionsBoxPositionOnEveryFrame,
}: {
  readonly elementRef: RefObject<HTMLElement>;
  readonly computeOptionsBoxPositionOnEveryFrame: boolean;
  readonly uniqueElementId: string;
}): void {
  const callback = useCallback(() => {
    if (elementRef.current === null) {
      return;
    }

    const bodyRect = document.body.getBoundingClientRect();
    const elementRect = elementRef.current.getBoundingClientRect();

    setCssVarsForRect(
      {
        left: elementRect.left - bodyRect.left,
        bottom: elementRect.bottom - bodyRect.top,
      },
      uniqueElementId,
    );
  }, [uniqueElementId, elementRef]);

  callback();

  useEffect(() => {
    if (elementRef.current === null) {
      return;
    }

    const cleanup = autoUpdate(elementRef.current, callback, computeOptionsBoxPositionOnEveryFrame);

    return () => {
      cleanup();
    };
  }, [uniqueElementId, elementRef, callback, computeOptionsBoxPositionOnEveryFrame]);
}
