// eslint-disable-next-line import/no-extraneous-dependencies

import type { OrderByDirection, RowItem, TableHeaderCellProps } from '@lemonade-hq/bluis';
import {
    ContentSection,
    EmptySection,
    ErrorSection,
    SummaryInnerSectionItemKey as Key,
    LoadingSection,
    Select,
    SummarySectionContent,
    Table,
    TableHeader,
    TableRow,
    TableTitle,
    SummaryInnerSectionItemValue as Value,
} from '@lemonade-hq/bluis';
import type { SelectOptionBase } from '@lemonade-hq/cdk';
import { Flex } from '@lemonade-hq/cdk';
import { capitalize, isDefined } from '@lemonade-hq/ts-helpers';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { formatDate, FORMATS } from 'commons/DateFormatter';
import { BSelectTitle } from 'components/Bluis/BSelect/select-styles';
import { CopyableText } from 'components/LoCo/common/components/Copyable/CopyButton';
import { SearchInput } from 'components/LoCo/common/components/SearchInput';
import { useTableSort } from 'components/LoCo/common/hooks/useTableSort';
import { LabelWithTooltip } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/SharedLabels';
import type { FieldEntry, FieldMetadata, SchemaFieldsMetadata, SchemaRevision } from 'models/LoCo/Insurance/Schema';
import { useGetProductSchemaAttributes, useGetProductSchemaRevisions } from 'queries/LoCo/Insurance/ProductQueries';

const StyledSelectorWrapper = styled(Flex)`
    flex-direction: column;
    gap: 5px;
`;

const StyledSelectorsSection = styled(Flex)`
    gap: 30px;
`;

const StyledTableTopWrapper = styled(Flex)`
    justify-content: space-between;
    padding-left: 40px;
`;

const StyledWrapper = styled(Flex)`
    flex-direction: column;
    gap: 30px;
`;

const StyledTableWrapper = styled(Flex)`
    flex-direction: column;
    gap: 30px;
`;

interface ProductSchemaTableProps {
    readonly productCode: string;
}

function getRevisionOptions(revisions: SchemaRevision[]): SelectOptionBase[] {
    return revisions.map<SelectOptionBase>(({ revision }, index) => ({
        id: revision,
        value: revision.toString(),
        label: index === 0 ? `${revision.toString()} (Latest)` : revision.toString(),
    }));
}

function getRevisionAddedAtDate(revisions: SchemaRevision[], revision: number): string | null {
    const item = revisions.find(rev => rev.revision === revision);

    return item ? formatDate(item.addedAt, FORMATS.SIMPLE_DATE_SHORT_MONTH) : null;
}
const AttributeRow: React.FC<{
    readonly field: Pick<FieldEntry, 'doc' | 'name'>;
    readonly fieldMetadata: FieldMetadata;
    readonly showUtilization?: boolean;
}> = ({ field, fieldMetadata, showUtilization }) => {
    const rowItems: RowItem[] = [
        { key: 'name', value: <CopyableText textToCopy={field.name} textToDisplay={field.name} visibleOnHover /> },
        {
            key: 'type',
            value: isDefined(fieldMetadata.error) ? (
                <LabelWithTooltip label={fieldMetadata.displayType} tooltipContent={fieldMetadata.error} type="error" />
            ) : (
                fieldMetadata.displayType
            ),
        },
        { key: 'description', value: field.doc ?? '' },
        { key: 'schema', value: capitalize(fieldMetadata.schemaType) },
    ];

    if (showUtilization) {
        rowItems.push({
            key: 'utilization',
            value: fieldMetadata.utilizedInEditions?.join(', ') ?? '',
        });
    }

    return <TableRow key={field.name} row={rowItems} />;
};

const getHeaderFields = (showUtilization: boolean): TableHeaderCellProps[] => {
    const headers = [
        { key: 'name', value: 'Name', width: 250, sortable: true },
        { key: 'type', value: 'Type', width: 250 },
        { key: 'description', value: 'Description', width: 200 },
        { key: 'schema', value: 'Schema', width: 200 },
    ];

    if (showUtilization) {
        headers.push({ key: 'utilization', value: 'Utilization', width: 200 });
    }

    return headers;
};

interface AttributesTableProps {
    readonly showLoader: boolean;
    readonly isError: boolean;
    readonly displayData: FieldEntry[];
    readonly fieldsMetadata: SchemaFieldsMetadata;
    readonly searchTerm: string;

    readonly setSearchTerm: (searchTerm: string) => void;
    readonly sortColumn: keyof FieldEntry;
    readonly sortDirection: OrderByDirection;
    readonly onColumnClick: (key: keyof FieldEntry) => void;
    readonly showUtilization?: boolean;
}

export const AttributesTable: React.FC<AttributesTableProps> = ({
    showLoader,
    isError,
    displayData,
    fieldsMetadata,
    searchTerm,
    setSearchTerm,
    onColumnClick,
    sortColumn,
    sortDirection,
    showUtilization = false,
}) => {
    if (showLoader) {
        return <LoadingSection />;
    }

    if (isError) {
        return <ErrorSection title="Error fetching schema attributes :(" />;
    }

    if (displayData.length === 0 && searchTerm.length === 0) {
        return <EmptySection>No schema found for this product</EmptySection>;
    }

    return (
        <StyledTableWrapper>
            <SearchInput onChange={setSearchTerm} placeholder="Search Names" />
            <Table>
                <TableHeader
                    headers={getHeaderFields(showUtilization)}
                    sortableSet={{
                        sort: key => onColumnClick(key as keyof FieldEntry),
                        sortingBy: sortColumn,
                        sortDirection,
                    }}
                />
                {displayData.map(field => (
                    <AttributeRow
                        field={field}
                        fieldMetadata={fieldsMetadata[field.name]}
                        key={field.name}
                        showUtilization={showUtilization}
                    />
                ))}
            </Table>
        </StyledTableWrapper>
    );
};

export const ProductSchemaTable: React.FC<ProductSchemaTableProps> = ({ productCode }) => {
    const {
        data: schemaRevisions,
        isPending: isLoadingSchemaRevisions,
        isError: isErrorSchemaRevisions,
    } = useGetProductSchemaRevisions(productCode);

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedPlatformRevision, setSelectedPlatformRevision] = useState<string | null>(
        searchParams.has('platformRevision') ? searchParams.get('platformRevision') : null
    );
    const [selectedProductRevision, setSelectedProductRevision] = useState<string | null>(
        searchParams.has('productRevision') ? searchParams.get('productRevision') : null
    );
    const [searchTerm, setSearchTerm] = useState<string>('');

    const {
        data: schemaAttributesResponse,
        isPending: isLoadingSchemaAttributes,
        isError: isErrorSchemaAttributes,
    } = useGetProductSchemaAttributes(
        productCode,
        selectedPlatformRevision,
        selectedProductRevision,
        Boolean(selectedPlatformRevision) && Boolean(selectedProductRevision)
    );

    // preselect latest revisions
    if (schemaRevisions) {
        if (schemaRevisions.platformRevisions.length && !isDefined(selectedPlatformRevision)) {
            const [latestPlatformRevision] = schemaRevisions.platformRevisions;
            setSelectedPlatformRevision(latestPlatformRevision.revision.toString());
        }

        if (schemaRevisions.productRevisions.length && !isDefined(selectedProductRevision)) {
            const [latestProductRevision] = schemaRevisions.productRevisions;

            setSelectedProductRevision(latestProductRevision.revision.toString());
        }
    }

    const platformRevisionOptions = useMemo<SelectOptionBase[]>(() => {
        return schemaRevisions ? getRevisionOptions(schemaRevisions.platformRevisions) : [];
    }, [schemaRevisions]);

    const productRevisionOptions = useMemo<SelectOptionBase[]>(() => {
        return schemaRevisions ? getRevisionOptions(schemaRevisions.productRevisions) : [];
    }, [schemaRevisions]);

    const { sort, sortColumn, sortDirection, onColumnClick } = useTableSort<FieldEntry>('name');

    const displayData = useMemo(() => {
        const schemaFields = isDefined(schemaAttributesResponse?.schema) ? schemaAttributesResponse.schema.fields : [];
        const input = searchTerm.length
            ? schemaFields.filter(field => field.name.toLowerCase().includes(searchTerm.toLowerCase()))
            : schemaFields;

        if (!input.length) return [];

        return sort(input);
    }, [schemaAttributesResponse?.schema, searchTerm, sort]);

    if (isLoadingSchemaRevisions) {
        return <LoadingSection />;
    }

    if (!schemaRevisions || isErrorSchemaRevisions) {
        return <ErrorSection title="Error fetching schema revisions :(" />;
    }

    function getRevisionAddedAt(revisions: SchemaRevision[], revision: number): JSX.Element | null {
        if (!revision) return null;

        return <Flex>{`${revision} (Published on ${getRevisionAddedAtDate(revisions, revision)})`}</Flex>;
    }

    return (
        <ContentSection>
            <StyledWrapper>
                <TableTitle title="Schema Attributes" />
                <StyledTableTopWrapper>
                    <SummarySectionContent>
                        <Key>Platform Schema</Key>
                        <Value>
                            {selectedPlatformRevision != null &&
                                getRevisionAddedAt(
                                    schemaRevisions.platformRevisions,
                                    parseInt(selectedPlatformRevision)
                                )}
                        </Value>
                        <Key>Product Schema</Key>
                        <Value>
                            {selectedProductRevision != null &&
                                getRevisionAddedAt(schemaRevisions.productRevisions, parseInt(selectedProductRevision))}
                        </Value>
                    </SummarySectionContent>

                    <StyledSelectorsSection>
                        <StyledSelectorWrapper>
                            <BSelectTitle>Platform Schema</BSelectTitle>
                            <Select
                                onOptionSelected={option => {
                                    setSearchParams({ platformRevision: option.value });
                                    setSelectedPlatformRevision(option.value);
                                }}
                                options={platformRevisionOptions}
                                placeholder="Select"
                                value={selectedPlatformRevision}
                                width={200}
                            />
                        </StyledSelectorWrapper>
                        <StyledSelectorWrapper>
                            <BSelectTitle>Product Schema</BSelectTitle>
                            <Select
                                onOptionSelected={option => {
                                    setSearchParams({ productRevision: option.value });
                                    setSelectedProductRevision(option.value);
                                }}
                                options={productRevisionOptions}
                                placeholder="Select"
                                value={selectedProductRevision}
                                width={200}
                            />
                        </StyledSelectorWrapper>
                    </StyledSelectorsSection>
                </StyledTableTopWrapper>
                {selectedPlatformRevision != null && selectedProductRevision != null && (
                    <AttributesTable
                        displayData={displayData}
                        fieldsMetadata={schemaAttributesResponse?.fieldsMetadata ?? {}}
                        isError={isErrorSchemaAttributes}
                        onColumnClick={onColumnClick}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        showLoader={isLoadingSchemaAttributes}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                    />
                )}
            </StyledWrapper>
        </ContentSection>
    );
};
