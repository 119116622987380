import { EmptySection, Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import type { ActionOption } from '@lemonade-hq/bluis';
import {
    ActionsMenu,
    ContentSection,
    Image,
    LinkTab,
    LoadingSection,
    PageWrapper,
    TabList,
    Tabs,
    TagMode,
} from '@lemonade-hq/bluis';
import {
    EntitySummaryHeader,
    HeaderGeneralDetails,
    HeaderLocation,
    HeaderRightSideBlock,
    LinkComp,
} from '@lemonade-hq/bluiza';
import { Box } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import type { FC } from 'react';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { getVersionTypeDisplayName } from '../common/display-texts/common';
import { getProductUrl } from '../common/urlBuilders';
import { HeaderImageSizes, ImageContainer } from '../LoCoPagesSharedStyles';
import { EditionSetsChangesSummary } from './ChangesSummary/EditionSetsChangesSummary';
import { CancelDialog } from './dialogs/CancelDialog';
import { PublishDialog } from './dialogs/PublishDialog';
import { ReplaceEditions } from './dialogs/ReplaceEditions';
import { TestInStagingProvider } from './dialogs/TestDialog';
import { UpdateEffectiveDates } from './dialogs/UpdateEffectiveDates';
import { UpdateRatingReferenceDialog } from './dialogs/UpdateRatingReferenceDialog';
import { ImpactAnalysis } from './ImpactAnalysis/ImpactAnalysis';
import { ReleaseDetails } from './releaseDetails/ReleaseDetails';
import type { ReleaseContextData } from './ReleasesShared';
import { getReleaseDisplayStatus, releaseContext, ReleaseDialogType } from './ReleasesShared';
import { HeaderPortal } from 'components/Header';
import { VersionType } from 'models/LoCo/Insurance/BaseEdition';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';
import type { Product } from 'models/LoCo/Insurance/Product';
import { ReleaseStatus } from 'models/LoCo/Insurance/Release';
import type { Release as ReleaseType } from 'models/LoCo/Insurance/Release';
import { useSuspenseGetProduct } from 'queries/LoCo/Insurance/ProductQueries';
import { useSuspenseGetRelease, useSuspenseGetReleaseEditionSets } from 'queries/LoCo/Insurance/ReleasesQueries';

const TEXTS = [
    'All Edition Sets included in the Release will be made available in the selected environment with their respective Effective Dates.',
    'The Release can be removed from the environment manually via Blender at any time.',
];

interface ReleasePageProps {
    readonly release: ReleaseType;
    readonly product: Product;
}

interface DialogsProps {
    readonly dialogState: ReleaseDialogType | null;
    readonly release: ReleaseType;
    readonly editionSetsCodes: string[];
    readonly onClose: () => void;
}

function getStatusTagMode(status: ReleaseStatus): TagMode {
    switch (status) {
        case ReleaseStatus.Draft:
            return TagMode.Update;
        case ReleaseStatus.Cancelled:
            return TagMode.Info;
        case ReleaseStatus.Published:
            return TagMode.Success;
        default:
            return TagMode.Info;
    }
}
const Dialogs: FC<DialogsProps> = ({ dialogState, release, editionSetsCodes, onClose }) => {
    switch (dialogState) {
        case ReleaseDialogType.Publish:
            return <PublishDialog onClose={onClose} release={release} />;
        case ReleaseDialogType.UpdateRatingReferenceEdition:
            return <UpdateRatingReferenceDialog onClose={onClose} release={release} />;
        case ReleaseDialogType.Test:
            return <TestInStagingProvider editionSetsCodes={editionSetsCodes} onClose={onClose} texts={TEXTS} />;
        case ReleaseDialogType.UpdateDates:
            return (
                <UpdateEffectiveDates
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- there are null only when the rolloutStrategy is BugFix
                    newBusinessEffectiveAt={new Date(release.newBusinessEffectiveAt!)}
                    onClose={onClose}
                    releasePublicId={release.publicId}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- there are null only when the rolloutStrategy is BugFix
                    renewalEffectiveAt={new Date(release.renewalEffectiveAt!)}
                    rolloutStrategy={release.rolloutStrategy}
                />
            );
        case ReleaseDialogType.Replace:
            return (
                <ReplaceEditions
                    editions={release.editions}
                    onClose={onClose}
                    platformSchemaRevision={release.platformSchemaRevision}
                    productCode={release.productCode}
                    productSchemaRevision={release.productSchemaRevision}
                    release={release}
                />
            );
        case ReleaseDialogType.Cancel:
            return <CancelDialog onClose={onClose} releasePublicId={release.publicId} />;
        default:
            return null;
    }
};

export const ReleasePage: React.FC<ReleasePageProps> = ({ product, release }) => {
    const { data: editionSets } = useSuspenseGetReleaseEditionSets(release.publicId);
    const editionSetsCodes = editionSets.map((editionSet: EditionSet) => editionSet.code);
    const releaseDetails = <ReleaseDetails product={product} release={release} />;
    const changesSummary = (
        <Suspense fallback={<LoadingSection />}>
            <EditionSetsChangesSummary release={release} />
        </Suspense>
    );
    const impactAnalysis = release.initialRelease ? (
        <EmptySection>No rollout insights for first release</EmptySection>
    ) : release.status === ReleaseStatus.Cancelled ? (
        <EmptySection>No rollout insights for cancelled release</EmptySection>
    ) : (
        <Suspense fallback={<LoadingSection noBorders noShadow />}>
            <ImpactAnalysis release={release} />
        </Suspense>
    );
    const [dialogState, setDialogState] = useState<ReleaseDialogType | null>(null);
    const onActionOptionClick = useCallback<Exclude<ActionOption['onClick'], undefined>>(actionOption => {
        setDialogState(actionOption.value as ReleaseDialogType);
    }, []);

    const onClose = useCallback(() => {
        setDialogState(null);
    }, []);

    const disabled = release.status !== ReleaseStatus.Draft;
    const ROUTES = {
        general: {
            path: 'details',
            label: 'Release Details',
            component: releaseDetails,
        },
        changesSummary: {
            path: 'changes-summary',
            label: 'Changes Summary',
            component: changesSummary,
        },
        impactAnalysis: {
            path: 'rollout-insights',
            label: 'Rollout Insights',
            component: impactAnalysis,
        },
    };

    const contextValue = useMemo<ReleaseContextData>(
        () => ({ onActionRequested: action => setDialogState(action), release }),
        [release]
    );

    return (
        <releaseContext.Provider value={contextValue}>
            <HeaderPortal>
                <EntitySummaryHeader>
                    <ImageContainer>
                        <Image
                            img={{
                                lightSrc: 'appraisal.svg',
                                darkSrc: 'appraisal-dark.svg',
                                ...HeaderImageSizes,
                            }}
                        />
                    </ImageContainer>
                    <HeaderGeneralDetails
                        status={{
                            label: getReleaseDisplayStatus(release.status),
                            mode: getStatusTagMode(release.status),
                        }}
                        subtitle={
                            <Flex gap={spacing.s10}>
                                <Text type="text-md">{release.friendlyName}</Text>
                                <HeaderRightSideBlock>
                                    <LinkComp url={getProductUrl(product.code)}>{product.name}</LinkComp>
                                </HeaderRightSideBlock>
                            </Flex>
                        }
                        title={`${getVersionTypeDisplayName(release.rolloutStrategy)} Release`}
                    />
                    <HeaderLocation country={product.country} state={product.state ?? product.country} />
                    <Box ml="auto">
                        <ActionsMenu
                            actions={[
                                {
                                    label: 'Publish to Production',
                                    value: ReleaseDialogType.Publish,
                                    onClick: onActionOptionClick,
                                    disabled: disabled || !release.readyToPublish,
                                },
                                {
                                    label: 'Test in Environment',
                                    value: ReleaseDialogType.Test,
                                    onClick: onActionOptionClick,
                                    disabled,
                                },
                                {
                                    label: 'Update Effective Dates',
                                    value: ReleaseDialogType.UpdateDates,
                                    onClick: onActionOptionClick,
                                    disabled: disabled || release.rolloutStrategy === VersionType.BugFix,
                                    tooltip:
                                        release.rolloutStrategy === VersionType.BugFix
                                            ? 'Bug Fix releases do not have effective dates'
                                            : undefined,
                                },
                                {
                                    label: 'Replace Editions in Release',
                                    value: ReleaseDialogType.Replace,
                                    onClick: onActionOptionClick,
                                    disabled,
                                },
                                {
                                    label: 'Update rating reference edition',
                                    value: ReleaseDialogType.UpdateRatingReferenceEdition,
                                    onClick: onActionOptionClick,
                                    disabled:
                                        disabled || !isDefined(release.editions.rating?.referenceEditionContentCode),
                                },
                                {
                                    label: 'Cancel Release',
                                    value: ReleaseDialogType.Cancel,
                                    warning: true,
                                    onClick: onActionOptionClick,
                                    disabled,
                                },
                            ]}
                            buttonText="Actions"
                        />
                    </Box>
                </EntitySummaryHeader>
            </HeaderPortal>
            <PageWrapper>
                <ContentSection>
                    <Tabs>
                        <HeaderPortal>
                            <TabList pageTabs>
                                {Object.values(ROUTES).map(({ label, path }) => (
                                    <LinkTab key={label} to={path}>
                                        {label}
                                    </LinkTab>
                                ))}
                            </TabList>
                        </HeaderPortal>
                        <Routes>
                            <Route element={<Navigate replace to="details" />} path="/" />

                            {Object.values(ROUTES).map(({ path, component }) => (
                                <Route element={component} key={path} path={path} />
                            ))}
                        </Routes>
                    </Tabs>
                </ContentSection>
            </PageWrapper>
            <Dialogs
                dialogState={dialogState}
                editionSetsCodes={editionSetsCodes}
                onClose={onClose}
                release={release}
            />
        </releaseContext.Provider>
    );
};

export const Release: React.FC = () => {
    const { productCode = '', releaseCode = '' } = useParams<{
        productCode: string;
        releaseCode: string;
    }>();

    const { data: releaseData } = useSuspenseGetRelease(releaseCode);

    const { data: productData } = useSuspenseGetProduct(productCode);

    return <ReleasePage product={productData} release={releaseData} />;
};
