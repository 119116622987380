import type { DialogAction, SelectOption } from '@lemonade-hq/bluis';
import { AlertMode, Dialog, ErrorSection, FormInputWrapper, Select } from '@lemonade-hq/bluis';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import { isDefined, snakeToCamelCase } from '@lemonade-hq/ts-helpers';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import type { Entries } from 'type-fest';
import { SimulationViolations } from './SimulationViolation';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import {
    useCheckEditionSetCompatibility,
    useGetProductEditionsSummary,
    useGetProductSchemaRevisions,
} from 'queries/LoCo/Insurance/ProductQueries';

const StyledSelect = styled(Select)`
    height: 38px;
    text-align: left;
`;

const StyledDialog = styled(Dialog)`
    > div {
        > div {
            min-width: 700px;
        }
    }
`;

const StyledFormInputWrapper = styled(FormInputWrapper)`
    width: 100%;

    > div {
        display: grid;
        grid-template-columns: 2fr 6fr;
        justify-items: start;
        gap: 10px;

        > label {
            white-space: nowrap;
            justify-self: flex-start;
        }
    }
`;

const Split = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;

    button {
        width: 190px !important;
    }
`;

const SUCCESS_MSG = 'Editions are compatible';

interface SimulationDialogProps {
    readonly coveragesEditionCode?: string;
    readonly digitalAgentEditionCode?: string;
    readonly ratingEditionCode?: string;
    readonly underwritingFiltersEditionCode?: string;
    readonly onClose: () => void;
}

const clearEmptyValues = <T extends Record<string, unknown>>(obj: T): T =>
    flow([Object.entries, (arr: Entries<T>) => arr.filter(([_, value]) => Boolean(value)), Object.fromEntries])(
        obj
    ) as T;

export const SimulationDialog: React.FC<SimulationDialogProps> = ({
    coveragesEditionCode,
    digitalAgentEditionCode,
    ratingEditionCode,
    underwritingFiltersEditionCode,
    onClose,
}) => {
    const product = useGetProductData();

    const { errors, values, setValue, valid } = useForm({
        fields: {
            coveragesEditionCode: {
                startValue: coveragesEditionCode ?? '',
                validations: {},
            },
            digitalAgentEditionCode: {
                startValue: digitalAgentEditionCode ?? '',
                validations: {},
            },
            ratingEditionCode: {
                startValue: ratingEditionCode ?? '',
                validations: {},
            },
            underwritingFiltersEditionCode: {
                startValue: underwritingFiltersEditionCode ?? '',
                validations: {},
            },
            productSchemaRevision: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
                skipValidationsCondition: formValues => formValues.platformSchemaRevision === '',
            },
            platformSchemaRevision: {
                startValue: '',
                validations: {},
                skipValidationsCondition: formValues => formValues.productSchemaRevision === '',
            },
        },
    });

    const {
        mutateAsync: checkCompatibilityMutate,
        isError,
        isPending: isLoading,
        data: compatibilityStatus,
    } = useCheckEditionSetCompatibility();

    const isValid = useCallback((formValues: typeof values) => {
        // we want to make sure that at least 2 values are selected - and not only one schema version is selected
        let countValues = 0;

        if (formValues.coveragesEditionCode) countValues++;
        if (formValues.digitalAgentEditionCode) countValues++;
        if (formValues.ratingEditionCode) countValues++;
        if (formValues.underwritingFiltersEditionCode) countValues++;
        if (Boolean(formValues.productSchemaRevision) !== Boolean(formValues.platformSchemaRevision)) {
            return false;
        } else if (formValues.productSchemaRevision && formValues.platformSchemaRevision) {
            countValues++;
        }

        return countValues > 1;
    }, []);

    const {
        data: productEditionSummary,
        isError: isErrorProductEditionSummary,
        isLoading: isLoadingProductEditionSummary,
    } = useGetProductEditionsSummary(product.code);

    const {
        data: SchemaRevisions,
        isError: isErrorSchemaRevisions,
        isLoading: isLoadingSchemaRevisions,
    } = useGetProductSchemaRevisions(product.code);

    const editionsOptions = useMemo<Record<EditionType, SelectOption[]>>(() => {
        if (!productEditionSummary)
            return {
                [EditionType.Coverages]: [],
                [EditionType.DigitalAgent]: [],
                [EditionType.Rating]: [],
                [EditionType.UnderwritingFilters]: [],
            };

        const editionOptions = {} as Record<EditionType, SelectOption[]>;

        Object.values(EditionType).forEach((editionType: EditionType) => {
            editionOptions[editionType] = productEditionSummary[
                `${snakeToCamelCase(editionType)}Editions` as keyof typeof productEditionSummary
            ].map(edition => ({
                id: edition.code,
                value: edition.code,
                label: `${edition.friendlyName} (${edition.status})`,
            }));
        });

        return editionOptions;
    }, [productEditionSummary]);

    const productSchemaOptions = useMemo<SelectOption[]>(() => {
        if (!SchemaRevisions) return [];

        return SchemaRevisions.productRevisions.map(revision => ({
            id: revision.revision,
            value: revision.revision.toString(),
            label: revision.revision.toString(),
        }));
    }, [SchemaRevisions]);

    const platformSchemaOptions = useMemo<SelectOption[]>(() => {
        if (!SchemaRevisions) return [];

        return SchemaRevisions.platformRevisions.map(revision => ({
            id: revision.revision,
            value: revision.revision.toString(),
            label: revision.revision.toString(),
        }));
    }, [SchemaRevisions]);

    const checkCompatibility = useCallback(async () => {
        const clearValues = clearEmptyValues(values);
        await checkCompatibilityMutate({
            productCode: product.code,
            ...clearValues,
            productSchemaRevision: isDefined(clearValues.productSchemaRevision)
                ? parseInt(clearValues.productSchemaRevision)
                : undefined,
            platformSchemaRevision: isDefined(clearValues.platformSchemaRevision)
                ? parseInt(clearValues.platformSchemaRevision)
                : undefined,
        });
    }, [checkCompatibilityMutate, product.code, values]);

    const isDisabled = isLoading || isLoadingProductEditionSummary || isErrorProductEditionSummary;

    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Check',
                type: 'submit',
                onClick: checkCompatibility,
                disabled: Boolean(isDisabled) || !valid || !isValid(values),
            },
        ],
        [onClose, checkCompatibility, isDisabled, valid, isValid, values]
    );

    const violations = useMemo<string[] | null>(() => {
        return compatibilityStatus?.violationMessages ?? null;
    }, [compatibilityStatus?.violationMessages]);

    const isSuccess = Boolean(compatibilityStatus) && violations?.length === 0;
    const isErrorFetch = isErrorProductEditionSummary || isErrorSchemaRevisions;
    const isLoadingFetch = isLoadingProductEditionSummary || isLoadingSchemaRevisions;

    return (
        <StyledDialog
            actions={actions}
            closeOnOutsideClick
            error={
                isError ? (
                    GENERAL_ERROR_MSG
                ) : violations && violations.length > 0 ? (
                    <SimulationViolations violations={violations} />
                ) : undefined
            }
            loading={isLoading || Boolean(isLoadingFetch)}
            notice={
                isSuccess
                    ? [
                          {
                              mode: AlertMode.Success,
                              title: SUCCESS_MSG,
                          },
                      ]
                    : undefined
            }
            onClose={onClose}
            size="large"
            title="Editions Compatibility Check"
        >
            {Boolean(isErrorFetch) && <ErrorSection noBorders title="Failed fetching base editions  :(" />}

            <StyledFormInputWrapper label={`Coverages`} showErrors={!isEmpty(errors.coveragesEditionCode)}>
                <StyledSelect
                    disabled={isDisabled}
                    onOptionSelected={({ value }) => setValue('coveragesEditionCode', value)}
                    options={editionsOptions[EditionType.Coverages]}
                    placeholder="Select"
                    value={values.coveragesEditionCode}
                />
            </StyledFormInputWrapper>

            <StyledFormInputWrapper label={`Digital Agent`} showErrors={!isEmpty(errors.coveragesEditionCode)}>
                <StyledSelect
                    disabled={isDisabled}
                    onOptionSelected={({ value }) => setValue('digitalAgentEditionCode', value)}
                    options={editionsOptions[EditionType.DigitalAgent]}
                    placeholder="Select"
                    value={values.digitalAgentEditionCode}
                />
            </StyledFormInputWrapper>

            <StyledFormInputWrapper label={`Rating`} showErrors={!isEmpty(errors.coveragesEditionCode)}>
                <StyledSelect
                    disabled={isDisabled}
                    onOptionSelected={({ value }) => setValue('ratingEditionCode', value)}
                    options={editionsOptions[EditionType.Rating]}
                    placeholder="Select"
                    value={values.ratingEditionCode}
                />
            </StyledFormInputWrapper>

            <StyledFormInputWrapper
                label={`Underwriting filters`}
                showErrors={!isEmpty(errors.underwritingFiltersEditionCode)}
            >
                <StyledSelect
                    disabled={isDisabled}
                    onOptionSelected={({ value }) => setValue('underwritingFiltersEditionCode', value)}
                    options={editionsOptions[EditionType.UnderwritingFilters]}
                    placeholder="Select"
                    value={values.underwritingFiltersEditionCode}
                />
            </StyledFormInputWrapper>

            <StyledFormInputWrapper label="Schema">
                <Split>
                    <StyledSelect
                        disabled={isDisabled}
                        onOptionSelected={({ value }) => setValue('productSchemaRevision', value)}
                        options={productSchemaOptions}
                        placeholder="Select Product Schema"
                        value={values.productSchemaRevision}
                    />

                    <StyledSelect
                        disabled={isDisabled}
                        onOptionSelected={({ value }) => setValue('platformSchemaRevision', value)}
                        options={platformSchemaOptions}
                        placeholder="Select Platform Schema"
                        value={values.platformSchemaRevision}
                    />
                </Split>
            </StyledFormInputWrapper>
        </StyledDialog>
    );
};
