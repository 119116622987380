import { TagMode } from '@lemonade-hq/bluis';
import { EditionSetStatus } from 'models/LoCo/Insurance/EditionSets';

export const EDITION_SET_STATUS_DISPLAY = {
    [EditionSetStatus.Published]: 'Published',
    [EditionSetStatus.PreRelease]: 'Pre-release',
    [EditionSetStatus.TestOnly]: 'Test-only',
    [EditionSetStatus.Archived]: 'Archived',
};

export const EDITION_SET_STATUS_TAG_MODE = {
    [EditionSetStatus.Published]: TagMode.Success,
    [EditionSetStatus.PreRelease]: TagMode.Update,
    [EditionSetStatus.TestOnly]: TagMode.Info,
    [EditionSetStatus.Archived]: TagMode.Alert,
};
