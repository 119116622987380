import { Flex, Icon, Layout } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent } from '@lemonade-hq/boutique';
import type { QueryKey } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useCallback } from 'react';
import type { FC } from 'react';
import { AttachmentPreview } from '../AttachmentPreview';
import type { AttachmentDTO } from '../types';
import {
    AttachmentFormat,
    getAttachmentAnalyticsParam,
    getAttachmentFormat,
    getThumbnailUrl,
    isFraud,
    isInvalidContentType,
} from '../utils';
import {
    dismissAttachment,
    fraudWarning,
    fraudWarningBG,
    hoverOverlay,
    thumbnail,
    thumbnailImage,
} from './AttachmentThumbnail.css';
import { Audio, Doc, Unknown, Video } from './ThumbnailIcons';

const AttachmentIcon: FC<{ readonly attachment: AttachmentDTO }> = ({ attachment }) => {
    const { contentType, fileName } = attachment;
    const attachmentType = getAttachmentFormat(!isInvalidContentType(contentType) ? contentType : fileName);

    switch (attachmentType) {
        case AttachmentFormat.Doc:
        case AttachmentFormat.Pdf:
            return <Doc />;
        case AttachmentFormat.Image:
            return <img alt="" className={thumbnailImage} loading="lazy" src={getThumbnailUrl(attachment, 'tiny')} />;
        case AttachmentFormat.Audio:
            return <Audio />;
        case AttachmentFormat.Video:
            return <Video />;
        default:
            return <Unknown />;
    }
};

export interface ThumbnailProps {
    readonly className?: string;
    readonly onClick?: (attachment: AttachmentDTO) => void;
    readonly openGallery?: (attachment: AttachmentDTO) => void;
    readonly onDismiss?: (attachment: AttachmentDTO) => void;
    readonly attachment: AttachmentDTO;
    readonly showPreview?: boolean;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly invalidateKeys?: QueryKey[];
}

export const AttachmentThumbnail: FC<ThumbnailProps> = ({
    className,
    onClick,
    attachment,
    onDismiss,
    showPreview = true,
    entityPublicId = '',
    entityType,
    openGallery,
    invalidateKeys,
}) => {
    const handleClick = useCallback(() => {
        if (openGallery != null) {
            openGallery(attachment);
            trackEvent('docs.clicked', {
                ...getAttachmentAnalyticsParam({
                    attachment,
                    entityType,
                    entityId: entityPublicId,
                }),
                action: 'click',
                action_type: 'open_gallery_view',
                source: 'thumbnail',
            });
        }

        onClick?.(attachment);
    }, [attachment, entityPublicId, entityType, onClick, openGallery]);

    const onDismissClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();

            onDismiss?.(attachment);
        },
        [attachment, onDismiss]
    );

    const content = (
        <Flex alignItems="center" className={clsx(className, thumbnail)} justifyContent="center" onClick={handleClick}>
            {showPreview && (
                <Flex alignItems="center" className={hoverOverlay} justifyContent="center">
                    <Icon color="light" name="eye-solid" />
                </Flex>
            )}
            {onDismiss && (
                <button className={dismissAttachment} onClick={onDismissClick} type="button">
                    <Icon color="neutral7" name="x-circle-solid" />
                </button>
            )}
            <AttachmentIcon attachment={attachment} />
            {isFraud(attachment) && (
                <>
                    <Flex alignItems="center" className={fraudWarning} justifyContent="center">
                        <Icon color="brand1" name="alert-circle-solid" />
                    </Flex>
                    <Layout className={clsx(fraudWarning, fraudWarningBG)} />
                </>
            )}
        </Flex>
    );

    if (showPreview) {
        return (
            <AttachmentPreview
                attachment={attachment}
                entityPublicId={entityPublicId}
                entityType={entityType}
                invalidateKeys={invalidateKeys}
                key={attachment.publicId}
                openGallery={openGallery}
            >
                {content}
            </AttachmentPreview>
        );
    }

    return content;
};
