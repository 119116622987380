import { clsx } from 'clsx';
import type { FC } from 'react';
import { Text } from '../../base/Text/Text';
import { selectChip } from '../../theme/select.css';
import { IconButton } from '../IconButton/IconButton';
import { Tooltip } from '../Tooltip/Tooltip';
import type { SelectOptionBase } from './Select';
import * as styles from './Select.css';

interface SelectButtonChipProps {
  readonly onDelete?: () => void;
  readonly label?: SelectOptionBase['label'];
  readonly tooltipLabel?: string;
  readonly isCounter?: boolean;
  readonly disabled?: boolean;
  readonly value?: number | string;
  readonly unlimitedWidth?: boolean;
}

export const SelectButtonChip: FC<SelectButtonChipProps> = ({
  tooltipLabel,
  label,
  onDelete,
  isCounter,
  disabled,
  value,
  unlimitedWidth,
}) => {
  return (
    <Tooltip content={tooltipLabel ?? label}>
      <div
        className={clsx(
          selectChip,
          styles.selectButtonChip({
            type: isCounter ? 'counter' : undefined,
            width: unlimitedWidth ? 'fullySpread' : undefined,
          }),
        )}
      >
        <Text className={styles.ellipsisText} fontWeight="semi-bold" type="text-sm">
          {label}
        </Text>
        {onDelete && (
          <IconButton
            aria-label={`Remove ${value}`}
            as="div"
            color="neutral7"
            disabled={disabled}
            icon="x"
            iconSize="sm"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onDelete();
            }}
            role="button"
            size="sm"
            variant="inline"
          />
        )}
      </div>
    </Tooltip>
  );
};
