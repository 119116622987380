import { camelToSnakeCaseKeys } from '@lemonade-hq/ts-helpers';
import axios from 'axios';
import type { Attachment, AttachmentType } from 'models/Attachment';
import { AttachmentEntityType } from 'models/Attachment';
import type { AttachmentRelationEntityType, CarAttachmentType, PageResult } from 'models/CarShared';
import { getUrlResolver } from 'commons/UrlResolver';
import type { InvolvedPartyType } from 'models/InvolvedParty';
import { OrderByDirection } from 'components/Bluis/EntitiesTable/types';

const carBlenderAttachmentsUrlResolver = getUrlResolver('car-blender', '/api/v1/attachments');
const carBlenderAttachmentRelationsUrlResolver = getUrlResolver('car-blender', '/api/v1/attachment_relations');
const carBlenderAttachmentDetectionsUrlResolver = getUrlResolver('car-blender', '/api/v1/attachment_detections');

export async function createAttachments({
    entityId,
    entityType,
    attachments,
}: {
    readonly entityId: string;
    readonly entityType: AttachmentEntityType;
    readonly attachments: {
        readonly type: AttachmentType;
        readonly filePublicId: string;
        readonly contentType: string;
        readonly description?: string;
        readonly involvedPartyId?: string;
        readonly involvedPartyType?: InvolvedPartyType;
    }[];
}): Promise<Attachment[]> {
    const url = carBlenderAttachmentsUrlResolver();

    const entityPublicId =
        entityType === AttachmentEntityType.Claim ? { claimPublicId: entityId } : { policyPublicId: entityId };
    const body = {
        attachments: attachments.map(a => ({
            ...entityPublicId,
            type: a.type,
            filePublicId: a.filePublicId,
            contentType: a.contentType,
            description: a.description ? a.description : null,
            entityPublicId: a.involvedPartyId ? a.involvedPartyId : null,
            entityType: a.involvedPartyType ? a.involvedPartyType : null,
        })),
    };

    return await axios.post<{ data: Attachment[] }>(url, body).then(response => response.data.data);
}

export async function getAttachmentTypes(): Promise<CarAttachmentType[]> {
    const url = carBlenderAttachmentsUrlResolver('/types');

    return await axios.get<{ data: CarAttachmentType[] }>(url).then(response => response.data.data);
}

export type AttachmentsToUpdate = Partial<Pick<Attachment, 'description' | 'type'>> & { readonly publicId: string };

export interface AttachmentFilterOptions {
    readonly claimPublicId: string;
    readonly chatSessionId?: string;
    readonly attachmentTypes?: CarAttachmentType[];
    readonly sources?: string[];
    readonly entityPublicIds?: string[];
    readonly description?: string;
    readonly createdAfter?: Date;
    readonly createdBefore?: Date;
    readonly pageParam?: number;
    readonly pageSize?: number;
    readonly sortBy?: string;
    readonly sortingOrder?: OrderByDirection;
}

export async function updateAttachments({
    attachments,
}: {
    readonly attachments: AttachmentsToUpdate[];
}): Promise<Attachment[]> {
    const url = carBlenderAttachmentsUrlResolver('/bulk_update');

    return await axios.patch<{ data: Attachment[] }>(url, { attachments }).then(response => response.data.data);
}

export async function createAttachmentRelations({
    entityPublicId,
    entityType,
    attachmentsPublicIds,
}: {
    readonly entityPublicId: string;
    readonly entityType: AttachmentRelationEntityType;
    readonly attachmentsPublicIds: string[];
}): Promise<null> {
    const url = carBlenderAttachmentRelationsUrlResolver();

    const body = {
        entityPublicId,
        entityType,
        attachmentsPublicIds,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function deleteAttachmentRelations({
    entityPublicId,
    attachmentsPublicIds,
}: {
    readonly entityPublicId: string;
    readonly attachmentsPublicIds: string[];
}): Promise<null> {
    const url = carBlenderAttachmentRelationsUrlResolver('/delete');

    const body = {
        entityPublicId,
        attachmentsPublicIds,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function fetchAttachments({
    claimPublicId,
    chatSessionId,
    pageParam = 1,
    pageSize = 10,
    attachmentTypes = [],
    description,
    entityPublicIds = [],
    sources = [],
    sortBy = 'createdAt',
    sortingOrder = OrderByDirection.Desc,
}: AttachmentFilterOptions): Promise<PageResult> {
    const url = carBlenderAttachmentsUrlResolver();

    const params: {
        claimPublicIds: string;
        chatSessionId?: string;
        pageNumber: number;
        pageSize: number;
        types?: string;
        description?: string;
        involvedPartyPublicId?: string;
        sources?: string;
        sortBy?: string;
        sortingOrder?: string;
    } = {
        claimPublicIds: claimPublicId,
        chatSessionId,
        pageNumber: pageParam,
        pageSize,
        sortBy,
        sortingOrder: sortingOrder === OrderByDirection.Asc ? 'ASC' : 'DESC',
        description: description !== '' ? description : undefined,
    };

    if (attachmentTypes.length > 0) {
        params.types = attachmentTypes.join(',');
    }

    if (entityPublicIds.length > 0) {
        params.involvedPartyPublicId = entityPublicIds.join(',');
    }

    if (sources.length > 0) {
        params.sources = sources.join(',');
    }

    return await axios.get(url, { params }).then(response => ({
        ...response.data,
        attachments: response.data.attachments.map(camelToSnakeCaseKeys),
    }));
}

interface AttachmentDetectionUpdate {
    readonly attachmentDetectionPublicId: string;
    readonly manualFeedback: boolean | null;
}

export async function updateAttachmentDetection({
    attachmentDetectionPublicId,
    manualFeedback,
}: AttachmentDetectionUpdate): Promise<void> {
    const url = carBlenderAttachmentDetectionsUrlResolver(`/${attachmentDetectionPublicId}`);

    return await axios.patch(url, { manualFeedback });
}
