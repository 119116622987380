/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CardGrid, CardGridArea, IconButton, pageWrapper, spacing, Text, Tooltip } from '@lemonade-hq/blender-ui';
import { Flex } from '@lemonade-hq/cdk';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { VoicePersonaEditor } from './simulation/VoicePersonaEditor';
import { VoiceSimulation } from './simulation/VoiceSimulation';
import { VOICE_FULL_PATH_PREFIX } from './Voice.Routes';
import { useForceBlenderUI } from 'hooks/useForceBlenderUI';
import { useFullscreen } from 'hooks/useFullScreen';

export const VoiceSimulationPage: FC = () => {
    useFullscreen();
    useForceBlenderUI();
    const navigate = useNavigate();

    // TODO: disable everything while persona editor schema is loading
    // TODO: disable editing schema while simulating call

    return (
        <Flex className={pageWrapper} gap={spacing.s16} height="100%" padding={spacing.s16}>
            <CardGrid
                areas={[['header'], ['content']]}
                flexBasis="40rem"
                flexGrow={0}
                flexShrink={0}
                gridTemplateRows="min-content"
                showSeparators
            >
                <CardGridArea alignItems="center" areaName="header" display="flex">
                    <Flex alignItems="center" gap={spacing.s10}>
                        <Tooltip content="Back to conversations page" side="bottom">
                            <IconButton
                                aria-label="back"
                                icon="chevron-down"
                                onClick={() => navigate(VOICE_FULL_PATH_PREFIX)}
                                rotation="cw90deg"
                                size="md"
                                variant="inline"
                            />
                        </Tooltip>
                        <Text type="h4">Phone Call Simulation</Text>
                    </Flex>
                </CardGridArea>
                <CardGridArea areaName="content" padding={spacing.s16}>
                    <VoiceSimulation />
                </CardGridArea>
            </CardGrid>
            <CardGrid areas={[['header'], ['content']]} gridTemplateRows="min-content" showSeparators>
                <CardGridArea alignItems="center" areaName="header" display="flex">
                    <Text type="h4">Persona Editor</Text>
                </CardGridArea>
                <CardGridArea areaName="content" padding={spacing.s16}>
                    <VoicePersonaEditor />
                </CardGridArea>
            </CardGrid>
        </Flex>
    );
};
