// Coverages Edition
import type { Currency } from '@lemonade-hq/lemonation';
import type { Alert, AlertType } from './Alerts';
import { SettingType } from './SettingType';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageRuleDisplay } from 'models/LoCo/Insurance/CoverageRule';

export interface CoveragesEdition extends Edition {
    readonly coverages: CoverageInstance[];
    readonly settings: SettingInstance[];
}

export interface InstanceBase {
    readonly name: string;
    readonly description: string;
    readonly templateCode: string;
    readonly relatedRules: CoverageRuleDisplay[];
}

export type CoverageScopeType = InsuranceScopeType.InsuredEntity | InsuranceScopeType.Policy;
export type CoverageInsuranceScope = InsuredEntityScope | PolicyScope;

export interface CoverageInstance extends InstanceBase {
    readonly required: boolean;
    readonly relatedSettings: { readonly templateCode: string; readonly name: string }[];
    readonly isBenefit: boolean;
    readonly scope: CoverageInsuranceScope;
    readonly alerts?: Alert<AlertType.CoverageAlert>[];
}

export interface CoupledSetting {
    readonly coupledSettingTemplateCode: string;
    readonly coupledSettingName: string;
    readonly values: {
        readonly currentSettingValue?: number;
        readonly coupledSettingValue?: number;
    }[];
}

export interface SettingInstanceBase extends InstanceBase {
    readonly type: SettingType;
    readonly values: Values;
    readonly relatedCoverages: { readonly templateCode: string; readonly name: string }[];
    readonly coupledSetting?: CoupledSetting;
    readonly alerts?: Alert<AlertType.SettingAlert>[];
}

export interface DeductibleSettingInstance extends SettingInstanceBase {
    readonly scope: InsuranceScope;
    readonly duration: Duration;
    readonly type: SettingType.Deductible;
    readonly currencyUnit: Currency;
}

export interface LimitSettingInstance extends SettingInstanceBase {
    readonly scope: InsuranceScope;
    readonly duration: Duration;
    readonly type: SettingType.Limit;
    readonly unit: SettingUnit;
    readonly currencyUnit?: Currency;
    readonly parentLimitTemplateCode?: string;
    readonly parentLimitName?: string;
}

export interface WaitingPeriodSettingInstance extends SettingInstanceBase {
    readonly type: SettingType.WaitingPeriod;
    readonly durationUnit: DurationUnit;
}

export interface CoinsuranceSettingInstance extends SettingInstanceBase {
    readonly type: SettingType.Coinsurance;
    readonly variant: CoinsuranceVariant;
}

export type SettingInstance =
    | CoinsuranceSettingInstance
    | DeductibleSettingInstance
    | LimitSettingInstance
    | WaitingPeriodSettingInstance;

// Values (settings instance attribute)
export enum ValueType {
    List = 'list',
    Range = 'range',
}

export interface ListOfValues {
    readonly type: ValueType.List;
    readonly values: number[];
    readonly includeUnlimited: boolean;
}

export interface RangeValue {
    readonly type: ValueType.Range;
    readonly min: number;
    readonly max: number;
    readonly step: number;
}

export type Values = ListOfValues | RangeValue;

// Insurance scope (settings instance attribute)
export enum InsuranceScopeType {
    InsuredEntity = 'insured_entity',
    ExternalEntity = 'external_entity',
    Policy = 'policy',
}

export enum EntityScopeValueSelectionMethod {
    Global = 'global',
    PerEntity = 'per_entity',
    Stacked = 'stacked',
}

export enum CoinsuranceVariant {
    Coinsurance = 'coinsurance',
    Copay = 'copay',
}

export enum DurationType {
    Event = 'event' /* claim */,
    Policy = 'policy',
    Timespan = 'timespan',
}

export enum DurationUnit {
    Year = 'year',
    Month = 'month',
    Week = 'week',
    Day = 'day',
}

export interface Duration {
    readonly type: DurationType;
    readonly amount?: number;
    readonly unit?: DurationUnit;
}

export interface ExternalEntityScope {
    readonly type: InsuranceScopeType.ExternalEntity;
    readonly name: string;
    readonly valueSelectionMethod?: EntityScopeValueSelectionMethod;
}

export interface InsuredEntityScope {
    readonly type: InsuranceScopeType.InsuredEntity;
    readonly insuredEntityCode: string;
    readonly valueSelectionMethod?: EntityScopeValueSelectionMethod;
}

export interface PolicyScope {
    readonly type: InsuranceScopeType.Policy;
    readonly valueSelectionMethod?: EntityScopeValueSelectionMethod;
}

export type InsuranceScope = ExternalEntityScope | InsuredEntityScope | PolicyScope;

// Units
export enum SettingUnit {
    Currency = 'currency',
    Event = 'event',
    Item = 'item',
    ClaimLossPercentage = 'claim_loss_percentage',
}

// Violations

export interface EditionValidationsResponse {
    readonly hasBreakingChanges: boolean;
    readonly messages: string[];
}

type SubLimitSettingInstance = LimitSettingInstance & {
    readonly parentLimitTemplateCode: string;
    readonly parentLimitName: string;
};

export function isSubLimitSettingInstance(
    settingInstance: SettingInstance
): settingInstance is SubLimitSettingInstance {
    return (
        settingInstance.type === SettingType.Limit &&
        settingInstance.parentLimitTemplateCode !== undefined &&
        settingInstance.parentLimitName !== undefined
    );
}

export const isSettingsInstance = (instance: CoverageInstance | SettingInstance): instance is SettingInstance => {
    return 'type' in instance;
};

export const isInsuredEntityScope = (scope: InsuranceScope): scope is InsuredEntityScope =>
    scope.type === InsuranceScopeType.InsuredEntity;
