import {
    Flex,
    FormLayout,
    FormProvider,
    generateTypedFormComponents,
    spacing,
    Text,
    useForm,
} from '@lemonade-hq/blender-ui';
import { Dialog } from '@lemonade-hq/bluis';
import { ma } from '@lemonade-hq/maschema-schema';
import type { PropsWithChildren } from 'react';
import { useCallback } from 'react';
import { useActivateEditionSetInEnv } from 'queries/LoCo/Insurance/EditionSetQueries';

export const testInEnvSchema = ma.record({
    envType: ma.oneOf(['staging', 'development']),
    envName: ma.string(),
    expireInDays: ma.oneOf(Array.from({ length: 30 }, (_, i) => i + 1)),
});

const { InputGroup } = generateTypedFormComponents<typeof testInEnvSchema>();

export const TestInStagingForm: React.FC<{ readonly texts: string[] }> = ({ texts }) => {
    return (
        <Flex flexDirection="column" gap={spacing.s16}>
            {texts.map(text => (
                <Text key={text} textAlign="center">
                    {text}
                </Text>
            ))}
            <FormLayout mt={spacing.s20}>
                <InputGroup
                    alignToTop
                    direction="horizontal"
                    gridSize="small"
                    inputComponent="Radio"
                    label="Environment Type"
                    schemaKey="envType"
                />
                <InputGroup
                    direction="horizontal"
                    gridSize="small"
                    inputComponent="Input"
                    label="Environment Name"
                    placeholder="Environment name"
                    schemaKey="envName"
                />
                <InputGroup
                    direction="horizontal"
                    gridSize="small"
                    inputComponent="Select"
                    label="Expire in (days)"
                    placeholder="Expire in (days)"
                    schemaKey="expireInDays"
                    tooltip="Upon expiration, the edition set(s) of the release will be removed from this environment automatically."
                />
            </FormLayout>
        </Flex>
    );
};

export const TestInStagingDialog: React.FC<PropsWithChildren<TestInStagingProviderProps>> = ({
    editionSetsCodes,
    onClose,
    children,
}) => {
    const {
        validationResults: { valid },
        values,
    } = useForm();
    const { mutateAsync: activateEditionSetInEnv, isPending, error } = useActivateEditionSetInEnv();

    const publish = useCallback(async () => {
        await activateEditionSetInEnv({
            editionSetCodes: editionSetsCodes,
            envStage: values?.envType as 'development' | 'staging',
            envName: values?.envName as string,
            daysUntilExpiration: values?.expireInDays as number,
        });
        onClose();
    }, [activateEditionSetInEnv, editionSetsCodes, onClose, values?.envName, values?.envType, values?.expireInDays]);

    return (
        <Dialog
            actions={[
                { type: 'close', text: 'Cancel', onClick: onClose },
                { type: 'submit', text: 'Activate in Env', onClick: publish, disabled: !valid },
            ]}
            error={
                error == null ? undefined : (error as { response: { data: { message: string } } }).response.data.message
            }
            loading={isPending}
            size="medium"
            title="Test release in environment"
        >
            {children}
        </Dialog>
    );
};

interface TestInStagingProviderProps {
    readonly editionSetsCodes: string[];
    readonly onClose: () => void;
}

export const TestInStagingProvider: React.FC<TestInStagingProviderProps & { readonly texts: string[] }> = ({
    editionSetsCodes,
    texts,
    onClose,
}) => {
    return (
        <FormProvider
            initialConfig={{ showErrorsOnBlur: true }}
            initialValues={{ expireInDays: 5 }}
            schema={testInEnvSchema}
        >
            <TestInStagingDialog editionSetsCodes={editionSetsCodes} onClose={onClose}>
                <TestInStagingForm texts={texts} />
            </TestInStagingDialog>
        </FormProvider>
    );
};
