import { forwardRef } from 'react';
import type { FC } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { spacing } from '../../theme/spacing.css';
import type { BannerProps } from '../Banner/Banner';
import { Banner } from '../Banner/Banner';

export type BannerWithIdProps = BannerProps &
  ({ readonly title: JSX.Element; readonly id: string } | { readonly title: string; readonly id?: never });

export interface BannerGroupProps {
  readonly banners: BannerWithIdProps[];
}

/**
 * Renders a set of `Banner` components stacked vertically, useful for showing banners within dialogs or popovers.
 * When using a JSX element for the `title` prop, an `id` prop needs to be provided.
 */
export const BannerGroup: FC<BannerGroupProps> = forwardRef<HTMLDivElement, BannerGroupProps>(({ banners }, ref) => {
  const hasBanners = Boolean(banners.length);

  if (!hasBanners) {
    return null;
  }

  return (
    <Flex flexDirection="column" gap={spacing.s04} ref={ref}>
      {banners.map(({ id, title, variant }) => (
        <Banner key={id ?? `${variant}-${title}`} title={title} variant={variant} />
      ))}
    </Flex>
  );
});
