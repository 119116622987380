import qs from 'qs';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';

const EDITION_TYPE_TO_PATH: Partial<Record<EditionType, string>> = {
    [EditionType.Rating]: 'rating',
    [EditionType.Coverages]: 'coverages',
    [EditionType.DigitalAgent]: 'digital-agent',
    [EditionType.UnderwritingFilters]: 'underwriting-filters',
};

export function getReleasesUrl(productCode: string): string {
    return `/backoffice/loco/products/${productCode}/releases`;
}

export function getReleaseUrl(productCode: string, releasePublicId: string): string {
    return `${getReleasesUrl(productCode)}/${releasePublicId}`;
}

export function getEditionUrl(productCode: string, editionCode: string, editionType: EditionType): string {
    return `/backoffice/loco/products/${productCode}/${EDITION_TYPE_TO_PATH[editionType]}/${editionCode}`;
}

export function getProductUrl(productCode: string): string {
    return `/backoffice/loco/products/${productCode}`;
}

export function getProductsPageUrl(): string {
    return `/backoffice/loco/products`;
}

export function getSchemaUrl(
    productCode: string,
    productRevision: string | undefined,
    platformRevision: string | undefined
): string {
    const query = qs.stringify({ productRevision, platformRevision });
    return `/backoffice/loco/products/${productCode}/schema?${query}`;
}

export function getEditionSetUrl(productCode: string, editionSetCode: string): string {
    return `/backoffice/loco/products/${productCode}/edition-sets/${editionSetCode}`;
}
