import { Checkbox, Select, spacing, Text } from '@lemonade-hq/blender-ui';
import { InfoIcon } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import { useEffect } from 'react';
import type { RolloutStrategies } from './ManageReleaseDialog';
import { RolloutStrategy } from './ManageReleaseDialog';
import {
    dialogStepItemsWrapper,
    labelWithTooltip,
    NoticeWrapper,
    stepTitle,
    stepWrapper,
} from './ManageReleaseDialog.css';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import { DialogStep } from 'components/LoCo/common/components/Dialog/Dialog';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { LeanEdition } from 'models/LoCo/Insurance/BaseEdition';
import { useGetLatestsPublished } from 'queries/LoCo/Insurance/EditionTrackerQueries';
import { useGetProductEditionSets } from 'queries/LoCo/Insurance/ProductQueries';

function useRolloutStrategiesOptions(
    productCode: string,
    selectedEditions: LeanEdition[]
): {
    readonly newBusiness: { readonly disabled: boolean; readonly selected: boolean };
    readonly activePolicies: { readonly disabled: boolean; readonly selected: boolean };
    readonly showRating?: boolean;
    readonly banner?: string;
} {
    const { data: publishedEditionSets } = useGetProductEditionSets(productCode, true);
    const noPublishedEditionSets = publishedEditionSets?.data.length === 0;
    const allEligibleForMajorUpdate = selectedEditions.every(edition => edition.eligibleForMajorUpdate);
    const selectedRating = selectedEditions.find(edition => edition.type === EditionType.Rating);
    const showRating = selectedRating != null && selectedRating.referenceEditionContentCode == null;

    if (noPublishedEditionSets) {
        return {
            newBusiness: { disabled: true, selected: true },
            activePolicies: { disabled: true, selected: false },
        };
    }

    if (allEligibleForMajorUpdate) {
        return {
            newBusiness: { disabled: true, selected: true },
            activePolicies: { disabled: false, selected: false },
            showRating,
            banner: 'The selected editions are created from the latest effective editions, requiring this release to affect new business and renewals',
        };
    }

    return {
        newBusiness: { disabled: true, selected: false },
        activePolicies: { disabled: false, selected: true },
        showRating,
        banner: 'This release can affect only active policies and pending renewals. For new business and renewals release, all editions must be created from the latest effective editions',
    };
}

interface RolloutStrategyProductUpdateProps {
    readonly productCode: string;
    readonly selectedEditions: LeanEdition[];
    readonly selectedStrategies: DeepNullable<RolloutStrategies>;
    readonly selectedRatingEdition: string | null;
    readonly onChange: (values: {
        readonly rolloutStrategies?: {
            readonly [RolloutStrategy.ActivePoliciesAndPendingRenewals]: boolean;
            readonly [RolloutStrategy.NewBusinessAndRenewals]: boolean;
        };
        readonly ratingReferenceEditionCode?: string;
    }) => void;
}

export const RolloutStrategyProductUpdate: React.FC<RolloutStrategyProductUpdateProps> = ({
    productCode,
    selectedStrategies,
    selectedEditions,
    selectedRatingEdition,
    onChange,
}) => {
    const { newBusiness, activePolicies, showRating, banner } = useRolloutStrategiesOptions(
        productCode,
        selectedEditions
    );
    const { data: editions } = useGetLatestsPublished(productCode);

    const ratingEditions =
        editions
            ?.filter(edition => edition.editionContentType === EditionType.Rating)
            .map(edition => ({
                value: edition.editionContentCode,
                label: edition.friendlyName,
            })) ?? [];

    useEffect(() => {
        onChange({
            rolloutStrategies: {
                [RolloutStrategy.NewBusinessAndRenewals]: newBusiness.selected,
                [RolloutStrategy.ActivePoliciesAndPendingRenewals]: activePolicies.selected,
            },
        });
    }, []);

    return (
        <>
            <DialogStep padding="40px 64px">
                <Flex className={stepWrapper}>
                    <Flex className={stepTitle}>Who does this release affect?</Flex>
                    <Flex className={dialogStepItemsWrapper} mb={spacing.s24}>
                        <Flex className={labelWithTooltip}>
                            <Checkbox
                                checked={
                                    selectedStrategies == null
                                        ? undefined
                                        : selectedStrategies[RolloutStrategy.NewBusinessAndRenewals] ?? undefined
                                }
                                disabled={newBusiness.disabled}
                                id={RolloutStrategy.NewBusinessAndRenewals}
                                name="strategy-type"
                                onCheckedChange={checked =>
                                    onChange({
                                        rolloutStrategies: {
                                            [RolloutStrategy.NewBusinessAndRenewals]: checked === true,
                                            [RolloutStrategy.ActivePoliciesAndPendingRenewals]:
                                                selectedStrategies[RolloutStrategy.ActivePoliciesAndPendingRenewals] ??
                                                false,
                                        },
                                    })
                                }
                            />
                            <Text>New Business and Renewals</Text>
                        </Flex>
                        <Flex className={labelWithTooltip}>
                            <Checkbox
                                checked={
                                    selectedStrategies == null
                                        ? undefined
                                        : selectedStrategies[RolloutStrategy.ActivePoliciesAndPendingRenewals] ??
                                          undefined
                                }
                                disabled={activePolicies.disabled}
                                id={RolloutStrategy.ActivePoliciesAndPendingRenewals}
                                name="strategy-type"
                                onCheckedChange={checked =>
                                    onChange({
                                        rolloutStrategies: {
                                            [RolloutStrategy.ActivePoliciesAndPendingRenewals]: checked === true,
                                            [RolloutStrategy.NewBusinessAndRenewals]:
                                                selectedStrategies[RolloutStrategy.NewBusinessAndRenewals] ?? false,
                                        },
                                    })
                                }
                            />
                            <Text>Active policies and pending renewals</Text>
                        </Flex>
                    </Flex>
                    {showRating && selectedStrategies[RolloutStrategy.ActivePoliciesAndPendingRenewals] && (
                        <>
                            <Text>Select which Rating Edition to replace for existing policies</Text>
                            <Select
                                onChange={value => value != null && onChange({ ratingReferenceEditionCode: value })}
                                options={ratingEditions}
                                placeholder="Select Rating Edition"
                                selectedKey={selectedRatingEdition}
                            />
                        </>
                    )}
                </Flex>
            </DialogStep>
            {banner != null && (
                <Flex className={NoticeWrapper}>
                    <InfoIcon />
                    <div>{banner}</div>
                </Flex>
            )}
        </>
    );
};
