import type { AlertMode } from '@lemonade-hq/bluis';
import type { WorkflowSegment } from '@lemonade-hq/bluiza';
import type { SnakeCaseToCamelCase } from '@lemonade-hq/ts-helpers';
import axios from 'axios';
import { stringifyUrl } from 'query-string';
import type { PetPreapproval } from '../../models/Pet/PetPreapproval';
import type { DocumentRequestGroupDTO } from './PetBlenderDocumentRequestAPI';
import type { PetMrrProcessStatusTool } from './PetMrrStatus';
import type { MedicalExamDTO } from './PreapprovalAPI';
import type { PetWaitingPeriodWaiverTool } from './WaitingPeriodWaiver';
import { getUrlResolver } from 'commons/UrlResolver';
import type { MRCompletenessHistory, MedicalRecordsCompleteness } from 'components/pet/MedicalRecords/MedicalRecordsCompleteness/utils';
import type { GroupReferenceType } from 'components/pet/PetClaimWorkflows/GroupReferenceType';
import type { ReferenceType, TaskType } from 'components/pet/PetClaimWorkflows/TaskType';
import type { Attachment, AttachmentType, PetAttachmentAutoItemization } from 'models/Attachment';
import type {
    PaymentBreakdownResponse,
    PaymentRequestResponse,
    PetPaymentControlPermissions,
    PetPaymentRequestDetails,
    Transaction,
} from 'models/Payment';
import type { CoOwner, Pet, PetType } from 'models/Pet';
import type { EmailTask } from 'models/Pet/EmailTask';
import type {
    AutoDeclineReason,
    AutoItemizedItems,
    ClaimAutoHandlingResultDTO,
    PetClaim,
    PetClaimAction,
    PetClaimEligibility,
    PetClaimEvent,
    Status as PetClaimStatus,
    PetClaimType,
    PetPolicyDetails,
    PetUserDetails,
    TagTypes,
} from 'models/PetClaim';
import type {
    FilterNames,
    PetClaimsHistoryEntity,
    PetClaimsHistoryMedicalConditionEntity,
} from 'models/PetClaimsHistory';
import type { PetClaimItem as PetClaimItemSnakeCase, PetFeatureType } from 'models/PetFeaturesAndItems';
import type { MedicalRecord, PetMedicalReview, PolicyDTO, PolicyEligibilitiesDto } from 'models/PetPolicy';
import type { BaseCoveragesData, ClaimDocumentReview, ClaimMedicalRecordReview, Coverage } from 'models/PetQuote';
import type { PetClaimSummaryType, PetSummary } from 'models/PetSummary';
import type { AutomatedEobRejectionReasons } from 'queries/Pet/PetBlenderClaimEobsQueries';

const petBlenderClaimsUrlResolver = getUrlResolver('pet-blender', '/api/v1/claims');

export enum ClaimResponseKeys {
    Actions = 'actions',
    MedicalRecord = 'medical_exam',
    PetMedicalReview = 'pet_medical_review',
    WorkflowAvailable = 'workflows_enabled',
    Workflow = 'claim_workflows',
    WorkflowGroups = 'claim_tasks_groups',
    WorkflowsNoteId = 'workflows_note_id',
    UserDetails = 'user_details',
    Policy = 'policy',
    PolicyDetails = 'policy_details',
    PolicyBaseCoverage = 'policy_base_coverage',
    ClaimEvents = 'claim_events',
    Claim = 'claim',
    ClaimEligibility = 'claim_eligibility',
    ClaimTags = 'claim_tags',
    ClaimMedia = 'claim_media',
    ClaimOverview = 'claim_overview',
    ClaimCoverages = 'claim_coverages',
    ClaimHistory = 'claim_history',
    ClaimAssignee = 'claim_assignee',
    ClaimReceipt = 'claim_receipt',
    ClaimReceiptAttachments = 'claim_receipt_attachments',
    ClaimFeatures = 'claim_features',
    ClaimSummary = 'claim_summary',
    ClaimPet = 'pet',
    ClaimDocumentReview = 'claim_document_review',
    ClaimLifecycle = 'claim_lifecycle',
    PoliciesEffectiveDates = 'policies_effective_dates',
    MedicalRecordReview = 'claim_medical_record_review',
    DocumentRequests = 'document_requests',
    Pet = 'pet',
    ValidateClaimConditionQuestions = 'validate_claim_condition_questions',
    ReviewClaimItemsQuestions = 'review_claim_items_questions',
    ClaimMedicalConditionRiskFlags = 'claim_medical_condition_risk_flags',
    ValidateClaimConditionsOptOutFlags = 'validate_claim_conditions_optout_flags',
    RiskAssessment = 'risk_assessment',
    PreExistingConditions = 'pre_existing_conditions',
    Preapprovals = 'preapprovals',
    EmailTask = 'email_task',
    AutoItemizedItems = 'auto_itemized_items',
    AutoItemizedInvoices = 'auto_itemized_invoices',
    AutomatedEobQualifications = 'automated_eob_qualifications',
    ClaimDocsUploadFlowUrl = 'claim_docs_upload_flow_url',
    AutoApproveLog = 'auto_approve_log',
    ClaimChatLog = 'claim_chat_log',
    ReservesChanges = 'reserves_changes',
    ClaimMath = 'claim_math',
    AutoApproveLogV2 = 'auto_approve_log_v2',
    ClaimMedicalConditions = 'claim_medical_conditions',
    ConditionsPredictionExecution = 'conditions_prediction_execution',
    PreventativeMedicalProcedures = 'preventative_medical_procedures',
    PolicyEligibilities = 'policy_eligibilities',
    PetWaitingPeriodWaiverTool = 'pet_waiting_period_waiver_tool',
    PetMrrProcessStatus = 'pet_mrr_process_status',
    RejectionReasons = 'automated_eob_rejection_reasons',
    ClaimSettlementData = 'claim_settlement_data',
    MultiFeaturePaymentControlAlert = 'multi_feature_payment_control_alert',
    PaymentRequestDetails = 'payment_request_details',
    PaymentBreakdown = 'payment_breakdown',
    PaymentControlPermissions = 'payment_control_permissions',
    PaymentRequest = 'payment_request',
    OpenPaymentRequestsAlert = 'open_payment_requests_alert',
    PetMedicalRecordsCompleteness = 'pet_medical_records_completeness',
    MissingInfoAutoEmail = 'missing_info_auto_email',
    EipdMacros = 'eipd_macros',
    ItemReviewTask = 'item_review_task',
    ExpectedMetLimits = 'expected_met_limits',
    PetMedicalRecordsCompletenessHistory = 'pet_medical_records_history',
}

export type PetBlenderClaim = SnakeCaseToCamelCase<PetClaim> & {
    readonly autoDeclineReason: AutoDeclineReason | null;
    readonly closable: boolean;
};

export enum MediaStatus {
    Deleted = 'Deleted',
    Submitted = 'Submitted',
}

export interface Media {
    readonly url: string;
    readonly takenAt?: string;
    readonly mediaTakenAt?: string;
    readonly createdAt: string;
    readonly type: AttachmentType;
    readonly transcription?: string;
    readonly transcriptionConfidence?: number;
    readonly status: MediaStatus;
}

export type FilterOptions = Partial<{
    readonly [FilterNames.Status]: {
        readonly code: PetClaimStatus;
        readonly text: string;
    }[];
    readonly [FilterNames.Type]: {
        readonly code: PetClaimType;
        readonly text: string;
    }[];
    readonly [FilterNames.MedicalCondition]: {
        readonly category: {
            readonly code: string;
            readonly text: string;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly featureTypes: string[];
        };
        readonly conditions: {
            readonly code: string;
            readonly text: string;
        }[];
    }[];
}>;

export interface ClaimHistoryDTO {
    readonly petClaims: {
        readonly rows: PetClaimsHistoryEntity[];
        readonly filterOptions: FilterOptions;
        readonly aggregatedPaidAmount: string;
    };
    readonly otherPetClaims: {
        readonly rows: PetClaimsHistoryEntity[];
        readonly filterOptions: FilterOptions;
        readonly aggregatedPaidAmount: string;
    };
    readonly petMedicalConditions: {
        readonly rows: PetClaimsHistoryMedicalConditionEntity[];
        readonly filterOptions: FilterOptions;
        readonly aggregatedPaidAmount: string;
    };
    readonly sameVetVisitDateAlert?: string;
}

export interface ClaimEligibilityDTO {
    readonly eligible: boolean;
    readonly dueDate?: string;
    readonly eligibilityDate?: string;
    readonly eligibilityWaiverIgnoredDate?: string;
}

export interface ClaimLifecycleDTO {
    readonly policyEffective: string;
    readonly accidentEligibility?: ClaimEligibilityDTO;
    readonly illnessEligibility?: ClaimEligibilityDTO;
    readonly cruciateLigamentEligibility?: ClaimEligibilityDTO;
    readonly orthopedicEligibility?: ClaimEligibilityDTO;
    readonly occurrenceDate: string;
    readonly dateOfVetVisit: string;
    readonly claimStarted: string;
    readonly claimSubmitted: string;
    readonly claimSettled: string;
    readonly claimClosed: string;
    readonly isFollowUp: boolean;
    readonly waitingPeriodWaiverFormAlert?: string;
}

export type PetClaimItem = SnakeCaseToCamelCase<PetClaimItemSnakeCase>;

export type ClaimFeatures = {
    readonly [key in PetFeatureType]: PetClaimItem[];
};

export type ClaimReceiptData = {
    readonly markedAsAdequate?: boolean | null;
    readonly hasProofOfPayment?: boolean | null;
    readonly matchesClaimDetails: boolean | null;
    readonly userEstimatedCost?: number | null;
    readonly petNameMatchesInvoice?: boolean | null;
    readonly ownerNameMatchesInvoice?: boolean | null;
};

export type PolicyBaseCoverage = {
    readonly limit: string;
    readonly deductible: string;
    readonly coinsurancePercent: number;
};

export type PetWorkflowGroup = {
    readonly title: string;
    readonly referenceType: GroupReferenceType;
    readonly publicId: string;
};

export type UpdateClaimReviewTaskWorkFlowPayload = {
    readonly claimPublicId: string;
    readonly isCompleted: boolean;
    readonly additionalInfo?: string | undefined;
    readonly documentReviewPublicId: string | undefined;
};

export type UpdateEmailTaskPayload = {
    readonly claimPublicId: string;
    readonly text: string;
    readonly publicId?: string;
};

export type UpdateClaimDocumentReviewPayload = UpdateClaimReviewTaskWorkFlowPayload;

export type UpdateMedicalRecordReviewPayload = UpdateClaimReviewTaskWorkFlowPayload;

export type CreateClaimObtainDocument = {
    readonly claimPublicId: string;
    readonly taskType: TaskType;
};

export interface ClaimPetDTO extends Pet {
    readonly recordedBirthdate?: string;
    readonly neutered?: boolean;
    readonly adoptionDate?: string;
    readonly previousInsurance?: boolean;
    readonly coOwners: CoOwner[];
}

export type PolicyEffectiveDate = {
    readonly effectiveAt: Date;
};

export enum OptOutDescriptionFlag {
    ValidationTasksCompleted = 'validationTasksCompleted',
    HasOpenTodoTasks = 'hasOpenTodoTasks',
    ClaimOptOut = 'claimOptout',
    BlockerDueObtainDocuments = 'blockerDueObtainDocuments',
    BlockerDueRiskFlags = 'blockerDueRiskFlags',
}

export type OptOutFlags = {
    readonly [key in OptOutDescriptionFlag]: boolean;
};

export enum RiskAssessmentKeys {
    HighRiskCondition = 'highRiskCondition',
    RecurringCondition = 'recurringCondition',
    NeedAdditionalDocuments = 'needAdditionalDocuments',
    NeedMedicalRecordReview = 'needMedicalRecordReview',
    VetRecommendation = 'vetRecommendation',
    IsAIFeatureRequirePreventativeCoverage = 'isAIFeatureRequirePreventativeCoverage',
    IsAdditionalReviewRequired = 'isAdditionalReviewRequired',
    IsAdditionalReviewRequiredOrthopedic = 'isAdditionalReviewRequiredOrthopedic',
    IsAdditionalReviewRequiredCruciateLigament = 'isAdditionalReviewRequiredCruciateLigament',
    IsRequiringDentalIllnessAddOn = 'isRequiringDentalIllnessAddOn',
    AdditionalReviewRequiredOrthopedic = 'additionalReviewRequiredOrthopedic',
}

export enum ClaimMedicalConditionRiskFlag {
    IsBehavioral = 'isBehavioral',
    IsAdequateCareDependant = 'isAdequateCareDependant',
}

export type ClaimMedicalConditionRiskFlags = {
    readonly claimMedicalConditionRiskFlags: ClaimMedicalConditionRiskFlag[];
};

export type RiskAssessment = {
    readonly [key in RiskAssessmentKeys]: boolean | null;
};

export type PetClaimChatLog = {
    readonly question: string;
    readonly answer: string;
}[];

export interface ReserveChange {
    readonly changeDate: string;
    readonly claimItemName: string;
    readonly type: string;
    readonly amount: string;
    readonly changedBy: string;
    readonly publicId: string;
    readonly claimItemId: string;
}

export interface ReservesChangesDTO {
    readonly reservesChanges: ReserveChange[];
    readonly totalReservesAmount: string;
}

export interface ClaimMathItem {
    readonly label: string;
    readonly cost: string;
}

export interface ClaimMath {
    readonly attributes: ClaimMathItem[];
}
export interface Log {
    readonly condition: string;
    readonly result: boolean;
    readonly overridden: boolean;
    readonly error: boolean;
}

export interface AfterClaimSubmittedDTO {
    readonly availability: {
        readonly available: boolean;
        readonly reason: string | null;
    };
    readonly logs: Log[];
}

export enum PhasesKeys {
    AFTER_CLAIM_SUBMITTED = 'after-claim-submitted',
    AFTER_CLAIM_VALIDATION = 'after-claim-validation',
}

export interface AutoApproveLogLegacy {
    readonly isAutoClaimApprovedAfterSubmit: boolean;
    readonly isLegacyAutoHandleAfterSubmit: true;
    readonly phases: {
        readonly [PhasesKeys.AFTER_CLAIM_SUBMITTED]: AfterClaimSubmittedDTO;
        readonly [PhasesKeys.AFTER_CLAIM_VALIDATION]: ClaimAutoHandlingResultDTO;
    };
}

export interface AutoApproveLogNew {
    readonly isAutoClaimApprovedAfterSubmit: boolean;
    readonly isLegacyAutoHandleAfterSubmit: false;
    readonly phases: {
        readonly [PhasesKeys.AFTER_CLAIM_SUBMITTED]: ClaimAutoHandlingResultDTO;
        readonly [PhasesKeys.AFTER_CLAIM_VALIDATION]: ClaimAutoHandlingResultDTO;
    };
}

export type AutoApproveLog = AutoApproveLogLegacy | AutoApproveLogNew;

type RiskQuestions = {
    readonly questions: RiskAssessmentKeys[];
};

export interface ClaimMedicalConditionDTO {
    readonly category: string;
    readonly condition: string;
    readonly eligibility: string;
}

export enum ConditionsPredictionExecutionStatus {
    Approved = 'approved',
    Rejected = 'rejected',
}

export interface ConditionsPredictionGroupDto {
    readonly petPublicId: string;
    readonly error: string;
}

export interface ConditionsPredictionExecutionDto {
    readonly claimPublicId: string;
    readonly status: ConditionsPredictionExecutionStatus;
    readonly amountLimitReached: boolean | null;
    readonly onPreExistingPeriod: boolean | null;
    readonly isInControlGroup: boolean | null;
    readonly conditionsPredictionGroup: ConditionsPredictionGroupDto;
}

export interface PreventativeMedicalProcedure {
    readonly code?: string;
    readonly text: string;
}

export enum AutomatedEOBAlert {
    NotSupported = 'not_supported',
    SupportedWithSomeRejectedItems = 'supported_with_some_rejected_items',
    SupportedAllItemsActive = 'supported_all_items_active',
}

export interface AutomatedEobQualificationsDTO {
    readonly isOptOutCTAEnabled: boolean;
    readonly isCreateSettlementEmailTaskCTAEnabled: boolean;
    readonly isSettlementEmailTaskExist: boolean;
    readonly alertType: AutomatedEOBAlert;
    readonly tips: string[] | null;
}

export enum AutomateEOBOptOutReason {
    ComplexClaim = 'complex_claim',
    SensitiveClaim = 'sensitive_claim',
    NeedToSendManualEmailAnyway = 'need_to_send_a_manual_email_anyway',
    Automatic = 'automatic',
    Other = 'other',
}

export interface MissingInfoAutoEmail {
    readonly isOptedOut?: boolean;
    readonly invoiceInvalidReason?: string;
    readonly needNocOrEOB?: boolean;
    readonly needAdditionalDocuments?: boolean | null;
    readonly sentAt?: Date;
}

export interface UpdateMissingInfoEmailParams {
    readonly claimPublicId: string;
    readonly invoiceInvalidReason?: string;
    readonly needNocOrEOB?: boolean;
}

export interface EipdMacros {
    readonly macros: {
        readonly claimDeterminationTaxOrBiohazard?: string;
        readonly claimDeterminationAutoSettlementEmail?: string;
    };
}

export interface ItemReviewData {
    readonly additionalInformation?: string;
    readonly hasBiohazardOrTax?: boolean;
}

export interface ExpectedMetLimits {
    readonly limits: string[];
}

// TODO: Add segment type for camelCase workflow
export interface PetBlenderClaimResponse {
    readonly medical_exam: MedicalRecord;
    readonly pet_medical_review: PetMedicalReview;
    readonly policy: PolicyDTO;
    readonly policy_details: PetPolicyDetails;
    readonly policy_base_coverage: PolicyBaseCoverage | null;
    readonly workflows_enabled: { readonly available: boolean };
    readonly claim_workflows: {
        readonly segments: WorkflowSegment<typeof TaskType, undefined, typeof ReferenceType>[];
        readonly taskGroups: PetWorkflowGroup[];
        readonly addTaskOptionList: TaskType[];
    };
    readonly claim_tasks_groups: PetWorkflowGroup[];
    readonly workflows_note_id: string | null;
    readonly claim: PetBlenderClaim;
    readonly auto_itemized_invoices: PetAttachmentAutoItemization[];
    readonly auto_itemized_items: AutoItemizedItems;
    readonly user_details: PetUserDetails;
    readonly claim_events: PetClaimEvent[];
    readonly claim_tags: TagTypes[];
    readonly claim_eligibility: SnakeCaseToCamelCase<PetClaimEligibility>;
    readonly claim_media: Media[];
    readonly claim_overview: { readonly description: string };
    readonly claim_coverages: {
        readonly baseCoverages: BaseCoveragesData;
        readonly endorsements: Coverage[];
        readonly hasDeductibleDowngraded: boolean;
    };
    readonly claim_history: ClaimHistoryDTO;
    readonly claim_receipt: ClaimReceiptData;
    readonly claim_receipt_attachments: Attachment[];
    readonly claim_assignee: { readonly publicId: string };
    readonly claim_features: {
        readonly editableClaimItems: boolean;
        readonly featureTypes: PetFeatureType[];
        readonly features: ClaimFeatures;
        readonly itemListIncomplete: boolean;
        readonly closableClaim: boolean;
        readonly behavioralHandledItemsAlert: string | null;
        readonly massageExcludedAlert: {
            readonly text: string;
            readonly mode: AlertMode;
        } | null;
    };
    readonly claim_summary: PetSummary;
    readonly claim_document_review: ClaimDocumentReview;
    readonly claim_medical_record_review: ClaimMedicalRecordReview;
    readonly policies_effective_dates: PolicyEffectiveDate[];
    readonly document_requests: DocumentRequestGroupDTO;
    readonly pet: ClaimPetDTO;
    readonly validate_claim_condition_questions: RiskQuestions;
    readonly review_claim_items_questions: RiskQuestions;
    readonly claim_medical_condition_risk_flags: ClaimMedicalConditionRiskFlags;
    readonly validate_claim_conditions_optout_flags: OptOutFlags;
    readonly risk_assessment: RiskAssessment;
    readonly email_task: EmailTask;
    readonly pre_existing_conditions: {
        readonly policyId: string;
        readonly animalTypeCode: PetType;
        readonly petPublicId: string;
        readonly medicalExam: MedicalExamDTO;
        readonly timezone: string;
        readonly preconditionsCodes: string[];
    };
    readonly actions: PetClaimAction[];
    readonly preapprovals: PetPreapproval[];
    readonly claim_lifecycle: ClaimLifecycleDTO;
    readonly automated_eob_qualifications: AutomatedEobQualificationsDTO;
    readonly claim_docs_upload_flow_url: string;
    readonly auto_approve_log: AutoApproveLog;
    readonly claim_chat_log: PetClaimChatLog;
    readonly reserves_changes: ReservesChangesDTO;
    readonly claim_math: ClaimMath;
    readonly auto_approve_log_v2: AutoApproveLog;
    readonly claim_medical_conditions: ClaimMedicalConditionDTO[];
    readonly conditions_prediction_execution: ConditionsPredictionExecutionDto | null;
    readonly preventative_medical_procedures: PreventativeMedicalProcedure[];
    readonly policy_eligibilities: PolicyEligibilitiesDto | null;
    readonly pet_waiting_period_waiver_tool: PetWaitingPeriodWaiverTool;
    readonly pet_mrr_process_status: PetMrrProcessStatusTool;
    readonly automated_eob_rejection_reasons: AutomatedEobRejectionReasons;
    readonly claim_settlement_data: {
        readonly emailWillBeSent: boolean;
    };
    readonly transactions: Transaction[][];
    readonly multi_feature_payment_control_alert: boolean;
    readonly payment_request_details: PetPaymentRequestDetails;
    readonly payment_control_permissions: PetPaymentControlPermissions;
    readonly payment_breakdown: PaymentBreakdownResponse;
    readonly payment_request: PaymentRequestResponse;
    readonly open_payment_requests_alert: string | null;
    readonly pet_medical_records_completeness: MedicalRecordsCompleteness;
    readonly missing_info_auto_email: MissingInfoAutoEmail;
    readonly eipd_macros: EipdMacros;
    readonly item_review_task: ItemReviewData;
    readonly expected_met_limits: ExpectedMetLimits;
    readonly pet_medical_records_history: MRCompletenessHistory;
}

export async function fetchClaimData<K extends keyof PetBlenderClaimResponse>(
    claimPublicId: string,
    keys: K[],
    taskReferenceId?: string | null
): Promise<PetBlenderClaimResponse> {
    const url = stringifyUrl(
        {
            url: petBlenderClaimsUrlResolver(`/${claimPublicId}/data`),
            query: { include: keys, ...(taskReferenceId != null && { taskReferenceId }) },
        },
        { arrayFormat: 'bracket' }
    );

    return await axios.get<{ data: PetBlenderClaimResponse }>(url).then(response => {
        return response.data.data;
    });
}

export async function updateClaimReceipt({
    claimPublicId,
    markedAsAdequate,
    hasProofOfPayment,
    matchesClaimDetails,
    userEstimatedCost,
    petNameMatchesInvoice,
    ownerNameMatchesInvoice,
}: {
    readonly claimPublicId: string;
    readonly markedAsAdequate?: boolean | null;
    readonly hasProofOfPayment?: boolean | null;
    readonly matchesClaimDetails?: boolean | null;
    readonly userEstimatedCost?: string | null;
    readonly petNameMatchesInvoice?: boolean | null;
    readonly ownerNameMatchesInvoice?: boolean | null;
}) {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/receipt`);

    return await axios
        .put(url, {
            markedAsAdequate,
            hasProofOfPayment,
            matchesClaimDetails,
            userEstimatedCost,
            petNameMatchesInvoice,
            ownerNameMatchesInvoice,
        })
        .then(response => {
            return response.data.data;
        });
}

export async function updateClaimCoOwner({
    claimPublicId,
    coOwnerId,
}: {
    readonly claimPublicId: string;
    readonly coOwnerId: string | null;
}): Promise<null> {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}`);
    const updatedClaimData = { coOwnerPublicId: coOwnerId };

    return await axios.put(url, updatedClaimData).then(response => {
        return response.data.data;
    });
}

export async function updateClaimDocumentReview({
    claimPublicId,
    documentReviewPublicId,
    isCompleted,
    additionalInfo,
}: UpdateClaimDocumentReviewPayload) {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/document_review/${documentReviewPublicId}`);

    return await axios.put(url, { isCompleted, additionalInfo }).then(response => {
        return response.data.data;
    });
}

export async function updateMedicalRecordReview({
    claimPublicId,
    isCompleted,
    additionalInfo,
}: UpdateMedicalRecordReviewPayload) {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/medical_record_review`);

    return await axios.put(url, { isCompleted, additionalInfo }).then(response => {
        return response.data.data;
    });
}

export async function startHandlingClaim({
    claimPublicId,
    operatorId,
}: {
    readonly claimPublicId: string;
    readonly operatorId: string;
}): Promise<null> {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/start_handling`);

    return await axios.post(url, { operatorId });
}

export interface UpdateClaimGrouped {
    readonly claimPublicId: string;
    readonly hasGroupedItems: boolean;
}

export async function markHasGroupedItems({ claimPublicId, hasGroupedItems }: UpdateClaimGrouped) {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}`);
    const updatedClaimData = { grouping: hasGroupedItems };

    return await axios.put(url, updatedClaimData).then(response => {
        return response.data.data;
    });
}

export async function editSummary({
    claimPublicId,
    summaryPublicId,
    content,
    isSubmitted,
    operatorName,
    taskPublicId,
}: {
    readonly claimPublicId: string;
    readonly summaryPublicId: string;
    readonly content?: string;
    readonly isSubmitted: boolean;
    readonly operatorName: string;
    readonly taskPublicId: string;
}): Promise<void> {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/summaries/${summaryPublicId}`);

    const body = {
        content,
        isSubmitted,
        operatorName,
        taskPublicId,
    };

    await axios.put(url, body).then(response => response.data.data);
}

export async function reopenClaim({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = petBlenderClaimsUrlResolver(`/${claimId}/reopen_claim`);

    return await axios.post(url).then(response => response.data.data);
}

export async function createSummary({
    claimPublicId,
    type,
    content,
}: {
    readonly claimPublicId: string;
    readonly type: PetClaimSummaryType;
    readonly content: string;
}): Promise<void> {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/summaries`);

    const body = {
        type,
        content,
    };

    await axios.post(url, body).then(response => response.data.data);
}

export async function updateEmailTask({
    claimPublicId,
    publicId,
    text,
}: {
    readonly claimPublicId: string;
    readonly text: string;
    readonly publicId?: string;
}) {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/email_tasks/${publicId}`);

    return await axios.put(url, { text }).then(response => {
        return response.data.data;
    });
}

export async function updateClaimDetails({
    claimPublicId,
    visitDate,
    occurrenceDate,
    type,
}: {
    readonly claimPublicId: string;
    readonly visitDate?: string | null;
    readonly occurrenceDate?: string | null;
    readonly type?: PetClaimType;
}) {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}`);
    const updatedClaimData = { visitDate, occurrenceDate, type };

    return await axios.put(url, updatedClaimData).then(response => {
        return response.data.data;
    });
}

export async function updateRiskAssessment({
    claimPublicId,
    property,
    value,
}: {
    readonly claimPublicId: string;
    readonly property: RiskAssessmentKeys;
    readonly value: boolean;
}) {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/risk_assessments`);

    return await axios
        .put(url, {
            [property]: value,
        })
        .then(response => {
            return response.data.data;
        });
}

export async function optOut({ claimPublicId, reason }: { readonly claimPublicId: string; readonly reason: string }) {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/claim-auto-handle-optouts`);

    return await axios.post(url, { reason, phaseName: 'after-claim-validation' }).then(response => {
        return response.data.data;
    });
}


export async function missingInfoEmailOptOut({
    claimPublicId,
    optOutReason,
}: {
    readonly claimPublicId: string;
    readonly optOutReason: string;
}) {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/missing-info-email/opt-out`);

    return await axios.post(url, { optOutReason }).then(response => {
        return response.data.data;
    });
}

export async function updateMissingInfoEmail({
    claimPublicId,
    invoiceInvalidReason,
    needNocOrEOB,
}: UpdateMissingInfoEmailParams) {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/missing-info-email`);

    return await axios.put(url, { invoiceInvalidReason, needNocOrEOB }).then(response => {
        return response.data.data;
    });
}

export async function updateItemReviewData({
    claimPublicId,
    additionalInformation,
    hasBiohazardOrTax,
}: {
    readonly claimPublicId: string;
    readonly additionalInformation?: string;
    readonly hasBiohazardOrTax?: boolean;
}) {
    const url = petBlenderClaimsUrlResolver(`/${claimPublicId}/item-review`);

    return await axios.put(url, { additionalInformation, hasBiohazardOrTax }).then(response => {
        return response.data;
    });
}
