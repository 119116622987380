import axios from 'axios';
import { AssignmentEntityType } from 'apis/ClaimsAPITyped';
import type { SendEmailArgsWithEntityId } from 'apis/EmailsAPI';
import { sendEmail as sendEmailResolver } from 'apis/EmailsAPI';
import {
    addCustomRecipient as addCustomRecipientGeneric,
    assignInteraction as assignInteractionGeneric,
    createNote as createNoteGeneric,
    dismissInteraction as dismissInteractionGeneric,
    manualResponseInteraction as manualResponseInteractionGeneric,
} from 'apis/InteractionsAPI';
import type { Carrier } from 'commons/StripeCarriers';
import { getUrlResolver } from 'commons/UrlResolver';
import type { PetPolicyView } from 'components/PetPolicy/types';
import type { Adjuster, Adjusters } from 'models/Adjuster';
import type { ClaimAdjusterFees } from 'models/AdjusterFees';
import type { Attachment } from 'models/Attachment';
import type { ClaimPriority, PriorityTypes } from 'models/ClaimPriority';
import type { FirstResponse, NoteCreateRequest } from 'models/Interaction';
import type { AvailablePayeeType, Payee, PayeesPayoutMethods } from 'models/Payee';
import type { PaymentInfo, PaymentMethodType, Transaction } from 'models/Payment';
import type { PetType } from 'models/Pet';
import type {
    Status as ClaimStatus,
    MedicalCondition,
    PetClaim,
    PetClaimSearchResult,
    PetClaimTags,
    PetClaimType,
    PetRejectionReasonsItem,
    PetStatus,
    TreatmentPlace,
    VetNote,
} from 'models/PetClaim';
import type {
    FeaturesAndItems,
    MedicalProceduresByFeatures,
    PetClaimProcedures,
    PetItemType,
} from 'models/PetFeaturesAndItems';
import { PetFeatureType } from 'models/PetFeaturesAndItems';
import type { MedicalProcedureType } from 'models/PetMedicalConditionTimeline';
import type { PetPolicy, RedeemedPreventativeCare } from 'models/PetPolicy';
import type { Coverage, PetQuote } from 'models/PetQuote';
import type { PetUser } from 'models/PetUser';
import type { ProcedureToInstantClaimErrorMap } from 'models/ProcedureToInstantClaimErrorMap';
import { EntityTypes, ProductTypes } from 'models/Products';
import type { Recipient } from 'models/Recipient';

export type SearchOrderByFields = 'loss_type' | 'settled' | 'status' | 'submitted_at' | 'user.first_name.raw';

export interface PetClaimResponse {
    readonly claim: PetClaim;
    readonly policy: PetPolicy;
    readonly tags: PetClaimTags;
    readonly user: PetUser;
    readonly available_claim_types: PetClaimType[];
    readonly claim_features: FeaturesAndItems;
    readonly claim_adjuster_fees: ClaimAdjusterFees;
    readonly coinsurance_percent: number;
    readonly available_feature_types: PetFeatureType[];
    readonly editable_claim_items: boolean;
    readonly transactions: Transaction[][];
    readonly timezone: string;
    readonly adjusters: Adjusters;
    readonly possible_recipients: Recipient[];
    readonly possible_recipients_v2: Recipient[];
    readonly priorities: ClaimPriority[];
    readonly payees: Payee[];
    readonly editable_claim_payees: boolean;
    readonly available_payees_types: AvailablePayeeType[];
    readonly procedure_to_instant_claim_error_map: ProcedureToInstantClaimErrorMap;
    readonly claim_coverages?: ClaimCoverage;
    readonly quote: PetQuote;
    readonly payment_methods: PayeesPayoutMethods[];
    readonly attachments: Attachment[];
    readonly claim_medical_conditions: MedicalCondition[];
}

export interface Alert {
    readonly type: string;
    readonly category: string;
    readonly title: string;
    readonly data: unknown;
    readonly context?: string;
    readonly priority?: string;
    readonly actions?: { readonly type: string; readonly title: string }[];
}

export interface ClaimCoverage {
    readonly policy: PetPolicyView;
    readonly quote: {
        readonly coinsurance_percent: number;
        readonly base_deductible: number;
        readonly coverage_limit: number;
        readonly coverages: Coverage[];
        readonly end_of_life_limits: {
            readonly limit: number;
            readonly remaining_limit: number;
            readonly deductible: number;
            readonly coinsurance_percent: number;
        };
        readonly dental_illness_limits?: {
            readonly limit: number;
            readonly remaining_limit: number;
        };
        readonly public_id?: string;
    };
    readonly redeemed_preventative_care: RedeemedPreventativeCare;
    readonly remaining_coverage_limit_amount: number;
    readonly remaining_deductible: number;
}

interface PetClaimsSearchParamsResponse {
    readonly statuses: ClaimStatus[];
    readonly settled: boolean[];
    readonly loss_types: PetClaimType[];
    readonly adjusters: {
        readonly full_name: string;
        readonly photo_url: string;
        readonly public_id: string;
        readonly operator_public_id: string;
    }[];
}

interface PetClaimsSearchParams {
    readonly offset: number;
    readonly order_by: SearchOrderByFields;
    readonly order_direction: 'asc' | 'desc';
    readonly status?: ClaimStatus[];
    readonly settled?: boolean;
    readonly loss_type?: PetClaimType;
    readonly adjuster_public_id: string;
}

export interface PetClaimsSearchResponse {
    readonly claims: PetClaimSearchResult[];
    readonly offset: number;
    readonly page_size: number;
    readonly size: number;
    readonly total: number;
}

interface ManualClaimDataResponse {
    readonly policy: PetPolicy;
    readonly user: PetUser;
    readonly allowed_manual_claim: boolean;
    readonly claim_types: PetClaimType[];
    readonly pet_statuses: PetStatus[];
    readonly address_timezone?: string;
    readonly treatment_places?: { readonly public_id: string; readonly name: string }[];
}

const BASE_URL = '/backoffice/pet/claims';
const petBlenderUrlResolver = getUrlResolver('pet-blender');

export async function fetchPetClaimData<K extends keyof PetClaimResponse>(
    claimId: string,
    keys: K[]
): Promise<Pick<PetClaimResponse, K>> {
    const mapKeys = (key: string) => `include[]=${key}`;
    const url = `/backoffice/pet/claims/${claimId}/data?${encodeURI(keys.map(mapKeys).join('&'))}`;

    return await axios.get<{ data: Pick<PetClaimResponse, K> }>(url).then(response => response.data.data);
}

export async function fetchSearchParams(): Promise<PetClaimsSearchParamsResponse> {
    const url = '/backoffice/pet/claims/search_params';

    return await axios.get<{ data: PetClaimsSearchParamsResponse }>(url).then(response => response.data.data);
}

export async function getPetClaimNotifications(claimPublicId: string): Promise<Alert[]> {
    const url = petBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/alerts`);

    return await axios.get<{ data: Alert[] }>(url).then(response => response.data.data);
}

export async function getPetClaimsByPetId(petId: string): Promise<PetClaim[]> {
    const url = '/backoffice/pet/claims';

    return await axios
        .get<{ data: PetClaim[] }>(url, {
            params: {
                pet_id: petId,
            },
        })
        .then(response => response.data.data);
}

export async function searchPetClaims(searchParams: Partial<PetClaimsSearchParams>): Promise<PetClaimsSearchResponse> {
    const url = '/backoffice/pet/claims/search';

    return await axios.post<{ data: PetClaimsSearchResponse }>(url, searchParams).then(response => response.data.data);
}

export async function markSettled({
    claimId,
    settled,
}: {
    readonly claimId: string;
    readonly settled: boolean;
}): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}/${settled ? 'mark_settled' : 'mark_not_settled'}`;

    return await axios.post(url).then(response => response.data.data);
}

export async function cancelClaim({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}/cancel`;

    return await axios.post(url).then(response => response.data.data);
}

export async function closeClaim({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}/close`;

    return await axios.post(url).then(response => response.data.data);
}

export async function simulateAutoDecline({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}/simulate_auto_decline`;

    return await axios.post(url).then(response => response.data.data);
}

export async function connectClaimToPreapproval({
    claimId,
    preapprovalPublicId,
    operatorId,
}: {
    readonly claimId: string;
    readonly preapprovalPublicId: string;
    readonly operatorId: string;
}): Promise<void> {
    const url = petBlenderUrlResolver(`/api/v1/preapprovals/${preapprovalPublicId}/connect-claim`);

    return await axios.patch(url, { claimPublicId: claimId, operatorId }).then(response => response.data.data);
}

export async function disconnectClaimFromPreapproval({
    claimId,
    preapprovalPublicId,
    operatorId,
}: {
    readonly claimId: string;
    readonly preapprovalPublicId: string;
    readonly operatorId: string;
}): Promise<void> {
    const url = petBlenderUrlResolver(`/api/v1/preapprovals/${preapprovalPublicId}/disconnect-claim`);

    return await axios.patch(url, { claimPublicId: claimId, operatorId }).then(response => response.data.data);
}

export async function simulateAutoApproval({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}/simulate_auto_approval`;

    return await axios.post(url).then(response => response.data.data);
}

export async function editClaim({
    claimId,
    claim,
    operatorId,
}: {
    readonly claimId: string;
    readonly claim: Partial<PetClaim>;
    readonly operatorId?: string;
}): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}`;

    return await axios.put(url, { ...claim, operator_id: operatorId }).then(response => response.data.data);
}

export async function markAsErrorItem({
    claimId,
    itemPublicId,
}: {
    readonly claimId: string;
    readonly itemPublicId: string;
}): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}/items/${itemPublicId}/mark_instant_claim_error`;

    return await axios.patch(url).then(response => response.data.data);
}

export async function fetchPetRejectionReasons(): Promise<PetRejectionReasonsItem[]> {
    const url = '/backoffice/pet/claim_items/rejection_reasons';

    return await axios.get<{ data: PetRejectionReasonsItem[] }>(url).then(response => response.data.data);
}

export async function payInvoice({
    claimId,
    carrier,
    invoiceId,
    paymentMethod,
    paymentMethodId,
    checkMemo,
    waiveDeductibleReason,
}: {
    readonly claimId: string;
    readonly carrier: Carrier;
    readonly invoiceId: string;
    readonly paymentMethod: PaymentMethodType;
    readonly paymentMethodId?: string;
    readonly checkMemo?: string;
    readonly waiveDeductibleReason?: string;
}): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}/pay`;

    return await axios
        .post(url, {
            carrier,
            invoice_id: invoiceId,
            payment_method_type: paymentMethod,
            payment_method_id: paymentMethodId,
            payout_opt: {
                check_memo: checkMemo,
                waive_deductible_reason: waiveDeductibleReason,
            },
        })
        .then(response => response.data.data);
}

export async function getAvailableProcedures(
    claimId: string,
    featureType: PetFeatureType,
    procedureType: string
): Promise<{ readonly code: string; readonly title: string }[]> {
    const url = `/backoffice/pet/claims/${claimId}/available_procedures?feature_type=${featureType}&procedure_type=${procedureType}`;

    return await axios.get<{ data: { code: string; title: string }[] }>(url).then(response => response.data.data);
}

export async function getAvailableItemTypes(claimId: string, featureType: PetFeatureType): Promise<PetItemType[]> {
    const url = `/backoffice/pet/claims/${claimId}/available_item_types?feature_type=${featureType}`;

    return await axios.get<{ data: PetItemType[] }>(url).then(response => response.data.data);
}

export async function getAvailableProcedureTypes(claimId: string, featureType: PetFeatureType): Promise<string[]> {
    const url = `/backoffice/pet/claims/${claimId}/available_procedure_types?feature_type=${featureType}`;

    return await axios.get<{ data: string[] }>(url).then(response => response.data.data);
}

export async function getPaymentInfo({
    claimId,
    itemIds,
}: {
    readonly claimId: string;
    readonly itemIds: string[];
    readonly payeeId?: string;
}): Promise<PaymentInfo> {
    const mapKeys = (key: string) => `item_ids[]=${key}`;
    const url = `/backoffice/pet/claims/${claimId}/payment_info?${itemIds.map(mapKeys).join('&')}`;

    return await axios.get<{ data: PaymentInfo }>(url).then(response => response.data.data);
}

export async function assignAdjuster({
    claimId,
    adjuster,
}: {
    readonly claimId: string;
    readonly adjuster: Adjuster | null;
}): Promise<null> {
    return await assignAdjusterToClaim({ claimId, adjuster, productType: ProductTypes.Pet });
}

export async function getManualClaimData(policyId: string): Promise<ManualClaimDataResponse> {
    const url = `/backoffice/pet/claims/manual_claim/new?policy_id=${policyId}`;

    return await axios.get(url).then(response => response.data.data);
}

export async function createManualClaim(
    policyId: string,
    type: PetClaimType | null,
    treatmentPlace: Partial<TreatmentPlace>,
    description: string,
    billingToken?: string,
    occurrenceDate?: string,
    visitDate?: string,
    petCurrentStatus?: string,
    carrier?: Carrier
): Promise<{ readonly claim: PetClaim }> {
    const url = `/backoffice/pet/claims/manual_claim/create?policy_id=${policyId}`;
    const body = {
        type,
        visit_date: visitDate,
        treatment_place: treatmentPlace,
        billing_token: billingToken,
        description,
        occurrence_date: occurrenceDate,
        pet_current_status: petCurrentStatus,
        carrier,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function sendEmail(args: SendEmailArgsWithEntityId): Promise<void> {
    await sendEmailResolver(args, BASE_URL);
}

export async function getFirstResponse(claimId: string): Promise<FirstResponse> {
    const url = `/backoffice/pet/claims/${claimId}/first_response`;

    return await axios.get<{ data: FirstResponse }>(url).then(response => response.data.data);
}

export async function cancelFirstResponse({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}/first_response/cancel`;

    return await axios.post(url);
}

export async function sendFirstResponse({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}/first_response/dispatch`;

    return await axios.post(url);
}

export async function addCustomRecipient({
    recipient,
    claimId,
}: {
    readonly recipient: Pick<Recipient, 'email' | 'name' | 'role'>;
    readonly claimId: string;
}): Promise<Recipient> {
    return await addCustomRecipientGeneric(recipient, EntityTypes.PetClaim, claimId);
}

export async function assignInteraction({
    interactionPublicId,
    claimId,
}: {
    readonly interactionPublicId: string;
    readonly claimId: string;
}): Promise<null> {
    return await assignInteractionGeneric({ entityType: EntityTypes.PetClaim, entityId: claimId, interactionPublicId });
}

export async function dismissInteraction({
    interactionPublicId,
    claimId,
}: {
    readonly interactionPublicId: string;
    readonly claimId: string;
}): Promise<null> {
    return await dismissInteractionGeneric({
        entityType: EntityTypes.PetClaim,
        entityId: claimId,
        interactionPublicId,
    });
}

export async function manualResponseInteraction({
    interactionPublicId,
    claimId,
    medium,
    body,
}: {
    readonly interactionPublicId: string;
    readonly claimId: string;
    readonly medium: string;
    readonly body: string;
}): Promise<null> {
    return await manualResponseInteractionGeneric({
        entityType: EntityTypes.PetClaim,
        entityId: claimId,
        medium,
        body,
        interactionPublicId,
    });
}

export async function createNote({ note }: { readonly note: NoteCreateRequest }): Promise<null> {
    return await createNoteGeneric({ note });
}

export async function dismissPrioritization({
    claimId,
    priorityType,
}: {
    readonly claimId: string;
    readonly priorityType: PriorityTypes;
}): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}/dismiss_prioritization`;

    return await axios.post(url, { rule_type: priorityType });
}

export async function dismissItemListIncomplete({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = `/backoffice/pet/claims/${claimId}/dismiss_item_list_incomplete`;

    return await axios.post(url);
}

export async function deleteVetNoteAttachment(claimId: string, filePublicId: string): Promise<void> {
    const url = `/backoffice/pet/claims/${claimId}/vet_notes/remove_file`;

    await axios
        .patch<void>(url, {
            file_id: filePublicId,
        })
        .then(response => response.data);
}

export async function updateVetNote(claimId: string, vetNote: VetNote): Promise<void> {
    const url = `/backoffice/pet/claims/${claimId}/vet_notes/update`;

    await axios.patch<void>(url, { status: vetNote.status }).then(response => response.data);
}

// blender-general routes
const blenderGeneralUrlResolver = getUrlResolver('blender-general');

export async function assignAdjusterToClaim({
    claimId,
    adjuster,
    productType,
    taskPublicIds,
}: {
    readonly claimId: string;
    readonly adjuster: Adjuster | null;
    readonly productType: ProductTypes;
    readonly taskPublicIds?: string[];
}): Promise<null> {
    const url = blenderGeneralUrlResolver(`/api/v2/assignments`);

    const data = {
        entityPublicId: claimId,
        entityType: AssignmentEntityType.Claim,
        productType,
        adjusterPublicId: adjuster?.public_id ?? null,
        taskPublicIds,
    };

    return await axios.post(url, data).then(response => response.data.data);
}

// pet-blender routes

const petUrlResolver = getUrlResolver('pet-blender');

export async function getAvailableClaimProcedures(
    claimId: string,
    featureType: PetFeatureType,
    procedureType?: MedicalProcedureType
): Promise<PetClaimProcedures> {
    const includeVetExamFees = featureType === PetFeatureType.AccidentIllness ? true : undefined;
    const url = petUrlResolver('/api/v1/medical_procedures');

    return await axios
        .get<{ data: PetClaimProcedures }>(url, {
            params: {
                claimPublicId: claimId,
                featureType,
                procedureType,
                ...(includeVetExamFees && { includeVetExamFees }),
            },
        })
        .then(response => response.data.data);
}

export async function getPetMedicalProcedures(
    petType: PetType,
    includeVetExamFees?: boolean
): Promise<PetClaimProcedures> {
    const url = petUrlResolver('/api/v1/medical_procedures/by_pet_type');

    return await axios
        .get<{ data: PetClaimProcedures }>(url, {
            params: {
                petType,
                includeVetExamFees,
            },
        })
        .then(response => response.data.data);
}

export async function getProceduresByFeatureTypes(
    claimPublicId: string,
    featureTypes: string
): Promise<MedicalProceduresByFeatures> {
    const url = petUrlResolver('/api/v1/medical_procedures/by_feature_types');

    return await axios
        .get<{ data: MedicalProceduresByFeatures }>(url, { params: { claimPublicId, featureTypes } })
        .then(response => response.data.data);
}

export async function exportClaimPdf({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = petUrlResolver('/api/v1/exports');

    const body = {
        entityType: EntityTypes.PetClaim,
        entityIds: [claimId],
        filename: null,
        sections: null,
    };

    return await axios.post(url, body).then(response => response.data.data);
}

export async function cancelAutoApproval({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = petUrlResolver(`/api/v1/claims/${claimId}/cancel_auto_approval`);

    return await axios.post(url).then(response => response.data.data);
}

export async function cancelAutoDecline({ claimId }: { readonly claimId: string }): Promise<null> {
    const url = petUrlResolver(`/api/v1/claims/${claimId}/cancel_auto_decline`);

    return await axios.post(url).then(response => response.data.data);
}
