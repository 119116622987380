import type { CalendarDate, DateValue } from '@internationalized/date';
import { fromDate, getLocalTimeZone, Time } from '@internationalized/date';
import type { FC, ReactElement } from 'react';
import { cloneElement, useCallback, useEffect, useRef } from 'react';
import type { AriaButtonOptions } from 'react-aria';
import { useButton, useDatePicker, useDialog, usePopover } from 'react-aria';
import { useDatePickerState } from 'react-stately';
import type { CalendarProps } from './Calendar';
import { DateTimeCalendar } from './DateTimeCalendar';
import { DateTimeField } from './DateTimeField';
import { focusable } from './DateTimePicker.css';

const CustomTrigger: FC<{
  readonly buttonProps: AriaButtonOptions<'button'>;
  readonly disabled: boolean;
  readonly children: ReactElement;
}> = ({ buttonProps, disabled, children }) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const { buttonProps: dateButtonProps } = useButton(buttonProps, buttonRef);

  return (
    <div className={focusable} ref={buttonRef} {...(disabled ? {} : dateButtonProps)}>
      {cloneElement(children, { disabled })}
    </div>
  );
};

export interface DateTimePickerProps {
  readonly value?: Date;
  readonly minDate?: Date;
  readonly maxDate?: Date;
  readonly format?: string; // e.g 'MM/DD/YYYY'
  readonly onClose?: () => void;
  readonly onOpen?: () => void;
  readonly onChange: (value: Date) => void;
  readonly onError?: (error: string | null) => void;
  readonly closeOnSelect?: boolean;
  readonly disabled?: boolean;
  readonly withTime?: boolean;
  readonly withDateInput?: boolean;
  readonly locale?: string;
  readonly children?: ReactElement;
  readonly cancelable?: boolean;
  readonly error?: string;
  readonly inputPosition?: CalendarProps['inputPosition'];
  readonly customHeader?: CalendarProps['customHeader'];
  readonly className?: string;
}

export const DateTimePicker: FC<DateTimePickerProps> = ({
  minDate,
  maxDate,
  value,
  format,
  onClose,
  onOpen,
  onChange,
  withTime = false,
  withDateInput = false,
  closeOnSelect = false,
  disabled = false,
  cancelable = false,
  locale,
  children,
  error,
  inputPosition,
  customHeader,
  className,
}) => {
  const state = useDatePickerState({
    value: value == null ? null : fromDate(value, getLocalTimeZone()),
    shouldCloseOnSelect: closeOnSelect,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    onChange: currDateValue => currDateValue != null && onChange(currDateValue.toDate()),
    onOpenChange: isOpen => {
      if (isOpen) {
        onOpen?.();
      } else {
        onClose?.();
      }
    },
    granularity: withTime ? 'minute' : 'day',
  });
  const wrapperRef = useRef(null);
  const triggerRef = useRef(null);
  const popoverRef = useRef(null);
  const dialogRef = useRef(null);

  const { fieldProps, buttonProps, dialogProps, calendarProps } = useDatePicker(
    { label: 'DateTimePicker' },
    state,
    wrapperRef,
  );

  const { popoverProps } = usePopover(
    {
      placement: 'bottom start',
      offset: 4,
      triggerRef,
      popoverRef,
    },
    state,
  );

  const { dialogProps: dateDialogProps } = useDialog(dialogProps, dialogRef);

  const handleCancel = useCallback(
    (startValue: DateValue | null | undefined) => {
      state.setValue(startValue ?? null);

      state.close();
    },
    [state],
  );

  const handleDone = useCallback(() => {
    state.close();
  }, [state]);

  // sets the default value - required for react-aria if we want to show selected date with granularity: 'minute'
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (withTime && state.timeValue == null) {
      state.setTimeValue(new Time());
    }
  }, [state, withTime]);

  return (
    <>
      <div ref={triggerRef}>
        {children != null ? (
          <CustomTrigger buttonProps={buttonProps} disabled={disabled}>
            {children}
          </CustomTrigger>
        ) : (
          <DateTimeField
            buttonProps={buttonProps}
            cancelable={cancelable}
            className={className}
            clear={() => state.setValue(null)}
            disabled={disabled}
            error={error}
            fieldProps={fieldProps}
            format={format}
            isOpen={state.isOpen}
            triggerTestId="datetimepicker-trigger"
            withTime={withTime}
          />
        )}
      </div>

      {state.isOpen && (
        <div ref={popoverRef} {...popoverProps}>
          <div ref={dialogRef} {...dateDialogProps}>
            <DateTimeCalendar
              {...calendarProps}
              customHeader={customHeader}
              disabled={disabled}
              fieldProps={fieldProps}
              inputPosition={inputPosition}
              locale={locale}
              maxValue={maxDate == null ? undefined : fromDate(maxDate, getLocalTimeZone())}
              minValue={minDate == null ? undefined : fromDate(minDate, getLocalTimeZone())}
              onCancel={handleCancel}
              onChange={(date: CalendarDate) => state.setDateValue(date)}
              onDone={handleDone}
              value={state.value}
              withDateInput={withDateInput || children != null}
              withTime={withTime && (children != null || withDateInput)}
            />
          </div>
        </div>
      )}
    </>
  );
};
