import { Flex, Icon, spacing, Text } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import type { AttachmentDTO, AttachmentType } from 'components/Attachments/types';

const Subtype: FC<{ readonly subtype?: AttachmentType | null }> = ({ subtype }) => {
    if (subtype != null)
        return (
            <Text color="tertiary" type="text-sm">
                {subtype.label}
            </Text>
        );

    return null;
};

export const DescriptionTypeInput: FC<{
    readonly attachment: AttachmentDTO;
    readonly updateInputType: 'description' | 'type';
}> = ({ attachment, updateInputType }) => {
    const { description, type, subtype, reviewed } = attachment;
    const isDescription = updateInputType === 'description';
    const descriptionMissing = reviewed === false && (description === '' || description == null);
    const typeMissing = reviewed === false && (type?.value === '' || type == null);

    if (isDescription && descriptionMissing) {
        return (
            <Flex alignItems="center" gap={spacing.s04} key={attachment.publicId}>
                <Icon color="textTertiary" name="info-circle-solid" />
                <Text color="tertiary">Missing</Text>
            </Flex>
        );
    } else if (!isDescription && typeMissing) {
        return (
            <Flex flexDirection="column" gap={spacing.s04} justifyContent="center" key={attachment.publicId}>
                <Subtype subtype={subtype} />
                <Flex alignItems="center" gap={spacing.s04}>
                    <Icon color="textTertiary" name="info-circle-solid" />
                    <Text color="tertiary">Missing</Text>
                </Flex>
            </Flex>
        );
    }

    return isDescription ? (
        <Text>{description}</Text>
    ) : (
        <Flex flexDirection="column" gap={spacing.s04} justifyContent="center" key={attachment.publicId}>
            <Subtype subtype={subtype} />
            <Text>{type?.label}</Text>
        </Flex>
    );
};
