import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';

const carBlenderRiskIndicatorsUrlResolver = getUrlResolver('car-blender', '/api/v1/risk_indicators');

export enum RiskIndicatorEntityType {
    Quote = 'quote',
    Policy = 'policy',
    Claim = 'claim',
}

export interface RiskIndicatorData {
    type: string;
    description: string;
}

export async function createRiskIndicator({
    entityType,
    entityPublicId,
    userPublicId,
    data,
}: {
    entityType: RiskIndicatorEntityType;
    entityPublicId: string;
    userPublicId: string;
    data: RiskIndicatorData;
}): Promise<void> {
    const url = carBlenderRiskIndicatorsUrlResolver();

    return axios.post(url, { ...data, entityType, entityPublicId, userPublicId });
}

export async function editRiskIndicator({
    riskIndicatorPublicId,
    data,
}: {
    riskIndicatorPublicId: string;
    data: RiskIndicatorData;
}) {
    const url = carBlenderRiskIndicatorsUrlResolver(`/${riskIndicatorPublicId}`);

    return axios.patch(url, data);
}

export async function removeRiskIndicator(riskIndicatorPublicId: string) {
    const url = carBlenderRiskIndicatorsUrlResolver(`/${riskIndicatorPublicId}`);

    return axios.delete(url);
}
