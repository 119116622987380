import type { ProductTypes } from '@lemonade-hq/bluiza';
import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';

const blenderGeneralUrlResolver = getUrlResolver('blender-general');

export interface PaymentReversalReasonOptionsResponse {
    readonly reasons: { readonly code: string; readonly display: string }[];
}

export interface PaymentReversalRequestResponse {
    readonly publicId: string;
}

export interface HandlePaymentRequestBase {
    readonly product: ProductTypes;
    readonly paymentRequestPublicId: string;
    readonly taskPublicId: string;
    readonly approved: boolean;
    readonly reviewerNotes: string;
    readonly claimItemIds: string;
    readonly operatorId: number;
    readonly requestedAmountInCents: number;
}

export interface HandlePaymentRequestCar extends HandlePaymentRequestBase {
    readonly claimPublicId: string;
}

export interface HandlePaymentRequestPet extends HandlePaymentRequestBase {
    readonly claimPublicId: string;
}

export async function getPaymentReversalOptions(paymentMethod: string): Promise<PaymentReversalReasonOptionsResponse> {
    const url = blenderGeneralUrlResolver(`/api/v1/payment_reversals/reversal_reasons?paymentMethod=${paymentMethod}`);

    const res = await axios.get(url).then(response => response.data.data);

    return res;
}

export async function requestPaymentReversal({
    operatorId,
    reason,
    txPublicId,
}: {
    readonly txPublicId: string;
    readonly operatorId: string;
    readonly reason: string;
}): Promise<PaymentReversalRequestResponse> {
    const url = blenderGeneralUrlResolver('/api/v1/payment_reversals');

    const res = await axios.post(url, { txPublicId, operatorId, reason }).then(response => response.data.data);

    return res;
}

export async function handlePaymentRequest<T extends HandlePaymentRequestBase>(body: T): Promise<void> {
    const url = blenderGeneralUrlResolver('/api/v1/payments_control/payments_request');

    await axios.put(url, body);
}

export async function issuePayment(body: {
    readonly product: ProductTypes;
    readonly paymentRequestPublicId: string;
    readonly claimItemIds: string;
    readonly taskPublicId: string;
}): Promise<void> {
    const url = blenderGeneralUrlResolver('/api/v1/payments_control/issue_payment');

    await axios.post(url, body);
}

export async function deletePaymentMethod(paymentMethodPublicId: string): Promise<void> {
    const url = blenderGeneralUrlResolver(`/api/v2/payment_methods/${paymentMethodPublicId}`);

    await axios.delete(url);
}
