import { Flex, spacing, Tooltip } from '@lemonade-hq/blender-ui';
import type { DialogProps } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import { useMemo } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { AttachmentThumbnail } from 'components/Attachments/AttachmentThumbnail';
import { useAttachmentsData } from 'components/Attachments/context';
import type { AttachmentDTO } from 'components/Attachments/types';

interface AttachmentDialogBaseProps {
    readonly attachments: AttachmentDTO[];
    readonly title: string;
    readonly isLoading: boolean;
    readonly error?: string;
    readonly isSubmitDisabled: boolean;
    readonly onSubmit: () => void;
    readonly submitLabel: string;
    readonly onClose: () => void;
}

export const AttachmentDialogBase: FC<PropsWithChildren<AttachmentDialogBaseProps>> = ({
    children,
    attachments,
    title,
    isLoading,
    error,
    isSubmitDisabled,
    onSubmit,
    submitLabel,
    onClose,
}) => {
    const { entityType, entityPublicId } = useAttachmentsData();
    const dialogProps = useMemo<DialogProps>(
        () => ({
            title,
            loading: isLoading,
            actions: [
                {
                    type: 'close',
                    text: 'Cancel',
                    onClick: onClose,
                },
                {
                    type: 'submit',
                    text: submitLabel,
                    disabled: isSubmitDisabled,
                    onClick: onSubmit,
                },
            ],
            size: 'small',
            error,
        }),
        [error, isLoading, isSubmitDisabled, onClose, onSubmit, submitLabel, title]
    );

    return (
        <Dialog {...dialogProps}>
            <Flex flexDirection="column" gap={spacing.s20} minHeight="30rem">
                <Flex flexWrap="wrap" gap={spacing.s08}>
                    {attachments.map(attachment => (
                        <Tooltip
                            content={
                                <>
                                    {attachment.type != null && <>Type: {attachment.type.label}</>}
                                    {attachment.description != null && (
                                        <>
                                            <br />
                                            Description: {attachment.description}
                                        </>
                                    )}
                                </>
                            }
                            key={attachment.publicId}
                            title={attachment.fileName ?? undefined}
                        >
                            <div>
                                <AttachmentThumbnail
                                    attachment={attachment}
                                    entityPublicId={entityPublicId}
                                    entityType={entityType}
                                    key={attachment.publicId}
                                    showPreview={false}
                                />
                            </div>
                        </Tooltip>
                    ))}
                </Flex>
                {children}
            </Flex>
        </Dialog>
    );
};
