import { Flex, Icon, Radio, RadioGroup, spacing, Text, Tooltip } from '@lemonade-hq/blender-ui';
import { stepWrapper } from './ManageReleaseDialog.css';
import { Step } from 'components/LoCo/common/components/CoverageRules/ManageRuleCommon';
import { ReleaseType } from 'models/LoCo/Insurance/BaseEdition';

interface ReleaseTypeSelectionProps {
    readonly value: ReleaseType | null;
    readonly onChange: (releaseType: ReleaseType) => void;
}

export const ReleaseTypeSelection: React.FC<ReleaseTypeSelectionProps> = ({ value, onChange }) => {
    return (
        <Step>
            <Flex className={stepWrapper}>
                <Flex flexDirection="column">
                    <Text fontWeight="bold" pb={spacing.s20}>
                        What&apos;s the purpose of this release?
                    </Text>
                    <RadioGroup defaultValue={value ?? undefined} onValueChange={onChange}>
                        <Flex alignItems="center">
                            <Radio id={ReleaseType.ProductChange} value={ReleaseType.ProductChange} />
                            <Text htmlFor={ReleaseType.ProductChange} pl={spacing.s12} pr={spacing.s08} type="label-sm">
                                Product change
                            </Text>
                            <Tooltip
                                content={`Changes to coverages, rules, UW filters, or rating. You'll be able to choose when these changes take effect and if they apply to New Business and Renewals, existing policies, or both`}
                            >
                                <Icon color="textTertiary" name="info-circle-solid" />
                            </Tooltip>
                        </Flex>
                        <Flex alignItems="center">
                            <Radio id={ReleaseType.BugFix} value={ReleaseType.BugFix} />
                            <Text htmlFor={ReleaseType.BugFix} pl={spacing.s12} pr={spacing.s08} type="label-sm">
                                Bug fix
                            </Text>
                            <Tooltip content="Fixes errors in published edition(s). The correction automatically applies based on the effective date(s) of the edition(s) being fixed">
                                <Icon color="textTertiary" name="info-circle-solid" />
                            </Tooltip>
                        </Flex>
                    </RadioGroup>
                </Flex>
            </Flex>
        </Step>
    );
};
