import type { ReactElement } from 'react';
import React, { cloneElement, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import COLORS from '../Colors';
import { useCarousel } from './CarouselContext';

const SCROLL_BUTTON_WIDTH = 80;

type CarouselScrollerProps = {
    readonly children: ReactElement[];
};

const StyledCarouselContainer = styled.div`
    text-align: center;
    background: ${COLORS.nero};
    position: relative;
    height: 90px;
    width: 100%;
`;
const StyledCarouselScrollerWrapper = styled.div`
    overflow: hidden;
    flex: 0 0 auto;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    position: relative;
    margin: 0;
`;

const StyledCarouselScroller = styled.ul<{ readonly scrollerOffset: number; readonly hasScroll: boolean }>`
    position: ${({ hasScroll, scrollerOffset }) => (!hasScroll && scrollerOffset > 0 ? `relative` : `absolute`)};
    padding: 0 ${SCROLL_BUTTON_WIDTH}px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
`;

const StyledCarouselThumbnail = styled.li`
    list-style: none;
    margin: 0;
`;

const StyledScrollerArrow = styled.div<{ readonly isBack?: boolean; readonly isDisabled?: boolean }>`
    width: ${SCROLL_BUTTON_WIDTH}px;
    height: 100%;
    background-image: linear-gradient(90deg, rgba(20, 20, 20, 1) 0%, rgba(20, 20, 20, 0) 100%);
    position: absolute;
    top: 0;
    ${({ isBack }) => (isBack ? `left: 0; ` : `right: 0;transform: rotate(180deg)`)};
    transition: 0.3s all ease;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
    ${({ isDisabled }) => isDisabled && `pointer-events: none;`}
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
        content: '';
        position: relative;
        width: 34px;
        height: 34px;
        background: white;
        border-radius: 50%;
        opacity: 0;
        transition: 0.3s all ease;
    }
    &:hover {
        &:before {
            opacity: 0.2;
        }
    }
    &:active {
        &:before {
            opacity: 0.4;
        }
    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('${__assets_url}blender_assets/backoffice/previous.svg');
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
`;

const CarouselMenuThumbnail: React.FC<{
    readonly index: number;
    readonly child: JSX.Element;
    readonly selectedItemRef: React.RefObject<HTMLLIElement>;
}> = ({ index, child, selectedItemRef }) => {
    const { selectedIndex, setSelectedIndex } = useCarousel();
    const { ref, inView } = useInView({
        triggerOnce: true,
    });

    return (
        <StyledCarouselThumbnail ref={index === selectedIndex ? selectedItemRef : null}>
            <div ref={ref}>
                {inView
                    ? cloneElement(child, {
                          isSelected: index === selectedIndex,
                          onClick: () => setSelectedIndex(index),
                      })
                    : null}
            </div>
        </StyledCarouselThumbnail>
    );
};

const CarouselScroller: React.FC<CarouselScrollerProps> = ({ children }) => {
    const [containerWidth, setContainerWidth] = useState(0);
    const [width, setWidth] = useState(0);
    const [hasScroll, setHasScroll] = useState(false);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollerOffset, setScrollerOffset] = useState(0);
    const scrollerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLUListElement>(null);
    const selectedItemRef = useRef<HTMLLIElement>(null);
    const clearMouseUpFunctionRef = useRef<(() => void) | null>(null);

    const calculateContainerWidth = () => {
        if (scrollerRef.current != null) {
            setContainerWidth(scrollerRef.current.clientWidth);
        }
    };

    const { selectedIndex } = useCarousel();

    useEffect(() => {
        window.addEventListener('resize', calculateContainerWidth);
        return () => {
            window.removeEventListener('resize', calculateContainerWidth);
            if (clearMouseUpFunctionRef.current) {
                document.removeEventListener('mouseup', clearMouseUpFunctionRef.current);
            }
        };
    }, []);
    useEffect(() => {
        calculateContainerWidth();
    }, [scrollerRef]);

    useEffect(() => {
        if (contentRef.current != null) {
            setWidth(contentRef.current.clientWidth);
        }
    }, [contentRef]);

    const scrollToSelectedItem = () => {
        if (hasScroll && selectedItemRef.current != null) {
            let scrollPosition = -selectedItemRef.current.offsetLeft + containerWidth / 2 - SCROLL_BUTTON_WIDTH;

            if (scrollPosition > -(containerWidth / 2 - SCROLL_BUTTON_WIDTH)) {
                scrollPosition = 0;
            }

            setScrollLeft(scrollPosition);
        }
    };

    useEffect(() => {
        if (hasScroll && selectedItemRef.current != null) {
            scrollToSelectedItem();
        }
    }, [selectedIndex, hasScroll]);

    useEffect(() => {
        setHasScroll(width > containerWidth);
        if (width < containerWidth) {
            setScrollerOffset((containerWidth - width) / 2);
        }
    }, [width, containerWidth]);

    const scrollSlider = (direction: 'left' | 'right') => {
        let counter = 1;
        const moveInterval = setInterval(() => {
            let position = scrollLeft + counter * (direction === 'left' ? 10 : -10);

            if (position > 0) {
                clearInterval(moveInterval);
                position = 0;
            }

            if (position < -width + containerWidth - SCROLL_BUTTON_WIDTH) {
                clearInterval(moveInterval);
                position = -width + containerWidth - SCROLL_BUTTON_WIDTH;
            }

            setScrollLeft(position);
            counter++;
        }, 10);
        const clearMoveInterval = () => {
            clearInterval(moveInterval);
        };

        clearMouseUpFunctionRef.current = () => {
            clearMoveInterval();
            document.removeEventListener('mouseup', clearMoveInterval);
            clearMouseUpFunctionRef.current = null;
        };

        document.addEventListener('mouseup', clearMouseUpFunctionRef.current);
    };

    return (
        <StyledCarouselContainer>
            <StyledCarouselScrollerWrapper ref={scrollerRef}>
                <StyledCarouselScroller
                    hasScroll={hasScroll}
                    ref={contentRef}
                    scrollerOffset={scrollerOffset}
                    style={{ left: `${scrollLeft}px` }}
                >
                    {children.map((child, index) => (
                        <CarouselMenuThumbnail
                            child={child}
                            index={index}
                            key={`${child.key}-li`}
                            selectedItemRef={selectedItemRef}
                        />
                    ))}
                </StyledCarouselScroller>
            </StyledCarouselScrollerWrapper>
            {hasScroll && (
                <>
                    <StyledScrollerArrow isBack isDisabled={scrollLeft >= 0} onMouseDown={() => scrollSlider('left')} />
                    <StyledScrollerArrow
                        isDisabled={-scrollLeft + containerWidth > width}
                        onMouseDown={() => scrollSlider('right')}
                    />
                </>
            )}
        </StyledCarouselContainer>
    );
};

export default CarouselScroller;
