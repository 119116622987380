import type { FC } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { spacing } from '../../theme/spacing.css';
import { IconButton } from '../IconButton/IconButton';
import { useAudioPlayer } from './AudioPlayer.Provider';
import { useAudioPlayerSegments } from './AudioPlayer.SegmentsProvider';

export const AudioPlayerSegmentedControls: FC = () => {
  const { isLoading, isPlaying, togglePlayState } = useAudioPlayer();
  const { segments, currentSegmentIndex, goToNextSegment, goToPrevSegment } = useAudioPlayerSegments();

  return (
    <Flex gap={spacing.s06}>
      <IconButton
        aria-label="prev"
        disabled={isLoading || currentSegmentIndex === -1}
        icon="media-skip"
        onClick={goToPrevSegment}
        rotation="180deg"
        size="lg"
        variant="secondary"
      />
      <IconButton
        aria-label={isPlaying ? 'playing' : 'paused'}
        disabled={isLoading}
        icon={isPlaying ? 'media-pause' : 'media-play'}
        onClick={togglePlayState}
        size="lg"
        variant="primary"
      />
      <IconButton
        aria-label="next"
        disabled={isLoading || currentSegmentIndex === segments.length - 1}
        icon="media-skip"
        onClick={goToNextSegment}
        size="lg"
        variant="secondary"
      />
    </Flex>
  );
};
