import { useCallback } from 'react';
import type { FC } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { spacing } from '../../theme/spacing.css';
import { Button } from '../Button/Button';
import { IconButton } from '../IconButton/IconButton';
import { Range } from '../Range/Range';
import { useAudioPlayer } from './AudioPlayer.Provider';
import { useAudioPlayerZoom } from './AudioPlayer.ZoomProvider';

export interface AudioPlayerZoomControlsProps {
  readonly step: number;
}

export const AudioPlayerZoomControls: FC<AudioPlayerZoomControlsProps> = ({ step }) => {
  const { isLoading } = useAudioPlayer();
  const { currentZoom, setZoom, maxZoom } = useAudioPlayerZoom();

  const handleZoomOut = useCallback(() => {
    setZoom(currentZoom - step);
  }, [setZoom, currentZoom, step]);

  const handleZoomIn = useCallback(() => {
    setZoom(currentZoom + step);
  }, [setZoom, currentZoom, step]);

  const handleFit = useCallback(() => {
    setZoom(1);
  }, [setZoom]);

  return (
    <Flex alignItems="center" gap={spacing.s06}>
      <IconButton
        aria-label="zoom out"
        disabled={isLoading || currentZoom === 1}
        icon="zoom-out"
        iconSize="xl"
        onClick={handleZoomOut}
        variant="inline"
      />
      <Range disabled={isLoading} max={maxZoom} min={1} onChange={setZoom} step={0.5} value={currentZoom} />
      <IconButton
        aria-label="zoom in"
        disabled={isLoading || currentZoom === maxZoom}
        icon="zoom-in"
        iconSize="xl"
        onClick={handleZoomIn}
        variant="inline"
      />
      <Button disabled={isLoading} label="Fit" onClick={handleFit} variant="inline" />
    </Flex>
  );
};
