import { Flex, spacing } from '@lemonade-hq/blender-ui';
import type { TableHeaderCellProps } from '@lemonade-hq/bluis';
import { ContentSection, EmptySection, MainButton, Table, TableHeader, TableRow, TableTitle } from '@lemonade-hq/bluis';
import { LinkComp } from '@lemonade-hq/bluiza';
import { Tooltip } from '@lemonade-hq/cdk';
import { useCallback, useState } from 'react';
import { getReleaseUrl } from '../../common/urlBuilders';
import { StyledSectionHeader } from '../../LoCoPagesSharedStyles';
import { EditionsAndSchemasLinks } from 'components/LoCo/common/components/EditionsAndSchemasLinks';
import { Paginator } from 'components/LoCo/common/components/Pagination/Paginator';
import { getVersionTypeDisplayName } from 'components/LoCo/common/display-texts/common';
import { getFormattedDate, getFormattedDateMaybe } from 'components/LoCo/common/helpers/dateHelpers';
import { ManageReleaseDialog } from 'components/LoCo/releases/dialogs/ManageReleaseDialog';
import type { Release } from 'models/LoCo/Insurance/Release';
import { ReleaseStatus } from 'models/LoCo/Insurance/Release';
import { useSuspenseGetReleases } from 'queries/LoCo/Insurance/ReleasesQueries';

const baseHeaderFields: TableHeaderCellProps[] = [
    { key: 'release', value: 'Release' },
    { key: 'created', value: 'Created' },
    { key: 'newBusiness', value: 'New Business' },
    { key: 'renewals', value: 'Renewal' },
    { key: 'editions', value: 'Editions' },
];

const draftHeaderFields: TableHeaderCellProps[] = [...baseHeaderFields, { key: 'readiness', value: 'Readiness' }];

function shouldIncludeReadiness(releaseStatus: ReleaseStatus): boolean {
    return releaseStatus === ReleaseStatus.Draft;
}

function getHeaderFields(releaseStatus: ReleaseStatus): TableHeaderCellProps[] {
    return shouldIncludeReadiness(releaseStatus) ? draftHeaderFields : baseHeaderFields;
}

function getReadiness(release: Release): string {
    if (release.status === ReleaseStatus.Draft) {
        return release.readyToPublish ? 'Ready for Publish' : 'Blocked';
    }

    return release.status;
}
const ReleaseToRow: React.FC<{
    readonly release: Release;
    readonly productCode: string;
}> = ({ release, productCode }) => {
    const row = [
        {
            key: 'release',
            value: (
                <Flex flexDirection="column" gap={spacing.s02}>
                    <LinkComp url={getReleaseUrl(productCode, release.publicId)}>{release.friendlyName}</LinkComp>
                    {getVersionTypeDisplayName(release.rolloutStrategy)}
                </Flex>
            ),
        },
        {
            key: 'created',
            value: (
                <Flex flexDirection="column" gap={spacing.s02}>
                    <Tooltip alignment="top" content={`By ${release.startedByName}`}>
                        <Flex>{getFormattedDate(release.startedAt)}</Flex>
                    </Tooltip>
                </Flex>
            ),
        },
        { key: 'effectiveNewBusiness', value: getFormattedDateMaybe(release.newBusinessEffectiveAt) },
        { key: 'effectiveRenewals', value: getFormattedDateMaybe(release.renewalEffectiveAt) },
        {
            key: 'editions',
            value: <EditionsAndSchemasLinks productCode={productCode} release={release} />,
        },
    ];

    if (shouldIncludeReadiness(release.status)) {
        row.push({ key: 'readiness', value: getReadiness(release) });
    }

    return <TableRow key={release.publicId} row={row} />;
};

function getReleaseStatusTitle(releaseStatus: ReleaseStatus): string {
    switch (releaseStatus) {
        case ReleaseStatus.Draft:
            return 'Releases in Preparation';
        case ReleaseStatus.Cancelled:
            return 'Cancelled Releases';
        default:
            throw new Error(`Unknown release status: ${releaseStatus}`);
    }
}

interface ReleasesTableProps {
    readonly productCode: string;
    readonly releaseStatus: ReleaseStatus;
}

export const ReleasesTable: React.FC<React.PropsWithChildren<ReleasesTableProps>> = ({
    productCode,
    releaseStatus,
}) => {
    const [pageNumber, setPageNumber] = useState(1);

    const { data: response } = useSuspenseGetReleases(productCode, releaseStatus, { page: pageNumber });

    const {
        data: { data: publishedReleases },
    } = useSuspenseGetReleases(productCode, ReleaseStatus.Published, { page: 1 });
    const isFirstRelease = publishedReleases.length === 0;

    const [showReleaseDialog, setShowReleaseDialog] = useState<boolean>(false);

    const onPageClick = (nextPage: number) => () => {
        setPageNumber(nextPage);
    };

    const onChangeDialogVisibility = useCallback(
        (isOpen: boolean) => () => {
            setShowReleaseDialog(isOpen);
        },
        []
    );

    const { data: releases, stats } = response;

    return (
        <ContentSection>
            <StyledSectionHeader>
                <TableTitle title={getReleaseStatusTitle(releaseStatus)} />
                {releaseStatus === ReleaseStatus.Draft && (
                    <MainButton onClick={onChangeDialogVisibility(true)}>Start a Release</MainButton>
                )}
            </StyledSectionHeader>
            {releases.length === 0 ? (
                <EmptySection>No releases for this product</EmptySection>
            ) : (
                <Table>
                    <TableHeader headers={getHeaderFields(releaseStatus)} />
                    {releases.map(release => (
                        <ReleaseToRow key={release.publicId} productCode={productCode} release={release} />
                    ))}
                </Table>
            )}
            <Paginator currentPage={pageNumber} onPageClick={onPageClick} totalPages={stats.totalPages} />
            {showReleaseDialog && (
                <ManageReleaseDialog
                    onClose={onChangeDialogVisibility(false)}
                    productCode={productCode}
                    skipFirstStep={isFirstRelease}
                />
            )}
        </ContentSection>
    );
};
