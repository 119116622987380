import type { AutocompleteOption } from '@lemonade-hq/bluis';
import { Autocomplete, Image, Spinner } from '@lemonade-hq/bluis';
import { font, Tooltip } from '@lemonade-hq/boutique';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ThemedColors } from '../Colors';
import DeleteAttachmentButton from '../DeleteAttachmentButton';
import type { AttachmentToAdd, UpdateAttachment } from './AddAttachments';
import Input from 'bluis/Input';
import { mapAttachmentType } from 'commons/AttachmentsUtils';
import type { AttachmentType } from 'models/Attachment';

export enum AttachmentRowItemType {
    Image,
    Title,
    Select,
    FreeText,
}

export function rowItemTypeToWidth(itemType: AttachmentRowItemType): number {
    switch (itemType) {
        case AttachmentRowItemType.Image:
            return 50;
        case AttachmentRowItemType.Title:
            return 137;
        case AttachmentRowItemType.Select:
            return 169;
        case AttachmentRowItemType.FreeText:
            return 284;
        default:
            return 0;
    }
}

export const TableHeader = styled.ol<{ readonly inputsScheme?: number[] }>`
    list-style: none;
    display: grid;
    padding: 0 12px;
    grid-template-columns: ${({ inputsScheme }) =>
        inputsScheme?.map(input => `${input}px`).join(' ') ?? '50px 137px 169px 324px'};
    column-gap: 16px;
    margin: 0;
    margin-bottom: 6px;
    ${font('main', { fontSize: '12px', lineHeight: '16px' })}
    color: ${ThemedColors.secondaryText};
`;

export const TableHeaderItem = styled.li`
    text-align: left;
    text-transform: uppercase;
`;

export const TableBody = styled.div<{ readonly rowsCount: number }>`
    display: grid;
    grid-template-rows: ${({ rowsCount }) => `repeat(${rowsCount}, 74px)`};
    row-gap: 8px;
    min-height: 200px;
    padding-top: 10px;
`;

export const TableRow = styled.ol<{ readonly expanded: boolean; readonly inputsScheme?: number[] }>`
    position: relative;
    list-style: none;
    display: grid;
    grid-template-columns: ${({ inputsScheme }) =>
        inputsScheme?.map(input => `${input}px`).join(' ') ?? '50px 137px 169px 324px'};
    column-gap: 16px;
    background-color: ${ThemedColors.elementBackground};
    border-radius: 5px;
    align-items: center;
    margin: 0;
    max-height: 0;
    transition: all 0.25s ease-out;
    opacity: 0;

    ${({ expanded }) =>
        expanded &&
        css`
            max-height: 74px;
            padding: 11px;
            opacity: 1;
        `}
`;

export const TableRowItem = styled.li`
    text-align: left;
    display: flex;
`;

export const TableRowCenteredItem = styled(TableRowItem)`
    justify-content: 'center';
`;

const LoadingWrapper = styled.div``;

export const LoadingImage: React.FC<React.PropsWithChildren<unknown>> = () => (
    <LoadingWrapper>
        <Spinner size={22} />
    </LoadingWrapper>
);

export const Thumbnail = styled(Image)`
    width: 50px;
    height: 50px;
    border-radius: 5px;
    object-fit: contain;
`;

export const ThumbnailImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 5px;
    object-fit: contain;
`;

export const Filename = styled.span`
    display: block;
    max-width: 121px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const RowsFooter = styled.div`
    height: 10px;
`;

const StyledInput = styled(Input)`
    width: '324px';
`;

export const AnimatedRow: React.FC<React.PropsWithChildren<{ readonly inputsScheme?: number[] }>> = ({
    children,
    inputsScheme,
}) => {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setExpanded(true);
        }, 0);
    }, []);

    return (
        <TableRow {...{ expanded }} inputsScheme={inputsScheme}>
            {children}
        </TableRow>
    );
};

interface TableRowLoaderProps<T extends AttachmentToAdd = AttachmentToAdd> {
    readonly attachment: T;
    readonly attachmentTypesOptions: AutocompleteOption[];
    readonly attachmentSubtypes?: AutocompleteOption[];
    readonly onDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    readonly onAttachmentTypeSelect: (props: {
        readonly value: string;
        readonly id: string;
        readonly isSubtype?: boolean;
    }) => void;
    readonly onDeleteAttachmentClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    readonly scrollerRef: React.RefObject<HTMLDivElement>;
    readonly additionalInputs?: AttachmentAdditionalInput[];
    readonly additionalInputsScheme?: number[];
}

export interface AttachmentAdditionalInput {
    readonly attachmentId: number;
    readonly components: JSX.Element[];
}

export interface AdditionalInputInfo {
    readonly title: string;
    readonly type: AttachmentRowItemType;
}

export interface AdditionalInputs {
    readonly scheme: AdditionalInputInfo[];
    readonly inputs: AttachmentAdditionalInput[];
}

const isDataUri = (src: string) => src.startsWith('data:');

const TableRowLoader: React.FC<React.PropsWithChildren<TableRowLoaderProps>> = ({
    attachment,
    attachmentTypesOptions,
    attachmentSubtypes = [],
    onDescriptionChange,
    onAttachmentTypeSelect,
    onDeleteAttachmentClick,
    additionalInputs,
    additionalInputsScheme,
}) => {
    const { src, filename, id, type = '', description, subtype } = attachment;
    const [filteredTypes, setFilteredTypes] = useState<AutocompleteOption[]>(attachmentTypesOptions);
    const [filteredSubtypes, setFilteredSubtypes] = useState<AutocompleteOption[]>(attachmentSubtypes);

    const attachmentAdditionalInputs = useMemo(
        () => additionalInputs?.find(additionalInput => additionalInput.attachmentId === id),

        [additionalInputs, id]
    );

    const onTypeSearch = useCallback(
        (term: string) => {
            const filtered = attachmentTypesOptions.filter(option =>
                option.label.toLowerCase().includes(term.toLowerCase())
            );

            setFilteredTypes(filtered);
        },
        [attachmentTypesOptions]
    );

    const onSubtypeSearch = useCallback(
        (term: string) => {
            const filtered = filteredSubtypes.filter(option => option.label.toLowerCase().includes(term.toLowerCase()));

            setFilteredSubtypes(filtered);
        },
        [filteredSubtypes]
    );

    return (
        <AnimatedRow inputsScheme={additionalInputsScheme} key={id}>
            <TableRowCenteredItem>
                {src ? (
                    isDataUri(src) ? (
                        <ThumbnailImage alt={filename} src={src} />
                    ) : (
                        <Thumbnail alt={filename} img={{ lightSrc: src }} />
                    )
                ) : (
                    <LoadingImage />
                )}
            </TableRowCenteredItem>
            <TableRowItem>
                <Tooltip alignment="top-end" content={filename}>
                    <Filename>{filename}</Filename>
                </Tooltip>
            </TableRowItem>
            {attachmentTypesOptions.length > 0 && (
                <TableRowItem>
                    <Autocomplete
                        onClear={() => setFilteredTypes(attachmentTypesOptions)}
                        onOptionSelected={option =>
                            onAttachmentTypeSelect({ value: option.value ?? '', id: String(id) })
                        }
                        onSearch={onTypeSearch}
                        options={filteredTypes}
                        placeholder="Select type"
                        value={snakeCaseToReadable(type)}
                        width={170}
                    />
                </TableRowItem>
            )}
            {attachmentSubtypes.length > 0 && (
                <TableRowItem>
                    <Autocomplete
                        onClear={() => setFilteredSubtypes(attachmentSubtypes)}
                        onOptionSelected={option =>
                            onAttachmentTypeSelect({ value: option.value ?? '', id: String(id), isSubtype: true })
                        }
                        onSearch={onSubtypeSearch}
                        options={filteredSubtypes}
                        placeholder="Select subtype"
                        value={snakeCaseToReadable(subtype ?? '')}
                        width={170}
                    />
                </TableRowItem>
            )}

            {attachmentAdditionalInputs != null && <>{attachmentAdditionalInputs.components}</>}
            <TableRowItem>
                <StyledInput
                    data-attachment-id={id}
                    onChange={onDescriptionChange}
                    placeholder="Description here"
                    value={description}
                />
            </TableRowItem>
            <DeleteAttachmentButton attachmentId={String(id)} onDelete={onDeleteAttachmentClick} />
        </AnimatedRow>
    );
};

const AddAttachmentsTable: React.FC<
    React.PropsWithChildren<{
        readonly attachments: AttachmentToAdd[];
        readonly update: UpdateAttachment;
        readonly deleteAttachment: (id: number) => void;
        readonly attachmentTypes?: string[];
        readonly attachmentSubtypes?: AutocompleteOption[];
        readonly additionalInputs?: AdditionalInputs;
    }>
> = ({ attachments, update, attachmentTypes = [], deleteAttachment, additionalInputs, attachmentSubtypes }) => {
    const inputs = useMemo(
        () => [
            AttachmentRowItemType.Image,
            AttachmentRowItemType.Title,
            AttachmentRowItemType.Select,
            ...(additionalInputs?.scheme.map(input => input.type) ?? []),
            AttachmentRowItemType.FreeText,
        ],
        [additionalInputs?.scheme]
    );

    const headers = useMemo(
        () => [
            '',
            'File name',
            'Type',
            ...(attachmentSubtypes && attachmentSubtypes.length > 0 ? 'Subtype' : []),
            ...(additionalInputs?.scheme.map(input => input.title) ?? []),
            'Description',
        ],
        [additionalInputs, attachmentSubtypes]
    );

    const inputsScheme = useMemo(() => inputs.map(input => rowItemTypeToWidth(input)), [inputs]);

    const scrollerRef = useRef<HTMLDivElement>(null);

    const attachmentTypesOptions: AutocompleteOption[] = useMemo(
        () =>
            attachmentTypes.map(type => ({
                value: type,
                label: snakeCaseToReadable(mapAttachmentType(type as AttachmentType)),
                id: type,
            })),
        [attachmentTypes]
    );

    const onDescriptionChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) =>
            update(Number(e.target.dataset.attachmentId!), { description: e.target.value }),
        [update]
    );

    const onAttachmentTypeSelect = useCallback(
        ({ value, id, isSubtype }: { value: string; id: string; isSubtype?: boolean }) => {
            if (isSubtype === true) {
                update(Number(id), { subtype: value });
            }

            update(Number(id), { type: value as AttachmentType });
        },
        [update]
    );

    const onDeleteAttachmentClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const target = e.target as HTMLButtonElement;

            deleteAttachment(Number(target.dataset.attachmentId!));
        },
        [deleteAttachment]
    );

    if (attachments.length === 0) return null;

    return (
        <>
            <TableHeader inputsScheme={inputsScheme}>
                {headers.map(h => (
                    <TableHeaderItem key={h}>{h}</TableHeaderItem>
                ))}
            </TableHeader>
            <TableBody ref={scrollerRef} rowsCount={attachments.length}>
                {attachments.map(attachment => (
                    <TableRowLoader
                        additionalInputs={additionalInputs?.inputs}
                        additionalInputsScheme={inputsScheme}
                        attachment={attachment}
                        attachmentSubtypes={attachmentSubtypes}
                        attachmentTypesOptions={attachmentTypesOptions}
                        key={attachment.id}
                        onAttachmentTypeSelect={onAttachmentTypeSelect}
                        onDeleteAttachmentClick={onDeleteAttachmentClick}
                        onDescriptionChange={onDescriptionChange}
                        scrollerRef={scrollerRef}
                    />
                ))}
                <RowsFooter />
            </TableBody>
        </>
    );
};

export default AddAttachmentsTable;
