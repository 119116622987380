export enum HomeOwnersReviewStatus {
    Approved = 'approved',
    Declined = 'declined',
    Canceled = 'canceled',
    InProgress = 'in_progress',
    AwaitingReview = 'awaiting_review',
    AwaitingCustomer = 'awaiting_customer',
    Pending = 'pending',
}

export enum HomeOwnersReviewRequestsLoading {
    Approve = 'approve',
    Decline = 'decline',
    Cancel = 'cancel',
}

export enum HomeOwnersReviewEntity {
    Quote = 'quote',
    Policy = 'policy',
}

export interface HomeownersReviewCoverages {
    readonly closingOrSwitching?: string;
    readonly effectiveDate: Date | null;
    readonly residence?: string;
    readonly dwelling: number;
    readonly otherStructures: number;
    readonly personalProperty: number;
    readonly lossOfUse: number;
    readonly personalLiability: number;
    readonly medical: number;
    readonly school: number;
    readonly baseDeductible?: number;
    readonly fungiPersonalProperty?: number;
    readonly fungiPersonalLiability?: number;
}

export interface HomeownersReviewTrigger {
    readonly priority: string;
    readonly trigger: string;
    readonly riskIndicatorText: string;
    readonly operatorToDoTexts: string[];
}

export interface HomeownersReviewer {
    readonly id: number | null;
    readonly name?: string;
    readonly imageUrl?: string;
}

export interface InspectionReport {
    readonly url: string;
}

export interface HomeownersReviewDetails {
    readonly id: number;
    readonly status: HomeOwnersReviewStatus;
    readonly operatorId: number | null;
    readonly reviewStarted: Date | null;
    readonly reviewEnded: Date | null;
    readonly triggers: HomeownersReviewTrigger[];
    readonly reviewers: HomeownersReviewer[];
    readonly inspectionReports: InspectionReport[];
}

export interface HomeownersReviewOwnership {
    readonly isTrustOwner: boolean | null;
    readonly isLlcOwner: boolean | null;
    readonly ownerName: string | null;
}

export interface HomeownersReviewProperty {
    readonly type: string | null;
    readonly inspectionReports?: InspectionReport[];
    readonly listingStatus: string | null;
    readonly listingPrice: number | null;
    readonly replacementCost: number | null;
    readonly ppc: number | null;
    readonly roofing: string | null;
    readonly structures: {
        readonly isPrimary: boolean | null;
        readonly roofCondition: number | null;
    }[];
    readonly userRoofAge: number | string | null;
    readonly userRoofYearInstalled?: number | null;
    readonly veriskRoofAge: number | null;
    readonly construction: string | null;
    readonly dwellingYearBuilt: number | null;
    readonly squareFt: number | null;
    readonly fireline: number | null;
    readonly hailScore: number | null;
    readonly qualityGrade: string | null;
    readonly treeCoverPercentage: number | null;
    readonly poolDetected: boolean | null;
    readonly vacant: boolean | null;
    readonly latestForeclosureEventDate: Date | null;
    readonly garageType: string | null;
    readonly garageSpaces: string | null;
}

export interface HomeownersReviewPropertyAddress {
    readonly lat: string;
    readonly lng: string;
    readonly city: string;
    readonly state: string;
    readonly street: string;
    readonly country: string;
    readonly postalCode: string;
    readonly streetNumber: string;
}

export interface HomeownersReviewPropertyPhoto {
    readonly contentType: string;
    readonly fileName: string;
    readonly thumbnailUrl: string;
    readonly url: string;
    readonly source: string;
}

export interface HomeownersReviewPropertyStaticMap {
    readonly propertyCoordinates: [number, number][];
    readonly satelliteImage: {
        readonly contentType: string;
        readonly fileName: string;
        readonly thumbnailUrl: string;
        readonly url: string;
        readonly source: string;
        readonly resolution: [number, number];
        readonly boundariesCoordinates: [number, number, number, number];
    } | null;
}

export interface HomeownersReviewUser {
    readonly lastName: string;
    readonly firstName: string;
    readonly age: number;
    readonly creditScoreTier?: number;
    readonly paymentMethod: string;
}

export interface HomeownersReviewVeriskMortgage {
    readonly lenderName: string | null;
    readonly isPrivateLender: boolean | null;
    readonly typeDescription: string | null;
    readonly startDate: string | null;
}

export interface HomeownersReviewZillow {
    readonly zpid: string | null;
}

export type HomeOwnersReviewPermissions = {
    readonly operatorPermitted: boolean;
    readonly showToolbar?: boolean;
    readonly disableToolbarActions?: boolean;
};
