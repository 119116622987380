import { useState } from 'react';
import { uploadFile } from 'apis/FilesAPI';
import type { Attachment, AttachmentEntityType, AttachmentToUpload, AttachmentType } from 'models/Attachment';

interface AttachmentUploadProps {
    readonly isUploading: boolean;
    readonly uploadFailed: boolean;
    readonly upload: (entityId: string, toUpload: AttachmentToUpload[]) => Promise<Attachment[] | null>;
}

/**
 * @deprecated
 * use useFileUpload from @lemonade-hq/bluis instead
 */
const useAttachmentUpload = (
    entityType: AttachmentEntityType,
    shard: string,
    createAttachments: (
        entityId: string,
        entityType: AttachmentEntityType,
        attachments: {
            readonly type: AttachmentType;
            readonly filePublicId: string;
            readonly contentType: string;
            readonly description?: string;
        }[]
    ) => Promise<Attachment[] | undefined>,
    uploadCallback?: (attachments?: Attachment[]) => Promise<unknown> | void
): AttachmentUploadProps => {
    const [isUploading, setIsUploading] = useState(false);
    const [uploadFailed, setUploadFailed] = useState(false);

    const upload = async (entityId: string, toUpload: AttachmentToUpload[]): Promise<Attachment[] | null> => {
        setIsUploading(true);
        setUploadFailed(false);

        try {
            const promisedAttachments = toUpload.map(async upload => {
                const { publicId: filePublicId } = await uploadFile(upload.file, shard);

                return {
                    ...upload,
                    filePublicId,
                    entityType,
                    entityId,
                    contentType: upload.file.type,
                };
            });

            const attachments = await Promise.all(promisedAttachments);

            const newAttachments = await createAttachments(entityId, entityType, attachments);

            if (uploadCallback) {
                await uploadCallback(newAttachments);
            }

            setIsUploading(false);
            return newAttachments ?? [];
        } catch {
            setIsUploading(false);
            setUploadFailed(true);

            return null;
        }
    };

    return { isUploading, uploadFailed, upload };
};

export default useAttachmentUpload;
