import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';
import { TreatmentPlaceDTO } from './PetTreatmentPlaceAPI';

const petUrlResolver = getUrlResolver('pet-blender', '/api/v1/user-vets');

export async function createUserVet({
    treatmentPlace,
}: {
    treatmentPlace: Partial<TreatmentPlaceDTO>;
}): Promise<TreatmentPlaceDTO> {
    const body = { ...treatmentPlace };

    const newUserVet = await axios
        .post<{ data: TreatmentPlaceDTO }>(petUrlResolver(), body)
        .then(response => response.data.data);

    return newUserVet;
}

export async function updateUserVet({
    publicId,
    treatmentPlace,
}: {
    publicId: string;
    treatmentPlace: Partial<TreatmentPlaceDTO>;
}): Promise<TreatmentPlaceDTO> {
    const url = petUrlResolver(`/${publicId}`);
    const body = { ...treatmentPlace };

    const updatedUserVet = await axios.put<{ data: TreatmentPlaceDTO }>(url, body).then(response => response.data.data);

    return updatedUserVet;
}

export async function getClaimUserVets(claimPublicId: string): Promise<TreatmentPlaceDTO[]> {
    const url = petUrlResolver(`/${claimPublicId}`);
    const userVets = await axios.get<{ data: TreatmentPlaceDTO[] }>(url).then(response => response.data.data);

    return userVets;
}

export async function getUserVets(userPublicId: string): Promise<TreatmentPlaceDTO[]> {
    const url = petUrlResolver();
    const userVets = await axios
        .get<{ data: TreatmentPlaceDTO[] }>(url, { params: { userPublicId } })
        .then(response => response.data.data);

    return userVets;
}
