import { ArgumentType } from './expressionTypes';
import type { InputFunction } from './operators';
import { QuoteLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageTemplate, SettingTemplate } from 'models/LoCo/Insurance/Registry';

/**
 * Creates additional functions for the schema of expressions.
 * It uses coverages and settings instances from the context of the current Coverages Edition to generate
 * additional functions
 *
 * ### Example
 *
 * a `selectedCoverage` function which gets a coverage instance as an argument and returns the value of that coverage
 *
 * ```typescript
 * function selectedCoverage(coverage: Coverages): 'On' | 'Off'
 * ```
 *
 * @param coveragesNames - coverages on the product's registry
 * @param settingsNames - settings on the product's registry
 * @param lifecycleContexts - lifecycle contexts of the current Coverages Edition
 * @returns
 */
export function getAdditionalFunctions(
    coverages: CoverageTemplate[],
    settings: SettingTemplate[],
    lifecycleContexts: QuoteLifecycleContext[],
    showSelectedSettingsAndCoverages?: boolean
): Record<string, InputFunction> {
    const showPrevious = [QuoteLifecycleContext.Renewal, QuoteLifecycleContext.Moving].some(context =>
        lifecycleContexts.includes(context)
    );

    const settingInputBase = {
        argument: {
            type: ArgumentType.Enum,
            symbols: settings.map(s => ({ label: s.name, value: s.code })),
            enumName: 'Settings',
        },
        returnType: {
            type: ArgumentType.Number,
        },
    } as InputFunction;

    const coverageInputBase = {
        argument: {
            type: ArgumentType.Enum,
            symbols: coverages.map(c => ({ label: c.name, value: c.code })),
            enumName: 'Coverages',
        },
        returnType: {
            type: ArgumentType.Enum,
            symbols: [
                { label: 'On', value: 'On' },
                { label: 'Off', value: 'Off' },
            ],
            enumName: 'CoverageStatus',
        },
    } as InputFunction;

    let functions: Record<string, InputFunction> = {};

    if (showSelectedSettingsAndCoverages) {
        functions = {
            selectedCoverage: {
                ...coverageInputBase,
                display: 'Selected Coverage',
            },
            selectedSetting: {
                ...settingInputBase,
                display: 'Selected Value',
            },
        };
    }

    if (showPrevious) {
        functions = {
            ...functions,
            previousSelectedCoverage: {
                ...coverageInputBase,
                display: 'Previous Selected Coverage',
            },
            previousSelectedSetting: {
                ...settingInputBase,
                display: 'Previous Selected Value',
            },
        };
    }

    return functions;
}
