import { LinkComp } from '@lemonade-hq/bluiza';
import { Flex } from '@lemonade-hq/cdk';
import { links } from './dialogs/ReleaseTable.css';
import { getEditionName } from 'components/LoCo/common/editions/editionHelpers';
import { getEditionUrl } from 'components/LoCo/common/urlBuilders';
import type { Edition, EditionType } from 'models/LoCo/Insurance/BaseEdition';
type EditionLabelRenderer = (editionType: EditionType, edition: Edition) => React.ReactNode;

type EditionLabelProps = {
    readonly productCode: string;
    readonly edition: Edition;
    readonly editionType: EditionType;
    readonly openLinkInNewTab?: boolean;
    readonly editionLabelRenderer: EditionLabelRenderer;
};

interface EditionsLinksProps {
    readonly openLinkInNewTab?: boolean;
    readonly productCode: string;
    readonly editions: Partial<Record<EditionType, Edition>>;
    readonly editionLabelRenderer: EditionLabelRenderer;
}

export function friendlyNameRenderer(editionType: EditionType, edition: Edition): React.ReactNode {
    const editionTypeName = getEditionName(editionType);
    return `${editionTypeName}: ${edition.friendlyName}`;
}

const EditionLink: React.FC<EditionLabelProps> = ({
    productCode,
    edition,
    editionType,
    openLinkInNewTab,
    editionLabelRenderer,
}) => {
    const label = editionLabelRenderer(editionType, edition);

    return (
        <LinkComp
            target={openLinkInNewTab ? '_blank' : '_self'}
            url={getEditionUrl(productCode, edition.code, editionType)}
        >
            {label}
        </LinkComp>
    );
};

export const EditionsListLinks: React.FC<EditionsLinksProps> = ({
    productCode,
    editions,
    editionLabelRenderer,
    openLinkInNewTab = false,
}) => {
    if (Object.keys(editions).length === 0) {
        return null;
    }

    return (
        <Flex className={links}>
            {Object.entries(editions)
                .sort((entry1, entry2) => entry1[0].localeCompare(entry2[0]))
                .map(([editionType, edition]) => (
                    <EditionLink
                        edition={edition}
                        editionLabelRenderer={editionLabelRenderer}
                        editionType={editionType as EditionType}
                        key={edition.code}
                        openLinkInNewTab={openLinkInNewTab}
                        productCode={productCode}
                    />
                ))}
        </Flex>
    );
};
