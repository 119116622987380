import type { CSSColorValue, CSSLengthValue } from '@lemonade-hq/houdini-kit';
import { cssPaint as waveform } from '@lemonade-hq/houdini-kit/waveform';
import { randomFloat } from '@lemonade-hq/ts-helpers';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { AudioSource } from '../../audio/AudioSource';
import type { LayoutProps } from '../../base/Layout/Layout';
import { Layout } from '../../base/Layout/Layout';

function sinValues(length: number, upTo: number, multiplier = 1, variance = 0, offset = 0): number[] {
  return Array.from(
    { length },
    (_, i) =>
      Math.min(
        1,
        Math.max(
          0,
          Math.abs(Math.sin((((i / length) * upTo + (1 + offset)) % upTo) + randomFloat(-variance, variance))),
        ),
      ) * multiplier,
  );
}

export type AudioVisualizerProps = LayoutProps &
  React.HTMLAttributes<HTMLDivElement> & {
    readonly source: AudioSource;
    readonly color: string;
    readonly gapWidth?: CSSLengthValue;
    readonly strokeWidth?: CSSLengthValue;
    readonly initialOffset?: number;
    readonly minPresentedVolume?: number;
  };

export const AudioVisualizer: FC<AudioVisualizerProps> = ({
  source,
  color,
  gapWidth = '2px',
  strokeWidth = '2px',
  initialOffset = 0,
  minPresentedVolume = 0.05,
  className: externalClassName,
  ...props
}) => {
  const [volume, setVolume] = useState(0);
  const [offset, setOffset] = useState(initialOffset);

  useEffect(() => {
    let raf: number;
    let lastTimestamp: number = Date.now();
    const onVolume = ({ volume: eventVolume }: { volume: number }): void =>
      setVolume(Math.max(minPresentedVolume, eventVolume));
    const scheduleOffset = (): void => {
      const updateOffset = (currentTime = Date.now()): void => {
        const dt = currentTime - lastTimestamp;
        setOffset(val => val + dt * 0.005);
        raf = requestAnimationFrame(updateOffset);
        lastTimestamp = currentTime;
      };

      updateOffset();
    };

    const resetOffset = (): void => {
      setOffset(0);
      setVolume(0);
      cancelAnimationFrame(raf);
    };

    source.subscribe('volume', onVolume);
    source.subscribe('start', scheduleOffset);
    source.subscribe('stop', resetOffset);

    if (source.isPlaying) {
      scheduleOffset();
    }

    return () => {
      cancelAnimationFrame(raf);
      source.unsubscribe('volume', onVolume);
      source.unsubscribe('start', scheduleOffset);
      source.unsubscribe('stop', resetOffset);
    };
  }, [source, minPresentedVolume]);

  const values = sinValues(100, Math.PI + 0.1, volume, 0.25, offset);

  return (
    <Layout {...props} overflow="hidden">
      <Layout
        height="100%"
        left="0"
        style={waveform({
          values,
          color: color as CSSColorValue,
          gapWidth,
          strokeWidth,
        })}
        top="0"
        width="100%"
      />
    </Layout>
  );
};
