import { Image } from '@lemonade-hq/bluis';
import { CoreEntityType, ProductTypes } from '@lemonade-hq/bluiza';
import { font } from '@lemonade-hq/boutique';
import { Box, Flex } from '@lemonade-hq/cdk';
import { getCountryFlag } from '@lemonade-hq/lemonation';
import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { formatAddress } from '../../../Commons/AddressUtils';
import BoldedResult from './BoldedResult';
import ConditionalLink from './ConditionalLink';
import type { SearchItemUser } from './helpers';
import { Container, LeftSection, MainInfo, SecondaryInfo, SecondaryInfoUpperCase } from './shared';
import { ThemedColors } from 'bluis/Colors';
import { formatPhoneNumber } from 'commons/PhoneNumberUtils';

const Country = styled(Box)`
    font-size: 12px;
`;

export const Email = styled(Box)<{ readonly capitalize?: boolean }>`
    ${font('main', { fontSize: '12px', lineHeight: 'normal', fontWeight: 400 })}
    color: ${ThemedColors.secondaryText};
`;

const ResultUser: FC<
    React.PropsWithChildren<{
        readonly term: string;
        readonly user: SearchItemUser;
        readonly onSearchResultClick: (params: {
            readonly url: string;
            readonly entity: CoreEntityType;
            readonly product: ProductTypes;
            readonly index: number;
        }) => void;
        readonly index: number;
    }>
> = ({ term, user, onSearchResultClick, index }) => {
    const {
        name,
        email,
        external_id: externalId,
        phone_number: phoneNumber,
        url,
        country,
        address_components: addressComponents,
    } = user;
    const state = addressComponents != null ? addressComponents.state : '';
    const formattedPhoneNumber = phoneNumber && formatPhoneNumber(phoneNumber);
    const formattedAddress = formatAddress(addressComponents);

    return (
        <ConditionalLink
            onClick={() => onSearchResultClick({ url, entity: CoreEntityType.User, product: ProductTypes.User, index })}
            url={url}
        >
            <Container>
                <LeftSection>
                    <MainInfo>
                        <BoldedResult term={term} value={name} />
                    </MainInfo>
                    <SecondaryInfoUpperCase mb="2px">
                        <BoldedResult term={term} value={externalId} />
                    </SecondaryInfoUpperCase>
                    <SecondaryInfo mb="2px">{formattedAddress}</SecondaryInfo>
                    {email && (
                        <Box mb="2px">
                            <Flex alignItems="center">
                                <Image
                                    classNames="icon-search-preview"
                                    img={{
                                        lightSrc: 'email-icon-gray.svg',
                                        darkSrc: 'email-icon-gray-dark.svg',
                                        width: 12,
                                        height: 12,
                                    }}
                                />
                                <Email ml="3px">{email}</Email>
                            </Flex>
                        </Box>
                    )}
                    {formattedPhoneNumber && (
                        <SecondaryInfo mb="2px">
                            <Flex alignItems="center">
                                <Image
                                    classNames="icon-search-preview"
                                    img={{
                                        lightSrc: 'email-gray-icon.svg',
                                        darkSrc: 'email-gray-icon-dark.svg',
                                        width: 12,
                                        height: 12,
                                    }}
                                />
                                <Box ml="3px">{formattedPhoneNumber}</Box>
                            </Flex>
                        </SecondaryInfo>
                    )}
                </LeftSection>
                <Flex alignItems="center">
                    <Box mr="2px">{country ? getCountryFlag(country) : '-'}</Box>
                    <Country mb="2px">{state}</Country>
                </Flex>
            </Container>
        </ConditionalLink>
    );
};

export default ResultUser;
