import { useCallback } from 'react';
import type { FC } from 'react';
import { useManageRuleDialogContext } from './ManageRuleDialogContext';
import { SegmentFilterSelection } from './Steps/SegmentSelection';

export const ConnectedSegmentSelection: FC = () => {
    const { dispatch, setHasError, renderExpressionRef, values } = useManageRuleDialogContext();

    const setExpression = useCallback(
        (expression: string) => {
            dispatch({ type: 'expression', value: expression });
        },
        [dispatch]
    );

    return (
        <SegmentFilterSelection
            expression={values.expression}
            lifecycleContexts={values.lifecycleContexts ?? []}
            renderExpressionRef={renderExpressionRef}
            setExpression={setExpression}
            setSegmentFilterIsInvalid={setHasError}
        />
    );
};
