import type { Status } from '@lemonade-hq/blender-ui';
import { Accordion, Banner, Flex, spacing, SummarySection, Text } from '@lemonade-hq/blender-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { listItem } from './EditionSet.css';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';
import { useCheckCoverageEditionCompatibility } from 'queries/LoCo/Insurance/CoveragesEditionQueries';
import { useGetEditionSet } from 'queries/LoCo/Insurance/EditionSetQueries';
import { useCheckEditionSetCompatibility } from 'queries/LoCo/Insurance/ProductQueries';

interface EditionSetValidationsProps {
    readonly productCode: string;
    readonly editionSet: EditionSet;
}

const ValidationAccordion: React.FC<{
    readonly title: string;
    readonly messages: string[];
    readonly badges: { readonly label: string; readonly variant: Status }[];
    readonly banner: { readonly title: string; readonly variant: Status };
}> = ({ title, messages, badges, banner }) => {
    return (
        <Accordion badges={badges} disabled={messages.length === 0} title={title}>
            <SummarySection title="Violations">
                <Flex flexDirection="column" width="100%">
                    {messages.length > 0 && (
                        <Banner mb={spacing.s08} title={banner.title} variant={banner.variant} withBorder={false} />
                    )}
                    {messages.map(message => (
                        <Text className={listItem} key={message}>
                            {message}
                        </Text>
                    ))}
                </Flex>
            </SummarySection>
        </Accordion>
    );
};

export const EditionSetValidations: React.FC<EditionSetValidationsProps> = ({ productCode, editionSet }) => {
    const { data: baseEditionSet } = useGetEditionSet(editionSet.baseEditionSetCode);
    const showBreakingChanges = baseEditionSet != null;

    const { data: backwardsCompatibility } = useCheckCoverageEditionCompatibility(
        editionSet.coveragesEdition.code,
        baseEditionSet?.coveragesEdition.code ?? '',
        baseEditionSet != null
    );

    const { mutateAsync: checkCompatibilityMutate, data: { violationMessages = [] } = {} } =
        useCheckEditionSetCompatibility();

    const checkCompatibility = useCallback(async () => {
        await checkCompatibilityMutate({
            productCode,
            coveragesEditionCode: editionSet.coveragesEdition.code,
            digitalAgentEditionCode: editionSet.digitalAgentEdition.code,
            ratingEditionCode: editionSet.ratingEdition.code,
            underwritingFiltersEditionCode: editionSet.underwritingFiltersEdition.code,
        });
    }, [checkCompatibilityMutate, editionSet, productCode]);

    useEffect(() => {
        void checkCompatibility();
    }, [checkCompatibility]);

    const compatibilityBadges = useMemo(
        () => [
            {
                label:
                    violationMessages.length > 0
                        ? violationMessages.length === 1
                            ? '1 Compatibility Issue'
                            : `${violationMessages.length} Compatibility Issues`
                        : 'No Compatibility Issues',
                variant: (violationMessages.length > 0 ? 'negative' : 'positive') as Status,
            },
        ],
        [violationMessages]
    );

    const breakingChangesBadges = useMemo(
        () => [
            {
                label: backwardsCompatibility?.hasBreakingChanges
                    ? backwardsCompatibility.messages.length === 1
                        ? '1 Breaking Change'
                        : `${backwardsCompatibility.messages.length} Breaking Changes`
                    : 'No Breaking Changes',
                variant: 'info' as Status,
            },
        ],
        [backwardsCompatibility]
    );

    return (
        <Flex flexDirection="column" gap={spacing.s12}>
            <Text type="h4">Validations</Text>
            <ValidationAccordion
                badges={compatibilityBadges}
                banner={{
                    title: 'Edition sets with compatibility issues cannot be published to production',
                    variant: 'negative',
                }}
                messages={violationMessages}
                title="Edition Set Compatibility"
            />
            {showBreakingChanges && (
                <ValidationAccordion
                    badges={breakingChangesBadges}
                    banner={{
                        title: `Breaking changes were determined against edition set ${baseEditionSet.coveragesEdition.code}`,
                        variant: 'info',
                    }}
                    messages={backwardsCompatibility?.messages ?? []}
                    title="Coverages Backwards Compatibility"
                />
            )}
        </Flex>
    );
};
