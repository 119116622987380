/* eslint-disable @typescript-eslint/ban-types */
export type FieldTypes = 'currency';
export type Label = {
    readonly fieldName: string;
    readonly text: string;
    readonly labelColor: string;
    readonly textColor?: string;
    readonly textHoverColor?: string;
};
export type Field = {
    readonly width?: string;
    readonly name: string;
    readonly title: string;
    readonly orderField?: string;
    readonly fieldType?: FieldTypes;
    readonly labelList?: Label[];
    readonly link?: Link;
    readonly icon?: string;
};

export type Link = { readonly genFunc: (...args: string[]) => string } & (
    | { readonly argName: string }
    | { readonly argNames: string[] }
);

export type Data = {
    readonly [key: string]: number | string | null;
};

export type WithPagination = {
    readonly withPagination: true;
    readonly pageSize: number;
    readonly onPageClick: (pageNumber: number) => () => void;
    readonly offset: number;
    readonly resultsCount: number;
};

export type Orderable = {
    readonly orderBy: string | null;
    readonly orderByDir: OrderByDirection | null;
    readonly setOrderBy: (by: string) => void;
    readonly setOrderByDir: (direction: OrderByDirection) => void;
};

export enum OrderByDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export function isOrderable(props: Orderable | {}): props is Orderable {
    return Boolean((props as Orderable).setOrderBy);
}

export function isWithPagination(props: WithPagination | {}): props is WithPagination {
    return (props as WithPagination).withPagination;
}

export type EntitiesTableProps<T extends Field[]> = {
    readonly fields: T;
    readonly tableData: Data[];
    readonly title?: string;
    readonly className?: string;
    readonly isLoading?: boolean;
    readonly isError?: boolean;
    readonly resultsCount?: number;
    readonly withPagination?: boolean;
} & (Orderable | {}) &
    (WithPagination | {});
