import { LoadingSection } from '@lemonade-hq/bluis';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { HomeOwnersReviewEntity } from 'models/HomeOwnersReview';
import { useHomeownerReviewRelatedEntity } from 'queries/HomeownersReviewQueries';

enum EntitySubPath {
    Policy = 'policies',
    Quote = 'quotes',
}

const entityTypeToEntitySubPath: Record<HomeOwnersReviewEntity, EntitySubPath> = {
    [HomeOwnersReviewEntity.Policy]: EntitySubPath.Policy,
    [HomeOwnersReviewEntity.Quote]: EntitySubPath.Quote,
};

const REDIRECT_TO_SEARCH_PARAM = 'redirect_to';

export const RerouteToEntityPage: React.FC = () => {
    const { reviewId = '' } = useParams<{ reviewId: string }>();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { data: { entityPublicId = '', entityType } = {}, isLoading } = useHomeownerReviewRelatedEntity(reviewId);

    useEffect(() => {
        const redirectTo = searchParams.get(REDIRECT_TO_SEARCH_PARAM) ?? '';
        const shouldNavigate = Boolean(!isLoading && entityPublicId && entityType);

        if (shouldNavigate) {
            const entitySubPath = entityTypeToEntitySubPath[entityType ?? HomeOwnersReviewEntity.Policy];

            navigate(`/backoffice/home/${entitySubPath}/${entityPublicId}/${redirectTo}`);
        }
    }, [isLoading, entityPublicId, entityType, navigate, searchParams]);

    return <LoadingSection />;
};
