import axios from 'axios';
import { PaymentMethodType, PayoutOpts } from 'models/Payment';
import { ServiceName, getUrlResolver } from '@lemonade-hq/bluiza';

const carBlenderClaimsUrlResolver = getUrlResolver({
    service: ServiceName.CarBlender,
    basePath: '/api/v1/claims',
    fallbackMap: new Set(),
});
const carBlenderInvoicesUrlResolver = getUrlResolver({
    service: ServiceName.CarBlender,
    basePath: '/api/v1/invoices',
    fallbackMap: new Set(),
});

export async function payInvoice({
    invoiceId,
    paymentMethod,
    paymentMethodId,
    claimId,
    payoutOpts,
    waiveDeductibleReason,
}: {
    invoiceId: string;
    paymentMethod: PaymentMethodType;
    claimId: string;
    paymentMethodId?: string;
    payoutOpts?: PayoutOpts;
    waiveDeductibleReason?: string;
}): Promise<void> {
    const url = carBlenderClaimsUrlResolver(`/${claimId}/pay`);

    await axios.post(url, {
        invoiceId,
        paymentMethod,
        paymentMethodId,
        payoutOpts,
        waiveDeductibleReason,
    });
}

export async function generateInvoicePdf({
    invoiceId,
}: {
    invoiceId: string;
}): Promise<{ data: { invoicePdfUrl: string } }> {
    const url = carBlenderInvoicesUrlResolver(`/${invoiceId}/pdf`);

    const { data } = await axios.post<{ data: { invoicePdfUrl: string } }>(url);

    return data;
}
