import { Flex, spacing } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { useState } from 'react';
import { ArchiveForm } from '../Archive/ArchiveForm';
import { SidePanel } from '../Gallery.css';
import { useGetAttachment } from '../useGetAttachment';
import { GalleryAttachmentDetails } from './AttachmentDetails';
import { AttachmentSources } from './AttachmentSources/AttachmentSources';
import { TopActionsPanel } from './TopActionsPanel/TopActionsPanel';

const TopSidebar: FC<{ readonly assignedToEntity: boolean }> = ({ assignedToEntity }) => {
    const attachment = useGetAttachment();

    const shouldShowArchive =
        Boolean(attachment?.suggestedArchive) && assignedToEntity && attachment?.actions?.includes('archive');
    const [showArchive, setShowArchive] = useState(shouldShowArchive);

    if (attachment == null) return null;

    return (
        <>
            <TopActionsPanel setShowArchive={setShowArchive} />
            {showArchive && <ArchiveForm onCloseArchive={() => setShowArchive(false)} />}
        </>
    );
};

export const InfoSidebar: FC = () => {
    const attachment = useGetAttachment();

    if (attachment == null) return null;

    const archived = attachment.status === 'archived';
    // unassigned attachments are not editable, by default attachment are assigned to entity (i.e. claim)
    const docAssignedToEntity = attachment.assignedToEntity ?? true;

    return (
        <Flex className={SidePanel} flexDirection="column" gap={spacing.s16}>
            <TopSidebar assignedToEntity={docAssignedToEntity} key={attachment.publicId} />
            {docAssignedToEntity && (
                <>
                    {!archived && <GalleryAttachmentDetails key={attachment.publicId} />}
                    <AttachmentSources />
                </>
            )}
        </Flex>
    );
};
