import type { FC } from 'react';
import { Select } from '../Select/Select';
import * as styles from './AudioPlayer.css';
import { useAudioPlayer } from './AudioPlayer.Provider';

export const AudioPlayerPlaybackRateControls: FC = () => {
  const { setPlaybackRate, playbackRate } = useAudioPlayer();

  return (
    <Select
      className={styles.playbackRateControls}
      onChange={setPlaybackRate}
      options={[
        { label: 'x0.5', value: 0.5 },
        { label: 'x1', value: 1 },
        { label: 'x1.5', value: 1.5 },
        { label: 'x2', value: 2 },
        { label: 'x3', value: 3 },
      ]}
      placeholder="Playback rate"
      selectedKey={playbackRate}
    />
  );
};
