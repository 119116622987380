import type { FC } from 'react';
import { useRef } from 'react';
import { useDateSegment } from 'react-aria';
import type { DateSegment as AriaDateSegment, DateFieldState } from 'react-stately';
import { focusable } from './DateTimePicker.css';

export const DateSegment: FC<{
  readonly segment: AriaDateSegment;
  readonly state: DateFieldState;
  readonly disabled: boolean;
}> = ({ segment, state, disabled }) => {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment({ ...segment, isEditable: !disabled }, state, ref);

  return (
    <div className={focusable} ref={ref} {...segmentProps}>
      {segment.text}
    </div>
  );
};
