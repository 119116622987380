import { Card, Flex, Icon, spacing } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { GalleryMedia } from '../MediaComponents/GalleryMedia';
import { useGetAttachment } from '../useGetAttachment';
import { arrow, carouselWrapper, documentWrapper } from './Carousel.css';
import { useCarousel } from './CarouselProvider';
import { Detection } from './Detection';
import { OpenInNewTab } from './OpenInNewTab';
import { Thumbnails } from './Thumbnails';
import { AttachmentFormat, getAttachmentFormat, isInvalidContentType } from 'components/Attachments/utils';

const Carousel: FC = () => {
    const { previousSlide, nextSlide } = useCarousel();
    const { contentType, fileName } = useGetAttachment() ?? {};
    const type = getAttachmentFormat(!isInvalidContentType(contentType) ? contentType : fileName);

    return (
        <Flex flexDirection="column" height="100%">
            <div className={documentWrapper({ isVideo: type === AttachmentFormat.Video })}>
                <button className={arrow({ direction: 'prev' })} onClick={previousSlide} type="button">
                    <Icon name="chevron-down" />
                </button>
                <Flex alignItems="center" height="100%" justifyContent="center">
                    <GalleryMedia />
                </Flex>
                <button className={arrow({ direction: 'next' })} onClick={nextSlide} type="button">
                    <Icon name="chevron-down" />
                </button>
            </div>
            <Thumbnails />
        </Flex>
    );
};

export const AttachmentCarousel: FC = () => {
    const attachment = useGetAttachment();

    if (attachment == null) return null;

    return (
        <Card className={carouselWrapper}>
            <Flex
                alignItems="flex-start"
                justifyContent="space-between"
                position="relative"
                py={`0 ${spacing.s08}`}
                zIndex={1}
            >
                <Detection />
                <OpenInNewTab url={attachment.url} />
            </Flex>
            <Carousel />
        </Card>
    );
};
