import type { Locale } from '@lemonade-hq/lemonation';

export enum MacrosSupportedProductType {
  Pet = 'pet',
  Car = 'car',
  Home = 'home & renters',
  All = 'all',
}

export enum MacrosSupportedEntity {
  Claims = 'claims',
  Cx = 'cx',
  Underwriting = 'underwriting',
}

export const SupportedProductsForEntities = {
  [MacrosSupportedEntity.Claims]: [
    MacrosSupportedProductType.Car,
    MacrosSupportedProductType.Home,
    MacrosSupportedProductType.Pet,
  ],
  [MacrosSupportedEntity.Underwriting]: [MacrosSupportedProductType.Home],
  [MacrosSupportedEntity.Cx]: [MacrosSupportedProductType.All],
};

interface MacrosBaseEntity {
  readonly guruId: string;
  readonly title: string;
}

export interface MacrosCard extends MacrosBaseEntity {
  readonly content: string;
  readonly tags: string[];
}

export type CardReference = MacrosBaseEntity;

export interface MacrosSection extends MacrosBaseEntity {
  readonly cards: CardReference[];
}

export interface MacrosBoard extends MacrosBaseEntity {
  readonly items: (CardReference | MacrosSection)[];
}

export interface MacrosBoardGroup extends MacrosBaseEntity {
  readonly boards: MacrosBoard[];
}

export enum MacrosParam {
  ClaimId = 'claim_public_id',
  ClaimLossType = 'claim_loss_type',
  ClaimLossSubType = 'claim_loss_sub_type',
  ClaimDateOfLoss = 'date_of_loss',

  PolicyState = 'policy_state',
  PolicyId = 'policy_public_id',
  PolicyFormattedAddress = 'policy_formatted_address',
  PolicyDeductible = 'policy_deductible',
  PolicyHolderName = 'policyholder_full_name',
  PolicyEffectedDate = 'policy_effective_date',
  PolicyRenewalDate = 'policy_renewal_date',
  PolicyHolderFirstName = 'policyholder_first_name',

  QuoteId = 'quote_public_id',

  AdvocateFirstName = 'advocate_first_name',
  AdvocatePhoneNumber = 'advocate_phone_number',

  UwAssociateFirstName = 'uw_associate_first_name',

  TodaysDate = 'todays_date',
  GiveBackName = 'give_back_name',

  PetName = 'pet_name',
  PetBreed = 'pet_breed',
  PetSexSheHe = 'pet_sex_she_he',
  PetSexHerHim = 'pet_sex_her_him',
  PetSexHerHis = 'pet_sex_her_his',
  PetIllnessEligibleFrom = 'pet_illness_eligible_from',
  PetAccidentEligibleFrom = 'pet_accident_eligible_from',
  PetCruciateLigamentEligibleFrom = 'pet_cruciate_ligament_eligible_from',
  TicketRequesterFirstName = 'ticket.requester.first_name',
  CurrentUserFirstName = 'current_user.first_name',
  TicketAssigneeFirstName = 'ticket.assignee.first_name',
}

export type MacrosParamsObject = Record<MacrosParam, string>;

export type MacrosParamsPartialObject = Partial<MacrosParamsObject>;

export type MacrosConfig = {
  readonly forcedAdditionalSpacing?: boolean;
  readonly searchHierarchy?: boolean;
  readonly convertBrTagToLineBreak?: boolean; // hotfix: convert <br> tag to \n, to handle Zendesk macros format, that doesn't support inline br
  readonly customTextTransformer?: (text: string) => string;
};

export interface MacrosInitProps {
  readonly entityType: MacrosSupportedEntity;
  readonly productType: MacrosSupportedProductType;
  readonly locale: Locale;
  readonly params?: MacrosParamsPartialObject;
  readonly onFocus?: () => void;
  readonly config?: MacrosConfig;
}

export interface MacrosCollection {
  readonly entityType: MacrosSupportedEntity;
  readonly locale: Locale;
}
