import { toInitials } from '@lemonade-hq/ts-helpers';
import { clsx } from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';
import type { RequiredDeep } from 'type-fest';
import { Flex } from '../../base/Flex/Flex';
import { avatarContainer, avatarImage } from './Avatar.css';

export interface AvatarProps {
  readonly size?: RequiredDeep<Parameters<typeof avatarContainer>[0]>['size'];
  readonly src?: string;
  readonly alt?: string;
  readonly name?: string;
  readonly className?: string;
}

export const Avatar: FC<PropsWithChildren<AvatarProps>> = ({
  children,
  alt,
  name,
  size = 'md',
  src,
  className: externalClassName,
}) => {
  const [error, setError] = useState(false);
  const nameInitials = toInitials(name, { isShort: true });

  return (
    <Flex alignItems="center" className={clsx(avatarContainer({ size }), externalClassName)} justifyContent="center">
      {src != null && !error ? (
        <img alt={alt} className={avatarImage({ size })} loading="lazy" onError={() => setError(true)} src={src} />
      ) : (
        nameInitials || children
      )}
    </Flex>
  );
};
