import axios from 'axios';
import { Maybe } from '@lemonade-hq/ts-helpers';
import { CoOwnerUpdateRequest, Pet } from 'models/Pet';
import { getUrlResolver } from 'commons/UrlResolver';

const petBlenderUrlResolver = getUrlResolver('pet-blender', '/api/v1/pet');
const blenderGeneralUrlResolver = getUrlResolver('blender-general', '/api/v1');
const petBlenderTasksUrlResolver = getUrlResolver('pet-blender', '/api/v1');

export interface AddWorkflowTaskParams {
    claimPublicId: string;
    taskType: string;
    referenceId?: string;
    operatorPublicId?: string;
    actionDate?: string;
    forcedSquad?: string;
}

export interface AddWorkflowCustomTaskParams {
    referenceType: string;
    groupPublicId: string;
    title: string;
    operatorPublicId: Maybe<string>;
    actionDate: string;
    description: string;
    forcedSquad?: string;
}

export async function fetchPet(petPublicId: string): Promise<Pet> {
    const url = petBlenderUrlResolver(`/${petPublicId}`);

    return axios.get(url).then(response => response.data.data);
}

export async function updatePet({
    petPublicId,
    pet,
    updatedCoOwners,
}: {
    petPublicId: string;
    pet: Partial<Pet>;
    updatedCoOwners: CoOwnerUpdateRequest;
}): Promise<Pet> {
    const url = petBlenderUrlResolver(`/${petPublicId}`);

    return axios.put(url, { ...pet, coOwnerUpdateRequest: updatedCoOwners }).then(response => response.data.data);
}

export async function addCoOwner({
    petPublicId,
    firstName,
    lastName,
}: {
    petPublicId: string;
    firstName: string;
    lastName: string;
}): Promise<Pet> {
    const url = petBlenderUrlResolver(`/${petPublicId}`);
    const coOwnerUpdateRequest = { coOwnersCreated: [{ firstName, lastName }] };

    return axios.put(url, { coOwnerUpdateRequest }).then(response => response.data.data);
}

export async function addPetWorkflowsTask({ data }: { data: AddWorkflowTaskParams }): Promise<void> {
    const url = petBlenderTasksUrlResolver('/claim_tasks/create');

    return axios.post(url, data);
}

export async function addPetWorkflowsCustomTask({ data }: { data: AddWorkflowCustomTaskParams }): Promise<void> {
    const url = blenderGeneralUrlResolver('/custom_tasks');

    return axios.post(url, data);
}

export async function reopenTask({ claimPublicId, taskId }: { claimPublicId: string; taskId: string }): Promise<null> {
    const url = petBlenderTasksUrlResolver(`/claim_tasks/reopen`);

    const data = {
        claimPublicId,
        taskPublicId: taskId,
    };

    return axios.post(url, data).then(response => response.data.data);
}
