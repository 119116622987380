import { useCallback, useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
    VOICE_CONVERSATIONS_PATH,
    VOICE_CONVERSATIONS_PATH_WITH_PARAMS,
    VOICE_FULL_PATH_PREFIX,
    VoiceURLParam,
} from '../Voice.Routes';
import type { VoiceURLParams } from '../Voice.Routes';
import type { VoiceConversation } from '../Voice.types';

export function useSelectedConversation(
    conversations: VoiceConversation[] | undefined
): [string | undefined, (conversationPublicId: string) => void] {
    const navigate = useNavigate();
    const { conversationPublicId: selectedConversationPublicId = undefined } = useParams<VoiceURLParams>();

    const selectedToolId = useMemo(
        () => conversations?.find(c => c.publicId === selectedConversationPublicId)?.publicId,
        [conversations, selectedConversationPublicId]
    );

    const setSelectedConversationPublicId = useCallback(
        (conversationPublicId: string | undefined) => {
            const path =
                conversationPublicId != null && conversationPublicId.length > 0
                    ? generatePath(`${VOICE_FULL_PATH_PREFIX}${VOICE_CONVERSATIONS_PATH_WITH_PARAMS}`, {
                          [VoiceURLParam.ConversationPublicId]: conversationPublicId,
                      })
                    : `${VOICE_FULL_PATH_PREFIX}${VOICE_CONVERSATIONS_PATH}`;
            navigate(path);
        },
        [navigate]
    );

    useEffect(() => {
        if (selectedToolId == null && conversations != null) {
            setSelectedConversationPublicId(conversations[0].publicId);
        }
    }, [conversations, selectedToolId, setSelectedConversationPublicId]);

    return [selectedConversationPublicId, setSelectedConversationPublicId];
}
