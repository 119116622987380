import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';

const petBlenderUrlResolver = getUrlResolver('pet-blender', '/api/v1');

export enum SourceTaskType {
    RiskAssessment = 'risk_assessment',
    VerifyInvoice = 'verify_invoice',
    Custom = 'custom',
}

export enum DocumentType {
    AllRecords = 'all_records',
    DateOfLoss = 'date_of_loss',
    Labs = 'labs',
    Invoice = 'invoice',
    Other = 'other',
}

export type CreateDocumentRequest = {
    treatmentPlacePublicId: string;
    documentTypes: DocumentType[];
    additionalInfo?: string;
    forcedSquad?: string;
};

export type CreateDocumentRequestGroup = {
    claimPublicId: string;
    sourceTaskType: SourceTaskType;
    documentRequests: CreateDocumentRequest[];
    isCreateTask?: boolean;
};

export type UpdateDocumentRequestsBody = {
    isDocumentAttached: boolean;
    documentNotAttachedInfo?: string;
};

export interface DocumentRequestDTO {
    publicId: string;
    treatmentPlacePublicId: string;
    documentTypes: DocumentType[];
    createdAt: string;
    isDocumentAttached?: boolean;
    additionalInfo?: string;
    documentNotAttachedInfo?: string;
    operatorPublicId?: string;
    actionDate?: string;
}

export interface DocumentRequestGroupDTO {
    publicId: string;
    claimPublicId: string;
    sourceTaskType: SourceTaskType;
    createdAt: string;
    documentRequests?: DocumentRequestDTO[];
}

export async function createDocumentRequests(createData: CreateDocumentRequestGroup): Promise<DocumentRequestGroupDTO> {
    const url = petBlenderUrlResolver('/document_requests');

    return axios.post(url, createData).then(response => response.data.data);
}

export async function updateDocumentRequest({
    documentRequestId,
    updateData,
}: {
    documentRequestId: string;
    updateData: UpdateDocumentRequestsBody;
}): Promise<void> {
    const url = petBlenderUrlResolver(`/document_requests/${documentRequestId}`);

    await axios.put(url, updateData).then(response => response.data.data);
}
