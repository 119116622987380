import { OrderByDirection } from '@lemonade-hq/bluis';
import { useCallback, useState } from 'react';

// a hook that sorts table data by a column
// stores the current sort column and direction, but not the data itself to prevent redundancy
export function useTableSort<T>(
    initialSortColumn: Partial<keyof T>,
    initialSortDirection: OrderByDirection = OrderByDirection.Asc
) {
    const [sortColumn, setSortColumn] = useState<Partial<keyof T>>(initialSortColumn);
    const [sortDirection, setSortDirection] = useState<OrderByDirection>(initialSortDirection);

    const onColumnClick = useCallback(
        (column: Partial<keyof T>) => {
            if (column === sortColumn) {
                setSortDirection(sortDirection === OrderByDirection.Asc ? OrderByDirection.Desc : OrderByDirection.Asc);
            } else {
                setSortColumn(column);
                setSortDirection(OrderByDirection.Asc);
            }
        },
        [sortColumn, sortDirection]
    );

    const sort = useCallback(
        (data: T[]) => {
            return data.sort((a, b) => {
                let result = 0;

                if (a[sortColumn] > b[sortColumn]) {
                    result = 1;
                } else if (a[sortColumn] < b[sortColumn]) {
                    result = -1;
                } else {
                    return 0;
                }

                return sortDirection === OrderByDirection.Asc ? result : -result;
            });
        },
        [sortColumn, sortDirection]
    );

    return { sort, sortColumn, sortDirection, onColumnClick };
}
