import { useQuery } from '@tanstack/react-query';
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { insuranceBlender } from '../../../apiClients';
import { CoveragesEditionQueryKey } from './CoveragesEditionQueries';
import { DigitalAgentEditionQueryKey } from './DigitalAgentEditionQueries';
import { RatingEditionQueryKey } from './RatingEditionQueries';
import { UnderwritingFiltersEditionQueryKey } from './UnderwritingFiltersEditionQueries';
import type { EditionViolations } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { EditionEligibilityResponse, EditionTrackerReference } from 'models/LoCo/Insurance/EditionTracker';
import { usePessimisticMutation } from 'queries/MutationHooks';

export enum EditionTrackerKey {
    GetProductUpdateEligibility = 'GetProductUpdateEligibility',
    GetBackwardsCompatibility = 'GetBackwardsCompatibility',
    GetLatestsPublished = 'GetLatestsPublished',
    GetEditionViolations = 'GetEditionViolations',
}

const EDITION_TRACKER_BASE_PATH = '/api/v1/edition-trackers';

async function approveEdition({ editionTrackerPublicId }: { readonly editionTrackerPublicId: string }): Promise<void> {
    await insuranceBlender.put(`${EDITION_TRACKER_BASE_PATH}/${editionTrackerPublicId}/approve`);
}

export function useApproveEdition(
    productCode: string,
    editionCode: string,
    editionType: EditionType
): UseMutationResult<void, unknown, { readonly editionTrackerPublicId: string }, null> {
    const { invalidateKey, mutationKey } = getApproveKeys(editionType);

    return usePessimisticMutation({
        mutationFn: approveEdition,
        invalidateKeys: [
            [invalidateKey, editionCode],
            [EditionTrackerKey.GetLatestsPublished, productCode],
        ],
        mutationKey: [mutationKey],
    });
}

async function archiveEdition({ editionTrackerPublicId }: { readonly editionTrackerPublicId: string }): Promise<void> {
    await insuranceBlender.put(`${EDITION_TRACKER_BASE_PATH}/${editionTrackerPublicId}/archive`);
}

export function useArchiveEdition(
    editionType: string,
    productCode?: string
): UseMutationResult<void, unknown, { readonly editionTrackerPublicId: string }, null> {
    const { invalidateKey, mutationKey } = getArchiveKeys(editionType as EditionType);
    return usePessimisticMutation({
        mutationFn: archiveEdition,
        invalidateKeys: [[invalidateKey, productCode]],
        mutationKey: [mutationKey],
    });
}

export async function getProductUpdateEligibility(productCode: string): Promise<EditionEligibilityResponse[]> {
    const response = await insuranceBlender.get<{ data: EditionEligibilityResponse[] }>(
        `${EDITION_TRACKER_BASE_PATH}/product-update-eligibility?productCode=${productCode}`
    );

    return response.data.data;
}

export function useGetProductUpdateEligibility(productCode: string): UseQueryResult<EditionEligibilityResponse[]> {
    return useQuery({
        queryKey: [EditionTrackerKey.GetProductUpdateEligibility, productCode],
        queryFn: async () => await getProductUpdateEligibility(productCode),
        enabled: Boolean(productCode),
    });
}

export async function getLatestsPublished(productCode: string): Promise<EditionTrackerReference[]> {
    const response = await insuranceBlender.get<{ data: EditionTrackerReference[] }>(
        `${EDITION_TRACKER_BASE_PATH}/latest-published?productCode=${productCode}`
    );

    return response.data.data;
}

export function useGetLatestsPublished(productCode: string): UseQueryResult<EditionTrackerReference[]> {
    return useQuery({
        queryKey: [EditionTrackerKey.GetLatestsPublished, productCode],
        queryFn: async () => await getLatestsPublished(productCode),
        enabled: Boolean(productCode),
    });
}

export async function getEditionViolations(editionTrackerPublicId: string): Promise<EditionViolations> {
    return await insuranceBlender
        .get<{ data: EditionViolations }>(`${EDITION_TRACKER_BASE_PATH}/${editionTrackerPublicId}/violations`)
        .then(response => response.data.data);
}

export function useGetEditionViolations(editionTrackerPublicId: string): UseQueryResult<EditionViolations> {
    return useQuery({
        queryKey: [EditionTrackerKey.GetEditionViolations, editionTrackerPublicId],
        queryFn: async () => await getEditionViolations(editionTrackerPublicId),
        gcTime: 0,
    });
}

// Key helper functions
type QueryKeys = { readonly invalidateKey: string; readonly mutationKey: string };

function getApproveKeys(editionType: EditionType): QueryKeys {
    switch (editionType) {
        case EditionType.Coverages:
            return {
                invalidateKey: CoveragesEditionQueryKey.GetCoveragesEdition,
                mutationKey: CoveragesEditionQueryKey.ApproveCoveragesEdition,
            };
        case EditionType.DigitalAgent:
            return {
                invalidateKey: DigitalAgentEditionQueryKey.GetDigitalAgentEdition,
                mutationKey: DigitalAgentEditionQueryKey.ApproveDigitalAgentEdition,
            };
        case EditionType.UnderwritingFilters:
            return {
                invalidateKey: UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition,
                mutationKey: UnderwritingFiltersEditionQueryKey.ApproveUnderwritingFiltersEdition,
            };
        case EditionType.Rating:
            return {
                invalidateKey: RatingEditionQueryKey.GetRatingEdition,
                mutationKey: '',
            };
        default:
            throw new Error(`Invalid edition type: ${editionType}`);
    }
}

function getArchiveKeys(editionType: EditionType): QueryKeys {
    switch (editionType) {
        case EditionType.Coverages:
            return {
                invalidateKey: CoveragesEditionQueryKey.GetProductCoveragesEdition,
                mutationKey: CoveragesEditionQueryKey.ArchiveCoveragesEdition,
            };
        case EditionType.DigitalAgent:
            return {
                invalidateKey: '',
                mutationKey: DigitalAgentEditionQueryKey.ArchiveDigitalAgentEdition,
            };
        case EditionType.UnderwritingFilters:
            return {
                invalidateKey: UnderwritingFiltersEditionQueryKey.GetProductUnderwritingFiltersEditions,
                mutationKey: UnderwritingFiltersEditionQueryKey.ArchiveUnderwritingFiltersEdition,
            };
        default:
            throw new Error(`Invalid edition type: ${editionType}`);
    }
}
