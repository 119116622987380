import { ServiceName, getUrlResolver } from '@lemonade-hq/bluiza';
import { RequireOnlyOne } from '@lemonade-hq/ts-helpers';
import axios from 'axios';
import { FraudDetection } from 'models/Attachment';

const blenderGeneralUrlResolver = getUrlResolver({ service: ServiceName.BlenderGeneral, fallbackMap: new Set() });

interface ScanFraudArgsBase {
    attachmentPublicId: string;
    entityPublicId: string;
    filePublicId: string | null;
    fileUrl: string;
    productLine?: 'home_eu' | 'home_us';
    model?: 'resistant_ai';
}

type ScanFraudArgs = RequireOnlyOne<ScanFraudArgsBase, 'filePublicId' | 'fileUrl'>;
export type ScanFraudError = { data?: { type: string } };

export const scanForFraud = async (body: ScanFraudArgs): Promise<null> => {
    const url = blenderGeneralUrlResolver(`/api/v1/fraud_detection`);

    return await axios.post(url, {
        ...body,
        model: 'resistant_ai',
    });
};

export type ScanStatusResult = FraudDetection & { attachmentPublicId: string };

export const getScanStatus = async (attachmentPublicIds: string[]): Promise<ScanStatusResult[]> => {
    const url = `${blenderGeneralUrlResolver(`/api/v1/fraud_detection`)}?attachmentPublicId=${attachmentPublicIds.join(
        ','
    )}`;

    return await axios.get(url).then(res => res.data?.data);
};
