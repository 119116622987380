import React from 'react';
import { Checkbox } from '@lemonade-hq/bluis';
import { snakeToCamelCaseKeys } from '@lemonade-hq/ts-helpers';
import './style.scss';

export default function ClaimItem({ item, currency, addItem, removeItem, checked }) {
    const { title, valueInCents: value, publicId, id } = snakeToCamelCaseKeys(item);
    const itemId = publicId || id;
    const priceValue = value / 100;
    const precision = value % 100 === 0 ? 0 : 2;
    const price = I18n.toCurrency(priceValue, { ...currency, ...{ precision } });
    const text = `${title} (${price})`;
    const onChange = e => {
        e.preventDefault();
        e.stopPropagation();

        if (checked) {
            removeItem(itemId);
        } else {
            addItem(itemId);
        }
    };

    return (
        <div key={itemId} className="attachment-claim-item" onClick={onChange}>
            <Checkbox
                {...{
                    checked,
                    onChange: () => {},
                }}
            />
            <div>{text}</div>
        </div>
    );
}
