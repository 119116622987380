import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core';
import type { Coordinates } from '@dnd-kit/utilities';
import { Flex, IconButton, Input, Layout, spacing } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { useAnalytics } from '@lemonade-hq/boutique';
import type { FC, PropsWithChildren } from 'react';
import { useLayoutEffect, useMemo, useReducer, useState } from 'react';
import type { AttachmentDTO } from '../../types';
import { draggableButton, image, imageActionBg, imageActions, imageWrapper, zoomInput } from './Media.css';
import { getAttachmentAnalyticsParam, getThumbnailUrl } from 'components/Attachments/utils';

const zoomValues = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500];
const zoomResetIndex = zoomValues.indexOf(100);

type State = {
    readonly rotate: number;
    readonly zoomIndex: number;
};

const initialState: State = {
    rotate: 0,
    zoomIndex: zoomResetIndex,
};

type Action =
    | { readonly type: 'RESET' }
    | { readonly type: 'SET_ROTATE'; readonly payload: number }
    | { readonly type: 'SET_ZOOM_INDEX'; readonly payload: number };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_ROTATE':
            return { ...state, rotate: action.payload };
        case 'SET_ZOOM_INDEX':
            return { ...state, zoomIndex: action.payload };
        case 'RESET':
            return { rotate: 0, zoomIndex: zoomResetIndex };
        default:
            // eslint-disable-next-line no-console
            console.error('This action type is not supported');
            return state;
    }
};

const ImageActions: FC<{
    readonly dispatch: React.Dispatch<Action>;
    readonly state: State;
    readonly attachment: AttachmentDTO;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly isGallery?: boolean;
}> = ({ dispatch, state, attachment, entityPublicId, entityType, isGallery }) => {
    const { trackEvent } = useAnalytics();
    const { rotate, zoomIndex } = state;
    const { url } = attachment;

    useLayoutEffect(() => {
        return () => {
            dispatch({ type: 'RESET' });
        };
    }, [dispatch, url]);

    const downloadFile = (): void => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment,
                entityType,
                entityId: entityPublicId,
            }),
            platform: isGallery ? 'gallery' : 'hub',
            action: 'click',
            action_type: 'download',
        });
        window.open(url.replaceAll('?inline=true', ''), '_blank');
    };

    const handleRotate = (): void => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment,
                entityType,
                entityId: entityPublicId,
            }),
            platform: isGallery ? 'gallery' : 'hub',
            action: 'click',
            action_type: 'rotate',
        });
        dispatch({ type: 'SET_ROTATE', payload: rotate + 90 });
    };

    const handleZoomOut = (): void => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment,
                entityType,
                entityId: entityPublicId,
            }),
            platform: isGallery ? 'gallery' : 'hub',
            action: 'click',
            action_type: 'zoom_out',
        });
        dispatch({ type: 'SET_ZOOM_INDEX', payload: zoomIndex - 1 });
    };

    const handleZoomIn = (): void => {
        trackEvent('docs.clicked', {
            ...getAttachmentAnalyticsParam({
                attachment,
                entityType,
                entityId: entityPublicId,
            }),
            platform: isGallery ? 'gallery' : 'hub',
            action: 'click',
            action_type: 'zoom_in',
        });
        dispatch({ type: 'SET_ZOOM_INDEX', payload: zoomIndex + 1 });
    };

    return (
        <Flex className={imageActions} gap={spacing.s08}>
            <Layout className={imageActionBg}>
                <IconButton color="neutral7" icon="rotate" onClick={handleRotate} size="lg" variant="inline" />
            </Layout>
            <Flex alignItems="center" className={imageActionBg} padding={`0 ${spacing.s10}`}>
                <IconButton
                    color="neutral7"
                    disabled={zoomIndex === 0}
                    icon="minus-solid"
                    onClick={handleZoomOut}
                    variant="inline"
                />
                <Flex alignItems="center" justifyContent="center" padding={`0 ${spacing.s08} 0 0`}>
                    <Input className={zoomInput} disabled value={`${zoomValues[zoomIndex]}%`} />
                </Flex>
                <IconButton
                    color="neutral7"
                    disabled={zoomIndex === zoomValues.length - 1}
                    icon="plus-solid"
                    onClick={handleZoomIn}
                    variant="inline"
                />
            </Flex>
            <Layout className={imageActionBg}>
                <IconButton color="neutral7" icon="download" onClick={downloadFile} size="lg" variant="inline" />
            </Layout>
        </Flex>
    );
};

const droppableId = ({ id }: { readonly id: string }): string => `gallery-image-droppable-${id}`;

const DroppableArea: FC<PropsWithChildren<{ readonly id: string }>> = ({ children, id }) => {
    const { setNodeRef } = useDroppable({
        id: droppableId({ id }),
    });

    return (
        <div className={imageWrapper} ref={setNodeRef}>
            {children}
        </div>
    );
};

const DraggableImage: FC<PropsWithChildren<{ readonly x: number; readonly y: number; readonly id: string }>> = ({
    x,
    y,
    children,
    id,
}) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: `gallery-image-draggable-${id}`,
    });
    const style = {
        top: y,
        left: x,
        ...(transform && {
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        }),
    };

    return (
        <button className={draggableButton} ref={setNodeRef} style={style} type="button" {...listeners} {...attributes}>
            {children}
        </button>
    );
};

export const GalleryImage: FC<{
    readonly attachment: AttachmentDTO;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly isGallery?: boolean;
}> = ({ attachment, entityPublicId, entityType, isGallery }) => {
    const { fileName, publicId } = attachment;
    const [state, dispatch] = useReducer(reducer, initialState);
    const zoomValue = useMemo(() => zoomValues[state.zoomIndex], [state.zoomIndex]);
    const imageUrl = useMemo(
        () => getThumbnailUrl(attachment, isGallery ? 'large' : 'medium'),
        [attachment, isGallery]
    );
    const [{ x, y }, setCoordinates] = useState<Coordinates>({ x: 0, y: 0 });

    const handleDragEnd = (event: DragEndEvent) => {
        if (event.over && event.over.id === droppableId({ id: publicId })) {
            setCoordinates(({ x: currentX, y: currentY }) => {
                return {
                    x: currentX + event.delta.x,
                    y: currentY + event.delta.y,
                };
            });
        }
    };

    return (
        <DndContext onDragEnd={handleDragEnd}>
            <DroppableArea id={publicId}>
                <DraggableImage id={publicId} x={x} y={y}>
                    <img
                        alt={fileName ?? ''}
                        className={image}
                        loading="lazy"
                        src={imageUrl}
                        style={{ transform: `scale(${zoomValue}%) rotate(${state.rotate}deg)` }}
                    />
                </DraggableImage>
                <ImageActions
                    attachment={attachment}
                    dispatch={dispatch}
                    entityPublicId={entityPublicId}
                    entityType={entityType}
                    isGallery={isGallery}
                    state={state}
                />
            </DroppableArea>
        </DndContext>
    );
};
