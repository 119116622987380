import type { Infer, RecordLikeShape } from '@lemonade-hq/maschema-schema';
import { validateRecord } from '@lemonade-hq/maschema-schema';

/**
 * Trims an object to match the shape of a given schema.
 *
 * In the future, this function should be moved to maschema-schema after some more testing and edge
 * cases coverage
 */
export function trimObjectToSchema<TSchema extends RecordLikeShape>(
    obj: Infer<TSchema>,
    schema: TSchema
): Infer<TSchema> {
    if (typeof obj !== 'object' || obj === null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return obj;
    }

    const result: Record<string, unknown> = {};

    // eslint-disable-next-line default-case
    switch (schema.type) {
        case 'record': {
            for (const key in schema.schema) {
                if (key in obj) {
                    const fieldSchema = schema.schema[key];
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    const fieldValue = obj[key];

                    if (fieldSchema.type === 'record') {
                        result[key] = trimObjectToSchema(fieldValue as Infer<typeof fieldSchema>, fieldSchema);
                    } else if (fieldSchema.type === 'array' && Array.isArray(fieldValue)) {
                        result[key] = fieldValue.map(item =>
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
                            fieldSchema.shape.type === 'record' ? trimObjectToSchema(item, fieldSchema.shape) : item
                        );
                    } else {
                        result[key] = fieldValue;
                    }
                }
            }

            break;
        }

        case 'referential': {
            throw new Error('Not implemented yet: "referential" case');
        }

        case 'union': {
            const matchedShape = schema.shapes.find(shape => validateRecord(shape, obj).valid);

            if (matchedShape) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return trimObjectToSchema(obj, matchedShape) as Infer<TSchema>;
            }

            throw new Error('No matching shape found for union type');
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result as Infer<TSchema>;
}
