import type { HomeClaimItem } from './HomeClaim';
import type { AttachmentRelationEntityType } from 'models/CarShared';
import { CarAttachmentType } from 'models/CarShared';

export enum AttachmentDetectionType {
    ModifiedDocuments = 'modified_documents',
}

export interface AttachmentDetection {
    readonly public_id: string;
    readonly prediction_id: string;
    readonly fraud_detection_type: AttachmentDetectionType;
    readonly manual_feedback: boolean | null;
    readonly details: {
        readonly reconstructed_original_url?: string;
        readonly side_by_side_file_url?: string;
        readonly left_comparison_file_url?: string;
        readonly right_comparison_file_url?: string;
        readonly text_changes?: { readonly [index: string]: string[] };
    };
}

export type Attachment = {
    readonly type: AttachmentType;
    readonly subtype?: string;
    readonly download_url: string;
    readonly thumbnail?: string;
    readonly created_at?: string;
    readonly content_type: string;
    readonly description?: string;
    readonly taken_at?: string;
    readonly location?: string;
    readonly file_public_id: string;
    readonly original_filename: string;
    readonly public_id?: string;
    readonly transcription?: string;
    readonly transcription_confidence?: number;
    readonly source?: AttachmentSource;
    readonly user_saved?: boolean;
    readonly attachment_detections?: AttachmentDetection[];
    readonly entity_public_id?: string;
    readonly claimItems?: HomeClaimAttachmentItem[];
    readonly id?: number | string;
    readonly fraudDoc?: {
        readonly prediction: boolean;
        readonly annotatedSource: string | null;
        readonly annotatedReconstructed: string | null;
        readonly textChanges?: { readonly [index: string]: string[] };
    } | null;
    readonly fraudDetections?: FraudDetection[];
    readonly fraud_detection_identifier: string | null;
    readonly originEntityPublicId?: string | null;
    readonly mediaAttachmentPublicId?: string;
};

export enum AttachmentSource {
    Chat = 'chat',
    Email = 'email',
    Manual = 'manual',
    MitchellPd = 'mitchell_pd',
    MitchellBi = 'mitchell_bi',
}

export interface AttachmentToUpload {
    readonly file: File;
    readonly type: AttachmentType;
    readonly description?: string;
    readonly involvedPartyId?: string;
    readonly involvedPartyType?: AttachmentRelationEntityType;
    readonly filename?: string;
    readonly filePublicId?: string;
}

export enum AttachmentShared {
    Claim = 'pet/claim_attachments',
    Preapproval = 'pet/preapproval_attachments',
}

export enum AttachmentEntityType {
    Policy = 'policy',
    Claim = 'claim',
    Pet = 'pet',
    Preapproval = 'preapproval',
}

export interface NotificationsAttachment {
    readonly name: string;
    readonly filePublicId: string;
    readonly type: string;
    readonly url: string;
}

export enum ProductAttachmentType {
    PolicyPDF = 'policy_pdf',
    ClaimVideo = 'claim_video',
    ClaimAudio = 'claim_audio',
    ClaimVideoPreview = 'claim_video_preview',
    UserSignature = 'user_signature',
    ClaimReceipt = 'claim_receipt',
    PoliceReport = 'police_report',
    Attachment = 'attachment',
}

export enum PetAttachmentType {
    MedicalExam = 'medical_exam',
    PetPhoto = 'pet_photo',
    ClaimReceipt = 'claim_receipt',
    ClaimVetNote = 'claim_vet_note',
    ClaimPhoto = 'claim_photo',
    ClaimRecording = 'claim_recording',
    ClaimOther = 'claim_other',
    PolicyImage = 'policy_image',
    PreapprovalEstimate = 'preapproval_estimate',
    PreapprovalOther = 'preapproval_other',
    InsuranceCancellation = 'insurance_cancellation',
    WaitingPeriodWaiverForm = 'waiting_period_waiver_form',
}

export enum PetAttachmentShards {
    MedicalRecords = 'medical_records',
    ClaimVetNote = 'claim_vet_note',
    ClaimReceipt = 'claim_receipt',
    InsuranceCancellation = 'insurance_cancellation',
}

export enum HomeAttachmentType {
    HomePolicyPDF = 'Policy PDF',
    Image = 'scheduled_item_image',
    Receipt = 'scheduled_item_receipt',
    Appraisal = 'scheduled_item_appraisal',
    ImageAndReceipt = 'scheduled_item_image_and_receipt',
    PoliceReport = 'police_report',
    FireReport = 'fire_report',
    LeaseCopy = 'lease_copy',
    BylawsCopy = 'bylaws_copy',
}

export interface HomeAttachment {
    readonly description: string;
    readonly desc: string;
    readonly dispatchable: boolean;
    readonly editable: boolean;
    readonly id: number | string;
    readonly name: string;
    readonly status: string;
    readonly taken_at: string;
    readonly thumbnail: string;
    readonly type: string;
    readonly url: string;
    readonly user_saved: boolean;
}

export type HomeClaimAttachmentItem = Pick<HomeClaimItem, 'publicId' | 'title' | 'valueInCents'>;

export interface HomeClaimAttachment {
    readonly address: string | null;
    readonly claimItems: HomeClaimAttachmentItem[];
    readonly contentType: string | null;
    readonly createdAt: string | null;
    readonly description: string | null;
    readonly editable: boolean;
    readonly filename: string | null;
    readonly fraudDoc: {
        readonly annotatedSource: string | null;
        readonly annotatedReconstructed: string | null;
        readonly prediction: boolean;
        readonly source: string | null;
    } | null;
    readonly id: number | string;
    readonly originEntityPublicId: string | null;
    readonly status: string;
    readonly takenAt: string | null;
    readonly thumbnailUrl: string | null;
    readonly type: string;
    readonly url: string;
    readonly userSaved: boolean | null;
    readonly filePublicId: string | null;
}

export interface PetAttachmentAutoItemization {
    readonly filePublicId: string;
    readonly type: AttachmentType;
    readonly takenAt?: string;
    readonly contentType?: string;
    readonly createdAt: string;
    readonly description?: string;
    readonly entityId: string;
    readonly deletable: boolean;
    readonly userSaved?: boolean;
    readonly transcriptionConfidence?: number;
    readonly transcription?: string;
    readonly url: string;
}

export const AttachmentType = {
    ...ProductAttachmentType,
    ...PetAttachmentType,
    ...CarAttachmentType,
    ...HomeAttachmentType,
};

export type AttachmentType = CarAttachmentType | HomeAttachmentType | PetAttachmentType | ProductAttachmentType;

export type AttachmentOptionalRelations = Partial<Record<AttachmentType, string[]>>;

export interface AttachmentsFiltersData {
    readonly types: AttachmentType[];
    readonly entityPublicIds: (string | null)[];
    readonly sources: AttachmentSource[];
}

export interface GroupAttachmentItem extends Attachment {
    readonly index: number;
}

export interface AttachmentGroup<TAttachment> {
    readonly type: string;
    readonly displayName: string;
    readonly items: (GroupAttachmentItem & TAttachment)[];
}

// Fraud detection - handle 3 types of detection models, each has a different detection result
export type DetectionModel = 'legacy_lmnd_result' | 'lmnd_ds' | 'resistant_ai';

export interface FraudDetection {
    readonly detectionModel: DetectionModel;
    readonly detectionResult: DetectionResult | null;
    readonly detectionStatus?: 'completed' | 'failed' | 'processing';
}

export type DetectionResult = LegacyDetectionResult | LmndDsDetectionResult | ResistantAiDetectionResult;

export interface LegacyDetectionResult {
    readonly prediction: boolean;
    readonly annotatedSource: string | null;
    readonly annotatedReconstructed: string | null;
}

export interface LmndDsDetectionResult {
    readonly predictionId: string;
    readonly prediction: 0 | 1;
    readonly predictionDetails?: {
        readonly textChanges: { readonly [index: string]: string[] };
    };
}

export interface ResistantAiDetectionResult {
    readonly externalViewUrl: string;
    readonly status?: string;
    readonly analysisTime?: string;
    readonly deploymentVersion?: string;
    readonly queryId?: string;
    readonly fileType?: string;
    readonly mimeType?: string;
    readonly sha256?: string;
    readonly score?: 'HIGH_RISK' | 'NORMAL' | 'TRUSTED' | 'WARNING';
    readonly sampleMetadata?: {
        readonly producer: string;
        readonly creator: string;
        readonly creation_date: string;
        readonly mod_date: null;
        readonly author: string;
        readonly title: null;
        readonly keywords: null;
        readonly subject: null;
        readonly pdf_version: null;
    };
    readonly indicators?: Indicator[];
    readonly documentClass?: {
        readonly id: string;
        readonly type: string;
        readonly document_class_type: string;
    };
}

export interface Indicator {
    readonly indicator_id: string;
    readonly type: string;
    readonly category: string;
    readonly title: string;
    readonly description: string;
    readonly metadata: string | null;
    readonly indicator_attributes: string | null;
    readonly origin: string;
}
