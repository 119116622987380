/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Card, darkThemeClass, Flex, Select, spacing, Text, TextArea } from '@lemonade-hq/blender-ui';
import { useAnalytics } from '@lemonade-hq/boutique';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useGetAttachment } from '../useGetAttachment';
import { archiveButton } from './ArchiveForm.css';
import { useArchive } from 'components/Attachments/ActionHooks/useArchive';
import { useAttachmentsData } from 'components/Attachments/context';
import type { AttachmentDTO } from 'components/Attachments/types';
import { getAttachmentAnalyticsParam } from 'components/Attachments/utils';

const ArchiveFormContent: FC<{ readonly onCloseArchive: () => void; readonly attachment: AttachmentDTO }> = ({
    onCloseArchive,
    attachment,
}) => {
    const { entityPublicId, entityType, invalidateKeys } = useAttachmentsData();
    const { suggestedArchive, archivingReason, publicId } = attachment;
    const {
        reasons,
        reason,
        otherReason,
        isReasonsLoading: isLoading,
        isReasonsError: isError,
        setReason,
        setOtherReason,
        handleSubmit,
        dismissArchive,
    } = useArchive({
        entityPublicId,
        entityType,
        attachments: [attachment],
        onCloseArchive,
        archivingReason,
        invalidateKeys,
    });

    const { trackEvent } = useAnalytics();
    const params = getAttachmentAnalyticsParam({
        attachment,
        entityType,
        entityId: entityPublicId,
    });

    const handleCancelArchive = useCallback(async () => {
        trackEvent('docs.clicked', {
            ...params,
            action: suggestedArchive ? 'click' : 'cancel',
            action_type: suggestedArchive ? 'suggested_archive_dismissed' : 'archive',
            source: 'gallery',
            platform: 'gallery',
            is_bulk: 'false',
        });
        await dismissArchive({ attachmentPublicId: publicId });
        onCloseArchive();
    }, [trackEvent, params, suggestedArchive, dismissArchive, publicId, onCloseArchive]);

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.clicked', {
            ...params,
            action: 'submit',
            action_type: 'archive',
            source: 'gallery',
            is_bulk: 'false',
        });
        await handleSubmit();
    };

    return (
        <Card p={spacing.s16}>
            <Text fontWeight="semi-bold" type="text-lg">
                ּ{suggestedArchive === true ? 'Suggested archive' : 'Archive'}
            </Text>
            <Flex flexDirection="column" gap={spacing.s24} mt={spacing.s12}>
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        {suggestedArchive === true ? 'We think you should archive this:' : 'Archive reason'}
                        {suggestedArchive == null ||
                            (!suggestedArchive && (
                                <Text as="span" color="error">
                                    &nbsp;*
                                </Text>
                            ))}
                    </Text>
                    <Select<never, { value: string; label: string }>
                        disabled={isLoading}
                        onChange={value => setReason(value ?? '')}
                        options={reasons}
                        optionsClassName={darkThemeClass}
                        placeholder="Select archive reason"
                        selectedKey={reason}
                    />
                    {isError && (
                        <Text color="error" type="label-sm">
                            Error loading archive reasons
                        </Text>
                    )}
                </Flex>
                {reason === 'other' && (
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <Text color="primary" type="label-sm">
                            Other reason
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                        </Text>
                        <TextArea
                            autoExpanding
                            maxLength={200}
                            onChange={e => setOtherReason(e.target.value)}
                            placeholder="Add reason"
                            value={otherReason}
                        />
                    </Flex>
                )}
                <Flex gap={spacing.s08}>
                    <Button
                        className={archiveButton}
                        label="Cancel"
                        onClick={handleCancelArchive}
                        size="md"
                        variant="secondary"
                    />
                    <Button
                        className={archiveButton}
                        disabled={reason === '' || (reason === 'other' && otherReason === '')}
                        label="Archive"
                        onClick={onSubmit}
                        size="md"
                        variant="primary"
                    />
                </Flex>
            </Flex>
        </Card>
    );
};

export const ArchiveForm: FC<{ readonly onCloseArchive: () => void }> = ({ onCloseArchive }) => {
    const attachment = useGetAttachment();

    if (attachment == null) return null;

    return <ArchiveFormContent attachment={attachment} onCloseArchive={onCloseArchive} />;
};
