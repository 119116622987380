import { useOptimisticMutation } from '@lemonade-hq/blender-ui';
import { toast } from '@lemonade-hq/bluis';
import type { PendingReason } from '@lemonade-hq/bluiza';
import { TaskStatus } from '@lemonade-hq/bluiza';
import { useCurrentPrincipal, useEntityPageData } from '@lemonade-hq/boutique';
import type {
    UseBaseMutationResult,
    UseMutateAsyncFunction,
    UseMutationResult,
    UseQueryResult,
    UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { castDraft, produce } from 'immer';
import { useNavigate } from 'react-router-dom';
import { useDataAggOptimisticMutation } from '../dataAggClient/useDataAggMutation';
import { createUseDataAggQuery } from '../dataAggClient/useDataAggQuery';
import { SubrogationQueriesQueryKey } from './blender-general/WorkflowsSubrogationQueries';
import { InteractionQueryKeys } from './InteractionsQueries';
import { usePessimisticMutation, usePessimisticMutationLegacy } from './MutationHooks';
import type { UpdateAttachmentDataParams } from 'apis/AttachmentsAPI';
import { updateAttachmentData } from 'apis/AttachmentsAPI';
import type { EditReviewSummaryPayload } from 'apis/ClaimsAPITyped';
import { createRefund, reviewSummary, submitIncidentWitness } from 'apis/ClaimsAPITyped';
import type { SendEmailArgsWithEntityId } from 'apis/EmailsAPI';
import { uploadFile } from 'apis/FilesAPI';
import type {
    AdjusterDetails,
    AdvanceLouPaymentTaskDTO,
    ClaimItemsFilters,
    CoverageDecisionResponse,
    CreateClaimItemPayload,
    CreateDepreciationItemPayload,
    CreateItemAndFeaturePayload,
    CreateLitigationPayload,
    CreateManualClaimDTO,
    CreateRepresentativePayload,
    EditClaimantPayload,
    EditClaimItemPayload,
    EditFeaturePayload,
    EditLiabilityCasePayload,
    EditLouQuestionnairePayload,
    EditValuatedItemPayload,
    HomeClaimDataResponse,
    HomeClaimUpdateParams,
    HomeCreateManualClaimResponse,
    ImportValuatedItemsPayload,
    NotificationsResponse,
    PayeePayload,
    UpdateDeclineDecisionProps,
    UpdateObtainFilesQuestionnaireParams,
    UpdatePoliceReportAttributes,
    UpsertValuatedItemPayload,
    ValuatedItemsFilters,
    ValuatedItemsImportStatusDTO,
    VerificationReportResponse,
    VerifyValuatedItemOwnershipPayload,
} from 'apis/HomeClaimsAPI';
import {
    activateClaimItems,
    assignInteraction,
    cancelFirstResponse,
    createAppraisalsSyncRequest,
    createCustomTask,
    createDepreciationItem,
    createItem,
    createItemAndFeature,
    createLitigation,
    createManualClaim,
    createNote,
    createPayee,
    createPredefinedTask,
    createRepresentative,
    deleteAllClaimLinkEndorsements,
    deleteRepresentative,
    dismissInteraction,
    editClaimant,
    editFeature,
    editItem,
    editLiabilityCase,
    editLouQuestionnaire,
    editRepresentative,
    editValuatedItem,
    fetchClaimStaticData,
    fetchHomeClaimDataV2,
    fetchHomeFeature,
    getAggregatedNotifications,
    getAttachments,
    getAuthorityRequestDetails,
    getAvailableAdjusters,
    getClaimantDetailsTask,
    getClaimItems,
    getCoverageDecision,
    getCustomTask,
    getDeclineDecision,
    getDeclineReasons,
    getDispatchVendorDialogDefaults,
    getDispatchVendorRequestTypes,
    getFirstResponse,
    getInitialReserves,
    getItemCategories,
    getPaymentRequestDetails,
    getPrePaymentInfoV2,
    getValuatedItems,
    getValuatedItemsImportStatus,
    getVerificationReport,
    getWaiveOwnershipReasons,
    importValuatedItems,
    initAdvanceLouPayment,
    manualResponseInteraction,
    markAsDone,
    rejectClaimItems,
    saveClaimAttachments,
    sendEmail,
    sendFirstResponse,
    sendUpdateLink,
    sendWorkflowEmail,
    submitDeclineReason,
    syncWorkflows,
    unmarkAsSuspicious,
    updateClaim,
    updateClaimObtainFilesQuestionnaire,
    updateClaimUserPreferences,
    updateClaimVerificationQuestionnaire,
    updateCoverageDecision,
    updateCustomTaskDescription,
    updateDeclineReason,
    updateLinkEndorsement,
    upsertValuatedItem,
    verifyValuatedItemOwnership,
} from 'apis/HomeClaimsAPI';
import { createLandlord } from 'apis/HomePoliciesAPI';
import { getNoteByClaim } from 'apis/InteractionsAPI';
import { cancelInvoice, payInvoice, simulatePayoutFailure, simulatePayoutSuccess } from 'apis/PayoutsAPI';
import { closeFeatureSubrogation, reopenFeatureSubrogation, updateSubrogationFeature } from 'apis/SubrogationAPI';
import { assign, assignTaskToSquad, dismiss, reopenTask, updateTask } from 'apis/WorkflowsV2API';
import { assignTicketToEntity } from 'apis/ZendeskAPI';
import type { AssignTicketToEntityParams } from 'apis/ZendeskAPI';
import { findTask } from 'bluis/Workflows/utils';
import { homeAttachmentToAttachment } from 'commons/AttachmentsUtils';
import type { UploadAttachmentData } from 'components/Attachments/AttachmentsQueries';
import { GalleryAttachmentQueryKey, useAddAttachments } from 'components/Attachments/AttachmentsQueries';
import { useFindHomeTask } from 'components/Claim/WorkflowsV2/Tasks/common/useFindHomeTask';
import { HomeTaskType } from 'components/Claim/WorkflowsV2/Types';
import type { Attachment, AttachmentToUpload } from 'models/Attachment';
import type { SendEmailRequestDTO } from 'models/Email';
import type {
    AllVerificationParams,
    ClaimantDetailsTask,
    ClaimItemV2,
    ClaimUserPreferencesType,
    ClaimVerificationQuestionnaire,
    ClaimVerificationQuestionnaireType,
    createLandlordParams,
    DeclineDecision,
    DispatchType,
    LinkedEntityType,
    ObtainFilesQuestionnaire,
    RejectClaimItemAttributes,
    UserPreferencesPayload,
    ValuatedItemsTableDTO,
    VendorDispatchPreferencesDTO,
    WitnessDetails,
} from 'models/HomeClaim';
import type { ClaimFeature, HomeFeatureType } from 'models/HomeFeature';
import type { OptionDTO } from 'models/HomeShared';
import type { FirstResponse, NoteClaimType, NoteCreateRequest, NoteType, PaginatedNotes } from 'models/Interaction';
import type { PaymentProviderType, PaymentRequestDetailsResponse } from 'models/Payment';
import type { EntityTypes } from 'models/Products';
import type { FeatureSubrogation } from 'models/Subrogation';

export const useHomeClaimDataAggQuery = createUseDataAggQuery('homeClaim');

export enum HomeClaimQueryKeys {
    GetClaim = 'GET_CLAIM',
    GetClaimNotifications = 'GET_CLAIM_NOTIFICATIONS',
    GetHomeClaimPayments = 'GET_HOME_CLAIM_PAYMENTS',
    GetClxFirstResponse = 'GET_CLX_FIRST_RESPONSE',
    GetHomeDeclineDecision = 'GET_HOME_DECLINE_DECISION',
    GetWorkflow = 'GET_WORKFLOW',
    GetClaimSidebarData = 'GET_CLAIM_SIDEBAR_DATA',
    GetVerificationTypes = 'GET_VERIFICATION_TYPES',
    GetCoverageDecision = 'GET_COVERAGE_DECISION',
    GetNotByClaim = 'GET_NOT_BY_CLAIM',
    GetClaimReserveLog = 'GET_CLAIM_RESERVE_LOG',
    getClaimVideoAttachments = 'GET_CLAIM_VIDEO_ATTACHMENTS',
    getClaimPoliceReport = 'GET_CLAIM_POLICE_REPORT',
    GetClaimLandlord = 'GET_CLAIM_LANDLORD',
    GetClaimWitness = 'GET_CLAIM_WITNESS',
    GetEmailTemplate = 'GET_EMAIL_TEMPLATE',
    GetClaimFeesLog = 'GET_CLAIM_FEES_LOG',
    GetClaimSubrogationLog = 'GET_CLAIM_SUBROGATION_LOG',
    GetCustomTask = 'GET_CUSTOM_TASK',
    GetClaimOverview = 'GET_CLAIM_OVERVIEW',
    GetClaimDefaultEventSummary = 'GET_CLAIM_DEFAULT_EVENT_SUMMARY',
    GetClaimEventSummary = 'GET_CLAIM_EVENT_SUMMARY',
    GetClaimEventSummaryClaimData = 'GET_CLAIM_EVENT_SUMMARY_CLAIM_DATA',
    GetCreatableTaskTypes = 'GET_CREATABLE_TASK_TYPES',
    GetClaimHeaderActions = 'GET_CLAIM_HEADER_ACTIONS',
    GetClaimActions = 'GET_CLAIM_ACTIONS',
    GetAuthorityRequestOptions = 'GET_AUTHORITY_REQUEST_OPTIONS',
    GetPaymentsRequest = 'GET_PAYMENTS_REQUEST',
    GetPaymentRequestDetails = 'GET_PAYMENTS_REQUEST_DETAILS',
    GetClaimFees = 'GET_CLAIM_FEES',
    GetClaimBaseData = 'GET_CLAIM_BASE_DATA',
    GetAvailableAdjusters = 'GET_AVAILABLE_ADJUSTERS',
    GetFeatureCoverageDecision = 'GET_FEATURE_COVERAGE_DECISION',
    GetDeclineReasons = 'GET_DECLINE_REASONS',
    GetManualClaimOptions = 'GET_MANUAL_CLAIM_OPTIONS',
    GetServiceTypeOptions = 'GET_SERVICE_TYPE_OPTIONS',
    GetClaimFeaturesActions = 'GET_CLAIM_FEATURES_ACTIONS',
    GetLinkEndorsements = 'GET_LINK_ENDORSEMENTS',
    GetAttachments = 'GET_ATTACHMENTS',
    GetAttachmentsRelatedData = 'GET_ATTACHMENTS_RELATED_DATA',
    GetClaimUserPreferences = 'GET_CLAIM_USER_PREFERENCES',
    UpdateClaimUserPreferences = 'UPDATE_CLAIM_USER_PREFERENCES',
    GetPossibleRecipients = 'GET_POSSIBLE_RECIPIENTS',
    GetClaimTabs = 'GET_CLAIM_TABS',
    GetClaimUserAndPolicySummary = 'GET_CLAIM_USER_AND_POLICY_SUMMARY',
    GetClaimPolicyVersionCoverages = 'GET_CLAIM_POLICY_VERSION_COVERAGES',
    GetClaimIncidentDetailsTaskLayout = 'GET_CLAIM_INCIDENT_DETAILS_TASK_LAYOUT',
    GetClaimIncidentDetailsUserDetails = 'GET_CLAIM_INCIDENT_DETAILS_USER_DETAILS',
    GetClaimPolicy = 'GET_CLAIM_POLICY',
    GetClaimDamages = 'GET_CLAIM_DAMAGES',
    GetClaimInjuries = 'GET_CLAIM_INJURIES',
    GetClaimIncidentDetailsLossType = 'GET_CLAIM_INCIDENT_DETAILS_LOSS_TYPE',
    GetClaimLocationDetails = 'GET_CLAIM_LOCATION_DETAILS',
    GetClaimUserSelectablePolicies = 'GET_CLAIM_USER_SELECTABLE_POLICIES',
    GetClaimStatusLetter = 'GET_CLAIM_STATUS_LETTER',
    GetClaimObtainFilesTask = 'GET_CLAIM_OBTAIN_FILES_TASK',
    GetPrePaymentInfo = 'GET_PRE_PAYMENT_INFO',
    GetDispatchVendorDialogDefaults = 'GET_DISPATCH_VENDOR_DIALOG_DEFAULTS',
    GetDispatchVendorRequestTypes = 'GET_DISPATCH_VENDOR_REQUEST_TYPES',
    GetClaimItems = 'GET_CLAIM_ITEMS',
    GetItemCategories = 'GET_ITEM_CATEGORIES',
    GetWaiveOwnershipReasons = 'GET_WAIVE_OWNERSHIP_REASONS',
    GetValuatedItems = 'GET_VALUATED_ITEMS',
    GetSubrogation = 'GET_SUBROGATION',
    GetValuatedItemsImportStatus = 'GET_VALUATED_ITEMS_IMPORT_STATUS',
    GetClaimantDetailsTask = 'GET_CLAIMANT_DETAILS_TASK',
    GetInitialReserves = 'GET_INITIAL_RESERVES',
}

export type WorkflowDataType = Pick<
    HomeClaimDataResponse,
    'creatable_task_types' | 'workflow_claim_actions' | 'workflow'
>;

export function useUpdateClaimMutation<TVariables>(
    claimId: string,
    method: (vars: TVariables) => Promise<null | void>
) {
    return usePessimisticMutationLegacy(method, [[HomeClaimQueryKeys.GetClaim, claimId]]);
}

export function useClaimNotifications(claimId: string): UseQueryResult<NotificationsResponse> {
    const key = [HomeClaimQueryKeys.GetClaimNotifications, claimId];

    return useQuery({ queryKey: key, queryFn: async () => await getAggregatedNotifications(claimId) });
}

export function useSendEmailMutation(
    claimId: string
): UseBaseMutationResult<void, unknown, SendEmailArgsWithEntityId, null> {
    return usePessimisticMutationLegacy(sendEmail, [[InteractionQueryKeys.GetCommunicationTimeline, claimId]]);
}

export function useFirstResponse(claimPublicId: string): UseQueryResult<FirstResponse> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClxFirstResponse, claimPublicId],
        queryFn: async () => await getFirstResponse(claimPublicId),
    });
}

export function useCancelFirstResponseMutation(claimPublicId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly claimPublicId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(cancelFirstResponse, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
        [HomeClaimQueryKeys.GetClxFirstResponse, claimPublicId],
    ]);
}

export function useSendFirstResponseMutation(claimPublicId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly claimPublicId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(sendFirstResponse, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
        [HomeClaimQueryKeys.GetClxFirstResponse, claimPublicId],
    ]);
}

export function useDeclineDecision(claimId: string): UseQueryResult<DeclineDecision | null> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetHomeDeclineDecision, claimId],
        queryFn: async () => await getDeclineDecision(claimId),
    });
}

export function useDeclineDecisionMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly claimId: string;
        readonly declined: boolean;
        readonly reason: string;
        readonly description: string;
        readonly block: boolean;
    },
    null
> {
    return usePessimisticMutationLegacy(submitDeclineReason, [[HomeClaimQueryKeys.GetHomeDeclineDecision, claimId]]);
}

export function useUpdateDeclineDecisionMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, UpdateDeclineDecisionProps, DeclineDecision | null | undefined> {
    // return usePessimisticMutationLegacy(updateDeclineReason, [[HomeClaimQueryKeys.GetHomeDeclineDecision, claimId]]);

    const queryKey = [HomeClaimQueryKeys.GetHomeDeclineDecision, claimId];

    function mutateTasks({
        data,
        variables,
    }: {
        data: DeclineDecision | null | undefined;
        variables: UpdateDeclineDecisionProps;
    }) {
        return produce(data, draft => {
            if (draft == null) {
                return draft;
            }

            return {
                ...draft,
                ...variables,
            };
        });
    }

    return useOptimisticMutation({
        mutationFn: updateDeclineReason,
        invalidateKeys: [queryKey],
        mutate: mutateTasks,
        mutateKey: queryKey,
    });
}

export function useAssignInteractionMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly interactionPublicId: string; readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(assignInteraction, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
    ]);
}

export function useAssignTicketMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, AssignTicketToEntityParams, null> {
    return usePessimisticMutationLegacy(assignTicketToEntity, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
    ]);
}

export function useDismissInteractionMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly interactionPublicId: string; readonly claimId: string }, null> {
    return usePessimisticMutationLegacy(dismissInteraction, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
    ]);
}

export function useCreateManualClaimMutation(): UseMutationResult<
    HomeCreateManualClaimResponse,
    unknown,
    CreateManualClaimDTO,
    null
> {
    return usePessimisticMutationLegacy(createManualClaim, [['home-claims-list']]);
}

export function useManualInteractionMutation(
    claimPublicId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly interactionPublicId: string; readonly claimId: string; readonly medium: string; readonly body: string },
    null
> {
    return usePessimisticMutationLegacy(manualResponseInteraction, [
        [InteractionQueryKeys.GetCommunicationTimeline, claimPublicId],
    ]);
}

export function useCreateNoteMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly note: NoteCreateRequest }, null> {
    return usePessimisticMutationLegacy(createNote, [[InteractionQueryKeys.GetCommunicationTimeline, claimPublicId]]);
}

export async function getClaimPayments(
    claimId: string,
    status: string,
    country?: string
): Promise<Pick<HomeClaimDataResponse, 'transactions'>> {
    // Legacy data aggregator call. Moved inside here as should not introduce new usages and this is the only supported key.
    // Added additional inputs to reduce run time (as this anyway needs to be deprecated)
    let url = `/backoffice/home/claims/${claimId}/data?include[]=transactions&status=${status}`;

    if (country) {
        url += `&country=${country}`;
    }

    return await axios
        .get<{ data: Pick<HomeClaimDataResponse, 'transactions'> }>(url)
        .then(response => response.data.data);
}

export function useClaimPayments(
    claimId: string,
    status: string,
    country?: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'transactions'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetHomeClaimPayments, claimId],
        queryFn: async () => await getClaimPayments(claimId, status, country),
    });
}

export function useGetAuthorityRequestDetails(requestPublicId: string): UseQueryResult<PaymentRequestDetailsResponse> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetAuthorityRequestOptions, requestPublicId],
        queryFn: async () => await getAuthorityRequestDetails(requestPublicId),
    });
}

export function useSimulatePayoutSuccessMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly transactionId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(simulatePayoutSuccess, [[HomeClaimQueryKeys.GetHomeClaimPayments, claimId]]);
}

export function useSimulatePayoutFailureMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly transactionId: string;
        readonly paymentMethodType: string;
        readonly errorCode: string;
    },
    null
> {
    return usePessimisticMutationLegacy(simulatePayoutFailure, [[HomeClaimQueryKeys.GetHomeClaimPayments, claimId]]);
}

export function useCreateRefundMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        readonly claim_id: string;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        readonly payee_id: string;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        readonly feature_id: string;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        readonly amount_in_cents: number;
        readonly type: string;
        readonly date: string;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        readonly reference_photo_file_id: string;
    },
    null
> {
    return usePessimisticMutationLegacy(createRefund, [[HomeClaimQueryKeys.GetHomeClaimPayments, claimId]]);
}

export function useSendUpdateLinkMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly invoiceId: string;
        readonly methods: { readonly email: boolean; readonly sms: boolean };
    },
    null
> {
    return usePessimisticMutationLegacy(sendUpdateLink, [[HomeClaimQueryKeys.GetHomeClaimPayments, claimId]]);
}

export function usePayInvoiceMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly invoiceId: string;
        readonly providerType: PaymentProviderType;
    },
    null
> {
    return usePessimisticMutationLegacy(payInvoice, [[HomeClaimQueryKeys.GetHomeClaimPayments, claimId]]);
}

export function useCancelInvoiceMutation(claimId: string): UseBaseMutationResult<
    void,
    unknown,
    {
        readonly invoiceId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(cancelInvoice, [[HomeClaimQueryKeys.GetHomeClaimPayments, claimId]]);
}

export function useMarkTaskDoneMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly claimId: string;
        readonly taskId: string;
        readonly operatorId: string;
        readonly taskType: HomeTaskType | undefined;
    },
    null
> {
    const queryKey = [HomeClaimQueryKeys.GetWorkflow, claimId];

    const cache = useQueryClient();

    async function onSuccess() {
        const res = await getClaimWorkflows(claimId);

        cache.setQueryData<WorkflowDataType>(queryKey, data => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return produce(data!, draftData => {
                draftData.workflow = res.workflow;
            });
        });
    }

    return usePessimisticMutationLegacy(
        markAsDone,
        [
            queryKey,
            [HomeClaimQueryKeys.GetClaimHeaderActions, claimId],
            [HomeClaimQueryKeys.GetClaimActions, claimId],
            [HomeClaimQueryKeys.GetClaimFeaturesActions, claimId],
        ],
        onSuccess
    );
}

export function useDismissTaskMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly taskId: string; readonly reason: string }, WorkflowDataType> {
    const queryKey = [HomeClaimQueryKeys.GetWorkflow, claimId];

    function mutateTasks({ data, variables }: { data: WorkflowDataType; variables: { taskId: string } }) {
        const { taskId } = variables;

        return produce(data, draft => {
            const task = castDraft(findTask(draft.workflow.flat(), task => task.publicId === taskId));

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            task!.task.task.status = TaskStatus.Dismissed;
        });
    }

    return useOptimisticMutation({
        mutationFn: dismiss,
        invalidateKeys: [queryKey],
        mutate: mutateTasks,
        mutateKey: queryKey,
    });
}

export function useReopenTaskMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly taskId: string }, WorkflowDataType> {
    const queryKey = [HomeClaimQueryKeys.GetWorkflow, claimId];

    function mutateTasks({ data, variables }: { data: WorkflowDataType; variables: { taskId: string } }) {
        const { taskId } = variables;

        return produce(data, draft => {
            const task = castDraft(findTask(draft.workflow.flat(), task => task.publicId === taskId));

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            task!.task.task.status = TaskStatus.Open;
        });
    }

    return useOptimisticMutation({
        mutationFn: reopenTask,
        invalidateKeys: [
            queryKey,
            [HomeClaimQueryKeys.GetClaimHeaderActions, claimId],
            [HomeClaimQueryKeys.GetClaimActions, claimId],
            [HomeClaimQueryKeys.GetClaimFeaturesActions, claimId],
        ],
        mutate: mutateTasks,
        mutateKey: queryKey,
    });
}

export function useWorkflowMutation<TVariables>(claimId: string, method: (vars: TVariables) => Promise<null | void>) {
    return usePessimisticMutationLegacy(method, [[HomeClaimQueryKeys.GetWorkflow, claimId]]);
}

async function getClaimWorkflows(claimId: string): Promise<WorkflowDataType> {
    const workflow = await fetchHomeClaimDataV2(claimId, [
        'workflow',
        'creatable_task_types',
        'workflow_claim_actions',
    ]);

    return workflow;
}

export function useClaimWorkflows(claimId: string): UseQueryResult<WorkflowDataType> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetWorkflow, claimId],
        queryFn: async () => await getClaimWorkflows(claimId),
    });
}

async function getClaimSidebarData(
    claimId: string
): Promise<Pick<HomeClaimDataResponse, 'licensed_note' | 'workflow_available'>> {
    return await fetchHomeClaimDataV2(claimId, ['workflow_available', 'licensed_note']);
}

export function useSidebarData(
    claimId: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'licensed_note' | 'workflow_available'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimSidebarData, claimId],
        queryFn: async () => await getClaimSidebarData(claimId),
    });
}

export function useVerificationReport(id: number): UseQueryResult<VerificationReportResponse[]> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetVerificationTypes, id],
        queryFn: async () => await getVerificationReport(id),
    });
}

export function useCoverageDecision(claimId: number): UseQueryResult<CoverageDecisionResponse[]> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetCoverageDecision, claimId],
        queryFn: async () => await getCoverageDecision(claimId),
    });
}

export function useNoteByClaim(
    claimId: string,
    entityType: NoteClaimType,
    noteType: NoteType
): UseQueryResult<PaginatedNotes> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetNotByClaim, claimId, { claimId, entityType }],
        queryFn: async () => await getNoteByClaim({ claimId, entityType, noteType }),
    });
}

export function useAvailableAdjusters(state?: string | null): UseQueryResult<AdjusterDetails[]> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetAvailableAdjusters, state],
        queryFn: async () => await getAvailableAdjusters(state),
    });
}

export type ClaimHeaderResponse = Pick<HomeClaimDataResponse, 'claim_header_actions' | 'claim_settings' | 'claim'>;

async function getClaimHeader(claimId: string): Promise<ClaimHeaderResponse> {
    return await fetchHomeClaimDataV2(claimId, ['claim_header_actions', 'claim', 'claim_settings']);
}

export function useClaimHeader(claimId: string): UseQueryResult<ClaimHeaderResponse> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimHeaderActions, claimId],
        queryFn: async () => await getClaimHeader(claimId),
    });
}

async function getClaimActions(claimId: string): Promise<Pick<HomeClaimDataResponse, 'claim_actions'>> {
    return await fetchHomeClaimDataV2(claimId, ['claim_actions']);
}

export function useClaimActions(
    claimId: string,
    enabled = false
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_actions'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimActions, claimId],
        queryFn: async () => await getClaimActions(claimId),
        enabled,
        staleTime: 100,
    });
}

export function useHomeClaimHeader(claimId: string): UseQueryResult<Pick<HomeClaimDataResponse, 'page_layout'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimTabs, claimId],
        queryFn: async () => await fetchHomeClaimDataV2(claimId, ['page_layout']),
    });
}

async function getClaimUserAndPolicySummary(
    claimId: string
): Promise<Pick<HomeClaimDataResponse, 'claim_policy' | 'claim' | 'user_summary'>> {
    return await fetchHomeClaimDataV2(claimId, ['claim', 'claim_policy', 'user_summary']);
}

export function useClaimUserAndPolicySummary(
    claimId: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_policy' | 'claim' | 'user_summary'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimUserAndPolicySummary, claimId],
        queryFn: async () => await getClaimUserAndPolicySummary(claimId),
    });
}

async function getClaimEventSummary(claimId: string): Promise<Pick<HomeClaimDataResponse, 'claim_event_summary'>> {
    return await fetchHomeClaimDataV2(claimId, ['claim_event_summary']);
}

export function useClaimEventSummary(
    claimId: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_event_summary'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimEventSummary, claimId],
        queryFn: async () => await getClaimEventSummary(claimId),
    });
}

async function getClaimDefaultEventSummary(
    claimId: string
): Promise<Pick<HomeClaimDataResponse, 'claim_default_event_summary'>> {
    return await fetchHomeClaimDataV2(claimId, ['claim_default_event_summary']);
}

export function useClaimDefaultEventSummary(
    claimId: string,
    enabled: boolean
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_default_event_summary'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimDefaultEventSummary, claimId],
        queryFn: async () => await getClaimDefaultEventSummary(claimId),
        enabled,
    });
}

async function getClaimOverview(
    claimId: string
): Promise<
    Pick<
        HomeClaimDataResponse,
        'claim_current_adjuster' | 'claim_items' | 'claim_policy' | 'claim_settings' | 'claim' | 'user_summary'
    >
> {
    return await fetchHomeClaimDataV2(claimId, [
        'claim',
        'claim_items',
        'claim_policy',
        'user_summary',
        'claim_current_adjuster',
        'claim_settings',
    ]);
}

export function useClaimOverview(
    claimId: string
): UseQueryResult<
    Pick<
        HomeClaimDataResponse,
        'claim_current_adjuster' | 'claim_items' | 'claim_policy' | 'claim_settings' | 'claim' | 'user_summary'
    >
> {
    return useQuery({
        queryKey: [[HomeClaimQueryKeys.GetClaimOverview, claimId]],
        queryFn: async () => await getClaimOverview(claimId),
    });
}

async function getClaimPoliceReport(
    claimId: string
): Promise<Pick<HomeClaimDataResponse, 'claim_police_report' | 'claim_policy'>> {
    return await fetchHomeClaimDataV2(claimId, ['claim_police_report', 'claim_policy']);
}

export function useClaimPoliceReport(
    claimId: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_police_report' | 'claim_policy'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.getClaimPoliceReport, claimId],
        queryFn: async () => await getClaimPoliceReport(claimId),
    });
}

async function getClaimVideoAttachments(
    claimId: string
): Promise<Pick<HomeClaimDataResponse, 'claim_video_attachments'>> {
    return await fetchHomeClaimDataV2(claimId, ['claim_video_attachments']);
}

export function useClaimVideoAttachments(
    claimId: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_video_attachments'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.getClaimVideoAttachments, claimId],
        queryFn: async () => await getClaimVideoAttachments(claimId),
    });
}

async function getClaimPolicyVersionCoverages(
    claimId: string
): Promise<
    Pick<
        HomeClaimDataResponse,
        | 'claim_policy_version_coverages'
        | 'claim_policy_version_details'
        | 'claim_policy'
        | 'effective_endorsements'
        | 'effective_scheduled_items'
        | 'mortgagees'
    >
> {
    return await fetchHomeClaimDataV2(claimId, [
        'claim_policy',
        'claim_policy_version_details',
        'claim_policy_version_coverages',
        'effective_scheduled_items',
        'effective_endorsements',
        'mortgagees',
    ]);
}

export function useClaimPolicyVersionCoverages(
    claimId: string
): UseQueryResult<
    Pick<
        HomeClaimDataResponse,
        | 'claim_policy_version_coverages'
        | 'claim_policy_version_details'
        | 'claim_policy'
        | 'effective_endorsements'
        | 'effective_scheduled_items'
        | 'mortgagees'
    >
> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimPolicyVersionCoverages, claimId],
        queryFn: async () => await getClaimPolicyVersionCoverages(claimId),
    });
}

async function getClaimIncidentDetailsTaskLayout(
    claimId: string
): Promise<Pick<HomeClaimDataResponse, 'claim_incident_details_task_layout'>> {
    return await fetchHomeClaimDataV2(claimId, ['claim_incident_details_task_layout']);
}

export function useClaimIncidentDetailsTaskLayout(
    claimId: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_incident_details_task_layout'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimIncidentDetailsTaskLayout, claimId],
        queryFn: async () => await getClaimIncidentDetailsTaskLayout(claimId),
    });
}

async function getClaimIncidentDetailsUserDetails(
    claimId: string
): Promise<
    Pick<
        HomeClaimDataResponse,
        'claim_policy_other_insureds' | 'claim_policy' | 'claim' | 'policy_secondary_insured' | 'user_extended_details'
    >
> {
    return await fetchHomeClaimDataV2(claimId, [
        'claim',
        'claim_policy',
        'user_extended_details',
        'policy_secondary_insured',
        'claim_policy_other_insureds',
    ]);
}

export function useClaimIncidentDetailsUserDetails(
    claimId: string
): UseQueryResult<
    Pick<
        HomeClaimDataResponse,
        'claim_policy_other_insureds' | 'claim_policy' | 'claim' | 'policy_secondary_insured' | 'user_extended_details'
    >
> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimIncidentDetailsUserDetails, claimId],
        queryFn: async () => await getClaimIncidentDetailsUserDetails(claimId),
    });
}

export function useClaimIncidentDetailsLossType(
    claimId: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'catastrophe' | 'claim'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimIncidentDetailsLossType, claimId],
        queryFn: async () => await fetchHomeClaimDataV2(claimId, ['claim', 'catastrophe']),
    });
}

async function getClaimReserveLog(claimId: string): Promise<Pick<HomeClaimDataResponse, 'reserve_log'>> {
    return await fetchHomeClaimDataV2(claimId, ['reserve_log']);
}

export function useReserveLog(claimId: string): UseQueryResult<unknown> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimReserveLog, claimId],
        queryFn: async () => await getClaimReserveLog(claimId),
    });
}

async function getClaimLandlord(claimId: string): Promise<Pick<HomeClaimDataResponse, 'landlord'>> {
    return await fetchHomeClaimDataV2(claimId, ['landlord']);
}

export function useLandlord(claimId: string): UseQueryResult<Pick<HomeClaimDataResponse, 'landlord'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimLandlord, claimId],
        queryFn: async () => await getClaimLandlord(claimId),
    });
}

async function getRecommendedTaskTypes(
    claimId: string
): Promise<Pick<HomeClaimDataResponse, 'recommended_task_types'>> {
    return await fetchHomeClaimDataV2(claimId, ['recommended_task_types']);
}

export function useRecommendedTaskTypes(
    claimId: string,
    enabled?: boolean
): UseQueryResult<Pick<HomeClaimDataResponse, 'recommended_task_types'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimLandlord, claimId],
        queryFn: async () => await getRecommendedTaskTypes(claimId),
        enabled,
    });
}

export function useCreateLandlordMutation(
    claimId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly policyPublicId: string; readonly landlord: createLandlordParams },
    null
> {
    return usePessimisticMutationLegacy(createLandlord, [['landlord', claimId]]);
}

async function getClaimFeesLog(claimId: string): Promise<Pick<HomeClaimDataResponse, 'fees_log'>> {
    return await fetchHomeClaimDataV2(claimId, ['fees_log']);
}

export function useFeesLog(claimId: string): UseQueryResult<Pick<HomeClaimDataResponse, 'fees_log'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimFeesLog, claimId],
        queryFn: async () => await getClaimFeesLog(claimId),
    });
}

async function getSubrogationLog(claimId: string): Promise<Pick<HomeClaimDataResponse, 'subrogation_log'>> {
    return await fetchHomeClaimDataV2(claimId, ['subrogation_log']);
}

export function useSubrogationLog(claimId: string): UseQueryResult<unknown> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimSubrogationLog, claimId],
        queryFn: async () => await getSubrogationLog(claimId),
    });
}

async function getClaimWitness(claimId: string): Promise<Pick<HomeClaimDataResponse, 'witnesses'>> {
    return await fetchHomeClaimDataV2(claimId, ['witnesses']);
}

export function useWitness(claimId: string): UseQueryResult<Pick<HomeClaimDataResponse, 'witnesses'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimWitness, claimId],
        queryFn: async () => await getClaimWitness(claimId),
    });
}

async function getEmailTemplate(
    claimId: string
): Promise<Pick<HomeClaimDataResponse, 'claim_policy' | 'claim' | 'decline_template' | 'user_summary'>> {
    return await fetchHomeClaimDataV2(claimId, ['decline_template', 'claim', 'claim_policy', 'user_summary']);
}

export function useEmailTemplate(
    claimId: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_policy' | 'claim' | 'decline_template' | 'user_summary'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetEmailTemplate, claimId],
        queryFn: async () => await getEmailTemplate(claimId),
    });
}

export function useClaimBaseData(
    claimId: string,
    enabled = true
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_policy' | 'claim' | 'user_summary'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimBaseData, claimId],
        queryFn: async () => await fetchHomeClaimDataV2(claimId, ['claim', 'claim_policy', 'user_summary']),
        enabled,
    });
}

async function getClaimFees(claimId: string): Promise<Pick<HomeClaimDataResponse, 'fees'>> {
    return await fetchHomeClaimDataV2(claimId, ['fees']);
}

export function useClaimFees(claimId: string): UseQueryResult<Pick<HomeClaimDataResponse, 'fees'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimFees, claimId],
        queryFn: async () => await getClaimFees(claimId),
    });
}

export function useWitnessMutation(claimPublicId: string): UseBaseMutationResult<null, unknown, WitnessDetails, null> {
    return usePessimisticMutationLegacy(
        async (details: WitnessDetails) => await submitIncidentWitness({ claimPublicId, details }),
        [['witnesses', claimPublicId]]
    );
}

export function useCustomTask(taskId: string): UseQueryResult<{
    readonly data: [{ readonly publicId: string; readonly description: string }];
}> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetCustomTask, taskId],
        queryFn: async () => await getCustomTask(taskId),
    });
}

export function useCustomTaskMutation(claimId: string): UseBaseMutationResult<
    {
        readonly task: {
            readonly task: {
                readonly publicId: string;
            };
        };
    },
    unknown,
    {
        readonly description: string;
        readonly title: string;
        readonly referenceType: string;
        readonly operatorPublicId: string;
        readonly entityPublicId: string;
        readonly entityType: EntityTypes;
        readonly actionDate: string;
        readonly forcedSquad?: string;
    },
    null
> {
    return usePessimisticMutationLegacy(createCustomTask, [[HomeClaimQueryKeys.GetWorkflow, claimId]]);
}

export function useCustomTaskDescriptionMutation(taskId: string): UseBaseMutationResult<
    void,
    unknown,
    {
        readonly publicId: string;
        readonly description?: string;
    },
    null
> {
    return usePessimisticMutationLegacy(updateCustomTaskDescription, [[HomeClaimQueryKeys.GetCustomTask, taskId]]);
}

export function useAssignTaskMutation(
    claimId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly taskId: string; readonly operatorId: string | null },
    Pick<WorkflowDataType, 'workflow'>
> {
    const queryKey = [HomeClaimQueryKeys.GetWorkflow, claimId];

    function mutateTasks({
        data,
        variables,
    }: {
        data: Pick<WorkflowDataType, 'workflow'>;
        variables: { taskId: string; operatorId: string | null };
    }) {
        const { taskId, operatorId } = variables;

        return produce(data, draft => {
            const task = castDraft(findTask(draft.workflow.flat(), task => task.publicId === taskId));

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            task!.task.task.operatorPublicId = operatorId;
        });
    }

    return useOptimisticMutation({
        mutationFn: assign,
        invalidateKeys: [queryKey],
        mutate: mutateTasks,
        mutateKey: queryKey,
    });
}

export function useAssignTaskToSquadMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly taskPublicId: string;
        readonly squad: string;
    },
    null
> {
    return usePessimisticMutationLegacy(assignTaskToSquad, [[HomeClaimQueryKeys.GetWorkflow, claimId]]);
}

export function useActionDateTaskMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly taskId: string;
        readonly actionDate?: string;
        readonly status?: TaskStatus;
        readonly pendingReason?: PendingReason;
    },
    Pick<WorkflowDataType, 'workflow'>
> {
    const queryKey = [HomeClaimQueryKeys.GetWorkflow, claimId];

    function mutateTasks({
        data,
        variables,
    }: {
        data: Pick<WorkflowDataType, 'workflow'>;
        variables: { taskId: string; actionDate?: string; status?: TaskStatus; pendingReason?: PendingReason };
    }) {
        const { taskId, actionDate, status, pendingReason } = variables;

        return produce(data, draft => {
            const task = castDraft(findTask(draft.workflow.flat(), task => task.publicId === taskId));

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            task!.task.task.actionDate = actionDate;
            task!.task.task.overdue = false;

            if (status != null) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                task!.task.task.status = status;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                task!.task.task.pendingReason = pendingReason;
            }
        });
    }

    return useOptimisticMutation({
        mutationFn: updateTask,
        invalidateKeys: [queryKey],
        mutate: mutateTasks,
        mutateKey: queryKey,
    });
}

async function getPaymentsRequest(claimId: string): Promise<Pick<HomeClaimDataResponse, 'payment_requests'>> {
    return await fetchHomeClaimDataV2(claimId, ['payment_requests', 'claim_policy']);
}

export function usePaymentsRequest(
    claimId: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_policy' | 'payment_requests'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetPaymentsRequest, claimId],
        queryFn: async () => await getPaymentsRequest(claimId),
    });
}

export function useReviewSummaryMutation(
    publicClaimId: string
): UseMutationResult<null, unknown, EditReviewSummaryPayload, Pick<HomeClaimDataResponse, 'claim_event_summary'>> {
    const queryKey = [HomeClaimQueryKeys.GetClaimEventSummary, publicClaimId];

    function mutateTasks({
        data,
        variables,
    }: {
        data: Pick<HomeClaimDataResponse, 'claim_event_summary'>;
        variables: EditReviewSummaryPayload;
    }): Pick<HomeClaimDataResponse, 'claim_event_summary'> {
        const { liabilityReferralRequired, liabilitySummary, summary } = variables;

        return produce(data, draft => {
            if (draft.claim_event_summary != null) {
                draft.claim_event_summary.summary = summary;

                if (liabilityReferralRequired !== undefined) {
                    draft.claim_event_summary.liabilityReferralRequired = liabilityReferralRequired;
                }

                if (liabilitySummary !== undefined) {
                    draft.claim_event_summary.liabilitySummary = liabilitySummary;
                }
            }
        });
    }

    return useOptimisticMutation({
        mutationFn: async payload => await reviewSummary({ publicClaimId, payload }),
        invalidateKeys: [queryKey],
        mutate: mutateTasks,
        mutateKey: queryKey,
    });
}

export function usePaymentRequestDetails(requestPublicID: string) {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetPaymentRequestDetails, requestPublicID],
        queryFn: async () => await getPaymentRequestDetails(requestPublicID),
    });
}

export function usePreDefinedTaskMutation(claimId: string): UseBaseMutationResult<
    void,
    unknown,
    {
        readonly id: string;
        readonly requestedTaskType: string;
        readonly actionDate: string;
        readonly operatorId?: string;
        readonly forcedSquad?: string;
    },
    null
> {
    return usePessimisticMutationLegacy(createPredefinedTask, [[HomeClaimQueryKeys.GetWorkflow, claimId]]);
}

export function useGetDetermineCoverage(featureId: string) {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetFeatureCoverageDecision, featureId],
        queryFn: async () => {
            const response = await fetchHomeFeature(featureId, [
                'coverage_decision',
                'coverage_decision_options',
                'feature',
            ]);

            return response;
        },
    });
}

export function useUpdateCoverageDecision(featureId?: string) {
    return usePessimisticMutationLegacy(
        async ({
            coverageAnalysis,
            coverageDecision,
            coverageReason,
        }: {
            coverageAnalysis?: string | null;
            coverageDecision?: string | null;
            coverageReason?: string | null;
        }) =>
            await updateCoverageDecision({
                featureId,
                coverageAnalysis,
                coverageDecision,
                coverageReason,
            }),
        [[HomeClaimQueryKeys.GetFeatureCoverageDecision, featureId]]
    );
}

export function useGetDeclineReasons(claimPublicId: string) {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetDeclineReasons],
        queryFn: async () => await getDeclineReasons(claimPublicId),
    });
}

export function useGetManualClaimOptions() {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetManualClaimOptions],
        queryFn: async () => {
            const response = await fetchClaimStaticData(['damage_types', 'loss_types']);

            return { damageTypes: response.damage_types, lossTypes: response.loss_types };
        },
    });
}

export function useGetLossTypesOptions() {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetManualClaimOptions],
        queryFn: async () => {
            const response = await fetchClaimStaticData(['loss_types']);

            return { lossTypes: response.loss_types };
        },
    });
}

export function useGetServiceTypeOptions(): UseQueryResult<OptionDTO[], Error> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetServiceTypeOptions],
        queryFn: async () => {
            const response = await fetchClaimStaticData(['service_type_options']);

            return response.service_type_options;
        },
    });
}

async function getLinkedEndorsements(
    claimId: string
): Promise<
    Pick<
        HomeClaimDataResponse,
        | 'claim_items_vendors'
        | 'claim_items'
        | 'claim_policy'
        | 'effective_endorsements'
        | 'effective_scheduled_items'
        | 'linked_endorsements'
    >
> {
    return await fetchHomeClaimDataV2(claimId, [
        'effective_endorsements',
        'effective_scheduled_items',
        'linked_endorsements',
        'claim_policy',
        'claim_items',
        'claim_items_vendors',
    ]);
}

export function useLinkEndorsements(claimId: string) {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetLinkEndorsements, claimId],
        queryFn: async () => await getLinkedEndorsements(claimId),
    });
}

export function useUpdateLinkEndorsementMutation(claimId: string): UseBaseMutationResult<
    void,
    unknown,
    {
        readonly linkedEntityType: LinkedEntityType;
        readonly linkedEntityId: string;
        readonly claimItemPublicIds: string[];
        readonly claimPublicId: string;
    },
    null
> {
    return usePessimisticMutationLegacy(updateLinkEndorsement, [[HomeClaimQueryKeys.GetLinkEndorsements, claimId]]);
}

export function useClaimFeaturesActions(
    claimId: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_features_actions'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimFeaturesActions, claimId],
        queryFn: async () => await fetchHomeClaimDataV2(claimId, ['claim_features_actions']),
    });
}

export function useDeleteAllClaimLinkEndorsementsMutation(
    claimId: string
): UseBaseMutationResult<void, unknown, string, null> {
    return usePessimisticMutationLegacy(deleteAllClaimLinkEndorsements, [
        [HomeClaimQueryKeys.GetLinkEndorsements, claimId],
    ]);
}

export interface VerifyLossDetails {
    readonly lossType: string;
    readonly lossSubType: string;
}

export function useUpdateVerifyLossDetails(
    claimId: string
): UseMutationResult<VerifyLossDetails, unknown, VerifyLossDetails, null> {
    return usePessimisticMutationLegacy(
        async data => await updateClaim(claimId, data),
        [
            [HomeClaimQueryKeys.GetClaim, claimId],
            [HomeClaimQueryKeys.GetWorkflow, claimId],
            [HomeClaimQueryKeys.GetClaimIncidentDetailsLossType, claimId],
            [HomeClaimQueryKeys.GetClaimHeaderActions, claimId],
        ]
    );
}

export interface CatastropheDetails {
    readonly catastropheId: number | null;
}

export function useUpdateCatastropheDetails(
    claimId: string
): UseMutationResult<CatastropheDetails, unknown, CatastropheDetails, null> {
    return usePessimisticMutationLegacy(
        async data => await updateClaim(claimId, data),
        [
            [HomeClaimQueryKeys.GetClaim, claimId],
            [HomeClaimQueryKeys.GetWorkflow, claimId],
            [HomeClaimQueryKeys.GetClaimIncidentDetailsLossType, claimId],
        ]
    );
}

export function useGetAttachments(claimId?: string, enabled?: boolean): UseQueryResult<Attachment[]> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetAttachments, claimId],
        queryFn: async () => await getAttachments(claimId),
        select: data => data.map(item => homeAttachmentToAttachment(item)),
        enabled: enabled ?? true,
    });
}

export function useGetAttachmentsRelatedData(
    claimId: string
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_items' | 'timezone'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetAttachmentsRelatedData, claimId],
        queryFn: async () => await fetchHomeClaimDataV2(claimId, ['claim_items', 'timezone']),
    });
}

export function useGetClaimUserPreferences(claimId: string): UseQueryResult<VendorDispatchPreferencesDTO> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimUserPreferences, { claimId }],
        queryFn: async () => {
            const response = await fetchHomeClaimDataV2(claimId, ['claim_user_preferences']);

            return response.claim_user_preferences;
        },
    });
}

export function useUpdateClaimUserPreferences(claimId?: string): UseMutationResult<
    void,
    unknown,
    {
        readonly preferences: UserPreferencesPayload;
        readonly type: ClaimUserPreferencesType;
    },
    null
> {
    return usePessimisticMutationLegacy(
        async ({ preferences, type }: { preferences: UserPreferencesPayload; type: ClaimUserPreferencesType }) => {
            await updateClaimUserPreferences({
                claimId,
                preferences,
                type,
            });
        },
        [[HomeClaimQueryKeys.GetClaimUserPreferences, { claimId }]]
    );
}

export function useSendWorkflowEmail({
    claimPublicId,
    taskPublicId,
}: {
    readonly claimPublicId?: string;
    readonly taskPublicId?: string;
}) {
    return useMutation({
        mutationFn: async (email: SendEmailRequestDTO) => {
            await sendWorkflowEmail({ claimPublicId, email, taskPublicId });
        },
    });
}

export function useGetPossibleRecipients(
    claimId: string,
    queryOptions?: { readonly enabled?: boolean }
): UseQueryResult<Pick<HomeClaimDataResponse, 'possible_recipients'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetPossibleRecipients, claimId],
        queryFn: async () => {
            return await fetchHomeClaimDataV2(claimId, ['possible_recipients']);
        },
        ...queryOptions,
    });
}

export function useGetClaimPolicy(
    claimId: string,
    queryOptions?: { readonly enabled?: boolean }
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_policy'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimPolicy, claimId],
        queryFn: async () => await fetchHomeClaimDataV2(claimId, ['claim_policy']),
        ...queryOptions,
    });
}

export function useGetClaimDamages(claimId: string, queryOptions?: { readonly enabled?: boolean }) {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimDamages, claimId],
        queryFn: async () => await fetchHomeClaimDataV2(claimId, ['claim_incident_damages_details']),
        ...queryOptions,
    });
}

export function useGetClaimInjuries(claimId: string, queryOptions?: { readonly enabled?: boolean }) {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimInjuries, claimId],
        queryFn: async () => await fetchHomeClaimDataV2(claimId, ['claim_incident_injury_details']),
        ...queryOptions,
    });
}

export function useCreateItemAndFeature(
    claimPublicId?: string
): UseMutationResult<
    { readonly featurePublicId: string; readonly itemPublicId: string },
    unknown,
    CreateItemAndFeaturePayload,
    null
> {
    return usePessimisticMutationLegacy(
        async (payload: CreateItemAndFeaturePayload) => await createItemAndFeature(claimPublicId, payload),
        [
            ['claim_initial_reserves', claimPublicId],
            [HomeClaimQueryKeys.GetInitialReserves, claimPublicId],
            ['claim_items', claimPublicId],
            [HomeClaimQueryKeys.GetClaimItems, claimPublicId],
            [HomeClaimQueryKeys.GetAttachmentsRelatedData, claimPublicId],
        ]
    );
}

export function useCreateItem(claimPublicId?: string): UseMutationResult<
    {
        readonly item: ClaimItemV2;
    },
    unknown,
    CreateClaimItemPayload,
    null
> {
    return usePessimisticMutationLegacy(
        async payload => await createItem(claimPublicId, payload),
        [
            ['claim_initial_reserves', claimPublicId],
            [HomeClaimQueryKeys.GetInitialReserves, claimPublicId],
            ['claim_items', claimPublicId],
            [HomeClaimQueryKeys.GetClaimItems, claimPublicId],
            [HomeClaimQueryKeys.GetAttachmentsRelatedData, claimPublicId],
            [HomeClaimQueryKeys.GetClaimActions, claimPublicId],
        ]
    );
}

export function useEditItem(
    claimPublicId?: string,
    publicId?: string
): UseMutationResult<
    {
        readonly feature: ClaimFeature;
    },
    unknown,
    Partial<EditClaimItemPayload>,
    null
> {
    return usePessimisticMutationLegacy(
        async payload => await editItem(claimPublicId, payload, publicId),
        [
            ['payable_valuated_items', claimPublicId],
            ['claim_initial_reserves', claimPublicId],
            [HomeClaimQueryKeys.GetInitialReserves, claimPublicId],
            ['claim_items', claimPublicId],
            [HomeClaimQueryKeys.GetClaimItems, claimPublicId],
            [HomeClaimQueryKeys.GetValuatedItems, claimPublicId],
            [HomeClaimQueryKeys.GetAttachmentsRelatedData, claimPublicId],
        ]
    );
}

export function useUpsertValuatedItem(
    claimPublicId?: string
): UseMutationResult<void, unknown, UpsertValuatedItemPayload, null> {
    return usePessimisticMutationLegacy(
        async payload =>
            await upsertValuatedItem({
                claimPublicId,
                payload,
            }),
        [
            ['payable_valuated_items', claimPublicId],
            [HomeClaimQueryKeys.GetValuatedItems, claimPublicId],
            [HomeClaimQueryKeys.GetAttachmentsRelatedData, claimPublicId],
            [HomeClaimQueryKeys.GetClaimActions, claimPublicId],
        ]
    );
}

export function useCreateDepreciationItem({
    claimPublicId,
}: {
    readonly claimPublicId?: string;
}): UseMutationResult<void, unknown, CreateDepreciationItemPayload, null> {
    return usePessimisticMutationLegacy(
        async payload =>
            await createDepreciationItem({
                claimPublicId,
                payload,
            }),
        [
            ['payable_valuated_items', claimPublicId],
            [HomeClaimQueryKeys.GetValuatedItems, claimPublicId],
            [HomeClaimQueryKeys.GetAttachmentsRelatedData, claimPublicId],
        ]
    );
}

export function useEditValuatedItem({
    appraisalPublicId,
    claimPublicId,
}: {
    readonly appraisalPublicId?: string | null;
    readonly claimPublicId?: string;
}): UseMutationResult<void, unknown, EditValuatedItemPayload, null> {
    return usePessimisticMutationLegacy(
        async payload => await editValuatedItem({ appraisalPublicId, claimPublicId, payload }),
        [
            ['payable_valuated_items', claimPublicId],
            [HomeClaimQueryKeys.GetValuatedItems, claimPublicId],
            [HomeClaimQueryKeys.GetAttachmentsRelatedData, claimPublicId],
        ]
    );
}

export function useEditFeature(
    claimPublicId?: string,
    publicId?: string
): UseMutationResult<void, unknown, EditFeaturePayload, null> {
    return usePessimisticMutationLegacy(
        async payload => await editFeature({ payload, publicId }),
        [
            ['claim_initial_reserves', claimPublicId],
            [HomeClaimQueryKeys.GetWorkflow, claimPublicId],
            [HomeClaimQueryKeys.GetInitialReserves, claimPublicId],
            [HomeClaimQueryKeys.GetClaimItems, claimPublicId],
            [HomeClaimQueryKeys.GetAttachmentsRelatedData, claimPublicId],
            [HomeClaimQueryKeys.GetClaimantDetailsTask, claimPublicId],
        ]
    );
}

export function useCreateAppraisalsSyncRequest({
    claimPublicId,
}: {
    readonly claimPublicId?: string;
}): UseMutationResult<void, Error, void, unknown> {
    return useMutation({
        mutationFn: async () => await createAppraisalsSyncRequest({ claimPublicId }),
    });
}

export function useEditClaimant({
    claimPublicId,
    featurePublicId,
}: {
    readonly claimPublicId?: string;
    readonly featurePublicId?: string;
}): UseMutationResult<void, unknown, EditClaimantPayload, null> {
    return usePessimisticMutation({
        invalidateKeys: [
            ['claim_initial_reserves', claimPublicId],
            [HomeClaimQueryKeys.GetInitialReserves, claimPublicId],
            [HomeClaimQueryKeys.GetClaimItems, claimPublicId],
            [HomeClaimQueryKeys.GetAttachmentsRelatedData, claimPublicId],
            [HomeClaimQueryKeys.GetClaimantDetailsTask, claimPublicId],
        ],
        mutationFn: async payload => await editClaimant({ featurePublicId, payload }),
    });
}

export function useGetValuatedItems(
    claimPublicId: string,
    filters?: ValuatedItemsFilters
): UseSuspenseQueryResult<ValuatedItemsTableDTO, Error> {
    return useSuspenseQuery({
        queryKey: [HomeClaimQueryKeys.GetValuatedItems, claimPublicId, filters],
        queryFn: async () => await getValuatedItems(claimPublicId, filters),
    });
}

export function useImportValuatedItems(
    claimPublicId?: string
): UseMutationResult<ValuatedItemsImportStatusDTO, unknown, ImportValuatedItemsPayload, unknown> {
    return useMutation({ mutationFn: async payload => await importValuatedItems({ claimPublicId, payload }) });
}

export function useGetValuatedItemsImportStatus(
    claimPublicId?: string
): UseMutationResult<ValuatedItemsImportStatusDTO, unknown, { readonly importTrackerPublicId?: string }, unknown> {
    return useMutation({
        mutationFn: async ({ importTrackerPublicId }: { importTrackerPublicId?: string }) =>
            await getValuatedItemsImportStatus({ claimPublicId, importTrackerPublicId }),
    });
}

export function useGetClaimLocationDetails(
    claimId: string,
    queryOptions?: { readonly enabled?: boolean }
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_location_details' | 'claim'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimLocationDetails, claimId],
        queryFn: async () => await fetchHomeClaimDataV2(claimId, ['claim_location_details', 'claim']),
        ...queryOptions,
    });
}

export const useUpdateClaimWhenAndWhere = (claimId: string) => {
    return usePessimisticMutationLegacy(
        async (data: HomeClaimUpdateParams) => await updateClaim(claimId, data),
        [
            [HomeClaimQueryKeys.GetClaimLocationDetails, claimId],
            [HomeClaimQueryKeys.GetClaimUserAndPolicySummary, claimId],
            [HomeClaimQueryKeys.GetClaimIncidentDetailsTaskLayout, claimId],
        ]
    );
};

export function useClaimUserSelectablePolicies(
    claimId: string,
    onSuccess: (data: Pick<HomeClaimDataResponse, 'claim_policy' | 'claim_selectable_user_policies'>) => void
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_policy' | 'claim_selectable_user_policies'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimUserSelectablePolicies, claimId],
        queryFn: async () => {
            const response = await fetchHomeClaimDataV2(claimId, ['claim_policy', 'claim_selectable_user_policies']);

            onSuccess(response);
            return response;
        },
    });
}

async function uploadClaimReport({
    addAttachments,
    files,
    type,
}: {
    readonly addAttachments: UseMutateAsyncFunction<
        AxiosResponse<unknown, unknown>,
        unknown,
        UploadAttachmentData[],
        null
    >;
    readonly files: FileList | null;
    readonly type: 'fire_report' | 'police_report';
}) {
    if (!files) {
        return;
    }

    const uploadResults = await Promise.all(
        Array.from(files).map(async file => await uploadFile(file, 'blender/claims/home/attachments'))
    );

    await addAttachments(
        uploadResults.map(({ publicId }, index) => ({
            filePublicId: publicId,
            type,
            contentType: files.item(index)?.type ?? '',
            description: '',
        }))
    );
}

export function useUploadClaimPoliceReport(claimId: string): UseMutationResult<void, unknown, FileList | null, null> {
    const { entityType = '', publicId: entityPublicId = '' } = useEntityPageData() ?? {};
    const { mutateAsync: addAttachments } = useAddAttachments({
        entityPublicId: entityPublicId ?? '',
        entityType: entityType as EntityTypes,
    });

    return usePessimisticMutation({
        mutationFn: async (files: FileList | null) =>
            await uploadClaimReport({ addAttachments, files, type: 'police_report' }),
        invalidateKeys: [
            [HomeClaimQueryKeys.getClaimPoliceReport, claimId],
            [HomeClaimQueryKeys.GetAttachments, claimId],
            [HomeClaimQueryKeys.GetClaimIncidentDetailsTaskLayout, claimId],
            ['claim_police_report', claimId],
            [HomeClaimQueryKeys.GetAttachments, claimId],
        ],
    });
}

export function useUploadClaimFireReport(claimId: string): UseMutationResult<void, unknown, FileList | null, null> {
    const { entityType = '', publicId: entityPublicId = '' } = useEntityPageData() ?? {};
    const { mutateAsync: addAttachments } = useAddAttachments({
        entityPublicId: entityPublicId ?? '',
        entityType: entityType as EntityTypes,
    });

    return usePessimisticMutation({
        mutationFn: async (files: FileList | null) =>
            await uploadClaimReport({ addAttachments, files, type: 'fire_report' }),
        invalidateKeys: [
            [HomeClaimQueryKeys.getClaimPoliceReport, claimId],
            [HomeClaimQueryKeys.GetAttachments, claimId],
        ],
    });
}

async function uploadClaimAttachments(entityId: string, toUpload: AttachmentToUpload[]) {
    if (!toUpload.length) return;

    const uploadResults = await Promise.all(
        Array.from(toUpload).map(
            async attachment => await uploadFile(attachment.file, 'blender/claims/home/attachments')
        )
    );

    await saveClaimAttachments(
        entityId,
        uploadResults.map(({ publicId }, index) => ({
            filePublicId: publicId,
            type: toUpload[index].type,
            contentType: toUpload[index].file.type,
            description: toUpload[index].description ?? '',
            fileName: toUpload[index].filename,
        }))
    );
}

export function useUploadClaimAttachments(claimId: string) {
    return usePessimisticMutationLegacy(
        async (toUpload: AttachmentToUpload[]) => await uploadClaimAttachments(claimId, toUpload),
        [[HomeClaimQueryKeys.GetAttachments, claimId]]
    );
}

export function useUpdateAttachment(claimId: string) {
    return usePessimisticMutationLegacy(
        async ({ attachmentId, details }: { attachmentId: string; details: UpdateAttachmentDataParams }) =>
            await updateAttachmentData(attachmentId, details),
        [
            [HomeClaimQueryKeys.GetAttachments, claimId],
            [HomeClaimQueryKeys.GetClaimItems, claimId],
            [HomeClaimQueryKeys.GetValuatedItems, claimId],
        ]
    );
}

export function useUpdateAttachmentV2(claimId: string) {
    return usePessimisticMutation({
        mutationFn: async ({ attachmentId, details }: { attachmentId: string; details: UpdateAttachmentDataParams }) =>
            await updateAttachmentData(attachmentId, details),
        invalidateKeys: [
            [GalleryAttachmentQueryKey.GetAttachments, claimId],
            [HomeClaimQueryKeys.GetClaimItems, claimId],
            [HomeClaimQueryKeys.GetValuatedItems, claimId],
        ],
    });
}

export function useUpdatePoliceReportDetails(claimId: string) {
    return usePessimisticMutationLegacy(
        async (data: UpdatePoliceReportAttributes) => await updateClaim(claimId, { policeReportData: data }),
        [
            [HomeClaimQueryKeys.getClaimPoliceReport, claimId],
            [HomeClaimQueryKeys.GetClaimHeaderActions, claimId],
            [HomeClaimQueryKeys.GetClaimIncidentDetailsTaskLayout, claimId],
            ['claim_police_report', claimId],
        ]
    );
}

export function useClaimStatusLetter(
    claimId: string,
    enabled = true
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_policy' | 'claim_status_update' | 'claim' | 'user_summary'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimStatusLetter, claimId],
        queryFn: async () =>
            await fetchHomeClaimDataV2(claimId, ['claim', 'claim_policy', 'user_summary', 'claim_status_update']),
        enabled,
    });
}

export function useGetClaimCurrentAdjuster(
    claimId: string,
    queryOptions?: { readonly enabled?: boolean }
): UseQueryResult<Pick<HomeClaimDataResponse, 'claim_current_adjuster'>> {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimLocationDetails, claimId],
        queryFn: async () => await fetchHomeClaimDataV2(claimId, ['claim_current_adjuster']),
        ...queryOptions,
    });
}

export function useUpdateClaimObtainMissingInfo(claimId: string) {
    function mutate(
        data: ObtainFilesQuestionnaire,
        variables: UpdateObtainFilesQuestionnaireParams
    ): ObtainFilesQuestionnaire {
        return produce(data, draftData => {
            if (draftData.obtainDwellingDamages != null) {
                draftData.obtainDwellingDamages = {
                    ...draftData.obtainDwellingDamages,
                    ...variables.dwellingDamages,
                };
            }

            if (draftData.obtainPoliceReport != null) {
                draftData.obtainPoliceReport = {
                    ...draftData.obtainPoliceReport,
                    ...variables.policeReport,
                };
            }

            if (draftData.obtainFireReport != null) {
                draftData.obtainFireReport = {
                    ...draftData.obtainFireReport,
                    ...variables.fireReport,
                };
            }

            if (draftData.obtainLandlordDetails != null) {
                draftData.obtainLandlordDetails = {
                    ...draftData.obtainLandlordDetails,
                    ...variables.landlord,
                };
            }

            return draftData;
        });
    }

    return useDataAggOptimisticMutation<
        HomeClaimDataResponse,
        'cause_and_origin_approval_task' | 'claim_obtain_missing_info_task',
        UpdateObtainFilesQuestionnaireParams
    >({
        entityType: 'homeClaim',
        entityId: claimId,
        mFunction: async (params: UpdateObtainFilesQuestionnaireParams) =>
            await updateClaimObtainFilesQuestionnaire(claimId, params),
        invalidateSerializers: ['claim_obtain_missing_info_task', 'cause_and_origin_approval_task'],
        mutate,
    });
}

export function useEditLouQuestionnaire(claimPublicId: string) {
    function mutate(data: AdvanceLouPaymentTaskDTO, variables: EditLouQuestionnairePayload): AdvanceLouPaymentTaskDTO {
        return produce(data, draftData => {
            if (draftData.claimLossOfUse.questionnaire == null) {
                draftData.claimLossOfUse.questionnaire = {
                    affectedFamilyMembersCount: variables.affectedFamilyMembersCount ?? null,
                    causeOfLossCovered: variables.causeOfLossCovered ?? null,
                    immediateNeedForHousing: variables.immediateNeedForHousing ?? null,
                    lossOfUseCovered: variables.lossOfUseCovered ?? null,
                    paymentReason: variables.paymentReason ?? null,
                    policyHolderOwnsPets: variables.policyHolderOwnsPets ?? null,
                };
            } else {
                draftData.claimLossOfUse.questionnaire = {
                    ...draftData.claimLossOfUse.questionnaire,
                    ...variables,
                };
            }

            return draftData;
        });
    }

    return useDataAggOptimisticMutation<
        HomeClaimDataResponse,
        'advance_loss_of_use_payment_task',
        EditLouQuestionnairePayload
    >({
        entityType: 'homeClaim',
        entityId: claimPublicId,
        mFunction: async (payload: EditLouQuestionnairePayload) =>
            await editLouQuestionnaire({ claimPublicId, payload }),
        invalidateSerializers: ['advance_loss_of_use_payment_task'],
        mutate,
    });
}

export function useUpdateClaimVerificationQuestionnaire<TType extends ClaimVerificationQuestionnaireType>(
    claimId: string,
    verificationType: TType
) {
    function mutate(
        data: ClaimVerificationQuestionnaire[],
        variables: Partial<AllVerificationParams>
    ): ClaimVerificationQuestionnaire[] {
        const verificationIndex = data.findIndex(report => report.verificationType === verificationType);

        const newVerification = {
            verificationType,
            verifications: variables,
        } as ClaimVerificationQuestionnaire;

        const newData = [...data];

        if (verificationIndex === -1) {
            newData.push(newVerification);
        } else {
            newData.splice(verificationIndex, 1, newVerification);
        }

        return newData;
    }

    return useDataAggOptimisticMutation<
        HomeClaimDataResponse,
        'claim_verification_reports',
        Partial<AllVerificationParams>
    >({
        entityType: 'homeClaim',
        entityId: claimId,
        mFunction: async (params: Partial<AllVerificationParams>) =>
            await updateClaimVerificationQuestionnaire(claimId, verificationType, params),
        invalidateSerializers: ['claim_verification_reports'],
        mutate,
    });
}

export function useGetPrePaymentInfo({
    claimPublicId,
    itemPublicIds,
}: {
    readonly claimPublicId: string;
    readonly itemPublicIds: string[];
}) {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetPrePaymentInfo, claimPublicId, itemPublicIds],
        queryFn: async () => await getPrePaymentInfoV2({ claimPublicId, itemPublicIds }),
        enabled: Boolean(itemPublicIds.length),
    });
}

export function useSyncWorkflows({
    claimPublicId,
    requiredTypes,
}: {
    readonly claimPublicId: string;
    readonly requiredTypes?: string[];
}) {
    const { operator } = useCurrentPrincipal();

    return useMutation({
        mutationFn: async () =>
            await syncWorkflows({
                id: claimPublicId,
                operatorId: operator?.id ?? '',
                requiredTypes,
            }),
    });
}

export function useCreateAdvancePaymentTask(claimPublicId: string) {
    const { findTask } = useFindHomeTask({ type: HomeTaskType.AdvanceLouPayment });

    const { mutateAsync: syncWorkflows } = useSyncWorkflows({
        claimPublicId,
        requiredTypes: ['advance_loss_of_use_payment'],
    });

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation({
        mutationFn: syncWorkflows,
        // On success, invalidate the workflows and navigate to the newly added task
        onSuccess: async () => {
            queryClient.invalidateQueries({ queryKey: ['claim_initial_reserves', claimPublicId] });
            await queryClient.invalidateQueries({ queryKey: [HomeClaimQueryKeys.GetWorkflow, claimPublicId] });

            const advanceLouTask = findTask();

            navigate(`#${advanceLouTask?.publicId}`);
        },
    });
}

export function useInitAdvanceLouPayment(claimPublicId: string) {
    return useMutation({
        mutationFn: async ({ valueInCents }: { valueInCents: number }) =>
            await initAdvanceLouPayment({ claimPublicId, valueInCents }),
    });
}

export function useGetDispatchVendorDialogDefaults(
    claimPublicId: string,
    dispatchType?: DispatchType,
    enabled?: boolean
) {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetDispatchVendorDialogDefaults, claimPublicId, dispatchType],
        queryFn: async () => await getDispatchVendorDialogDefaults(claimPublicId, dispatchType),
        enabled,
    });
}

export function useGetDispatchVendorRequestTypes() {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetDispatchVendorRequestTypes],
        queryFn: async () => await getDispatchVendorRequestTypes(),
    });
}

export function useRejectClaimItems(claimPublicId: string) {
    return usePessimisticMutationLegacy(
        async (payload: RejectClaimItemAttributes) => await rejectClaimItems(claimPublicId, payload),
        [
            [HomeClaimQueryKeys.GetClaimItems, claimPublicId],
            [HomeClaimQueryKeys.GetValuatedItems, claimPublicId],
            ['payable_valuated_items', claimPublicId],
        ],
        undefined,
        () => {
            toast.error('Failed to reject item');
        }
    );
}

export function useVerifyValuatedItemOwnership({
    appraisalPublicId,
    claimPublicId,
}: {
    readonly appraisalPublicId?: string | null;
    readonly claimPublicId?: string;
}): UseMutationResult<void, unknown, VerifyValuatedItemOwnershipPayload, null> {
    return usePessimisticMutationLegacy(
        async payload => await verifyValuatedItemOwnership({ appraisalPublicId, claimPublicId, payload }),
        [
            [HomeClaimQueryKeys.GetClaimItems, claimPublicId],
            [HomeClaimQueryKeys.GetValuatedItems, claimPublicId],
            ['payable_valuated_items', claimPublicId],
        ]
    );
}

export function useGetClaimItems(claimPublicId: string, filters?: ClaimItemsFilters) {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimItems, claimPublicId, filters],
        queryFn: async () => await getClaimItems(claimPublicId, filters),
    });
}

export function useActivateClaimItems(claimPublicId: string) {
    return usePessimisticMutationLegacy(
        async ({ itemPublicId }: { itemPublicId: string[] }) => await activateClaimItems(claimPublicId, itemPublicId),
        [
            [HomeClaimQueryKeys.GetClaimItems, claimPublicId],
            [HomeClaimQueryKeys.GetValuatedItems, claimPublicId],
            ['payable_valuated_items', claimPublicId],
        ],
        undefined,
        () => {
            toast.error('Failed to activate items');
        }
    );
}

export function useGetItemCategories(options?: { readonly enabled?: boolean }) {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetItemCategories],
        queryFn: getItemCategories,
        enabled: options?.enabled,
    });
}

export function useGetWaiveOwnershipReasons(claimPublicId: string) {
    return useQuery({
        queryKey: [HomeClaimQueryKeys.GetWaiveOwnershipReasons, claimPublicId],
        queryFn: async () => await getWaiveOwnershipReasons(claimPublicId),
    });
}

export function useCreateLitigation({
    claimPublicId,
}: {
    readonly claimPublicId?: string;
}): UseMutationResult<void, unknown, CreateLitigationPayload, unknown> {
    return usePessimisticMutationLegacy(
        async payload =>
            await createLitigation({
                claimPublicId,
                payload,
            }),
        [
            ['litigation', claimPublicId],
            [HomeClaimQueryKeys.GetClaimantDetailsTask, claimPublicId],
        ]
    );
}

export function useCreateRepresentative({
    claimPublicId,
}: {
    readonly claimPublicId?: string;
}): UseMutationResult<void, unknown, CreateRepresentativePayload, unknown> {
    return usePessimisticMutationLegacy(
        async payload =>
            await createRepresentative({
                claimPublicId,
                payload,
            }),
        [
            ['representatives', claimPublicId],
            [HomeClaimQueryKeys.GetClaimantDetailsTask, claimPublicId],
        ]
    );
}

export function useEditRepresentative({
    claimPublicId,
    representativeId,
}: {
    readonly claimPublicId?: string;
    readonly representativeId?: string;
}): UseMutationResult<void, unknown, CreateRepresentativePayload, unknown> {
    return usePessimisticMutationLegacy(
        async payload =>
            await editRepresentative({
                claimPublicId,
                payload,
                representativeId,
            }),
        [
            ['representatives', claimPublicId],
            [HomeClaimQueryKeys.GetClaimantDetailsTask, claimPublicId],
        ]
    );
}

export function useDeleteRepresentative({
    claimPublicId,
    representativeId,
}: {
    readonly claimPublicId?: string;
    readonly representativeId?: string;
}): UseMutationResult<void, unknown, void, unknown> {
    return usePessimisticMutationLegacy(
        async () =>
            await deleteRepresentative({
                claimPublicId,
                representativeId,
            }),
        [
            ['representatives', claimPublicId],
            [HomeClaimQueryKeys.GetClaimantDetailsTask, claimPublicId],
        ]
    );
}

export function useCloseFeatureSubrogationMutation(
    claimPublicId: string
): UseBaseMutationResult<
    void,
    unknown,
    { readonly featureSubrogationId: string; readonly closeReason?: string },
    null
> {
    return usePessimisticMutationLegacy(closeFeatureSubrogation, [[HomeClaimQueryKeys.GetSubrogation, claimPublicId]]);
}

export function useReopenFeatureSubrogationMutation(
    claimPublicId: string
): UseBaseMutationResult<void, unknown, { readonly featureSubrogationId: string }, null> {
    return usePessimisticMutationLegacy(reopenFeatureSubrogation, [[HomeClaimQueryKeys.GetSubrogation, claimPublicId]]);
}

export function useUpdateSubrogationFeatureMutation(
    claimPublicId: string,
    entityPublicId?: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly featureSubrogationId: string; readonly featureSubrogation: Partial<FeatureSubrogation> },
    null
> {
    return usePessimisticMutationLegacy(updateSubrogationFeature, [
        [HomeClaimQueryKeys.GetWorkflow, claimPublicId],
        [HomeClaimQueryKeys.GetSubrogation, claimPublicId],
        [SubrogationQueriesQueryKey.GetFeatureSubrogationManage, entityPublicId],
    ]);
}

export function useGetClaimantDetailsTask({
    claimPublicId,
    featurePublicId,
}: {
    readonly claimPublicId?: string;
    readonly featurePublicId?: string;
}): UseSuspenseQueryResult<ClaimantDetailsTask> {
    return useSuspenseQuery({
        queryKey: [HomeClaimQueryKeys.GetClaimantDetailsTask, claimPublicId, featurePublicId],
        queryFn: async () => await getClaimantDetailsTask({ claimPublicId, featurePublicId }),
    });
}

export function useEditLiabilityCase({
    claimPublicId,
    liabilityCasePublicId,
}: {
    readonly claimPublicId?: string;
    readonly liabilityCasePublicId?: string;
}): UseMutationResult<void, unknown, EditLiabilityCasePayload, null> {
    return usePessimisticMutation({
        invalidateKeys: [[HomeClaimQueryKeys.GetClaimantDetailsTask, claimPublicId]],
        mutationFn: async payload => await editLiabilityCase({ claimPublicId, liabilityCasePublicId, payload }),
    });
}

export function useRemoveSuspiciousFlag(claimPublicId: string): UseMutationResult<unknown, Error, void, unknown> {
    return useMutation({
        mutationFn: async () => await unmarkAsSuspicious(claimPublicId),
    });
}

export function useGetInitialReserves({
    claimPublicId,
    featureType,
}: {
    readonly claimPublicId?: string;
    readonly featureType?: HomeFeatureType;
}): UseSuspenseQueryResult<ClaimantDetailsTask> {
    return useSuspenseQuery({
        queryKey: [HomeClaimQueryKeys.GetInitialReserves, claimPublicId, featureType],
        queryFn: async () => await getInitialReserves({ claimPublicId, featureType }),
    });
}

export function useCreatePayee({
    claimPublicId,
}: {
    readonly claimPublicId?: string;
}): UseMutationResult<void, unknown, PayeePayload, null> {
    return usePessimisticMutation({
        mutationFn: async payload =>
            await createPayee({
                claimPublicId,
                payload,
            }),
        invalidateKeys: [['claim_payees', claimPublicId]],
    });
}
