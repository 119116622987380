import { Flex } from '../../base/Flex/Flex';
import { Grid } from '../../base/Grid/Grid';
import { Layout } from '../../base/Layout/Layout';
import { Text } from '../../base/Text/Text';
import { spacing } from '../../theme/spacing.css';
import { SectionHeader } from '../SectionHeader/SectionHeader';

export interface LabelValueItem {
  readonly label: string;
  readonly value: JSX.Element | number | string | null;
  readonly type?: 'email' | 'phonenumber';
  readonly link?: { readonly url: string; readonly blank?: boolean };
  readonly warning?: boolean;
}

export type LabelValueTableProps = {
  readonly title?: string;
  readonly subtitle?: string;
  readonly data: LabelValueItem[];
  readonly columnCount?: 1 | 2;
  readonly labelWidth?: string;
  readonly className?: string;
};

const Value: React.FC<LabelValueItem> = ({ value, link, type = 'default', warning }) => {
  if (value == null) {
    return <>-</>;
  }

  if (!(typeof value === 'number' || typeof value === 'string')) {
    return value;
  }

  if (warning != null) {
    return (
      <Text color="error" type="text-md">
        {value}
      </Text>
    );
  }

  if (link != null) {
    return (
      <a href={link.url} rel="noreferrer" target={link.blank ? '_blank' : '_self'}>
        {value}
      </a>
    );
  }

  if (type === 'email') {
    return <a href={`mailto:${value}`}>{value}</a>;
  }

  if (type === 'phonenumber') {
    return <a href={`tel:${value}`}>{value}</a>;
  }

  return value;
};

export const LabelValueTable: React.FC<LabelValueTableProps> = ({
  title,
  subtitle,
  data,
  columnCount = 2,
  labelWidth = '18rem',
  className,
}) => {
  return (
    <Layout className={className}>
      <SectionHeader subtitle={subtitle} title={title} />
      <Grid gap={spacing.s08} gridAutoFlow="row" gridTemplateColumns={`repeat(${columnCount}, 1fr)`}>
        {data.map((item: LabelValueItem, i) => (
          <Flex alignItems="baseline" gap={spacing.s16} key={item.label} role="cell">
            <Text
              color="secondary"
              flexShrink={0}
              key={item.label}
              overflow="hidden"
              textOverflow="ellipsis"
              type="text-md"
              whiteSpace="nowrap"
              width={labelWidth}
            >
              {item.label}
            </Text>
            {/* eslint-disable-next-line react/no-array-index-key -- not other options in this case */}
            <Text key={i} type="text-md" whiteSpace="pre-wrap">
              <Value {...item} />
            </Text>
          </Flex>
        ))}
      </Grid>
    </Layout>
  );
};
