import { inversedLerp } from '@lemonade-hq/ts-helpers';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { clsx } from 'clsx';
import type { Ref } from 'react';
import { forwardRef } from 'react';
import * as styles from './Range.css';

export interface RangeProps {
  readonly min: number;
  readonly max: number;
  readonly value: number;
  readonly step: number;
  readonly onChange?: (value: number) => void;
  readonly disabled?: boolean;
  readonly className?: string;
}

export const Range = forwardRef(
  ({ min, max, value, step, onChange, disabled, className }: RangeProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    return (
      <input
        className={clsx(styles.range, className)}
        disabled={disabled}
        max={max}
        min={min}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(parseFloat(e.target.value))}
        ref={ref}
        step={step}
        style={assignInlineVars({ [styles.rangePercentage]: `${inversedLerp(min, max, value) * 100}%` })}
        type="range"
        value={value}
      />
    );
  },
);
