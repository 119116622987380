import type { FC } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Layout } from '../../base/Layout/Layout';
import { shimmering, shimmeringMockBlock } from '../../theme/shimmering.css';
import { spacing } from '../../theme/spacing.css';

const times = (amount: number): number[] => Array.from(new Array(amount).keys());

export const TableLoader: FC<{
  readonly rowsAmount?: number;
  readonly columnsAmount?: number;
  readonly className?: string;
}> = ({ rowsAmount = 5, columnsAmount = 6, className }) => (
  <Flex className={className} flexDirection="column" gap={spacing.s12} padding={spacing.s12}>
    <Layout className={shimmering}>
      <Layout className={shimmeringMockBlock} />
    </Layout>
    {times(rowsAmount).map(rowNum => (
      <Layout
        className={shimmering}
        display="grid"
        gap={spacing.s08}
        gridTemplateColumns="40px repeat(4, 1fr) 30px"
        key={rowNum}
        style={{ animationDelay: `${rowNum * 0.3}s` }}
      >
        {times(columnsAmount).map(columnNum => (
          <Layout className={shimmeringMockBlock} key={columnNum} />
        ))}
      </Layout>
    ))}
  </Flex>
);
