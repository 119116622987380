import type { SelectOptionBase } from '@lemonade-hq/blender-ui';
import { Flex, Grid, Input, Select, spacing, Text } from '@lemonade-hq/blender-ui';
import { DateTimePicker } from '@lemonade-hq/bluis';
import { camelCaseToReadable, isDefined, snakeToCamelCase, titlize } from '@lemonade-hq/ts-helpers';
import { useCallback, useMemo } from 'react';
import type { TestInEnvironmentDialogData } from './TestInEnvironmentDialog';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import { getEditionName } from 'components/LoCo/common/editions/editionHelpers';
import { EditionType, VersionType } from 'models/LoCo/Insurance/BaseEdition';
import { useGetProductEditionsSummary, useGetProductSchemaRevisions } from 'queries/LoCo/Insurance/ProductQueries';

function isValidForm(
    selectedEditionsCount: number,
    selectedProductSchema: string | null,
    selectedPlatformSchema: string | null
): boolean {
    if (selectedEditionsCount === 0) {
        return false;
    }

    return (
        selectedEditionsCount === Object.keys(EditionType).length &&
        isDefined(selectedProductSchema) &&
        isDefined(selectedPlatformSchema)
    );
}

interface DefineEditionSetProps {
    readonly productCode: string;
    readonly state: DeepNullable<TestInEnvironmentDialogData>;
    readonly isLatestMinorsLoading: boolean;
    readonly onChange: ({
        type,
        value,
    }: {
        readonly type: keyof TestInEnvironmentDialogData;
        readonly value: DeepNullable<TestInEnvironmentDialogData[keyof TestInEnvironmentDialogData]>;
    }) => void;
}

export const DefineEditionSetStep: React.FC<DefineEditionSetProps> = ({
    productCode,
    state,
    isLatestMinorsLoading,
    onChange,
}) => {
    const { data: editions } = useGetProductEditionsSummary(productCode);
    const { data: schemaRevisions, isPending: isLoadingSchemaRevisions } = useGetProductSchemaRevisions(productCode);
    const productSchemaOptions = useMemo<SelectOptionBase[]>(() => {
        if (!schemaRevisions) return [];

        return schemaRevisions.productRevisions.map(revision => ({
            id: revision.revision,
            value: revision.revision.toString(),
            label: revision.revision.toString(),
        }));
    }, [schemaRevisions]);

    const platformSchemaOptions = useMemo<SelectOptionBase[]>(() => {
        if (!schemaRevisions) return [];

        return schemaRevisions.platformRevisions.map(revision => ({
            id: revision.revision,
            value: revision.revision.toString(),
            label: revision.revision.toString(),
        }));
    }, [schemaRevisions]);

    const editionsData = useMemo(() => {
        return [
            {
                type: EditionType.Coverages,
                options: editions?.coveragesEditions ?? [],
                selectedEdition: state.editions.selectedEditions.coveragesEditionCode,
            },
            {
                type: EditionType.DigitalAgent,
                options: editions?.digitalAgentEditions ?? [],
                selectedEdition: state.editions.selectedEditions.digitalAgentEditionCode,
            },
            {
                type: EditionType.UnderwritingFilters,
                options: editions?.underwritingFiltersEditions ?? [],
                selectedEdition: state.editions.selectedEditions.underwritingFiltersEditionCode,
            },
            {
                type: EditionType.Rating,
                options: editions?.ratingEditions ?? [],
                selectedEdition: state.editions.selectedEditions.ratingEditionCode,
            },
        ];
    }, [editions, state.editions]);

    const onEditionSelection = useCallback(
        (editionItemType: EditionType) => (code: string | null) => {
            onChange({
                type: 'editions',
                value: {
                    selectedEditions: {
                        ...state.editions.selectedEditions,
                        [`${snakeToCamelCase(editionItemType)}EditionCode`]: code,
                    },
                    selectedPlatformSchema: state.editions.selectedPlatformSchema,
                    selectedProductSchema: state.editions.selectedProductSchema,
                    isValid: isValidForm(
                        Object.values({
                            ...state.editions.selectedEditions,
                            [`${snakeToCamelCase(editionItemType)}EditionCode`]: code,
                        }).filter(edition => edition != null).length,
                        state.editions.selectedProductSchema,
                        state.editions.selectedPlatformSchema
                    ),
                },
            });
        },
        [
            onChange,
            state.editions.selectedEditions,
            state.editions.selectedPlatformSchema,
            state.editions.selectedProductSchema,
        ]
    );

    const onSchemaSelection = useCallback(
        (selectedProductSchema: string | null, selectedPlatformSchema: string | null) => {
            const isFormValid = isValidForm(
                Object.values(state.editions.selectedEditions).filter(edition => edition != null).length,
                selectedProductSchema,
                selectedPlatformSchema
            );

            onChange({
                type: 'editions',
                value: {
                    selectedEditions: {
                        ...state.editions.selectedEditions,
                    },
                    selectedPlatformSchema,
                    selectedProductSchema,
                    isValid: isFormValid,
                },
            });
        },
        [onChange, state.editions.selectedEditions]
    );

    const handleDateChange = useCallback(
        (key: 'newBusiness' | 'renewals', date: Date) => {
            const dates = {
                ...state.effectiveDates,
                [key]: date,
            };
            onChange({
                type: 'effectiveDates',
                value: {
                    ...dates,
                    isValid: isValidForm(
                        Object.values(state.editions.selectedEditions).filter(isDefined).length,
                        state.editions.selectedProductSchema,
                        state.editions.selectedPlatformSchema
                    ),
                },
            });
        },
        [
            onChange,
            state.editions.selectedEditions,
            state.editions.selectedPlatformSchema,
            state.editions.selectedProductSchema,
            state.effectiveDates,
        ]
    );

    const onRolloutStrategyChange = useCallback(
        (value: string | null) => {
            if (value == null) return;

            onChange({ type: 'versionType', value: value as VersionType });

            if (value === VersionType.Major) {
                onChange({
                    type: 'version',
                    value: {
                        ...state.version,
                        minor: 0,
                        isValid: state.version.major != null && state.version.major > 0,
                    },
                });
            }
        },
        [onChange, state.version]
    );

    return (
        <Flex alignItems="center" flexDirection="column" gap={spacing.s12} p={spacing.s32}>
            <Text textAlign="center">
                These configurations are for testing purposes only and must be redefined later if creating a Release
                with this Edition.
            </Text>
            <Text textAlign="center">Select the Editions to include in the test Edition Set:</Text>
            <Grid
                gap="1rem"
                gridTemplateColumns="200px 300px"
                pb={spacing.s20}
                pt={spacing.s08}
                style={{ alignItems: 'center', justifyContent: 'center' }}
            >
                {editionsData.map(editionItem => (
                    <>
                        <Flex>{getEditionName(editionItem.type)}</Flex>
                        <Select
                            disabled={editionItem.options.length === 0 || isLatestMinorsLoading}
                            onChange={onEditionSelection(editionItem.type)}
                            options={editionItem.options.map(option => ({
                                label: option.friendlyName,
                                value: option.code,
                            }))}
                            placeholder="Select Edition"
                            selectedKey={editionItem.selectedEdition ?? undefined}
                        />
                    </>
                ))}
                <Flex>Product Schema</Flex>
                <Select
                    disabled={isLoadingSchemaRevisions}
                    onChange={value => onSchemaSelection(value as string, state.editions.selectedPlatformSchema)}
                    options={productSchemaOptions}
                    placeholder="Select Schema"
                    selectedKey={state.editions.selectedProductSchema ?? undefined}
                />
                <Flex>Platform Schema</Flex>
                <Select
                    disabled={isLoadingSchemaRevisions}
                    onChange={value => onSchemaSelection(state.editions.selectedProductSchema, value as string)}
                    options={platformSchemaOptions}
                    placeholder="Select Schema"
                    selectedKey={state.editions.selectedPlatformSchema ?? undefined}
                />
            </Grid>
            <Text textAlign="center">Select the Effective Dates:</Text>
            <Grid
                gap="1rem"
                gridTemplateColumns="200px 300px"
                pt={spacing.s08}
                style={{ alignItems: 'center', justifyContent: 'center' }}
            >
                <Flex>New Business</Flex>
                <DateTimePicker
                    onChange={date => handleDateChange('newBusiness', date)}
                    value={state.effectiveDates.newBusiness ?? undefined}
                />
                <Flex>Renewals</Flex>
                <DateTimePicker
                    onChange={date => handleDateChange('renewals', date)}
                    value={state.effectiveDates.renewals ?? undefined}
                />
            </Grid>
            <Flex alignItems="center" flexDirection="column" pt={spacing.s20} px={spacing.s64}>
                <Text textAlign="center">Select Version Number:</Text>
                <Flex flexDirection="column" pt={spacing.s12}>
                    <Grid
                        gap="1rem"
                        gridTemplateColumns="200px 300px"
                        pt={spacing.s08}
                        style={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Flex>Rollout Strategy</Flex>
                        <Select
                            onChange={onRolloutStrategyChange}
                            options={Object.entries(VersionType).map(([key, value]) => ({
                                label: titlize(camelCaseToReadable(key)),
                                value,
                            }))}
                            placeholder="Select"
                            selectedKey={state.versionType ?? undefined}
                        />
                        <Text>Major</Text>
                        <Input
                            min="1"
                            onChange={event =>
                                onChange({
                                    type: 'version',
                                    value: {
                                        ...state.version,
                                        major: Number(event.target.value),
                                        isValid:
                                            state.version.minor != null &&
                                            state.version.minor > -1 &&
                                            Number(event.target.value) > -1,
                                    },
                                })
                            }
                            type="number"
                            value={state.version.major ?? undefined}
                        />
                        <Text>Minor</Text>
                        <Input
                            disabled={state.versionType === VersionType.Major}
                            min="0"
                            onChange={event =>
                                onChange({
                                    type: 'version',
                                    value: {
                                        ...state.version,
                                        minor: Number(event.target.value),
                                        isValid: state.version.major != null && state.version.minor != null,
                                    },
                                })
                            }
                            type="number"
                            value={state.version.minor ?? undefined}
                        />
                    </Grid>
                </Flex>
            </Flex>
        </Flex>
    );
};
