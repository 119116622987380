import React from 'react';
import StatelessToolTip, { StatefulToolTip } from 'react-portal-tooltip';
import styled from 'styled-components';
import { ThemedColors } from 'bluis/Colors';

const defaultStyle: React.CSSProperties = {
    background: ThemedColors.tooltipBackground,
    padding: '13px 12px',
    borderRadius: '5px',
    color: ThemedColors.tooltipText,
    fontSize: '14px',
    boxShadow: 'none',
    pointerEvents: 'none',
    zIndex: 101,
};

const defaultArrowStyle: React.CSSProperties = {
    width: '10px',
    height: '10px',
    color: ThemedColors.tooltipBackground,
    backgroundColor: ThemedColors.tooltipBackground,
    border: 0,
    transition: 'unset',
    borderRadius: '0 0 3px 0',
    zIndex: 101,
};

interface TooltipProps {
    readonly parent: JSX.Element | React.RefObject<unknown> | string;
    readonly group?: string;
    readonly position: 'bottom' | 'top';
    readonly style?: React.CSSProperties;
    readonly arrow?: 'bottom' | 'center' | 'left' | 'right' | 'top';
    readonly arrowStyle?: React.CSSProperties;
    readonly timeout?: number;
    readonly className?: string;
}

const Stateless = styled(StatelessToolTip)`
    color: ${ThemedColors.primaryText};
`;

const Stateful = styled(StatefulToolTip)`
    color: ${ThemedColors.primaryText};
`;

/**
 * @deprecated
 * use Tooltip from '@lemonade-hq/bluis' instead
 */
export const StatefulTooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
    parent,
    group,
    children,
    position,
    style,
    arrowStyle,
    arrow = 'center',
    timeout,
    className,
}) => {
    const tooltipStyle = {
        style: {
            ...defaultStyle,
            ...{ transform: position === 'bottom' ? 'translateY(-4px)' : undefined },
            ...style,
        },
        arrowStyle: {
            ...defaultArrowStyle,
            ...{ transform: `translateY(${position === 'top' ? '-6px' : '6px'}) rotate(45deg)` },
            ...arrowStyle,
        },
    };

    return (
        <Stateful
            arrow={arrow}
            className={className}
            group={group}
            parent={parent}
            position={position}
            style={tooltipStyle}
            tooltipTimeout={timeout ?? 200}
        >
            {children}
        </Stateful>
    );
};

/**
 * @deprecated
 * use Tooltip from '@lemonade-hq/bluis' instead
 */
export const StatelessTooltip: React.FC<React.PropsWithChildren<TooltipProps & { readonly active: boolean }>> = ({
    parent,
    group,
    children,
    position,
    style,
    arrowStyle,
    timeout,
    active,
}) => {
    const tooltipStyle = {
        style: {
            ...defaultStyle,
            ...{ transform: position === 'bottom' ? 'translateY(-4px)' : undefined },
            ...style,
        },
        arrowStyle: {
            ...defaultArrowStyle,
            ...{ transform: `translateY(${position === 'top' ? '-6px' : '6px'}) rotate(45deg)` },
            ...arrowStyle,
        },
    };

    return (
        <Stateless
            active={active}
            arrow="center"
            group={group}
            parent={parent}
            position={position}
            style={tooltipStyle}
            tooltipTimeout={timeout ?? 200}
        >
            {children}
        </Stateless>
    );
};
