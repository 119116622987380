/* eslint-disable @typescript-eslint/no-non-null-assertion, react/function-component-definition,  react/no-array-index-key,  @typescript-eslint/no-non-null-asserted-optional-chain */
import type { FC, ReactNode } from 'react';
import { createElement, useEffect, useRef } from 'react';
import { ListBox, ListBoxItem } from 'react-aria-components';
import * as styles from './AudioPlayer.css';
import { useAudioPlayerSegments } from './AudioPlayer.SegmentsProvider';
import type { AudioPlayerSegment } from './AudioPlayer.SegmentsProvider.types';

interface AudioPlayerSegmentProps<TSegment extends AudioPlayerSegment> {
  readonly segment: TSegment;
  readonly index: number;
}

export interface AudioPlayerSegmentedListProps<TSegment extends AudioPlayerSegment> {
  readonly segmentComponent: FC<AudioPlayerSegmentProps<TSegment>>;
  readonly filterBy?: (segment: TSegment, index: number) => boolean;
}

export function AudioPlayerSegmentedList<TSegment extends AudioPlayerSegment>({
  segmentComponent,
  filterBy = () => true,
}: AudioPlayerSegmentedListProps<TSegment>): ReactNode {
  const { segments, currentSegmentIndex, goToSegmentIndex } = useAudioPlayerSegments<TSegment>();
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentSegmentIndex === -1) {
      window.scrollTo(0, 0);
    } else {
      const element = container.current?.querySelector(`:nth-child(${currentSegmentIndex + 1})`);
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [currentSegmentIndex]);

  return (
    <ListBox
      className={styles.segmentedList}
      onSelectionChange={([selection]) => {
        goToSegmentIndex(selection as number);
      }}
      ref={container}
      selectedKeys={currentSegmentIndex === -1 ? undefined : [currentSegmentIndex]}
      selectionMode="single"
    >
      {segments.filter(filterBy).map((segment, index) => (
        <ListBoxItem className={styles.listSegment} id={index} key={index}>
          {createElement(segmentComponent, { segment, index })}
        </ListBoxItem>
      ))}
    </ListBox>
  );
}
