import type { ActionOption, DialogAction } from '@lemonade-hq/bluis';
import { ActionsMenu, Dialog, Image } from '@lemonade-hq/bluis';
import { EntitySummaryHeader, HeaderGeneralDetails, HeaderLocation, LinkComp } from '@lemonade-hq/bluiza';
import { Box } from '@lemonade-hq/cdk';
import { useMemo, useState } from 'react';
import { getProductUrl } from '../common/urlBuilders';
import { HeaderImageSizes, ImageContainer } from '../LoCoPagesSharedStyles';
import { EDITION_SET_STATUS_DISPLAY, EDITION_SET_STATUS_TAG_MODE } from './utils';
import { HeaderPortal } from 'components/Header';
import { getEditionSetStatus } from 'models/LoCo/Insurance/EditionSets';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';
import type { Product } from 'models/LoCo/Insurance/Product';
import { useArchiveEditionSet } from 'queries/LoCo/Insurance/EditionSetQueries';

const ArchiveEditionSetDialog: React.FC<{ readonly editionSet: EditionSet; readonly onClose: () => void }> = ({
    editionSet,
    onClose,
}) => {
    const { mutateAsync: archive, error, isPending } = useArchiveEditionSet(editionSet.code);

    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Archive',
                type: 'submit',
                onClick: async () => {
                    await archive(editionSet.code);
                    onClose();
                },
                disabled: isPending,
            },
        ],
        [archive, editionSet.code, isPending, onClose]
    );

    return (
        <Dialog actions={actions} error={error as string} onClose={onClose} title="Archive Edition Set">
            Are you sure you want to archive this edition set?
        </Dialog>
    );
};

interface EditionSetHeaderProps {
    readonly product: Product;
    readonly editionSet: EditionSet;
}

export const EditionSetHeader: React.FC<EditionSetHeaderProps> = ({ product, editionSet }) => {
    const [showDialog, setShowDialog] = useState(false);
    const status = getEditionSetStatus(editionSet);

    const actions: ActionOption[] = useMemo(
        () => [
            {
                label: 'Archive Edition Set',
                value: 'archiveEditionSet',
                disabled: !editionSet.isTest,
                onClick: () => setShowDialog(true),
            },
        ],
        [editionSet.isTest]
    );

    return (
        <HeaderPortal>
            <EntitySummaryHeader>
                <ImageContainer>
                    <Image
                        img={{
                            lightSrc: 'appraisal.svg',
                            darkSrc: 'appraisal-dark.svg',
                            ...HeaderImageSizes,
                        }}
                    />
                </ImageContainer>
                <HeaderGeneralDetails
                    status={{
                        label: EDITION_SET_STATUS_DISPLAY[status],
                        mode: EDITION_SET_STATUS_TAG_MODE[status],
                    }}
                    subtitle={<LinkComp url={getProductUrl(product.code)}>{product.name}</LinkComp>}
                    title={`${editionSet.friendlyName} (${editionSet.version})`}
                />
                <HeaderLocation country={product.country} state={product.state ?? product.country} />
                <Box ml="auto">
                    <ActionsMenu actions={actions} />
                </Box>
            </EntitySummaryHeader>
            {showDialog && <ArchiveEditionSetDialog editionSet={editionSet} onClose={() => setShowDialog(false)} />}
        </HeaderPortal>
    );
};
