import { darkThemeClass, Flex, Spinner } from '@lemonade-hq/blender-ui';
import { RootErrorBoundary } from '@lemonade-hq/bluiza';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import type { QueryKey } from '@tanstack/react-query';
import { clsx } from 'clsx';
import type { FC } from 'react';
import { createPortal } from 'react-dom';
import type { HubFilters } from '../AttachmentHub/Filters';
import { useGetAttachments, useGetAttachmentsByIds } from '../AttachmentsQueries';
import { AttachmentsProvider, useAttachmentsData } from '../context';
import type { AttachmentDTO, AttachmentStats } from '../types';
import { AttachmentCarousel } from './Carousel/AttachmentCarousel';
import { CarouselProvider } from './Carousel/CarouselProvider';
import { GalleryDialog } from './Gallery.css';
import { InfoSidebar } from './InfoSidebar';

type WithPublicIds = {
    /** When provided, the component will fetch all attachments by their public IDs. */
    readonly publicIds?: string[];
    readonly attachments?: never;
    readonly stats?: never;
};

type WithAttachments = {
    readonly attachments?: AttachmentDTO[];
    readonly publicIds?: never;
    readonly stats?: AttachmentStats;
};

export type AttachmentGalleryProps = {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly filters?: HubFilters;
    readonly index?: number;
    readonly invalidateKeys?: QueryKey[];
    readonly onClose: () => void;
} & (WithAttachments | WithPublicIds);

const AttachmentGalleryContent: FC<Pick<AttachmentGalleryProps, 'index' | 'onClose'>> = ({ index, onClose }) => {
    const { attachments } = useAttachmentsData();

    if (attachments.length === 0) {
        // make sure to close the gallery if the attachments list changes to empty
        onClose();
        return null;
    }

    return (
        <CarouselProvider index={index} onClose={onClose} totalSlides={attachments.length}>
            <div className={clsx(GalleryDialog, darkThemeClass)}>
                <InfoSidebar />
                <AttachmentCarousel />
            </div>
        </CarouselProvider>
    );
};

export const AttachmentGallery: FC<AttachmentGalleryProps> = ({
    attachments,
    entityType,
    entityPublicId,
    filters,
    index = 0,
    invalidateKeys,
    onClose,
    publicIds,
    stats,
}) => {
    // get all attachments if there are more than one pages
    const hasMorePages = stats != null && stats.totalPages > 1;
    const hasAttachmentIds = publicIds != null && publicIds.length > 0;

    const { data, isLoading } = useGetAttachments({
        entityType,
        entityPublicId,
        params: { ...filters, page: 1 },
        pageSize: stats?.totalRecords,
        enabled: hasMorePages,
    });

    // Get attachments by public IDs. Handy when there's a need to only show attachments on-demand.
    // For example, when clicking on a thumbnail.
    const { data: attachmentsByIds, isLoading: isLoadingByIds } = useGetAttachmentsByIds({
        publicIds,
        enabled: hasAttachmentIds,
    });

    // calc the correct index of a file in the list when getting all attachments
    const selectedIndex = hasMorePages ? stats.pageSize * (stats.currentPage - 1) + index : index;

    if (isLoading || isLoadingByIds)
        return (
            <Flex alignItems={'center'} height={'100%'} justifyContent={'center'} width={'100%'}>
                <Spinner size={'lg'} />
            </Flex>
        );

    const content = (
        <RootErrorBoundary>
            <AttachmentsProvider
                attachments={data?.data ?? attachments ?? attachmentsByIds}
                entityPublicId={entityPublicId}
                entityType={entityType}
                invalidateKeys={invalidateKeys}
            >
                <AttachmentGalleryContent index={selectedIndex} onClose={onClose} />
            </AttachmentsProvider>
        </RootErrorBoundary>
    );

    return createPortal(content, document.body);
};
