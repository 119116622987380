import * as sentry from '@sentry/react';
import { Route, Routes } from 'react-router-dom';

import { ChatEditor } from './chat-editor/ChatEditor';
import { SuspendableContent } from './common/components/SuspendableContent';
import { EditionSet } from './edition-sets/EditionSet';
import { CoveragesEdition } from './editions/coverage-editions/CoveragesEdition';
import { DigitalAgentEdition } from './editions/digital-agent-edition/DigitalAgentEdition';
import { RatingEdition } from './editions/rating-edition/RatingEdition';
import { UnderwritingFiltersEdition } from './editions/underwriting-filters-edition/UnderwritingFiltersEdition';
import { CoveragesAndSettingsRegistry } from './global-registry/coverages-registry/CoveragesAndSettingsRegistry';
import { UnderwritingRegistry } from './global-registry/underwriting-registry/UnderwritingRegistry';
import { ProductLayout } from './products/ProductMissionControl/ProductLayout';
import { Release } from './releases/Release';
import { LoCoTestHub } from './test-hub/LoCoTestHub';
import { LoCoProducts } from 'components/LoCo/products/LoCoProducts';
import { LoCoProductsMissionControl } from 'components/LoCo/products/ProductMissionControl/ProductMissionControl';

const SentryRoutes = sentry.withSentryReactRouterV6Routing(Routes);

export const LoCoRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <SentryRoutes>
            <Route element={<LoCoProducts />} path="/products" />
            <Route element={<LoCoTestHub />} path="/test-hub" />
            <Route element={<ChatEditor />} path={`/chat_editor/editor`} />
            <Route element={<ProductLayout />} path="/products/:productCode/">
                {['/products/:productCode/', '/products/:productCode/*'].map(path => (
                    <Route element={<LoCoProductsMissionControl />} key={path} path={path} />
                ))}

                <Route
                    element={
                        <SuspendableContent>
                            <RatingEdition />
                        </SuspendableContent>
                    }
                    path="/products/:productCode/rating/:ratingEditionCode/*"
                />
                <Route element={<CoveragesEdition />} path="/products/:productCode/coverages/:coveragesEditionCode" />
                <Route
                    element={
                        <SuspendableContent>
                            <DigitalAgentEdition />
                        </SuspendableContent>
                    }
                    path="/products/:productCode/digital-agent/:digitalAgentEditionCode"
                />
                <Route
                    element={
                        <SuspendableContent>
                            <UnderwritingFiltersEdition />
                        </SuspendableContent>
                    }
                    path="/products/:productCode/underwriting-filters/:underwritingFiltersEditionCode"
                />
                <Route
                    element={
                        <SuspendableContent>
                            <Release />
                        </SuspendableContent>
                    }
                    path="/products/:productCode/releases/:releaseCode/*"
                />
                <Route
                    element={
                        <SuspendableContent>
                            <EditionSet />
                        </SuspendableContent>
                    }
                    path="/products/:productCode/edition-sets/:editionSetCode/*"
                />
            </Route>
            <Route
                element={
                    <SuspendableContent>
                        <CoveragesAndSettingsRegistry />
                    </SuspendableContent>
                }
                path="/coverages-registry/*"
            />
            <Route
                element={
                    <SuspendableContent>
                        <UnderwritingRegistry />
                    </SuspendableContent>
                }
                path="/underwriting-registry/*"
            />
        </SentryRoutes>
    );
};
