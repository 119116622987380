import { Text } from '@lemonade-hq/blender-ui';
import type { DialogAction } from '@lemonade-hq/bluis';
import { AlertMode, Dialog, ErrorSection, LoadingSection } from '@lemonade-hq/bluis';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useCallback, useMemo } from 'react';
import { GeneralViolations, Violations } from 'components/LoCo/common/components/ViolationsErrorMessage';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import {
    useApproveEdition,
    useGetEditionViolations,
    useGetLatestsPublished,
} from 'queries/LoCo/Insurance/EditionTrackerQueries';

interface ApproveEditionDialogDraftProps {
    readonly productCode: string;
    readonly editionType: EditionType;
    readonly editionCode: string;
    readonly editionTrackerPublicId: string;
    readonly referenceEditionContentCode?: string;
    readonly onClose: () => void;
}

export const ApproveEditionDialogDraft: React.FC<ApproveEditionDialogDraftProps> = ({
    editionCode,
    onClose,
    editionType,
    productCode,
    editionTrackerPublicId,
    referenceEditionContentCode,
}) => {
    const {
        data: violations,
        isLoading: isLoadingViolations,
        isError: isViolationsError,
    } = useGetEditionViolations(editionTrackerPublicId);

    const {
        mutateAsync: approveEdition,
        isError: isApproveError,
        isPending: isSubmitting,
        error,
    } = useApproveEdition(productCode, editionCode, editionType);

    const {
        data: latestPublished,
        isLoading: isLoadingLatestPublished,
        isError: isErrorLatestPublished,
    } = useGetLatestsPublished(productCode);

    const notice = useMemo(() => {
        if (
            Boolean(referenceEditionContentCode) &&
            Boolean(latestPublished) &&
            !latestPublished?.some(latest => latest.editionContentCode === referenceEditionContentCode)
        ) {
            return [{ title: 'This edition can only be published as a bug fix', mode: AlertMode.Attention }];
        }

        return undefined;
    }, [referenceEditionContentCode, latestPublished]);

    const onSubmit = useCallback(async () => {
        await approveEdition({ editionTrackerPublicId });
        onClose();
    }, [approveEdition, editionTrackerPublicId, onClose]);

    const actions = useMemo<DialogAction[]>(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Approve',
                type: 'submit',
                onClick: onSubmit,
                disabled: isSubmitting || !isDefined(violations) || violations.hasViolation,
            },
        ],
        [onClose, onSubmit, isSubmitting, violations]
    );

    const dialogError = useMemo(() => {
        if (isLoadingViolations) return;
        if (isViolationsError || !isDefined(violations)) {
            return 'Error Loading Violations';
        }

        if (violations.hasViolation) {
            return <GeneralViolations title="The edition cannot be published:" violations={violations.messages} />;
        }

        if (isApproveError) {
            return <Violations editionType={editionType} error={error} />;
        }
    }, [editionType, error, isApproveError, isLoadingViolations, isViolationsError, violations]);

    const isLoading = isLoadingViolations || isLoadingLatestPublished;

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={dialogError}
            loading={isSubmitting}
            notice={notice}
            onClose={onClose}
            title="Approve Edition"
        >
            {isErrorLatestPublished ? (
                <ErrorSection noBorders />
            ) : isLoading ? (
                <LoadingSection noBorders noShadow />
            ) : editionType === EditionType.Rating ? (
                <Text>Once you approve this edition, it will be available for release</Text>
            ) : (
                <Text>Once you approve this edition, you won&apos;t be able to make any changes to it</Text>
            )}
        </Dialog>
    );
};
