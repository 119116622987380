import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { ThemedColors } from '../Colors';

const BSwitchContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    user-select: none;
`;

const BSwitchTitle = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 5px;
    color: ${ThemedColors.primaryText};
`;

const SwitchContainer = styled.div`
    cursor: pointer;
    user-select: none;
    position: relative;
    border: 1px solid ${ThemedColors.separatorColor};
    background: ${ThemedColors.componentBackground};

    ${props => css`
        opacity: ${props.isDisabled ? '0.5' : '1'};
        pointer-events: ${props.isDisabled ? 'none' : ''};
        height: ${props.small ? '30px' : '39px'};
        border-radius: ${props.small ? '15px' : '19.5px'};
    `}
`;

const SelectedCircle = styled.div`
    position: absolute;
    top: -1px;
    left: 0;
    bottom: -1px;
    border-radius: 19.5px;
    z-index: 1;
    pointer-events: none;

    ${props => css`
        width: ${props.small ? '48px' : '66px'};
        border: 1px solid ${props.color ?? ThemedColors.primaryColor};
        visibility: ${props.hide ? 'hidden' : 'visible'};
        transform: translateX(${props.selectedIndex * (props.small ? 46 : 64) - 1}px);
        transition: ${props.animateSwitch ? 'transform' : 'none'} 0.2s;
    `}
`;

const SwitchItem = styled.div`
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    position: relative;
    border-radius: 19.5px;
    padding: 0 10px;

    ${props => css`
        width: ${props.small ? '46px' : '64px'};
        line-height: ${props.small ? '27px' : '37px'};
        font-weight: ${props.isSelected ? '600' : '400'};
        background-color: ${props.isSelected ? props.background ?? 'inherit' : 'inherit'};
        font-size: ${props.small ? '11px' : 'inherit'};
        color: ${props => {
            if (props.isSelected) {
                return props.color ?? ThemedColors.primaryColor;
            }

            return props.greyOutNotSelected ? ThemedColors.secondaryText : ThemedColors.primaryText;
        }};
    `}

    ${props =>
        props.showSeparator &&
        css`
            &:not(:last-child):before {
                content: '';
                width: 1px;
                height: 20px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: ${ThemedColors.separatorColor};
            }
        `};
`;

/**
 * @deprecated
 * use Switch/MultiSwitch from '@lemonade-hq/bluis' instead
 */
const BSwitch = ({
    options = [],
    selected,
    onSwitch,
    isDisabled,
    title,
    className,
    small,
    greyOutNotSelected,
    removeSeparatorOnSelect,
}) => {
    const [animateSwitch, setAnimateSwitch] = useState(true);
    const selectedIndex = options.findIndex(({ value }) => value === selected);
    const isSelected = selected != null;

    useEffect(() => {
        if (!isSelected) {
            setAnimateSwitch(false);
        }
    }, [isSelected]);

    const handleClick = value => () => {
        if (!animateSwitch && isSelected) {
            setAnimateSwitch(true);
        }

        onSwitch(value);
    };

    return (
        <BSwitchContainer className={className} small={small}>
            {title && <BSwitchTitle>{title}</BSwitchTitle>}
            <SwitchContainer isDisabled={isDisabled} small={small}>
                <SelectedCircle
                    color={selectedIndex > -1 ? options[selectedIndex].color : undefined}
                    hide={selectedIndex === -1}
                    selectedIndex={selectedIndex}
                    small={small}
                    animateSwitch={animateSwitch}
                />
                {options.map(({ title, value, color, background }) => (
                    <SwitchItem
                        small={small}
                        key={value}
                        color={color}
                        isSelected={selected === value}
                        onClick={handleClick(value)}
                        background={background}
                        greyOutNotSelected={greyOutNotSelected}
                        showSeparator={!(removeSeparatorOnSelect && isSelected)}
                    >
                        {title}
                    </SwitchItem>
                ))}
            </SwitchContainer>
        </BSwitchContainer>
    );
};

BSwitch.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSwitch: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    title: PropTypes.string,
    className: PropTypes.string,
};

export default BSwitch;
