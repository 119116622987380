import { Card, Flex, spacing } from '@lemonade-hq/blender-ui';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useMemo, useState } from 'react';
import { useTableSort } from '../common/hooks/useTableSort';
import { AttributesTable } from '../products/ProductMissionControl/ProductSchemaTable';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';
import type { FieldEntry } from 'models/LoCo/Insurance/Schema';
import { useGetEditionSetSchema } from 'queries/LoCo/Insurance/EditionSetQueries';

interface EditionSetSchemaProps {
    readonly editionSet: EditionSet;
}

export const EditionSetSchema: React.FC<EditionSetSchemaProps> = ({ editionSet }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const {
        data: schemaAttributesResponse,
        isPending: isLoadingSchemaAttributes,
        isError: isErrorSchemaAttributes,
    } = useGetEditionSetSchema(editionSet.code);

    const { sort, sortColumn, sortDirection, onColumnClick } = useTableSort<FieldEntry>('name');

    const displayData = useMemo(() => {
        const schemaFields = isDefined(schemaAttributesResponse?.schema) ? schemaAttributesResponse.schema.fields : [];
        const input =
            searchTerm.length > 0
                ? schemaFields.filter(field => field.name.toLowerCase().includes(searchTerm.toLowerCase()))
                : schemaFields;

        if (!input.length) return [];

        return sort(input);
    }, [schemaAttributesResponse?.schema, searchTerm, sort]);

    return (
        <Flex pt={spacing.s32}>
            <Card p={spacing.s32}>
                <AttributesTable
                    displayData={displayData}
                    fieldsMetadata={schemaAttributesResponse?.fieldsMetadata ?? {}}
                    isError={isErrorSchemaAttributes}
                    onColumnClick={onColumnClick}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    showLoader={isLoadingSchemaAttributes}
                    showUtilization
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            </Card>
        </Flex>
    );
};
