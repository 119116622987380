/* eslint-disable react/no-array-index-key */

import type { Milliseconds, Pixels } from '@lemonade-hq/ts-helpers';
import { useCallback, useEffect, useRef } from 'react';
import { useAudioPlayer } from './AudioPlayer.Provider';
import { useAudioPlayerSegments } from './AudioPlayer.SegmentsProvider';
import { useAudioPlayerZoom } from './AudioPlayer.ZoomProvider';

interface UseTimelineHeadScrollProps {
  readonly scrollerRef: React.RefObject<HTMLDivElement>;
  readonly headRef: React.RefObject<HTMLDivElement>;
  readonly scrollThreshold: Pixels; // how far the head is from the edge of the scrolling window to automatically scroll
  readonly manualScrollDuration: Milliseconds; // how long after manual scrolling allow automatic scrolling to take over
}

// this hook makes sure the play-head (the line indicating what is the current time) is always on the current viewed timeline window.
// it also manages user behavior - e.g., if the user scrolls manually, wait a certain time until you scroll back automatically to the play-head.
export function useTimelineHeadScroll({
  scrollerRef,
  headRef,
  scrollThreshold,
  manualScrollDuration,
}: UseTimelineHeadScrollProps): void {
  const { currentTime, totalTime } = useAudioPlayer();
  const { currentSegment } = useAudioPlayerSegments();
  const { currentZoom } = useAudioPlayerZoom();
  const isAutomaticallyScrolling = useRef(false);
  const isManuallySeeking = useRef(false);

  const moveScrollToHead = useCallback(() => {
    if (scrollerRef.current) {
      const windowWidth = scrollerRef.current.clientWidth;
      const currentScroll = scrollerRef.current.scrollLeft;
      const headX = headRef.current?.offsetLeft ?? 0;
      const isPlayHeadAfterWindow =
        headX - scrollThreshold >= currentScroll && headX + scrollThreshold <= currentScroll + windowWidth;

      if (!isPlayHeadAfterWindow && !isAutomaticallyScrolling.current && !isManuallySeeking.current) {
        isAutomaticallyScrolling.current = true;
        scrollerRef.current.scrollTo({ left: headX - windowWidth / 2, behavior: 'smooth' });
      }
    }
  }, [headRef, scrollThreshold, scrollerRef]);

  // reset scrolling flags
  useEffect(() => {
    let timeoutRef: number;
    const scroller = scrollerRef.current;

    const startHandler = (): void => {
      if (!isAutomaticallyScrolling.current) {
        isManuallySeeking.current = true;
        timeoutRef = window.setTimeout(() => {
          isManuallySeeking.current = false;
        }, manualScrollDuration);
      }
    };

    const endHandler = (): void => {
      isAutomaticallyScrolling.current = false;
    };

    scroller?.addEventListener('scroll', startHandler);
    scroller?.addEventListener('scrollend', endHandler);

    return () => {
      scroller?.removeEventListener('scroll', startHandler);
      scroller?.removeEventListener('scrollend', endHandler);
      window.clearTimeout(timeoutRef);
    };
  }, [scrollerRef, manualScrollDuration]);

  // reset manual seeking when segment changes
  useEffect(() => {
    isManuallySeeking.current = false;
    moveScrollToHead();
  }, [currentSegment, moveScrollToHead]);

  // recalculate on resize of container
  useEffect(() => {
    const resizeObserver = new ResizeObserver(moveScrollToHead);
    if (scrollerRef.current) resizeObserver.observe(scrollerRef.current);

    return () => resizeObserver.disconnect();
  }, [moveScrollToHead, scrollerRef]);

  // recalculate when zoon or current time changes
  useEffect(() => {
    moveScrollToHead();
  }, [moveScrollToHead, currentZoom, currentTime, totalTime]);
}
