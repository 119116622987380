import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface CarouselContextProps {
    readonly currentIndex: number;
    readonly nextSlide: () => void;
    readonly previousSlide: () => void;
    readonly goToSlide: (index: number) => void;
    readonly onClose: () => void;
}

const CarouselContext = createContext<CarouselContextProps | null>(null);

export const CarouselProvider: FC<
    PropsWithChildren<{ readonly totalSlides: number; readonly index?: number; readonly onClose: () => void }>
> = ({ totalSlides, onClose, index = 0, children }) => {
    const [currentIndex, setCurrentIndex] = useState(index > totalSlides - 1 ? 0 : index);

    const nextSlide = useCallback(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % totalSlides);
    }, [totalSlides]);

    const previousSlide = useCallback(() => {
        setCurrentIndex(prevIndex => (prevIndex - 1 + totalSlides) % totalSlides);
    }, [totalSlides]);

    const goToSlide = useCallback(
        (idx: number) => {
            if (idx >= 0 && idx < totalSlides) {
                setCurrentIndex(idx);
            }
        },
        [totalSlides]
    );

    const handleKeyDown = (e: KeyboardEvent): void => {
        switch (e.key) {
            case 'Escape':
                onClose();
                break;
            case 'ArrowRight':
                nextSlide();
                break;
            case 'ArrowLeft':
                previousSlide();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value = useMemo(
        () => ({
            currentIndex,
            nextSlide,
            previousSlide,
            goToSlide,
            onClose,
        }),
        [currentIndex, nextSlide, previousSlide, goToSlide, onClose]
    );

    return <CarouselContext.Provider value={value}>{children}</CarouselContext.Provider>;
};

export const useCarousel = () => {
    const context = useContext(CarouselContext);
    if (!context) {
        throw new Error('useCarousel must be used within a CarouselProvider');
    }

    return context;
};
