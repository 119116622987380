/* eslint-disable @typescript-eslint/naming-convention */
import { Icon } from '@lemonade-hq/blender-ui';
import { Image } from '@lemonade-hq/bluis';
import type { Vector } from '@lemonade-hq/ts-helpers';
import { clamp, ZERO } from '@lemonade-hq/ts-helpers';
import type { SyntheticEvent } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type DraggableImageProps = {
    readonly src: string;
    readonly fileName: string;
    readonly containerDimensions: DOMRect | null;
    readonly zoom?: number;
    readonly updateLoading?: (isLoading: boolean) => void;
    readonly updateError?: (isError: boolean) => void;
    readonly isError: boolean | undefined;
};

type ErrorContainerProps = {
    readonly src: string;
    readonly fileName: string;
};

type Dimensions = {
    readonly width: number;
    readonly height: number;
};

const StyledDraggableImageContainer = styled.div<{ readonly isDraggable: boolean }>`
    position: relative;
    ${({ isDraggable }) => isDraggable && `cursor: move;`}
`;

const HiddenImage = styled(Image)`
    display: none;
`;

const StyledDraggableImage = styled(Image)<{ readonly position: Vector }>`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 10px;
    user-select: none;
    -webkit-user-drag: none;

    ${({ position }) => `top: translate(${position.y}px, left: ${position.x}px);`}
`;

const StyledErrorContainer = styled.div`
    text-align: center;
    margin: auto;
    color: white;
`;

const StyledErrorMessage = styled.div`
    font-size: 14px;
    padding-bottom: 33px;
`;

const DownloadButton = styled.a`
    height: 46px;
    padding: 0 30px 0 24px;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;

    &:hover {
        background-color: #f0f0f0;
    }

    svg {
        margin-right: 5px;
    }
`;

const ERROR_MESSAGE_STRING = `We can't display this file at the moment. You can download it below.`;

const ErrorContainer: React.FC<React.PropsWithChildren<ErrorContainerProps>> = ({ src, fileName }) => {
    return (
        <StyledErrorContainer>
            <StyledErrorMessage>{ERROR_MESSAGE_STRING}</StyledErrorMessage>
            <DownloadButton download={fileName} href={src}>
                <Icon name="download" />
                Download
            </DownloadButton>
        </StyledErrorContainer>
    );
};

const DraggableImage: React.FC<React.PropsWithChildren<DraggableImageProps>> = ({
    src,
    fileName,
    containerDimensions,
    zoom = 100,
    updateLoading,
    updateError,
    isError,
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState<Dimensions>({ height: 0, width: 0 });
    const [imageSize, setImageSize] = useState<Dimensions>({ height: 0, width: 0 });
    const [position, setPosition] = useState<Vector>(ZERO);
    const dragStartRef = useRef<Vector | null>(null);

    const setBoundedPosition = (position: Vector) => {
        if (!containerDimensions || !wrapperRef.current || zoom <= 100) {
            return;
        }

        const bindingRect = wrapperRef.current.getBoundingClientRect();
        const minTop = Math.max(containerDimensions.top - bindingRect.top, 0);
        const maxTop =
            containerDimensions.bottom - bindingRect.top - Math.max(bindingRect.height, containerDimensions.height);
        const y = clamp(minTop, maxTop, position.y);

        const minLeft = Math.max(containerDimensions.left - bindingRect.left, 0);
        const maxLeft =
            containerDimensions.right - bindingRect.left - Math.max(bindingRect.width, containerDimensions.width);
        const x = clamp(minLeft, maxLeft, position.x);

        setPosition({ x, y });
    };

    useEffect(() => {
        if (zoom <= 100) {
            setPosition({ x: 0, y: 0 });
        }
    }, [zoom]);

    useEffect(() => {
        const isVerticalAligned = (): boolean | null => {
            if (containerDimensions == null || imageSize.height === 0) {
                return null;
            }

            return containerDimensions.height / imageSize.height < containerDimensions.width / imageSize.width;
        };

        const isVertical = isVerticalAligned();

        if (isVertical == null || !containerDimensions) {
            return;
        }

        if (isVertical) {
            const { height } = containerDimensions;
            const width = (imageSize.width * containerDimensions.height) / imageSize.height;

            setDimensions({ width, height });
        } else {
            const { width } = containerDimensions;
            const height = (imageSize.height * containerDimensions.width) / imageSize.width;

            setDimensions({ width, height });
        }
    }, [imageSize, containerDimensions]);

    const onDrag = (event: MouseEvent) => {
        if (dragStartRef.current == null) {
            return;
        }

        setBoundedPosition({ x: event.clientX - dragStartRef.current.x, y: event.clientY - dragStartRef.current.y });
    };

    const endDrag = (event: MouseEvent) => {
        if (dragStartRef.current == null) {
            return;
        }

        document.removeEventListener('mouseup', endDrag);
        document.removeEventListener('mousemove', onDrag);
        setBoundedPosition({ x: event.clientX - dragStartRef.current.x, y: event.clientY - dragStartRef.current.y });
        dragStartRef.current = null;
    };

    const startDrag = (event: React.MouseEvent<HTMLDivElement>) => {
        dragStartRef.current = { x: event.clientX - position.x, y: event.clientY - position.y };
        document.addEventListener('mouseup', endDrag);
        document.addEventListener('mousemove', onDrag);
    };

    useEffect(() => {
        return () => {
            document.removeEventListener('mouseup', endDrag);
            document.removeEventListener('mousemove', onDrag);
        };
    }, []);

    const onLoad = (event: SyntheticEvent<HTMLImageElement>) => {
        setImageSize({ height: event.currentTarget.naturalHeight, width: event.currentTarget.naturalWidth });
        updateLoading?.(false);
    };

    const handleError = () => {
        updateError?.(true);
    };

    return (
        <>
            {isError ? (
                <ErrorContainer fileName={fileName} src={src} />
            ) : (
                <StyledDraggableImageContainer
                    isDraggable={zoom > 100}
                    onMouseDown={startDrag}
                    ref={wrapperRef}
                    style={{ width: dimensions.width, height: dimensions.height }}
                >
                    <StyledDraggableImage img={{ lightSrc: src }} position={position} />
                </StyledDraggableImageContainer>
            )}
            <HiddenImage alt="" img={{ lightSrc: src }} onError={handleError} onLoad={onLoad} />
        </>
    );
};

export default DraggableImage;
