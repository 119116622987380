import type { RolloutStrategies, RolloutStrategy } from './ManageReleaseDialog';
import { RolloutStrategyBugFix } from './RolloutStrategyBugFix';
import { RolloutStrategyProductUpdate } from './RolloutStrategyProductUpdate';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import type { LeanEdition } from 'models/LoCo/Insurance/BaseEdition';
import { ReleaseType } from 'models/LoCo/Insurance/BaseEdition';

interface RolloutStrategyProps {
    readonly productCode: string;
    readonly selectedEditions: LeanEdition[];
    readonly releaseType: ReleaseType | null;
    readonly selectedStrategies: DeepNullable<RolloutStrategies>;
    readonly selectedRatingEdition: string | null;
    readonly onChange: (values: {
        readonly rolloutStrategies?: {
            readonly [RolloutStrategy.ActivePoliciesAndPendingRenewals]: boolean;
            readonly [RolloutStrategy.NewBusinessAndRenewals]: boolean;
        };
        readonly ratingReferenceEditionCode?: string;
    }) => void;
}

export const RolloutStrategyStep: React.FC<RolloutStrategyProps> = ({
    productCode,
    selectedStrategies,
    selectedEditions,
    releaseType,
    selectedRatingEdition,
    onChange,
}) => {
    if (releaseType === ReleaseType.ProductChange) {
        return (
            <RolloutStrategyProductUpdate
                onChange={onChange}
                productCode={productCode}
                selectedEditions={selectedEditions}
                selectedRatingEdition={selectedRatingEdition}
                selectedStrategies={selectedStrategies}
            />
        );
    }

    return (
        <RolloutStrategyBugFix
            onChange={onChange}
            productCode={productCode}
            selectedEditions={selectedEditions}
            selectedRatingEdition={selectedRatingEdition}
        />
    );
};
