import axios from 'axios';
import { getUrlResolver } from 'commons/UrlResolver';
import { VetNoteStatus } from 'models/PetClaim';
import { VetNoteAttachment } from 'models/PetVetNotes';

const petBlenderUrlResolver = getUrlResolver('pet-blender', '/api/v1/claims');

export interface ClaimVetNotes {
    publicId: string;
    createdAt: string;
    updatedAt: string;
    claimPublicId: string;
    status: VetNoteStatus;
    attachments: VetNoteAttachment[];
}

export async function getClaimVetNotes({ claimPublicId }: { claimPublicId: string }): Promise<ClaimVetNotes> {
    const url = petBlenderUrlResolver(`/${claimPublicId}/vet_notes`);

    return axios.get(url).then(response => response.data.data);
}

export async function updateVetNoteDescription({
    filePublicId,
    claimPublicId,
    description,
}: {
    filePublicId: string;
    claimPublicId: string;
    description: string;
}): Promise<null> {
    const url = petBlenderUrlResolver(`/${claimPublicId}/vet_notes/${filePublicId}`);

    return axios.put(url, { description }).then(response => response.data.data);
}

export async function deleteVetNote({
    filePublicId,
    claimPublicId,
}: {
    filePublicId: string;
    claimPublicId: string;
}): Promise<null> {
    const url = petBlenderUrlResolver(`/${claimPublicId}/vet_notes/${filePublicId}`);

    return axios.delete(url).then(response => response.data.data);
}
