import { useOptimisticMutation } from '@lemonade-hq/blender-ui';
import type { PendingReason } from '@lemonade-hq/bluiza';
import { TaskStatus } from '@lemonade-hq/bluiza';
import type { UseBaseMutationResult, UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { castDraft, produce } from 'immer';
import type { HttpError } from '../../Commons/HttpUtils';
import { PetMedicalExamReviewQueries } from './PetMedicalExamReviewQueries';
import { addCoOwner, reopenTask } from 'apis/Pet/PetAPI';
import { deleteAttachment } from 'apis/Pet/PetAttachmentsAPI';
import type {
    ClaimReceiptData,
    ItemReviewData,
    PetBlenderClaimResponse,
    RiskAssessmentKeys,
    UpdateClaimGrouped,
    UpdateMissingInfoEmailParams,
} from 'apis/Pet/PetBlenderClaimAPI';
import {
    ClaimResponseKeys,
    fetchClaimData,
    markHasGroupedItems,
    missingInfoEmailOptOut,
    optOut,
    startHandlingClaim,
    updateClaimCoOwner,
    updateClaimDetails,
    updateClaimReceipt,
    updateMissingInfoEmail,
    updateItemReviewData,
    updateRiskAssessment,
} from 'apis/Pet/PetBlenderClaimAPI';
import type {
    CreateDocumentRequestGroup,
    DocumentRequestGroupDTO,
    UpdateDocumentRequestsBody,
} from 'apis/Pet/PetBlenderDocumentRequestAPI';
import { createDocumentRequests, updateDocumentRequest } from 'apis/Pet/PetBlenderDocumentRequestAPI';
import { dismiss, markAsDone, reassignUpdateOpenMissingInfoTasks } from 'apis/Pet/PetBlenderTaskAPI';
import { getAvailableClaimProcedures } from 'apis/Pet/PetClaimsAPI';
import { deleteVetNote, getClaimVetNotes, updateVetNoteDescription } from 'apis/Pet/PetClaimVetNotesAPI';
import { assign, assignTaskToSquad, updateTask } from 'apis/WorkflowsV2API';
import { findTask } from 'components/Bluis/Workflows/utils';
import type { PetClaimProcedures, PetFeatureType } from 'models/PetFeaturesAndItems';
import type { VetNote } from 'models/PetVetNotes';
import { usePessimisticMutation } from 'queries/MutationHooks';

export enum ClaimQueryKeys {
    GetPet = 'GET_PET',
    GetMedicalRecord = 'GET_MEDICAL_RECORD',
    GetPetMedicalReview = 'GET_PET_MEDICAL_REVIEW',
    GetPolicy = 'GET_POLICY',
    GetWorkflowAvailable = 'GET_WORKFLOW_AVAILABLE',
    GetPetClaimWorkflowTasks = 'GET_PET_CLAIM_WORKFLOW_TASKS',
    GetPetClaimWorkflowGroups = 'GET_PET_CLAIM_WORKFLOW_GROUPS',
    GetWorkflowsNote = 'GET_WORKFLOWS_NOTE',
    GetPreapprovalPolicyDetails = 'GET_PREAPPROVAL_POLICY_DETAILS',
    GetUserDetails = 'GET_USER_DETAILS',
    GetPetWorkflowsHomeTaskData = 'GET_WORKFLOWS_HOME_TASK',
    GetPetWorkflowsClaimHistoryData = 'GET_WORKFLOWS_CLAIM_HISTORY_TASK',
    GetPetClaimHistoryData = 'GET_CLAIM_HISTORY_DATA',
    GetMedia = 'GET_CLAIM_MEDIA',
    GetPetClaimCoveragesTaskData = 'GET_PET_CLAIM_COVERAGES_TASK_DATA',
    GetPetClaimCoverages = 'GET_PET_CLAIM_COVERAGES',
    GetPetClaimTags = 'GET_PET_CLAIM_TAGS',
    GetPetClaimEligibility = 'GET_PET_CLAIM_ELIGIBILITY',
    GetPetClaimEvents = 'GET_PET_CLAIM_EVENTS',
    GetVerifyInvoiceTaskData = 'GET_VERIFY_INVOICE_DATA',
    GetReviewDocumentsTaskData = 'GET_REVIEW_DOCUMENTS_DATA',
    GetReviewMedicalRecordsTaskData = 'GET_REVIEW_MEDICAL_RECORDS_DATA',
    GetConfirmProofOfPayment = 'GET_CONFIRM_PROOF_OF_PAYMENT',
    GetReviewClaimItemsData = 'GET_REVIEW_CLAIM_ITEMS_DATA',
    GetPetClaimAutoItemizedItems = 'GET_PET_CLAIM_AUTO_ITEMIZED_ITEMS',
    GetPetClaimAutoItemizedInvoices = 'GET_PET_CLAIM_AUTO_ITEMIZED_INVOICES',
    GetPetClaimMath = 'GET_PET_CLAIM_MATH',
    GetClaimAutomatedEobQualifications = 'GET_CLAIM_AUTOMATED_EOB_QUALIFICATIONS',
    GetClaim = 'GET_CLAIM',
    GetClaimActions = 'GET_CLAIM_ACTIONS',
    GetPreApproval = 'GET_PRE_APPROVAL',
    GetClaimItemsPaymentTaskData = 'GET_CLAIM_ITEMS_PAYMENT_DATA',
    GetPetValidateInvoiceDetailsTaskData = 'GET_PET_VALIDATE_INVOICE_DETAILS',
    GetValidateClaimConditionQuestions = 'GET_VALIDATE_CLAIM_CONDITION_FLAGS_QUESTIONS',
    GetReviewClaimItemsQuestions = 'GET_REVIEW_CLAIM_ITEMS_FLAGS_QUESTIONS',
    GetClaimMedicalConditionRiskFlags = 'GET_CLAIM_MEDICAL_CONDITION_RISK_FLAGS',
    GetValidateClaimConditionsOptOutFlags = 'GET_VALIDATE_CLAIM_CONDITIONS_OPT_OUT_FLAGS',
    GetClaimReceiptData = 'GetClaimReceiptData',
    GetPetClaimTasks = 'GET_PET_CLAIM_TASKS',
    GetPetClaimSummary = 'GET_PET_CLAIM_SUMMARY',
    GetRiskAssessmentAdditionalTaskData = 'GET_RISK_ASSESSMENT_TASK_DATA',
    GetRiskAssessmentData = 'GET_RISK_ASSESSMENT_DATA',
    GetDocumentRequestData = 'GET_DOCUMENT_REQUEST_DATA',
    GetDocumentRequestClaimData = 'GET_DOCUMENT_REQUEST_CLAIM_DATA',
    GetPreExistingConditions = 'GET_PRE_EXISTING_CONDITIONS',
    GetClaimVetNotes = 'GET_CLAIM_VET_NOTES',
    GetPetEmailTask = 'GET_PET_EMAIL_TASK',
    GetClaimWithPolicyDetails = 'GET_CLAIM_WITH_POLICY_DETAILS',
    GetClaimAssignee = 'GET_CLAIM_ASSIGNEE',
    GetClaimLifecycle = 'GET_CLAIM_LIFECYCLE',
    GetPossibleClaimProcedures = 'GET_POSSIBLE_CLAIM_PROCEDURES',
    GetVetsNotesAdditionalData = 'GET_VETS_NOTES_ADDITIONAL_DATA',
    GetPetProfileData = 'GET_PET_PROFILE_DATA',
    getClaimDocsUploadFlowUrl = 'GET_CLAIM_DOCS_UPLOAD_FLOW_URL',
    GetPetInstantClaimLogs = 'GET_PET_INSTANT_CLAIM_LOGS',
    GetPetChatLog = 'GET_PET_CHAT_LOG',
    GetPetReservesChanges = 'GET_PET_RESERVES_CHANGES',
    ClaimAutoHandlingResult = 'CLAIM_AUTO_HANDLING_RESULT',
    GetPolicyEligibilities = 'GET_POLICY_ELIGIBILITIES',
    GetPreventativeMedicalProcedures = 'GET_PREVENTATIVE_MEDICAL_PROCEDURES',
    GetClaimUnderwritingTool = 'GET_CLAIM_UNDERWRITING_TOOL',
    GetRejectionReasonsData = 'GET_REJECTION_REASONS_DATA',
    GetClaimRejectionReasonsPreview = 'GET_CLAIM_REJECTION_REASONS_PREVIEW',
    GetClaimSettleData = 'GET_CLAIM_SETTLE_DATA',
    GetPolicesEffectiveDates = 'GET_POLICIES_EFFECTIVE_DATES',
    GetAuthorityRequestOptions = 'GET_AUTHORITY_REQUEST_OPTIONS',
    GetPetMedicalRecordsCompleteness = 'GET_PET_MEDICAL_RECORDS_COMPLETENESS',
    GetMissingInfoAutoEmail = 'GET_MISSING_INFO_AUTO_EMAIL',
    GetFinalSummaryParams = 'GET_FINAL_SUMMARY_PARAMS',
    GetClaimItemReviewData = 'GET_CLAIM_ITEM_REVIEW_DATA',
}

export type PetClaimCoveragesCoveragesData = Pick<
    PetBlenderClaimResponse,
    ClaimResponseKeys.ClaimCoverages | ClaimResponseKeys.ClaimEligibility | ClaimResponseKeys.Policy
>;

export type PetWorkflowsHomeTaskData = Pick<
    PetBlenderClaimResponse,
    | ClaimResponseKeys.Claim
    | ClaimResponseKeys.ClaimAssignee
    | ClaimResponseKeys.ClaimEligibility
    | ClaimResponseKeys.ClaimEvents
    | ClaimResponseKeys.ClaimHistory
    | ClaimResponseKeys.ClaimMedia
    | ClaimResponseKeys.ClaimMedicalConditions
    | ClaimResponseKeys.ClaimOverview
    | ClaimResponseKeys.ClaimReceipt
    | ClaimResponseKeys.ClaimReceiptAttachments
    | ClaimResponseKeys.ClaimTags
    | ClaimResponseKeys.ConditionsPredictionExecution
    | ClaimResponseKeys.Policy
    | ClaimResponseKeys.PolicyDetails
    | ClaimResponseKeys.PolicyEligibilities
    | ClaimResponseKeys.UserDetails
>;

export type PetWorkflowsClaimAssignee = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimAssignee>;
type PetClaimData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.Claim>;
type PetClaimActionsData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.Actions>;
type PetPreapprovals = Pick<PetBlenderClaimResponse, ClaimResponseKeys.Preapprovals>;
type PetPolicyData = Pick<PetBlenderClaimResponse, 'policy'>;
type PetClaimTagsData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimTags>;
type PetClaimEligibilityData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimEligibility>;
type PetBlenderClaimEvents = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimEvents>;
type PetWorkflowAvailableData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.WorkflowAvailable>;
export type PetMedicalExam = Pick<PetBlenderClaimResponse, ClaimResponseKeys.MedicalRecord>;
export type PetMedicalReview = Pick<PetBlenderClaimResponse, ClaimResponseKeys.PetMedicalReview>;
export type PetWorkflowData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.Workflow>;
type PetWorkflowsNoteData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.WorkflowsNoteId>;
type VerifyInvoiceTaskData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.Claim | ClaimResponseKeys.UserDetails>;
type DocumentReviewTaskData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimDocumentReview>;
type MedicalRecordReviewTaskData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.MedicalRecordReview>;
type PetWorkflowsMedia = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimMedia | ClaimResponseKeys.PolicyDetails>;
type ClaimData = Pick<
    PetBlenderClaimResponse,
    ClaimResponseKeys.Claim | ClaimResponseKeys.Pet | ClaimResponseKeys.PolicyDetails | ClaimResponseKeys.UserDetails
>;
type DocumentRequestsData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.DocumentRequests>;
type PreExistingConditionsData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.PreExistingConditions>;
export type PetClaimHistoryData = Pick<
    PetBlenderClaimResponse,
    ClaimResponseKeys.ClaimHistory | ClaimResponseKeys.PolicyDetails
>;
type ConfirmProofOfPaymentData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimReceipt>;
type ReviewClaimItemsData = Pick<
    PetBlenderClaimResponse,
    ClaimResponseKeys.Claim | ClaimResponseKeys.ClaimFeatures | ClaimResponseKeys.PolicyBaseCoverage
>;
type ClaimItemsPaymentTaskData = Pick<
    PetBlenderClaimResponse,
    | ClaimResponseKeys.ClaimFeatures
    | ClaimResponseKeys.MultiFeaturePaymentControlAlert
    | ClaimResponseKeys.OpenPaymentRequestsAlert
>;
type PetClaimSummary = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimSummary>;
type EmailTask = Pick<PetBlenderClaimResponse, ClaimResponseKeys.EmailTask>;

type RiskAssessmentData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.Claim | ClaimResponseKeys.RiskAssessment>;

type PetData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.Pet>;

type ValidateClaimConditionQuestions = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ValidateClaimConditionQuestions>;

type ReviewClaimItemsQuestions = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ReviewClaimItemsQuestions>;

type ClaimMedicalConditionRiskFlags = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimMedicalConditionRiskFlags>;

type ValidateClaimConditionsOptOutFlags = Pick<
    PetBlenderClaimResponse,
    ClaimResponseKeys.ValidateClaimConditionsOptOutFlags
>;

type RiskAssessmentAdditionalTaskData = Pick<
    PetBlenderClaimResponse,
    | ClaimResponseKeys.Claim
    | ClaimResponseKeys.ClaimPet
    | ClaimResponseKeys.MedicalRecord
    | ClaimResponseKeys.PetMedicalReview
    | ClaimResponseKeys.PoliciesEffectiveDates
    | ClaimResponseKeys.Policy
    | ClaimResponseKeys.PolicyDetails
>;

type VetNotesAdditionalData = Pick<
    PetBlenderClaimResponse,
    ClaimResponseKeys.ClaimPet | ClaimResponseKeys.PolicyDetails
>;

export type PetValidateInvoiceDetailsTaskData = Pick<
    PetBlenderClaimResponse,
    | ClaimResponseKeys.Claim
    | ClaimResponseKeys.ClaimPet
    | ClaimResponseKeys.Policy
    | ClaimResponseKeys.PolicyDetails
    | ClaimResponseKeys.UserDetails
>;
type ClaimReceipt = Pick<
    PetBlenderClaimResponse,
    ClaimResponseKeys.ClaimReceipt | ClaimResponseKeys.ClaimReceiptAttachments
>;
type PetClaimWithPolicyData = Pick<
    PetBlenderClaimResponse,
    ClaimResponseKeys.Claim | ClaimResponseKeys.Policy | ClaimResponseKeys.PolicyDetails
>;

type PetClaimLifecycle = Pick<
    PetBlenderClaimResponse,
    ClaimResponseKeys.Claim | ClaimResponseKeys.ClaimLifecycle | ClaimResponseKeys.Policy
>;

type PetClaimAutoItemizedItems = Pick<PetBlenderClaimResponse, ClaimResponseKeys.AutoItemizedItems>;
type PetClaimAutoItemizedInvoices = Pick<PetBlenderClaimResponse, ClaimResponseKeys.AutoItemizedInvoices>;

export type PetClaimAutomatedEobQualifications = Pick<
    PetBlenderClaimResponse,
    ClaimResponseKeys.AutomatedEobQualifications
>;

export type PetClaimUnderwritingTool = Pick<
    PetBlenderClaimResponse,
    ClaimResponseKeys.PetMrrProcessStatus | ClaimResponseKeys.PetWaitingPeriodWaiverTool
>;

type ClaimSettleData = Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimSettlementData>;

export function useClaim(claimPublicId: string): UseQueryResult<PetClaimData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaim, claimPublicId],
        queryFn: async () => await fetchClaimData(claimPublicId, [ClaimResponseKeys.Claim]),
    });
}

export function useClaimForMacros(claimPublicId: string): UseQueryResult<PetClaimData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaim, claimPublicId],

        queryFn: async () =>
            await fetchClaimData(claimPublicId, [
                ClaimResponseKeys.Claim,
                ClaimResponseKeys.Policy,
                ClaimResponseKeys.UserDetails,
            ]),
    });
}

export function useClaimWithPolicyDetails(claimPublicId: string): UseQueryResult<PetClaimWithPolicyData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimWithPolicyDetails, claimPublicId],

        queryFn: async () =>
            await fetchClaimData(claimPublicId, [
                ClaimResponseKeys.Claim,
                ClaimResponseKeys.Policy,
                ClaimResponseKeys.PolicyDetails,
            ]),
    });
}

export function useClaimLifecycle(claimPublicId: string): UseQueryResult<PetClaimLifecycle> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimLifecycle, claimPublicId],

        queryFn: async () =>
            await fetchClaimData(claimPublicId, [
                ClaimResponseKeys.Claim,
                ClaimResponseKeys.Policy,
                ClaimResponseKeys.ClaimLifecycle,
            ]),
    });
}

export function useClaimActions(claimPublicId: string, enabled = true): UseQueryResult<PetClaimActionsData, HttpError> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimActions, claimPublicId],
        queryFn: async () => await fetchClaimData(claimPublicId, [ClaimResponseKeys.Actions]),
        enabled,
    });
}

export function usePreapprovals(claimPublicId: string): UseQueryResult<PetPreapprovals, HttpError> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPreApproval, claimPublicId],
        queryFn: async () => await fetchClaimData(claimPublicId, [ClaimResponseKeys.Preapprovals]),
    });
}

export function useGetMedicalExam(claimPublicId: string): UseQueryResult<PetMedicalExam> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetMedicalRecord, claimPublicId],
        queryFn: async () => await fetchMedicalExam(claimPublicId),
    });
}

async function fetchMedicalExam(claimPublicId: string): Promise<PetMedicalExam> {
    return await fetchClaimData(claimPublicId, [ClaimResponseKeys.MedicalRecord]);
}

export function useGetPetMedicalReview(claimPublicId: string): UseQueryResult<PetMedicalReview> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetMedicalReview, claimPublicId],
        queryFn: async () => await fetchPetMedicalReview(claimPublicId),
    });
}

async function fetchPetMedicalReview(claimPublicId: string): Promise<PetMedicalReview> {
    return await fetchClaimData(claimPublicId, [ClaimResponseKeys.PetMedicalReview]);
}

export function usePetPolicy(claimPublicId: string): UseQueryResult<PetPolicyData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPolicy, claimPublicId],
        queryFn: async () => await fetchPolicy(claimPublicId),
    });
}

async function fetchPolicy(claimPublicId: string): Promise<PetPolicyData> {
    return await fetchClaimData(claimPublicId, [ClaimResponseKeys.Policy]);
}

export function useClaimTags(claimPublicId: string): UseQueryResult<PetClaimTagsData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetClaimTags, claimPublicId],
        queryFn: async () => await fetchTags(claimPublicId),
    });
}

async function fetchTags(claimPublicId: string): Promise<PetClaimTagsData> {
    return await fetchClaimData(claimPublicId, [ClaimResponseKeys.ClaimTags]);
}

export function useClaimEligibility(claimPublicId: string): UseQueryResult<PetClaimEligibilityData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetClaimEligibility, claimPublicId],
        queryFn: async () => await fetchEligibility(claimPublicId),
    });
}

async function fetchEligibility(claimPublicId: string): Promise<PetClaimEligibilityData> {
    return await fetchClaimData(claimPublicId, [ClaimResponseKeys.ClaimEligibility]);
}

export function useClaimEvents(claimPublicId: string): UseQueryResult<PetBlenderClaimEvents> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetClaimEvents, claimPublicId],
        queryFn: async () => await fetchEvents(claimPublicId),
    });
}

async function fetchEvents(claimPublicId: string): Promise<PetBlenderClaimEvents> {
    return await fetchClaimData(claimPublicId, [ClaimResponseKeys.ClaimEvents]);
}

export function useWorkflowAvailable(claimPublicId: string): UseQueryResult<PetWorkflowAvailableData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetWorkflowAvailable, claimPublicId],
        queryFn: async () => await fetchWorkflowAvailable(claimPublicId),
    });
}

async function fetchWorkflowAvailable(claimPublicId: string): Promise<PetWorkflowAvailableData> {
    return await fetchClaimData(claimPublicId, [ClaimResponseKeys.WorkflowAvailable]);
}

export function usePetClaimWorkflow(claimPublicId: string): UseQueryResult<PetWorkflowData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetClaimWorkflowTasks, claimPublicId],
        queryFn: async () => await getWorkflow(claimPublicId),
    });
}

async function getWorkflow(claimPublicId: string): Promise<PetWorkflowData> {
    const workflowData = await fetchClaimData(claimPublicId, [ClaimResponseKeys.Workflow]);

    return workflowData;
}

async function getWorkflowNote(claimId: string): Promise<PetWorkflowsNoteData> {
    return await fetchClaimData(claimId, [ClaimResponseKeys.WorkflowsNoteId]);
}

export function usePetClaimWorkflowNote(claimId: string): UseQueryResult<PetWorkflowsNoteData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetWorkflowsNote, claimId],
        queryFn: async () => await getWorkflowNote(claimId),
    });
}

async function getClaimMedia(claimId: string): Promise<PetWorkflowsMedia> {
    return await fetchClaimData(claimId, [ClaimResponseKeys.ClaimMedia, ClaimResponseKeys.PolicyDetails]);
}

export function usePetClaimMedia(claimId: string): UseQueryResult<PetWorkflowsMedia> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetMedia, claimId],
        queryFn: async () => await getClaimMedia(claimId),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
}

export function usePetWorkflowsHomeTaskData(claimId: string): UseQueryResult<PetWorkflowsHomeTaskData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetWorkflowsHomeTaskData, claimId],

        queryFn: async () => {
            return await fetchClaimData(claimId, [
                ClaimResponseKeys.UserDetails,
                ClaimResponseKeys.Policy,
                ClaimResponseKeys.PolicyDetails,
                ClaimResponseKeys.PolicyEligibilities,
                ClaimResponseKeys.Claim,
                ClaimResponseKeys.ClaimEvents,
                ClaimResponseKeys.ClaimTags,
                ClaimResponseKeys.ClaimEligibility,
                ClaimResponseKeys.ClaimMedia,
                ClaimResponseKeys.ClaimOverview,
                ClaimResponseKeys.ClaimAssignee,
                ClaimResponseKeys.ClaimMedicalConditions,
                ClaimResponseKeys.ClaimReceipt,
                ClaimResponseKeys.ConditionsPredictionExecution,
            ]);
        },
    });
}

export function usePetClaimAssignee(claimId: string): UseQueryResult<PetWorkflowsClaimAssignee> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimAssignee, claimId],

        queryFn: async () => {
            return await fetchClaimData(claimId, [ClaimResponseKeys.ClaimAssignee]);
        },
    });
}

export function usePetClaimCoverages(claimId: string): UseQueryResult<PetClaimCoveragesCoveragesData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetClaimCoveragesTaskData, claimId],

        queryFn: async () =>
            await fetchClaimData(claimId, [
                ClaimResponseKeys.ClaimCoverages,
                ClaimResponseKeys.Policy,
                ClaimResponseKeys.ClaimEligibility,
            ]),
    });
}

export function usePetClaimCoveragesOnly(
    claimId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimCoverages>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetClaimCoverages, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.ClaimCoverages]),
    });
}

export function usePetClaimAutoItemizedItems(claimId: string): UseQueryResult<PetClaimAutoItemizedItems> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetClaimAutoItemizedItems, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.AutoItemizedItems]),
    });
}

export function usePetClaimAutoItemizedInvoices(claimId: string): UseQueryResult<PetClaimAutoItemizedInvoices> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetClaimAutoItemizedInvoices, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.AutoItemizedInvoices]),
    });
}

export function usePetClaimAutomatedEobQualifications(
    claimId: string
): UseQueryResult<PetClaimAutomatedEobQualifications> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimAutomatedEobQualifications, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.AutomatedEobQualifications]),
    });
}

export function usePetClaimHistory(claimId: string): UseQueryResult<PetClaimHistoryData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetWorkflowsClaimHistoryData, claimId],

        queryFn: async () => {
            return await fetchClaimData(claimId, [ClaimResponseKeys.ClaimHistory, ClaimResponseKeys.PolicyDetails]);
        },
    });
}

export function usePetClaimHistoryOnly(claimId: string): UseQueryResult<PetClaimHistoryData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetClaimHistoryData, claimId],

        queryFn: async () => {
            return await fetchClaimData(claimId, [ClaimResponseKeys.ClaimHistory]);
        },
    });
}

export function useClaimReceiptData(claimId: string): UseQueryResult<ClaimReceipt> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimReceiptData, claimId],

        queryFn: async () =>
            await fetchClaimData(claimId, [ClaimResponseKeys.ClaimReceipt, ClaimResponseKeys.ClaimReceiptAttachments]),
    });
}

export function useVerifyInvoiceTaskData(claimId: string): UseQueryResult<VerifyInvoiceTaskData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetVerifyInvoiceTaskData, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.UserDetails, ClaimResponseKeys.Claim]),
    });
}

export function useReviewClaimItemsData(claimId: string): UseQueryResult<ReviewClaimItemsData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetReviewClaimItemsData, claimId],

        queryFn: async () =>
            await fetchClaimData(claimId, [
                ClaimResponseKeys.ClaimFeatures,
                ClaimResponseKeys.PolicyBaseCoverage,
                ClaimResponseKeys.Claim,
            ]),
    });
}

export function useClaimItemsPaymentTaskData(claimId: string): UseQueryResult<ClaimItemsPaymentTaskData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimItemsPaymentTaskData, claimId],

        queryFn: async () =>
            await fetchClaimData(claimId, [
                ClaimResponseKeys.ClaimFeatures,
                ClaimResponseKeys.MultiFeaturePaymentControlAlert,
                ClaimResponseKeys.OpenPaymentRequestsAlert,
            ]),
    });
}

export function useClaimSettleData(claimId: string): UseQueryResult<ClaimSettleData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimSettleData, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.ClaimSettlementData]),
    });
}

export function useReviewDocumentsTaskData(
    claimId: string,
    taskReferenceId: string | undefined,
    onSuccess: (data: DocumentReviewTaskData) => void
): UseQueryResult<DocumentReviewTaskData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetReviewDocumentsTaskData, claimId, taskReferenceId],
        queryFn: async () => {
            const response = await fetchClaimData(claimId, [ClaimResponseKeys.ClaimDocumentReview], taskReferenceId);
            onSuccess(response);
            return response;
        },
    });
}

export function useReviewMedicalRecordsTaskData(
    claimId: string,
    onSuccess: (data: MedicalRecordReviewTaskData) => void
): UseQueryResult<MedicalRecordReviewTaskData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetReviewMedicalRecordsTaskData, claimId],
        queryFn: async () => {
            const response = await fetchClaimData(claimId, [ClaimResponseKeys.MedicalRecordReview]);
            onSuccess(response);
            return response;
        },
    });
}

export function usePetEmailTask(
    claimPublicId: string,
    taskReferenceId: string | null | undefined,
    onSuccess: (data: EmailTask) => void
): UseQueryResult<EmailTask> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetEmailTask, claimPublicId, taskReferenceId],
        queryFn: async () => {
            const response = await fetchClaimData(claimPublicId, [ClaimResponseKeys.EmailTask], taskReferenceId);
            onSuccess(response);
            return response;
        },
    });
}

export function useUpdateClaimReceipt(claimId: string) {
    const queryKey = [ClaimQueryKeys.GetClaimReceiptData, claimId];

    function mutateInvoiceTaskData({
        data,
        variables,
    }: {
        data: ClaimReceipt;
        variables: {
            claimPublicId: string;
            markedAsAdequate?: boolean | null;
            hasProofOfPayment?: boolean | null;
            matchesClaimDetails?: boolean | null;
            userEstimatedCost?: string | null;
        };
    }) {
        return produce(data, draft => {
            Object.keys(variables).forEach(key => {
                if (variables[key] !== undefined) {
                    draft.claim_receipt[key] = variables[key];
                }
            });
        });
    }

    const args = {
        mutationFn: updateClaimReceipt,
        mutate: mutateInvoiceTaskData,
        mutateKey: [queryKey],
        invalidateKeys: [queryKey],
    };

    return useOptimisticMutation(args);
}

export function useDeleteInvoiceAttachment(claimId: string) {
    const args = {
        mutationFn: deleteAttachment,
        invalidateKeys: [[ClaimQueryKeys.GetClaimReceiptData, claimId]],
    };

    return usePessimisticMutation(args);
}

// Document Requests

export function useDocumentRequestsClaimData(claimId: string): UseQueryResult<ClaimData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetDocumentRequestClaimData, claimId],

        queryFn: async () =>
            await fetchClaimData(claimId, [
                ClaimResponseKeys.Claim,
                ClaimResponseKeys.PolicyDetails,
                ClaimResponseKeys.UserDetails,
                ClaimResponseKeys.Pet,
            ]),
    });
}

export function useDocumentRequestsData(
    claimId: string,
    taskReferenceId: string | undefined
): UseQueryResult<DocumentRequestsData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetDocumentRequestData, claimId, taskReferenceId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.DocumentRequests], taskReferenceId),
    });
}

export function useCreateDocumentRequests(
    claimId: string
): UseMutationResult<DocumentRequestGroupDTO, unknown, CreateDocumentRequestGroup, null> {
    return usePessimisticMutation({
        mutationFn: createDocumentRequests,
        invalidateKeys: [[ClaimQueryKeys.GetPetClaimWorkflowTasks, claimId]],
    });
}

export function useUpdateDocumentRequests(claimId: string): UseMutationResult<
    void,
    unknown,
    {
        readonly documentRequestId: string;
        readonly updateData: UpdateDocumentRequestsBody;
    },
    null
> {
    return usePessimisticMutation({
        mutationFn: updateDocumentRequest,
        invalidateKeys: [[ClaimQueryKeys.GetDocumentRequestData, claimId]],
    });
}

export function usePreExistingCondition(claimId: string): UseQueryResult<PreExistingConditionsData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPreExistingConditions, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.PreExistingConditions]),
    });
}

// Tasks

export function useMarkTaskDoneMutation(claimPublicId: string) {
    const args = {
        mutationFn: markAsDone,
        invalidateKeys: [[ClaimQueryKeys.GetPetClaimWorkflowTasks, claimPublicId]],
    };

    return usePessimisticMutation(args);
}

export function useProofOfPaymentConfirmationData(claimId: string): UseQueryResult<ConfirmProofOfPaymentData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetConfirmProofOfPayment, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.ClaimReceipt]),
    });
}

export function useUpdateConfirmProofOfPayment(claimId: string) {
    const queryKey = [ClaimQueryKeys.GetConfirmProofOfPayment, claimId];

    function mutateInvoiceTaskData({
        data,
        variables,
    }: {
        data: ClaimReceiptData;
        variables: {
            claimPublicId: string;
            hasProofOfPayment?: boolean | null | undefined;
        };
    }) {
        return produce(data, draft => {
            draft.hasProofOfPayment = variables.hasProofOfPayment;
        });
    }

    const args = {
        mutationFn: updateClaimReceipt,
        mutate: mutateInvoiceTaskData,
        mutateKey: [queryKey],
        invalidateKeys: [queryKey],
    };

    return useOptimisticMutation(args);
}

export function useMarkItemsGrouped(claimPublicId: string): UseBaseMutationResult<null, unknown, UpdateClaimGrouped> {
    const queryKey = [ClaimQueryKeys.GetClaim, claimPublicId];

    function updateCachedClaimGrouping({ data, variables }: { data: PetClaimData; variables: UpdateClaimGrouped }) {
        const { hasGroupedItems } = variables;

        return produce(data, draft => {
            draft.claim.grouping = hasGroupedItems;
        });
    }

    const args = {
        mutationFn: markHasGroupedItems,
        mutate: updateCachedClaimGrouping,
        mutateKey: [queryKey],
        invalidateKeys: [queryKey],
    };

    return useOptimisticMutation(args);
}

export function useStartHandlingMutation(
    claimPublicId: string
): UseBaseMutationResult<null, unknown, { readonly claimPublicId: string; readonly operatorId: string }, null> {
    const args = {
        mutationFn: startHandlingClaim,
        invalidateKeys: [
            [ClaimQueryKeys.GetPetWorkflowsHomeTaskData, ClaimQueryKeys.GetPetClaimWorkflowTasks, claimPublicId],
        ],
    };

    return usePessimisticMutation(args);
}

export function useAssignTaskMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly taskId: string;
        readonly operatorId: string | null;
    },
    null
> {
    const args = {
        mutationFn: assign,
        invalidateKeys: [[ClaimQueryKeys.GetPetClaimWorkflowTasks, claimId]],
    };

    return usePessimisticMutation(args);
}

export function useAssignTaskToSquadMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly taskPublicId: string;
        readonly squad: string;
    },
    null
> {
    const args = {
        mutationFn: assignTaskToSquad,
        invalidateKeys: [
            [ClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
            [ClaimQueryKeys.GetClaimAssignee, claimId],
        ],
    };

    return usePessimisticMutation(args);
}

export function useActionDateTaskMutation(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly taskId: string;
        readonly actionDate?: string;
        readonly status?: TaskStatus;
        readonly pendingReason?: PendingReason;
    },
    PetWorkflowData
> {
    const queryKey = [ClaimQueryKeys.GetPetClaimWorkflowTasks, claimId];

    function mutateTasks({
        data,
        variables,
    }: {
        data: PetWorkflowData;
        variables: { taskId: string; actionDate?: string; status?: TaskStatus; pendingReason?: PendingReason };
    }) {
        const { taskId, actionDate, status, pendingReason } = variables;

        return produce(data, draft => {
            const task = castDraft(findTask(draft.claim_workflows.segments.flat(), task => task.publicId === taskId));

            if (task) {
                task.task.task.actionDate = actionDate;
                task.task.task.overdue = false;

                if (status != null) {
                    task.task.task.status = status;
                    task.task.task.pendingReason = pendingReason;
                }
            }
        });
    }

    const args = {
        mutationFn: updateTask,
        mutate: mutateTasks,
        mutateKey: [queryKey],
        invalidateKeys: [queryKey],
    };

    return useOptimisticMutation(args);
}

export function useReopenTaskMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimPublicId: string; readonly taskId: string }, PetWorkflowData> {
    const queryKey = [ClaimQueryKeys.GetPetClaimWorkflowTasks, claimId];

    function mutateTasks({ data, variables }: { data: PetWorkflowData; variables: { taskId: string } }) {
        const { taskId } = variables;

        return produce(data, draft => {
            const task = castDraft(findTask(draft.claim_workflows.segments.flat(), task => task.publicId === taskId));

            if (task) task.task.task.status = TaskStatus.Open;
        });
    }

    const args = {
        mutationFn: reopenTask,
        mutate: mutateTasks,
        mutateKey: [queryKey],
        invalidateKeys: [queryKey],
    };

    return useOptimisticMutation(args);
}

export function usePetClaimSummary(
    claimPublicId: string,
    taskReferenceId: string | undefined,
    onSuccess: (data: PetClaimSummary) => void
): UseQueryResult<PetClaimSummary> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetClaimSummary, claimPublicId, taskReferenceId],
        queryFn: async () => {
            const response = await fetchClaimData(claimPublicId, [ClaimResponseKeys.ClaimSummary], taskReferenceId);

            onSuccess(response);
            return response;
        },
        refetchOnWindowFocus: false,
    });
}

export function usePetValidateInvoiceDetailsTaskData(
    claimId: string
): UseQueryResult<PetValidateInvoiceDetailsTaskData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetValidateInvoiceDetailsTaskData, claimId],

        queryFn: async () => {
            return await fetchClaimData(claimId, [
                ClaimResponseKeys.Claim,
                ClaimResponseKeys.Policy,
                ClaimResponseKeys.ClaimPet,
                ClaimResponseKeys.UserDetails,
                ClaimResponseKeys.PolicyDetails,
            ]);
        },
    });
}

export function useAddCoOwnerMutation(claimPublicId: string) {
    const args = {
        mutationFn: addCoOwner,
        invalidateKeys: [[ClaimQueryKeys.GetPetValidateInvoiceDetailsTaskData, claimPublicId]],
    };

    return usePessimisticMutation(args);
}

export function useUpdateClaimCoOwnerMutation(claimPublicId: string) {
    const queryKey = [ClaimQueryKeys.GetPetValidateInvoiceDetailsTaskData, claimPublicId];

    function mutateClaimCoOwner({
        data,
        variables,
    }: {
        data: PetValidateInvoiceDetailsTaskData;
        variables: {
            coOwnerId: string | null;
        };
    }) {
        return produce(data, draft => {
            draft.claim.coOwnerPublicId = variables.coOwnerId ?? undefined;
        });
    }

    const args = {
        mutationFn: updateClaimCoOwner,
        mutate: mutateClaimCoOwner,
        mutateKey: [queryKey],
        invalidateKeys: [queryKey],
    };

    return useOptimisticMutation(args);
}

export function useRiskAssessmentData(claimId: string): UseQueryResult<RiskAssessmentData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetRiskAssessmentData, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.Claim, ClaimResponseKeys.RiskAssessment]),
    });
}

export function usePetData(claimId: string): UseQueryResult<PetData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPet, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.Pet]),
    });
}

export function useValidateClaimConditionQuestions(claimId: string): UseQueryResult<ValidateClaimConditionQuestions> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetValidateClaimConditionQuestions, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.ValidateClaimConditionQuestions]),
    });
}

export function useReviewClaimItemsQuestions(claimId: string): UseQueryResult<ReviewClaimItemsQuestions> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetReviewClaimItemsQuestions, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.ReviewClaimItemsQuestions]),
    });
}

export function useMedicalConditionRiskFlags(claimId: string): UseQueryResult<ClaimMedicalConditionRiskFlags> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimMedicalConditionRiskFlags, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.ClaimMedicalConditionRiskFlags]),
    });
}

export function useOptOutFlags(claimId: string): UseQueryResult<ValidateClaimConditionsOptOutFlags> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetValidateClaimConditionsOptOutFlags, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.ValidateClaimConditionsOptOutFlags]),
    });
}

export function useVetNotesAdditionalData(claimId: string): UseQueryResult<VetNotesAdditionalData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetVetsNotesAdditionalData, claimId],
        queryFn: async () =>
            await fetchClaimData(claimId, [ClaimResponseKeys.PolicyDetails, ClaimResponseKeys.ClaimPet]),
    });
}

export function useRiskAssessmentAdditionalTaskData(claimId: string): UseQueryResult<RiskAssessmentAdditionalTaskData> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetRiskAssessmentAdditionalTaskData, claimId],

        queryFn: async () =>
            await fetchClaimData(claimId, [
                ClaimResponseKeys.Claim,
                ClaimResponseKeys.Policy,
                ClaimResponseKeys.PolicyDetails,
                ClaimResponseKeys.PoliciesEffectiveDates,
                ClaimResponseKeys.MedicalRecord,
                ClaimResponseKeys.PetMedicalReview,
                ClaimResponseKeys.ClaimPet,
            ]),
    });
}

export function useUpdateClaimDetailsMutation(claimPublicId: string) {
    const args = {
        mutationFn: updateClaimDetails,
        invalidateKeys: [
            [ClaimQueryKeys.GetPetValidateInvoiceDetailsTaskData, claimPublicId],
            [ClaimQueryKeys.GetRiskAssessmentAdditionalTaskData, claimPublicId],
            [ClaimQueryKeys.GetPetWorkflowsHomeTaskData, claimPublicId],
            [ClaimQueryKeys.GetWorkflowAvailable, claimPublicId],
        ],
    };

    return usePessimisticMutation(args);
}

export function useDismissTaskMutation(
    claimId: string
): UseBaseMutationResult<
    null,
    unknown,
    { readonly claimPublicId: string; readonly taskId: string; readonly dismissReason: string },
    PetWorkflowData
> {
    const queryKey = [ClaimQueryKeys.GetPetClaimWorkflowTasks, claimId];

    function mutateTasks({ data, variables }: { data: PetWorkflowData; variables: { taskId: string } }) {
        const { taskId } = variables;

        return produce(data, draft => {
            const task = castDraft(findTask(draft.claim_workflows.segments.flat(), task => task.publicId === taskId));

            if (task) task.task.task.status = TaskStatus.Dismissed;
        });
    }

    const args = {
        mutationFn: dismiss,
        mutate: mutateTasks,
        mutateKey: [queryKey],
        invalidateKeys: [queryKey],
    };

    return useOptimisticMutation(args);
}

export function useUpdateRiskAssessment(claimId: string) {
    const queryKey = [ClaimQueryKeys.GetRiskAssessmentData, claimId];

    function mutateRiskAssessment({
        data,
        variables,
    }: {
        data: RiskAssessmentData;
        variables: {
            claimPublicId: string;
            property: RiskAssessmentKeys;
            value: boolean;
        };
    }) {
        return produce(data, draft => {
            draft.risk_assessment[variables.property] = variables.value;
        });
    }

    const args = {
        mutationFn: updateRiskAssessment,
        mutate: mutateRiskAssessment,
        mutateKey: [queryKey],
        invalidateKeys: [queryKey, [ClaimQueryKeys.GetMissingInfoAutoEmail, claimId]],
    };

    return useOptimisticMutation(args);
}

export function useClaimVetNotes(claimPublicId: string) {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimVetNotes, claimPublicId],
        queryFn: async () => await getClaimVetNotes({ claimPublicId }),
    });
}

export function useUpdateVetNoteDescriptionMutation(petPublicId: string) {
    const queryKey = [PetMedicalExamReviewQueries.GetVetNotes, petPublicId];

    function mutateVetNote({
        data,
        variables,
    }: {
        data: VetNote[];
        variables: { filePublicId: string; claimPublicId: string; description: string };
    }) {
        const { filePublicId, description } = variables;

        return produce(data, draft => {
            const vetNote = draft.find(vetNote => vetNote.attachment.filePublicId === filePublicId);

            if (vetNote) vetNote.attachment.description = description;
        });
    }

    const args = {
        mutationFn: updateVetNoteDescription,
        mutate: mutateVetNote,
        mutateKey: [queryKey],
        invalidateKeys: [queryKey],
    };

    return useOptimisticMutation(args);
}

export function useRemoveVetNoteMutation(petPublicId: string) {
    const args = {
        mutationFn: deleteVetNote,
        invalidateKeys: [[PetMedicalExamReviewQueries.GetVetNotes, petPublicId]],
    };

    return usePessimisticMutation(args);
}

export function useRefreshClaimTasks(claimId: string): () => void {
    const cache = useQueryClient();

    return () => {
        cache.invalidateQueries({
            queryKey: [ClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
        });
    };
}

export function useAvailableClaimProcedures(
    claimId: string,
    featureType: PetFeatureType
): UseQueryResult<PetClaimProcedures> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPossibleClaimProcedures, claimId],
        queryFn: async () => await getAvailableClaimProcedures(claimId, featureType),
    });
}

export function useClaimDocsUploadFlowUrl(
    claimId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimDocsUploadFlowUrl>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.getClaimDocsUploadFlowUrl, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.ClaimDocsUploadFlowUrl]),
    });
}

export function useInstantClaimLogs(
    claimId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.AutoApproveLog>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetInstantClaimLogs, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.AutoApproveLog]),
    });
}

export function useChatLogs(
    claimId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimChatLog | ClaimResponseKeys.PolicyDetails>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetChatLog, claimId],
        queryFn: async () =>
            await fetchClaimData(claimId, [ClaimResponseKeys.ClaimChatLog, ClaimResponseKeys.PolicyDetails]),
    });
}

export function useReservesChanges(
    claimId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.ClaimCoverages | ClaimResponseKeys.ReservesChanges>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetReservesChanges, claimId],
        queryFn: async () =>
            await fetchClaimData(claimId, [ClaimResponseKeys.ReservesChanges, ClaimResponseKeys.ClaimCoverages]),
    });
}

export function usePetClaimMath(
    claimId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.Claim | ClaimResponseKeys.ClaimMath>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetClaimMath, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.ClaimMath, ClaimResponseKeys.Claim]),
    });
}

export function useGetPetAutoRiskAssessment(
    claimId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.AutoApproveLogV2>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetInstantClaimLogs, ClaimQueryKeys.ClaimAutoHandlingResult, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.AutoApproveLogV2]),
    });
}

export function useGetPreventativeMedicalProcedures(
    claimId: string,
    enabled = false
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.PreventativeMedicalProcedures>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPreventativeMedicalProcedures, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.PreventativeMedicalProcedures]),
        enabled,
    });
}

export function useGetPolicyEligibilities(
    claimId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.PolicyEligibilities>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPolicyEligibilities, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.PolicyEligibilities]),
    });
}

export function useGetFinalSummaryParams(
    claimPublicId: string
): UseQueryResult<
    Pick<
        PetBlenderClaimResponse,
        | ClaimResponseKeys.ClaimCoverages
        | ClaimResponseKeys.ClaimFeatures
        | ClaimResponseKeys.ClaimMedicalConditions
        | ClaimResponseKeys.EipdMacros
        | ClaimResponseKeys.ExpectedMetLimits
        | ClaimResponseKeys.RejectionReasons
    >
> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetFinalSummaryParams, claimPublicId],
        queryFn: async () =>
            await fetchClaimData(claimPublicId, [
                ClaimResponseKeys.ClaimMedicalConditions,
                ClaimResponseKeys.ClaimFeatures,
                ClaimResponseKeys.ClaimCoverages,
                ClaimResponseKeys.RejectionReasons,
                ClaimResponseKeys.EipdMacros,
                ClaimResponseKeys.ExpectedMetLimits,
            ]),
    });
}

export function useOptOutMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimPublicId: string; readonly reason: string }, null> {
    const args = {
        mutationFn: optOut,
        invalidateKeys: [[ClaimQueryKeys.GetPetClaimWorkflowTasks, claimId]],
    };

    return usePessimisticMutation(args);
}

export function useMissingInfoEmailOptOutMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimPublicId: string; readonly optOutReason: string }, null> {
    const args = {
        mutationFn: missingInfoEmailOptOut,
        invalidateKeys: [
            [ClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
            [ClaimQueryKeys.GetMissingInfoAutoEmail, claimId],
        ],
    };

    return usePessimisticMutation(args);
}

export function useMissingInfoEmailMutation(
    claimId: string
): UseBaseMutationResult<null, unknown, UpdateMissingInfoEmailParams, null> {
    const args = {
        mutationFn: updateMissingInfoEmail,
        invalidateKeys: [
            [ClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
            [ClaimQueryKeys.GetMissingInfoAutoEmail, claimId],
        ],
    };

    return usePessimisticMutation(args);
}

export function useReassignUpdateOpenMissingInfoTasks(
    claimId: string
): UseBaseMutationResult<null, unknown, { readonly claimPublicId: string; readonly taskPublicId: string }, null> {
    const args = {
        mutationFn: reassignUpdateOpenMissingInfoTasks,
        invalidateKeys: [
            [ClaimQueryKeys.GetPetClaimWorkflowTasks, claimId],
            [ClaimQueryKeys.GetMissingInfoAutoEmail, claimId],
        ],
    };

    return usePessimisticMutation(args);
}

export function usePolicyEligibilities(
    claimId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.PolicyEligibilities>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPolicyEligibilities, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.PolicyEligibilities]),
    });
}

export function useUnderWritingTool(claimId: string): UseQueryResult<PetClaimUnderwritingTool> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimUnderwritingTool, claimId],

        queryFn: async () =>
            await fetchClaimData(claimId, [
                ClaimResponseKeys.PetWaitingPeriodWaiverTool,
                ClaimResponseKeys.PetMrrProcessStatus,
            ]),
    });
}

export function useRejectionReasonsData(
    claimId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.RejectionReasons>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetRejectionReasonsData, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.RejectionReasons]),
    });
}

export function usePolicesEffectiveDates(
    claimId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.PoliciesEffectiveDates>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPolicesEffectiveDates, claimId],
        queryFn: async () => await fetchClaimData(claimId, [ClaimResponseKeys.PoliciesEffectiveDates]),
    });
}

export function useGetAuthorityRequestDetails(
    claimId: string,
    taskReferenceId: string
): UseQueryResult<
    Pick<
        PetBlenderClaimResponse,
        | ClaimResponseKeys.PaymentBreakdown
        | ClaimResponseKeys.PaymentControlPermissions
        | ClaimResponseKeys.PaymentRequest
        | ClaimResponseKeys.PaymentRequestDetails
    >
> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetAuthorityRequestOptions, claimId, taskReferenceId],

        queryFn: async () => {
            return await fetchClaimData(
                claimId,
                [
                    ClaimResponseKeys.PaymentRequestDetails,
                    ClaimResponseKeys.PaymentBreakdown,
                    ClaimResponseKeys.PaymentControlPermissions,
                    ClaimResponseKeys.PaymentRequest,
                ],
                taskReferenceId
            );
        },
    });
}

export function useGetPetMedicalRecordsCompleteness(
    claimPublicId: string
): UseQueryResult<
    Pick<
        PetBlenderClaimResponse,
        ClaimResponseKeys.PetMedicalRecordsCompleteness | ClaimResponseKeys.PetMedicalRecordsCompletenessHistory
    >
> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetPetMedicalRecordsCompleteness, claimPublicId],
        queryFn: async () =>
            await fetchClaimData(claimPublicId, [
                ClaimResponseKeys.PetMedicalRecordsCompleteness,
                ClaimResponseKeys.PetMedicalRecordsCompletenessHistory,
            ]),
    });
}

export function useMissingInfoAutoEmail(
    claimPublicId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, ClaimResponseKeys.MissingInfoAutoEmail>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetMissingInfoAutoEmail, claimPublicId],
        queryFn: async () => await fetchClaimData(claimPublicId, [ClaimResponseKeys.MissingInfoAutoEmail]),
    });
}
export function useGetItemReviewData(
    claimPublicId: string
): UseQueryResult<Pick<PetBlenderClaimResponse, 'item_review_task'>> {
    return useQuery({
        queryKey: [ClaimQueryKeys.GetClaimItemReviewData, claimPublicId],
        queryFn: async () => await fetchClaimData(claimPublicId, [ClaimResponseKeys.ItemReviewTask]),
    });
}

function mutateItemReviewData({
    data,
    variables,
}: {
    readonly data: ItemReviewData;
    readonly variables: {
        readonly additionalInformation?: string;
        readonly hasBiohazardOrTax?: boolean;
    };
}): ItemReviewData {
    return produce(data, draft => {
        draft.additionalInformation = variables.additionalInformation;
        draft.hasBiohazardOrTax = variables.hasBiohazardOrTax;
    });
}

export function useUpdateItemReviewData(claimId: string): UseBaseMutationResult<
    null,
    unknown,
    {
        readonly claimPublicId: string;
        readonly additionalInformation?: string;
        readonly hasBiohazardOrTax?: boolean;
    },
    ItemReviewData
> {
    const queryKey = [ClaimQueryKeys.GetClaimItemReviewData, claimId];

    const args = {
        mutationFn: updateItemReviewData,
        mutate: mutateItemReviewData,
        mutateKey: [queryKey],
        invalidateKeys: [queryKey],
    };

    return useOptimisticMutation(args);
}
