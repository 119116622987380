import { createContext } from 'react';
import { Locale } from '@lemonade-hq/lemonation';
import { HomeClaimPolicy } from 'models/HomeClaim';
import { Currency, FormatCurrency } from 'hooks/useCurrency';

export interface HomeClaimContext {
    currency: Currency | null;
    claimPublicId: string;
    formatCurrency: FormatCurrency;
    policy?: HomeClaimPolicy;
    reloadClaim: (claimId: string | number) => Promise<void>;
    locale: Locale;
}

export default createContext<HomeClaimContext>({
    currency: null,
    claimPublicId: '',
    formatCurrency: () => '',
    reloadClaim: async () => Promise.resolve(),
    locale: '' as Locale,
});
