import { LoadingSection, MultiSwitch } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import debounce from 'lodash/debounce';
import type { FC, MutableRefObject } from 'react';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { validateSimpleTransition } from '../../ExpressionSimpleEditor/expressionTransformers';
import { getAdditionalFunctions } from '../../ExpressionSimpleEditor/getAdditionalFunctions';
import { INPUT_OPERATORS } from '../../ExpressionSimpleEditor/operators';
import type { RuleBuilderRefMethods } from '../ManageRuleDialogContext';
import { useRegistryContext } from '../ManageRuleDialogContext';
import { SegmentRuleFreeTextEditor } from './SegmentRuleFreeTextEditor';
import { SegmentRuleSimpleEditor } from './SegmentRuleSimpleEditor';
import {
    isArrayField,
    isEnumField,
    isPrimitiveField,
    isRecordField,
} from 'components/LoCo/common/helpers/schemaHelpers';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import type { QuoteLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import { useGetLatestProductSchemaAttributes } from 'queries/LoCo/Insurance/ProductQueries';

const StyledSegmentSelection = styled(Flex)`
    padding: 40px 64px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    overflow: auto;
`;

const StyledEditorWrapper = styled(Flex)`
    width: 100%;
    min-height: 250px;
`;

const StyledMultiSwitch = styled(MultiSwitch)`
    height: 40px;
`;

interface SegmentSelectionProps {
    readonly renderExpressionRef: MutableRefObject<RuleBuilderRefMethods | null>;
    readonly expression: string | null;
    readonly lifecycleContexts?: QuoteLifecycleContext[];
    readonly setExpression: (expression: string) => void;
    readonly setSegmentFilterIsInvalid: (isInvalid: boolean) => void;
    readonly allowEmpty?: boolean;
    readonly showSelectedSettingsAndCoverages?: boolean;
}

export const SegmentFilterSelection: FC<SegmentSelectionProps> = ({
    renderExpressionRef,
    expression,
    lifecycleContexts,
    setExpression,
    setSegmentFilterIsInvalid,
    allowEmpty = true,
    showSelectedSettingsAndCoverages = true,
}) => {
    const product = useGetProductData();

    const { coverages, settings } = useRegistryContext();

    const [isFreeText, setIsFreeText] = useState(
        expression !== null && expression.length > 0
            ? !validateSimpleTransition(expression, [
                  'selectedCoverage',
                  'selectedSetting',
                  'previousSelectedCoverage',
                  'previousSelectedSetting',
              ]).valid
            : false
    );

    const [canTransitionFromSimpleToFreeText, setCanTransitionFromSimpleToFreeText] = useState(
        expression !== null ? validateSimpleTransition(expression).valid : true
    );

    const { data: productSchema, isError, isLoading } = useGetLatestProductSchemaAttributes(product.code, true);

    // show only supported field types
    const filteredSchema = useMemo(() => {
        if (isDefined(productSchema) && productSchema.schema.fields.length > 0) {
            productSchema.schema.fields = productSchema.schema.fields.filter(
                field => isPrimitiveField(field) || isEnumField(field) || isRecordField(field) || isArrayField(field)
            );
        }

        return productSchema;
    }, [productSchema]);

    const additionalFunctions = useMemo(
        () => getAdditionalFunctions(coverages, settings, lifecycleContexts ?? [], showSelectedSettingsAndCoverages),
        [coverages, settings, lifecycleContexts, showSelectedSettingsAndCoverages]
    );

    const onValidateExpression = debounce(
        useCallback(
            (isExpressionValid: boolean) => {
                if (!isExpressionValid) {
                    setSegmentFilterIsInvalid(true);
                    setCanTransitionFromSimpleToFreeText(false);
                    return;
                }

                if (renderExpressionRef.current == null) {
                    return;
                }

                const expressionValue = renderExpressionRef.current.get();
                if (expressionValue === '' && !allowEmpty) {
                    setSegmentFilterIsInvalid(true);
                    setCanTransitionFromSimpleToFreeText(true);
                    return;
                }

                setSegmentFilterIsInvalid(false);
                setCanTransitionFromSimpleToFreeText(
                    validateSimpleTransition(expressionValue, [
                        ...Object.keys(additionalFunctions),
                        ...Object.keys(INPUT_OPERATORS),
                    ]).valid
                );
            },
            [renderExpressionRef, allowEmpty, setSegmentFilterIsInvalid, additionalFunctions]
        ),
        100
    );

    if (isLoading) {
        return <LoadingSection />;
    }

    if (isError || filteredSchema === undefined)
        throw new Error('Failed to load product schema, please try again later');

    return (
        <StyledSegmentSelection>
            <StyledEditorWrapper>
                {isFreeText ? (
                    <SegmentRuleFreeTextEditor
                        additionalFunctions={additionalFunctions}
                        expression={expression ?? ''}
                        onValidation={onValidateExpression}
                        productSchema={filteredSchema}
                        renderExpressionRef={renderExpressionRef}
                    />
                ) : (
                    <SegmentRuleSimpleEditor
                        additionalFunctions={additionalFunctions}
                        allowEmpty={allowEmpty}
                        expression={expression ?? ''}
                        onValidation={onValidateExpression}
                        productSchema={filteredSchema}
                        renderExpressionRef={renderExpressionRef}
                    />
                )}
            </StyledEditorWrapper>
            <StyledMultiSwitch
                disabled={!canTransitionFromSimpleToFreeText}
                name="editor-switch"
                onSwitch={e => {
                    if (renderExpressionRef.current == null) {
                        throw new Error('Expression renderer is not set');
                    }

                    const newExpression = renderExpressionRef.current.get();
                    setExpression(newExpression);
                    setIsFreeText(e?.target.value === 'custom');
                }}
                options={[
                    { label: 'Simple', value: 'simple' },
                    { label: 'Code', value: 'custom' },
                ]}
                selected={isFreeText ? 1 : 0}
            />
        </StyledSegmentSelection>
    );
};
