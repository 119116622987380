import type { IconName, ThemeColor } from '@lemonade-hq/blender-ui';
import { Button, fullWidth, ListItemMenu, shimmering, spacing } from '@lemonade-hq/blender-ui';
import { Flex } from '@lemonade-hq/cdk';
import { DatetimeFormat, formatDatetime } from '@lemonade-hq/lemonation';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { VOICE_PATH_NAMESPACE, VOICE_SIMULATION_PATH } from '../Voice.Routes';
import type { VoiceConversation } from '../Voice.types';
import { VIEWED_CONVERSATIONS_LIST_KEY } from './VoiceConversation.consts';

export interface VoicePlaygroundConversationsListProps {
    readonly conversations: VoiceConversation[];
    readonly currentConversationPublicId?: string;
    readonly isLoading: boolean;
    readonly onChange: (conversationPublicId: string) => void;
}

export const VoiceConversationsList: FC<VoicePlaygroundConversationsListProps> = ({
    conversations,
    currentConversationPublicId,
    isLoading,
    onChange,
}) => {
    const [viewedConversationsPublicIds] = useLocalStorage<string[]>(VIEWED_CONVERSATIONS_LIST_KEY, []);

    const sections = useMemo(() => {
        const groupedByDate = Object.entries(groupBy(conversations, c => new Date(c.startedAt).toDateString()));
        const nonSortedSections = groupedByDate.map(([day, options]) => ({
            options: orderBy(options, 'startedAt', 'desc').map(conversation => ({
                icon: (conversation.simulation ? 'cube' : 'phone-solid') as IconName,
                iconColor: (viewedConversationsPublicIds.includes(conversation.publicId)
                    ? 'neutral7'
                    : 'brand1') as ThemeColor,
                id: conversation.publicId,
                label: conversation.publicId,
                sideLabel: formatDatetime(new Date(conversation.startedAt), {
                    datetimeFormat: DatetimeFormat.TimeDefault,
                    locale: 'en-GB',
                }),
            })),
            label: formatDatetime(new Date(day), {
                datetimeFormat: DatetimeFormat.DateDefault,
            }),
            sideLabel: `${options.length.toString()} ${pluralize('call', options.length)}`,
        }));
        return orderBy(nonSortedSections, 'label', 'desc');
    }, [conversations, viewedConversationsPublicIds]);

    return (
        <Flex flexDirection="column" gap={spacing.s16} overflowY="scroll">
            <Link to={`/backoffice/${VOICE_PATH_NAMESPACE}${VOICE_SIMULATION_PATH}`}>
                <Button
                    className={fullWidth}
                    label="Simulate Phone Call"
                    size="lg"
                    startIcon="plus-solid"
                    variant="secondary"
                />
            </Link>
            <ListItemMenu
                className={isLoading ? shimmering : undefined}
                onSelect={onChange}
                preventDeselection
                sections={sections}
                selection={currentConversationPublicId}
            />
        </Flex>
    );
};
