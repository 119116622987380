import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const whiteLabelRoutes: RegExp[] = [
    /\/backoffice\/pet\/claims/,
    /\/backoffice\/pet\/policies/,
    /\/backoffice\/car\/.+/,
    /\/backoffice\/life/,
    /\/backoffice\/search/,
    /\/backoffice\/homeowner_reviews/,
    /\/backoffice\/home\/quotes/,
    /\/backoffice\/home\/policies/,
    /\/backoffice\/users/,
    /\/backoffice\/blocked_addresses/,
    /\/backoffice\/snippets/,
    /\/backoffice\/causes/,
    /\/backoffice\/file_generator/,
    /\/backoffice\/performance_dashboard/,
    /\/backoffice\/loco/,
    /\/backoffice\/llmnd_agents/,
    /\/backoffice\/playground/,
    /\/backoffice\/attachments_gallery/,
    /\/backoffice\/new_temp_blocked_areas/,
    /\/backoffice\/watch_tower/,
    /\/backoffice\/?$/,
];

export default function useViewModeEnabled(): boolean {
    const location = useLocation();

    return useMemo(() => whiteLabelRoutes.some(w => w.test(location.pathname)), [location.pathname]);
}
