import { Flex } from '@lemonade-hq/blender-ui';
import { MultiSwitch } from '@lemonade-hq/bluis';
import type { CallExpression, Expression } from 'jsep';
import jsep from 'jsep';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ExpressionFreeTextEditor } from '../../../ExpressionFreeTextEditor/ExpressionFreeTextEditor';
import { ArgumentType } from '../../../ExpressionSimpleEditor/expressionTypes';
import type { InputFunction } from '../../../ExpressionSimpleEditor/operators';
import type { SchemaAutocompleteOption } from '../../../SchemaAutocomplete';
import { SchemaAutocomplete } from '../../../SchemaAutocomplete';
import type { FormStateReducerAction, ManageRuleFormState } from '../../ManageRuleDialogContext';
import { useManageRuleDialogContext } from '../../ManageRuleDialogContext';
import { freeTextWrapper } from './outcomes.css';
import { schemaToFieldsRecord } from 'components/LoCo/common/helpers/schemaHelpers';
import { RuleOutcomeType } from 'models/LoCo/Insurance/CoverageRule';
import type { Outcome } from 'models/LoCo/Insurance/CoverageRule';
import type { SchemaResponse } from 'models/LoCo/Insurance/Schema';

type AdditionalOptions = { readonly isMin?: boolean; readonly isMax?: boolean; readonly rangeIndex?: number };

type SelectOutcomeExpressionProps = {
    readonly productSchema: SchemaResponse;
    readonly dispatch: <T extends keyof ManageRuleFormState>(action: FormStateReducerAction<T>) => void;
    readonly outcome: Outcome;
    readonly additionalOptions?: AdditionalOptions;
    readonly allowFreeText?: boolean;
    readonly additionalFunctions: Record<string, InputFunction>;
};

const StyledMultiSwitch = styled(MultiSwitch)`
    height: 40px;
`;

export const SelectOutcomeExpression: React.FC<SelectOutcomeExpressionProps> = ({
    productSchema,
    dispatch,
    outcome,
    additionalOptions,
    allowFreeText,
    additionalFunctions,
}) => {
    const updateValue = updateOutcomeValue(outcome, dispatch, additionalOptions);
    const [isFreeText, setIsFreeText] = useState(false);
    const editorValueRef = useRef<string | null>(null);
    const { renderExpressionRef } = useManageRuleDialogContext();

    useEffect(() => {
        if (allowFreeText)
            renderExpressionRef.current = {
                get: () => {
                    if (editorValueRef.current === null) {
                        throw new Error('Rule builder is not set');
                    }

                    return editorValueRef.current;
                },
            };
    }, [allowFreeText, renderExpressionRef]);

    const onChange = (option: SchemaAutocompleteOption | null): void => {
        if (option === null) {
            updateValue('');
        } else if (option.type === 'value') {
            updateValue(option.value);
        } else {
            updateValue(`${option.value}('${option.argument}')`);
        }
    };

    const value = getOutcomeValue(outcome, additionalOptions);

    const autocomplete = (
        <SchemaAutocomplete
            additionalFunctions={additionalFunctions}
            expressionType={{ type: ArgumentType.Number }}
            onChange={onChange}
            productSchema={schemaToFieldsRecord(productSchema.schema.fields)}
            value={value}
        />
    );

    if (!allowFreeText) return autocomplete;

    return (
        <Flex className={freeTextWrapper}>
            {!isFreeText ? (
                autocomplete
            ) : (
                <ExpressionFreeTextEditor
                    additionalFunctions={additionalFunctions}
                    editorValueRef={editorValueRef}
                    expression={''}
                    onChange={updateValue}
                    productSchema={productSchema}
                />
            )}
            <StyledMultiSwitch
                name="editor-switch"
                onSwitch={e => {
                    updateValue('');
                    setIsFreeText(e?.target.value === 'custom');
                }}
                options={[
                    { label: 'Simple', value: 'simple' },
                    { label: 'Code', value: 'custom' },
                ]}
                selected={isFreeText ? 1 : 0}
            />
        </Flex>
    );
};

function updateOutcomeValue(
    outcome: Outcome,
    dispatch: <T extends keyof ManageRuleFormState>(action: FormStateReducerAction<T>) => void,
    additionalOptions?: AdditionalOptions
): (value: string) => void {
    return (newValue: string) => {
        const newOutcome = { ...outcome };
        if (newOutcome.type === RuleOutcomeType.SettingRangeValueOutcome) {
            if (additionalOptions?.isMin) {
                newOutcome.notBelow = newValue;
            } else if (additionalOptions?.isMax) {
                newOutcome.notAbove = newValue;
            }
        }

        if (newOutcome.type === RuleOutcomeType.SettingExactValuesOutcome) {
            newOutcome.values[additionalOptions?.rangeIndex ?? 0] = newValue;
        }

        if (newOutcome.type === RuleOutcomeType.SettingNearestToValueOutcome) {
            newOutcome.expression = newValue;
        }

        dispatch({
            type: 'outcome',
            value: newOutcome,
        });
    };
}

function getOutcomeValue(outcome: Outcome | null, additionalOptions?: AdditionalOptions): SchemaAutocompleteOption {
    if (outcome === null) return { value: '', type: 'value' };
    if (outcome.type === RuleOutcomeType.SettingDefaultValueOutcome) {
        return { value: outcome.value, type: 'value' };
    }

    if (outcome.type === RuleOutcomeType.SettingNearestToValueOutcome) {
        return extractFunction(outcome.expression);
    }

    if (outcome.type === RuleOutcomeType.SettingExactValuesOutcome) {
        return { value: outcome.values[additionalOptions?.rangeIndex ?? 0], type: 'value' };
    }

    if (outcome.type === RuleOutcomeType.SettingRangeValueOutcome) {
        return additionalOptions?.isMin ? extractFunction(outcome.notBelow) : extractFunction(outcome.notAbove);
    }

    return { value: '', type: 'value' };
}

function extractFunction(expression: string | undefined): SchemaAutocompleteOption {
    if (expression === undefined) return { value: '', type: 'value' };

    const translated = jsep(expression);

    if (!isExpressionIsFunction(translated)) {
        return { value: expression, type: 'value' };
    }

    return {
        value: translated.callee.name as string,
        argument: translated.arguments[0]?.value as string,
        type: 'function',
    };
}

function isExpressionIsFunction(expression: Expression): expression is CallExpression {
    return expression.type === 'CallExpression';
}
