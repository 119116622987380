import { SecondaryButton } from '@lemonade-hq/bluis';
import { font, themedColor } from '@lemonade-hq/boutique';
import { Flex } from '@lemonade-hq/cdk';
import React from 'react';
import styled from 'styled-components';
import type { Action } from 'bluis/ActionsMenu';
import ActionsMenu from 'bluis/ActionsMenu';

export type ActionsProp = Action[] | Omit<Action, 'name'>;

interface TitleProps {
    readonly title: JSX.Element | string;
    readonly subtitle?: string;
    readonly actions?: ActionsProp;
    readonly actionsTitle?: string;
    readonly className?: string;
    readonly onActionMenuOpen?: () => void;
    readonly space?: boolean;
}

export const TitleAlt = styled.h3`
    ${font('alt', { fontSize: '24px', lineHeight: '29px' })}
    font-weight: 400 !important;
    color: ${themedColor('primaryText')} !important;
    margin: 0 !important;
`;

export const Title = styled.h3`
    ${font('main', { fontSize: '24px', lineHeight: '29px', fontWeight: 'bold' })}
    color: ${themedColor('primaryText')} !important;
    margin: 0 !important;
`;

const Subtitle = styled.p``;

function renderActions({
    actions,
    actionsTitle = 'Actions',
    onActionMenuOpen,
}: {
    readonly actions: ActionsProp;
    readonly actionsTitle?: string;
    readonly onActionMenuOpen?: () => void;
}) {
    return Array.isArray(actions) ? (
        <ActionsMenu actions={actions} onOpen={onActionMenuOpen} text={actionsTitle} />
    ) : (
        <SecondaryButton disabled={actions.disabled} onClick={actions.onClick as () => void}>
            {actions.text}
        </SecondaryButton>
    );
}

/**
 * @deprecated
 * use ContentSection/ContentSectionTitle/ActionMenu from '@lemonade-hq/bluis' instead
 */
export const CommonSectionTitle: React.FC<React.PropsWithChildren<TitleProps>> = ({
    title,
    subtitle,
    actions,
    actionsTitle,
    className,
    children,
    onActionMenuOpen,
    space = true,
}) => (
    <Flex alignItems="center" className={className} justifyContent={space ? 'space-between' : 'unset'} pb={40}>
        <div>
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </div>
        {actions && renderActions({ actions, actionsTitle, onActionMenuOpen })}
        {children}
    </Flex>
);

export const CommonSection = styled.section`
    background: ${themedColor('componentBackground')};
    border: 1px solid ${themedColor('separator')};
    border-radius: 10px;
    padding: 40px 21px 29px 32px;

    &:not(:first-child) {
        margin-top: 28px;
    }
`;
