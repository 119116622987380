import type { UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { insuranceBlender } from '../../../apiClients';
import type { ArchiveEditionParams, CreateEditionParams } from 'models/LoCo/Insurance/BaseEdition';
import type {
    CoverageInstance,
    CoveragesEdition,
    EditionValidationsResponse,
} from 'models/LoCo/Insurance/CoveragesEdition';
import type { SettingInstancePayload } from 'models/LoCo/Insurance/SettingInstanceRequests';
import { usePessimisticMutation } from 'queries/MutationHooks';

const BASE_PATH = '/api/v1/coverages-editions';

export enum CoveragesEditionQueryKey {
    // Coverage editions
    GetCoveragesEdition = 'GET_COVERAGES_EDITION',
    CreateCoveragesEdition = 'CREATE_COVERAGES_EDITION',
    ArchiveCoveragesEdition = 'ARCHIVE_COVERAGES_EDITION',
    ApproveCoveragesEdition = 'APPROVE_COVERAGES_EDITION',
    GetProductCoveragesEdition = 'GET_PRODUCT_COVERAGES_EDITION',
    CreateCoveragesEditionRule = 'CREATE_COVERAGES_EDITION_RULE',
    EditCoveragesEditionRule = 'EDIT_COVERAGES_EDITION_RULE',
    DeleteCoveragesEditionRule = 'DELETE_COVERAGES_EDITION_RULE',
    CheckCoverageEditionCompatibility = 'CHECK_COVERAGE_EDITION_COMPATIBILITY',

    // Coverage instances
    AddCoverageInstances = 'ADD_COVERAGE_INSTANCES',
    RemoveCoverageInstances = 'REMOVE_COVERAGE_INSTANCES',
    EditCoverageInstance = 'EDIT_COVERAGE_INSTANCES',
    // Setting instances
    AddSettingInstances = 'ADD_SETTING_INSTANCES',
    RemoveSettingInstances = 'REMOVE_SETTING_INSTANCES',
    EditSettingInstance = 'EDIT_SETTING_INSTANCES',
}

// === Editions === //

export async function getCoveragesEdition(editionCode: string): Promise<CoveragesEdition> {
    const response = await insuranceBlender.get<{ data: CoveragesEdition }>(`${BASE_PATH}/${editionCode}`);

    return response.data.data;
}

function getCoveragesEditionQuery(
    editionCode?: string,
    onSuccess?: (coverageEdition: CoveragesEdition) => void
): UseQueryOptions<CoveragesEdition> {
    return {
        queryKey: [CoveragesEditionQueryKey.GetCoveragesEdition, editionCode],
        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- editionCode is defined since the query is disabled when it's not
            const result = await getCoveragesEdition(editionCode!);
            onSuccess?.(result);
            return result;
        },
        enabled: Boolean(editionCode),
    };
}

export function useGetCoveragesEdition(
    editionCode?: string,
    onSuccess?: (coverageEdition: CoveragesEdition) => void
): UseQueryResult<CoveragesEdition> {
    return useQuery(getCoveragesEditionQuery(editionCode, onSuccess));
}

async function createCoveragesEdition({
    productCode,
    description,
    baseEditionCode,
}: CreateEditionParams): Promise<CoveragesEdition> {
    const response = await insuranceBlender.post<{ data: CoveragesEdition }>(BASE_PATH, {
        productCode,
        description,
        baseEditionCode,
    });

    return response.data.data;
}

export function useCreateCoveragesEdition(productCode: string) {
    return usePessimisticMutation({
        mutationFn: createCoveragesEdition,
        invalidateKeys: [[CoveragesEditionQueryKey.GetProductCoveragesEdition, productCode]],
        mutationKey: [CoveragesEditionQueryKey.CreateCoveragesEdition],
    });
}

async function archiveCoveragesEdition({ editionCode }: ArchiveEditionParams): Promise<void> {
    await insuranceBlender.put(`${BASE_PATH}/${editionCode}/archive`);
}

export function useArchiveCoveragesEdition(productCode?: string) {
    return usePessimisticMutation({
        mutationFn: archiveCoveragesEdition,
        invalidateKeys: [[CoveragesEditionQueryKey.GetProductCoveragesEdition, productCode]],
        mutationKey: [CoveragesEditionQueryKey.ArchiveCoveragesEdition],
    });
}

async function addCoverageInstancesToEdition({
    editionCode,
    coverages,
}: {
    readonly editionCode: string;
    readonly coverages: Pick<CoverageInstance, 'isBenefit' | 'required' | 'scope' | 'templateCode'>[];
}): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${editionCode}/coverage-instances`, {
        coverages,
    });
}

export function useAddCoverageInstancesToEdition(editionCode: string) {
    return usePessimisticMutation({
        mutationFn: addCoverageInstancesToEdition,
        invalidateKeys: [[CoveragesEditionQueryKey.GetCoveragesEdition, editionCode]],
        mutationKey: [CoveragesEditionQueryKey.AddCoverageInstances],
    });
}

async function updateEditionCoverage({
    editionCode,
    coverage,
}: {
    readonly editionCode: string;
    readonly coverage: Partial<CoverageInstance>;
}): Promise<void> {
    await insuranceBlender.put(`${BASE_PATH}/${editionCode}/coverage-instances/${coverage.templateCode}`, coverage);
}

export function useUpdateEditionCoverageInstance(editionCode: string) {
    return usePessimisticMutation({
        mutationFn: updateEditionCoverage,
        invalidateKeys: [[CoveragesEditionQueryKey.GetCoveragesEdition, editionCode]],
        mutationKey: [CoveragesEditionQueryKey.EditCoverageInstance],
    });
}

async function removeEditionCoverageInstance({
    editionCode,
    coverageTemplateCodes,
}: {
    readonly editionCode: string;
    readonly coverageTemplateCodes: string[];
}): Promise<void> {
    await insuranceBlender.delete(`${BASE_PATH}/${editionCode}/coverage-instances`, {
        data: {
            coverageTemplateCodes,
        },
    });
}

export function useRemoveEditionCoverage(editionCode: string) {
    return usePessimisticMutation({
        mutationFn: removeEditionCoverageInstance,
        invalidateKeys: [[CoveragesEditionQueryKey.GetCoveragesEdition, editionCode]],
        mutationKey: [CoveragesEditionQueryKey.RemoveCoverageInstances],
    });
}

export async function checkCoverageEditionCompatibility(
    headEditionCode: string,
    baseEditionCode: string
): Promise<EditionValidationsResponse> {
    const query = qs.stringify({ baseEditionCode });

    const response = await insuranceBlender.get(`${BASE_PATH}/${headEditionCode}/check-breaking-changes?${query}`);

    return response.data.data;
}

export function useCheckCoverageEditionCompatibility(
    headEditionCode: string,
    baseEditionCode: string,
    enabled = true
): UseQueryResult<EditionValidationsResponse> {
    return useQuery({
        queryKey: [CoveragesEditionQueryKey.CheckCoverageEditionCompatibility, headEditionCode, baseEditionCode],
        queryFn: async () => await checkCoverageEditionCompatibility(headEditionCode, baseEditionCode),
        enabled,
    });
}

// === Settings === //

async function addSettingInstancesToEdition({
    editionCode,
    settings,
}: {
    readonly editionCode: string;
    readonly settings: SettingInstancePayload[];
}): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${editionCode}/setting-instances`, {
        settings,
    });
}

export function useAddSettingInstancesToEdition(editionCode: string) {
    return usePessimisticMutation({
        mutationFn: addSettingInstancesToEdition,
        invalidateKeys: [[CoveragesEditionQueryKey.GetCoveragesEdition, editionCode]],
        mutationKey: [CoveragesEditionQueryKey.AddSettingInstances],
    });
}

async function updateEditionSettingInstance({
    setting,
    editionCode,
    settingTemplateCode,
}: {
    readonly setting: SettingInstancePayload;
    readonly editionCode: string;
    readonly settingTemplateCode: string;
}): Promise<void> {
    await insuranceBlender.put(`${BASE_PATH}/${editionCode}/setting-instances/${settingTemplateCode}`, setting);
}

export function useUpdateEditionSettingInstance(editionCode: string) {
    return usePessimisticMutation({
        mutationFn: updateEditionSettingInstance,
        invalidateKeys: [[CoveragesEditionQueryKey.GetCoveragesEdition, editionCode]],
        mutationKey: [CoveragesEditionQueryKey.EditSettingInstance],
    });
}

async function removeSettingInstanceFromEdition({
    settingCode,
    editionCode,
}: {
    readonly settingCode: string;
    readonly editionCode: string;
}): Promise<void> {
    await insuranceBlender.delete(`${BASE_PATH}/${editionCode}/setting-instances`, {
        data: { settingTemplateCodes: [settingCode] },
    });
}

export function useRemoveSettingInstanceFromEdition(editionCode: string) {
    return usePessimisticMutation({
        mutationFn: removeSettingInstanceFromEdition,
        invalidateKeys: [[CoveragesEditionQueryKey.GetCoveragesEdition, editionCode]],
        mutationKey: [CoveragesEditionQueryKey.RemoveSettingInstances],
    });
}

interface DeleteCoverageRuleParams {
    readonly editionCode: string;
    readonly ruleId: string;
}

async function deleteCoveragesEditionRule({ editionCode, ruleId }: DeleteCoverageRuleParams): Promise<void> {
    await insuranceBlender.delete(`${BASE_PATH}/${editionCode}/rules/${ruleId}`);
}

export function useDeleteCoveragesEditionRule(editionCode: string): UseMutationResult<
    void,
    unknown,
    {
        readonly ruleId: string;
    },
    null
> {
    return usePessimisticMutation({
        mutationFn: async ({ ruleId }: { ruleId: string }) => await deleteCoveragesEditionRule({ editionCode, ruleId }),
        invalidateKeys: [[CoveragesEditionQueryKey.GetCoveragesEdition, editionCode]],
        mutationKey: [CoveragesEditionQueryKey.DeleteCoveragesEditionRule],
    });
}
