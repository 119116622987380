import assert from 'assert';
import type { SelectOptionBase } from '@lemonade-hq/blender-ui';
import { Select } from '@lemonade-hq/blender-ui';
import { ErrorSection } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import { isDefined, snakeToCamelCase } from '@lemonade-hq/ts-helpers';
import { useCallback, useMemo } from 'react';
import type { Key } from 'react-aria-components';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import { getEditionName } from 'components/LoCo/common/editions/editionHelpers';
import type { LeanEdition } from 'models/LoCo/Insurance/BaseEdition';
import { EditionStatus, EditionType, ReleaseType } from 'models/LoCo/Insurance/BaseEdition';
import type { EditionEligibilityResponse } from 'models/LoCo/Insurance/EditionTracker';
import type { ProductEditions } from 'models/LoCo/Insurance/Product';
import { useGetProductUpdateEligibility } from 'queries/LoCo/Insurance/EditionTrackerQueries';
import {
    useGetProductEditionSets,
    useGetProductEditionsSummary,
    useGetProductSchemaRevisions,
} from 'queries/LoCo/Insurance/ProductQueries';

function getEdition(
    editionType: EditionType,
    editionSelectionData: DeepNullable<EditionSelectionData>
): DeepNullable<LeanEdition> | undefined {
    const selectedEdition = editionSelectionData.selectedEditions.find(edition => edition.type === editionType);

    return selectedEdition
        ? {
              code: selectedEdition.code,
              type: editionType,
              friendlyName: selectedEdition.friendlyName,
              description: selectedEdition.description,
              trackerPublicId: selectedEdition.trackerPublicId,
              eligibleForMajorUpdate: selectedEdition.eligibleForMajorUpdate,
          }
        : undefined;
}

function getEligibleEditionsByType(
    editionType: EditionType,
    editionSelectionData: DeepNullable<EditionSelectionData>,
    editions?: EditionEligibilityResponse[]
): EditionOptions {
    const editionsByType = editions?.filter(edition => edition.editionContentType === editionType) ?? [];

    return {
        options: editionsByType.map(edition => ({
            code: edition.editionContentCode,
            friendlyName: edition.friendlyName,
            type: editionType,
            description: edition.description,
            trackerPublicId: edition.trackerPublicId,
            eligibleForMajorUpdate: edition.eligibleForMajorUpdate,
        })),
        type: editionType,
        selectedEdition: getEdition(editionType, editionSelectionData),
    };
}

function getEditionsByType(
    editionType: EditionType,
    editionSelectionData: DeepNullable<EditionSelectionData>,
    editions?: ProductEditions
): EditionOptions {
    return {
        options:
            editions?.[`${snakeToCamelCase(editionType)}Editions`]?.map(edition => ({
                code: edition.code,
                friendlyName: edition.friendlyName,
                type: editionType,
                description: edition.description,
                trackerPublicId: edition.trackerPublicId,
            })) ?? [],
        type: editionType,
        selectedEdition: getEdition(editionType, editionSelectionData),
    };
}

function isValidForm(
    selectedEditionsCount: number,
    selectedProductSchema: string | null,
    selectedPlatformSchema: string | null,
    isProductFirstRelease: boolean
): boolean {
    if (selectedEditionsCount === 0) {
        return false;
    }

    if (!isProductFirstRelease) {
        return true;
    }

    return (
        selectedEditionsCount === Object.keys(EditionType).length &&
        isDefined(selectedProductSchema) &&
        isDefined(selectedPlatformSchema)
    );
}

interface EditionOptions {
    readonly options: LeanEdition[];
    readonly type: EditionType;
    readonly selectedEdition?: DeepNullable<LeanEdition>;
}

export interface EditionSelectionData {
    readonly selectedEditions: LeanEdition[];
    readonly selectedPlatformSchema: string | null;
    readonly selectedProductSchema: string | null;
    readonly isValid: boolean;
}

interface EditionSelectInputsProps {
    readonly productCode: string;
    readonly editionSelectionData: DeepNullable<EditionSelectionData>;
    readonly releaseType: ReleaseType | null;
    readonly onChange: (newEditionSelectionData: DeepNullable<EditionSelectionData>) => void;
}

export const EditionSelectInputs: React.FC<EditionSelectInputsProps> = ({
    productCode,
    onChange,
    editionSelectionData,
    releaseType,
}) => {
    const { data: eligibleEditions } = useGetProductUpdateEligibility(productCode);
    const { data: approvedEditions } = useGetProductEditionsSummary(productCode, { status: EditionStatus.Approved });
    const { data: productPublishedEditionSets, isError: isErrorProductPublishedEditionSets } = useGetProductEditionSets(
        productCode,
        true
    );
    const {
        data: schemaRevisions,
        isPending: isLoadingSchemaRevisions,
        isError: isErrorSchemaRevisions,
    } = useGetProductSchemaRevisions(productCode);

    const isProductFirstRelease =
        isDefined(productPublishedEditionSets?.data) && productPublishedEditionSets.data.length === 0;

    const productSchemaOptions = useMemo<SelectOptionBase[]>(() => {
        if (!schemaRevisions) return [];

        return schemaRevisions.productRevisions.map(revision => ({
            id: revision.revision,
            value: revision.revision.toString(),
            label: revision.revision.toString(),
        }));
    }, [schemaRevisions]);

    const platformSchemaOptions = useMemo<SelectOptionBase[]>(() => {
        if (!schemaRevisions) return [];

        return schemaRevisions.platformRevisions.map(revision => ({
            id: revision.revision,
            value: revision.revision.toString(),
            label: revision.revision.toString(),
        }));
    }, [schemaRevisions]);

    const editionsData = useMemo(() => {
        if (releaseType === ReleaseType.BugFix) {
            return [
                getEditionsByType(EditionType.Coverages, editionSelectionData, approvedEditions),
                getEditionsByType(EditionType.DigitalAgent, editionSelectionData, approvedEditions),
                getEditionsByType(EditionType.UnderwritingFilters, editionSelectionData, approvedEditions),
                getEditionsByType(EditionType.Rating, editionSelectionData, approvedEditions),
            ];
        } else {
            return [
                getEligibleEditionsByType(EditionType.Coverages, editionSelectionData, eligibleEditions),
                getEligibleEditionsByType(EditionType.DigitalAgent, editionSelectionData, eligibleEditions),
                getEligibleEditionsByType(EditionType.UnderwritingFilters, editionSelectionData, eligibleEditions),
                getEligibleEditionsByType(EditionType.Rating, editionSelectionData, eligibleEditions),
            ];
        }
    }, [releaseType, editionSelectionData, approvedEditions, eligibleEditions]);

    const onEditionSelection = useCallback(
        (editionType: EditionType, editions: LeanEdition[]) => (code: Key | null) => {
            const edition = editions.find(option => option.code === code);
            assert(edition, 'Edition summary not found');

            // take into account selected edition (which is not yet in props as it was just selected)
            const selectedEditionsCount =
                editionSelectionData.selectedEditions.filter(selectedEdition => editionType !== selectedEdition.type)
                    .length + 1;

            const isFormValid = isValidForm(
                selectedEditionsCount,
                editionSelectionData.selectedProductSchema,
                editionSelectionData.selectedPlatformSchema,
                isProductFirstRelease
            );
            const newSelectedEdition = {
                ...edition,
                type: editionType,
            };
            const newSelectedEditions =
                isDefined(editionSelectionData) && isDefined(editionSelectionData.selectedEditions)
                    ? [
                          ...editionSelectionData.selectedEditions.filter(
                              selectedEdition => editionType !== selectedEdition.type
                          ),
                          newSelectedEdition,
                      ]
                    : [newSelectedEdition];

            onChange({
                ...editionSelectionData,
                selectedEditions: newSelectedEditions,
                isValid: isFormValid,
            });
        },
        [isProductFirstRelease, editionSelectionData, onChange]
    );

    const onSchemaSelection = useCallback(
        (selectedProductSchema: string | null, selectedPlatformSchema: string | null) => {
            const isFormValid = isValidForm(
                editionSelectionData.selectedEditions.length,
                selectedProductSchema,
                selectedPlatformSchema,
                isProductFirstRelease
            );

            onChange({
                ...editionSelectionData,
                selectedPlatformSchema,
                selectedProductSchema,
                isValid: isFormValid,
            });
        },
        [isProductFirstRelease, editionSelectionData, onChange]
    );

    if (isErrorProductPublishedEditionSets || isErrorSchemaRevisions) {
        return <ErrorSection noBorders />;
    }

    return (
        <>
            {editionsData.map(editionsItem => (
                <>
                    <Flex>{getEditionName(editionsItem.type)}</Flex>
                    <Select
                        disabled={editionsItem.options.length === 0}
                        onChange={onEditionSelection(editionsItem.type, editionsItem.options)}
                        options={editionsItem.options.map(option => ({
                            label: option.friendlyName,
                            value: option.code,
                        }))}
                        placeholder="Select Edition"
                        selectedKey={editionsItem.selectedEdition?.code ?? undefined}
                    />
                </>
            ))}
            <Flex>Product Schema</Flex>
            <Select
                disabled={isLoadingSchemaRevisions}
                onChange={value => onSchemaSelection(value as string, editionSelectionData.selectedPlatformSchema)}
                options={productSchemaOptions}
                placeholder="Select Schema"
                selectedKey={editionSelectionData.selectedProductSchema ?? undefined}
            />
            <Flex>Platform Schema</Flex>
            <Select
                disabled={isLoadingSchemaRevisions}
                onChange={value => onSchemaSelection(editionSelectionData.selectedProductSchema, value as string)}
                options={platformSchemaOptions}
                placeholder="Select Schema"
                selectedKey={editionSelectionData.selectedPlatformSchema ?? undefined}
            />
        </>
    );
};
