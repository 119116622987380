import type { InvolvedPartyType } from 'models/InvolvedParty';

export enum FeatureType {
    Damage = 'damage',
    Injury = 'injury',
}

export enum FeatureName {
    Collision = 'collision',
    Comprehensive = 'comprehensive',
    PropertyDamageLiability = 'property_damage_liability',
    Roadside = 'roadside_assistance',
    Rental = 'rental',
    UMPD = 'um_pd',
    UMBI = 'um_bi',
    UIMBI = 'uim_bi',
    UIMPD = 'uim_pd',
    UnderInsuredMotorist = 'underinsured_motorist_bodily_injury',
    BodilyInjuryLiability = 'bodily_injury_liability',
    PIP = 'personal_injury_protection',
    MedicalPayments = 'medical_payments',
    LostWages = 'lost_wages',
    EssentialServices = 'essential_services',
    Funeral = 'funeral',
    Death = 'death',
    TORT = 'tort',
    ExtendedMedicalLimit = 'extended_medical_limit',
}

export type Features = { readonly [key in FeatureName]: Feature };

export interface Feature {
    readonly limit?: number;
    readonly deductible: number;
    readonly items: Item[];
}

export interface ItemInvolvedPerson extends ItemInvolvedPartyBase {
    readonly type: InvolvedPartyType.Person;
    readonly limit: number;
    readonly deductible?: number;
}

export interface ItemInvolvedVehicle extends ItemInvolvedPartyBase {
    readonly type: InvolvedPartyType.Vehicle;
}

interface ItemInvolvedPartyBase {
    readonly publicId: string;
}

export enum CarClaimItemStatus {
    Active = 'active',
    Paid = 'paid',
    Rejected = 'rejected',
    Archived = 'archived',
    PaymentFailed = 'payment_failed',
    PendingPayment = 'pending_payment',
    PaymentInProgress = 'payment_in_progress',
    PaymentScheduled = 'payment_scheduled',
    PendingBillInfo = 'pending_bill_info',
    PendingApproval = 'pending_approval',
    Approved = 'approved',
    PendingRepairCompletion = 'pending_repair_completion',
}

export enum ItemType {
    Loss = 'loss',
    Expense = 'expense',
}

export type Item = ExpenseItem | LossItem;

interface ItemBase {
    readonly publicId: string;
    readonly status: CarClaimItemStatus;
    readonly cost: number;
    readonly description?: string;
    readonly involvedParty: ItemInvolvedPerson | ItemInvolvedVehicle;
    readonly referenceId?: string;
    readonly scheduledPaymentTime?: Date;
    readonly vendorName?: string;
    readonly featureName?: string;
}

interface ExpenseItem extends ItemBase {
    readonly expenseType: string;
    readonly itemType: ItemType.Expense;
}

interface LossItem extends ItemBase {
    readonly itemType: ItemType.Loss;
    readonly lossItemType: string;
}

export interface PossibleFeature {
    readonly name: FeatureName;
    readonly type: FeatureType;
    readonly lossItemTypes: LossItemType[];
}

export enum LossItemType {
    Tow = 'tow',
    DamageRepair = 'damage_repair',
    Glass = 'glass',
    BreakdownRecovery = 'breakdown_recovery',
    Rental = 'rental',
    GlassReplacement = 'glass_replacement',
    FuneralCost = 'funeral_cost',
    PainAndSuffering = 'pain_and_suffering',
    LostWages = 'lost_wages',
    MedicalBills = 'medical_bills',
    Storage = 'storage',
    Other = 'other',
    Bicycle = 'bicycle',
    Battery = 'battery',
    FlatTire = 'flat_tire',
}
