import axios from 'axios';
import { camelToSnakeCaseKeys, camelToSnakeCaseArray, SnakeCaseToCamelCase } from '@lemonade-hq/ts-helpers';
import { AttachmentType, AttachmentEntityType, Attachment, PetAttachmentType } from 'models/Attachment';
import { AttachmentsToUpdate } from 'queries/Pet/PetClaimQueries';
import { getUrlResolver } from 'commons/UrlResolver';
import { SelectedAddons } from 'components/pet/PetPreApprovalWorkflows/tasks/EstimateCoverageAmount/EstimateCoverageAmount';

const petUrlResolver = getUrlResolver('pet-blender');

export async function createAttachments(
    entityId: string,
    entityType: AttachmentEntityType,
    attachments: {
        type: AttachmentType;
        filePublicId: string;
        contentType: string;
        description?: string;
    }[]
): Promise<Attachment[]> {
    const url = petUrlResolver(`/api/v1/attachments`);
    const body = {
        attachments: attachments.map(a => ({
            type: a.type,
            filePublicId: a.filePublicId,
            entityId,
            entityType,
            contentType: a.contentType,
            description: a.description,
        })),
    };
    const response = await axios
        .post(url, body)
        .then(response => camelToSnakeCaseKeys<{ data: SnakeCaseToCamelCase<Attachment>[] }>(response.data));

    return response.data;
}

export async function getAttachmentTypes(): Promise<PetAttachmentType[]> {
    const url = petUrlResolver(`/api/v1/attachments/types`);

    return axios.get<{ data: PetAttachmentType[] }>(url).then(response => response.data.data);
}

export async function updateAttachments({
    attachments,
}: {
    attachments: AttachmentsToUpdate[];
}): Promise<Attachment[]> {
    const url = petUrlResolver(`/api/v1/attachments`);

    const response = await axios
        .put<{ data: SnakeCaseToCamelCase<Attachment>[] }>(url, { attachments })
        .then(response => camelToSnakeCaseKeys(response.data));

    return response.data;
}

export async function getAttachmentsByEntityId(entityId: string): Promise<Attachment[]> {
    if (!entityId) {
        return [];
    }

    const url = petUrlResolver(`/api/v1/attachments`);
    const attachments = await axios
        .get<{ data: SnakeCaseToCamelCase<Attachment[]> }>(url, { params: { entityId } })
        .then(response => response.data.data);

    return camelToSnakeCaseArray(attachments);
}

export async function deleteAttachment({
    attachmentPublicId,
    attachmentType,
}: {
    attachmentPublicId: string;
    attachmentType: AttachmentType;
}): Promise<Attachment[]> {
    const url = petUrlResolver(`/api/v1/attachments/${attachmentPublicId}`);
    const body = { data: { type: attachmentType } };

    return axios.delete(url, body).then(response => response.data.data);
}

export async function fetchEstimatedCostSelectedAddons({
    preapprovalId,
}: {
    preapprovalId: string;
}): Promise<SelectedAddons> {
    const url = petUrlResolver(`/api/v1/preapprovals/${preapprovalId}/estimated-costs-addons`);

    return axios.get(url).then(response => response.data.data);
}

export async function updateEstimatedCostSelectedAddons({
    preapprovalId,
    selectedAddons,
}: {
    preapprovalId: string;
    selectedAddons: SelectedAddons;
}): Promise<null> {
    const url = petUrlResolver(`/api/v1/preapprovals/${preapprovalId}/estimated-costs-addons`);
    const body = { data: selectedAddons };

    return axios.post(url, body).then(response => response.data.data);
}
