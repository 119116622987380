import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { insuranceBlender } from '../../../apiClients';
import { CoveragesEditionQueryKey } from './CoveragesEditionQueries';
import { DigitalAgentEditionQueryKey } from './DigitalAgentEditionQueries';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageRule } from 'models/LoCo/Insurance/CoverageRule';
import { usePessimisticMutation } from 'queries/MutationHooks';

const COVERAGES_EDITIONS_BASE_PATH = '/api/v1/coverages-editions';
const DIGITAL_AGENT_EDITIONS_BASE_PATH = '/api/v1/digital-agent-editions';
const UNDERWRITING_FILTERS_EDITIONS_BASE_PATH = '/api/v1/underwriting-filters-editions';
const RATING_EDITIONS_BASE_PATH = '/api/v1/rating-editions';

export interface CreateCoverageRuleParams {
    readonly editionType: EditionType;
    readonly editionCode: string;
    readonly rule: Omit<CoverageRule, 'publicId'>;
    readonly variants?: string[];
}

export type CreateRuleArgs = Omit<CoverageRule, 'publicId'>;

interface EditEditionCoverageRuleParams {
    readonly editionType: EditionType;
    readonly editionCode: string;
    readonly rule: CoverageRule;
    readonly variants?: string[];
}

export enum EditionQueryKey {
    GetEditionViolations = 'GET_EDITION_VIOLATIONS',
    GetEditionApproveEligibility = 'GET_EDITION_APPROVE_ELIGIBILITY',
    GetEditionSummary = 'GET_EDITION_SUMMARY',
}

const getBasePath = (editionType: EditionType): string => {
    switch (editionType) {
        case EditionType.Coverages:
            return COVERAGES_EDITIONS_BASE_PATH;
        case EditionType.DigitalAgent:
            return DIGITAL_AGENT_EDITIONS_BASE_PATH;
        case EditionType.UnderwritingFilters:
            return UNDERWRITING_FILTERS_EDITIONS_BASE_PATH;
        case EditionType.Rating:
            return RATING_EDITIONS_BASE_PATH;
        default:
            throw new Error('Invalid edition type');
    }
};

// Coverages rules related queries
async function createEditionRule({
    editionType,
    editionCode,
    rule,
    variants,
}: CreateCoverageRuleParams): Promise<void> {
    const path = getBasePath(editionType);

    await insuranceBlender.post(`${path}/${editionCode}/rules`, { rule, variants });
}

export function useCreateEditionRule(
    editionType: EditionType,
    editionCode: string
): UseMutationResult<
    void,
    unknown,
    {
        readonly rule: CreateCoverageRuleParams['rule'];
        readonly variants?: string[];
    },
    null
> {
    const getKey =
        editionType === EditionType.Coverages
            ? CoveragesEditionQueryKey.GetCoveragesEdition
            : DigitalAgentEditionQueryKey.GetDigitalAgentEdition;

    const createRuleKey =
        editionType === EditionType.Coverages
            ? CoveragesEditionQueryKey.CreateCoveragesEditionRule
            : DigitalAgentEditionQueryKey.CreateDigitalAgentEditionRule;

    return usePessimisticMutation({
        mutationFn: async ({ rule, variants }: { rule: CreateCoverageRuleParams['rule']; variants?: string[] }) =>
            await createEditionRule({ editionType, editionCode, rule, variants }),
        invalidateKeys: [
            [getKey, editionCode],
            [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode],
        ],
        mutationKey: [createRuleKey],
    });
}

async function editEditionRule({
    editionType,
    editionCode,
    rule,
    variants,
}: EditEditionCoverageRuleParams): Promise<void> {
    const path = getBasePath(editionType);

    await insuranceBlender.put(`${path}/${editionCode}/rules/${rule.publicId}`, { rule, variants });
}

export function useEditEditionRule(
    editionType: EditionType,
    editionCode: string
): UseMutationResult<
    void,
    unknown,
    {
        readonly rule: CoverageRule;
        readonly variants?: string[];
    },
    null
> {
    const getKey =
        editionType === EditionType.Coverages
            ? CoveragesEditionQueryKey.GetCoveragesEdition
            : DigitalAgentEditionQueryKey.GetDigitalAgentEdition;

    const editRuleKey =
        editionType === EditionType.Coverages
            ? CoveragesEditionQueryKey.EditCoveragesEditionRule
            : DigitalAgentEditionQueryKey.EditDigitalAgentEditionRule;

    return usePessimisticMutation({
        mutationFn: async ({ rule, variants }: { rule: CoverageRule; variants?: string[] }) =>
            await editEditionRule({ editionType, editionCode, rule, variants }),
        invalidateKeys: [
            [getKey, editionCode],
            [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode],
        ],
        mutationKey: [editRuleKey],
    });
}

export async function getEditionSummary(
    editionCode: string | undefined,
    editionType: EditionType
): Promise<Edition | null> {
    return await insuranceBlender
        .get<{ data: Edition }>(`${getBasePath(editionType)}/${editionCode}/summary`)
        .then(response => response.data.data);
}

export function useGetEditionSummary(
    editionCode?: string,
    editionType?: EditionType | null
): UseQueryResult<Edition | null> {
    return useQuery({
        queryKey: [EditionQueryKey.GetEditionSummary, editionCode],
        queryFn: async () => await getEditionSummary(editionCode, editionType!),
        enabled: Boolean(editionCode) && Boolean(editionType),
    });
}
