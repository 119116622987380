import type { OrderByDirection } from '@lemonade-hq/bluis';
import type { WorkflowSegment } from '@lemonade-hq/bluiza';
import { ProductTypes } from '@lemonade-hq/bluiza';
import type { SnakeCaseToCamelCase } from '@lemonade-hq/ts-helpers';
import axios from 'axios';
import { stringify } from 'qs';
import { stringifyUrl } from 'query-string';
import { homeBlender } from '../apiClients';
import type { SendEmailArgsWithEntityId } from './EmailsAPI';
import { sendEmail as sendEmailResolver } from './EmailsAPI';
import {
    addCustomRecipient as addCustomRecipientGeneric,
    assignInteraction as assignInteractionGeneric,
    createNote as createNoteGeneric,
    dismissInteraction as dismissInteractionGeneric,
    manualResponseInteraction as manualResponseInteractionGeneric,
} from 'apis/InteractionsAPI';
import { getUrlResolver } from 'commons/UrlResolver';
import type {
    CreatableWorkflowTaskType,
    HomeTaskType,
    HomeWorkflowAvailable,
    NewCustomTaskContent,
    RecommendedTaskType,
    ReferenceType,
    VerificationType,
} from 'components/Claim/WorkflowsV2/Types';
import type { Address, AddressDTO } from 'models/Address';
import type { AdjusterSquad } from 'models/Adjuster';
import { AdjusterRole } from 'models/Adjuster';
import type { Alert } from 'models/Alert';
import type { Attachment, HomeAttachment, HomeClaimAttachment } from 'models/Attachment';
import type { SendEmailRequestDTO } from 'models/Email';
import type {
    AllVerificationParams,
    ClaimAction,
    ClaimantDetailsTask,
    ClaimCatastrophe,
    ClaimCurrentAdjuster,
    ClaimDefaultEventSummary,
    ClaimDetailsSection,
    ClaimDispatchExpenseVendorTask,
    ClaimEndorsement,
    ClaimFeaturesActions,
    ClaimHeaderAction,
    ClaimIncidentDetailsDamagesDetails,
    ClaimIncidentDetailsTaskLayout,
    ClaimInjuryDetails,
    ClaimItemsTableDTO,
    ClaimItemType,
    ClaimItemV2,
    ClaimItemVendor,
    ClaimLocationDetails,
    ClaimLossType,
    ClaimPolicyVersionDetails,
    ClaimScheduledItem,
    ClaimSelectableUserPolicy,
    ClaimSettings,
    Status as ClaimStatus,
    ClaimUserExtendedDetails,
    ClaimUserPreferences,
    ClaimUserPreferencesType,
    ClaimVerificationQuestionnaire,
    ClaimVerificationQuestionnaireType,
    ClaimVideoAttachments,
    DeclineDecision,
    DispatchType,
    DispatchVendorDialogDefaults,
    DispatchVendorRequestType,
    Fees,
    FeesLog,
    HomeClaimItem,
    HomeClaimItems,
    HomeClaimLicensedNote,
    HomeClaimPolicy,
    HomeClaimReview,
    HomeClaimSearchResult,
    HomeClaimType,
    HomeClaimV2,
    IncidentLocation,
    Landlord,
    LinkedEndorsement,
    LinkedEntityType,
    LossItemClassification,
    LossServiceType,
    ObtainFilesQuestionnaire,
    RejectClaimItemAttributes,
    ReserveLog,
    SubrogationLog,
    UserPreferencesPayload,
    ValuatedItemsTableDTO,
    ValuatedItemsViewMode,
    Witness,
} from 'models/HomeClaim';
import type { ClaimFeature, ClaimInitialReservesDTO, HomeFeatureType } from 'models/HomeFeature';
import type { SecondaryInsuredType } from 'models/HomePolicy';
import type { AlertDTO, HomeCoverage, HomePageLayout, HomeUserSummary, OptionDTO } from 'models/HomeShared';
import type { CommunicationMethods, FirstResponse, NoteCreateRequest } from 'models/Interaction';
import type { Payee, PayeesPayoutMethods, PayeeType } from 'models/Payee';
import type {
    PaymentInfo,
    PaymentMethodType,
    PaymentRequestData,
    PaymentRequestDetailsResponse,
    Transaction,
} from 'models/Payment';
import { EntityTypes } from 'models/Products';
import type { Recipient } from 'models/Recipient';
import type { InformedCustomerVia } from 'models/Workflows';

export type SearchOrderByFields = 'submitted_at' | 'user_full_name';

const blenderGeneralUrlResolver = getUrlResolver('blender-general');
const homeBlenderUrlResolver = getUrlResolver('home-blender');

export interface HomeClaimDataResponse {
    readonly transactions: Transaction[][];
    readonly workflow: WorkflowSegment<typeof HomeTaskType, undefined, typeof ReferenceType>[];
    readonly creatable_task_types: CreatableWorkflowTaskType[];
    readonly reserve_log: ReserveLog[];
    readonly subrogation_log: SubrogationLog[];
    readonly fees_log: FeesLog[];
    readonly landlord: Landlord | null;
    readonly witnesses: Witness[];
    readonly decline_template: { readonly template: string };
    readonly claim: HomeClaimV2;
    readonly claim_items: HomeClaimItems;
    readonly claim_policy: HomeClaimPolicy;
    readonly claim_user_preferences: ClaimUserPreferences;
    readonly user_summary: HomeUserSummary;
    readonly licensed_note: HomeClaimLicensedNote;
    readonly claim_police_report: ClaimPoliceReport;
    readonly claim_default_event_summary: ClaimDefaultEventSummary;
    // TODO: Remove null when ready for integration
    // https://team-lemonade.fibery.io/Software_Dev/Task/101960
    readonly claim_event_summary: HomeClaimReview | null;
    readonly workflow_available: HomeWorkflowAvailable;
    readonly payment_requests: PaymentRequestData[];
    readonly recommended_task_types?: RecommendedTaskType[];
    readonly fees: Fees;
    readonly effective_endorsements: ClaimEndorsement[];
    readonly effective_scheduled_items: ClaimScheduledItem[];
    readonly claim_actions: ClaimAction[];
    readonly claim_features_actions: ClaimFeaturesActions;
    readonly claim_header_actions: ClaimHeaderAction[];
    readonly workflow_claim_actions: ClaimAction[];
    readonly linked_endorsements: LinkedEndorsement[];
    readonly claim_items_vendors: ClaimItemVendor[];
    readonly claim_video_attachments: ClaimVideoAttachments;
    readonly timezone: string | null;
    readonly possible_recipients: Recipient[];
    readonly page_layout: HomePageLayout<ClaimDetailsSection>;
    readonly claim_policy_version_details: ClaimPolicyVersionDetails;
    readonly claim_policy_version_coverages: HomeCoverage[];
    readonly claim_incident_details_task_layout: ClaimIncidentDetailsTaskLayout;
    readonly user_extended_details: ClaimUserExtendedDetails;
    readonly claim_policy_version_pdf_url: { readonly url: string } | null;
    readonly policy_secondary_insured: SecondaryInsuredType | null;
    readonly claim_policy_other_insureds: string[];
    readonly claim_initial_reserves: ClaimInitialReservesDTO;
    readonly claim_incident_damages_details: ClaimIncidentDetailsDamagesDetails;
    readonly catastrophe: ClaimCatastrophe | null;
    readonly claim_incident_injury_details: ClaimInjuryDetails;
    readonly claim_location_details: ClaimLocationDetails;
    readonly claim_selectable_user_policies: ClaimSelectableUserPolicy[];
    readonly claim_status_update: { readonly alerts: AlertDTO[] };
    readonly claim_current_adjuster: ClaimCurrentAdjuster | null;
    readonly claim_obtain_missing_info_task: ObtainFilesQuestionnaire;
    readonly advance_loss_of_use_payment_task: AdvanceLouPaymentTaskDTO;
    readonly claim_settings: ClaimSettings;
    readonly claim_verification_reports: ClaimVerificationQuestionnaire[];
    readonly cause_and_origin_approval_task: Pick<ObtainFilesQuestionnaire, 'obtainFireReport'>;
    readonly claim_dispatch_expense_vendors_task: ClaimDispatchExpenseVendorTask;
    readonly mortgagees: Mortgagee[];
    readonly litigation: Litigation | null;
    readonly claim_representative_types: OptionDTO[];
    readonly representatives: Representative[];
    readonly payable_valuated_items: PayableValuatedItem[];
    readonly claim_review_inventory_task: ClaimInventoryReviewTaskDTO;
    readonly liability_referral_task: LiabilityReferralTask | null;
    readonly claim_assigned_payees: ClaimAssignedPayee[];
    readonly claim_payee_actions: OptionDTO[];
}

export interface ClaimAssignedPayee {
    readonly accountId: string;
    readonly claimId: number;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly paymentOptions: OptionDTO[];
    readonly paymentsSum: number;
    readonly fullName: string;
    readonly id: number;
    readonly status: string;
    readonly subType: string;
    readonly type: PayeeType;
    readonly typeDisplayName: string;
    readonly userId: number;
    readonly vendorId: string;
}

export interface LiabilityReferralTask {
    readonly liabilitySummary: string | null;
    readonly evidenceOfPotentialLiability: boolean | null;
    readonly settings: LiabilityReferralTaskSettings;
}

export interface LiabilityReferralTaskSettings {
    readonly enableMarkAsDone: boolean;
    readonly evidenceToggleEnabled: boolean;
}

export interface ClaimInventoryReviewTaskDTO {
    readonly importAllowed: boolean;
    readonly valueCheckerInventoryUrl: string | null;
}

export interface PayableValuatedItem {
    readonly appraisalItemPublicId: string;
    readonly category: string | null;
    readonly claimItemPublicId: string;
    readonly disabled: boolean;
    readonly replacementCostValue: number;
    readonly title: string;
}

export enum AdversePartyType {
    FirstParty = 'first_party',
    ThirdParty = 'third_party',
}

export interface Representative {
    readonly address: AddressDTO | null;
    readonly email: string | null;
    readonly lawFirm: string | null;
    readonly name: string;
    readonly phone: string | null;
    readonly publicId: string;
    readonly type: string;
}

export interface Litigation {
    readonly adversePartyType: AdversePartyType;
    readonly name: string;
    readonly publicId: string;
}

export interface HomeCreateManualClaimResponse {
    readonly claimPublicId: string;
}

export interface HomeFeatureDataResponse {
    readonly coverage_decision: CoverageDecision;
    readonly coverage_decision_options: CoverageDecisionOption[];
    readonly feature: Feature;
}
export interface ClaimPoliceReport {
    readonly incident?: string;
    readonly precinct?: string;
    readonly officer?: string;
    readonly attachments: HomeClaimAttachment[];
}

export interface DeclineReason {
    readonly title: string;
    readonly value: string;
}

export interface LossTypeOption {
    readonly key: string;
    readonly title: string;
}

export type DamageType = LossTypeOption;

export interface Mortgagee {
    readonly name: string;
}

export interface LossType extends LossTypeOption {
    readonly lossSubTypes?: LossTypeOption[];
}

export interface ClaimStaticDataResponse {
    readonly damage_types: DamageType[];
    readonly decline_reasons: DeclineReason[];
    readonly loss_types: LossType[];
    readonly service_type_options: OptionDTO[];
}

export interface CreateManualClaimDTO {
    readonly policyId: string;
    readonly userId: string;
    readonly emergency: boolean;
    readonly catastropheId?: string;
    readonly phoneNumber: string;
    readonly damageType: string;
    readonly lossType: string;
    readonly lossSubType?: string;
    readonly dateOfLoss: string;
    readonly description: string;
    readonly accountName?: string;
    readonly bankName?: string;
    readonly bankId?: string;
    readonly bankAccountId?: string;
    readonly accountNumber?: string;
    readonly accountAba?: string;
}

interface HomeClaimsQueryParamsResponse {
    readonly countries: Country[];
    readonly statuses: ClaimStatus[];
    readonly settled: boolean[];
    readonly subrogation: boolean[];
    readonly adjusters: Omit<AdjusterDetails, 'fullName'>[];
    readonly lossTypes: { readonly type: HomeClaimType; readonly displayName: string }[];
}

interface Country {
    readonly countryName: string;
    readonly flag: string;
}

export interface AdjusterDetails {
    readonly publicId: string;
    readonly operatorPublicId: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly fullName: string;
    readonly photoUrl: string;
    readonly role: AdjusterRole;
    readonly squad: AdjusterSquad;
    readonly isLicensed?: boolean;
}

export interface HomeClaimsSearchSortParams {
    readonly column: SearchOrderByFields;
    readonly direction: OrderByDirection;
}

interface HomeClaimsSearchFilter {
    readonly country?: string;
    readonly statuses?: ClaimStatus[];
    readonly isSettled?: boolean;
    readonly isSubrogation?: boolean;
    readonly adjusterPublicId?: string;
    readonly lossType?: ClaimLossType;
}

export interface HomeClaimsSearchParams {
    readonly page: number;
    readonly sort?: HomeClaimsSearchSortParams;
    readonly filter?: HomeClaimsSearchFilter;
    readonly limit?: number;
}

interface HomeClaimsSearchResponse {
    readonly claims: HomeClaimSearchResult[];
    readonly offset: number;
    readonly pageSize: number;
    readonly size: number;
    readonly total: number;
}

export interface VerificationReportResponse {
    readonly verification_type: string;
    readonly verifications: VerificationType;
    readonly id?: string;
    readonly claim_id?: string;
    readonly report_dismissed?: boolean;
    readonly report_dismissal_reason?: string | null;
}

export interface CoverageDecisionResponse {
    readonly id: number;
    readonly decision: string;
    readonly reason: string;
    readonly supporting_data: VerificationReportResponse;
}

export type NotificationsResponse = Alert[];

export interface QuoteAnswersResponse {
    readonly attributes: { readonly [key: string]: string } | null;
    readonly api_request: { readonly [key: string]: string } | null;
}

export interface PrePaymentInfo {
    readonly item_ids: string[];
    readonly payees: Payee[];
    readonly waive_reasons: string[];
    readonly payment_methods: PayeesPayoutMethods[];
    readonly deductible_can_be_waived: boolean;
    readonly total_below_deductible: boolean;
}

export interface PrePaymentInfoV2 {
    readonly payeePaymentMethods: SnakeCaseToCamelCase<PayeesPayoutMethods>[];
    readonly payees: SnakeCaseToCamelCase<Payee>[];
    readonly totalBelowDeductible: boolean;
    readonly waiveReasons: string[];
}

export interface ClaimsLitigationResponse {
    readonly id: number;
    readonly claimId: number;
    readonly active: boolean;
    readonly adversePartyType: string;
    readonly adversePartyDetails: string;
}

export interface PreDefinedTasks {
    readonly requestedTaskType: string;
    readonly actionDate: string;
    readonly operatorPublicId?: string;
    readonly forcedSquad?: string;
}

interface DecisionOption {
    readonly name: string;
    readonly displayName: string;
}

interface Feature {
    readonly id: string;
    readonly title: string;
    readonly type: string;
}

interface CoverageDecision {
    readonly claimPublicId: string;
    readonly featureId: string;
    readonly coverageAnalysis: string | null;
    readonly coverageDecision: DecisionOption | null;
    readonly coverageReason: DecisionOption | null;
}

export interface EditFeaturePayload {
    readonly claimantAdditionalInfo?: string | null;
    readonly claimantExtraDetails?: EditClaimantExtraDetails | null;
    readonly claimantFirstName?: string | null;
    readonly claimantEmail?: string | null;
    readonly claimantLastName?: string | null;
    readonly claimantPhoneNumber?: string | null;
}

export type EditClaimantExtraDetails = Pick<
    EditClaimantPayload,
    | 'address'
    | 'dateOfBirth'
    | 'externalClaimId'
    | 'gender'
    | 'insuranceCarrier'
    | 'insurancePocEmail'
    | 'insurancePocName'
    | 'insurancePocPhone'
    | 'ssn'
>;

export interface EditClaimantPayload {
    readonly additionalInfo?: string | null;
    readonly address: AddressDTO | null;
    readonly dateOfBirth?: string | null;
    readonly email?: string | null;
    readonly externalClaimId?: string | null;
    readonly firstName: string;
    readonly gender?: string | null;
    readonly insuranceCarrier?: string | null;
    readonly insurancePocEmail?: string | null;
    readonly insurancePocName?: string | null;
    readonly insurancePocPhone?: string | null;
    readonly lastName: string;
    readonly phoneNumber?: string | null;
    readonly ssn?: string | null;
}

export interface CreateClaimantPayload {
    readonly claimantAdditionalInfo?: string | null;
    readonly claimantEmail?: string | null;
    readonly claimantFirstName?: string;
    readonly claimantLastName?: string;
    readonly claimantPhone?: string | null;
}

export interface CreateClaimItemPayload {
    readonly description: string;
    readonly featurePublicId: string;
    readonly title: string;
    readonly type: string;
    readonly valueInCents: number;
    readonly itemSubType?: string;
}

export interface UpsertValuatedItemPayload {
    readonly actualCashValue?: number | null;
    readonly age?: number | null;
    readonly category?: string | null;
    readonly claimedAmount?: number;
    readonly classification?: LossItemClassification;
    readonly claimItemPublicId?: string;
    readonly featureType: HomeFeatureType;
    readonly itemAppraisalPublicId?: string | null;
    readonly purchasedAt?: string | null;
    readonly replacementCostValue?: number;
    readonly serviceType?: LossServiceType;
    readonly title: string;
    readonly quantity?: number | null;
}

export interface EditValuatedItemPayload {
    readonly actualCashValue?: number | null;
    readonly category?: string | null;
    readonly claimedAmount?: number;
    readonly claimItemPublicId: string;
    readonly ownershipVerified?: boolean;
    readonly receiptApproved?: boolean | null;
    readonly receiptValue?: number | null;
    readonly replacementCostValue?: number | null;
    readonly title?: string;
    readonly waiveOwnership?: boolean | null;
    readonly waiveOwnershipReason?: string | null;
}

export interface EditClaimItemPayload {
    readonly description: string;
    readonly title: string;
    readonly valueInCents: number;
    readonly expenseType?: string;
    readonly category?: string;
    readonly ownershipVerified?: boolean;
    readonly valueVerified?: boolean;
    readonly waiveOwnership?: boolean;
    readonly waiveOwnershipReason?: string;
}

export interface CreateItemAndFeaturePayload {
    readonly useExistingFeature: boolean;
    readonly feature: CreateClaimantPayload & { readonly claimPublicId: string; readonly type: string };
    readonly item: {
        readonly description?: string;
        readonly title?: string;
        readonly type: string;
        readonly valueInCents: number;
        readonly itemSubType?: string;
    };
}

export type GetPaymentInfoArgs = {
    readonly claimId: string;
    readonly itemIds: string[];
    readonly payeeId: string;
} & ({ readonly waiveDeductible: false } | { readonly waiveDeductible: true; readonly waiveReason: string });

export enum IssuePaymentCtaDisableReason {
    LossOfUseNotCovered = 'loss_of_use_not_covered',
    NoAchPaymentMethod = 'no_ach_payment_method',
    NoInsuredPayee = 'no_insured_payee',
    QuestionnaireIncomplete = 'questionnaire_incomplete',
}

export interface AdvanceLouPaymentTaskDTO {
    readonly alerts: AlertDTO[];
    readonly claimLossOfUse: {
        readonly claimItemId: string | null;
        readonly questionnaire: ClaimLossOfUseQuestionnaire | null;
    };
    readonly issuePaymentCTADisableReason: IssuePaymentCtaDisableReason | null;
    readonly paymentLimitInCents: number;
}

export interface ClaimLossOfUseQuestionnaire {
    readonly affectedFamilyMembersCount: number | null;
    readonly causeOfLossCovered: boolean | null;
    readonly immediateNeedForHousing: boolean | null;
    readonly lossOfUseCovered: boolean | null;
    readonly policyHolderOwnsPets: boolean | null;
    readonly paymentReason: string | null;
}

export interface EditLouQuestionnairePayload {
    readonly affectedFamilyMembersCount?: number | null;
    readonly causeOfLossCovered?: boolean | null;
    readonly immediateNeedForHousing?: boolean | null;
    readonly policyHolderOwnsPets?: boolean | null;
    readonly lossOfUseCovered?: boolean | null;
    readonly paymentReason?: string | null;
}

interface CoverageDecisionOption {
    readonly decision: DecisionOption;
    readonly reasons: DecisionOption[];
}

const ADJUSTER_ROLES_ALLOWED_TO_BE_ASSIGNED = [
    AdjusterRole.Specialist,
    AdjusterRole.Advocate,
    AdjusterRole.TeamLead,
    AdjusterRole.Coach,
    AdjusterRole.Admin,
];

export async function getAvailableAdjusters(state?: string | null): Promise<AdjusterDetails[]> {
    const url = blenderGeneralUrlResolver('/api/v1/adjusters');

    return await axios
        .get(url, {
            params: {
                productType: ProductTypes.Home,
                role: ADJUSTER_ROLES_ALLOWED_TO_BE_ASSIGNED.join(','),
                licenseState: state,
            },
        })
        .then(response => response.data.data);
}

export type HomeClaimIncludeKeysResponse<K extends keyof HomeClaimDataResponse> = {
    readonly data: Pick<HomeClaimDataResponse, K>;
    readonly error: { readonly [key in K]: string | undefined };
};

export async function fetchHomeClaimIncludeKeys<K extends keyof HomeClaimDataResponse>(
    claimId: string,
    keys: K[]
): Promise<HomeClaimIncludeKeysResponse<K>> {
    const url = stringifyUrl(
        {
            url: homeBlenderUrlResolver(`/api/v1/claims/${claimId}`),
            query: {
                include: keys,
            },
        },
        { arrayFormat: 'comma' }
    );

    return await axios.get(url).then(response => response.data.data);
}

export async function fetchHomeClaimDataV2<K extends keyof HomeClaimDataResponse>(
    claimId: string,
    keys: K[]
): Promise<Pick<HomeClaimDataResponse, K>> {
    return (await fetchHomeClaimIncludeKeys(claimId, keys)).data;
}

export async function markAsSuspicious(claimId: string, reasonTypes: string[], note: string) {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/mark_suspicious`);

    const body = {
        flagTypes: reasonTypes,
        comment: note,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function unmarkAsSuspicious(claimId: string) {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/mark_not_suspicious`);

    return await axios.post(url).then(response => response.data);
}

export async function fetchHomeFeature<K extends keyof HomeFeatureDataResponse>(
    featureId: string,
    keys: K[]
): Promise<Pick<HomeFeatureDataResponse, K>> {
    const url = homeBlenderUrlResolver(`/api/v1/features/${featureId}`);

    const response = await axios.get(url, {
        params: {
            include: keys.join(','),
        },
    });

    return response.data.data?.data;
}

export async function fetchClaimStaticData<K extends keyof ClaimStaticDataResponse>(
    keys: K[]
): Promise<Pick<ClaimStaticDataResponse, K>> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/static_data`);

    const response = await axios.get(url, {
        params: {
            include: keys.join(','),
        },
    });

    return response.data.data;
}

export type PaymentDetailsIncludeKeysResponse<K extends keyof PaymentRequestDetailsResponse> = {
    readonly data: Pick<PaymentRequestDetailsResponse, K>;
};

export async function fetchPaymentRequestIncludeKeys<K extends keyof PaymentRequestDetailsResponse>(
    publicId: string,
    keys: K[]
): Promise<PaymentDetailsIncludeKeysResponse<K>> {
    const url = stringifyUrl(
        {
            url: homeBlenderUrlResolver(`/api/v1/payments_control/payment_request/${publicId}`),
            query: {
                include: keys,
            },
        },
        { arrayFormat: 'comma' }
    );

    return await axios.get(url).then(response => response.data.data);
}

export async function fetchPaymentRequestData<K extends keyof PaymentRequestDetailsResponse>(
    publicId: string,
    keys: K[]
): Promise<Pick<PaymentRequestDetailsResponse, K>> {
    return (await fetchPaymentRequestIncludeKeys(publicId, keys)).data;
}

// @deprecated Notifications are deprecated, use Alerts
export async function getNotifications(claimId: string): Promise<{ readonly data?: Record<string, unknown> }> {
    const url = `/backoffice/claims/${claimId}/notifications`;

    return await axios.get(url, { timeout: 60_000 }).then(response => response.data);
}

export async function fetchAdjusters(): Promise<AdjusterDetails[]> {
    const url = blenderGeneralUrlResolver(`/api/v1/adjusters?productType=${ProductTypes.Home}`);

    return await axios.get<{ data: AdjusterDetails[] }>(url).then(response => response.data.data);
}

export async function fetchSearchParams(): Promise<HomeClaimsQueryParamsResponse> {
    const url = homeBlenderUrlResolver('/api/v1/claims/query_params');

    return await axios.get<{ data: HomeClaimsQueryParamsResponse }>(url).then(response => response.data.data);
}

export async function searchHomeClaims(
    searchParams: Partial<HomeClaimsSearchParams>
): Promise<HomeClaimsSearchResponse> {
    const stringifiedParams = stringify(searchParams);
    const url = homeBlenderUrlResolver(`/api/v1/claims?${stringifiedParams}`);

    return await axios.get<{ data: HomeClaimsSearchResponse }>(url).then(response => response.data.data);
}

export async function sendEmail(args: SendEmailArgsWithEntityId): Promise<void> {
    const url = homeBlenderUrlResolver('/api/v1/claims');

    await sendEmailResolver(args, url, true);
}

export async function getFirstResponse(claimPublicId: string): Promise<FirstResponse> {
    const url = `/backoffice/claims/${claimPublicId}/first_response`;

    return await axios.get(url).then(response => response.data.data);
}

export async function cancelFirstResponse({ claimPublicId }: { readonly claimPublicId: string }): Promise<null> {
    const url = `/backoffice/claims/${claimPublicId}/first_response/cancel`;

    return await axios.post(url);
}

export async function sendFirstResponse({ claimPublicId }: { readonly claimPublicId: string }): Promise<null> {
    const url = `/backoffice/claims/${claimPublicId}/first_response/dispatch`;

    return await axios.post(url);
}

export async function startHandlingClaim(claimPublicId: string): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/start_handling`);

    return await axios.post(url).then(response => response.data);
}

export async function getDeclineDecision(claimId: string): Promise<DeclineDecision | null> {
    const url = `/backoffice/claims/${claimId}/claim_decline_decisions`;

    return await axios.get<{ data: DeclineDecision | null }>(url).then(response => response.data.data);
}

export async function submitDeclineReason({
    claimId,
    declined,
    reason,
    description,
    block,
}: {
    readonly claimId: string;
    readonly declined: boolean;
    readonly reason: string;
    readonly description: string;
    readonly block: boolean;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/decline_decisions`);

    const body = { declined, reason, description, blockUser: block };

    return await axios.post(url, body).then(response => response.data);
}

export interface UpdateDeclineDecisionProps {
    readonly claimId: string;
    readonly decisionId: string;
    readonly declined?: boolean;
    readonly reason?: string;
    readonly description?: string;
    readonly block?: boolean;
    readonly informedCustomerVia?: InformedCustomerVia;
}

export async function updateDeclineReason({
    claimId,
    decisionId,
    declined,
    reason,
    description,
    block,
    informedCustomerVia,
}: UpdateDeclineDecisionProps): Promise<null> {
    const url = `/backoffice/claims/${claimId}/claim_decline_decisions/${decisionId}`;

    const body = { declined, reason, description, block_user: block, informed_customer_via: informedCustomerVia };

    return await axios.patch(url, body).then(response => response.data);
}

export async function declineClaim({
    claimId,
    reason,
}: {
    readonly claimId: string;
    readonly reason: string;
}): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/decline`);

    const body = {
        declineReason: reason,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function cancelClaim(publicClaimId: string): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${publicClaimId}/cancel`);

    await axios.post(url);
}

export async function reopenClaim(publicClaimId: string): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${publicClaimId}/reopen`);

    await axios.post(url);
}

export async function addCustomRecipient({
    recipient,
    claimId,
}: {
    readonly recipient: Pick<Recipient, 'email' | 'name' | 'role'>;
    readonly claimId: string;
}): Promise<Recipient> {
    return await addCustomRecipientGeneric(recipient, EntityTypes.HomeClaim, claimId);
}

export async function assignInteraction({
    interactionPublicId,
    claimId,
}: {
    readonly interactionPublicId: string;
    readonly claimId: string;
}): Promise<null> {
    return await assignInteractionGeneric({
        entityType: EntityTypes.HomeClaim,
        entityId: claimId,
        interactionPublicId,
    });
}

export async function dismissInteraction({
    interactionPublicId,
    claimId,
}: {
    readonly interactionPublicId: string;
    readonly claimId: string;
}): Promise<null> {
    return await dismissInteractionGeneric({
        entityType: EntityTypes.HomeClaim,
        entityId: claimId,
        interactionPublicId,
    });
}

export async function manualResponseInteraction({
    interactionPublicId,
    claimId,
    medium,
    body,
}: {
    readonly interactionPublicId: string;
    readonly claimId: string;
    readonly medium: string;
    readonly body: string;
}): Promise<null> {
    return await manualResponseInteractionGeneric({
        entityType: EntityTypes.HomeClaim,
        entityId: claimId,
        medium,
        body,
        interactionPublicId,
    });
}

export async function createNote({ note }: { readonly note: NoteCreateRequest }): Promise<null> {
    return await createNoteGeneric({ note });
}

export async function sendUpdateLink({
    invoiceId,
    methods,
}: {
    readonly invoiceId: string;
    readonly methods: CommunicationMethods;
}) {
    const communication_channels = [];

    if (methods.email) communication_channels.push('email');
    if (methods.sms) communication_channels.push('sms');

    return await axios
        .post(`/backoffice/claim_items/send_update_link`, { invoice_id: invoiceId, communication_channels })
        .then(({ data }) => data);
}

export async function dataExport(claimEncryptedId: string) {
    const url = `/backoffice/exports`;

    const body = {
        entity_type: EntityTypes.HomeClaim,
        entity_ids: [claimEncryptedId],
        filename: null,
        sections: null,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function dataExportSync(claimEncryptedId: string, sections: string[]) {
    const url = `/backoffice/exports/sync`;

    const body = {
        entity_type: EntityTypes.HomeClaim,
        entity_id: claimEncryptedId,
        filename: null,
        sections,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function setVerificationTypes({
    id,
    requiredTypes,
    operatorId,
}: {
    readonly id: string;
    readonly requiredTypes: string[];
    readonly operatorId: string;
}) {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${id}/workflows/sync`);

    return await axios
        .post(url, { requiredTypes, operatorPublicId: operatorId.toString() })
        .then(response => response.data);
}

export async function createBulkPreDefined({
    id,
    requiredTypes,
    operatorId,
}: {
    readonly id: string;
    readonly requiredTypes: PreDefinedTasks[];
    readonly operatorId: string;
}): Promise<[{ readonly publicId: string }]> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${id}/workflows/bulk_sync`);

    return await axios
        .post(url, { requiredTypes, operatorPublicId: operatorId.toString() })
        .then(response => response.data);
}

export async function createPredefinedTask({
    id,
    requestedTaskType,
    actionDate,
    operatorId,
    forcedSquad,
}: {
    readonly id: string;
    readonly requestedTaskType: string;
    readonly actionDate: string;
    readonly operatorId?: string;
    readonly forcedSquad?: string;
}): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${id}/workflow_tasks/`);

    await axios.post(url, {
        requestedTaskType,
        actionDate,
        operatorPublicId: operatorId?.toString(),
        forcedSquad,
    });
}

export async function syncWorkflows({
    id,
    operatorId,
    requiredTypes = [],
}: {
    readonly id: string;
    readonly operatorId: string;
    readonly requiredTypes?: string[];
}) {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${id}/workflows/sync`);

    return await axios
        .post(url, { requiredTypes, operatorPublicId: operatorId.toString() })
        .then(response => response.data);
}

export async function getVerificationReport(id: number): Promise<VerificationReportResponse[]> {
    const url = `/backoffice/claims/${id}/claim_verification_reports`;

    return await axios.get(url).then(response => response.data.data);
}

export async function postVerificationReport({
    id,
    verifications,
    type,
}: {
    readonly id: number;
    readonly verifications: VerificationType;
    readonly type: HomeTaskType;
}): Promise<{ readonly data: VerificationReportResponse }> {
    const url = `/backoffice/claims/${id}/claim_verification_reports`;

    const body = {
        verification_type: type,
        verifications,
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function patchVerificationReport({
    id,
    verifications,
    reportId,
}: {
    readonly id: number;
    readonly verifications: VerificationType;
    readonly reportId: string;
}): Promise<{ readonly data: VerificationReportResponse }> {
    const url = `/backoffice/claims/${id}/claim_verification_reports/${reportId}`;

    const body = { verifications };

    return await axios.patch(url, body).then(response => response.data);
}

export async function getCoverageDecision(id: number): Promise<CoverageDecisionResponse[]> {
    const url = `/backoffice/claims/${id}/claim_coverage_decisions`;

    return await axios.get(url).then(response => response.data.data);
}

export async function reopenFeature(featurePublicId: string): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/features/${featurePublicId}/reopen`);

    return await axios.post(url).then(response => response.data);
}

export async function submitCoverageDecision({
    claimPublicId,
    decision,
    reason,
    supportingData,
}: {
    readonly claimPublicId?: string;
    readonly decision: string;
    readonly reason: string;
    readonly supportingData: Record<string, never> | VerificationReportResponse | undefined;
}) {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/coverage_decisions`);

    const body = { decision, reason, supportingData };

    return await axios.post(url, body).then(response => response.data);
}

export async function markAsDone({
    claimId,
    taskId,
    operatorId,
    taskType,
}: {
    readonly claimId: string;
    readonly taskId: string;
    readonly operatorId: string;
    readonly taskType: HomeTaskType | undefined;
}): Promise<null> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/workflow_tasks/${taskId}/done`);

    return await axios.post(url, { operatorPublicId: operatorId.toString(), taskType }).then(response => response.data);
}

export async function saveAttachments(attachments: HomeAttachment[], claimId: string): Promise<Attachment[] | null> {
    const url = `/backoffice/claims/${claimId}/save_attachments`;
    const body = {
        attachments: attachments.reduce(
            (acc, { url, description = '', type = '' }, idx) => ({
                ...acc,
                [idx]: { url, description, type },
            }),
            {}
        ),
    };

    return await axios.post(url, body).then(response => response.data);
}

export async function uploadFile(file: File): Promise<{ readonly url: string }> {
    const url = '/backoffice/claims/upload';
    const body = new FormData();

    body.append('upload', file);

    return await axios.post(url, body).then(({ data }) => data);
}

export async function createCustomTask({
    description,
    title,
    referenceType,
    operatorPublicId,
    entityPublicId,
    entityType,
    actionDate,
    forcedSquad,
}: NewCustomTaskContent): Promise<{ readonly task: { readonly task: { readonly publicId: string } } }> {
    const url = blenderGeneralUrlResolver('/api/v1/custom_tasks');

    const customTask = {
        description,
        title,
        referenceType,
        operatorPublicId,
        entityPublicId,
        entityType,
        actionDate,
        forcedSquad,
    };

    return await axios.post(url, customTask).then(({ data }) => data.data);
}

export async function getCustomTask(
    publicId: string
): Promise<{ readonly data: [{ readonly publicId: string; readonly description: string }] }> {
    const url = blenderGeneralUrlResolver(`/api/v1/custom_tasks/?publicId=${publicId}`);

    return await axios.get(url).then(response => response.data);
}

export async function updateCustomTaskDescription({
    description,
    publicId,
}: {
    readonly description?: string;
    readonly publicId: string;
}): Promise<void> {
    const url = blenderGeneralUrlResolver(`/api/v1/custom_tasks/${publicId}`);

    return await axios.patch(url, { description }).then(({ data }) => data);
}

/**
 * @deprecated
 * Use `getPrePaymentInfoV2` instead.
 */
export async function getPrePaymentInfo(claimItems: HomeClaimItem[]): Promise<PrePaymentInfo> {
    const itemIds = claimItems.map(item => item.id);
    const itemsQuery = encodeURIComponent(JSON.stringify(itemIds));

    const url = `/backoffice/claim_items/pre_payment_info?claim_items=${itemsQuery}`;

    return await axios.get(url).then(response => response.data);
}

export async function getPrePaymentInfoV2({
    claimPublicId,
    itemPublicIds,
}: {
    readonly claimPublicId: string;
    readonly itemPublicIds: string[];
}): Promise<PrePaymentInfoV2> {
    const url = homeBlenderUrlResolver(
        `/api/v1/claims/${claimPublicId}/pre_payment_info?claimItemPublicIds=${itemPublicIds.join(',')}`
    );

    return await axios.get<{ data: PrePaymentInfoV2 }>(url).then(response => response.data.data);
}

export async function getPaymentInfo({
    claimId,
    itemIds,
    payeeId,
    ...deductibleProps
}: {
    readonly claimId: string;
    readonly itemIds: string[];
    readonly payeeId: string;
} & (
    | { readonly waiveDeductible: false }
    | { readonly waiveDeductible: true; readonly waiveReason: string }
)): Promise<PaymentInfo> {
    const body = {
        claim_item_ids: itemIds,
        claim_id: claimId,
        payee_id: payeeId,
        waive_deductible: deductibleProps.waiveDeductible,
        ...(deductibleProps.waiveDeductible ? { waive_deductible_reason: deductibleProps.waiveReason } : null),
    };

    const url = '/backoffice/claim_items/payment_info';

    return await axios.post(url, body).then(response => response.data);
}

export async function pay(
    invoiceId: string,
    paymentMethod: PaymentMethodType,
    claimId: string,
    claimItemsIds: string[],
    paymentMethodId?: string,
    checkMemo?: string | undefined,
    waiveDeductibleReason?: string
): Promise<void> {
    const url = `/backoffice/claim_items/pay`;
    const body = {
        invoice_id: invoiceId,
        payment_method_type: paymentMethod,
        waive_deductible_reason: waiveDeductibleReason,
        claim_id: claimId,
        claim_item_ids: claimItemsIds,
        payment_method_id: paymentMethodId,
        payout_options: {
            statementDescriptor: checkMemo,
        },
    };

    return await axios.post(url, body, { timeout: 60_000 }).then(response => response.data);
}

export async function cancelAutoDecline(claimPublicId: string): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/cancel_auto_decline`);

    await axios.post(url);
}

export async function closeClaim(claimId: string): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/close`);

    return await axios.post(url).then(response => response.data);
}

export interface UpdatePoliceReportAttributes {
    readonly incident: string;
    readonly precinct: string;
    readonly officer: string;
}

export type HomeClaimUpdateParams = {
    readonly settled?: boolean;
    readonly settled_at?: number;
    readonly test?: boolean;
    readonly lossType?: string;
    readonly lossSubType?: string;
    readonly catastropheId?: number | null;
    readonly incidentAddress?: Address;
    readonly incidentLocation?: IncidentLocation;
    readonly dateOfLoss?: Date;
    readonly policyPublicId?: string;
    readonly policeReportData?: UpdatePoliceReportAttributes;
};

export async function updateClaim<T extends HomeClaimUpdateParams>(claimId: string, fields: T): Promise<T> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/update`);

    const body = {
        ...fields,
    };

    return await axios.put(url, body).then(response => response.data.data);
}

export async function getPaymentRequestDetails(
    requestPublicID: string
): Promise<Pick<PaymentRequestDetailsResponse, 'payment_request_details'>> {
    return await fetchPaymentRequestData(requestPublicID, ['payment_request_details']);
}

export async function getAuthorityRequestDetails(requestPublicID: string): Promise<PaymentRequestDetailsResponse> {
    return await fetchPaymentRequestData(requestPublicID, [
        'payment_request_details',
        'payment_breakdown',
        'payment_control_permissions',
        'payment_request',
    ]);
}

export async function issuePayment(body: {
    readonly product: ProductTypes;
    readonly paymentRequestPublicId: string;
    readonly claimItemIds: string;
    readonly taskPublicId: string;
}) {
    const url = blenderGeneralUrlResolver(`/api/v1/payments_control/issue_payment`);

    return await axios.post(url, body).then(response => response.data.data);
}

export async function addLitigation(
    claimId: string,
    adversePartyType: string,
    adversePartyDetails: string
): Promise<ClaimsLitigationResponse> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/litigation`);
    const response = await axios.post<ClaimsLitigationResponse>(url, { adversePartyType, adversePartyDetails });

    return response.data;
}

export interface CreateLitigationPayload {
    readonly adversePartyType: string;
    readonly adversePartyDetails?: string;
    readonly relatedEntityId?: string;
    readonly relatedEntityType?: string;
}

export async function createLitigation({
    claimPublicId,
    payload,
}: {
    readonly claimPublicId?: string;
    readonly payload: CreateLitigationPayload;
}): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/litigations`);

    await axios.post(url, payload);
}

export interface CreateRepresentativePayload {
    readonly address: AddressDTO | null;
    readonly email?: string | null;
    readonly lawFirm?: string | null;
    readonly name: string;
    readonly phone?: string | null;
    readonly referenceId?: string | null;
    readonly referenceType?: string | null;
    readonly type: string;
}

export async function createRepresentative({
    claimPublicId,
    payload,
}: {
    readonly claimPublicId?: string;
    readonly payload: CreateRepresentativePayload;
}): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/representatives`);

    await axios.post(url, payload);
}

export async function editRepresentative({
    claimPublicId,
    payload,
    representativeId,
}: {
    readonly claimPublicId?: string;
    readonly payload: CreateRepresentativePayload;
    readonly representativeId?: string;
}): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/representatives/${representativeId}`);

    await axios.patch(url, payload);
}

export async function deleteRepresentative({
    claimPublicId,
    representativeId,
}: {
    readonly claimPublicId?: string;
    readonly representativeId?: string;
}): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/representatives/${representativeId}`);

    await axios.delete(url);
}

export async function getClaimAlerts(claimId: string): Promise<Alert[]> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/alerts`);

    return await axios.get(url).then(response => response.data.data);
}

export async function getAggregatedNotifications(claimId: string): Promise<unknown[]> {
    const [notifications, alerts] = await Promise.all([getNotifications(claimId), getClaimAlerts(claimId)]);

    const data = notifications.data ?? {};

    alerts.forEach(alert => {
        data[alert.type] = alert;
    });

    return Object.values(data);
}

export async function updateCoverageDecision({
    coverageAnalysis,
    coverageDecision,
    coverageReason,
    featureId,
}: {
    readonly coverageAnalysis?: string | null;
    readonly coverageDecision?: string | null;
    readonly coverageReason?: string | null;
    readonly featureId: string | undefined;
}): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/features/${featureId}/coverage_decision`);
    const body = { coverageAnalysis, coverageDecision, coverageReason };
    const response = await axios.patch(url, body);

    return response.data;
}

export async function updateLinkEndorsement({
    linkedEntityType,
    linkedEntityId,
    claimItemPublicIds,
    claimPublicId,
}: {
    readonly linkedEntityType: LinkedEntityType;
    readonly linkedEntityId: string;
    readonly claimItemPublicIds: string[];
    readonly claimPublicId: string;
}): Promise<void> {
    const url = `/api/v1/claims/${claimPublicId}/link_endorsement`;
    const body = { linkedEntityType, linkedEntityId, claimItemPublicIds };
    const response = await homeBlender.put(url, body);

    return response.data;
}

export async function deleteAllClaimLinkEndorsements(claimPublicId: string): Promise<void> {
    const url = `/api/v1/claims/${claimPublicId}/link_endorsement`;
    const response = await homeBlender.delete(url);

    return response.data;
}

export async function getAttachments(claimId?: string): Promise<HomeClaimAttachment[]> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/attachments?includeFraudDetections=true`);
    const response = await axios.get<{ data: HomeClaimAttachment[] }>(url);

    return response.data.data;
}

export async function updateClaimUserPreferences({
    claimId,
    preferences,
    type,
}: {
    readonly claimId?: string;
    readonly preferences: UserPreferencesPayload;
    readonly type: ClaimUserPreferencesType;
}): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/user_preferences`);

    await axios.post(url, {
        preferences,
        type,
    });
}

export async function createManualClaim(manualClaimData: CreateManualClaimDTO): Promise<HomeCreateManualClaimResponse> {
    const url = homeBlenderUrlResolver('/api/v1/claims');

    const response = await axios.post(url, manualClaimData);

    return response.data.data;
}

export async function setLossOfUseCoverageStatus(claimId: string, status: string): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimId}/loss_of_use_coverage`);
    const body = { status };

    return await axios.put(url, body).then(response => response.data.data);
}

export async function sendWorkflowEmail({
    claimPublicId,
    email,
    taskPublicId,
}: {
    readonly claimPublicId?: string;
    readonly email?: SendEmailRequestDTO;
    readonly taskPublicId?: string;
}) {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/workflow_tasks/${taskPublicId}/send_email`);

    await axios.post(url, email);
}

export async function geClaimPolicyVersionPdfUrl(
    claimId: string
): Promise<Pick<HomeClaimDataResponse, 'claim_policy_version_pdf_url'>> {
    return await fetchHomeClaimDataV2(claimId, ['claim_policy_version_pdf_url']);
}

export const openClaimPolicyVersionPdf = async (claimPublicId: string) => {
    try {
        const { claim_policy_version_pdf_url } = await geClaimPolicyVersionPdfUrl(claimPublicId);

        if (claim_policy_version_pdf_url == null) return;

        window.open(claim_policy_version_pdf_url.url);
    } catch (e) {
        // do nothing for now
    }
};

export const createItemAndFeature = async (
    claimPublicId?: string,
    payload?: CreateItemAndFeaturePayload
): Promise<{ readonly featurePublicId: string; readonly itemPublicId: string }> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/item_and_feature`);
    const response = await axios.post<{ data: { featurePublicId: string; itemPublicId: string } }>(url, payload);

    return response.data.data;
};

export const createItem = async (
    claimPublicId?: string,
    payload?: CreateClaimItemPayload
): Promise<{ readonly item: ClaimItemV2 }> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/items`);
    const response = await axios.post<{ data: { item: ClaimItemV2 } }>(url, payload);

    return response.data.data;
};

export const editItem = async (
    claimPublicId?: string,
    payload?: Partial<EditClaimItemPayload>,
    publicId?: string
): Promise<{ readonly feature: ClaimFeature }> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/items/${publicId}`);
    const response = await axios.patch<{ data: { feature: ClaimFeature } }>(url, payload);

    return response.data.data;
};

export const editFeature = async ({
    payload,
    publicId,
}: {
    readonly payload: EditFeaturePayload;
    readonly publicId?: string;
}) => {
    const url = homeBlenderUrlResolver(`/api/v1/features/${publicId}`);

    await axios.patch(url, payload);
};

export const editClaimant = async ({
    featurePublicId,
    payload,
}: {
    readonly featurePublicId?: string;
    readonly payload: EditClaimantPayload;
}): Promise<void> => {
    const url = homeBlenderUrlResolver(`/api/v1/features/${featurePublicId}`);

    await axios.patch(url, { claimant: payload });
};

export const upsertValuatedItem = async ({
    claimPublicId,
    payload,
}: {
    readonly claimPublicId?: string;
    readonly payload?: UpsertValuatedItemPayload;
}): Promise<void> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/valuated_items`);

    await axios.post(url, payload);
};

export const editValuatedItem = async ({
    appraisalPublicId,
    claimPublicId,
    payload,
}: {
    readonly appraisalPublicId?: string | null;
    readonly claimPublicId?: string;
    readonly payload?: EditValuatedItemPayload;
}): Promise<void> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/valuated_items/${appraisalPublicId}`);

    await axios.patch(url, payload);
};

export interface CreateDepreciationItemPayload {
    readonly claimItemPublicId: string[];
    readonly featureType: HomeFeatureType;
}

export const createDepreciationItem = async ({
    claimPublicId,
    payload,
}: {
    readonly claimPublicId?: string;
    readonly payload: CreateDepreciationItemPayload;
}): Promise<void> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/valuated_items/depreciation_item`);

    await axios.post(url, payload);
};

export interface VerifyValuatedItemOwnershipPayload {
    readonly approved: boolean;
    readonly claimItemPublicId: string;
}

export const verifyValuatedItemOwnership = async ({
    appraisalPublicId,
    claimPublicId,
    payload,
}: {
    readonly appraisalPublicId?: string | null;
    readonly claimPublicId?: string;
    readonly payload: VerifyValuatedItemOwnershipPayload;
}): Promise<void> => {
    const url = homeBlenderUrlResolver(
        `/api/v1/claims/${claimPublicId}/valuated_items/${appraisalPublicId}/verify_ownership`
    );

    await axios.put(url, payload);
};

export interface SaveClaimAttachmentParams {
    readonly url?: string;
    readonly filePublicId?: string;
    readonly contentType: string;
    readonly description: string;
    readonly type: string;
}

export const saveClaimAttachments = async (claimPublicId: string, attachments: SaveClaimAttachmentParams[]) => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/attachments`);

    await axios.post(url, { attachments });
};

export interface UpdateObtainFilesQuestionnaireParams {
    readonly dwellingDamages?: {
        readonly insuredHasOwnContractor: boolean | null;
        readonly reasonEstimateNotNeeded: string | null;
        readonly reasonPhotosNotNeeded: string | null;
        readonly shouldObtainEstimateOfDamages: boolean | null;
        readonly shouldObtainPhotosOfDamage: boolean | null;
    };
    readonly policeReport?: {
        readonly obtainIncidentId: boolean;
        readonly incidentIdReasons: string[];
        readonly obtainFullReport: boolean | null;
        readonly fullReportReasons: string[];
    };
    readonly landlord?: {
        readonly obtainLandlordDetails: boolean;
    };
    readonly fireReport?: {
        readonly obtainFireReport: boolean | null;
        readonly fireReportReasons: string[];
        readonly dispatchCauseAndOrigin: boolean | null;
        readonly causeAndOriginReason: string;
        readonly causeAndOriginLeadApprovalDecision: boolean | null;
        readonly causeAndOriginLeadDecisionReason: string;
    };
}

export const updateClaimObtainFilesQuestionnaire = async (
    claimPublicId: string,
    params: UpdateObtainFilesQuestionnaireParams
): Promise<null> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/missing_info_questionnaire`);

    return await axios.put(url, { ...params });
};

export const editLouQuestionnaire = async ({
    claimPublicId,
    payload,
}: {
    readonly claimPublicId: string;
    readonly payload: EditLouQuestionnairePayload;
}): Promise<null> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/loss_of_use`);

    return await axios.patch(url, payload);
};

export const initAdvanceLouPayment = async ({
    claimPublicId,
    valueInCents,
}: {
    readonly claimPublicId: string;
    readonly valueInCents: number;
}) => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/initiate_advance_loss_of_use_payment`);
    const response = await axios.post<{ data: { claimItemPublicId: string } }>(url, { valueInCents });

    return response.data.data.claimItemPublicId;
};

export const updateClaimVerificationQuestionnaire = async <TType extends ClaimVerificationQuestionnaireType>(
    claimPublicId: string,
    verificationType: TType,
    verifications: Partial<AllVerificationParams>
): Promise<null> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/claim_verification_reports`);

    return await axios.post(url, { verificationType, verifications });
};

export const getDispatchVendorDialogDefaults = async (
    claimPublicId?: string,
    dispatchType?: DispatchType
): Promise<DispatchVendorDialogDefaults> => {
    const url = homeBlenderUrlResolver(
        `/api/v1/claims/${claimPublicId}/dispatch_vendor_dialog_defaults${
            dispatchType != null ? `?dispatchType=${dispatchType}` : ''
        }`
    );
    const response = await axios.get<{ data: DispatchVendorDialogDefaults }>(url);

    return response.data.data;
};

export const getDispatchVendorRequestTypes = async (): Promise<DispatchVendorRequestType[]> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/incident_request_types`);

    const response = await axios.get<{ data: DispatchVendorRequestType[] }>(url);

    return response.data.data;
};

export const rejectClaimItems = async (claimPublicId: string, payload: RejectClaimItemAttributes): Promise<void> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/items/reject`);

    const response = await axios.put(url, payload);

    return response.data.data;
};

export const activateClaimItems = async (claimPublicId: string, itemPublicId: string[]): Promise<void> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/items/activate`);

    const response = await axios.put(url, { itemPublicId });

    return response.data.data;
};

export interface ClaimItemsFilters {
    readonly featurePublicId?: string;
    readonly featureReference?: string;
    readonly itemType?: ClaimItemType;
    readonly excludeRejectedItems?: boolean;
}

export const getClaimItems = async (
    claimPublicId: string,
    filters?: ClaimItemsFilters
): Promise<ClaimItemsTableDTO> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/items_table`);

    const response = await axios.get<{ data: { data: { claim_items_table: ClaimItemsTableDTO } } }>(url, {
        params: filters,
    });

    return response.data.data.data.claim_items_table;
};

export interface ValuatedItemsFilters {
    readonly featureType?: HomeFeatureType;
    readonly viewMode?: ValuatedItemsViewMode;
}

export const getValuatedItems = async (
    claimPublicId: string,
    filters?: ValuatedItemsFilters
): Promise<ValuatedItemsTableDTO> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/valuated_items_table`);

    const response = await axios.get<{ data: { data: { claim_valuated_items_table: ValuatedItemsTableDTO } } }>(url, {
        params: filters,
    });

    return response.data.data.data.claim_valuated_items_table;
};

export interface ImportValuatedItemsPayload {
    readonly featureType: HomeFeatureType;
    readonly filePublicId: string;
}

export interface ValuatedItemsImportStatusDTO {
    readonly processedItemsCount: number;
    readonly publicId: string;
    readonly status: ValuatedItemsImportStatus;
    readonly totalItemsCount: number;
}

export const importValuatedItems = async ({
    claimPublicId,
    payload,
}: {
    readonly claimPublicId?: string;
    readonly payload: ImportValuatedItemsPayload;
}): Promise<ValuatedItemsImportStatusDTO> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/valuated_items/import`);

    const response = await axios.post<{ data: ValuatedItemsImportStatusDTO }>(url, payload);

    return response.data.data;
};

export enum ValuatedItemsImportStatus {
    Failed = 'failed',
    InProgress = 'in_progress',
    Success = 'success',
}

export const getValuatedItemsImportStatus = async ({
    claimPublicId,
    importTrackerPublicId,
}: {
    readonly claimPublicId?: string;
    readonly importTrackerPublicId?: string;
}): Promise<ValuatedItemsImportStatusDTO> => {
    const url = homeBlenderUrlResolver(
        `/api/v1/claims/${claimPublicId}/valuated_items/import/${importTrackerPublicId}`
    );
    const response = await axios.get<{ data: ValuatedItemsImportStatusDTO }>(url);

    return response.data.data;
};

export const createAppraisalsSyncRequest = async ({
    claimPublicId,
}: {
    readonly claimPublicId?: string;
}): Promise<void> => {
    await axios.post(homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/valuated_items_sync_request`));
};

export const getItemCategories = async (): Promise<string[]> => {
    const url = homeBlenderUrlResolver('/api/v1/claims/item_categories');

    const response = await axios.get<{ data: string[] }>(url);

    return response.data.data;
};

export const getWaiveOwnershipReasons = async (claimPublicId: string): Promise<string[]> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/items/waive_ownership_reasons`);

    const response = await axios.get<{ data: string[] }>(url);

    return response.data.data;
};

export const getDeclineReasons = async (claimPublicId: string): Promise<DeclineReason[]> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/decline_reasons`);

    const response = await axios.get<{ data: DeclineReason[] }>(url);

    return response.data.data;
};

export const getClaimantDetailsTask = async ({
    claimPublicId,
    featurePublicId,
}: {
    readonly claimPublicId?: string;
    readonly featurePublicId?: string;
}): Promise<ClaimantDetailsTask> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/feature_claimant_details_task`);

    const response = await axios.get<{ data: { data: { claimant_details_task: ClaimantDetailsTask } } }>(url, {
        params: {
            featurePublicId,
        },
    });

    return response.data.data.data.claimant_details_task;
};

export interface EditLiabilityCasePayload {
    readonly adjusterAssessment?: string | null;
    readonly demandDetails?: EditDemandDetailsPayload;
}

export interface EditDemandDetailsPayload {
    readonly amountClaimed?: number;
    readonly dateReceived?: string;
    readonly dueDate?: string | null;
    readonly type?: string[];
}

export const editLiabilityCase = async ({
    claimPublicId,
    liabilityCasePublicId,
    payload,
}: {
    readonly claimPublicId?: string;
    readonly liabilityCasePublicId?: string;
    readonly payload: EditLiabilityCasePayload;
}): Promise<void> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/liability_cases/${liabilityCasePublicId}`);

    await axios.patch(url, payload);
};

export const getInitialReserves = async ({
    claimPublicId,
    featureType,
}: {
    readonly claimPublicId?: string;
    readonly featureType?: HomeFeatureType;
}): Promise<ClaimInitialReservesDTO> => {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/claim_initial_reserves`);

    const response = await axios.get<{ data: { claim_initial_reserves: ClaimInitialReservesDTO } }>(url, {
        params: { featureType },
    });

    return response.data.data.claim_initial_reserves;
};

export interface PayeePayload {
    readonly address: {
        readonly city: string;
        readonly country: string;
        readonly postalCode: string;
        readonly state?: string;
        readonly street: string;
        readonly streetNumber: string;
    };
    readonly accountAba?: string | null;
    readonly accountName?: string | null;
    readonly accountNumber?: string | null;
    readonly bankAccountId?: string | null;
    readonly bankId?: string | null;
    readonly dateOfBirth: string;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly payeeType: PayeeType;
    readonly ssn: string | null;
}

export async function createPayee({
    claimPublicId,
    payload,
}: {
    readonly claimPublicId: string | undefined;
    readonly payload: PayeePayload;
}): Promise<void> {
    const url = homeBlenderUrlResolver(`/api/v1/claims/${claimPublicId}/payees`);

    await axios.post(url, payload);
}
