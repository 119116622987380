import { useMultiStripe } from '@lemonade-hq/boutique';
import type { Maybe } from '@lemonade-hq/ts-helpers';
import { camelToSnakeCaseKeys, snakeToCamelCaseKeys } from '@lemonade-hq/ts-helpers';
import type { Stripe } from '@stripe/stripe-js';
import { toReadable } from './StringUtils';
import { formatAddress } from 'commons/AddressUtils';
import type { Address } from 'models/Address';
import { FeatureName } from 'models/CarFeatures';
import { Carrier } from 'models/CarQuote';
import type { Location, QuoteAddress } from 'models/CarShared';
import type { Type } from 'models/Driver';
import { InsuredTypes } from 'models/Driver';
import type { InvolvedParty, InvolvedPerson, InvolvedVehicle } from 'models/InvolvedParty';
import { InvolvedPartyType, InvolvedPersonRole } from 'models/InvolvedParty';

const validVinRegex = new RegExp('^[A-Z0-9]{17}$');
const validEinRegex = /^\d{2}-?\d{7}$/;

export function isValidVin(vin: string): boolean {
    return validVinRegex.test(vin);
}

export function isValidEin(ein: string): boolean {
    return validEinRegex.test(ein);
}

export function carTitle({
    year,
    make,
    model,
}: {
    readonly year: number | string;
    readonly make: string;
    readonly model: string;
}): string {
    return `${year} ${make} ${model}`;
}

export const FriendlyInsuredType: { readonly [key in Type]: string } = {
    [InsuredTypes.PrimaryNamedInsured]: 'Primary',
    [InsuredTypes.SecondaryNamedInsured]: 'Secondary',
    [InsuredTypes.AdditionalDriver]: 'Additional',
};

export enum PolicyCoverages {
    MedicalPaymentsCoverage = 'med_limit',
    UninsuredUnderinsuredPerPersonCoverage = 'um_uim_bi_per_person_limit',
    UninsuredUnderinsuredPerIncidentCoverage = 'um_uim_bi_per_incident_limit',
    UnderinsuredPerPersonCoverage = 'uim_bi_per_person_coverage',
    UnderinsuredPerIncidentCoverage = 'uim_bi_per_incident_coverage',
    UninsuredPerPersonCoverage = 'um_bi_per_person_coverage',
    UninsuredPerIncidentCoverage = 'um_bi_per_incident_coverage',
    BodilyInjuryPerPersonCoverage = 'bi_per_person_limit',
    BodilyInjuryPerIncidentCoverage = 'bi_per_incident_limit',
    PropertyDamageCoverage = 'pd_limit',
    PersonalInjuryProtectionPerPersonCoverage = 'pip_per_person_limit',
    PersonalInjuryProtectionPerIncidentCoverage = 'pip_per_incident_limit',
    UmPdCoverage = 'um_pd_limit',
    UmPdDeductible = 'um_pd_deductible',
    UimPdCoverage = 'uim_pd_limit',
    UimPdDeductible = 'uim_pd_deductible',
    UmuimPdCoverage = 'um_uim_pd_limit',
    UmuimPdDeductible = 'um_uim_pd_deductible',
}

export enum VehicleCoverages {
    CollisionDeductible = 'coll_deductible',
    ComprehensiveDeductible = 'comp_deductible',
    GlassWindshieldDeductible = 'comp_glass_deductible',
    RideshareRentalCoverage = 'rental_per_day_limit',
    RideshareRentalMaxDaysGap = 'rental_max_days_limit',
    Gap = 'gap_coverage',
}

export const Coverages = {
    ...PolicyCoverages,
    ...VehicleCoverages,
};

export type Coverages = PolicyCoverages | VehicleCoverages;

export const FriendlyCoverage: { readonly [key in Coverages]: string } = {
    [Coverages.MedicalPaymentsCoverage]: 'MedPay',
    [Coverages.UninsuredUnderinsuredPerPersonCoverage]: 'UM/UIMBI',
    [Coverages.UninsuredUnderinsuredPerIncidentCoverage]: 'UM/UIMBI',
    [Coverages.UnderinsuredPerPersonCoverage]: 'UIMBI',
    [Coverages.UnderinsuredPerIncidentCoverage]: 'UIMBI',
    [Coverages.UninsuredPerPersonCoverage]: 'UMBI',
    [Coverages.UninsuredPerIncidentCoverage]: 'UMBI',
    [Coverages.BodilyInjuryPerPersonCoverage]: 'BI per Person',
    [Coverages.BodilyInjuryPerIncidentCoverage]: 'BI per Accident',
    [Coverages.PropertyDamageCoverage]: 'PD Liability',
    [Coverages.PersonalInjuryProtectionPerPersonCoverage]: 'Personal Injury',
    [Coverages.PersonalInjuryProtectionPerIncidentCoverage]: 'Personal Injury',
    [Coverages.UmPdCoverage]: 'UMPD',
    [Coverages.UmPdDeductible]: 'UMPD',
    [Coverages.UimPdCoverage]: 'UIMPD',
    [Coverages.UimPdDeductible]: 'UIMPD',
    [Coverages.UmuimPdCoverage]: 'UM/UIMPD',
    [Coverages.UmuimPdDeductible]: 'UM/UIMPD',
    [Coverages.CollisionDeductible]: 'Collision',
    [Coverages.ComprehensiveDeductible]: 'Comprehensive',
    [Coverages.GlassWindshieldDeductible]: 'Glass Windshield',
    [Coverages.RideshareRentalCoverage]: 'Rental',
    [Coverages.RideshareRentalMaxDaysGap]: 'Rental',
    [Coverages.Gap]: 'Gap',
};

export function involvedPartyTitle(involvedParty?: InvolvedParty): string {
    if (involvedParty == null) return '';

    return involvedParty.type === InvolvedPartyType.Vehicle ? vehicleTitle(involvedParty) : involvedParty.name;
}

export const isPersonKnown = ({ name }: InvolvedPerson): boolean => {
    return name !== '';
};

export const isVehicleKnown = ({ make }: InvolvedVehicle): boolean => {
    return make !== '';
};

export const isInvolvedPartyKnown = (involvedParty: InvolvedParty): boolean => {
    return involvedParty.type === InvolvedPartyType.Vehicle
        ? isVehicleKnown(involvedParty)
        : isPersonKnown(involvedParty);
};

export function vehicleTitle(vehicle?: {
    readonly year?: number;
    readonly make: string;
    readonly model?: string;
}): string {
    if (vehicle == null) return '';

    return isVehicleKnown(vehicle as InvolvedVehicle)
        ? [vehicle.year, vehicle.make, vehicle.model].filter(x => x != null && x !== '').join(' ')
        : 'Unknown Vehicle';
}

export function personName(person: { readonly name: string }): string {
    return person.name === '' ? 'Unknown Person' : person.name;
}

export function featureName(name: FeatureName): string {
    switch (name) {
        case FeatureName.UIMBI:
            return 'Underinsured Motorist Bodily Injury';
        case FeatureName.UMBI:
            return 'Uninsured Motorist Bodily Injury';
        case FeatureName.UIMPD:
            return 'Underinsured Motorist Property Damage';
        case FeatureName.UMPD:
            return 'Uninsured Motorist Property Damage';
        case FeatureName.Funeral:
            return 'Non Medical Expenses - Funeral';
        case FeatureName.Death:
            return 'Non Medical Expenses - Death';
        case FeatureName.LostWages:
            return 'Non Medical Expenses - Lost Wages';
        case FeatureName.EssentialServices:
            return 'Non Medical Expenses - Essential Services';
        default:
            return toReadable(name);
    }
}

export function isLegalGuardian(person: InvolvedPerson): boolean {
    return person.role === InvolvedPersonRole.Other && (person.connectedInvolvedPeople ?? []).length > 0;
}

export const GENDER_MAP = {
    unknown: 'other',
};

export function quoteAddressToLocation(address: QuoteAddress): Location {
    return {
        streetNumber: address.streetNumber ?? null,
        street: address.street,
        city: address.city,
        unit: address.unit ?? null,
        state: address.state,
        country: address.country,
        postalCode: address.postalCode,
        placeId: null,
        lat: null,
        lng: null,
        formattedAddress: formatAddress(address),
        rawData: null,
        timezone: null,
        addressExternalId: undefined,
    };
}

export function addressToLocation(address: Address): Location {
    return snakeToCamelCaseKeys(address);
}

export function locationToAddress(location: Location): Address {
    return camelToSnakeCaseKeys(location);
}

export function getStripeKeyFromCarrier(carrier: string): string {
    if (carrier === Carrier.HomeStateCountyMutual) {
        return import.meta.env.VITE_STRIPE_KEY_HSCM as string;
    }

    return import.meta.env.VITE_STRIPE_KEY as string;
}

export const carriersPublishableKeys = {
    [Carrier.LemonadeInsuranceCompany]: import.meta.env.VITE_STRIPE_KEY as string,
    [Carrier.HomeStateCountyMutual]: import.meta.env.VITE_STRIPE_KEY_HSCM as string,
    [Carrier.MetromileInsuranceCompany]: import.meta.env.VITE_STRIPE_KEY_MM as string,
} as const;

export function useStripeClientFromCarrier(): (carrier: string) => Maybe<Stripe> {
    const { clients } = useMultiStripe(Object.values(carriersPublishableKeys));

    return (carrier: string) => clients[carriersPublishableKeys[carrier] ?? import.meta.env.VITE_STRIPE_KEY];
}
