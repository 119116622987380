import { getTypeFromContentType, Image, NoticeFilledIcon, SecondaryButton } from '@lemonade-hq/bluis';
import { trackEvent } from '@lemonade-hq/boutique';
import { Flex } from '@lemonade-hq/cdk';
import { COLORS } from '@lemonade-hq/tokens';
import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { StatefulTooltip } from '../Tooltip';
import DraggableImage from './DraggableImage';
import { getInlineUrl } from './utils';
import { isFraud as isFraudAttachment } from 'commons/AttachmentsUtils';
import type { Attachment } from 'models/Attachment';

export const StyledAttachmentPreview = styled.div<{ readonly isSelected: boolean; readonly isFullHeight: boolean }>`
    position: relative;
    height: ${({ isFullHeight }) => (isFullHeight ? '100%' : 'calc(100% - 88px)')};
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
`;

export const StyledImagePreview = styled(Flex)`
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    max-height: 100%;
    height: 100%;
    width: 100%;
    flex: 1;
    background-position: center;
    position: relative;
    justify-content: center;
    align-items: center;
    img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
    }
`;

export const StyledPdfIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: 0 none;
    border-radius: 10px;
`;
const IconContainer = styled(NoticeFilledIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
`;

const Mask = styled(Flex)`
    position: absolute;
    inset: 0;
    border-radius: 5px;
    background-color: ${COLORS.scarlet};
    opacity: 0.2;
    z-index: 1100;
`;
const StyledVideoWrapper = styled(Flex)`
    display: flex;
    height: 100%;
    justify-content: center;
    flex: 1;
`;
const ThumbnailWrapper = styled(Flex)`
    justify-content: center;
    height: 100%;
    width: 100%;
`;

const DownloadPreview = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    p {
        font-size: 16px;
        margin-bottom: 20px;
        color: ${COLORS.white};
    }
`;

const DownloadLink = styled.a`
    color: inherit;
    display: block;
    height: 100%;

    &:hover {
        text-decoration: none;
    }
`;

const StyledImageThumb = styled(Image)<{ readonly isPlaceHolder?: boolean }>`
    height: 100%;
    width: 100%;
    object-fit: ${({ isPlaceHolder }) => (isPlaceHolder ? 'none' : 'contain')};
`;
const StyledThumbnail = styled.div<{
    readonly isFraud: boolean;
    readonly isSelected?: boolean;
    readonly error?: boolean;
}>`
    position: relative;
    height: 50px;
    width: 50px;
    transition: 0.2s all ease-in-out;
    text-align: center;
    cursor: pointer;
    background-color: ${({ error }) => (error ? COLORS.night : COLORS.light)};
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    border: ${({ isFraud }) => (isFraud ? `2px solid ${COLORS.scarlet}` : '')};
    display: flex;
    margin: 0 7px;
    justify-content: center;
    ${({ isSelected }) =>
        isSelected
            ? css`
                  border: 2px solid white;
                  margin: 0 10px;
                  ${Mask} {
                      display: none;
                  }

                  ${IconContainer} {
                      display: none;
                  }
              `
            : css`
                  transform: scale(0.88);
                  opacity: 0.6;
              `};

    ${({ error }) =>
        error &&
        css`
            background-image: url('${__assets_url}blender_assets/backoffice/warning.svg');
        `}

    &:hover {
        opacity: 1;
        border: none;
        ${Mask} {
            opacity: 0;
        }

        ${IconContainer} {
            display: none;
        }
    }
`;

export const Video = styled.video`
    border-radius: 10px;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
`;

const getAttachmentContentType = (attachment: Attachment): string => {
    if (!attachment.content_type) {
        return 'image';
    }

    if (attachment.content_type.includes('http')) {
        const { download_url: url } = attachment;
        const [fileExtension] = url.substring(url.lastIndexOf('.') + 1).split('?');
        const contentType = getTypeFromContentType(attachment.content_type);

        return fileExtension === 'pdf' ? 'application/pdf' : `${contentType}/${fileExtension}`;
    }

    return attachment.content_type === 'pdf' ? 'application/pdf' : attachment.content_type;
};

export interface AttachmentPreviewItemProps {
    readonly attachment: Attachment;
    readonly zoom?: number;
    readonly isSelected?: boolean;
    readonly getIsLoading?: () => boolean;
    readonly getIsError?: () => boolean;
    readonly updateLoading?: (loading: boolean) => void;
    readonly updateError?: (error: boolean) => void;
    readonly onNavigation?: (index: number) => void;
}

const VideoThumb = styled.div`
    position: relative;
    video {
        border-radius: 0;
    }
    &:after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-image: url('${__assets_url}blender_assets/backoffice/play.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
`;

export const getAttachmentPreviewType = (attachment: Attachment): AttachmentPreviewType => {
    const contentType = getAttachmentContentType(attachment);
    const type = contentType.substring(0, contentType.indexOf('/'));

    switch (type) {
        case 'application':
            if (contentType.includes('/pdf')) return 'pdf';
            if (contentType.includes('/unknown')) return 'image';

            return 'other';
        case 'video':
            return 'video';
        case 'text':
            return 'html';
        default:
            return 'image';
    }
};

interface ThumbnailItemProps extends AttachmentPreviewItemProps {
    readonly onClick?: () => void;
    readonly tooltipContent?: JSX.Element | string;
}

export const ThumbnailItem: React.FC<React.PropsWithChildren<ThumbnailItemProps>> = ({
    attachment,
    onClick,
    isSelected,
    tooltipContent,
}) => {
    const [error, setError] = useState<boolean>(false);
    const isFraud = isFraudAttachment(attachment);

    const contentType = useMemo(() => getAttachmentPreviewType(attachment), [attachment]);

    function handleOnClick() {
        const pathArray = window.location.pathname.split('/');

        trackEvent('attachment.clicked', {
            fraud_flag: isFraud.toString(),
            attachment_id: attachment.file_public_id,
            attachment_type: attachment.type,
            entity_id: pathArray.at(4) ?? '',
            context: pathArray.at(5) ?? '',
        });

        onClick?.();
    }

    const ThumbnailComponent = (
        <ThumbnailWrapper>
            {contentType === 'image' && (
                <>
                    <StyledImageThumb
                        alt=""
                        img={{ lightSrc: attachment.thumbnail ?? attachment.download_url }}
                        onError={() => setError(true)}
                    />
                    {isFraud && (
                        <>
                            <Mask alignItems="center" justifyContent="center" />
                            <IconContainer />
                        </>
                    )}
                </>
            )}
            {contentType === 'pdf' && (
                <>
                    <StyledImageThumb
                        alt=""
                        img={{ lightSrc: '/blender_assets/backoffice/pdf_icon.svg' }}
                        isPlaceHolder
                    />
                    {isFraud && (
                        <>
                            <Mask alignItems="center" justifyContent="center" />
                            <IconContainer />
                        </>
                    )}
                </>
            )}
            {contentType === 'html' && (
                <StyledImageThumb alt="" img={{ lightSrc: '/blender_assets/backoffice/pdf_icon.svg' }} isPlaceHolder />
            )}
            {contentType === 'video' && (
                <VideoThumb>
                    <Video src={attachment.download_url} />
                </VideoThumb>
            )}
        </ThumbnailWrapper>
    );

    return (
        <StyledThumbnail error={error} isFraud isSelected={isSelected} onClick={handleOnClick}>
            {tooltipContent != null ? (
                <StatefulTooltip parent={ThumbnailComponent} position="top" style={{ zIndex: 1002 }}>
                    {tooltipContent}
                </StatefulTooltip>
            ) : (
                ThumbnailComponent
            )}
        </StyledThumbnail>
    );
};

type AttachmentPreviewType = 'html' | 'image' | 'other' | 'pdf' | 'video';

const AttachmentPreviewComponent: React.FC<React.PropsWithChildren<AttachmentPreviewItemProps>> = ({
    attachment,
    isSelected,
    getIsLoading,
    getIsError,
    updateLoading,
    updateError,
    zoom = 100,
}) => {
    const [dimensions, setDimensions] = useState<DOMRect | null>(null);
    const previewContainer = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const contentType = useMemo(() => getTypeFromContentType(attachment.content_type), [attachment.content_type]);

    const setContainerDimensions = useCallback(() => {
        if (previewContainer.current != null) {
            setDimensions(previewContainer.current.getBoundingClientRect());
        }
    }, []);

    useLayoutEffect(() => {
        if (previewContainer.current) {
            setDimensions(previewContainer.current.getBoundingClientRect());
        }
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', setContainerDimensions);
        return () => {
            window.removeEventListener('resize', setContainerDimensions);
        };
    }, [setContainerDimensions]);

    useLayoutEffect(() => {
        setContainerDimensions();
    }, [setContainerDimensions, zoom]);

    useEffect(() => {
        if (!isSelected && videoRef.current) {
            videoRef.current.pause();
        }

        if (isSelected && getIsLoading?.()) {
            if (contentType === 'doc' || contentType === 'pdf' || contentType == null) {
                setTimeout(() => updateLoading?.(false), 500);
            }
        }
    }, [contentType, getIsLoading, isSelected, updateLoading]);

    const PreviewElement = useCallback((): JSX.Element | null => {
        switch (contentType) {
            case 'image':
            case null:
                return (
                    <StyledImagePreview
                        style={{
                            transform: isSelected ? `scale(${zoom / 100})` : '',
                        }}
                    >
                        <DraggableImage
                            containerDimensions={dimensions}
                            fileName={attachment.original_filename}
                            isError={contentType == null || getIsError?.()}
                            src={attachment.download_url}
                            updateError={updateError}
                            updateLoading={updateLoading}
                            zoom={zoom}
                        />
                    </StyledImagePreview>
                );
            case 'video':
                return (
                    <StyledVideoWrapper>
                        <Video
                            controls
                            onError={() => updateError?.(true)}
                            onLoadedData={() => updateLoading?.(false)}
                            ref={videoRef}
                            src={attachment.download_url}
                        />
                    </StyledVideoWrapper>
                );
            case 'pdf': {
                return <StyledPdfIframe src={getInlineUrl(attachment.download_url)} />;
            }

            case 'doc':
                return (
                    <DownloadPreview>
                        <p>Documents of this file type cannot be displayed</p>
                        <SecondaryButton>
                            <DownloadLink
                                as="a"
                                download={attachment.original_filename}
                                href={attachment.download_url.replace(/\?inline=true$/, '')}
                            >
                                <span>Download</span>
                            </DownloadLink>
                        </SecondaryButton>
                    </DownloadPreview>
                );
            default:
                return null;
        }
    }, [
        contentType,
        isSelected,
        zoom,
        attachment.download_url,
        attachment.original_filename,
        dimensions,
        updateLoading,
        updateError,
        getIsError,
    ]);

    return (
        <StyledAttachmentPreview
            isFullHeight={contentType === 'doc'}
            isSelected={isSelected ?? false}
            ref={previewContainer}
        >
            <PreviewElement />
        </StyledAttachmentPreview>
    );
};

export const AttachmentPreviewItem = memo(AttachmentPreviewComponent);
