/* eslint-disable react/function-component-definition */
import type { ReactNode } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Text } from '../../base/Text/Text';
import { spacing } from '../../theme/spacing.css';
import { formatMediaTime } from '../../utils/media';
import { Avatar } from '../Avatar/Avatar';
import { Icon } from '../Icon/Icon';
import { useAudioPlayerSegments } from './AudioPlayer.SegmentsProvider';
import type { AudioPlayerSegment } from './AudioPlayer.SegmentsProvider.types';

export interface AudioPlayerStructuredTranscriptItemProps<TSegment extends AudioPlayerSegment> {
  readonly segment: TSegment;
  readonly index: number;
}

export function AudioPlayerStructuredTranscriptItem<TSegment extends AudioPlayerSegment>({
  segment,
  index,
}: AudioPlayerStructuredTranscriptItemProps<TSegment>): ReactNode {
  const { segmentPresenter } = useAudioPlayerSegments();
  const { title, content, image } = segmentPresenter?.(segment, index) ?? { title: 'Unknown', content: 'N/A' };

  return (
    <Flex alignItems="flex-start" gap={spacing.s08} overflow="hidden">
      {image != null ? <Avatar alt="image" size="sm" src={image} /> : <Avatar name={title} size="sm" />}

      <Flex flexDirection="column" gap={spacing.s06} marginBlockStart={spacing.s02}>
        <Flex alignItems="center" gap={spacing.s04}>
          <Text type="h6">{title}</Text>
          <Flex alignItems="center" gap={spacing.s04} marginBlockStart={spacing.s02}>
            <Icon color="textSecondary" name="bullet" />
            <Text color="secondary" type="text-sm">
              {formatMediaTime(segment.startedAt)}
            </Text>
          </Flex>
        </Flex>
        <Text type="text-sm">{content}</Text>
      </Flex>
    </Flex>
  );
}
