import type { TableColumn, TableItem } from '@lemonade-hq/blender-ui';
import {
    ActionsMenu,
    Button,
    Card,
    Flex,
    FormLayout,
    FormProvider,
    generateTypedFormComponents,
    Grid,
    spacing,
    Table,
    Text,
    useForm,
} from '@lemonade-hq/blender-ui';
import { Image, PaginationBar } from '@lemonade-hq/bluis';
import { EntitySummaryHeader, HeaderGeneralDetails, LinkComp } from '@lemonade-hq/bluiza';
import { ma } from '@lemonade-hq/maschema-schema';
import { useCallback, useMemo, useState } from 'react';
import { getEditionSetUrl } from '../common/urlBuilders';
import { HeaderImageSizes, ImageContainer } from '../LoCoPagesSharedStyles';
import { Dialogs } from './TestHubDialogs';
import { formatDate, FORMATS } from 'commons/DateFormatter';
import { HeaderPortal } from 'components/Header';
import type { EditionSetEnvOverride } from 'models/LoCo/Insurance/EditionSets';
import type { GetEnvsOverridesRequest } from 'queries/LoCo/Insurance/EditionSetQueries';
import { useGetEnvsOverrides } from 'queries/LoCo/Insurance/EditionSetQueries';

const testHubSchema = ma.record({
    envStage: ma.oneOf(['staging', 'development']),
    envName: ma.string(),
});

const { InputGroup } = generateTypedFormComponents<typeof testHubSchema>();

const columns: TableColumn[] = [
    {
        name: 'Edition Set',
        key: 'editionSet',
    },
    {
        name: 'Products',
        key: 'products',
    },
    {
        name: 'Activated',
        key: 'activatedAt',
    },
    {
        name: 'Until',
        key: 'expirationDate',
    },
    {
        name: '',
        key: 'actions',
    },
];

interface LoCoTestHubFormProps {
    readonly isLoading: boolean;
    readonly onSubmit: (values?: GetEnvsOverridesRequest) => void;
}

export const LoCoTestHubForm: React.FC<LoCoTestHubFormProps> = ({ isLoading, onSubmit }) => {
    const {
        values,
        validationResults: { valid },
    } = useForm();

    return (
        <FormLayout mt={spacing.s20}>
            <InputGroup
                alignToTop
                direction="horizontal"
                gridSize="small"
                inputComponent="Radio"
                label="Environment Type"
                schemaKey="envStage"
            />
            <InputGroup
                direction="horizontal"
                gridSize="small"
                inputComponent="Input"
                label="Environment Name"
                placeholder="Environment name"
                schemaKey="envName"
            />
            <Grid alignItems="center" gridTemplateColumns="auto 22rem">
                <Flex />
                <Button
                    disabled={!valid}
                    label="LOOK UP ENVIRONMENT"
                    loading={isLoading}
                    onClick={() => onSubmit(values)}
                    size="lg"
                    variant="primary"
                />
            </Grid>
        </FormLayout>
    );
};

export const LoCoTestHub: React.FC = () => {
    const [query, setQuery] = useState<GetEnvsOverridesRequest | undefined>();
    const [dialogType, setDialogType] = useState<'reactivate' | 'remove' | undefined>();
    const [selectedEnv, setSelectedEnv] = useState<EditionSetEnvOverride | undefined>();

    const { data: envsOverrides, isLoading, refetch } = useGetEnvsOverrides(query);

    const totalPages = useMemo(() => envsOverrides?.stats.totalPages ?? 0, [envsOverrides]);

    const getActions = useCallback((env: EditionSetEnvOverride) => {
        if (new Date(env.expirationDate) > new Date()) {
            return [
                {
                    label: 'Remove from Environment',
                    onClick: () => {
                        setDialogType('remove');
                        setSelectedEnv(env);
                    },
                },
            ];
        } else if (env.editionSet.publishedAt == null) {
            return [
                {
                    label: 'Reactivate in Environment',
                    onClick: () => {
                        setDialogType('reactivate');
                        setSelectedEnv(env);
                    },
                },
            ];
        }
    }, []);

    const data: Record<string, TableItem>[] = useMemo(
        () =>
            envsOverrides?.data.map(env => {
                const actions = getActions(env);
                const stillActive = new Date(env.expirationDate) >= new Date();
                const expirationDate = formatDate(env.expirationDate, FORMATS.SIMPLE_DATE_SHORT_MONTH);

                return {
                    editionSet: {
                        value: (
                            <LinkComp url={getEditionSetUrl(env.productCode, env.editionSetCode)}>
                                {env.editionSet.friendlyName}
                            </LinkComp>
                        ),
                    },
                    products: { value: env.productName },
                    activatedAt: { value: formatDate(env.activatedAt, FORMATS.SIMPLE_DATE_SHORT_MONTH) },
                    expirationDate: {
                        value: stillActive ? `Still active (ends ${expirationDate})` : expirationDate,
                    },
                    actions: { value: actions != null ? <ActionsMenu items={actions} /> : '' },
                };
            }) ?? [],
        [envsOverrides?.data, getActions]
    );

    const onFormSubmit = (values?: GetEnvsOverridesRequest): void => {
        if (values != null) {
            if (query?.envName !== values.envName || query?.envStage !== values.envStage) {
                setQuery({
                    envStage: values.envStage,
                    envName: values.envName,
                    size: 10,
                    page: 1,
                });
            } else {
                void refetch();
            }
        }
    };

    const onPageClick = (nextPage: number) => () => {
        setQuery(prev => ({ ...prev, page: nextPage }));
    };

    return (
        <FormProvider initialConfig={{ showErrorsOnBlur: true }} schema={testHubSchema}>
            <>
                <HeaderPortal>
                    <EntitySummaryHeader>
                        <ImageContainer>
                            <Image
                                img={{
                                    lightSrc: 'appraisal.svg',
                                    darkSrc: 'appraisal-dark.svg',
                                    ...HeaderImageSizes,
                                }}
                            />
                        </ImageContainer>
                        <HeaderGeneralDetails title="LoCo Test Hub" />
                    </EntitySummaryHeader>
                </HeaderPortal>
                <Flex p={spacing.s16}>
                    <LoCoTestHubForm isLoading={isLoading} onSubmit={onFormSubmit} />
                </Flex>
                <Flex p={spacing.s16}>
                    <Card>
                        <Flex alignItems="center" justifyContent="space-between" py={spacing.s16}>
                            <Text type="h4">Configuration Activation History in Environment</Text>
                            {query != null && (
                                <Text>
                                    Showing results for: &quot;{query.envStage} - {query.envName}&quot;
                                </Text>
                            )}
                        </Flex>
                        <Table
                            columns={columns}
                            data={data}
                            emptyMessage={
                                envsOverrides == null
                                    ? 'Please select environment type and name to see activation history'
                                    : 'No configuration activation history found for environment'
                            }
                        />
                        {query?.page != null && (
                            <PaginationBar currentPage={query.page} onPageClick={onPageClick} pages={totalPages} />
                        )}
                    </Card>
                </Flex>
            </>
            {dialogType != null && selectedEnv != null && (
                <Dialogs dialogType={dialogType} env={selectedEnv} onClose={() => setDialogType(undefined)} />
            )}
        </FormProvider>
    );
};
