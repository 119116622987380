// Referenced from https://github.com/floating-ui/floating-ui/blob/master/packages/utils/src/index.ts

type OverflowAncestors = (Element | VisualViewport | Window)[];

export function getNodeName(node: Node | Window): string {
  if (node instanceof Node) {
    return (node.nodeName || '').toLowerCase();
  }

  // Mocked nodes in testing environments may not be instances of Node. By
  // returning `#document` an infinite loop won't occur.
  // https://github.com/floating-ui/floating-ui/issues/2317
  return '#document';
}

export function isOverflowElement(element: Element): boolean {
  const { overflow, overflowX, overflowY, display } = getComputedStyle(element);

  return (
    /auto|scroll|overlay|hidden|clip/.test(overflow + overflowY + overflowX) &&
    !['inline', 'contents'].includes(display)
  );
}

export function isLastTraversableNode(node: Node): boolean {
  return ['html', 'body', '#document'].includes(getNodeName(node));
}

export function getNearestOverflowAncestor(node: HTMLElement): HTMLElement {
  const parentNode = node.parentElement;
  if (!parentNode) return node;
  if (isLastTraversableNode(parentNode)) {
    return node.ownerDocument.body;
  }

  if (isOverflowElement(parentNode)) {
    return parentNode;
  }

  return getNearestOverflowAncestor(parentNode);
}

export function getOverflowAncestors(node: HTMLElement, list: OverflowAncestors = []): OverflowAncestors {
  const scrollableAncestor = getNearestOverflowAncestor(node);
  const isBody = scrollableAncestor === node.ownerDocument.body;

  if (isBody) {
    return list.concat(
      window,
      window.visualViewport ?? [],
      isOverflowElement(scrollableAncestor) ? scrollableAncestor : [],
    );
  }

  return list.concat(scrollableAncestor, getOverflowAncestors(scrollableAncestor, []));
}

export function rectsAreEqual(a: DOMRect, b: DOMRect): boolean {
  return a.x === b.x && a.y === b.y && a.width === b.width && a.height === b.height;
}

function observeMove(element: Element, onMove: () => void): () => void {
  let observer: IntersectionObserver | null = null;
  let timeoutId: NodeJS.Timeout;

  const body = document.body;

  function cleanup(): void {
    clearTimeout(timeoutId);
    observer?.disconnect();
    observer = null;
  }

  function refresh(threshold = 1): void {
    cleanup();

    const bodyRect = element.getBoundingClientRect();
    const { left, top, width, height } = bodyRect;
    onMove();

    if (!width || !height) {
      return;
    }

    const insetTop = Math.floor(top);
    const insetRight = Math.floor(body.clientWidth - (left + width));
    const insetBottom = Math.floor(body.clientHeight - (top + height));
    const insetLeft = Math.floor(left);
    const rootMargin = `${-insetTop}px ${-insetRight}px ${-insetBottom}px ${-insetLeft}px`;

    const options = {
      rootMargin,
      threshold: Math.max(0, Math.min(1, threshold)) || 1,
    };

    let isFirstUpdate = true;

    function handleObserve(entries: IntersectionObserverEntry[]): void {
      const ratio = entries[0].intersectionRatio;

      if (ratio !== threshold) {
        if (!isFirstUpdate) {
          refresh();
          return;
        }

        if (!ratio) {
          // If the reference is clipped, the ratio is 0. Throttle the refresh
          // to prevent an infinite loop of updates.
          timeoutId = setTimeout(() => {
            refresh(1e-7);
          }, 1000);
        } else {
          refresh(ratio);
        }
      }

      if (ratio === 1 && !rectsAreEqual(bodyRect, element.getBoundingClientRect())) {
        // It's possible that even though the ratio is reported as 1, the
        // element is not actually fully within the IntersectionObserver's root
        // area anymore. This can happen under performance constraints. This may
        // be a bug in the browser's IntersectionObserver implementation. To
        // work around this, we compare the element's bounding rect now with
        // what it was at the time we created the IntersectionObserver. If they
        // are not equal then the element moved, so we refresh.
        refresh();
      }

      isFirstUpdate = false;
    }
    // Older browsers don't support a `document` as the root and will throw an
    // error.
    try {
      observer = new IntersectionObserver(handleObserve, {
        ...options,
        // Handle <iframe>s
        root: body.ownerDocument,
      });
    } catch (e) {
      observer = new IntersectionObserver(handleObserve, options);
    }

    observer.observe(element);
  }

  refresh();

  return cleanup;
}

/**
 * Automatically updates the position of the floating element when necessary.
 * Should only be called when the floating element is mounted on the DOM or
 * visible on the screen.
 * @returns cleanup function that should be invoked when the floating element is
 * removed from the DOM or hidden from the screen.
 * @see https://floating-ui.com/docs/autoUpdate
 */
export function autoUpdate(reference: HTMLElement, update: () => void, animationFrame: boolean = false): () => void {
  const ancestors = getOverflowAncestors(reference);

  ancestors.forEach(ancestor => {
    ancestor.addEventListener('scroll', update, { passive: true });
    ancestor.addEventListener('resize', update);
  });

  const cleanupIo = observeMove(reference, update);

  let resizeObserver: ResizeObserver | null = null;

  resizeObserver = new ResizeObserver(() => {
    update();
  });

  if (!animationFrame) {
    resizeObserver.observe(reference);
  }

  let frameId: number;
  let prevRefRect = animationFrame ? reference.getBoundingClientRect() : null;

  if (animationFrame) {
    frameLoop();
  }

  function frameLoop(): void {
    const nextRefRect = reference.getBoundingClientRect();

    if (prevRefRect && !rectsAreEqual(prevRefRect, nextRefRect)) {
      update();
    }

    prevRefRect = nextRefRect;
    frameId = requestAnimationFrame(frameLoop);
  }

  update();

  return () => {
    ancestors.forEach(ancestor => {
      ancestor.removeEventListener('scroll', update);
      ancestor.removeEventListener('resize', update);
    });

    cleanupIo();
    resizeObserver?.disconnect();
    resizeObserver = null;

    if (animationFrame) {
      cancelAnimationFrame(frameId);
    }
  };
}
